import { ref } from 'vue'

export default function () {
  const _props = {
    modelValue: {
      type: null,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    rules: {
      type: Object,
      default() {
        return
      }
    },
    required: {
      type: Boolean,
      default: false
    }
  }

  const _emits = ['update:modelValue']

  return { _props, _emits }
}
