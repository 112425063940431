import vatRates from '../../../public/data/vatRates.json'

export default function () {
  const get = (countryCode) => {
    let countryRate = vatRates[countryCode]
    if (countryRate == undefined) {
      return false
    }
    let currentRate = countryRate.rates.find((rate) => {
      let now = new Date().toISOString()

      let afterStart = rate.start < now
      let beforeEnd = rate.end > now || rate.end == null

      return afterStart && beforeEnd
    })

    return currentRate.value
  }

  return { get }
}
