import currencies from '../../../public/data/currencies'

export default function () {
  const get = () => {
    return currencies
  }

  const getForSelect = () => {
    let values = []
    for (let [key, value] of Object.entries(currencies)) {
      values.push({
        label: `${value.name} (${value.code})`,
        value: value.code
      })
    }
    return values
  }

  const getForTable = () => {
    let values = {}
    for (let [key, value] of Object.entries(currencies)) {
      values[value.code] = `${value.name} (${value.code})`
    }
    return values
  }

  return {
    getCurrencies: get,
    getCurrenciesForSelect: getForSelect,
    getCurrenciesForTable: getForTable
  }
}
