<template>
  <span class="status data" :class="classObject" :title="displayValue">
    {{ displayValue }}
  </span>
</template>

<script>
export default {
  name: 'StatusBadge'
}
</script>

<script setup>
import { computed } from 'vue'
const props = defineProps({
  displayValue: {
    type: String,
    required: true
  }
})

const classObject = computed(() => {
  if (!props.displayValue) return
  const valueLowerCase = props.displayValue.trim().toLowerCase()
  return {
    statusGrey:
      valueLowerCase === 'draft' /* ORDER  STATUS */ ||
      valueLowerCase === 'archived',
    statusOrange:
      valueLowerCase === 'unfulfilled' /* ORDER  STATUS */ ||
      valueLowerCase === 'awaiting approval' ||
      valueLowerCase === 'waiting to be collected' /* SHIPMENT STATUS */,
    statusLightGreen:
      valueLowerCase === 'part-fulfilled' /* ORDER  STATUS */ ||
      valueLowerCase === 'approved',
    statusGreen:
      valueLowerCase === 'fulfilled' /* ORDER  STATUS */ ||
      valueLowerCase === 'active' ||
      valueLowerCase === 'received' ||
      valueLowerCase === 'delivered' /* SHIPMENT STATUS */,
    statusRed: valueLowerCase === 'cancelled' || valueLowerCase === 'inactive',
    statusYellow:
      valueLowerCase === 'sent to supplier' ||
      valueLowerCase === 'shipped' /* SHIPMENT STATUS */,
    statusGold: valueLowerCase === 'paid' /* ORDER PAYMENT STATUS */
  }
})
</script>

<style lang="scss">
.status {
  display: block;
  // border-radius: 5px;
  padding: 0rem 0.5rem;
  // color: #ffffff;
  width: fit-content !important;
}

.statusGold {
  border-left: 0.3rem solid #c2a1f1;
}

.statusGrey {
  border-left: 0.3rem solid #b7b7ba;
}

.statusLightGreen {
  // border-left: 0.3rem solid #48b9b2;
  border-left: 0.3rem solid #a8e1e1;
}

.statusGreen {
  border-left: 0.3rem solid #a1e4bf;
}

.statusYellow {
  border-left: 0.3rem solid #fad15e;
}

.statusOrange {
  border-left: 0.3rem solid #fcc888;
}

.statusRed {
  // border-left: 0.3rem solid #e71d2b;
  border-left: 0.3rem solid #ee9496;
}
</style>
