import marketplaces from '../../../public/data/marketplaces.json'

export default function () {
  const get = () => {
    return marketplaces
  }

  const getForSelect = () => {
    let values = []
    for (let [key, value] of Object.entries(marketplaces)) {
      values.push({
        label: value.country,
        value: value.marketplaceId
      })
    }
    return values
  }

  return { getMarketplaces: get, getMarketplacesForSelect: getForSelect }
}
