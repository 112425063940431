<template>
  <span class="swap-container">
    <span class="swap-input">
      <slot v-if="swap" name="first"></slot>
      <slot v-else name="second"></slot>
    </span>
    <span class="swap-button">
      <button class="btn btn-grey borderless" @click="swap = !swap">
        <p class="m-0">
          {{ swap ? buttonText[0] : buttonText[1] }}
        </p>
      </button>
    </span>
  </span>
</template>

<script setup>
import { ref, watch } from 'vue'
import TableText from '@/components/table/form/TableText'
import TableNumber from '@/components/table/form/TableNumber'
import TableDate from '@/components/table/form/TableDate'
import TableSelect from '@/components/table/form/TableSelect'

const emit = defineEmits(['update:modelValue', 'search'])
const props = defineProps({
  modelValue: {
    type: null,
    validator: (v) => true,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  buttonText: {
    type: [Array, String],
    default: 'Swap'
  },
  display: {
    type: String,
    default: 'label'
  },
  reduce: {
    type: Function,
    default() {}
  },
  options: {
    type: Array,
    default() {
      return []
    }
  },
  rules: {
    type: Object,
    default() {
      return {}
    }
  }
})

const value = ref(props.modelValue)

watch(
  () => props.modelValue,
  (val) => (value.value = val)
)

const swap = ref(true)
const previousValue = ref(null)

const updated = (data) => {
  if (data == previousValue.value) {
    return
  }
  data = formatData(data)
  emit('update:modelValue', data)
  previousValue.value = data
}
</script>

<style lang="scss">
.swap-container {
  display: flex;
}
.swap-input {
  & .vs__dropdown-toggle {
    width: 100% !important;
  }
}
.swap-button {
  padding: 0 0.5rem;
}
</style>
