<template>
  <ValidationProvider ref="validation" v-slot="{ errors, changes }">
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: {
          show: local.archivedAt == null,
          disable: (!changes && !saveButtonEnabled) || saving || errors
        },
        2: {
          show:
            local.paidAt == null &&
            local.archivedAt == null &&
            !changes &&
            !saving
        },
        3: {
          show:
            purchaseOrderId &&
            purchaseOrderId != 'new' &&
            local.deliveryLocationId != '' &&
            local.deliveryLocationId != undefined &&
            editable &&
            !changes &&
            !saving
        },
        4: {
          show:
            purchaseOrderId &&
            purchaseOrderId != 'new' &&
            local.approvedAt != null &&
            local.archivedAt == null &&
            !changes &&
            !saving
        },
        5: {
          show: local.archivedAt != null && selectedTab === 'Purchase Order'
        },
        6: {
          show: local.archivedAt == null && !changes && !saving
        },
        7: {
          show: local.archivedAt == null && !changes && !saving,
          disable: local.currency == null
        },
        8: {
          show: local.archivedAt == null && !changes && !saving,
          disable: local.currency == null
        },
        9: {
          show: selectedTab == 'Purchase Order'
        }
      }"
    ></ActionBar>
    <!-- NOTIFICATIONS -->
    <div class="row">
      <div
        v-if="local.archivedAt == null && local.paidAt != null"
        class="col-12"
      >
        <p id="paidAtNotification" role="alert">
          Paid {{ local.paidAt }} by {{ local.paidBy }}
        </p>
      </div>
      <div v-if="!editable" class="col-12">
        <p id="approvedNotification" role="alert">
          This PO has been marked as approved. No further changes can be made to
          it without removing the approval.
        </p>
      </div>
      <div
        v-if="
          local.minOrderValue != null &&
          local.totalItemPrice < parseFloat(local.minOrderValue)
        "
        class="col-12"
      >
        <p id="minOrderNotification">
          This order item total is below the minimum order value for this
          supplier ({{ local.minOrderValue }})
        </p>
      </div>
    </div>
    <!--  ./NOTIFICATIONS -->
    <TableTabs ref="tabManager" v-slot="{ activeTab }" :tabs="tabs">
      <TableContainer v-if="activeTab === 'Purchase Order'">
        <TablePanel :cols="2" :width="2">
          <template #1>
            <TableForm>
              <!-- SUPPLIER -->
              <TableRow
                v-if="!purchaseOrderId || purchaseOrderId == 'new'"
                v-model="local.tenantSupplierId"
                label="Supplier"
                :options="suppliers"
                type="select"
                @search="searchSuppliers"
                @change="updateSupplier"
              ></TableRow>

              <TableRow
                v-else
                v-model="local.supplierName"
                :link="`/suppliers/${local.tenantSupplierId}`"
                label="Supplier"
                type="text"
                read-only
              ></TableRow>
              <!-- ./SUPPLIER -->

              <!-- PO NUMBER -->
              <TableRow
                v-model="local.poNumber"
                label="PO Number"
                type="text"
                :rules="{ length: { min: 3 } }"
                read-only
              ></TableRow>
              <!-- ./PO NUMBER -->

              <!-- STATUS -->
              <TableRow
                v-model="local.status"
                label="Status"
                type="select"
                :options="[
                  { value: 0, label: 'Draft' },
                  { value: 1, label: 'Awaiting approval' },
                  { value: 2, label: 'Sent to supplier' },
                  { value: 3, label: 'Received' }
                ]"
              ></TableRow>
              <!-- ./STATUS -->

              <!-- RAISED AT -->
              <TableRow
                v-model="local.createdAt"
                label="Raised At"
                type="text"
                read-only
              ></TableRow>
              <!-- ./RAISED AT -->

              <!-- RAISED BY -->
              <TableRow
                v-model="local.createdBy"
                label="Raised By"
                type="text"
                read-only
              ></TableRow>
              <!-- ./RAISED BY -->

              <!-- TOTAL ITEM QUANTITY -->
              <TableRow
                v-model="totalPoUnits"
                label="Item Quantity Total"
                type="number"
                read-only
              ></TableRow>
              <!--./TOTAL ITEM QUANTITY -->

              <!-- TOTAL ITEM PRICE-->
              <TableRow
                v-model="local.totalItemPrice"
                label="Line Item total"
                type="number"
                read-only
              ></TableRow>
              <!--./TOTAL ITEM PRICE -->
            </TableForm>
          </template>
          <template #2>
            <TableForm>
              <!-- DELIVERY CHARGE-->
              <TableRow
                v-model="local.deliveryCharge"
                label="Delivery Charge"
                type="number"
              ></TableRow>
              <!--./DELIVERY CHARGE -->

              <!-- DELIVERY CHARGE VAT -->
              <TableRow
                v-model="deliveryChargeVATPercent"
                label="Delivery Charge VAT (%)"
                type="number"
                @update:model-value="updateDeliveryChargeVAT"
              ></TableRow>
              <!--./DELIVERY CHARGE VAT -->

              <!-- TOTAL PO PRICE-->
              <TableRow
                v-model="local.totalPoPrice"
                label="Purchase Order Total"
                type="number"
                read-only
              ></TableRow>
              <!--./TOTAL PO PRICE -->

              <!-- APPROVED BY -->
              <TableRow
                v-if="local.approvedAt"
                v-model="local.approvedBy"
                label="Approved By"
                type="text"
                read-only
              ></TableRow>
              <!-- ./APPROVED BY -->

              <!-- APPROVED AT -->
              <TableRow
                v-if="local.approvedAt"
                v-model="local.approvedAt"
                label="Approved on"
                type="text"
                read-only
              ></TableRow>
              <!-- ./APPROVED AT -->

              <!-- DELIVERY ADDRESS -->
              <TableRow
                v-model="local.deliveryLocationId"
                label="Delivery Address"
                type="select"
                :options="deliveryAddressOptions"
                :read-only="!editable"
                :row-class="(row) => row == null"
              ></TableRow>
              <!-- ./DELIVERY ADDRESS -->

              <!-- EXPECTED DATE -->
              <TableRow
                v-model="local.expectedDate"
                label="Expected Date"
                type="date"
              ></TableRow>
              <!-- ./EXPECTED DATE -->

              <!-- CURRENCY -->
              <TableRow
                v-model="local.currency"
                label="Currency"
                type="select"
                :options="currencies"
              ></TableRow>
              <!-- ./CURRENCY -->
            </TableForm>
          </template>
        </TablePanel>
        <TablePanel :cols="2" :width="2">
          <template #1>
            <TableForm :style="'height: 100%'">
              <!-- INTERNAL NOTES -->
              <TableRow
                v-model="local.internalNotes"
                label="Internal Notes"
                type="textarea"
                :style="'height: 50%'"
              ></TableRow>
              <!-- ./INTERNAL NOTES -->

              <!-- PO NOTES -->
              <TableRow
                v-model="local.poNotes"
                label="PO Notes"
                type="textarea"
                :style="'height: 50%'"
              ></TableRow>
              <!-- ./PO NOTES -->
            </TableForm>
          </template>
          <template #2>
            <TableForm>
              <RowContainer heading="Supplier Details">
                <!-- ACCOUNT NUMBER-->
                <TableRow
                  v-model="local.accountNumber"
                  label="Account Number"
                  type="text"
                  read-only
                  :indent-level="1"
                ></TableRow>
                <!-- ./ACCOUNT NUMBER -->

                <!-- PAYMENT TERMS -->
                <TableRow
                  v-model="local.paymentTerms"
                  label="Payment Terms"
                  type="text"
                  read-only
                  :indent-level="1"
                ></TableRow>
                <!-- ./PAYMENT TERMS -->

                <!-- MINIMUM ORDER VALUE-->
                <TableRow
                  v-model="local.minOrderValue"
                  label="Minimum Order Value"
                  type="text"
                  read-only
                  :indent-level="1"
                ></TableRow>
                <!--./MINIMUM ORDER VALUE -->
              </RowContainer>
            </TableForm>
          </template>
        </TablePanel>
        <TablePanel>
          <template #1>
            <ColumnPreferences
              ref="preferences"
              :cols="purchaseOrderItemCols"
              list-name="PO Items"
              :storage-name="localStorageName"
              @save="updateColumns"
            ></ColumnPreferences>
            <div class="row mb-1">
              <div class="col-1">
                <button
                  v-if="
                    purchaseOrderId && purchaseOrderId != 'new' && !pageLoading
                  "
                  :disabled="disablePoButtons"
                  type="button"
                  class="btn btn-purple"
                  style="width: 100%"
                  @click="openReceiveMultipleModal"
                >
                  Check in stock
                </button>
              </div>
              <div class="col-1">
                <button
                  v-if="
                    purchaseOrderId && purchaseOrderId != 'new' && !pageLoading
                  "
                  :disabled="disablePoButtons"
                  type="button"
                  class="btn btn-purple"
                  style="width: 100%"
                  @click="openCancelMultipleModal"
                >
                  Cancel stock
                </button>
              </div>
              <div class="col-2 offset-6 summary-title">
                <b>Item Total</b>
              </div>
              <div class="col-2 text-align-right summary-data">
                <SkeletonText v-if="pageLoading"></SkeletonText>
                <span v-else>{{ local.totalItemPrice }}</span>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-2 offset-8 summary-title">
                <b>Delivery Charge</b>
              </div>
              <div class="col-2 text-align-right summary-data">
                <SkeletonText v-if="pageLoading"></SkeletonText>
                <span v-else>{{ local.deliveryCharge || '0.00' }}</span>
              </div>
            </div>
            <div class="row mb-1">
              <div class="col-2 offset-8 summary-title">
                <b>Purchase Order Total</b>
              </div>
              <div class="col-2 text-align-right summary-data">
                <SkeletonText v-if="pageLoading"></SkeletonText>
                <span v-else>{{ local.totalPoPrice }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col-2 offset-8 summary-title">
                <b> Received </b>
              </div>
              <div class="col-2 text-align-right summary-data">
                <SkeletonText v-if="pageLoading"></SkeletonText>
                <span v-else>{{ local.totalReceivedPrice }}</span>
              </div>
            </div>
            <TableGrid
              ref="poItemsGrid"
              v-off-screen="() => (duplicateActionRow = true)"
              :rows="local.purchaseOrderItems"
              :cols="itemsCols"
              id-key="tenantPurchaseOrderItemId"
              :trclick="openRsmModal"
              :total-count="local.purchaseOrderItems.length"
              :delete-function="deletePoLine"
              :check-deleted="checkDeleted"
              :delete="local.approvedAt == null"
              :info-icons="purchaseOrderItemInfoBlocks"
              checkbox
              select-all
              @toggle-alert-icons="_toggleAlertIcons"
            ></TableGrid>
            <div
              v-if="editable && !local.tenantSupplierId && !pageLoading"
              class="row"
            >
              <div class="col-12 d-flex justify-content-center">
                <p class="m-0">
                  Choose a supplier to add SKUs to a purchase order.
                </p>
              </div>
            </div>
            <div
              v-else-if="
                local.tenantSupplierId && local.purchaseOrderItems.length == 0
              "
              class="row"
            >
              <div class="col-12 d-flex justify-content-center">
                <p class="m-0">
                  No SKUs have been added for this purchase order.
                </p>
              </div>
            </div>
            <template v-if="editable && local.tenantSupplierId">
              <div :colspan="itemsCols.length" class="tbl-cell-btn">
                <button type="button" class="btn" @click="openSkuPoModal()">
                  <font-awesome-icon
                    :icon="['fas', 'circle-plus']"
                  ></font-awesome-icon>
                  <b>Add SKUs</b>
                </button>
              </div>
            </template>
            <template v-if="duplicateActionRow">
              <div class="row bottom-action-row">
                <div class="col-1">
                  <button
                    v-if="purchaseOrderId && purchaseOrderId != 'new'"
                    :disabled="disablePoButtons"
                    type="button"
                    class="btn btn-purple"
                    style="width: 100%"
                    @click="openReceiveMultipleModal"
                  >
                    Check in stock
                  </button>
                </div>
                <div class="col-1">
                  <button
                    v-if="purchaseOrderId && purchaseOrderId != 'new'"
                    :disabled="disablePoButtons"
                    type="button"
                    class="btn btn-purple"
                    style="width: 100%"
                    @click="openCancelMultipleModal"
                  >
                    Cancel stock
                  </button>
                </div>
                <div class="col-2 offset-6 summary-title">
                  <b>Item Total</b>
                </div>
                <div class="col-2 text-align-right summary-data">
                  {{ local.totalItemPrice }}
                </div>
              </div>
              <div class="row">
                <div class="col-2 offset-8 summary-title">
                  <b>Delivery Charge</b>
                </div>
                <div class="col-2 text-align-right summary-data">
                  {{ local.deliveryCharge || '0.00' }}
                </div>
              </div>
              <div class="row">
                <div class="col-2 offset-8 summary-title">
                  <b>Purchase Order Total</b>
                </div>
                <div class="col-2 text-align-right summary-data">
                  {{ local.totalPoPrice }}
                </div>
              </div>
              <div class="row">
                <div class="col-2 offset-8 summary-title">
                  <b> Received </b>
                </div>
                <div class="col-2 text-align-right summary-data">
                  {{ local.totalReceivedPrice }}
                </div>
              </div>
            </template>
          </template>
        </TablePanel>
      </TableContainer>

      <template v-if="activeTab === 'History'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableGrid
                :cols="auditHistoryCols"
                :rows="historyPoRows"
                :total-count="totalHistoryPoCount"
                :filter-function="(args) => setHistoryRows(args.skip)"
                caption="Purchase Order History"
                scrollable
              ></TableGrid>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
    </TableTabs>

    <!-- SKU PO MODAL -->
    <BraidModal
      ref="skuPoModal"
      @confirm="saveSkuPoModal"
      @close="closeSkuPoModal"
      @reject="closeSkuPoModal"
    >
      <template #title>Add SKU to PO</template>
      <template #body>
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableGrid
                ref="skuPoModalGrid"
                select-id="m2mSkuSupplierId"
                :cols="skuPoCols"
                :rows="skuPoRows"
                :local-loading="skuPoLoading"
                :total-count="totalSkuPoCount"
                :filter-function="getSkuPoRows"
                :default-sort="defaultSkuPoSort"
                checkbox
                select-all
                :update-url="false"
                caption="List of SKU POs"
                scrollable
              ></TableGrid>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
    </BraidModal>
    <!-- SKU PO MODAL -->

    <!-- OUTSTANDING PO ITEMS MODAL -->
    <BraidModal ref="outstandingPoItemsModal">
      <template #title>
        {{ outstandingPoItems.sku }} - Outstanding items on Purchase Orders
      </template>
      <template #body>
        <TableGrid
          :cols="outstandingPoItemCols"
          :rows="outstandingPoItems.rows"
          id-key="tenantPurchaseOrderItemId"
          :total-count="outstandingPoItems.rows.length"
          :update-url="false"
          :info-icons="outstandingPoItemInfoBlocks"
          scrollable
        ></TableGrid>
      </template>
      <template #footer></template>
    </BraidModal>
    <!-- OUTSTANDING PO ITEMS MODAL -->

    <!-- RECEIVE MULTIPLE MODAL -->
    <ReceiveMultipleSkusModal
      ref="receiveMultipleModal"
      :items="receiveMultipleData.items"
      :purchase-order-id="purchaseOrderId"
      :locations="locations"
      :delivery-location-id="local.deliveryLocationId"
      :purchase-order-items="local.purchaseOrderItems"
      @get-data="getData"
      @close="closeReceiveMultipleModal"
    ></ReceiveMultipleSkusModal>
    <!-- RECEIVE MULTIPLE MODAL -->

    <ReceiveStockModal
      ref="receiveStockModal"
      :po-number="local.poNumber"
      :locations="locations"
      @confirm="getData"
      @get-data="getData"
    ></ReceiveStockModal>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    ></ConfirmLeaveFormModal>

    <CancelMultipleSkusModal
      ref="cancelMultipleModal"
      :items="multiSelectModalItems"
      :purchase-order-id="purchaseOrderId"
      @close="closeCancelMultipleModal"
    ></CancelMultipleSkusModal>

    <BackorderMultipleSkusModal
      ref="backorderMultipleModal"
      :items="multiSelectModalItems"
      :purchase-order-id="purchaseOrderId"
      @close="closeBackorderMultipleModal"
    ></BackorderMultipleSkusModal>
  </ValidationProvider>
</template>

<script setup>
import {
  ref,
  nextTick,
  onUpdated,
  computed,
  watch,
  watchEffect,
  inject
} from 'vue'
const _ = require('lodash')

import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import useVat from '@/composables/selectData/useVat'
const router = useRouter()
const api = useApi()
const vat = useVat()

const {
  formatDate,
  formatDateForSave,
  formatDateTime,
  formatDateTimeForSave,
  UTC_OFFSET,
  dayjs
} = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())
const inputDateFormat = ref('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
import SkeletonText from '@/components/skeleton/SkeletonText.vue'
import useCurrencies from '@/composables/selectData/useCurrencies'
const { getCurrenciesForSelect } = useCurrencies()
const currencies = getCurrenciesForSelect()
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: String,
    default: ''
  }
})

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

// Object that contains all the data
const local = ref({ purchaseOrderItems: [] })

const duplicateActionRow = ref(false)

// To manually enabled the save button
const saveButtonEnabled = ref(false)

// Colspan of summary box and action button
const summaryBoxColSpan = ref(2)
const deletePoLine = async (row, toDelete) => {
  saveButtonEnabled.value = true

  if (toDelete) {
    row.delete = 1
    return
  }

  row.delete = 0
}

const disablePoButtons = computed(() => {
  if (!poItemsGrid.value) {
    return true
  }

  return poItemsGrid.value.selected.length === 0
})

const checkDeleted = (row) => {
  return row.delete == 1
}

/**
 * Formats data returned from api
 */
const handleResponse = (data) => {
  let purchaseOrder = data.purchaseOrder
  let purchaseOrderItems = []
  let packs = data.skuSupplier || []
  suppliers.value = data.suppliers || []
  skuInventory.value = data.skuInventory || []

  data.purchaseOrderItems = setVariantValues(
    data.purchaseOrderItems,
    data.variantValues
  )

  if (data.purchaseOrderItems && data.purchaseOrderItems.length > 0) {
    purchaseOrderItems = _.cloneDeep(data.purchaseOrderItems)
  }

  purchaseOrderItems.forEach((item) => {
    item.packs = packs
      .filter(
        (pack) =>
          pack.tenantSkuId == item.tenantSkuId &&
          pack.unitsPerCase == item.unitsPerCase
      )
      .map((pack) => {
        return {
          m2mSkuSupplierId: pack.m2mSkuSupplierId,
          price: pack.price,
          minOrderQty: pack.minOrderQty
        }
      })
    if (item.packs.length > 0) {
      item.minOrderQty = item.packs.reduce(
        (min, p) => (p.minOrderQty < min ? p.minOrderQty : min),
        item.packs[0].minOrderQty
      )
    } else {
      item.minOrderQty = 0
    }

    item.linePrice = getLinePrice(item)
    item.lineVat = getLineVat(item)
    item.lineTotal = getLineTotal(item)
  })

  local.value = {
    tenantPurchaseOrderId: purchaseOrder.tenantPurchaseOrderId,
    poNumber: purchaseOrder.poNumber,
    tenantSupplierId: purchaseOrder.tenantSupplierId,
    supplierName: purchaseOrder.supplierName,
    status: purchaseOrder.status,
    internalNotes: purchaseOrder.internalNotes,
    poNotes: purchaseOrder.poNotes,
    currency: purchaseOrder.currency,
    totalUnits: purchaseOrder.totalUnits,
    paymentTerms: purchaseOrder.paymentTerms,
    accountNumber: purchaseOrder.accountNumber,
    minOrderValue: purchaseOrder.minOrderValue || null,
    deliveryCharge: purchaseOrder.deliveryCharge || 0,
    deliveryChargeVAT: purchaseOrder.deliveryChargeVatPercent || 0,
    deliveryChargeVATAmount: purchaseOrder.deliveryChargeVatAmount || 0,
    approvedAt: formatDateTime(
      purchaseOrder.approvedAt,
      utcOffset.value,
      inputDateFormat.value
    ),
    purchaseOrderItems: purchaseOrderItems,
    deliveryLocationId: purchaseOrder.deliveryLocationId,
    billingLocationId: purchaseOrder.billingLocationId,
    totalCost: purchaseOrder.totalCost,
    approvedBy: purchaseOrder.approvedBy,
    createdAt: formatDateTime(
      purchaseOrder.createdAt,
      utcOffset.value,
      inputDateFormat.value
    ),
    createdBy: purchaseOrder.createdBy,
    updatedAt: formatDateTime(
      purchaseOrder.updatedAt,
      utcOffset.value,
      inputDateFormat.value
    ),
    updatedBy: purchaseOrder.updatedBy,
    archivedAt: formatDateTime(
      purchaseOrder.archivedAt,
      utcOffset.value,
      inputDateFormat.value
    ),
    archivedBy: purchaseOrder.archivedBy,
    expectedDate: formatDateForSave(purchaseOrder.expectedDate, 'YYYY/MM/DD'),
    paidBy: purchaseOrder.paidBy,
    paidAt: formatDateTime(
      purchaseOrder.paidAt,
      utcOffset.value,
      inputDateFormat.value
    )
  }

  if (!local.value.deliveryLocationId && locations.value.length === 1) {
    local.value.deliveryLocationId = locations.value[0].tenantLocationId
  }

  updateTotalLinePrice()
  updateDeliveryCharge()
  updatePoTotal()
  updateTotalReceivedPrice()

  remote.value = JSON.parse(JSON.stringify(local.value))
}

// Template ref to validation provider component
const validation = ref(null)

/*
 *****************      MODALS
 */
import { createPdf } from '@/composables/pdfs/pdf'
import ReceiveStockModal from '@/components/modals/purchaseOrders/ReceiveStockModal'
import ReceiveMultipleSkusModal from '@/components/modals/ReceiveMultipleSkusModal'
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'
import CancelMultipleSkusModal from '@/components/modals/purchaseOrders/CancelMultipleSkusModal'
import BackorderMultipleSkusModal from '@/components/modals/purchaseOrders/BackorderMultipleSkusModal'

const receiveStockModal = ref(null)
const confirmLeaveModal = ref(null)
const confirmModalNext = ref(null)
const cancelMultipleModal = ref(null)
const backorderMultipleModal = ref(null)
const skuPoModal = ref(null)
const outstandingPoItemsModal = ref(null)
const receiveMultipleModal = ref(null)
const multiSelectModalLocation = ref()
const showModal = ref(false)
const modalStatus = ref(false)
const multiSelectModalItems = ref([])
const actionSelect = ref(null)
const receiveMultipleData = ref({ items: [] })
const skuPoModalGrid = ref(null)

const totalSkuPoCount = ref(0)
const defaultSkuPoSort = ref({ sku: 1 })
const receiveMultipleRows = ref([])
const newPoSkus = ref([])

const skuPoRows = ref([])
const skuPoCols = ref([
  {
    label: 'SKU',
    name: 'sku',
    filter: true,
    visible: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    filter: true,
    visible: true
  },
  {
    label: 'Brand',
    name: 'brandName',
    filter: true,
    visible: true
  },
  {
    label: 'Title',
    name: 'title',
    filter: true,
    visible: true
  },
  {
    label: 'Variants',
    name: 'variant',
    visible: true
  },
  {
    label: 'To receive',
    name: 'qtyOutstanding',
    visible: true,
    width: '150px'
  }
])

const performAction = async () => {
  switch (true) {
    case action.value === 'RECEIVE':
      await openReceiveMultipleModal()
      break
    case /^RECEIVE_LOCATION_([0-9]+)$/.test(action.value):
      let option =
        actionSelect.value.options[actionSelect.value.options.selectedIndex]
      let locationId = option.dataset.locationId
      let locationName = option.dataset.locationName
      if (!locationId) {
        break
      }
      receiveMultipleData.value.location = { locationId, locationName }
      await openReceiveMultipleModal()
      break
    case action.value === 'CANCEL':
      await openCancelMultipleModal()
      break
    case action.value === 'BACKORDER':
      await openBackorderMultipleModal()
      break
  }
  action.value = ''
}

const openSkuPoModal = async () => {
  skuPoModalGrid.value.resetFilters(false)
  skuPoModalGrid.value.clearPreviousFilters()
  skuPoModalGrid.value.addToSelected(selectedPoSkuIds.value)
  await getSkuPoRows({ sort: defaultSkuPoSort.value })
  nextTick(skuPoModal.value.show())
  modalStatus.value = true
}

const saveSkuPoModal = () => {
  let index = 0
  let length = skuPoModalGrid.value.selected.length
  let formattedSkus = []

  resetPurchaseOrderItemsIndex()

  let supplier = suppliers.value.find(
    (supplier) => supplier.tenantSupplierId == local.value.tenantSupplierId
  )
  let counter = 0
  for (index; index < length; index++) {
    let m2mSkuSupplierId = skuPoModalGrid.value.selected[index]

    if (!m2mSkuSupplierId) {
      continue
    }

    if (selectedPoSkuIds.value.includes(m2mSkuSupplierId)) {
      continue
    }

    if (newPoSkus.value.includes(m2mSkuSupplierId)) {
      continue
    }

    newPoSkus.value.push(m2mSkuSupplierId)

    let selectedSku = skuPoRows.value.find((row) => {
      return row.m2mSkuSupplierId == m2mSkuSupplierId
    })

    if (!selectedSku) {
      continue
    }

    let vatPercent =
      selectedSku.vatRate == '-1.00'
        ? vat.get(supplier.addressMainCountry)
        : selectedSku.vatRate
    let minOrderQty = selectedSku.packs.reduce(
      (min, p) => (p.minOrderQty < min ? p.minOrderQty : min),
      selectedSku.packs[0].minOrderQty
    )

    let price = selectedSku.price || 0.0
    let formatted = {
      brandName: selectedSku.brandName,
      gtin: selectedSku.gtin || null,
      hsCode: selectedSku.hsCode || null,
      innerCaseSize: selectedSku.innerCaseSize,
      minOrderQty: minOrderQty, //
      mpn: selectedSku.mpn, //
      orderCaseSize: selectedSku.orderCaseSize,
      quantity: 0,
      sku: selectedSku.sku, //
      supplierSku: selectedSku.supplierSku, //
      tenantSkuId: selectedSku.tenantSkuId,
      title: selectedSku.title, //
      price: parseFloat(price).toString(), //
      priceOptions: selectedSku.priceOptions,
      vatOptions: selectedSku.priceVat,
      vatPercent: vatPercent,
      status: 0,
      brandName: selectedSku.brandName,
      variantValue: selectedSku.variant,
      index: local.value.purchaseOrderItems.length + counter,
      quantityOrdered: 0,
      linePrice: 0.0,
      packs: selectedSku.packs,
      unitsPerCase: selectedSku.unitsPerCase || 1,
      variant: selectedSku.variant
    }
    counter++
    formattedSkus.push(formatted)
  }

  local.value.purchaseOrderItems =
    local.value.purchaseOrderItems.concat(formattedSkus)
  skuPoModalGrid.value.resetFilters()
}

const resetPurchaseOrderItemsIndex = () => {
  let length = local.value.purchaseOrderItems.length
  let index = 0
  let counter = 0
  for (index; index < length; index++) {
    if (!local.value.purchaseOrderItems[index].doNotShow) {
      local.value.purchaseOrderItems[index].index = counter
      counter++
    }
  }
}

const closeSkuPoModal = () => {
  skuPoModalGrid.value.resetFilters(false)
  skuPoModal.value.hide()
  modalStatus.value = false
}

const skuPoLoading = ref(false)

const getSkuPoRows = async (args = {}) => {
  skuPoLoading.value = true
  args.purchaseOrder = 1
  args.tenantSupplierId = local.value.tenantSupplierId

  // This IF statement handles the filtering for the sku.
  // The column is called 'name' to account for the added (Pack of 6)
  if (args.filter && args.filter.name) {
    args.filter.sku = args.filter.name
  }

  let response = await api.get('/skus', args)
  skuPoRows.value = setSkuPoVariantValues(
    response.data.skus,
    response.data.variantValues
  )
  totalSkuPoCount.value = parseInt(response.data.count)
  skuPoLoading.value = false
}

const setSkuPoVariantValues = (skus, variantValues) => {
  let index = 0
  let length = skus.length

  for (index; index < length; index++) {
    let sku = skus[index]
    let tenantSkuId = sku.tenantSkuId
    let skuVariantValues = _.filter(variantValues, { tenantSkuId })

    if (sku.backorderPos.length > 0) {
      let filteredPoNumbers = sku.backorderPos.filter(
        (sku) => sku.poNumber != local.value.poNumber
      )
      let poNumbers = filteredPoNumbers.map((po) => po.poNumber)
      let qtyOutstanding = filteredPoNumbers.map(
        (po) => po.quantityOrdered - po.quantityReceived - po.quantityCancelled
      )

      sku.qtyOutstanding = qtyOutstanding.reduce(
        (previous, current) => previous + current,
        0
      )
      if (sku.qtyOutstanding == 0) {
        sku.qtyOutstanding = '-'
      }
      sku.onBackorder = poNumbers.join(', ')
      if (sku.onBackorder == '') {
        sku.onBackorder = '-'
      }
    } else {
      sku.qtyOutstanding = '-'
      sku.onBackorder = '-'
    }

    if (skuVariantValues.length === 0) {
      continue
    }

    let variant1 = skuVariantValues[0]
    let variant2 = skuVariantValues[1]

    if (variant1) {
      sku.variantValues = variant1.en
    }

    if (variant2) {
      if (sku.variantValues) {
        sku.variantValues += ', ' + variant2.en
      } else {
        sku.variantValues = variant2.en
      }
    }
  }

  let grouped = _.groupBy(skus, (r) => {
    return `${r.tenantSkuId}${r.unitsPerCase}`
  })

  let formatted = []
  let current = {}
  Object.values(grouped).forEach((groupedSkus, index) => {
    groupedSkus = groupedSkus.sort((a, b) => a.minOrderQty - b.minOrderQty)
    current = groupedSkus[0]
    current.packs = []
    current.name = groupedSkus[0].sku

    if (groupedSkus[0].stockOptions == 'perPack') {
      current.name += ` (Pack of ${groupedSkus[0].unitsPerCase})`
    }

    groupedSkus.forEach((record) => {
      let pack = {
        minOrderQty: record.minOrderQty,
        price: record.price,
        m2mSkuSupplierId: record.m2mSkuSupplierId
      }
      current.packs.push(pack)
    })
    formatted.push(current)
  })

  return formatted
}

const cancel = async () => {
  await openCancelMultipleModal()
}

const openRsmModal = (tenantPurchaseOrderItemId) => {
  let item = local.value.purchaseOrderItems.find((item) => {
    return item.tenantPurchaseOrderItemId === tenantPurchaseOrderItemId
  })

  if (!item) {
    return
  }

  let inventory = skuInventory.value.filter(
    (inventory) => inventory.skuId == item.tenantSkuId
  )

  item = JSON.parse(JSON.stringify(item))
  inventory = JSON.parse(JSON.stringify(inventory))

  receiveStockModal.value.show(item, inventory)
}

const openReceiveMultipleModal = () => {
  receiveMultipleData.value.items = []

  let index = 0
  let length = poItemsGrid.value.selected.length

  for (index; index < length; index++) {
    let sku = poItemsGrid.value.selected[index].sku
    let purchaseOrderItem = _.find(local.value.purchaseOrderItems, {
      sku: sku
    })

    if (!purchaseOrderItem) {
      continue
    }

    if (!purchaseOrderItem.tenantPurchaseOrderItemId) {
      continue
    }

    let item = purchaseOrderItem
    let quantityOrdered = purchaseOrderItem.quantityOrdered || 0
    let quantityReceived = purchaseOrderItem.quantityReceived || 0
    let quantityCancelled = purchaseOrderItem.quantityCancelled || 0

    let quantityToReceive =
      parseInt(quantityOrdered) -
      (parseInt(quantityReceived) + parseInt(quantityCancelled))

    item.quantityToReceive = quantityToReceive < 0 ? 0 : quantityToReceive

    receiveMultipleData.value.items.push(item)
  }

  receiveMultipleModal.value.show()
}

const closeReceiveMultipleModal = () => {
  receiveMultipleData.value = { items: [] }
}

const openCancelMultipleModal = () => {
  let index = 0
  let length = poItemsGrid.value.selected.length

  for (index; index < length; index++) {
    let lineItem = poItemsGrid.value.selected[index]
    let tenantPurchaseOrderItemId = lineItem.tenantPurchaseOrderItemId
    let purchaseOrderItem = _.find(local.value.purchaseOrderItems, {
      tenantPurchaseOrderItemId
    })

    if (!purchaseOrderItem) {
      continue
    }

    let item = purchaseOrderItem
    let quantityOrdered = purchaseOrderItem.quantityOrdered || 0
    let quantityReceived = purchaseOrderItem.quantityReceived || 0
    let quantityCancelled = purchaseOrderItem.quantityCancelled || 0

    item.quantityToCancel =
      parseInt(quantityOrdered) -
      (parseInt(quantityReceived) + parseInt(quantityCancelled))

    multiSelectModalItems.value.push(item)
  }

  cancelMultipleModal.value.show()
}

const closeCancelMultipleModal = () => {
  multiSelectModalItems.value = []
  cancelMultipleModal.value.hide()
}

const openBackorderMultipleModal = () => {
  let index = 0
  let length = selected.value.length

  for (index; index < length; index++) {
    let sku = selected.value[index]
    let purchaseOrderItem = _.find(local.value.purchaseOrderItems, {
      sku: sku
    })
    if (!purchaseOrderItem) {
      continue
    }

    let item = JSON.parse(JSON.stringify(purchaseOrderItem))
    item.backorder = 1

    multiSelectModalItems.value.push(item)
  }

  backorderMultipleModal.value.show()
}

const closeBackorderMultipleModal = (items) => {
  if (items) {
    updatePurchaseOrderItems(items)
  }
  multiSelectModalItems.value = []
  backorderMultipleModal.value.hide()
}

const closeReceiveStockModal = () => {
  receiveStockModal.value.hide()
}

/**
 *****************      TABLE FORM
 */
import TableForm from '@/components/table/form/TableForm'
import RowContainer from '@/components/table/form/RowContainer'
import TableRow from '@/components/table/form/TableRow'

const actionOptions = ref([])
const suppliers = ref([])
const locations = ref([])
const defaultLocationId = ref(null)
const locationOptions = ref([])
const skuInventory = ref([])
const saving = ref(false)
const purchaseOrderId = ref(false)

const remote = ref({})
const selected = ref([])
const allLinesSelected = ref(0)
const action = ref('')

const canOrderUnits = (row) => {
  return row.orderByUnits
}

const canOrderCases = (row) => {
  return row.orderByCase
}

const editable = computed(() => {
  return local.value.approvedAt == null && local.value.archivedAt == null
})

const updateOrderQuantity = (row) => {
  row.quantityOrdered = row.casesOrdered * row.unitsPerCase

  let packs = row.packs.sort((a, b) => a.minOrderQty - b.minOrderQty)
  let length = packs.length
  let index = length - 1

  for (index; index >= 0; index--) {
    let pack = packs[index]
    if (pack.minOrderQty <= row.quantityOrdered) {
      row.price = pack.price
      row.m2mSkuSupplierId = pack.m2mSkuSupplierId
      break
    }
  }

  row.linePrice = getLinePrice(row)
  row.lineVat = getLineVat(row)
  row.lineTotal = getLineTotal(row)

  updateTotalLinePrice()
  updateDeliveryCharge()
  updatePoTotal()
  updateTotalReceivedPrice()
}

const tabs = ref(['Purchase Order', 'History'])

const purchaseOrderItemCols = [
  {
    label: 'SKU',
    name: 'sku',
    visible: true,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true
  },
  {
    label: 'Title',
    name: 'title',
    visible: true
  },
  {
    label: 'Supplier SKU',
    name: 'supplierSku'
  },
  {
    label: 'MPN',
    name: 'mpn'
  },
  {
    label: 'Variants',
    name: 'variantValue',
    visible: true
  },
  {
    label: 'Qty to order',
    name: 'casesOrdered',
    visible: true,
    number: true,
    hideZero: true,
    input: true,
    inputType: 'number',
    width: 200,
    change: updateOrderQuantity
  },
  {
    label: 'Case Size',
    name: 'unitsPerCase',
    visible: true,
    number: true,
    width: 70
  },
  {
    label: 'Price',
    name: 'price',
    visible: true,
    number: true,
    width: 70
  },
  {
    label: 'Price By',
    name: 'priceOptions',
    visible: true,
    multiple: true,
    displayValues: {
      perPack: 'Case',
      perUnit: 'Unit'
    },
    width: 70
  },
  {
    label: 'Min Qty',
    name: 'minOrderQty',
    visible: true,
    hideZero: true,
    number: true,
    width: 70
  },
  {
    label: 'Ordered',
    name: 'quantityOrdered',
    visible: true,
    number: true,
    hideZero: true,
    width: 70
  },
  {
    label: 'Received',
    name: 'quantityReceived',
    visible: true,
    number: true,
    hideZero: true,
    width: 70
  },
  {
    label: 'Cancelled',
    name: 'quantityCancelled',
    visible: true,
    number: true,
    hideZero: true,
    width: 70
  },
  {
    label: 'Expected Date',
    name: 'expectedDate',
    visible: true,
    input: true,
    onClick: true,
    inputType: 'date',
    width: 100
  },
  {
    label: 'Comments',
    name: 'comments',
    visible: true,
    input: true,
    inputType: 'text'
  },
  {
    label: 'Item Total',
    name: 'linePrice',
    visible: true,
    number: true,
    width: 70
  },
  {
    label: 'VAT',
    name: 'lineVat',
    visible: true,
    number: true,
    width: 70
  },
  {
    label: 'Total',
    name: 'lineTotal',
    visible: true,
    number: true,
    width: 70
  }
]

/**
 * This is the amount viewed by the user (percentage out of 100)
 */
const deliveryChargeVATPercent = computed(() => {
  return local.value.deliveryChargeVAT * 100
})

/**
 * When the delivery charge is updated, deliveryChargeVATAmount is updated
 */
watch(
  () => local.value.deliveryCharge,
  (current, previous) => {
    local.value.deliveryChargeVATAmount =
      current * local.value.deliveryChargeVAT
  }
)

/**
 * Updates deliveryChargeVAT
 */
const updateDeliveryChargeVAT = (data) => {
  let newVatPercent = data.value / 100
  local.value.deliveryChargeVAT = newVatPercent
  local.value.deliveryChargeVATAmount =
    newVatPercent * local.value.deliveryCharge
}

const getPoItemProblem = (col, row) => {
  let warning = getPoItemWarning(col, row)

  return warning ? true : false
}

const getPoItemProblemText = (col, row) => {
  let warning = getPoItemWarning(col, row)
  switch (warning) {
    case 'MIN_QTY':
      return 'Quantity ordered is lower than minimum order quantity'

    case 'UNITS_PER_CASE':
      return 'Quantity ordered is not a multiple of case size'

    case 'NOT_RECEIVED':
      return 'All stock not received by expected date'

    default:
      return ''
  }
}

const getPoItemWarning = (col, row) => {
  if (row.minOrderQty > row.quantityOrdered) {
    return 'MIN_QTY'
  }

  if (row.unitsPerCase && row.quantityOrdered % row.unitsPerCase !== 0) {
    return 'UNITS_PER_CASE'
  }

  if (
    row.quantityReceived < row.quantityOrdered &&
    dayjs().isAfter(row.expectedDate)
  ) {
    return 'NOT_RECEIVED'
  }
}

const receivedAllItems = (col, row) => {
  return row.quantityOrdered - row.quantityReceived - row.quantityCancelled <= 0
}

const purchaseOrderItemInfoBlocks = ref({
  sku: [
    {
      type: 'Success',
      show: true,
      hoverText: () => 'All stock has been received',
      condition: receivedAllItems,
      onClick: () => {}
    },
    {
      type: 'Warning',
      show: true,
      hoverText: getPoItemProblemText,
      condition: getPoItemProblem,
      onClick: (col, row) => openOutstandingPoItemModal(row)
    }
  ]
})

const updatePoItem = _.debounce((item) => {
  local.value.purchaseOrderItems.map((poItem) => {
    if (poItem.tenantPurchaseOrderItemId != item.tenantPurchaseOrderItemId) {
      return
    }
    for (let [key, value] of Object.entries(item)) {
      if (poItem[key] != value) {
        poItem.key = value
      }
    }
  })
  saveButtonEnabled.value = true
}, 250)

/**
 * This is to store triggered/called functions from a column as it doesn't stringify
 */
const columnFunctions = {
  comments: {
    input: true,
    displayCheck: () => true,
    change: updatePoItem
  },
  expectedDate: {
    input: editable,
    displayCheck: () => true,
    change: updatePoItem
  },
  casesOrdered: {
    input: true,
    displayCheck: () => true,
    change: updateOrderQuantity
  }
}

const buttonText = computed(() => {
  switch (true) {
    case action.value === 'RECEIVE':
      return 'Receive stock'
    case /^RECEIVE_LOCATION_([0-9]+)$/.test(action.value):
      return 'Check in to location'
    case action.value === 'BACKORDER':
      return 'Put on Backorder'
    case action.value === 'CANCEL':
      return 'Cancel Stock'
    default:
      return 'Purchase Order Action'
  }
})

const deliveryAddressOptions = computed(() => {
  return locations.value.map((location) => {
    return {
      value: location.tenantLocationId,
      label: `${location.name} ( ${location.address1 || ''}
      ${location.address2 || ''}
      ${location.address3 || ''}
      ${location.addressPostcode || ''}
      )`
    }
  })
})

const updateTotalLinePrice = () => {
  let float = local.value.purchaseOrderItems
    .map((item) => {
      if (item.delete !== 1) {
        return parseFloat(item.linePrice)
      }
      return 0
    })
    .reduce((prev, curr) => prev + curr, 0)
  local.value.totalItemPrice = float.toFixed(2)
}

const updateDeliveryCharge = () => {
  let deliveryCharge = local.value.deliveryCharge || '0.0'
  let deliveryChargeVAT = local.value.deliveryChargeVAT || '0.0'
  deliveryCharge = parseFloat(deliveryCharge)
  deliveryChargeVAT = parseFloat(deliveryChargeVAT)
  deliveryCharge += deliveryChargeVAT * deliveryCharge

  local.value.totalDeliveryCharge = deliveryCharge.toFixed(2)
}

const updatePoTotal = () => {
  let float =
    parseFloat(local.value.totalItemPrice) +
    parseFloat(local.value.totalDeliveryCharge)
  local.value.totalPoPrice = float.toFixed(2)
}

const updateTotalReceivedPrice = () => {
  let index = 0
  let data = local.value.purchaseOrderItems
  let length = data.length
  let totalPrice = 0.0

  for (index; index < length; index++) {
    let item = data[index]
    let linePrice = getReceivedLinePrice(item)
    totalPrice += parseFloat(linePrice)
  }

  local.value.totalReceivedPrice = totalPrice.toFixed(2)
}

const getReceivedLinePrice = (item) => {
  if (!item.quantityReceived || !item.price) {
    return 0.0
  }

  let quantityReceived = item.quantityReceived || 0
  let price = item.price || 0.0

  let linePrice

  if (item.priceOptions == 'perPack') {
    linePrice =
      (parseInt(quantityReceived) / item.unitsPerCase) * parseFloat(price)
  } else {
    linePrice = item.quantityReceived * item.price
  }
  return linePrice.toFixed(2)
}

/**
 * This is the array of sku ids within the PO
 */
const selectedPoSkuIds = computed(() => {
  let index = 0
  let data = local.value.purchaseOrderItems
  let length = data.length
  let ids = []

  for (index; index < length; index++) {
    let item = data[index]
    let m2mSkuSupplierId = item.m2mSkuSupplierId
    if (ids.indexOf(m2mSkuSupplierId) !== -1) {
      continue
    }
    ids.push(m2mSkuSupplierId)
  }
  return ids
})

// Tried to do this by exposing active tab in the TableTab
// but couldn't get it to work, so I've done it this hacky way
// instead
const selectedTab = ref()
const tabManager = ref(null)
watchEffect(() => {
  if (tabManager.value) {
    selectedTab.value = tabManager.value.tab
  }
})

const totalPoUnits = computed(() => {
  let index = 0
  let data = local.value.purchaseOrderItems
  let length = data.length
  let totalUnits = 0

  for (index; index < length; index++) {
    let item = data[index]
    if (item.delete == 1) {
      continue
    }
    let quantityOrdered = item.quantityOrdered || 0
    totalUnits += quantityOrdered
  }

  return totalUnits
})

const getPurchaseOrderId = () => {
  purchaseOrderId.value = false

  if (props.id && parseInt(props.id) > 0) {
    purchaseOrderId.value = props.id
  }
}

const getLocations = async () => {
  let locationData = await api.getLocations()
  locations.value = locationData.filter(
    (location) => location.managedLocation == 0
  )
  let defaultLocation = locationData.find(
    (location) => location.isDefaultDelivery == 1
  )
  defaultLocationId.value = defaultLocation.tenantLocationId
  local.value.deliveryLocationId = defaultLocationId.value
}

const setTitle = () => {
  let pageTitle = local.value.poNumber || 'Create purchase order'
  document.getElementById('pagetitle').innerHTML = pageTitle
  document.title = pageTitle
}

const getPurchaseOrder = async () => {
  if (!purchaseOrderId.value || purchaseOrderId.value === 'new') {
    setDefaultDeliveryLocation()
    return
  }

  let response = await api.get('/purchaseOrders/' + purchaseOrderId.value)
  handleResponse(response.data)
}

const getData = async () => {
  loading()
  try {
    progressBarStart()

    getPurchaseOrderId()
    await getLocations()
    await getPurchaseOrder()
    createLocationOptions()
    setTitle()
    loaded()
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
    router.back()
  }
}

getData()

const routeRefreshOnMounted = ref(false)
onUpdated(() => {
  if (
    routeRefreshOnMounted.value &&
    router.currentRoute.value.params.id == 'new'
  ) {
    router.go()
  }
  routeRefreshOnMounted.value = true
})

const createLocationOptions = () => {
  locationOptions.value = locations.value.map((location) => {
    return {
      VALUE: location.tenantLocationId,
      LABEL: `${location.name} - (${location.address1}, ${location.address2} ${location.address3} ${location.addressPostcode})`
    }
  })
}

const updatePurchaseOrder = (purchaseOrder) => {
  ;(purchaseOrder.createdAt = formatDateTime(
    purchaseOrder.createdAt,
    utcOffset.value
  )),
    (purchaseOrder.approvedAt = formatDateTime(
      purchaseOrder.approvedAt,
      utcOffset.value
    )),
    (purchaseOrder.updatedAt = formatDateTime(
      purchaseOrder.updatedAt,
      utcOffset.value
    )),
    (purchaseOrder.archivedAt = formatDateTime(
      purchaseOrder.archivedAt,
      utcOffset.value
    )),
    (purchaseOrder.paidAt = formatDateTime(
      purchaseOrder.paidAt,
      utcOffset.value
    )),
    (purchaseOrder.expectedDate = formatDate(
      purchaseOrder.expectedDate,
      utcOffset.value
    )),
    (local.value = Object.assign({}, local.value, purchaseOrder))
  remote.value = Object.assign({}, remote.value, purchaseOrder)
}

const receivedInFull = (row) => {
  let quantityCancelled = row.quantityCancelled || 0
  let quantityReceived = row.quantityReceived || 0
  let quantityOrdered = row.quantityOrdered || 0

  if (!row.tenantPurchaseOrderItemId) {
    return false
  }

  return (
    parseInt(quantityCancelled) + parseInt(quantityReceived) >=
    parseInt(quantityOrdered)
  )
}

const poPdf = async (type) => {
  if (!local.value.deliveryLocationId) {
    alert('You cannot generate a PDF without a delivery address')
    return
  }
  let data = JSON.parse(JSON.stringify(local.value))
  data.purchaseOrderItems = _.sortBy(data.purchaseOrderItems, 'sku')
  data = formatDataForPdf(data)
  const tenant = await getCompanyData()
  let response = await api.get('/files/download-logo', {
    fileName: tenant.logoName
  })
  tenant.logoUrl = response.data.url || null
  createPdf(locations.value, tenant, data, type)
}

const formatDataForPdf = (data) => {
  let itemTotal = 0
  let vatTotal = 0
  data.purchaseOrderItems.forEach((item) => {
    itemTotal += item.linePrice * 100
    vatTotal += item.lineVat * 1000
  })

  data.itemTotal = (itemTotal / 100).toFixed(2)
  data.vatTotal = (vatTotal / 1000).toFixed(2)
  data.poTotal = ((itemTotal * 10 + vatTotal) / 1000).toFixed(2)
  return data
}

const getCompanyData = async () => {
  let response = await api.get('/tenant')
  return response.data.tenant
}

const checkMouseAction = (e) => {
  const isTextHighlighting = window.getSelection().toString().trim() !== ''

  if (!isTextHighlighting) {
    e.target.parentElement.click()
  }
}

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
  confirmModalNext()
}

const closeConfirmModal = () => {
  confirmModalNext.value = null
  confirmLeaveModal.value.hide()
}

const cancelRemoveLine = (index) => {
  local.value.purchaseOrderItems[index].delete = null
}

const removeLine = (row, index) => {
  if (!row.tenantPurchaseOrderItemId) {
    local.value.purchaseOrderItems.splice(index, 1)
    return
  }

  local.value.purchaseOrderItems[index].delete = 1
}

const updatePurchaseOrderItems = (items) => {
  if (!items || items.length === 0) {
    return
  }

  let index = 0
  let length = items.length

  for (index; index < length; index++) {
    let item = items[index]
    let tenantPurchaseOrderItemId = item.tenantPurchaseOrderItemId
    let vmIndex = _.findIndex(local.value.purchaseOrderItems, {
      tenantPurchaseOrderItemId
    })

    if (vmIndex === -1) {
      return
    }

    local.value.purchaseOrderItems[vmIndex] = item
  }
}

const getLinePrice = (item) => {
  if (!item.quantityOrdered || !item.price) {
    return 0.0
  }

  let quantityOrdered = item.quantityOrdered || 0
  let price = item.price || 0.0

  let linePrice

  if (item.priceOptions == 'perPack') {
    linePrice =
      (parseInt(quantityOrdered) / item.unitsPerCase) * parseFloat(price)
  } else {
    linePrice = parseInt(quantityOrdered) * parseFloat(price)
  }

  return linePrice.toFixed(2)
}

const getLineVat = (item) => {
  let linePrice = item.linePrice * 100
  let vat = item.vatPercent * 1000

  let lineVAT = (linePrice * vat) / 100000

  return lineVAT.toFixed(2)
}

const getLineTotal = (item) => {
  let total = (item.linePrice * 100 + item.lineVat * 100) / 100

  return total.toFixed(2)
}

const searchSuppliers = (search, loading) => {
  if (search !== '') {
    getSuppliers(search, loading)
  }
}

const updateSupplier = (tenantSupplierId) => {
  let supplier = suppliers.value.find(
    (supplier) => supplier.tenantSupplierId == tenantSupplierId
  )
  local.value.deliveryCharge = supplier.deliveryCharge || '0.0'
  local.value.deliveryChargeVAT = supplier.deliveryChargeVatPercent || '0.0'
  local.value.currency = supplier.currency
  local.value.accountNumber = supplier.accountNumber
  local.value.paymentTerms = supplier.paymentTerms
  local.value.minOrderValue = supplier.minOrderValue
}

const setDefaultDeliveryLocation = () => {
  let defaultDeliveryLocation = _.find(locations.value, {
    isDefaultDelivery: 1
  })

  if (!defaultDeliveryLocation) {
    return
  }

  local.value.deliveryLocationId = defaultDeliveryLocation.tenantLocationId
}

const setVariantValues = (purchaseOrderItems, variantValues) => {
  let index = 0
  let length = purchaseOrderItems.length

  let counter = 0
  for (index; index < length; index++) {
    let purchaseOrderItem = purchaseOrderItems[index]

    let existingItem = local.value.purchaseOrderItems.filter(
      (item) => item.sku == purchaseOrderItem.sku
    )
    if (existingItem.length > 0) {
      purchaseOrderItem.index = existingItem[0].index
    } else {
      purchaseOrderItem.index = local.value.purchaseOrderItems.length + counter
      counter++
    }

    let tenantSkuId = purchaseOrderItem.tenantSkuId
    let skuVariantValues = _.filter(variantValues, { tenantSkuId })
    purchaseOrderItem.expectedDate = purchaseOrderItem.expectedDate
      ? formatDateForSave(purchaseOrderItem.expectedDate, utcOffset.value)
      : null

    if (skuVariantValues.length === 0) {
      continue
    }

    let variant1 = skuVariantValues[0]
    let variant2 = skuVariantValues[1]
    purchaseOrderItem.variantValue = ''

    if (variant1) {
      purchaseOrderItem.variantValue += `${variant1.en} `
    }

    if (variant2) {
      purchaseOrderItem.variantValue += variant2.en
    }
  }

  purchaseOrderItems.sort((previous, current) => previous.index - current.index)
  return purchaseOrderItems
}

const removeDeletedItemsFromView = () => {
  let index = 0
  let length = local.value.purchaseOrderItems.length

  let counter = 0
  for (index; index < length; index++) {
    let item = local.value.purchaseOrderItems[index]
    if (item.delete == 1 || item.deletedBy != null) {
      local.value.purchaseOrderItems[index].doNotShow = 1
      counter++
    }
  }
}

const gotoPurchaseOrders = () => {
  router.push('/purchase-orders/')
}

const unarchivePurchaseOrder = async () => {
  let response = await api.put(
    '/purchaseOrders/' + purchaseOrderId.value,
    { unarchive: 1, tenantPurchaseOrderId: purchaseOrderId.value },
    saving
  )
  let purchaseOrder = response.data.purchaseOrder
  updatePurchaseOrder(purchaseOrder)
}

const receive = async () => {
  await openReceiveMultipleModal()
}

const receiveLocation = async (location) => {
  if (!location.locationId) return
  multiSelectModalLocation.value = location
  await openReceiveMultipleModal()
}

const formatValuesForSave = () => {
  local.value.totalCost = local.value.totalPoPrice
  local.value.totalUnits = totalPoUnits.value
  if (local.value.paidAt) {
    local.value.paidAt = formatDateTimeForSave(
      local.value.paidAt,
      utcOffset.value
    )
  }
  if (local.value.archivedAt) {
    local.value.archivedAt = formatDateTimeForSave(
      local.value.archivedAt,
      utcOffset.value
    )
  }
  if (local.value.expectedDate) {
    local.value.expectedDate = formatDateForSave(local.value.expectedDate)
  }
  if (local.value.approvedAt) {
    local.value.approvedAt = formatDateTimeForSave(
      local.value.approvedAt,
      utcOffset.value
    )
  }
  local.value.purchaseOrderItems.forEach((item) => {
    item.expectedDate = formatDateForSave(item.expectedDate, utcOffset.value)
  })
}

const update = async () => {
  formatValuesForSave()

  if (!local.value.deliveryLocationId) {
    alert('Please enter the delivery address on save')
    return
  }

  if (!purchaseOrderId.value) {
    insert()
    return
  }

  let response = await api.put(
    '/purchaseOrders/' + purchaseOrderId.value,
    {
      purchaseOrder: {
        ...local.value,
        deliveryChargeVatPercent: local.value.deliveryChargeVAT,
        deliveryChargeVatAmount: local.value.deliveryChargeVATAmount
      }
    },
    saving
  )

  handleResponse(response.data)
  validation.value.save()
  removeDeletedItemsFromView()
  saveButtonEnabled.value = false
}

const insert = async () => {
  if (local.value.tenantSupplierId == null) {
    return
  }

  let response = await api.post(
    '/purchaseOrders/',
    { purchaseOrder: local.value },
    saving
  )
  purchaseOrderId.value = String(response.data.insertedId)
  router.replace('/purchase-orders/' + purchaseOrderId.value)
  handleResponse(response.data)
  setTitle()
}

const markAsPaid = async () => {
  let response = await api.put(`/purchaseOrders/${purchaseOrderId.value}`, {
    tenantPurchaseOrderId: purchaseOrderId.value,
    markAsPaid: 1
  })
  let purchaseOrder = response.data.purchaseOrder
  updatePurchaseOrder(purchaseOrder)
}

const removePoApproval = async () => {
  let response = await api.put(
    '/purchaseOrders/' + purchaseOrderId.value,
    { removeApproval: 1, tenantPurchaseOrderId: purchaseOrderId.value },
    saving
  )
  let purchaseOrder = response.data.purchaseOrder
  updatePurchaseOrder(purchaseOrder)
}

const approvePO = async () => {
  let response = await api.put(
    '/purchaseOrders/' + purchaseOrderId.value,
    {
      tenantPurchaseOrderId: purchaseOrderId.value,
      approve: 1,
      totalCost: local.value.totalPoPrice,
      totalUnits: totalPoUnits.value
    },
    saving
  )
  let purchaseOrder = response.data.purchaseOrder
  updatePurchaseOrder(purchaseOrder)
}

const archivePurchaseOrder = async () => {
  let response = await api.put(
    '/purchaseOrders/' + purchaseOrderId.value,
    { archive: 1, tenantPurchaseOrderId: purchaseOrderId.value },
    saving
  )
  let purchaseOrder = response.data.purchaseOrder
  updatePurchaseOrder(purchaseOrder)
}

const outstandingPoItems = ref({
  rows: [],
  sku: ''
})
const outstandingPoItemCols = ref([
  {
    label: 'PO Number',
    name: 'poNumber',
    visible: true,
    onClick: {
      route: '/purchase-orders/',
      id: 'tenantPurchaseOrderId'
    }
  },
  {
    label: 'Status',
    name: 'status',
    visible: true,
    multiple: true,
    displayValues: {
      0: 'Draft',
      1: 'Awaiting approval',
      2: 'Sent to supplier',
      3: 'Received'
    }
  },
  {
    label: 'Ordered',
    name: 'quantityOrdered',
    visible: true
  },
  {
    label: 'Received',
    name: 'quantityReceived',
    visible: true
  }
])
const outstandingPoItemInfoBlocks = ref({
  poNumber: [
    {
      type: 'Info',
      hoverText: () => 'Current Purchase Order',
      condition: (col, row) =>
        row.tenantPurchaseOrderId == purchaseOrderId.value,
      onClick: (col, row) => {}
    }
  ]
})
const openOutstandingPoItemModal = async (row) => {
  let tenantSkuId = row.tenantSkuId
  let response = await api.get(`/purchaseOrderItems/getOutstandingPoItems`, {
    tenantSkuId: tenantSkuId
  })
  let items = response.data.purchaseOrderItems
  items.forEach((item) => {
    if (item.quantityReceived == 0 || item.quantityReceived == null) {
      item.quantityReceived = '-'
    }
  })
  outstandingPoItems.value.rows = items
  outstandingPoItems.value.sku = row.sku

  if (items.length > 0) {
    nextTick(outstandingPoItemsModal.value.show())
    modalStatus.value = true
  }
}

const handleError = (value) => {
  console.log(value)
}

const getSuppliers = _.debounce(async (searchValue, loading) => {
  if (!searchValue) {
    return
  }

  loading(true)
  let response = await api.get('/suppliers/', {
    filter: { name: searchValue }
  })
  suppliers.value = response.data.suppliers.map((supplier) => {
    supplier.label = supplier.name
    supplier.value = supplier.tenantSupplierId
    return supplier
  })
  loading(false)
}, 250)

/**
 * COLUMN PREFS
 */
// Template ref to the ColumnPreferences component
const preferences = ref()
const poItemsGrid = ref()

// Shows the column preference modal
const showColumnSettings = () => {
  preferences.value.show()
}

const itemsCols = ref()

const localStorageName = ref('braidPoItems')
import { columnPreferences } from '@/composables/columnPreferences'
const {
  readColumnPreferences,
  readAlertIconPreferences,
  assignColumnFunctions,
  toggleAlertIcons,
  updateAlertIcons
} = columnPreferences(localStorageName.value)

const _toggleAlertIcons = (type) => {
  toggleAlertIcons(type)
  for (let key of Object.keys(purchaseOrderItemInfoBlocks.value)) {
    purchaseOrderItemInfoBlocks.value[key].forEach((alert) => {
      if (alert.type == type) {
        alert.show = !alert.show
      }
    })
  }
}

const getPurchaseOrderItemColumns = () => {
  readColumnPreferences(purchaseOrderItemCols)
  itemsCols.value = assignColumnFunctions(columnFunctions)
  purchaseOrderItemInfoBlocks.value = updateAlertIcons(
    purchaseOrderItemInfoBlocks.value
  )
}

getPurchaseOrderItemColumns()

const updateColumns = (cols) => {
  itemsCols.value = cols
}

const resyncPrices = async () => {
  let response = await api.get('/purchaseOrderItems/price-resync', {
    tenantPurchaseOrderId: purchaseOrderId.value
  })

  let m2mSkuSupplierRecords = response.data?.m2mSkuSupplierRecords || []

  let index = 0
  let length = local.value.purchaseOrderItems.length
  for (index; index < length; index++) {
    let purchaseOrderItem = local.value.purchaseOrderItems[index]
    let relevantM2mRecord = m2mSkuSupplierRecords.find((record) => {
      return record.m2mSkuSupplierId == purchaseOrderItem.m2mSkuSupplierId
    })

    if (!relevantM2mRecord) {
      continue
    }

    purchaseOrderItem.price = relevantM2mRecord.price
  }
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  },
  {
    id: 2,
    label: 'Mark as fully paid',
    click: markAsPaid,
    icon: ['fas', 'check-double']
  },
  {
    id: 3,
    label: 'Approve',
    click: approvePO,
    icon: ['fas', 'check']
  },
  {
    id: 4,
    label: 'Make purchase order editable',
    click: removePoApproval,
    icon: ['fas', 'pen']
  },
  {
    id: 5,
    label: 'Unarchive',
    click: unarchivePurchaseOrder,
    icon: ['fas', 'arrow-rotate-left']
  },
  {
    id: 6,
    label: 'Archive',
    click: archivePurchaseOrder,
    icon: ['fas', 'box-archive']
  },
  {
    id: 7,
    label: 'View PDF',
    click: poPdf,
    icon: ['fas', 'file-pdf']
  },
  {
    id: 8,
    label: 'Download PDF',
    click: () => poPdf('download'),
    icon: ['fas', 'file-arrow-down']
  },
  {
    id: 9,
    label: 'Column Settings',
    click: showColumnSettings,
    icon: ['fas', 'gear']
  },
  {
    id: 10,
    label: 'Re-sync prices',
    click: resyncPrices,
    icon: ['fas', 'refresh']
  }
])

/*
  HISTORY
*/
watch(selectedTab, async (current, previous) => {
  switch (current) {
    case 'History':
      if (!loadedHistory.value) {
        await getHistoryRows()
        loadedHistory.value = true
      }
      break

    case 'Purchase Order':
      nextTick(() => poItemsGrid.value.updateRows())
      break
  }
})

/**
 * Calucaltes the number of audit history records
 */
const totalHistoryPoCount = computed(() => {
  return purchaseOrderAudit.value.length
})
const loadedHistory = ref(false)
const historyPoRows = ref([])
const purchaseOrderAudit = ref([])
const purchaseOrderAuditCols = ref([
  {
    label: 'Currency',
    name: 'currency'
  },
  {
    label: 'PO Number',
    name: 'poNumber'
  },
  {
    label: 'Internal Notes',
    name: 'internalNotes'
  },
  {
    label: 'PO Notes',
    name: 'poNotes'
  },
  {
    label: 'Status',
    name: 'status'
  },
  {
    label: 'Delivery Location',
    name: 'deliveryLocationId'
  },
  {
    label: 'Billing Location',
    name: 'billingLocationId'
  },
  {
    label: 'Total Units',
    name: 'totalUnits'
  },
  {
    label: 'Total Cost',
    name: 'totalCost'
  },
  {
    label: 'Expected Date',
    name: 'expectedDate'
  },
  {
    label: 'Paid At',
    name: 'paidAt'
  },
  {
    label: 'Paid By',
    name: 'paidBy'
  }
])
const purchaseOrderItemAuditCols = ref([
  {
    label: 'Price',
    name: 'price'
  },
  {
    label: 'Quantity Ordered',
    name: 'quantityOrdered'
  },
  {
    label: 'Quantity Cancelled',
    name: 'quantityCancelled'
  },
  {
    label: 'Quantity Received',
    name: 'quantityReceived'
  },
  {
    label: 'Status',
    name: 'status'
  },
  {
    label: 'Backorder',
    name: 'backorder'
  },
  {
    label: 'Backorder Quantity',
    name: 'backorderQty'
  },
  {
    label: 'Backorder Notes',
    name: 'backorderNotes'
  },
  {
    label: 'Backorder Expected Date',
    name: 'backorderExpectedDate'
  },
  {
    label: 'Sku',
    name: 'sku'
  }
])

import { useAudit } from '@/composables/useAudit'
const {
  auditHistoryCols,
  mergeAudit,
  sortAudit,
  formatAuditDates,
  processAudit
} = useAudit()

const getHistoryRows = async (args = {}) => {
  let responsePOAudit = await api.get('/audit/tenantPurchaseOrder', {
    ...args,
    params: {
      tenantPurchaseOrderId: purchaseOrderId.value
    }
  })
  let responsePOItemAudit = await api.get('/audit/tenantPurchaseOrderItem', {
    ...args,
    params: {
      tenantPurchaseOrderId: purchaseOrderId.value
    }
  })
  let auditRecords = mergeAudit([
    responsePOAudit.data.data,
    responsePOItemAudit.data.data
  ])
  let auditSorted = sortAudit(auditRecords)
  purchaseOrderAudit.value = processAudit(
    {
      'Purchase Order': 'tenantPurchaseOrderId',
      'Purchase Order Item': 'tenantPurchaseOrderItemId'
    },
    {
      'Purchase Order': purchaseOrderAuditCols.value,
      'Purchase Order Item': purchaseOrderItemAuditCols.value
    },
    auditSorted
  )
  setHistoryRows()
}

/**
 *
 */
const setHistoryRows = (paginationSkip = 0) => {
  let paginationEnd = paginationSkip + 20
  if (paginationEnd > purchaseOrderAudit.value.length) {
    paginationEnd = purchaseOrderAudit.value.length
  }
  let rows = purchaseOrderAudit.value.slice(paginationSkip, paginationEnd)
  rows = JSON.parse(JSON.stringify(rows))
  let formatted = formatAuditDates(rows)

  historyPoRows.value = formatted
}
</script>

<style lang="scss">
#paidAtNotification,
#saveNotification,
#minOrderNotification,
#approvedNotification {
  color: $braid-white;
  text-transform: uppercase;
  text-align: center;
  font-weight: bold;
  // border-radius: 1.5rem;
  margin: 0;
  // padding: 0.5rem;
}

#minOrderNotification,
#saveNotification {
  background-color: $error-color;
}

#paidAtNotification {
  background-color: $braid-green-accessible;
}

#approvedNotification {
  background-color: $info-color;
  color: #000000;
}

.text-align-right {
  text-align: right;
}

.po-action {
  width: 15rem;
  & input {
    border: 1px solid rgba(0, 0, 0, 0.2);
  }
}

.bottom-action-row {
  padding-top: 5rem;
}

.summary-title {
  width: 15rem;
}

.summary-data {
  width: 10rem;
}
</style>
