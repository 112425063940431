<template>
  <ValidationProvider
    v-if="loaded"
    ref="validation"
    v-slot="{ errors, changes }"
  >
    <ActionBar
      :buttons="buttons"
      :conditions="{ 0: { disable: !changes || saving || errors } }"
    />

    <TableForm>
      <!-- CHANNEL NAME -->
      <TableRow
        v-model="local.name"
        label="Channel Name"
        type="text"
      ></TableRow>
      <!-- ./CHANNEL NAME -->

      <!-- CHANNEL -->
      <TableRow
        v-model="local.channel"
        label="Channel"
        :options="[
          { value: 'AMAZON_EU', label: 'Amazon EU' },
          { value: 'AMAZON_US', label: 'Amazon US' },
          { value: 'EBAY', label: 'EBay' },
          { value: 'Shopify', label: 'Shopify' }
        ]"
        type="select"
      ></TableRow>
      <!-- ./CHANNEL -->

      <!-- KEY 1 -->
      <TableRow
        v-if="keyLabels.key1"
        v-model="local.key1"
        label="Key 1"
        type="text"
      ></TableRow>
      <!-- ./KEY 1 -->

      <!-- KEY 2 -->
      <TableRow
        v-if="keyLabels.key2"
        v-model="local.key2"
        label="Key 2"
        type="text"
      ></TableRow>
      <!-- ./KEY 2 -->

      <!-- KEY 3 -->
      <TableRow
        v-if="keyLabels.key3"
        v-model="local.key3"
        label="Key 3"
        type="text"
      ></TableRow>
      <!-- ./KEY 3 -->

      <!-- KEY 4 -->
      <TableRow
        v-if="keyLabels.key4"
        v-model="local.key4"
        label="Key 4"
        type="text"
      ></TableRow>
      <!-- ./KEY 4 -->

      <!-- SECRET KEY -->
      <TableRow
        v-if="keyLabels.secretKey"
        v-model="local.secretKey"
        label="Secret Key"
        type="text"
      ></TableRow>
      <!-- ./SECRET KEY -->

      <!-- LAST SUCCESSFUL SYNC -->
      <TableRow
        v-model="local.lastSuccessfulSync"
        label="Last successful sync "
        read-only
        type="text"
      ></TableRow>
      <!-- ./LAST SUCCESSFUL SYNC -->

      <!--  CREATED AT -->
      <TableRow
        v-model.trim="local.createdAt"
        label="Created at "
        disabled
        read-only
        type="text"
      ></TableRow>

      <!--  ./CREATED AT -->

      <!--  EMAIL -->
      <TableRow
        v-model.trim="local.email"
        label="Amazon messages email"
        disabled
        read-only
        type="text"
      ></TableRow>

      <!--  ./EMAIL -->
    </TableForm>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    ></ConfirmLeaveFormModal>
  </ValidationProvider>
</template>

<script setup>
import { ref, computed, onMounted, onUpdated, nextTick, inject } from 'vue'

import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'

const router = useRouter()
const api = useApi()

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: [String, Number],
    default: ''
  }
})

const { formatDate, formatDateTime, UTC_OFFSET } = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

import en from '@/lang/en'

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

//Display content once it's loaded from the DB
const loaded = ref(false)

// Object that contains all the data
const local = ref({})

const channels = ref({
  AMAZON_EU: 'Amazon EU',
  AMAZON_US: 'Amazon US',
  EBAY: 'EBay',
  Shopify: 'Shopify',
  ROYAL_MAIL: 'Royal Mail'
})

const keyLabels = computed(() => {
  switch (local.value.channel) {
    case 'AMAZON_EU':
    case 'AMAZON_US':
      return {
        key1: en.amazonKey1,
        key2: en.amazonKey2,
        key3: en.amazonKey3,
        secretKey: en.amazonSecretKey
      }
    default:
      return {}
  }
})

const channelId = ref(false)
const getChannelId = () => {
  if (props.id && parseInt(props.id) > 0) {
    channelId.value = props.id
  }
}

/**
 * Formats data returned from api
 */
const handleResponse = (data) => {
  let channel = data.channels[0]
  local.value = {
    tenantChannelId: channel.tenantChannelId,
    channel: channel.channel,
    name: channel.name,
    key1: channel.key1 || null,
    key2: channel.key2 || null,
    key3: channel.key3 || null,
    key4: channel.key4 || null,
    secretKey: null,
    email: data.email || '',
    createdBy: channel.createdBy,
    updatedBy: channel.updatedBy,
    createdAt: formatDateTime(channel.createdAt, utcOffset.value),
    updatedAt: formatDateTime(channel.updatedAt, utcOffset.value),
    lastSuccessfulSync: formatDateTime(
      channel.lastSuccessfulSync,
      utcOffset.value
    )
  }
}

/*
 *****************      VALIDATION PROVIDER
 */

// Template reference to the Validation Provider Component
const validation = ref(null)

/*
 *****************      TABLE FORM
 */
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

const update = async () => {
  if (!channelId.value) {
    insert()
    return
  }

  let response = await api.put('/channels/' + channelId.value, {
    channel: local.value
  })
  handleResponse(response.data)
  validation.value.save()
}

const reAuth = async () => {
  localStorage.reAuthChannelId = channelId.value
  window.location.replace(
    'https://auth.ebay.com/oauth2/authorize?client_id=FLOWMOTI-Braid-PRD-f8463c110-94f4f0e2&response_type=code&redirect_uri=FLOWMOTION_LTD-FLOWMOTI-Braid--usueqinlp&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly&prompt=login'
  )
}

/**
 * Create options for the select element
 */
const channelOptions = ref([])

const createChannelOptions = () => {
  let options = []
  Object.entries(channels.value).forEach(([value, label]) => {
    options.push({ label, value })
  })
  channelOptions.value = options
}

const getChannel = async () => {
  if (!channelId.value || channelId.value === 'new') {
    return
  }
  let response = await api.get('/channels/' + channelId.value)
  handleResponse(response.data)
}

const insert = async () => {
  let response = await api.post('/channels/', { channel: local.value })
  channelId.value = String(response.data.insertedId)
  router.replace('/channels/' + channelId.value)
  handleResponse(response.data)
  await createPriceProfile()
}

const createPriceProfile = async () => {
  await api.post('/pricing/', { channel: local.value })
}

const gotoChannels = () => {
  router.push('/channels/')
}

/*
 *****************      MODALS
 */
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'

const confirmLeaveModal = ref(null)
const confirmModalNext = ref(null)

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  {
    id: 0,
    label: 'Save',
    click: update
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back()
  },
  {
    id: 2,
    label: 'Re-authenticate',
    click: reAuth
  }
])

onMounted(async () => {
  try {
    progressBarStart()

    createChannelOptions()
    getChannelId()
    await getChannel()
    loaded.value = true

    //Set tab title and page title
    let pageTitle = local.value.name || 'Create Channel'
    document.getElementById('pagetitle').innerHTML = pageTitle
    document.title = pageTitle
    progressBarDone()
  } catch (e) {
    progressBarFail()
  }
})

const routeRefreshOnMounted = ref(false)
onUpdated(() => {
  if (
    routeRefreshOnMounted.value &&
    router.currentRoute.value.params.id == 'new'
  ) {
    router.go()
  }
  routeRefreshOnMounted.value = true
})
</script>
