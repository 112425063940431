<template>
  <ValidationProvider
    v-if="loaded"
    ref="validation"
    v-slot="{ errors, changes }"
  >
    <ActionBar
      :buttons="buttons"
      :conditions="{ 0: { disable: !changes || errors || saving } }"
    />
    <TableContainer>
      <SupplierPanel
        ref="supplierPanelRef"
        :sku-suppliers="local.skuSuppliers"
        :editable="true"
        :bulk-update="true"
        :changes="changes"
        @update="update"
      ></SupplierPanel>
    </TableContainer>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    >
    </ConfirmLeaveFormModal>
  </ValidationProvider>
</template>

<script setup>
import { ref, onMounted, onUpdated, nextTick, inject } from 'vue'

import SupplierPanel from '@/components/content/skus/Suppliers.vue'
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

import { useRouter } from 'vue-router'
const router = useRouter()
import useApi from '@/components/useApi'
const api = useApi()

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: [String, Number],
    default: ''
  }
})

const supplierPanelRef = ref(null)

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const { formatDateTime, UTC_OFFSET } = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

// Object that contains all the data
const local = ref({})

// Displays content once it's fetched from the DB
const saving = ref(false)
const loaded = ref(false)
const supplierId = ref()

/**
 * Formats data returned from api
 */
const handleResponse = (data) => {
  local.value.supplier = data.supplier
  local.value.skuSuppliers = data.pricing
}

/*
 *****************      VALIDATION PROVIDER
 */

// Template ref to validation provider component
const validation = ref(null)

/*
 *****************      MODALS
 */
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'

const confirmLeaveModal = ref(null)
const confirmModalNext = ref(null)

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
  confirmModalNext.value()
}

const closeConfirmModal = () => {
  confirmModalNext.value = null
  confirmLeaveModal.value.hide()
}

/**
 *****************      TABLE FORM
 */

const update = async () => {
  let pricing = supplierPanelRef.value.formatSkuSupplierPricing()
  let response = await api.put(
    '/suppliers/' + supplierId.value,
    { pricing: pricing, bulkPricing: 1, tenantSupplierId: supplierId.value },
    saving
  )
  handleResponse(response.data)
  validation.value.save()
  supplierPanelRef.value.resetInitial()
}

const getSupplierId = () => {
  supplierId.value = false
  let query = router.currentRoute.value.query
  let querySupplierId = query.supplierId
  if (querySupplierId && parseInt(querySupplierId) > 0) {
    supplierId.value = querySupplierId
  }
}

const getSkuSupplierPricing = async () => {
  if (!supplierId.value || supplierId.value === 'new') {
    return
  }
  let response = await api.get('/suppliers/pricing', {
    tenantSupplierId: supplierId.value
  })
  handleResponse(response.data)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = [
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  }
]

const setTitle = () => {
  let pageTitle = `Update ${local.value.supplier.name} pricing`
  document.getElementById('pagetitle').innerHTML = pageTitle
  document.title = pageTitle
}

const getData = async () => {
  try {
    progressBarStart()
    getSupplierId()
    await getSkuSupplierPricing()
    setTitle()
    loaded.value = true
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

getData()
</script>

<style lang="scss">
.defaultDelivery {
  background-color: $braid-green-accessible;
  color: $braid-white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 1.563rem;
  text-align: center;
  padding: 0.3rem;
}
</style>
