<template>
  <div class="row">
    <div class="col">
      <nav class="navbar navbar-expand action-bar-wrapper">
        <div class="container-fluid">
          <ul
            ref="buttonBar"
            class="navbar-nav button-bar"
            :class="{ 'w-100': collapse, 'w-auto': !collapse }"
          >
            <template v-for="button in getButtonBarButtons">
              <li
                v-if="buttonState[button.id].show"
                :id="button.id"
                :key="button.id"
                class="nav-item"
              >
                <SkeletonActionBarButton
                  v-if="pageLoading"
                ></SkeletonActionBarButton>
                <button
                  v-else
                  :disabled="buttonState[button.id].disable"
                  class="nav-link btn"
                  @click="button.click"
                >
                  <span v-if="button.icon">
                    <font-awesome-icon
                      v-if="/^fa/.test(button.icon[0])"
                      class="fa-sm"
                      :icon="button.icon"
                    ></font-awesome-icon>
                    <i
                      v-else-if="/^bi/.test(button.icon[0])"
                      :class="'bi ' + button.icon.join('-')"
                    ></i>
                  </span>
                  <span>{{ button.label }}</span>
                </button>
              </li>
            </template>

            <li class="dropdown" :class="{ 'visually-hidden': !showToggle }">
              <button
                id="dropdownToggle"
                ref="toggle"
                class="btn nav-link"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                >>
              </button>

              <ul
                id="collapsedButtonContainer"
                ref="collapsed"
                class="dropdown-menu"
                aria-labelledby="dropdownToggle"
              >
                <li
                  v-for="button in getCollapsedButtons"
                  :key="button.id"
                  class="nav-item"
                >
                  <button
                    v-if="buttonState[button.id].show"
                    class="nav-link btn collapsedButton"
                    :disabled="buttonState[button.id].disable"
                    @click="button.click"
                  >
                    <font-awesome-icon
                      v-if="button.icon"
                      class="fa-xs"
                      :icon="button.icon"
                    ></font-awesome-icon>
                    <span>
                      {{ button.label }}
                    </span>
                  </button>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script setup>
import {
  ref,
  toRef,
  computed,
  watch,
  onMounted,
  onUnmounted,
  nextTick
} from 'vue'

const _ = require('lodash')
import { pageLoad } from '@/composables/pageLoad'
import SkeletonActionBarButton from '@/components/skeleton/SkeletonActionBarButton.vue'

const { getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const props = defineProps({
  buttons: {
    type: Array,
    default() {
      return []
    }
  },
  conditions: {
    type: Object,
    default() {
      return {}
    }
  }
})

const button_conditions = toRef(props, 'conditions')

//Flag that indicates something needs collapsing
const collapse = ref(false)

//Height of navbar that we collapse a button at
const MAX_HEIGHT = 50

//Number of buttons to always show i.e, do not collapse this number of buttons
const MIN_COLLAPSE_LIMIT = 2

/**
 *        Keeps track of buttons that have been collapsed.
 *        KEY                                 :       VALUE
 *        screen width button was collapsed at: button that was collapsed
 */
const collapsed_buttons = ref({})

/*
 * Keeps track of which buttons are disabled and shown
 */
const buttonState = ref({})

/**
 * Reactive references to props
 */
const buttons = ref(props.buttons)
const conditions = ref(props.conditions)

/**
 * References to template elements
 */
const toggle = ref(null)
const buttonBar = ref(null)

/**
 * Set reactive button state for button with id
 */
const createButtonState = (id, show = true, disable = false) => {
  buttonState.value[id] = { show, disable }
}

buttons.value.forEach((button, index) => {
  //Set parent to be a reactive property for buttons
  button.parent = 'buttonBar'
  buttons.value[index] = button

  // Create button state
  let show = conditions.value?.[button.id]?.show
  let disable = conditions.value?.[button.id]?.disable
  createButtonState(button.id, show, disable)
})

/**
 */

/*
 * Get the buttons that have been rendered on the button bar
 */
const getButtonBarButtons = computed(() => {
  return buttons.value.filter(function (button) {
    return button.parent === 'buttonBar'
  })
})

/*
 * Get the buttons that are in the collapsed menu
 */
const getCollapsedButtons = computed(() => {
  return buttons.value.filter((button) => {
    return button.parent === 'collapsed'
  })
})

// Show the toggle button if we have collapsed items or we need to collapse an item
const showToggle = computed(() => {
  return collapse.value || getCollapsedButtons.value.length > 0
})

watch(button_conditions, (newVal, oldVal) => {
  const newEntries = Object.entries(newVal)
  const oldEntries = Object.entries(oldVal)
  // Find out which properties have changed
  // Differences are tracked on first array provided (check lodash docs)
  const differences = _.differenceWith(newEntries, oldEntries, _.isEqual)

  // If we have differences update state using those, otherwise use newEntries (on create)
  const data = differences.length > 0 ? differences : newEntries
  data.forEach((datum) => {
    const [id, constraints] = datum
    Object.entries(constraints).forEach((constraint) => {
      const [property, value] = constraint
      buttonState.value[id][property] = value
    })
  })
})

onMounted(() => {
  //Important to collapse any buttons when the component is mounted
  window.addEventListener('resize', autoCollapse)
  setTimeout(autoCollapse, 500)
})

onUnmounted(() => {
  window.removeEventListener('resize', autoCollapse)
})

// Get the html button by id
const getButtonBarButtonByID = (id) => {
  return getButtonBarButtons.value.find((button) => {
    return button.id == id
  })
}

/*
 * Move the last button in the action bar to the collapsed section
 */
const collapseButton = () => {
  let last_index = getButtonBarButtons.value.length - 1
  let last_element_id = getButtonBarButtons.value[last_index].id
  let button = getButtonBarButtonByID(last_element_id)
  button.parent = 'collapsed'
  collapsed_buttons.value[window.innerWidth] = button
}

const showButtons = () => {
  const collapsed_widthstamps = Object.keys(collapsed_buttons.value)
  for (let i = 0; i < collapsed_widthstamps.length; i++) {
    let current_widthstamp = parseInt(collapsed_widthstamps[i], 10)

    //Include the width of the toggle button to make sure we don't bounce between collapsing and showing buttons
    if (
      current_widthstamp + parseInt(toggle.value.clientWidth, 10) >=
      window.innerWidth
    ) {
      break
    }
    collapsed_buttons.value[current_widthstamp].parent = 'buttonBar'
    delete collapsed_buttons.value[current_widthstamp]
  }
}

const autoCollapse = (e) => {
  // Collapse an item if the button bar height is greater than 50 (i.e and item has wrapped)
  let button_bar_height = buttonBar.value.clientHeight
  collapse.value = button_bar_height >= MAX_HEIGHT
  if (collapse.value) {
    if (getButtonBarButtons.value.length - MIN_COLLAPSE_LIMIT > 0) {
      collapseButton()
      nextTick(autoCollapse)
    }
  } else {
    if (
      buttonBar.value.clientHeight < MAX_HEIGHT &&
      getCollapsedButtons.value.length > 0
    ) {
      showButtons()
    }
  }
}

const getCollapsedButtonByID = (id) => {
  return getCollapsedButtons.value.find((button) => {
    return button.id == id
  })
}

/**
 * Use conditions to override default button state where needed
 */
</script>

<style lang="scss" scoped>
@import './scss/buttons';

.action-bar-wrapper {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  background-color: $braid-grey-1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.button-bar {
  gap: 1rem;
  flex-wrap: wrap;

  button {
    //Remove border styled by bootstrap
    &.btn {
      border: 0;
    }

    background-color: $braid-grey-1;
    span {
      padding-left: 0.5rem;
    }
  }
}

.dropdown-menu {
  background-color: $braid-grey-1;
}

.icon {
  color: $braid-grey-4;
}

#collapsedButtonContainer {
  &.show {
    display: flex;
  }
  flex-direction: column;
  justify-content: flex-start;

  .collapsedButton {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    text-align: left;
    align-items: center;
  }
}
</style>
