<template>
  <Table
    id="messages-table"
    :columns="messageColumns"
    :rows="messages"
    id-key="tenantMessageId"
    :total-count="messageCount"
    :limit="messageLimit"
    header="Unanswered messages"
    :link="messagesLink"
    link-text="View messages"
    :get-data="getData"
  ></Table>
</template>

<script setup>
import { ref, inject, nextTick } from 'vue'
import { dayjs } from '@/dayjs'

import Table from '@/components/content/widgets/Table'
import useApi from '@/components/useApi'
const api = useApi()
const redrawVueMasonry = inject('redrawVueMasonry')

const messages = ref([])
const messageCount = ref(0)
const channels = ref(null)
const messageLimit = 1
const messagesLink = `/messages?filter=messageStatus=Unanswered`

const messageColumns = ref([
  {
    label: 'Subject',
    name: 'subject',
    visible: true,
    onClick: {
      route: '/messages/',
      id: 'tenantMessageId'
    },
    primary: true
  },
  {
    label: 'Sender',
    name: 'senderId',
    visible: true
  },
  {
    label: 'Channel',
    name: 'tenantChannelId',
    visible: true
  },
  {
    label: 'Item',
    name: 'itemTitle',
    visible: true
  }
])

const getData = async (args = {}) => {
  await getMessages()
  emit('done')
}

const getMessages = async () => {
  let args = {
    limit: messageLimit,
    sort: { createdAt: -1 },
    filter: {
      messageStatus: ['Unanswered']
    }
  }
  let response = await api.get('/messages', args)
  messageColumns.value = getChannelValues(
    messageColumns.value,
    response.data.channels
  )
  messages.value = response.data.messages
  messageCount.value = parseInt(response.data.count)
}

const getChannelValues = (tableCols, response = []) => {
  let index = 0
  let length = response.length
  let channelValues = {}
  channels.value = response

  for (index; index < length; index++) {
    let channel = response[index]
    channelValues[channel.tenantChannelId] = channel.name
  }

  let columnIndex = tableCols.findIndex((column) => {
    return column.name === 'tenantChannelId'
  })

  tableCols[columnIndex].displayValues = channelValues
  return tableCols
}

const emit = defineEmits(['done'])
</script>

<style scoped lang="scss">
.masonry-container {
  margin: 1rem;
  width: 100%;
}

.spacer {
  width: 0.5rem;
}

.dispatch-order-widget {
  height: 10rem;
  width: 10rem;
  .number-data {
    height: 60%;
    font-size: 4rem;
    font-weight: 600;
  }

  .text-title {
    height: 40%;
    text-align: center;
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
