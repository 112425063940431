import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import {
  faCog,
  faTag,
  faUser,
  faSignOutAlt,
  faSave,
  faTimesCircle,
  faTrash,
  faSortAmountUp,
  faSortAmountDownAlt,
  faFilter,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleUp,
  faAngleDoubleUp,
  faAngleDown,
  faAngleDoubleDown,
  faAngleDoubleRight,
  faPlusCircle,
  faExclamation,
  faEye,
  faEyeSlash,
  faCheck,
  faCheckDouble,
  faArchive,
  faFilePdf,
  faFileDownload,
  faArrowDown,
  faArrowUp,
  faArrowRight,
  faCaretDown,
  faCaretRight,
  faInfoCircle,
  faChevronDown,
  faChevronRight,
  faLock,
  faPen,
  faArrowRotateLeft,
  faBoxesPacking,
  faTableColumns,
  faLocationCrosshairs,
  faBoxOpen,
  faTruckArrowRight,
  faBarcode,
  faUserTie,
  faBagShopping,
  faClipboardList,
  faLink,
  faTruckRampBox,
  faPlus,
  faCircleInfo,
  faGear,
  faBoxArchive,
  faSquare,
  faCheckSquare,
  faChartArea,
  faSquareCaretUp,
  faTriangleExclamation,
  faCircleExclamation,
  faCircleCheck,
  faBell,
  faGifts,
  faUpload,
  faBuilding,
  faReply,
  faFileLines,
  faArrowsRotate,
  faFileInvoiceDollar,
  faBars,
  faMagnifyingGlass,
  faCodeBranch,
  faHouse,
  faCircleQuestion,
  faList,
  faSterlingSign,
  faPrint
} from '@fortawesome/free-solid-svg-icons'
import { faCheckCircle as farCheckCircle } from '@fortawesome/free-regular-svg-icons'
import { faTimesCircle as farTimesCircle } from '@fortawesome/free-regular-svg-icons'
import { faCopy as farCopy } from '@fortawesome/free-regular-svg-icons'
import { faPaste as farPaste } from '@fortawesome/free-regular-svg-icons'
import {
  faAmazon as fabAmazon,
  faEbay as fabEbay,
  faShopify as fabShopify
} from '@fortawesome/free-brands-svg-icons'

// Add the imported icons to the library
library.add(
  faCog,
  faTag,
  faUser,
  faSignOutAlt,
  faSave,
  faTimesCircle,
  farTimesCircle,
  farCheckCircle,
  faTrash,
  faSortAmountUp,
  faSortAmountDownAlt,
  faFilter,
  faAngleLeft,
  faAngleDoubleLeft,
  faAngleRight,
  faAngleDoubleRight,
  faAngleUp,
  faAngleDoubleUp,
  faAngleDown,
  faAngleDoubleDown,
  faPlusCircle,
  faExclamation,
  faEye,
  faEyeSlash,
  faCheck,
  faSquare,
  faCheckSquare,
  faCheckDouble,
  faArchive,
  faFilePdf,
  faFileDownload,
  faArrowDown,
  faArrowUp,
  faArrowRight,
  faCaretDown,
  faCaretRight,
  faInfoCircle,
  faChevronDown,
  faChevronRight,
  faLock,
  faPen,
  faArrowRotateLeft,
  faBoxesPacking,
  faTableColumns,
  faLocationCrosshairs,
  faBoxOpen,
  faTruckArrowRight,
  faBarcode,
  faUserTie,
  faBagShopping,
  faClipboardList,
  faLink,
  faTruckRampBox,
  faPlus,
  faCircleInfo,
  faGear,
  faBoxArchive,
  farCopy,
  farPaste,
  faChartArea,
  faSquareCaretUp,
  faTriangleExclamation,
  faCircleExclamation,
  faCircleCheck,
  faBell,
  faGifts,
  faUpload,
  faReply,
  faBuilding,
  faFileLines,
  faArrowsRotate,
  faFileInvoiceDollar,
  faBars,
  faMagnifyingGlass,
  faCodeBranch,
  fabAmazon,
  fabEbay,
  fabShopify,
  faHouse,
  faCircleQuestion,
  faList,
  faSterlingSign,
  faPrint
)

export default FontAwesomeIcon
