<template>
  <input
    ref="input"
    class="table-attribute-input"
    :value="modelValue"
    :readonly="readOnly"
    :placeholder="placeholder"
    :list="attributeValue.listName"
    :class="{ 'read-only': readOnly }"
    @change="updateValue"
  />

  <datalist :id="attributeValue.listName">
    <option
      v-for="(option, index) in options"
      :key="option.en + index"
      :data-value="option.tenantAttributeNameId"
      :value="option.en"
    ></option>
  </datalist>
</template>
<script>
import { ref, watch } from 'vue'
import useTableInput from '@/components/table/useTableInput'
const config = useTableInput()

const attributeProps = {
  attributeValue: {
    type: Object,
    default: {}
  },
  options: {
    type: Array,
    default() {
      return []
    }
  },
  change: {
    type: Function,
    default() {
      return
    }
  }
}
</script>

<script setup>
const props = defineProps({ ...config._props, ...attributeProps })

const emit = defineEmits([].concat(config._emits))

const input = ref(null)

/**
 * We have to watch the value intead of relying on the input event.
 * This is due to the fact that when a new value is pasted via the
 * context menu; the input event isn't triggered (neither is paste event)
 */
watch(
  () => props.modelValue,
  (newValue) => {
    emit('update:modelValue', newValue)
  }
)

const updateValue = (event) => {
  let displayValue = event.target.value
  emit('update:modelValue', displayValue)
}

const focus = () => {
  input.value.focus()
}
</script>

<style lang="scss">
.table-attribute-input {
  width: 100%;
  height: 100%;
  background-color: white;
  outline: none;
  border: none;
  padding: 0 0.5rem;
  border-radius: 3px;
  line-height: 1.5;

  &.read-only {
    cursor: default !important;
    background-color: #f5f5f5;
  }
}
</style>
