<template>
  <v-select
    ref="input"
    class="table-select-input form-control table-form-select"
    :class="{ readonly: readOnly }"
    :options="options"
    :label="display"
    :append-to-body="true"
    :reduce="reduce"
    :search="search"
    :multiple="multiple"
    :disabled="readOnly"
    :selectable="(item) => !itemDisabled(item)"
    @update:model-value="change"
    @mouseleave="hide"
    @mouseenter="show"
    @search="emitSearch"
  ></v-select>
</template>

<script>
import 'vue-select/dist/vue-select.css'
export default {}
</script>

<script setup>
import { ref, onMounted } from 'vue'
const input = ref(null)
const props = defineProps({
  options: {
    type: Array,
    default() {
      return [{}]
    }
  },
  display: {
    type: String,
    default: 'label'
  },
  reduce: {
    type: Function,
    default(option) {
      return option.value
    }
  },
  search: {
    type: Function,
    default() {}
  },
  readOnly: {
    type: Boolean,
    default: false
  },
  rules: {
    type: Object,
    default() {
      return {}
    }
  },
  multiple: {
    type: Boolean,
    default: false
  },
  itemDisabled: {
    type: Function,
    default() {
      return false
    }
  }
})
const emit = defineEmits(['search', 'change'])
const focus = () => {
  input.value.searchEl.focus()
}
const emitSearch = (search, loading) => {
  emit('search', search, loading)
}
const actions = ref()
onMounted(() => {
  actions.value = input.value.$el.querySelector('.vs__actions')
  hide()
})
const show = () => {
  actions.value.style.visibility = 'visible'
  actions.value.style.cursor = 'pointer'
}
const hide = () => {
  actions.value.style.visibility = 'hidden'
}
const change = () => {
  emit('change')
}
defineExpose({ focus })
</script>

<style lang="scss">
.table-select-input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 0;
  border-radius: 3px;
  line-height: 1.5;

  &.table-form-select {
    min-height: 2rem;
    & .vs__selected-options {
      padding: 0;
    }
    & .vs__selected {
      padding: 0 0.5rem;
      margin: 0;
      height: 100%;
    }
    & .vs__selected-options {
      min-height: 2rem;
    }
    & .vs__dropdown-toggle {
      // width: 50%;
      padding: 0;
    }
    & .vs__search {
      background-color: transparent;
      width: 10%;
      height: 0;
    }
  }
  &.vs--open {
    & .vs__search {
      background-color: transparent;
      width: 10%;
      height: 100%;
    }
  }
  &.vs--disabled {
    .vs__dropdown-toggle {
      background-color: transparent;
    }
    .vs__actions {
      visibility: hidden !important;
    }
  }
  &.readonly {
    background-color: $braid-grey-1;
  }
}
</style>
