<template>
  <tr v-if="pageLoading" class="row-container">
    <td class="pb-1"><SkeletonText></SkeletonText></td>
  </tr>
  <tr v-else class="row-container" @click="toggleVisibility">
    <td :colspan="colspan">
      <div class="table-row-header" :class="classObject">
        <span>
          <FontAwesomeIcon
            v-if="visible && !disabled"
            :icon="['fas', 'caret-down']"
          ></FontAwesomeIcon>

          <FontAwesomeIcon
            v-if="!visible && !disabled"
            :icon="['fas', 'caret-right']"
          ></FontAwesomeIcon>
        </span>
        <p>{{ heading + '' }}</p>
      </div>
    </td>
    <td v-if="checkbox" colspan="1">
      <input type="checkbox" :checked="visible" />
    </td>
    <td v-else-if="button" colspan="1" class="tbl-cell-btn">
      <button class="btn" @click.stop.prevent="$emit('buttonClicked')">
        <FontAwesomeIcon
          :icon="['fas', buttonIcon]"
          class="btn-icon"
        ></FontAwesomeIcon>
        {{ buttonText }}
      </button>
    </td>
    <td v-else-if="colspan == 1" colspan="1"></td>
  </tr>
  <slot v-if="visible"></slot>
</template>

<script>
export default {
  name: 'RowContainer'
}
</script>

<script setup>
import { ref, computed } from 'vue'
import TableRow from '@/components/table/form/TableRow'
import SkeletonText from '@/components/skeleton/SkeletonText.vue'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const emit = defineEmits(['toggleVisibility', 'buttonClicked'])

const props = defineProps({
  heading: {
    type: [String, Number],
    required: true
  },
  indentLevel: {
    type: Number,
    default: 0
  },
  autoCollapse: {
    type: Boolean,
    required: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  checkbox: {
    type: Boolean,
    required: false
  },
  colspan: {
    type: Number,
    default: 1
  },
  button: {
    type: Boolean,
    required: false
  },
  buttonText: {
    type: String,
    required: false,
    default: ''
  },
  buttonIcon: {
    type: String,
    required: false,
    default: ''
  }
})

const classObject = computed(() => {
  return {
    indent: props.indentLevel == 0,
    indent2: props.indentLevel == 1,
    indent3: props.indentLevel == 2
  }
})

const iconClassObject = computed(() => {
  return {
    grey: visible.value,
    white: !visible.value
  }
})

const visible = ref(true)

if (props.autoCollapse) {
  visible.value = false
}

const toggleVisibility = () => {
  if (!props.disabled) {
    visible.value = !visible.value
    emit('toggleVisibility', visible.value)
  }
}
</script>

<style lang="scss">
/*
*NOTE: .indent classes are set in TableRow.vue
*/
.table-row-header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-content: center;
  // width: 20%;
  text-align: left;
  font-weight: bold;

  p {
    margin: 0;
    margin-left: 0.5rem;
    user-select: none; /* Chrome */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }
}

.row-container td {
  width: fit-content;
}

.margin-left-15 {
  margin-left: 15px;
}

.grey {
  color: #cccccc;
}

.white {
  color: #ffffff;
}
</style>
<style lang="scss" scoped>
td:not(:first-of-type) {
  padding-left: 0.5rem;
}
.visible {
  visibility: visible;
}
.hidden {
  visibility: hidden;
}
</style>
<style scoped>
.tbl-cell-btn button {
  width: 100%;
  text-align: left;
}
</style>
