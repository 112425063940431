<template>
  <TableGrid
    :cols="cols"
    :rows="taxRates"
    :total-count="Object.keys(taxRates).length"
    :trclick="openModal"
    id-key="m2mProductLocationTaxId"
    scrollable
  ></TableGrid>
  <button @click="openModal('new')">Add tax rate</button>

  <BraidModal ref="taxModal" @confirm="saveModal" @reject="hideModal">
    <template #title>
      <template v-if="modalData.m2mProductLocationTaxId == undefined">
        Add Tax Rate
      </template>
      <template v-else>Edit Tax Rate</template>
    </template>
    <template #body>
      <TableForm>
        <TableRow
          v-model="modalData.label"
          label="Label"
          type="text"
        ></TableRow>

        <TableRow
          v-model="modalData.country"
          label="Country"
          :options="countries"
          type="select"
        ></TableRow>

        <TableRow
          v-model="modalData.taxRate"
          label="Tax Rate"
          placeholder="Enter as a decimal. E.g, 20% tax is 0.2"
          type="number"
        ></TableRow>
      </TableForm>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref } from 'vue'

const _ = require('lodash')
const uuid = require('uuid')

import useCountries from '@/composables/selectData/useCountries.js'
const { getCountriesForSelect } = useCountries()
const countries = getCountriesForSelect()

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => {
      return {}
    }
  },
  save: {
    type: Function,
    default() {
      return
    }
  }
})

const emit = defineEmits(['update:modelValue', 'save'])

/*
 *****************      MODALS
 */
import BraidModal from '@/components/modals/Modal.vue'

const taxModal = ref(null)
const modalData = ref({})

const taxRates = ref(props.modelValue)

const saveTaxRate = (rate = {}) => {
  //Avoid reactive problems
  let taxRate = Object.assign({}, rate)

  // If taxrate has a m2mProductLocationTaxId
  // It is an existing taxrate being edited
  if (taxRate.m2mProductLocationTaxId) {
    let index = _.findIndex(taxRates.value, {
      m2mProductLocationTaxId: taxRate.m2mProductLocationTaxId
    })

    if (index !== -1) {
      taxRates.value[index] = taxRate
      emit('update:modelValue', taxRates.value)
      emit('save')
      return
    }
  }

  taxRates.value.push(taxRate)
  emit('update:modelValue', taxRates.value)
  emit('save')
}

const hideModal = () => {
  modalData.value = {}
  taxModal.value.hide()
}

const saveModal = () => {
  saveTaxRate(modalData.value)
  modalData.value = {}
  taxModal.value.hide()
}

const openModal = (id) => {
  //If you need to provide logic for adding a new tax rate
  // check for id === 'new'

  if (id) {
    // Prevent a reactive reference being passed to the modal
    // to stop the modalData object mutating row data in the tablegrid
    // by using Object.assign
    let rowData = Object.assign(
      {},
      _.find(taxRates.value, {
        m2mProductLocationTaxId: id
      }) || {}
    )

    modalData.value = rowData
  }

  taxModal.value.show()
}

/*
 *****************      GRID
 */
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

const cols = ref([
  {
    label: 'Label',
    name: 'label',
    visible: true
  },
  {
    label: 'Country',
    name: 'country',
    visible: true
  },
  {
    label: 'Tax rate',
    name: 'taxRate',
    visible: true
  }
])
</script>

<style lang="scss">
// Without this the options display behind the modal
.vs__dropdown-menu {
  z-index: 1090 !important;
}
</style>
