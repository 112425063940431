<template>
  <Widget v-if="!alert.dismiss && alert.dismissedAt == null">
    <div :class="className" class="alert-dismissable">
      <font-awesome-icon
        :icon="['fas', icon]"
        :class="className"
        size="lg"
      ></font-awesome-icon>
      <button
        type="button"
        class="btn-close dismiss-button"
        aria-label="Close"
        @click="dismissAlert"
      ></button>
      <span class="alert-span">{{ alert.summary }}</span>
    </div>
  </Widget>
</template>

<script setup>
import { ref, inject, computed } from 'vue'
import Widget from '@/components/content/widgets/Widget'

const emit = defineEmits(['dismiss-alert'])

const width = 2
const props = defineProps({
  alert: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

const icon = computed(() => {
  switch (props.alert.alertLevel) {
    case 'URGENT':
      return 'triangle-exclamation'

    case 'WARNING':
      return 'circle-exclamation'

    case 'INFO':
    default:
      return 'circle-info'
  }
})

const className = computed(() => {
  switch (props.alert.alertLevel) {
    case 'URGENT':
      return 'text-red'

    case 'WARNING':
      return 'text-orange'

    case 'INFO':
    default:
      return 'text-blue'
  }
})

const dismissAlert = async () => {
  emit('dismiss-alert', props.alert)
  // await api.put(ss`/alerts/${props.alert.tenantAlertId}`, { alert: props.alert })
}
</script>

<style lang="scss">
.alert-span {
  padding-left: 1rem;
}

.text-orange path,
.text-orange span {
  color: $info-color;
}

.text-red path,
.text-red span {
  color: $error-color;
}

.text-blue path,
.text-blue span {
  color: $braid-blue;
}

.dismiss-button {
  float: right;
}
</style>
