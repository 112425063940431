<template>
  <ValidationProvider v-slot="{ errors, changes }">
    <ActionBar
      :buttons="buttons"
      :conditions="{ 0: { disable: !changes || errors } }"
    />

    <TableContainer>
      <TablePanel :cols="2" :width="4">
        <template #1>
          <TableForm>
            <!-- ITEM TITLE -->
            <TableRow
              v-model="local.itemTitle"
              label="Item"
              type="text"
              read-only
            ></TableRow>
            <!-- ./ITEM TITLE -->

            <!-- MESSAGE STATUS-->
            <TableRow
              v-model="local.messageStatus"
              label="Status"
              type="text"
              read-only
            ></TableRow>
            <!-- ./MESSAGE STATUS -->
          </TableForm>
        </template>
        <template #2>
          <TableForm>
            <!-- CREATED AT-->
            <TableRow
              v-model="local.channelCreatedAt"
              label="Created date"
              type="text"
              read-only
            ></TableRow>
            <!-- ./CREATED AT -->

            <!-- UPDATED AT -->
            <TableRow
              v-model="local.channelUpdatedAt"
              label="Last updated"
              type="text"
              read-only
            ></TableRow>
            <!-- ./UPDATED AT -->
          </TableForm>
        </template>
      </TablePanel>
      <TablePanel>
        <template #1>
          <TableForm :style="'height: 30rem'">
            <!-- SENDER ID -->
            <TableRow
              v-model="local.senderId"
              label="Sender"
              type="text"
              read-only
            ></TableRow>
            <!-- ./SENDER ID -->

            <!-- SUBJECT -->
            <TableRow
              v-model="local.subject"
              label="Subject"
              type="textarea"
              read-only
            ></TableRow>
            <!-- ./SUBJECT -->

            <!-- BODY -->
            <TableRow
              v-model="local.body"
              label="Message body"
              type="textarea"
              :style="'height: 60%'"
              read-only
            ></TableRow>
            <!-- ./BODY -->
          </TableForm>
        </template>
      </TablePanel>
    </TableContainer>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    >
    </ConfirmLeaveFormModal>

    <BraidModal
      ref="replyModal"
      @confirm="createReply"
      @close="closeReplyModal"
      @cancel="closeReplyModal"
    >
      <template #header>Message reply</template>
      <template #body>
        <TableForm>
          <TableRow
            v-model="messageReply.subject"
            label="Subject"
            type="text"
          ></TableRow>
          <TableRow
            v-model="messageReply.message"
            label="Message"
            type="textarea"
          ></TableRow>
        </TableForm>
      </template>
    </BraidModal>
  </ValidationProvider>
</template>

<script setup>
import { ref, onMounted, nextTick, inject, computed } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})
import { dayjs } from '@/dayjs'
import useApi from '@/components/useApi'

import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'
import BraidModal from '@/components/modals/Modal.vue'
import ActionBar from '@/components/content/ActionBar'

import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

const router = useRouter()
const route = useRoute()
const api = useApi()

const utcOffset = ref(null)
const messageId = ref(false)
const local = ref({})
const confirmLeaveModal = ref(null)
const confirmModalNext = ref(null)

const props = defineProps({
  id: {
    type: [String, Number],
    default: ''
  }
})
/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')
const messageReply = ref({})
const replyModal = ref()

const openReplyModal = () => {
  messageReply.value = {}
  replyModal.value.show()
}

const closeReplyModal = () => {
  messageReply.value = {}
}

const createReply = () => {
  api.post('/messages/reply', {
    reply: messageReply.value,
    message: local.value
  })
}

const setTitle = async () => {
  let pageTitle = local.value.senderId
    ? `Message from ${local.value.senderId}`
    : 'Create message'
  document.getElementById('pagetitle').innerHTML = pageTitle
  document.title = pageTitle
}

const getMessageId = () => {
  messageId.value = false
  if (props.id && parseInt(props.id) > 0) {
    messageId.value = props.id
  }
}

const getMessage = async () => {
  if (!messageId.value || messageId.value === 'new') {
    return
  }
  let response = await api.get('/messages/' + messageId.value)
  handleResponse(response.data)
}

const getData = async () => {
  loading()
  try {
    progressBarStart()
    utcOffset.value = dayjs().utcOffset()
    getMessageId()
    await getMessage()
    setTitle()
    loaded()
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
    router.back()
  }
}

getData()

const insert = async () => {
  let response = await api.post('/messages/', { message: local.value })
  message.value = String(response.data.insertedId)
  router.replace('/messages/' + messageId.value)
  handleResponse(response.data)
  setTitle()
}

const update = async () => {
  if (!messageId.value) {
    insert()
    return
  }
  await api.put('/messages/' + messageId.value, { message: local.value })
}

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
  confirmModalNext.value()
}

const closeConfirmModal = () => {
  confirmModalNext.value = null
  confirmLeaveModal.value.hide()
}

const handleResponse = (data) => {
  let message = data.messages[0]
  local.value = {
    tenantMessageId: messageId,
    tenantChannelId: message.tenantChannelId,
    channelMessageId: message.channelMessageId,
    itemId: message.itemId,
    itemTitle: message.itemTitle,
    messageStatus: message.messageStatus,
    senderEmail: message.senderEmail,
    senderId: message.senderId,
    subject: message.subject,
    body: message.body,
    channelCreatedAt: message.channelCreatedAt
      ? dayjs(message.channelCreatedAt)
          .utcOffset(utcOffset.value)
          .format('Do MMM YYYY HH:mm')
      : null,
    channelUpdatedAt: message.channelUpdatedAt
      ? dayjs(message.channelUpdatedAt)
          .utcOffset(utcOffset.value)
          .format('Do MMM YYYY HH:mm')
      : null
  }
}

const buttons = [
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  },
  {
    id: 2,
    label: 'Reply',
    click: openReplyModal,
    icon: ['fa', 'reply']
  }
]
</script>

<style lang="scss">
.defaultDelivery {
  background-color: $braid-green-accessible;
  color: $braid-white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 1.563rem;
  text-align: center;
  padding: 0.3rem;
}
</style>
