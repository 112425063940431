<template>
  <TablePanel v-if="channels.length === 0">
    <template #1>
      You must set up an integration with either Royal Mail or Parcel2Go to
      create labels</template
    >
  </TablePanel>
  <TablePanel v-if="!hasAddress">
    <template #1
      >This order does not have an address so you cannot create a
      label</template
    >
  </TablePanel>
  <TablePanel v-else :cols="3" :width="4" header="Labels">
    <template #1>
      <TableForm>
        <TableRow
          v-model="selectedConsignment"
          label="Consignment"
          type="select"
          :options="dispatch.consignments"
          :reduce="(value) => value.tenantConsignmentId"
          display="consignmentNumber"
          :read-only="dispatch.consignments.length <= 1"
        ></TableRow>
        <TableRow
          v-model="estimated.totalValue"
          label="Estimated value"
          type="number"
        ></TableRow>
        <TableRow
          v-model="weight.kg"
          label="Weight (kg)"
          type="number"
        ></TableRow>
        <TableRow
          v-model="weight.g"
          label="Weight (g)"
          type="number"
        ></TableRow>
        <TableRow
          v-model="collectionLocationId"
          type="select"
          label="Collect from"
          :options="props.locations"
        ></TableRow>
        <TableRow type="custom">
          <div class="tbl-cell-btn">
            <button type="button" class="btn" @click="getData">
              <font-awesome-icon
                :icon="['fas', button.icon]"
              ></font-awesome-icon>
              <b>{{ button.text }}</b>
            </button>
          </div>
        </TableRow>
      </TableForm>
    </template>
    <template #2>
      <TableForm v-if="labelOptions.length > 0">
        <div
          v-for="(label, index) in labelOptions.slice(0, 3)"
          :key="index"
          class="row clickable"
        >
          <div class="col-1 radio-input">
            <input
              type="radio"
              :checked="selectedLabel == label"
              @click="selectedLabel = label"
            />
          </div>
          <div class="col-11 clickable">
            <div class="row">
              <div class="col-12">
                <b>{{ label.name }}</b>
              </div>
              <div class="col-12">
                {{ `£${label.price} - ${label.deliveryDate}` }}
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="
            selectedLabel.id != null &&
            !labelOptions.slice(0, 3).includes(selectedLabel)
          "
          class="row selected-option clickable"
        >
          <div class="col-1 radio-input">
            <input type="radio" checked />
          </div>
          <div class="col-11">
            <div class="row">
              <div class="col-12">
                <b>{{ selectedLabel.name }}</b>
              </div>
              <div class="col-12">
                {{ `£${selectedLabel.price} - ${selectedLabel.deliveryDate}` }}
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <button
            type="button"
            class="btn more-options offset-1"
            @click="showMoreOptions"
          >
            <font-awesome-icon
              :icon="['fas', 'chevron-down']"
              class="icon"
            ></font-awesome-icon>
            Show more options
          </button>
        </div>
      </TableForm>
    </template>
    <template #3>
      <div
        v-if="labelOptions.length > 0 && selectedLabel.id != undefined"
        class="label-action"
      >
        <button
          v-if="dispatch.consignments.length > 0 && hasAddress"
          :disabled="selectedLabel == null || buyLabelClicked"
          type="button"
          class="btn btn-purple"
          @click="buySingleLabel"
        >
          Buy label
        </button>
      </div>
    </template>
  </TablePanel>

  <BraidModal ref="labelOptionsModal">
    <template #title>Label Options</template>
    <template #body>
      <TableGrid
        ref="grid"
        :cols="labelOptionsCols"
        :rows="labelOptions"
        :total-count="labelOptions.length"
        checkbox
        select-single
      ></TableGrid>
    </template>
  </BraidModal>
</template>

<script setup>
import { computed, ref, watch, watchEffect } from 'vue'
import { useRouter } from 'vue-router'
import { dayjs } from '@/dayjs'
import useApi from '@/components/useApi'
import formatForSelect from '@/composables/formatForSelect'
import { CreateMultipleLabelPdf } from '@/composables/pdfs/multipleLabelPdf'
import { CreateIntegratedLabel } from '@/composables/pdfs/integratedLabelPdf'

const router = useRouter()
const api = useApi()
const emit = defineEmits('get-label', 'update-status')
const buyLabelClicked = ref(false)

const props = defineProps({
  order: {
    type: Object,
    default() {
      return {}
    }
  },
  channels: {
    type: Array,
    default() {
      return []
    }
  },
  locations: {
    type: Array,
    default() {
      return []
    }
  },
  dispatch: {
    type: Object,
    default() {
      return {}
    }
  },
  itemsTotal: {
    type: Number,
    default: 0.0
  }
})

import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

const createMultipleLabelPdf = new CreateMultipleLabelPdf()
const createIntegratedLabel = new CreateIntegratedLabel()

const estimated = ref({ totalValue: props.itemsTotal })
const collectionLocationId = ref()
const grid = ref(null)
const labelOptions = ref([])
const packageTypes = ref([])
const services = ref([])
const carrier = ref({})
const royalMailQuotes = ref([])
const parcel2GoQuotes = ref([])
const locations = ref([])

const selectedLabel = ref({
  id: null
})
const weight = ref({
  kg: null,
  g: null
})
const selectedConsignment = ref(null)

const order = ref(null)

const inchesToCm = (inches) => {
  return inches * 2.54
}

const convertMeasurement = (number, units) => {
  number = parseFloat(number)

  switch (units) {
    case 'IN':
      return inchesToCm(number)

    case 'CM':
      return number

    case 'MM':
      return number / 10

    case 'M':
      return number * 100
  }
}

const getGramWeight = () => {
  if (weight.value.g) {
    return parseFloat(weight.value.g)
  }

  if (weight.value.kg) {
    return parseFloat(weight.value.kg * 1000)
  }

  return 0.0
}

const relevantPackageType = computed(() => {
  let dimensionUnits = props.dispatch.dimensionUnits
  let width = convertMeasurement(props.dispatch.width, dimensionUnits)
  let height = convertMeasurement(props.dispatch.height, dimensionUnits)
  let depth = convertMeasurement(props.dispatch.depth, dimensionUnits)
  let weight = getGramWeight()

  let possiblePackageTypes = packageTypes.value.filter((packageType) => {
    return (
      parseFloat(packageType.maxWidthInCm) >= width &&
      parseFloat(packageType.maxHeightInCm) >= height &&
      parseFloat(packageType.maxDepthInCm) >= depth &&
      parseFloat(packageType.maxWeightInGrams) >= weight
    )
  })

  if (possiblePackageTypes.length === 0) {
    return 'none'
  }

  if (possiblePackageTypes.length === 1) {
    return possiblePackageTypes[0]
  }

  let sorted = possiblePackageTypes.sort((a, b) => {
    return a.packageTypeId - b.packageTypeId
  })

  return sorted[0]
})

const button = computed(() => {
  if (labelOptions.value.length === 0) {
    return {
      text: 'Get quotes',
      icon: 'circle-plus'
    }
  }

  return {
    text: 'Refresh quotes',
    icon: 'arrows-rotate'
  }
})

const handleRoyalMailData = (data) => {
  let carriers = data.carriers
  let allPackageTypes = data.packageTypes
  let allServices = data.services
  let channels = data.channels

  if (!channels || channels.length === 0) {
    return
  }

  let royalMailCarrier = carriers.find((carrier) => {
    return carrier.carrierName === 'ROYAL_MAIL'
  })

  let carrierPackageTypes = allPackageTypes.filter((packageType) => {
    return packageType.carrierId == royalMailCarrier.carrierId
  })

  let services = allServices.filter((service) => {
    return service.carrierId == royalMailCarrier.carrierId
  })

  let formatted = []
  let index = 0
  let length = services.length
  for (index; index < length; index++) {
    let service = services[index]

    formatted.push({
      id: service.serviceCode,
      name: service.serviceName,
      serviceCode: service.serviceCode,
      serviceName: service.serviceName,
      price: 'User account dependant',
      channel: 'ROYAL_MAIL',
      tenantChannelId: channels[0].tenantChannelId,
      deliveryDate: ''
    })
  }

  packageTypes.value = carrierPackageTypes
  carrier.value = royalMailCarrier
  services.value = services
  royalMailQuotes.value = formatted
}

const royalMailData = async () => {
  let response = await api.get('/royal-mail/quotes')
  handleRoyalMailData(response.data)
}

const handleParcel2GoData = (data) => {
  let quotes = data.quotes
  let formattedQuotes = []
  let index = 0
  let length = quotes.length

  for (index; index < length; index++) {
    let quote = quotes[index]
    let value = quote.Service.Slug
    let price = quote.TotalPrice.toFixed(2)
    let deliveryDate = quote.EstimatedDeliveryDate
    let tenantChannelId = quote.tenantChannelId
    let channel = quote.channel

    if (deliveryDate) {
      deliveryDate = dayjs(deliveryDate).format('ddd, Do MMM')
    }

    formattedQuotes.push({
      id: value,
      name: quote.Service.Name,
      price: price,
      deliveryDate: deliveryDate,
      tenantChannelId: tenantChannelId,
      channel: channel
    })
  }

  parcel2GoQuotes.value = formattedQuotes
}

const parcel2GoData = async (args) => {
  let response = await api.get('/parcel2Go/quotes', {
    estimatedValue: estimated.value.totalValue,
    order: props.order,
    dispatch: props.dispatch,
    weight: weight.value
  })
  handleParcel2GoData(response.data)
}

const formatLabelOptions = () => {
  labelOptions.value = parcel2GoQuotes.value.sort((a, b) => {
    return a.price - b.price
  })

  labelOptions.value.push(...royalMailQuotes.value)
}

const setDefaultData = () => {
  if (props.dispatch.consignments.length > 0) {
    selectedConsignment.value =
      props.dispatch.consignments[0].tenantConsignmentId
  }

  if (labelOptions.value.length > 0) {
    selectedLabel.value = labelOptions.value[0]
  }

  props.locations.forEach((location) => {
    if (location.isDefaultDelivery) {
      collectionLocationId.value = location.tenantLocationId
    }
  })
}

const getData = async (args = {}) => {
  if (!weight.value.kg && !weight.value.g) {
    return
  }

  await royalMailData(args)
  await parcel2GoData(args)
  formatLabelOptions()
}

getData()
setDefaultData()

const hasAddress = computed(() => {
  let fields = [
    'shippingAddrLine1',
    'shippingAddrPostcode',
    'shippingAddrCountry'
  ]

  let index = 0
  let length = fields.length
  for (index; index < length; index++) {
    let field = fields[index]
    let value = props.dispatch.order[field]

    if (!value || value == '') {
      return false
    }
  }

  return true
})

const buySingleLabel = async () => {
  if (!hasAddress.value) {
    return
  }

  buyLabelClicked.value = true

  let channel = selectedLabel.value.channel

  switch (channel) {
    case 'ROYAL_MAIL':
      await buyRoyalMailLabel()
      break

    case 'PARCEL2GO':
      await buyParcel2GoLabel()
      break
  }

  blankData()
  emit('update-status')
}

const blankData = () => {
  selectedLabel.value = { id: null }
  labelOptions.value = []
  weight.value = { g: null, kg: null }
}

const buyParcel2GoLabel = async () => {
  let collectionLocation = props.locations.find((location) => {
    return location.tenantLocationId == collectionLocationId.value
  })
  let response = await api.post('/parcel2Go/create-label', {
    tenantChannelId: selectedLabel.value.tenantChannelId,
    dispatch: props.dispatch,
    order: props.order,
    service: selectedLabel.value.id,
    collectionLocation: collectionLocation,
    weight: weight.value,
    estimatedValue: estimated.value.totalValue
  })

  let label = response.data.label
  let carrier = {
    carrierName: 'PARCEL2GO',
    displayName: 'Parcel2Go'
  }
  let service = {
    serviceCode: selectedLabel.value.id,
    serviceName: selectedLabel.value.name
  }
  let dispatch = props.dispatch

  let labelResponse = await api.post('/labels/', {
    labels: [
      {
        label,
        carrier,
        service,
        dispatch
      }
    ]
  })

  let tenantLabelIds = labelResponse.data.tenantLabelIds
  let tenantLabelId = tenantLabelIds[0]

  emit('get-label', tenantLabelId)
}

const buyRoyalMailLabel = async () => {
  let order = props.order
  let dispatch = props.dispatch

  let labelData = {
    orders: [
      {
        tenantDispatchId: dispatch.tenantDispatchId,
        orderNumber: order.channelOrderId,
        createdAt: order.orderDate,
        itemsSubTotal: estimated.value.totalValue,
        shippingCostsCharged: order.shipping,
        currency: order.currency,
        phone: order.phone,
        email: order.email,
        customerName: order.customerName,
        shippingAddrLine1: order.shippingAddrLine1,
        shippingAddrLine2: order.shippingAddrLine2,
        shippingAddrLine3: order.shippingAddrLine3,
        shippingAddrPostcode: order.shippingAddrPostcode,
        shippingAddrCountry: order.shippingAddrCountry,
        weight: weight.value,
        packageFormatIdentifier: relevantPackageType.value.packageName,
        serviceCode: selectedLabel.value.id
      }
    ]
  }

  let response = await api.post('/royal-mail/orders', {
    labelData,
    tenantChannelId: selectedLabel.value.tenantChannelId,
    carrier: 'ROYAL_MAIL'
  })

  // let label = response.data.createdLabels[0]
  // label.tenantChannelId = selectedLabel.value.tenantChannelId
  // let service = labelOptions.value.find((item) => {
  //   return item.id == selectedLabel.value.id
  // })
  // let labelResponse = await api.post('/labels/', {
  //   labels: [
  //     {
  //       label: label,
  //       carrier: carrier.value,
  //       packageType: relevantPackageType.value,
  //       service: service,
  //       dispatch: dispatch
  //     }
  //   ]
  // })

  // let tenantLabelIds = labelResponse.data.tenantLabelIds
  // let tenantLabelId = tenantLabelIds[0]

  // emit('get-label', tenantLabelId)
}

const buyAllLabels = () => {}

watch(
  () => weight.value,
  (current, previous) => {
    if (current != previous) {
      getData()
    }
  }
)

const labelOptionsModal = ref(null)
const showMoreOptions = () => {
  labelOptionsModal.value.show()
}

const labelOptionsCols = ref([
  {
    label: 'Carrier',
    name: 'carrier',
    visible: true
  },
  {
    label: 'Name',
    name: 'name',
    visible: true
  },
  {
    label: 'Price',
    name: 'price',
    visible: true
  }
])

watchEffect(() => {
  if (!grid.value || !grid.value.selected) {
    return
  }

  if (grid.value.selected.length === 0) {
    selectedLabel.value = { id: null }
    return
  }

  selectedLabel.value = grid.value.selected[0]
})
</script>

<style scoped lang="scss">
.clickable {
  cursor: pointer;
}

.label-action {
  display: inline-flex;
  flex-direction: column;

  button {
    border-color: $braid-purple;
    &.secondary {
      color: $braid-purple;
      background-color: $braid-grey-2;
    }
  }

  button + button {
    margin-top: 0.5rem;
  }
}

.radio-input {
  display: flex;
  align-items: center;
  justify-content: center;
}

.more-options {
  width: fit-content;
  color: $braid-purple;
  & svg.icon {
    path {
      fill: $braid-purple;
    }
  }
}

.selected-option {
  border-top: 1px solid $braid-grey-3;
}
</style>
