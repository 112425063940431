import dayjs from 'dayjs'
import * as advancedFormat from 'dayjs/plugin/advancedFormat'
import * as customParseFormat from 'dayjs/plugin/customParseFormat'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as isSameOrBefore from 'dayjs/plugin/isSameOrBefore'

const DATE_FORMAT_STRING = 'DD/MM/YYYY'
const DATE_INPUT_FORMAT_STRING = 'YYYY-MM-DD'
const DATE_TIME_FORMAT_STRING = 'DD/MM/YYYY HH:mm'
const SAVE_DATE_TIME_FORMAT_STRING = 'YYYY-MM-DD HH:mm:ss'
const SAVE_DATE_FORMAT_STRING = 'YYYY-MM-DD'
// OLD STRING: 'Do MMM YYYY HH:mm'

dayjs.extend(advancedFormat)
dayjs.extend(customParseFormat)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)

function setTimezone(timezone) {
  if (!timezone || timezone === 'null') {
    timezone = dayjs.tz.guess()
  }
  dayjs.tz.setDefault(timezone)
}

function formatNow() {
  return dayjs().format(DATE_FORMAT_STRING)
}

function formatDateTimeNow() {
  return dayjs().format(DATE_TIME_FORMAT_STRING)
}

function formatDateInput(date, inputFormat = 'YYYY-MM-DD') {
  let formatted_date = date
    ? dayjs(date, inputFormat).format(DATE_INPUT_FORMAT_STRING)
    : null
  return formatted_date
}

function formatDate(date, inputFormat = 'YYYY-MM-DD') {
  let formatted_date = date
    ? dayjs(date, inputFormat).format(DATE_FORMAT_STRING)
    : null
  return formatted_date
}

function formatDateTime(date = null, inputFormat = 'YYYY-MM-DD HH:mm:ss') {
  let formatted_date =
    date != null
      ? dayjs(date, inputFormat).tz().format(DATE_TIME_FORMAT_STRING)
      : null
  return formatted_date
}

function formatDateForSave(date, inputFormat = 'YYYY-MM-DD') {
  let formatted_date = date
    ? dayjs(date, inputFormat).format(SAVE_DATE_FORMAT_STRING)
    : null
  return formatted_date
}

function formatDateTimeForSave(date, offset, inputFormat = 'DD/MM/YYYY HH:mm') {
  let formatted_date =
    date != null && offset != null
      ? dayjs(date, inputFormat)
          .utcOffset(offset)
          .format(SAVE_DATE_TIME_FORMAT_STRING)
      : null
  return formatted_date
}

function UTC_OFFSET() {
  const offset = dayjs().utcOffset()
  return offset
}

export {
  setTimezone,
  formatNow,
  formatDateTimeNow,
  formatDate,
  formatDateTime,
  formatDateTimeForSave,
  formatDateForSave,
  formatDateInput,
  UTC_OFFSET,
  dayjs,
  DATE_FORMAT_STRING
}
