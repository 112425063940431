<template>
  <Widget :header="header" :width="75">
    <TableGrid
      ref="grid"
      :pagination="false"
      :cols="columns"
      :rows="rows"
      :total-count="totalCount"
      :limit="limit"
      :id-key="idKey"
      :truncate="true"
    >
    </TableGrid>
    <div class="footer">
      <span style="float: left">
        {{ footerText }}
      </span>
      <button
        v-if="link"
        style="float: right"
        class="btn"
        @click.stop.prevent="router.push(link)"
      >
        {{ linkText }}
        <FontAwesomeIcon
          :icon="['fas', 'arrow-right']"
          class="btn-icon"
        ></FontAwesomeIcon>
      </button>
    </div>
  </Widget>
</template>

<script setup>
import { ref, inject, computed, nextTick } from 'vue'
import Widget from '@/components/content/widgets/Widget'
import { useRouter } from 'vue-router'
import { pageLoad } from '@/composables/pageLoad'

const { loading, loaded, getLoading } = pageLoad()
const redrawVueMasonry = inject('redrawVueMasonry')
const router = useRouter()
const grid = ref(null)
const channels = ref([])

const pageLoading = computed(() => {
  return getLoading()
})
const footerText = computed(() => {
  let rowCount = props.limit
  if (props.totalCount < props.limit) {
    rowCount = props.totalCount
  }

  let startNumber = 1

  if (props.totalCount == 0) {
    startNumber = 0
  }

  return `Rows ${startNumber} to ${rowCount} of ${props.totalCount}`
})

const props = defineProps({
  columns: {
    type: Array,
    default() {
      return []
    }
  },
  rows: {
    type: Array,
    default() {
      return []
    }
  },
  header: {
    type: String,
    default: ''
  },
  link: {
    type: String,
    default: ''
  },
  idKey: {
    type: String,
    default: ''
  },
  linkText: {
    type: String,
    default: ''
  },
  totalCount: {
    type: Number,
    default: 0
  },
  limit: {
    type: Number,
    default: 0
  },
  defaultSort: {
    type: Object,
    default() {
      return {}
    }
  },
  getData: {
    type: Function,
    default: () => {}
  }
})

const getTableData = async () => {
  loading()
  await props.getData()
  loaded()
  nextTick(() => {
    redrawVueMasonry()
  })
}

getTableData()
</script>

<style scoped lang="scss">
.footer {
  height: 2rem;
}
</style>
