import { ref } from 'vue'
import { jsPDF } from 'jspdf'
const _ = require('lodash')
const { formatNow } = require('@/dayjs')

export default function () {
  const lineHeight = ref(5)
  const pageHeight = ref(300)
  const headerHeight = ref(20)
  const footerHeight = ref(20)
  const pageHeadInfoHeight = ref(50)
  const xCol1 = ref(15)
  const xCol2 = ref(50)
  const yStart = ref(20) // Starting line
  const y = ref(0)
  const pageCount = ref(1)
  const tenant = ref({})
  const locations = ref([])
  const date = ref(formatNow())
  const usesUnits = ref(false)
  const usesCases = ref(false)
  const hasVariants = ref(false)
  const invoiceSubtotal = ref(0.0)
  const invoiceVatTotal = ref(0.0)

  const createInvoicePdf = async (invoice, tenant, type) => {
    pageCount.value = 1
    y.value = yStart.value

    const doc = new jsPDF('p', 'mm', 'a4') // 'p' = portrait

    let billingAddress = {
      company: invoice.billingAddrCompany || '',
      country: invoice.billingAddrCountry || '',
      addrLine1: invoice.billingAddrLine1 || '',
      addrLine2: invoice.billingAddrLine2 || '',
      addrLine3: invoice.billingAddrLine3 || '',
      postcode: invoice.billingAddrPostcode || ''
    }
    let dispatchAddress = {
      company: invoice.dispatchAddrCompany || '',
      country: invoice.dispatchAddrCountry || '',
      addrLine1: invoice.dispatchAddrLine1 || '',
      addrLine2: invoice.dispatchAddrLine2 || '',
      addrLine3: invoice.dispatchAddrLine3 || '',
      postcode: invoice.dispatchAddrPostcode || ''
    }

    if (
      !_checkdispatchAddress(dispatchAddress) &&
      (!_checkBillingAddress(billingAddress) ||
        !invoice.useDispatchAddrForBilling)
    ) {
      console.log('Address not right')
      return
    }

    pageCount.value = _getPageCount(invoice)

    doc.setFontSize(24)
    doc.setFont('Helvetica', 'bold')
    doc.text('INVOICE', xCol1.value, y.value)
    doc.setFontSize(14)
    doc.setFont('Helvetica', 'normal')
    doc.text(invoice.invoiceNumber, xCol1.value + 40, y.value)

    // Tenant Detail Title
    doc.setFontSize(6)
    doc.setFont('Helvetica', 'bold')
    doc.text('Email:', xCol1.value + 120, y.value)
    doc.text('Phone:', xCol1.value + 120, y.value + 5)
    doc.text('Customer Ref:', xCol1.value + 120, y.value + 15)

    // Tenant Detail Data
    doc.setFont('Helvetica', 'normal')
    doc.text(tenant.contactEmail, xCol1.value + 140, y.value)
    doc.text(tenant.contactPhone || '', xCol1.value + 140, y.value + 5)
    doc.text(invoice.invoiceNumber, xCol1.value + 140, y.value + 15)

    if (invoice.order.orderNumber) {
      doc.setFontSize(6)
      doc.setFont('Helvetica', 'bold')
      doc.text('Order Ref:', xCol1.value + 120, y.value + 10)
      doc.setFont('Helvetica', 'normal')
      doc.text(invoice.order.orderNumber, xCol1.value + 140, y.value + 10)
    }

    if (invoice.dueDate) {
      doc.setFontSize(6)
      doc.setFont('Helvetica', 'bold')
      doc.text('Due Date:', xCol1.value + 120, y.value + 25)
      doc.setFont('Helvetica', 'normal')
      doc.text(invoice.dueDate, xCol1.value + 140, y.value + 25)
    }

    doc.setFontSize(8)
    doc.setFont('Helvetica', 'bold')
    doc.text('Dispatch To:', xCol1.value, y.value + 10)
    doc.text('Invoice to:', xCol1.value + 40, y.value + 10)

    doc.setFontSize(6)
    doc.setFont('Helvetica', 'normal')
    doc.text(dispatchAddress.company, xCol1.value, y.value + 15)
    doc.text(dispatchAddress.addrLine1, xCol1.value, y.value + 20)
    doc.text(dispatchAddress.addrLine2, xCol1.value, y.value + 25)
    doc.text(dispatchAddress.addrLine3, xCol1.value, y.value + 30)
    doc.text(dispatchAddress.postcode, xCol1.value, y.value + 35)

    if (invoice.useDispatchAddrForBilling) {
      doc.text(dispatchAddress.company, xCol1.value + 40, y.value + 15)
      doc.text(dispatchAddress.addrLine1, xCol1.value + 40, y.value + 20)
      doc.text(dispatchAddress.addrLine2, xCol1.value + 40, y.value + 25)
      doc.text(dispatchAddress.addrLine3, xCol1.value + 40, y.value + 30)
      doc.text(dispatchAddress.postcode, xCol1.value + 40, y.value + 35)
    } else {
      doc.text(billingAddress.company, xCol1.value + 40, y.value + 15)
      doc.text(billingAddress.addrLine1, xCol1.value + 40, y.value + 20)
      doc.text(billingAddress.addrLine2, xCol1.value + 40, y.value + 25)
      doc.text(billingAddress.addrLine3, xCol1.value + 40, y.value + 30)
      doc.text(billingAddress.postcode, xCol1.value + 40, y.value + 35)
    }

    let currentHeight = pageHeadInfoHeight.value
    doc.setFontSize(8)
    doc.setFont('Helvetica', 'bold')

    // Top of Table
    doc.line(
      xCol1.value,
      y.value + currentHeight,
      xCol1.value + 180,
      y.value + currentHeight
    )
    currentHeight += lineHeight.value

    doc.text('Our Sku', xCol1.value, y.value + currentHeight)
    doc.text('Title', xCol1.value + 20, y.value + currentHeight)
    doc.text('Quantity', xCol1.value + 140, y.value + currentHeight, {
      align: 'right'
    })
    doc.text('Value', xCol1.value + 160, y.value + currentHeight, {
      align: 'right'
    })
    doc.text('Subtotal', xCol1.value + 180, y.value + currentHeight, {
      align: 'right'
    })

    currentHeight += lineHeight.value
    doc.line(
      xCol1.value,
      y.value + currentHeight - 3,
      xCol1.value + 180,
      y.value + currentHeight - 3
    )
    // currentHeight += lineHeight.value

    doc.setFont('Helvetica', 'normal')
    for (let index in invoice.invoiceItems) {
      if (
        y.value + currentHeight >
        pageHeight.value - footerHeight.value - y.value
      ) {
        doc.text(
          `Order Number: ${invoice.order.channelOrderId}`,
          xCol1.value + 80,
          pageHeight.value - 30
        )
        doc.text(
          `Page ${1} of ${pageCount.value}`,
          xCol1.value + 150,
          pageHeight.value - 30
        )
        doc.addPage()
        currentHeight = y.value
        doc.setFontSize(24)
        doc.setFont('Helvetica', 'bold')
        doc.text('INVOICE', xCol1.value, y.value)

        // Tenant Detail Title
        doc.setFontSize(6)
        doc.text('Email:', xCol1.value + 120, y.value)
        doc.text('Phone:', xCol1.value + 120, y.value + 5)
        doc.text('Customer Ref:', xCol1.value + 120, y.value + 15)

        // Tenant Detail Data
        doc.setFont('Helvetica', 'normal')
        doc.text(tenant.contactEmail, xCol1.value + 140, y.value)
        doc.text(tenant.contactPhone, xCol1.value + 140, y.value + 5)
        doc.text(invoice.invoiceNumber, xCol1.value + 140, y.value + 15)

        // Top of Table
        doc.line(
          xCol1.value,
          y.value + currentHeight,
          xCol1.value + 180,
          y.value + currentHeight
        )
        currentHeight += lineHeight.value

        doc.setFontSize(8)
        doc.setFont('Helvetica', 'bold')

        doc.text('Our Sku', xCol1.value, y.value + currentHeight)
        doc.text('Title', xCol1.value + 20, y.value + currentHeight)
        doc.text('Quantity', xCol1.value + 140, y.value + currentHeight, {
          align: 'right'
        })
        doc.text('Value', xCol1.value + 160, y.value + currentHeight, {
          align: 'right'
        })
        doc.text('Subtotal', xCol1.value + 180, y.value + currentHeight, {
          align: 'right'
        })

        currentHeight += lineHeight.value
        doc.line(
          xCol1.value,
          y.value + currentHeight - 3,
          xCol1.value + 180,
          y.value + currentHeight - 3
        )
        doc.setFont('Helvetica', 'normal')
      }

      let item = invoice.invoiceItems[index]

      let subtotal = item.quantity * item.price
      invoiceSubtotal.value += subtotal
      invoiceVatTotal.value += parseFloat(item.lineVat || 0.0)
      doc.text(`${item.sku || ''}`, xCol1.value, y.value + currentHeight)
      doc.text(`${item.title}`, xCol1.value + 20, y.value + currentHeight)
      doc.text(`${item.quantity}`, xCol1.value + 140, y.value + currentHeight, {
        align: 'right'
      })
      doc.text(`${item.price}`, xCol1.value + 160, y.value + currentHeight, {
        align: 'right'
      })
      doc.text(
        `${subtotal.toFixed(2)}`,
        xCol1.value + 180,
        y.value + currentHeight,
        {
          align: 'right'
        }
      )
      currentHeight += lineHeight.value
    }
    currentHeight -= lineHeight.value
    doc.line(
      xCol1.value,
      y.value + currentHeight + 1,
      xCol1.value + 180,
      y.value + currentHeight + 1
    )
    currentHeight += lineHeight.value

    doc.setFont('Helvetica', 'bold')
    doc.text('Subtotal', xCol1.value + 150, y.value + currentHeight, {
      align: 'right'
    })
    doc.setFont('Helvetica', 'normal')
    doc.text(
      `£ ${invoiceSubtotal.value.toFixed(2)}`,
      xCol1.value + 180,
      y.value + currentHeight,
      {
        align: 'right'
      }
    )
    currentHeight += lineHeight.value

    doc.setFont('Helvetica', 'bold')
    doc.text('VAT', xCol1.value + 150, y.value + currentHeight, {
      align: 'right'
    })
    doc.setFont('Helvetica', 'normal')
    doc.text(
      `£ ${invoiceVatTotal.value.toFixed(2)}`,
      xCol1.value + 180,
      y.value + currentHeight,
      {
        align: 'right'
      }
    )
    currentHeight += lineHeight.value

    let total = invoiceSubtotal.value + invoiceVatTotal.value
    doc.setFont('Helvetica', 'bold')
    doc.text('Amount Due', xCol1.value + 150, y.value + currentHeight, {
      align: 'right'
    })
    doc.text(
      `£ ${total.toFixed(2)}`,
      xCol1.value + 180,
      y.value + currentHeight,
      {
        align: 'right'
      }
    )
    currentHeight += lineHeight.value

    // doc.text(
    //   `Order Number: ${invoice.order.channelOrderId}`,
    //   xCol1.value + 80,
    //   pageHeight.value - 30
    // )
    doc.text(
      `Page ${1} of ${pageCount.value}`,
      xCol1.value + 150,
      pageHeight.value - 30
    )

    let filename = invoice.invoiceNumber + '.pdf'
    if (type == 'download') {
      doc.save(filename)
      return
    }
    let blob = doc.output('blob', { filename })
    blob.name = filename
    let url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }

  const _getPageCount = (invoice) => {
    let noOfItems = invoice.invoiceItems.length
    let currentPageHeight = y.value + 50

    let noOfPages = 1

    while (noOfItems > 0) {
      if (currentPageHeight < pageHeight.value - footerHeight.value - y.value) {
        currentPageHeight + lineHeight.value
      } else {
        noOfPages++
        currentPageHeight = y.value + headerHeight.value
      }
      noOfItems--
    }
    return noOfPages
  }

  const _checkdispatchAddress = (address) => {
    if (
      address.addrLine1 == undefined ||
      address.addrLine2 == undefined ||
      address.addrLine3 == undefined ||
      address.postcode == undefined
    ) {
      return false
    }
    return true
  }

  const _checkBillingAddress = (address) => {
    if (
      address.addrLine1 == undefined ||
      address.addrLine2 == undefined ||
      address.addrLine3 == undefined ||
      address.postcode == undefined
    ) {
      return false
    }
    return true
  }

  const _getColumns = (invoice) => {
    let columns = []
    for (let [key, value] in Object.entries(invoice)) {
      if (value && value != -1) {
        columns.push(key)
      }
    }
  }

  return { createInvoicePdf }
}
