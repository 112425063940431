<template>
  <div class="row">
    <div class="col-12 title-row">
      <h1 v-if="title">{{ title }}</h1>
    </div>

    <div class="col-12 steps-container-1">
      <div class="steps">
        <template v-for="(step, idx) in stepObjects" :key="step + idx">
          <span
            class="step-item"
            :class="{
              active: activeStepIndex == idx,
              completed: stepObjects[idx].completed,
              available: idx === 0 || stepObjects[idx - 1].completed,
              disabled: !canSetStep(idx)
            }"
            @click="setStep(idx)"
          >
            <span class="dot">
              <FontAwesomeIcon
                v-if="icons[stepObjects[idx].title]"
                :icon="icons[stepObjects[idx].title]"
                class="icon"
                :class="{ active: activeStepIndex == idx }"
              ></FontAwesomeIcon>
            </span>
            <div class="step-connector"></div>
            <p class="step-title">
              {{ step.title }}
            </p>
          </span>
        </template>
      </div>
    </div>
  </div>

  <!-- Putting the named slots in default slot means the first page will be rendered by default -->
  <slot>
    <template v-for="slot in slotNames">
      <slot
        v-if="steps[activeStepIndex] === slot.displayName"
        :name="slot.slotName"
      >
      </slot>
    </template>
  </slot>

  <div class="text-center">
    <div class="btn-row">
      <button
        :disabled="!canSetStep(activeStepIndex - 1) || !allowPrevious"
        :class="{
          disabled: !canSetStep(activeStepIndex - 1) || !allowPrevious
        }"
        class="btn step-btn"
        @click="prevStep"
      >
        <FontAwesomeIcon :icon="['fas', 'angle-left']"></FontAwesomeIcon>
        Previous
      </button>

      <button
        v-if="activeStepIndex !== steps.length - 1"
        :disabled="!canSetStep(activeStepIndex + 1)"
        :class="{ disabled: !canSetStep(activeStepIndex + 1) }"
        class="btn step-btn"
        @click="nextStep"
      >
        {{ nextText }}
        <FontAwesomeIcon :icon="['fas', 'angle-right']"></FontAwesomeIcon>
      </button>

      <button
        v-else
        :disabled="!canSetStep(activeStepIndex + 1)"
        :class="{ disabled: !canSetStep(activeStepIndex + 1) }"
        class="btn step-btn"
        @click="$emit('complete-wizard')"
      >
        {{ finishStepText }}
        <FontAwesomeIcon :icon="['fas', 'angle-right']"></FontAwesomeIcon>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, watch, computed } from 'vue'

const props = defineProps({
  title: {
    type: String,
    default() {
      return ''
    }
  },
  nextText: {
    type: String,
    default() {
      return 'Next'
    }
  },
  finishStepText: {
    type: String,
    default() {
      return 'Finish'
    }
  },
  steps: {
    type: Array,
    default() {
      return []
    },
    required: true
  },
  icons: {
    type: Object,
    default() {
      return {}
    }
  },
  allowPrevious: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(['complete-wizard', 'complete-step'])

/**
 * Allows us to keep track of which steps have been completed
 */

const generateInitialStepObjects = () => {
  return props.steps.map((step) => {
    return { title: step, completed: false }
  })
}

const stepObjects = ref(generateInitialStepObjects())

/**
 * Called in reaction to a change in props.steps array
 *
 * NOTE: this method keeps current steps based on the step title.
 * Therefore it is important that each step has a unique name to make sure it works as expected.
 *
 * E.g,   amazon adds a new step for "auth0", spotify also adds an "auth0" step.
 *        Make sure to title them uniquely, for example "Amazon auth0" and "Spotify auth0"
 */
const generateNewStepObjects = () => {
  const newSteps = [].concat(props.steps)
  // Find which steps we should keep
  // I.e, steps that are already exist and are
  // included in the new props.steps array
  const currentStepObjectsToKeep = stepObjects.value.filter((step) => {
    if (newSteps.includes(step.title)) {
      // Remove matches between currentsteps and new props.steps so that
      // newsteps only includes the title of new steps to add to the steps object array
      const idx = newSteps.findIndex((newStep) => newStep === step.title)
      if (idx >= 0) {
        newSteps.splice(idx, 1)
      }

      return true
    }
  })

  // Create an array of objects for the new steps
  const newStepObjects = newSteps.map((stepTitle) => {
    return reactive({ title: stepTitle, completed: false })
  })

  // Join new steps to the end of the current steps
  const newStepObjectsArray = currentStepObjectsToKeep.concat(newStepObjects)

  stepObjects.value = newStepObjectsArray
}

watch(
  () => props.steps,
  () => {
    // stepObjects.value = generateStepObjects()
    generateNewStepObjects()
  },
  { deep: true }
)

/**
 * Index of active step
 */
const activeStepIndex = ref(0)

/**
 * Change the step to the step at index
 * @param {number} index step to change to
 */
const setStep = (index) => {
  let stepCompleted = props.steps[activeStepIndex.value]
  emits('complete-step', stepCompleted)

  if (canSetStep(index)) {
    activeStepIndex.value = index
  }
}

/**
 * @param {number} index Number of the step to change to
 * @returns {boolean} True/False if the index provided is valid to change to
 */
const canSetStep = (index) => {
  //Is index within bounds?
  if (index >= 0 && index < props.steps.length) {
    //If we are moving to the first step
    if (index === 0) {
      return true
    }

    //Otherwise check to see if the previous step is completed
    const newIndex = index - 1
    if (newIndex >= 0) {
      if (stepObjects.value[newIndex].completed) {
        return true
      }
    }
  }

  //If we are on the last step and it is completed return true
  if (activeStepIndex.value === props.steps.length - 1) {
    if (stepObjects.value[activeStepIndex.value].completed) {
      return true
    }
  }

  return false
}

/**
 * Move to the next step in the wizard
 */
const nextStep = () => {
  setStep(activeStepIndex.value + 1)
}
/**
 * Move to the previous step in the wizard
 */
const prevStep = () => {
  setStep(activeStepIndex.value - 1)
}

/**
 * Change the completed flag for a step.
 * @param {string} stepName Name of the step to modify
 * @param {boolean} complete Is the step complete or not?
 */
const toggleStepCompleted = (stepName, complete) => {
  const stepObject = stepObjects.value.find((step) => step.title === stepName)

  if (stepObject) {
    stepObject.completed = complete
  }
}

defineExpose({ toggleStepCompleted, prevStep, nextStep, activeStepIndex })

// Multi word slot names are lowercase and joined by underscores.
// Foo Bar would become foo_bar
const slotNames = computed(() => {
  return stepObjects.value.map((step) => {
    return {
      slotName: step.title.toLowerCase().trim().replaceAll(' ', '_'),
      displayName: step.title
    }
  })
})
</script>

<style lang="scss">
button.icon {
  background: none;
  color: inherit;
  border: none;
  min-width: 1.5rem;
  margin: 0;
  padding: 0;
  padding-top: 0.01rem;
  font: inherit;
  outline: inherit;

  &:first-of-type {
    margin-left: 1rem;
  }

  &:last-of-type {
    margin-right: 1rem;
  }
}

.steps-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .steps {
    display: flex;
    background: none;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;

    .step-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 0.5rem;
      cursor: pointer;
      color: $braid-grey-4;

      svg.icon {
        margin-top: 0.3rem;
        font-size: 1.2rem;
        path {
          fill: $braid-grey-3;
        }
      }

      &.completed {
        color: $braid-font-color;

        svg.icon {
          path {
            fill: $braid-font-color;
          }
        }

        & + .step-connector {
          background-color: $braid-font-color;
        }
      }

      &.available {
        color: $braid-font-color;

        svg.icon {
          path {
            fill: $braid-grey-4;
          }
        }
      }
    }

    .step-connector {
      background-color: $braid-grey-3;
      flex-shrink: 2;
      height: 0.2rem;
      width: 10em;
    }
  }
}

.steps-container-1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;

  .steps {
    display: flex;
    background: none;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    gap: 5rem;

    .step-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-bottom: 0.5rem;
      cursor: pointer;
      color: $braid-grey-3;

      .step-connector {
        position: relative;
        top: -1.9rem;
        background-color: $braid-grey-3;
        flex-shrink: 2;
        height: 0.8rem;
        z-index: 5;
      }

      &:first-child {
        .dot + .step-connector {
          left: 50%;
          width: calc(50% + 5rem);
        }
      }

      &:not(:first-child):not(:last-child) {
        .dot + .step-connector {
          width: calc(100% + 5rem);
          z-index: 10;
        }
      }

      &:last-child {
        .dot + .step-connector {
          right: 50%;
          width: calc(50% + 5rem);
        }
      }
      &:first-child + :last-child {
        .dot + .step-connector {
          right: 50%;
          width: calc(50% + 2.5rem);
        }
      }

      &.completed {
        .dot,
        .step-connector {
          background-color: $braid-purple;
        }
      }

      &.active {
        .dot,
        .step-connector {
          background-color: $braid-light-purple;
        }
      }

      &.disabled {
        .dot,
        .step-connector {
          background-color: $braid-grey-3;
        }
      }
    }

    .step-title {
      font-size: 1rem;
      font-weight: 700;
    }
    .dot {
      height: 3rem;
      width: 3rem;
      background-color: $braid-grey-3;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 11;

      svg.icon {
        path {
          fill: white;
        }
      }
    }

    &.completed {
      color: $braid-font-color;

      svg.icon {
        path {
          fill: $braid-font-color;
        }
      }

      & + .step-connector {
        background-color: $braid-font-color;
      }
    }
  }
}

.title-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.active {
  font-weight: bold;
}

.btn-row {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 5rem;

  .step-btn {
    border: 1px solid black;
    // width: 10rem;

    &:not(.disabled) {
      color: $braid-purple;
      font-weight: bold;
      border-color: $braid-purple;
      border-width: 2px;
    }
  }
}

.disabled {
  cursor: not-allowed !important;
}
</style>
