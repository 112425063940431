<template>
  <TableGrid
    ref="grid"
    :cols="pricingProfileCols"
    :rows="pricingProfileRows"
    :total-count="noOfPricingProfiles"
    :filter-function="getRows"
    :default-sort="defaultSort"
    :trclick="openPricingProfileModal"
    :update-url="false"
  >
  </TableGrid>

  <div class="tbl-cell-btn">
    <button type="button" class="btn" @click="addPricingProfile">
      <font-awesome-icon :icon="['fas', 'circle-plus']"></font-awesome-icon>
      <b>Add</b>
    </button>
  </div>

  <BraidModal
    ref="pricingProfileModal"
    @confirm="saveModal"
    @close="closeModal"
  >
    <template #title>
      Pricing Profile - {{ selectedPricingRow.name || '' }}
    </template>
    <template #body>
      <TableForm>
        <TableRow
          v-model="selectedPricingRow.name"
          type="text"
          label="Name"
        ></TableRow>
        <TableRow
          v-model="selectedPricingRow.country"
          :options="countries"
          type="select"
          label="Country"
        ></TableRow>
        <TableRow
          v-model="selectedPricingRow.currency"
          :options="currencies"
          type="select"
          label="Currency"
        ></TableRow>
        <TableRow
          v-if="props.vatRegistered"
          v-model="selectedPricingRow.vatOptions"
          :options="vatOptions"
          type="select"
          label="VAT Options"
          @update:model-value="updateVatOptions"
        ></TableRow>
      </TableForm>
      <TableGrid
        :cols="channelCols"
        :rows="channels"
        :get-checked="isChannelSelected"
        :total-count="noOfChannels"
        :toggle="toggleProfileChannel"
        checkbox
      ></TableGrid>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref, onMounted, inject, watch, toRaw } from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
const router = useRouter()
const api = useApi()
const { getTableFilters } = useColumnFilters(router)
import useCurrencies from '@/composables/selectData/useCurrencies'
const { getCurrenciesForSelect, getCurrenciesForTable } = useCurrencies()
const currencies = getCurrenciesForSelect()
const currencyDisplayValues = getCurrenciesForTable()
import useCountries from '@/composables/selectData/useCountries'
const { getCountriesForSelect } = useCountries()
const countries = getCountriesForSelect()

const props = defineProps({
  vatRegistered: {
    type: Boolean,
    default: false
  },
  vatNumber: {
    type: String,
    default: ''
  }
})

/**
 * @see  @/components/template/TheProgressBar
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/**
 ********** TABLE
 */
import TableForm from '@/components/table/form/TableForm'
import RowContainer from '@/components/table/form/RowContainer'
import TableRow from '@/components/table/form/TableRow'

const grid = ref(null)
const pricingProfileCols = ref([
  {
    label: 'Name',
    name: 'name',
    visible: true,
    filter: true
  },
  {
    label: 'Country',
    name: 'country',
    visible: true,
    filter: true
  },
  {
    label: 'Currency',
    name: 'currency',
    multiple: true,
    displayValues: currencyDisplayValues,
    visible: true,
    filter: true
  },
  {
    label: 'VAT Options',
    name: 'vatOptions',
    visible: true,
    multiple: true,
    displayValues: {
      0: 'No VAT',
      1: 'Including VAT',
      2: 'Excluding VAT'
    }
  },
  {
    label: 'Channels',
    name: 'channelList',
    visible: true,
    filter: true
  }
])
const pricingProfileRows = ref(null)
const noOfPricingProfiles = ref(0)
const channelCols = ref([
  {
    label: 'Name',
    name: 'name',
    visible: true
  },
  {
    label: 'Channel',
    name: 'channel',
    visible: true
  }
])
const channels = ref(null)
const noOfChannels = ref(0)
const defaultSort = ref({ name: -1 })
const showArchived = ref(false)

/**
 ********* METHODS
 */

const handleResponse = (data) => {
  let pricingProfiles = data.pricingProfiles
  let count = parseInt(data.count)

  pricingProfiles.forEach((profile) => {
    profile.channelList = ''
    profile.channels.forEach((channel, index) => {
      if (index > 0) {
        profile.channelList += ', '
      }
      profile.channelList += channels.value.find(
        (obj) => obj.tenantChannelId == channel.tenantChannelId
      ).name
    })
  })

  pricingProfileRows.value = pricingProfiles
  noOfPricingProfiles.value = count
  INITIAL_PROFILES.value = [...toRaw(pricingProfileRows.value)]
}

const getRows = async (args = {}) => {
  try {
    progressBarStart()

    if (!args.filter) {
      args.filter = {}
    }

    // let response = await api.get('/pricing', args)
    // handleResponse(response.data)
    progressBarDone()
  } catch (e) {
    progressBarFail()
  }
}

/**
 * Gets all channels for a tenant
 */
const getChannels = async () => {
  let response = await api.get('/channels', {})
  channels.value = response.data.channels
  noOfChannels.value = response.data.count
}

/**
 *********** PRICING PROFILE MODAL
 */
import BraidModal from '@/components/modals/Modal.vue'
const selectedPricingRow = ref({
  name: '',
  country: '',
  currency: '',
  vatOptions: '',
  channels: []
})
const pricingProfileModal = ref(null)
const vatOptions = ref([
  { value: '0', label: 'No VAT' },
  { value: '1', label: 'Including VAT' },
  { value: '2', label: 'Excluding VAT' }
])

/**
 * When a pricing profile is clicked, set row as selected profile and display modal
 * @param row selected row to show
 */
const openPricingProfileModal = (row) => {
  selectedPricingRow.value = JSON.parse(JSON.stringify(row))
  pricingProfileModal.value.show()
}

/**
 * Opens the pricing profile modal with no selected profile
 * for creation of a new one
 */
const addPricingProfile = () => {
  pricingProfileModal.value.show()
}

/**
 * Finds the edited pricing profile and updates it in parent component.
 * If the pricing profile is not found, a new one is created and added to the array
 */
const saveModal = () => {
  let index = pricingProfileRows.value.findIndex(
    (element) =>
      element.tenantPricingId == selectedPricingRow.value.tenantPricingId
  )

  if (index > -1) {
    pricingProfileRows.value[index] = JSON.parse(
      JSON.stringify(selectedPricingRow.value)
    )
  } else {
    pricingProfileRows.value.push(
      JSON.parse(JSON.stringify(selectedPricingRow.value))
    )
  }

  pricingProfileRows.value.forEach((profile) => {
    profile.channelList = ''
    profile.channels.forEach((channel, index) => {
      if (index > 0) {
        profile.channelList += ', '
      }
      profile.channelList += channels.value.find(
        (obj) => obj.tenantChannelId == channel.tenantChannelId
      ).name
    })
  })

  closeModal()
}

/**
 * Toggles the checkbox select of a channel for a pricing profile
 * @param row the channel that has been selected
 */
const toggleProfileChannel = (row) => {
  let index = selectedPricingRow.value.channels.findIndex(
    (channel) => channel.tenantChannelId == row.tenantChannelId
  )
  if (index > -1) {
    selectedPricingRow.value.channels[index].archive =
      selectedPricingRow.value.channels[index].archive == 1 ? 0 : 1
  } else {
    selectedPricingRow.value.channels.push({
      archive: 0,
      tenantChannelId: row.tenantChannelId,
      tenantPricingProfile: selectedPricingRow.value.tenantPricingId
    })
  }
}

/**
 * Clears the selected row and closes the modal
 */
const closeModal = () => {
  selectedPricingRow.value = {
    name: '',
    country: '',
    currency: '',
    vatOptions: '',
    channels: []
  }
  pricingProfileModal.value.hide()
}

/**
 * Checks if the channel displayed in the model is related to the selectedPricingRow,
 * selected channels will be set as connected channels on save
 * @param row the channel that is currently being checked in the modal
 */
const isChannelSelected = (row) => {
  return (
    selectedPricingRow.value.channels.findIndex(
      (channel) => channel.tenantChannelId == row.tenantChannelId
    ) != -1
  )
}

const updateVatOptions = (data) => {
  console.log(data)
}

const uuid = require('uuid')
let INITIAL_PROFILES = ref(null)

const _UNIQUE_STATE_ID = ref(uuid.v4())

// @see @/components/table/ValidationProvider
const registerFormState = inject('registerFormState')
const updateFormChanges = inject('updateFormChanges')

registerFormState(_UNIQUE_STATE_ID, 'pricing_profiles')
const profileChangesMade = ref(false)

watch(
  pricingProfileRows,
  (newValue) => {
    let changesMade = !_.isEqual(toRaw(newValue), INITIAL_PROFILES.value)
    profileChangesMade.value = changesMade
    updateFormChanges(_UNIQUE_STATE_ID, 'pricing_profiles', changesMade)
  },
  { deep: true }
)

const save = async () => {
  if (!profileChangesMade.value) {
    return
  }
  let response = await api.post('/pricing', {
    pricing: pricingProfileRows.value
  })
  handleResponse(response.data)
}

defineExpose({ save })

onMounted(async () => {
  let tableFilters = getTableFilters(defaultSort.value)
  await getChannels()
  await getRows(tableFilters)
})
</script>

<style scoped></style>
