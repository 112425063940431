<template>
  <!-- PAGINATION - TABLE 1 has full width. In its own table because it disrupts column width changes for checkbox-cell and delete-cell -->
  <table id="pagination-table">
    <thead>
      <tr v-if="pagination" class="pagination-bar">
        <th id="paginationCell">
          <div id="paginationWrapper">
            <button
              v-if="hasFilters"
              id="clear-all-filters"
              aria-label="Clear filters"
              class="btn pagination-button"
              @click.prevent="resetFilters"
            >
              <font-awesome-icon icon="circle-xmark"></font-awesome-icon>
              Clear filters
            </button>
            <div id="paginationControls">
              <!-- BUTTON: FIRST PAGE OF RECORDS -->
              <button
                aria-label="First"
                class="btn btn-page-first"
                :disabled="skip <= 0"
                @click.prevent="paginate('g', 0)"
              >
                <font-awesome-icon icon="angles-left"></font-awesome-icon>
              </button>
              <!-- ./BUTTON: FIRST PAGE OF RECORDS -->

              <!-- BUTTON: PREVIOUS PAGE OF RECORDS -->
              <button
                class="btn btn-page-previous"
                aria-label="Previous"
                :disabled="skip <= 0"
                @click.prevent="paginate('p')"
              >
                <font-awesome-icon icon="angle-left"></font-awesome-icon>
              </button>
              <!-- ./BUTTON: PREVIOUS PAGE OF RECORDS -->

              <!-- SKIP DISPLAY -->
              <input
                id="skipDisplay"
                :value="skipDisplay"
                type="number"
                class="form-control"
                @keyup.enter="(event) => customPageSkip(event.target.value)"
              />
              <!-- ./SKIP DISPLAY -->

              <span
                id="recordCount"
                class="input-group-text pagination-display"
              >
                to {{ endRecordCount }} of {{ totalCount }}
              </span>

              <!-- BUTTON: NEXT PAGE OF RECORDS -->
              <button
                class="btn btn-page-next"
                aria-label="Next"
                :disabled="skip + limit >= totalCount"
                @click.prevent="paginate('n')"
              >
                <font-awesome-icon icon="angle-right"></font-awesome-icon>
              </button>
              <!-- ./BUTTON: NEXT PAGE OF RECORDS -->

              <!-- BUTTON:  LAST PAGE OF RECORDS -->
              <button
                class="btn btn-page-last"
                aria-label="Last"
                :disabled="skip + limit >= totalCount"
                @click.prevent="paginate('g', totalCount - limit)"
              >
                <font-awesome-icon icon="angles-right"></font-awesome-icon>
              </button>
              <!-- ./BUTTON:  LAST PAGE OF RECORDS -->
            </div>
          </div>
        </th>
      </tr>
    </thead>
  </table>
  <!-- ./PAGINATION -->
</template>

<script setup>
import { ref, computed } from 'vue'

import { useRouter } from 'vue-router'
const router = useRouter()

const emits = defineEmits(['skip'])
const props = defineProps({
  pagination: {
    type: Boolean,
    default: true
  },
  hasFilters: {
    type: Boolean,
    default: false
  },
  resetFilters: {
    type: Function,
    default() {
      return
    }
  },
  skip: {
    type: Number,
    default: 0
  },
  totalCount: {
    type: [String, Number],
    default: 0
  },
  limit: {
    type: Number,
    default: 20
  },
  callFilter: {
    type: Function,
    default() {
      return
    }
  },
  updateUrl: {
    type: Boolean,
    default: true
  }
})

const skipDisplay = computed(() => {
  if (props.totalCount == 0) {
    return 0
  }
  return props.skip + 1
})

/**
 * Computed value of total number of records in list
 */
const endRecordCount = computed(() => {
  let recordCountskip = props.skip || 0
  let end = parseInt(recordCountskip) + props.limit
  if (end > props.totalCount) {
    return props.totalCount
  }
  return end
})

/**
 *
 */
const paginate = (direction, paginateSkip = 0) => {
  let newPageIndex = 0
  switch (direction) {
    case 'p':
      if (props.skip === 0) {
        break
      }
      if (props.skip - props.limit < 0) {
        newPageIndex = 0
        emits('skip', newPageIndex)
        break
      }
      newPageIndex = props.skip - props.limit
      emits('skip', newPageIndex)
      break
    case 'n':
      newPageIndex = props.skip + props.limit
      emits('skip', newPageIndex)
      break
    case 'g':
      if (paginateSkip >= props.totalCount) {
        paginateSkip = props.totalCount - props.limit
      }
      newPageIndex = parseInt(paginateSkip)
      emits('skip', newPageIndex)
      break
  }

  let query = router.currentRoute.value.query
  query = JSON.parse(JSON.stringify(query))
  if (props.updateUrl) {
    query.page = newPageIndex
    router.replace({
      query
    })
  }
  props.callFilter()
}

const customPageSkip = (page) => {
  if (page < 0) {
    page = 0
  } else if (page > 0) {
    page -= 1
  }
  paginate('g', page)
}
</script>

<style lang="scss">
#pagination-table {
  width: 100%;
  #paginationCell {
    padding: 0;
    #paginationWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 1rem;
      align-items: flex-end;
      .pagination-button {
        margin-right: 1rem;
        & :first-child {
          margin-right: 0.5rem;
        }
      }
      #paginationControls {
        display: flex;
        flex-direction: row;
        #skipDisplay {
          padding: 0 0.25rem;
          margin: 0;
          align-self: center;
          text-align: right;
          width: 20%;
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
        /* Chrome, Safari, Edge, Opera */
        #skipDisplay::-webkit-outer-spin-button,
        #skipDisplay::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        #skipDisplay[type='number'] {
          -moz-appearance: textfield;
        }
        #recordCount {
          background-color: inherit;
          border: 0;
        }
      }
    }
  }
}
</style>
