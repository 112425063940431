<template>
  <BraidModal
    ref="modal"
    confirm-button-text="Cancel Stock"
    reject-button-text="Close"
    @confirm="cancelStock"
    @reject="close"
  >
    <template #title>Cancel Stock</template>
    <template #body>
      <table class="table table-hover" style="width: 100%">
        <thead>
          <th>SKU</th>
          <th>Title</th>
          <th>Variants</th>
          <th>Quantity ordered</th>
          <th>Quantity received</th>
          <th>Quantity cancelled</th>
          <th>Quantity to cancel</th>
        </thead>
        <tbody>
          <tr v-for="(row, index) in items" :key="row.sku + index">
            <td>{{ row.sku }}</td>
            <td>{{ row.title }}</td>
            <td>{{ row.variant1Value }} {{ row.variant2Value }}</td>
            <td>{{ row.quantityOrdered }}</td>
            <td>{{ row.quantityReceived }}</td>
            <td>{{ row.quantityCancelled || 0 }}</td>
            <td>
              <input
                v-model="row.quantityToCancel"
                type="number"
                class="form-control"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </BraidModal>
</template>

<script>
export default {
  name: 'CancelMultipleSkusModal'
}
</script>

<script setup>
import { ref } from 'vue'
import useApi from '@/components/useApi'

const props = defineProps({
  items: {
    type: Array,
    default() {
      return []
    }
  },
  purchaseOrderId: {
    type: [String, Boolean],
    required: true
  }
})

const emit = defineEmits(['close'])

const api = useApi()

const cancelStock = async () => {
  const URI = `/purchaseOrders/${props.purchaseOrderId}`
  let index = 0
  let length = props.items.length

  for (index; index < length; index++) {
    let item = props.items[index]
    let quantityCancelled = item.quantityCancelled || 0
    item.quantityCancelled =
      parseInt(quantityCancelled) + parseInt(item.quantityToCancel)
  }

  let params = {
    purchaseOrderItems: props.items,
    updateItemsOnly: 1,
    purchaseOrderId: props.purchaseOrderId
  }

  await api.put(URI, params)
  hide()
}

const modal = ref(null)
const show = () => {
  modal.value.show()
}

const hide = () => {
  modal.value.hide()
}

const close = () => {
  emit('close')
}

defineExpose({ show, hide })
</script>

<style lang="scss"></style>
