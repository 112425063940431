export default function () {
  const get = () => {
    return {
      UPS: 'https://www.ups.com/track?tracknum=[TRACKING_ID]&requester=WT/',
      DHL: 'https://www.dhl.com/gb-en/home/tracking.html?tracking-id=[TRACKING_ID]&submit=1',
      DPD: 'http://www.dpd.co.uk/search.jsp?q=[TRACKING_ID]',
      ROYAL_MAIL:
        'https://www.royalmail.com/track-your-item?trackNumber=[TRACKING_ID]',
      HERMES: 'https://www.myhermes.co.uk/track#/parcel/[TRACKING_ID]/details/',
      FEDEX: 'http://www.fedex.com/Tracking?tracknumbers=[TRACKING_ID]'
    }
  }
  return { getCarriers: get }
}
