<template>
  <ValidationProvider>
    <button class="btn btn-purple borderless" @click="exportSkus()">
      Export Ezi Returns report as CSV
    </button>
  </ValidationProvider>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onActivated, ref } from 'vue'
import useApi from '@/components/useApi'

const router = useRouter()
const api = useApi()

const rows = ref([])

const exportSkus = async () => {
  let response = await api.get(
    '/skus/',
    { 'ezi-returns': 1 },
    { responseType: 'blob', contentType: 'text/csv' }
  )

  let url = window.URL.createObjectURL(response.data)

  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', `EziReturns.csv`)
  document.body.appendChild(link)
  link.click()
  link.remove()
  return rawData
}
</script>
