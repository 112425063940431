<template>
  <TableContainer>
    <TablePanel v-if="missingAddress">
      <template #1>
        At least one of these dispatches is missing an address. This must be
        rectified before creating labels.
      </template>
    </TablePanel>
    <TablePanel v-else-if="hasRequiredChannel">
      <template #1>
        <Wizard
          ref="wizard"
          :steps="wizardSteps"
          :icons="stepIcons"
          :allow-previous="false"
          next-text="Buy labels"
          @complete-step="buyRoyalMailLabels"
          @complete-wizard="goToLabels"
        >
          <template #services>
            <ValidationProvider ref="validation">
              <div v-if="!pageLoading" class="row">
                <div class="col-4">
                  <div class="btn-group d-flex">
                    <button
                      type="button"
                      class="btn btn-purple"
                      :class="!bulkUpdate ? 'btn-fill-outline' : 'btn-outline '"
                      style="width: 100%"
                      @click="bulkUpdate = false"
                    >
                      Individual
                    </button>
                    <button
                      type="button"
                      class="btn btn-purple"
                      :class="bulkUpdate ? 'btn-fill-outline' : 'btn-outline '"
                      style="width: 100%"
                      @click="bulkUpdate = true"
                    >
                      Bulk
                    </button>
                  </div>
                </div>
              </div>
              <TablePanel v-if="bulkUpdate" :cols="2">
                <template #1>
                  <TableForm>
                    <PackagePreset
                      ref="packagePresetComponent"
                      :package-presets="packagePresets"
                      @new-preset="formatPackagePresets"
                      @update-value="updatePackageDimensions"
                    ></PackagePreset>
                    <TableRow
                      v-model="allData.weight"
                      label="Estimated weight (g)"
                      type="number"
                    ></TableRow>
                    <TableRow
                      v-model="allData.value"
                      label="Estimated value"
                      type="number"
                    ></TableRow>
                  </TableForm>
                </template>
                <template #2>
                  <TableForm>
                    <TableRow
                      v-model="allData.collectFrom"
                      label="Collection location"
                      type="select"
                      :options="locations"
                    ></TableRow>
                    <TableRow
                      v-model="allData.serviceType"
                      label="Service"
                      type="select"
                      :options="services"
                    ></TableRow>
                  </TableForm>
                </template>
              </TablePanel>
              <TablePanel>
                <template #1>
                  <TableGrid
                    :cols="columns"
                    :rows="dispatches"
                    :total-count="dispatches.length"
                    id-key="tenantDispatchId"
                    scrollable
                  ></TableGrid>
                </template>
              </TablePanel>
            </ValidationProvider>
          </template>

          <template #print>
            <ValidationProvider>
              <TablePanel v-if="!labelUrls || labelUrls.length === 0">
                <template #1>
                  <div class="wait-text">
                    {{ waitingText }}
                  </div>
                </template>
              </TablePanel>
              <TablePanel v-else :cols="3">
                <template #1>
                  <TableForm>
                    <TableRow type="custom">
                      <div class="tbl-cell-btn">
                        <button
                          :disabled="!labelUrls || labelUrls.length === 0"
                          type="button"
                          class="btn"
                          @click="printLabels"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'print']"
                          ></font-awesome-icon>
                          <b>Print 6X4</b>
                        </button>
                      </div>
                    </TableRow>
                  </TableForm>
                </template>
                <template #2>
                  <TableForm>
                    <TableRow type="custom">
                      <div class="tbl-cell-btn">
                        <button
                          :disabled="!labelUrls || labelUrls.length === 0"
                          type="button"
                          class="btn"
                          @click="printIntegratedLabels"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'print']"
                          ></font-awesome-icon>
                          <b>Print A4 integrated labels</b>
                        </button>
                      </div>
                    </TableRow>
                  </TableForm>
                </template>
                <template #3>
                  <TableForm>
                    <TableRow type="custom">
                      <div class="tbl-cell-btn">
                        <button
                          :disabled="!labelUrls || labelUrls.length === 0"
                          type="button"
                          class="btn"
                          @click="printA4Labels"
                        >
                          <font-awesome-icon
                            :icon="['fas', 'print']"
                          ></font-awesome-icon>
                          <b>Print A4 labels</b>
                        </button>
                      </div>
                    </TableRow>
                  </TableForm>
                </template>
              </TablePanel>
              <TablePanel :cols="1">
                <template #1>
                  <TableGrid
                    ref="serviceGrid"
                    :cols="referenceCols"
                    :rows="dispatches"
                    :total-count="dispatches.length"
                    id-key="tenantDispatchId"
                    scrollable
                  ></TableGrid>
                </template>
              </TablePanel>
            </ValidationProvider>
          </template>
        </Wizard>
      </template>
    </TablePanel>
  </TableContainer>
</template>
<script setup>
import { ref, watch, computed, nextTick, inject, toRaw } from 'vue'
import Wizard from './Wizard.vue'
import TableForm from '@/components/table/form/TableForm.vue'
import TableRow from '@/components/table/form/TableRow.vue'
import ValidationProvider from '@/components/table/ValidationProvider.vue'
import RowContainer from '@/components/table/form/RowContainer.vue'
import TableTabs from '@/components/table/tabs/TableTabs'
import TableGrid from '@/components/table/grid/TableGrid'
import TableContainer from '@/components/table/panel/TableContainer'
import TablePanel from '@/components/table/panel/TablePanel'
import TableFileUpload from '@/components/table/form/TableFileUpload'
import SkuLabelService from '@/components/SkuLabelService'
import PackagePreset from '@/components/PackagePreset'
import useApi from '@/components/useApi'
import formatForSelect from '@/composables/formatForSelect'
import { useRouter, useRoute } from 'vue-router'
import { CreateMultipleLabelPdf } from '@/composables/pdfs/multipleLabelPdf'
import { CreateIntegratedLabel } from '@/composables/pdfs/integratedLabelPdf'
import { getSkuDataForLabel } from '@/composables/getSkuDataForLabel'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const createAlert = inject('createAlert')

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const { getSkusForLabel } = getSkuDataForLabel()
const createMultipleLabelPdf = new CreateMultipleLabelPdf()
const createIntegratedLabelPdf = new CreateIntegratedLabel()

const serviceGrid = ref(null)
const packagePresetComponent = ref(null)
const allData = ref({})
const api = useApi()
const integrations = ref([])
const packagePresets = ref([])
const listings = ref([])
const integrationOptions = ref([])
const rows = ref([])
const locations = ref([])
const dispatches = ref([])
const services = ref([])
const bulkUpdate = ref(true)
const router = useRouter()
const route = useRoute()
const packageTypes = ref([])
const channel = ref({})
const hasRequiredChannel = ref(true)
const labelUrls = ref([])
const waitingText = ref('')

const checkStepComplete = () => {
  const completed = checkDataComplete()
  changeStepComplete('Services', completed)
}

const editableCols = [
  {
    label: 'Items',
    name: 'items',
    visible: true
  },
  {
    label: 'Package measurements',
    name: 'tenantPackagePresetId',
    visible: true,
    input: true,
    inputType: 'select',
    displayValues: packagePresets,
    change: checkStepComplete
  },
  {
    label: 'Estimated weight (G)',
    name: 'weight',
    visible: true,
    input: true,
    inputType: 'number',
    width: 200,
    change: checkStepComplete
  },
  {
    label: 'Estimated value',
    name: 'value',
    visible: true,
    input: true,
    inputType: 'number',
    width: 200,
    change: checkStepComplete
  },
  {
    label: 'Collection location',
    name: 'collectFrom',
    visible: true,
    input: true,
    inputType: 'select',
    displayValues: locations,
    change: checkStepComplete
  },
  {
    label: 'Service',
    name: 'serviceType',
    visible: true,
    input: true,
    inputType: 'select',
    displayValues: services,
    change: checkStepComplete
  }
]

const displayCols = [
  {
    label: 'Dispatch number',
    name: 'channelDispatchId',
    visible: true
  },
  {
    label: 'Order number',
    name: 'orderNumber',
    visible: true
  },
  {
    label: 'Items',
    name: 'items',
    visible: true
  }
]

const referenceCols = [
  {
    label: 'Items',
    name: 'items',
    visible: true
  },
  {
    label: 'Customer',
    name: 'customerName',
    visible: true
  },
  {
    label: 'Address',
    name: 'completeAddress',
    visible: true
  }
]

const columns = computed(() => {
  if (bulkUpdate.value) {
    return displayCols
  }

  return editableCols
})

const printA4Labels = async () => {
  createMultipleLabelPdf.create(labelUrls.value, 'A4')
}

const printLabels = async () => {
  createMultipleLabelPdf.create(labelUrls.value, '4X6')
}

const printIntegratedLabels = async () => {
  let skus = await getSkusForLabel(api, labelUrls.value)
  createIntegratedLabelPdf.createMultiple(labelUrls.value, skus)
}

const updatePackageDimensions = () => {
  let dimensions = packagePresetComponent.value.dimensions
  allData.value.height = dimensions.height
  allData.value.width = dimensions.width
  allData.value.depth = dimensions.depth
  allData.value.dimensionUnits = dimensions.dimensionUnits
}

const buyRoyalMailLabels = async (stepName) => {
  loading()
  if (stepName !== 'Services') {
    return
  }

  if (missingAddress.value) {
    return
  }

  waitingText.value = 'Please wait, currently buying Royal Mail labels.'

  let index = 0
  let length = dispatches.value.length
  let allLabelData = { orders: [] }
  let allDataRelevantPackageType

  if (bulkUpdate.value) {
    allDataRelevantPackageType = getRelevantPackageType()
  }

  for (index; index < length; index++) {
    let dispatch = dispatches.value[index]
    let labelData = {
      tenantDispatchId: dispatch.tenantDispatchId,
      orderNumber: dispatch.channelOrderId,
      createdAt: dispatch.channelOrderCreatedAt,
      currency: dispatch.currency,
      phone: dispatch.phone,
      email: dispatch.email,
      customerName: dispatch.customerName,
      shippingAddrLine1: dispatch.shippingAddrLine1,
      shippingAddrLine2: dispatch.shippingAddrLine2,
      shippingAddrLine3: dispatch.shippingAddrLine3,
      shippingAddrPostcode: dispatch.shippingAddrPostcode,
      shippingAddrCountry: dispatch.shippingAddrCountry,
      shippingCostsCharged: 0
    }

    if (!bulkUpdate.value) {
      let relevantPackageType = getRelevantPackageType(dispatch)
      dispatch.relevantPackageType = relevantPackageType
      labelData.packageFormatIdentifier = relevantPackageType.packageName
      labelData.weight = { g: dispatch.weight }
      labelData.serviceCode = dispatch.serviceType
      labelData.itemsSubTotal = dispatch.value
      allLabelData.orders.push(labelData)
      continue
    }

    dispatch.relevantPackageType = allDataRelevantPackageType
    labelData.packageFormatIdentifier = allDataRelevantPackageType.packageName
    labelData.weight = { g: allData.value.weight }
    labelData.serviceCode = allData.value.serviceType
    labelData.itemsSubTotal = allData.value.value

    allLabelData.orders.push(labelData)
  }

  await api.post('/royal-mail/orders', {
    labelData: allLabelData,
    tenantChannelId: channel.value.tenantChannelId,
    carrier: 'ROYAL_MAIL'
  })

  waitingText.value = 'Almost done, just retrieving the labels to print'
  goToLabels()
  // let labels = response.data.createdLabels
  // let formatted = formatLabels(labels)

  // let labelsResponse = await api.post('/labels/', {
  //   labels: formatted
  // })

  // let tenantLabelIds = labelsResponse.data.tenantLabelIds

  // await getLabels(labels, tenantLabelIds)
  // loaded()
  // serviceGrid.value.updateRows()
}

const getLabels = async (labels, tenantLabelIds) => {
  let index = 0
  let length = labels.length
  for (index; index < length; index++) {
    let label = labels[index]
    if (!label.label) {
      label.label = await getAndSaveLabels(label)
    }
  }

  let response = await api.get('/labels/display-labels', {
    tenantLabelIds
  })

  labelUrls.value = response.data.labels
  changeStepComplete('Print', true)
}

const getAndSaveLabel = async (label) => {
  let service = ''

  switch (label.carrier) {
    case 'ROYAL_MAIL':
      service = 'royal-mail'
      break

    case 'PARCEL2GO':
      service = 'parcel2go'
      break
  }

  let response = await api.get(`/${service}/labels`, {
    carrier: label.carrier,
    carrierLabelIds: [label.carrierLabelId],
    tenantChannelId: label.tenantChannelId
  })

  let imageUrls = response.data.imageUrls

  let labelRecord = {
    label: {
      carrierLabelId: label.carrierLabelId,
      tenantLabelId: label.tenantLabelId,
      label: imageUrls[label.carrierLabelId]
    }
  }

  await api.put(`/labels/`, {
    labels: [labelRecord],
    saving
  })

  return imageUrls[label.carrierLabelId]
}

const getCompleteAddress = (row) => {
  let completeString = ''

  completeString += row.shippingAddrLine1 ? row.shippingAddrLine1 + ', ' : ''
  completeString += row.shippingAddrLine2 ? row.shippingAddrLine2 + ', ' : ''
  completeString += row.shippingAddrLine3 ? row.shippingAddrLine3 + ', ' : ''
  completeString += row.shippingAddrPostcode
    ? row.shippingAddrPostcode + ', '
    : ''
  completeString += row.shippingAddrCountry ? row.shippingAddrCountry : ''

  return completeString
}

const goToLabels = () => {
  router.push('/labels')
}

const formatLabels = (labels) => {
  let allDataService
  if (bulkUpdate.value) {
    allDataService = services.value.find((item) => {
      return item.value == allData.value.serviceType
    })
  }

  let formatted = []
  let index = 0
  let length = labels.length

  for (index; index < length; index++) {
    let label = labels[index]
    label.tenantChannelId = channel.value.tenantChannelId

    let dispatch = dispatches.value.find((dispatch) => {
      return dispatch.channelOrderId == label.orderReference
    })
    let relevantPackageType = dispatch.relevantPackageType
    let service = allDataService

    if (!bulkUpdate.value) {
      service = services.value.find((item) => {
        return item.value == dispatch.serviceType
      })
    }

    dispatch.consignments = []
    formatted.push({
      label: label,
      carrier: { carrierName: 'ROYAL_MAIL', displayName: 'Royal Mail' },
      packageType: relevantPackageType,
      service: service,
      dispatch: dispatch
    })
  }

  return formatted
}

const convertMeasurement = (number, units) => {
  if (!number || !units) {
    return
  }

  number = parseFloat(number)

  switch (units) {
    case 'IN':
      return inchesToCm(number)

    case 'CM':
      return number

    case 'MM':
      return number / 10

    case 'M':
      return number * 100
  }
}

const getRelevantPackageType = (dispatch) => {
  let dimensionUnits = allData.value.dimensionUnits
  let width = convertMeasurement(allData.value.width, dimensionUnits)
  let height = convertMeasurement(allData.value.height, dimensionUnits)
  let depth = convertMeasurement(allData.value.depth, dimensionUnits)
  let weight = allData.value.weight

  if (dispatch) {
    let tenantPackagePreset = packagePresets.value.find((packagePreset) => {
      return (
        packagePreset.tenantPackagePresetId == dispatch.tenantPackagePresetId
      )
    })

    dimensionUnits = tenantPackagePreset.dimensionUnits
    width = convertMeasurement(tenantPackagePreset.width, dimensionUnits)
    height = convertMeasurement(tenantPackagePreset.height, dimensionUnits)
    depth = convertMeasurement(tenantPackagePreset.depth, dimensionUnits)
    weight = dispatch.weight
  }

  let possiblePackageTypes = packageTypes.value.filter((packageType) => {
    return (
      parseFloat(packageType.maxWidthInCm) >= width &&
      parseFloat(packageType.maxHeightInCm) >= height &&
      parseFloat(packageType.maxDepthInCm) >= depth &&
      parseFloat(packageType.maxWeightInGrams) >= weight
    )
  })

  if (possiblePackageTypes.length === 0) {
    return 'none'
  }

  if (possiblePackageTypes.length === 1) {
    return possiblePackageTypes[0]
  }

  let sorted = possiblePackageTypes.sort((a, b) => {
    return a.packageTypeId - b.packageTypeId
  })

  return sorted[0]
}

const hasAddress = (dispatch = {}) => {
  let fields = [
    'shippingAddrLine1',
    'shippingAddrPostcode',
    'shippingAddrCountry'
  ]

  let index = 0
  let length = fields.length
  for (index; index < length; index++) {
    let field = fields[index]
    let value = dispatch[field]

    if (!value || value == '') {
      return false
    }
  }

  return true
}

const missingAddress = computed(() => {
  let index = 0
  let length = dispatches.value.length
  for (index; index < length; index++) {
    let dispatch = dispatches.value[index]
    if (!hasAddress(dispatch)) {
      return true
    }
  }

  return false
})

const getRows = async () => {
  let ids = route.query.ids.split(',')
  try {
    let response = await api.get('/dispatches', { ids })
    dispatches.value = orderDispatches(response.data.dispatches)
    dispatches.value.forEach((dispatch) => {
      dispatch.completeAddress = getCompleteAddress(dispatch)
    })
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

const orderDispatches = (dispatches) => {
  return dispatches.sort((a, b) => {
    let dispatchItemA = a.dispatchItems[0] || {}
    let dispatchItemB = b.dispatchItems[0] || {}

    let skuA = dispatchItemA.sku
    let skuB = dispatchItemB.sku

    if (skuA < skuB) {
      return -1
    }

    if (skuA > skuB) {
      return 1
    }

    return 0
  })
}

const getLocations = async () => {
  let retrievedLocations = await api.getLocations()
  let formattedLocations = []

  let index = 0
  let length = retrievedLocations.length
  for (index; index < length; index++) {
    let retrievedLocation = retrievedLocations[index]
    formattedLocations.push({
      ...retrievedLocation,
      value: retrievedLocation.tenantLocationId,
      label: retrievedLocation.name
    })

    if (retrievedLocation.isDefaultDelivery) {
      allData.value.collectFrom = retrievedLocation.tenantLocationId
    }
  }

  locations.value = formattedLocations
}

const getPackagePresets = async () => {
  let response = await api.get('/packagePresets')
  let presets = response.data.packagePresets
  formatPackagePresets(presets)
}

const formatPackagePresets = (presets = []) => {
  packagePresets.value = []
  let index = 0
  let length = presets.length
  for (index; index < length; index++) {
    let data = presets[index]
    let label = `${data.name} - ${data.height} x ${data.width} x ${data.depth}`
    packagePresets.value.push({
      label: label,
      value: data.tenantPackagePresetId,
      ...data
    })
  }
}

const handleRoyalMailData = (data) => {
  if (!data.channels || data.channels.length === 0) {
    hasRequiredChannel.value = false
    createAlert('error', 'You need a Royal Mail connection to bulk buy labels')
    return
  }

  let allServices = data.services
  let formatted = []
  let index = 0
  let length = allServices.length
  for (index; index < length; index++) {
    let service = allServices[index]
    formatted.push({
      value: service.serviceCode,
      label: service.serviceName,
      ...service
    })
  }

  hasRequiredChannel.value = true
  packageTypes.value = data.packageTypes
  channel.value = data.channels[0]
  services.value = formatted
}

const getServices = async () => {
  let response = await api.get('/royal-mail/quotes')
  handleRoyalMailData(response.data)
}

const getData = async () => {
  loading()
  try {
    await getRows()
    await getServices()
    await getLocations()
    await getPackagePresets()
    loaded()
  } catch (e) {
    router.back()
  }
}

getData()

/*******************************************************
                    GENERAL WIZARD SETUP
 ********************************************************/

const DEFAULT_STEPS = ['Services', 'Print']
const stepIcons = ref({
  Services: ['fas', 'code-branch'],
  Print: ['fas', 'print']
})
let wizardSteps = ref([].concat(DEFAULT_STEPS))

/**
 * Mark a step as complete or incomplete
 * @param {string} stepName Name of step to complete (must be one of the wizardSteps array options)
 * @param {boolean} completed True or false if the step is completed
 */
const changeStepComplete = (stepName, completed) => {
  wizard.value.toggleStepCompleted(stepName, completed)
}

const checkDataComplete = () => {
  if (bulkUpdate.value) {
    return checkBulkDataComplete()
  }

  return checkIndividualDataComplete()
}

const checkBulkDataComplete = () => {
  let fields = [
    'width',
    'height',
    'depth',
    'dimensionUnits',
    'weight',
    'value',
    'collectFrom',
    'serviceType'
  ]

  let index = 0
  let length = fields.length
  for (index; index < length; index++) {
    let field = fields[index]
    if (allData.value[field] == undefined || allData.value[field] === '') {
      return false
    }
  }

  return true
}

const checkIndividualDataComplete = () => {
  let index = 0
  let length = dispatches.value.length
  for (index; index < length; index++) {
    let dispatch = dispatches.value[index]
    let dispatchComplete = checkDispatchComplete(dispatch)

    if (!dispatchComplete) {
      return false
    }
  }

  return true
}

const checkDispatchComplete = (dispatch) => {
  const necessaryFields = [
    'tenantPackagePresetId',
    'weight',
    'value',
    'collectFrom',
    'serviceType'
  ]

  let index = 0
  let length = necessaryFields.length
  for (index; index < length; index++) {
    let field = necessaryFields[index]
    let value = dispatch[field]

    if (value == undefined || value == '') {
      return false
    }
  }

  return true
}

watch(allData.value, () => {
  if (!wizard.value) {
    return
  }

  checkStepComplete()
})

//template refs
const wizard = ref(null)
/*******************************************************
                 SHOPIFY CHOICES
 ********************************************************/
</script>

<style lang="scss" scoped>
.wait-text {
  color: $braid-purple;
  text-align: center;
}
</style>
