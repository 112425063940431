<template>
  <ul class="nav nav-tabs">
    <li v-for="(tab, index) in tabs" :key="tab + index" class="nav-item">
      <a
        v-if="!pageLoading"
        :id="'tab-' + tab"
        class="nav-link"
        aria-current="page"
        href="#"
        :class="{ active: activeTab == tab }"
        @click.prevent="changeTab(tab)"
        >{{ tab }}</a
      >
    </li>
  </ul>
  <slot :active-tab="activeTab"></slot>
</template>

<script setup>
import { ref, computed } from 'vue'
import { pageLoad } from '@/composables/pageLoad'

const { getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const props = defineProps({
  tabs: {
    type: Array,
    default() {
      ;[]
    }
  }
})

//Access via v-slot
const activeTab = ref(props.tabs[0])
//Access via template reference
defineExpose({ tab: activeTab })

const changeTab = (newTab) => {
  if (props.tabs.includes(newTab)) {
    activeTab.value = newTab
  }
}
</script>

<style lang="scss">
.nav-tabs {
  background-color: $braid-grey-2;
  font-size: 0.9rem;
  border-bottom: 0;
  border: 0;
  margin-top: 0;
  margin-bottom: 1rem;

  li:first-of-type {
    margin-left: 0;
    .nav-link {
      border-top-left-radius: 0;
    }
  }
  .nav-item {
    .active {
      background-color: $braid-grey-0;
    }
  }
}

.nav-tabs .nav-link {
  border: 0;
  margin-bottom: 0;
}

.nav-link {
  color: #000000;
  &:hover {
    color: inherit;
    background-color: #cccccc;
  }
}
</style>
