import { jsPDF } from 'jspdf'
import { ref } from 'vue'

const _ = require('lodash')
const dayjs = require('dayjs')
import useCurrencies from '@/composables/selectData/useCurrencies'
const { getCurrencies } = useCurrencies()
const currency = getCurrencies()

const lineHeight = ref(5)
const xCol1 = ref(15)
const xCol2 = ref(100)
const xCol3 = ref(185)
const xCol4 = ref(260)

const yRow1 = ref(20)
const yRow2 = ref(50)
const yRow3 = ref(90)
const yRow4 = ref(120)

const yCurrent = ref(0)

const pageCount = ref(1)
const rowMaxLines = ref(0)
const doc = ref(null)
const cellWidths = {
  sku: 25,
  supplierSku: 30,
  title: 53,
  variantValue: 53
}
const fields = [
  'sku',
  'title',
  'variantValue',
  'mpn',
  'supplierSku',
  'priceCase',
  'unitsPerCase',
  'casesOrdered',
  'linePrice',
  'lineVat',
  'lineTotal'
]

/**
 * Resets all the refs to default and deletes old doc
 */
const resetRefs = () => {
  xCol1.value = 20
  xCol2.value = 84
  xCol3.value = 148
  xCol4.value = 212

  yRow1.value = 20
  yRow2.value = 50
  yRow3.value = 90
  yRow4.value = 120

  pageCount.value = 1

  doc.value = new jsPDF('l', 'mm', 'a4') // 'l' = landscape
}

/**
 * Process for creating new Purchase Order Pdf
 */
const createPdf = async (locations, tenant, purchaseOrder, type) => {
  resetRefs()
  let billingAddress = getBillingAddress(locations, tenant)
  let deliveryAddress = getDeliveryAddress(locations, purchaseOrder)
  let isApproved = purchaseOrder.approvedAt != null
  let poNumber = purchaseOrder.poNumber
  let purchaseOrderItems = purchaseOrder.purchaseOrderItems

  addLogo(tenant)
  addDeliveryAddress(deliveryAddress)
  addBillingAddress(billingAddress)
  addCompanyDetails(purchaseOrder)
  // addPoNotes(purchaseOrder)
  if (!isApproved) {
    addTitleMessage('PREVIEW *** NOT APPROVED')
  }
  addItemTableHeader()
  addLineItems(purchaseOrderItems)
  addTotal(purchaseOrder)
  addFooter(poNumber)
  open(type, poNumber)
}

/**
 *
 * @param {String} type
 * @param {Number} poNumber
 * @returns
 *
 * Names the file and opens on completion
 */
const open = (type, poNumber) => {
  let filename = poNumber + '.pdf'

  if (type === 'download') {
    doc.value.save(filename)
    return
  }

  let blob = doc.value.output('blob', { filename })
  blob.name = filename
  var url = URL.createObjectURL(blob)
  window.open(url, '_blank')
}

/**
 *
 * @param {Array} locations
 * @param {Object} tenant
 * @returns {Object} formatted billing address
 */
const getBillingAddress = (locations, tenant) => {
  let address = {}
  if (tenant.invoiceLocationId) {
    address =
      _.find(locations, {
        tenantLocationId: tenant.invoiceLocationId
      }) || {}
  }

  return address
}

/**
 *
 * @param {Array} locations
 * @param {Object} purchaseOrder
 * @returns {Object} formatted delivery address
 */
const getDeliveryAddress = (locations, purchaseOrder) => {
  let address = {}
  if (purchaseOrder.deliveryLocationId) {
    address =
      _.find(locations, {
        tenantLocationId: purchaseOrder.deliveryLocationId
      }) || {}
  }

  return address
}

/**
 *
 * @param {Object} tenant
 *
 * Adds to logo to the PO
 */
const addLogo = (tenant) => {
  let x = xCol1.value
  let y = yRow1.value
  if (tenant.logoUrl && tenant.logoUrl != null) {
    let logo = new Image(20, 20)
    logo.src = tenant.logoUrl
    doc.value.addImage(logo, 'PNG', x, y, 20, 20)
  }
}

/**
 *
 * @param {Object} deliveryAddress
 *
 * Writes the delivery address to PO
 */
const addDeliveryAddress = (deliveryAddress) => {
  let x = xCol1.value
  let y = yRow2.value

  doc.value.setFont('Helvetica', 'normal')
  doc.value.setFontSize(10)

  if (Object.keys(deliveryAddress).length != 0) {
    doc.value.setFontSize(16)
    doc.value.text('Delivery Address', x, y)
    doc.value.setFontSize(10)
    addLine(deliveryAddress.companyName, x, y + 5, xCol2.value - xCol1.value)
    addLine(deliveryAddress.address1, x, y + 10, xCol2.value - xCol1.value)
    addLine(deliveryAddress.address2, x, y + 15, xCol2.value - xCol1.value)
    addLine(deliveryAddress.address3, x, y + 20, xCol2.value - xCol1.value)
    addLine(
      deliveryAddress.addressPostcode,
      x,
      y + 25,
      xCol2.value - xCol1.value
    )
  }
}

/**
 *
 * @param {Object} billingAddress
 *
 * Writes the billing address to PO
 */
const addBillingAddress = (billingAddress) => {
  let x = xCol2.value
  let y = yRow2.value

  doc.value.setFont('Helvetica', 'normal')
  doc.value.setFontSize(10)

  if (Object.keys(billingAddress).length != 0) {
    doc.value.setFontSize(16)
    doc.value.text('Billing Address', x, y)
    doc.value.setFontSize(10)
    addLine(billingAddress.companyName, x, y + 5, xCol2.value - xCol1.value)
    addLine(billingAddress.address1, x, y + 10, xCol2.value - xCol1.value)
    addLine(billingAddress.address2, x, y + 15, xCol2.value - xCol1.value)
    addLine(billingAddress.address3, x, y + 20, xCol2.value - xCol1.value)
    addLine(
      billingAddress.addressPostcode,
      x,
      y + 25,
      xCol2.value - xCol1.value
    )
  }
}

/**
 *
 * @param {Object} purchaseOrder
 *
 * Adds the related PO data, inc. PO Number, Supplier, Payment Terms...
 */
const addCompanyDetails = (purchaseOrder) => {
  let x = xCol4.value
  let y = yRow1.value

  doc.value.setFont('Helvetica', 'normal')
  doc.value.setFontSize(10)

  doc.value.text('PO Number', x, y)
  doc.value.text(purchaseOrder.poNumber || '', x + 50, y, {
    align: 'right'
  })

  doc.value.text('Raised', x, y + 5)
  doc.value.text(
    purchaseOrder.createdAt.substring(0, 10) || '',
    x + 50,
    y + 5,
    {
      align: 'right'
    }
  )

  doc.value.text('Raised by', x, y + 10)
  doc.value.text(purchaseOrder.createdBy || '', x + 50, y + 10, {
    align: 'right'
  })

  if (purchaseOrder.approvedBy) {
    doc.value.text('Approved by', x, y + 15)
    doc.value.text(purchaseOrder.approvedBy, x + 50, y + 15, {
      align: 'right'
    })
  }

  doc.value.text('Supplier', x, y + 25)
  doc.value.text(purchaseOrder.supplierName || '', x + 50, y + 25, {
    align: 'right'
  })

  doc.value.text('Terms', x, y + 30)
  doc.value.text(purchaseOrder.paymentTerms || '', x + 50, y + 30, {
    align: 'right'
  })

  doc.value.text('Currency', x, y + 35)
  doc.value.text(
    `${purchaseOrder.currency} (${currency[purchaseOrder.currency].symbol})` ||
      '',
    x + 50,
    y + 35,
    { align: 'right' }
  )
}

/**
 *
 * @param {String} message
 *
 * Adds a title message/warning to the PO
 */
const addTitleMessage = (message) => {
  let x = xCol2.value
  let y = yRow1.value

  if (message != '' || message != null) {
    doc.value.setFontSize(24)
    doc.value.setFont('Helvetica', 'bold')
    doc.value.setTextColor(255, 0, 0)
    doc.value.text(message || '', x, y)
    doc.value.setTextColor(0, 0, 0)
  }
}

/**
 *
 * @param {String} poNotes
 *
 * Adds Purchase Order notes
 */
const addPoNotes = (poNotes) => {
  let x = xCol2.value
  let y = yRow3.value
  if (poNotes != '' || poNotes != null) {
    doc.value.text(poNotes, x, y)
  }
}

/**
 * Add the header for the table
 */
const addItemTableHeader = (y = null) => {
  let x = xCol1.value
  if (!y) {
    y = yRow3.value
  }

  doc.value.setFont('Helvetica', 'bold')
  doc.value.setFontSize(10)

  doc.value.text('SKU', x, y)
  x += 30

  doc.value.text('Supplier SKU', x, y)
  x += 35

  doc.value.text('Title', x, y)
  x += 55

  doc.value.text('Variant', x, y)
  x += 55

  doc.value.text('Units', x, y, { align: 'right' })
  doc.value.text('Per Case', x, y + lineHeight.value, { align: 'right' })
  x += 17

  doc.value.text('Case', x, y, { align: 'right' })
  doc.value.text('Qty', x, y + lineHeight.value, { align: 'right' })
  x += 17

  doc.value.text('Case', x, y, { align: 'right' })
  doc.value.text('Price', x, y + lineHeight.value, { align: 'right' })
  x += 17

  doc.value.text('Item', x, y, { align: 'right' })
  doc.value.text('Total', x, y + lineHeight.value, { align: 'right' })
  x += 17

  doc.value.text('VAT', x, y, { align: 'right' })
  x += 17

  doc.value.text('Line', x, y, { align: 'right' })
  doc.value.text('Total', x, y + lineHeight.value, { align: 'right' })
}

/**
 *
 * @param {Array} items
 *
 * Handles Unit items and case items for the PO table
 */
const addLineItems = (items) => {
  doc.value.setFont('Helvetica', 'normal')
  doc.value.setFontSize(10)
  yCurrent.value = yRow3.value + lineHeight.value + lineHeight.value

  let pages = getItemsPerPage(items)

  for (let pageNumber in pages) {
    if (pageNumber > 1) {
      addNewPage()
    }
    let items = pages[pageNumber]
    addLineItemsForPage(items)
  }
}

const addLineItemsForPage = (items) => {
  let index = 0
  let length = items.length
  for (index; index < length; index++) {
    let item = items[index]
    outputItem(item)
  }
}

const addNewPage = () => {
  doc.value.addPage()
  addItemTableHeader(yRow1.value)
  doc.value.setFont('Helvetica', 'normal')
  doc.value.setFontSize(10)
  yCurrent.value = yRow1.value + lineHeight.value + lineHeight.value
  pageCount.value++
}

const getItemsPerPage = (items) => {
  let pages = {}
  let currentPage = 1
  let currentYPoint = yCurrent.value
  rowMaxLines.value = 1

  let index = 0
  let length = items.length
  for (index; index < length; index++) {
    let item = items[index]

    rowMaxLines.value = getRowCount(item)

    if (currentYPoint > 160) {
      currentPage++
      currentYPoint = yCurrent.value
      // If less than 2/3 items left, then grab 1/2 from end of last page
      if (!items[index + 2]) {
        pages[currentPage] = [pages[currentPage - 1].pop()]
      }

      if (!items[index + 1]) {
        pages[currentPage].push(pages[currentPage - 1].pop())
      }
    }

    if (!pages[currentPage]) {
      pages[currentPage] = []
    }

    pages[currentPage].push(item)
    currentYPoint += lineHeight.value * rowMaxLines.value
  }

  return pages
}

/**
 * Given a line object (ie what will be a line item in the PDF)
 * This will return the number of rows the line will span - taking into account the max column widths
 * @param {Object} line
 */
const getRowCount = (line) => {
  let rowCount = 1
  let index = 0
  let length = fields.length
  for (index; index < length; index++) {
    let field = fields[index]
    let value = line[field]
    let cellWidth = cellWidths[field]
    let text = doc.value.splitTextToSize(value, cellWidth, {})
    if (text.length > rowCount) {
      rowCount = text.length
    }
  }

  return rowCount
}

/**
 *
 * @param {Object} item
 * @param {String} type
 *
 * Writes an item to the table
 */
const outputItem = (item) => {
  let x = xCol1.value

  let sku = item.sku
  let title = item.title

  let variantValue = item.variantValue || ''
  let mpn = item.mpn || ''
  let supplierSku =
    typeof item.supplierSku === 'string' && item.supplierSku.length > 0
      ? item.supplierSku
      : mpn
  let priceCase = item.price || '-'
  let unitsPerCase = item.unitsPerCase || '0'
  let casesOrdered = item.casesOrdered || '0'
  let linePrice = item.linePrice
  let lineVat = item.lineVat
  let lineTotal = item.lineTotal

  rowMaxLines.value = 1

  addTableCell(sku, x, cellWidths.sku)
  x += 30

  addTableCell(supplierSku, x, cellWidths.supplierSku)
  x += 35

  addTableCell(title, x, cellWidths.title, {}, true)
  x += 55

  addTableCell(variantValue.substring(0, 25), x, cellWidths.variantValue)
  x += 55

  addTableCell(String(unitsPerCase.toLocaleString()), x, null, {
    align: 'right'
  })
  x += 17

  addTableCell(String(casesOrdered.toLocaleString()), x, null, {
    align: 'right'
  })
  x += 17

  addTableCell(String(priceCase.toLocaleString()), x, null, {
    align: 'right'
  })
  x += 17

  addTableCell(String(linePrice.toLocaleString()), x, null, {
    align: 'right'
  })
  x += 17

  addTableCell(String(lineVat.toLocaleString()), x, null, {
    align: 'right'
  })
  x += 17

  addTableCell(String(lineTotal.toLocaleString()), x, null, {
    align: 'right'
  })

  yCurrent.value += lineHeight.value * rowMaxLines.value
}

/**
 *
 * @param {String} data to print
 * @param {Integer} x x coord to write to
 * @param {Integer} y y coord to write to
 * @param {Integer} cellWidth Width that the line should be. OPTIONAL
 * @param {Object} options Write formatting
 *
 * This function writes a single line NO WRAP
 */
const addLine = (data, x, y, cellWidth = null, options = {}) => {
  if (cellWidth == null) {
    doc.value.text(data, x, yCurrent.value, options)
  } else {
    let text = doc.value.splitTextToSize(data, cellWidth, {})
    text = text.length > 1 ? text[0] + '...' : text[0]
    doc.value.text(text, x, y, options)
  }
}

/**
 *
 * @param {String} data to print
 * @param {Integer} x x coord to write to
 * @param {Integer} y y coord to write to (start from)
 * @param {Integer} cellWidth Width that each like should be
 * @param {Object} options Write formatting
 * @param {Integer} maxRows maximum number of rows to print. OPTIONAL
 */
const addLines = (data, x, y, cellWidth, options = {}, maxRows = null) => {
  if (cellWidth == null) {
    doc.value.text(data, x, yCurrent.value, options)
  } else {
    let text = doc.value.splitTextToSize(data, cellWidth, {})
    let noOfRows = text.length
    let index = 0

    for (index; index > noOfRows; index++) {
      if (index <= maxRows) {
        doc.value.text(text[index], x, y, options)
        y += lineHeight.value
      }
    }
  }
}

/**
 *
 * @param {String} data to print
 * @param {Integer} x x coord to write to
 * @param {Integer} cellWidth Width that the line should be. OPTIONAL
 * @param {Object} options Write formatting
 *
 * This function writes a single line (cell) NO WRAP
 */
const addTableCell = (
  data,
  x,
  cellWidth = null,
  options = {},
  wrap = false
) => {
  if (cellWidth == null) {
    doc.value.text(data, x, yCurrent.value, options)
  } else {
    let text = doc.value.splitTextToSize(data, cellWidth, {})
    if (wrap) {
      let rows = 0
      let y = yCurrent.value
      text.forEach((line) => {
        doc.value.text(line, x, y, options)
        y += lineHeight.value
        rows++
        if (rows > rowMaxLines.value) {
          rowMaxLines.value = rows
        }
      })
    } else {
      text = text.length > 1 ? text[0] + '...' : text[0]
      doc.value.text(text, x, yCurrent.value, options)
    }
  }
}

/**
 *
 * @param {Object} purchaseOrder
 *
 * Adds the total to the PO
 */
const addTotal = (purchaseOrder) => {
  let x = xCol4.value
  yCurrent.value += lineHeight.value

  doc.value.setFontSize(14)
  doc.value.setFont('Helvetica', 'bold')

  doc.value.text('Subtotal ', x, yCurrent.value)
  doc.value.text(
    (currency[purchaseOrder.currency].symbol || '') +
      ' ' +
      String(purchaseOrder.itemTotal),
    x + 60,
    yCurrent.value,
    { align: 'right' }
  )
  yCurrent.value += lineHeight.value

  doc.value.text('VAT ', x, yCurrent.value)
  doc.value.text(
    (currency[purchaseOrder.currency].symbol || '') +
      ' ' +
      String(purchaseOrder.vatTotal),
    x + 60,
    yCurrent.value,
    { align: 'right' }
  )

  yCurrent.value += lineHeight.value

  doc.value.text('Total ', x, yCurrent.value)
  doc.value.text(
    (currency[purchaseOrder.currency].symbol || '') +
      ' ' +
      String(purchaseOrder.poTotal),
    x + 60,
    yCurrent.value,
    { align: 'right' }
  )
}

/**
 *
 * @param {String} poNumber
 *
 * Adds the footer to the PO, this includes that Purchase Order Number and page number
 */
const addFooter = (poNumber) => {
  doc.value.setFont('Helvetica', 'normal')
  doc.value.setFontSize(10)
  for (let pageNum = 1; pageNum <= pageCount.value; pageNum++) {
    let pageText =
      'Page ' + pageNum.toString() + ' of ' + pageCount.value.toString()
    doc.value.setPage(pageNum)
    doc.value.text('Purchase order:  ' + poNumber, xCol3.value + 50, 195)
    doc.value.text(pageText, xCol4.value + 60, 195, { align: 'right' })
  }
}

export { createPdf }
