<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Messages"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    ref="grid"
    :cols="columns"
    :rows="rows"
    :default-sort="defaultSort"
    :filter-function="getRows"
    :total-count="totalCount"
    id-key="tenantMessageId"
    :reset-filters="resetFilters"
    scrollable
  >
  </TableGrid>
</template>

<script setup>
import {
  ref,
  onActivated,
  watch,
  inject,
  nextTick,
  onDeactivated,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const router = useRouter()
const api = useApi()
const { getTableFilters, toggleArchived } = useColumnFilters(router)
const deactivated = ref(false)

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  pageId: {
    type: String,
    default: ''
  }
})

/**
 * @see  @/components/template/TheProgressBar
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/*
 *****************      GRID
 */

// Template reference to the TableGrid component

const grid = ref(null)
const channels = ref([])
const rows = ref([])
const defaultSort = ref({ channelCreatedAt: -1 })
const totalCount = ref(0)

const getRows = async (args = {}) => {
  loading()
  try {
    progressBarStart()
    let response = await api.get('/messages', args)
    rows.value = response.data.messages
    getChannelValues(response.data.channels)
    totalCount.value = parseInt(response.data.count)
    loaded()
    updateFilters(false)
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

const getChannelValues = (response = []) => {
  let index = 0
  let length = response.length
  let channelValues = {}
  channels.value = response

  for (index; index < length; index++) {
    let channel = response[index]
    channelValues[channel.tenantChannelId] = channel.name
  }

  let columnIndex = columns.value.findIndex((column) => {
    return column.name === 'tenantChannelId'
  })

  columns.value[columnIndex].displayValues = channelValues

  updateColumns(columns.value)
}

const updateTableFilters = ({ filter, sort, skip }) => {
  if (!grid.value) {
    return
  }
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
  grid.value.setPage(skip)
}

const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
// Default Column Settings
const cols = [
  {
    label: 'Subject',
    name: 'subject',
    filter: true,
    visible: true,
    onClick: {
      route: '/messages/',
      id: 'tenantMessageId'
    },
    primary: true
  },
  {
    label: 'Sender',
    name: 'senderId',
    filter: true,
    visible: true
  },
  {
    label: 'Channel',
    name: 'tenantChannelId',
    visible: true,
    filter: true
  },
  {
    label: 'Email',
    name: 'senderEmail',
    filter: true,
    visible: true
  },
  {
    label: 'Item',
    name: 'itemTitle',
    filter: true,
    visible: true
  },
  {
    label: 'Status',
    name: 'messageStatus',
    filter: true,
    visible: true,
    displayValues: {
      Unanswered: 'Unanswered',
      Answered: 'Answered'
    }
  },
  {
    label: 'Created at',
    name: 'channelCreatedAt',
    visible: true,
    dateTime: true
  }
]

const resetFilters = () => {
  appliedFilters.value = {}
}

// Template ref to the ColumnPreferences component
const preferences = ref()

// Shows the column preference modal
const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)
const columns = ref()

const localStorageName = ref('braidMessages')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences } = columnPreferences(localStorageName.value)

columns.value = readColumnPreferences(cols)

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}
/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'
import { read } from '@popperjs/core'

const buttons = ref([
  { id: 0, label: 'Column Settings', click: show, icon: ['fas', 'gear'] }
])

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }

  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    if (!previous) {
      // This means it is coming from a different submenu and will be using the onActivated trigger
      return
    }

    if (deactivated.value) {
      // This means that the component is being kept alive using keep-alive and will be triggered by the onActivated trigger
      return
    }

    filterUpdate.value = true
  }
)

const updateFilters = (needRows = true) => {
  let tableFilters = getTableFilters(defaultSort.value)
  if (needRows) {
    getRows(tableFilters)
  }
  updateTableFilters(tableFilters)
}

watch(filterUpdate, (current, previous) => {
  if (!current) {
    return
  }
  updateFilters()
  filterUpdate.value = false
})
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
.skus {
  padding: 12px 15px 0px 15px;
}
</style>
