<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="SKUs"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    ref="grid"
    :cols="columns"
    :rows="rows"
    :default-sort="defaultSort"
    :filter-function="getRows"
    :total-count="totalCount"
    id-key="tenantSkuId"
    :row-class="getRowClass"
    :reset-filters="resetFilters"
    scrollable
  ></TableGrid>
</template>

// This is for the keep-alive to work
<script>
export default {
  name: 'SkusList'
}
</script>

<script setup>
import {
  ref,
  onActivated,
  watch,
  nextTick,
  inject,
  onDeactivated,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const router = useRouter()
const api = useApi()
const { getTableFilters, toggleArchived } = useColumnFilters(router)
const deactivated = ref(false)

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  pageId: {
    type: String,
    default: ''
  },
  productsId: {
    type: String,
    default: ''
  }
})

/**
 * @see  @/components/template/TheProgressBar
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/*
 *****************      GRID
 */

// Template reference to the TableGrid component
const grid = ref(null)

const rows = ref([])
const totalCount = ref(0)
const defaultSort = ref({ brandName: 1 })
const showArchived = ref(false)

const getRowClass = (row) => {
  if (row.archivedAt != null && showArchived.value == true) {
    return 'archived-row'
  }
}

const toggleShowArchived = async (value) => {
  let filter = toggleArchived()
  grid.value.setFilter(filter)
  grid.value.filterColumns()
}

const newSku = () => {
  router.push('/sku/new')
}

const getRows = async (args = {}) => {
  loading()
  try {
    progressBarStart()

    if (!args.filter) {
      args.filter = {}
    }

    showArchived.value = args.filter.showArchived == 1

    let response = await api.get('/skus', args)
    rows.value = response.data.skus
    totalCount.value = parseInt(response.data.count)
    loaded()
    updateFilters(false)
    progressBarDone()
  } catch (e) {
    progressBarFail()
  }
}

const updateTableFilters = ({ filter, sort, skip }) => {
  if (!grid.value) {
    return
  }

  appliedFilters.value = filter != '' ? filter : {}
  grid.value.clearPreviousFilters()
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
  grid.value.setPage(skip)
}

const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)

const resetFilters = () => {
  appliedFilters.value = {}
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
// Default Column Settings
const cols = [
  {
    label: 'SKU',
    name: 'sku',
    filter: true,
    visible: true,
    width: 150,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true
  },
  {
    label: 'Title',
    name: 'title',
    filter: true,
    visible: true
  },
  {
    label: 'Brand',
    name: 'brandName',
    filter: true,
    visible: true
  },
  {
    label: 'Variant',
    name: 'variant',
    filter: true,
    visible: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    filter: true
  },
  {
    label: 'GTIN',
    name: 'gtin',
    filter: true
  },
  {
    label: 'ASIN',
    name: 'asin',
    filter: true
  },
  // {
  //   label: 'In stock',
  //   name: 'onHand',
  //   number: true,
  //   visible: true,
  //   filter: true,
  //   width: 140,
  //   hideZero: true
  // },
  {
    label: 'Last updated',
    name: 'updatedAt',
    dateTime: true
  }
]

// Template ref to the ColumnPreferences component
const preferences = ref()

// Shows the column preference modal
const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)
const columns = ref()

const localStorageName = ref('braidSKUs')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences } = columnPreferences(localStorageName.value)

columns.value = readColumnPreferences(cols)

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

const goToSkuImport = () => {
  router.push('/sku-import/')
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  { id: 0, label: 'Add new', click: newSku, icon: ['fas', 'circle-plus'] },
  { id: 1, label: 'Column Settings', click: show, icon: ['fas', 'gear'] }
])

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }

  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    filterUpdate.value = true
  }
)

const updateFilters = (needRows = true) => {
  let tableFilters = getTableFilters(defaultSort.value)
  if (needRows) {
    getRows(tableFilters)
  }
  updateTableFilters(tableFilters)
}

watch(filterUpdate, (current, previous) => {
  if (!current) {
    return
  }
  updateFilters()
  filterUpdate.value = false
})
</script>
