import { PDFDocument, degrees, rgb, StandardFonts } from 'pdf-lib'

export class CreateIntegratedLabel {
  constructor() {
    this.document = null
    this.label = null
    this.labelUrl = null
    this.mmToPoint = 0.352777778
    this.perforationFromTop = 120 / this.mmToPoint
    this.headerFontSize = 12
    this.fontSize = 12
    this.y = 0
    this.quantityColStart = 15
    this.skuColStart = 50
    this.mpnColStart = 150
    this.variantColStart = 250
    this.titleColStart = 350
    this.orderNumberDone = false
    this.font
    this.boldFont
  }

  async createMultiple(labels, skus) {
    let index = 0
    let length = labels.length
    this.document = await PDFDocument.create()

    for (index; index < length; index++) {
      let label = labels[index]
      let url = label.url
      let dispatchItems = label.dispatchItems

      if (!url) {
        continue
      }

      await this.create(url, dispatchItems, skus)
    }

    await this.openPdf()
  }

  async create(labelUrl, dispatchItems, skus) {
    this.labelUrl = labelUrl
    await this.embedFonts()

    const page = this.document.addPage()

    await this.drawLabel(page)
    await this.drawDispatchItems(page, dispatchItems, skus)
  }

  async createPackingSlips(orderItems, skus) {
    let index = 0
    let length = orderItems.length
    for (index; index < length; index++) {
      let orderItem = orderItems[index]
      await this.embedFonts()

      const page = this.document.addPage()

      await this.drawDispatchItems(page, orderItem, skus)
    }
  }

  async embedFonts() {
    this.boldFont = await this.document.embedFont(StandardFonts.HelveticaBold)
    this.font = await this.document.embedFont(StandardFonts.Helvetica)
  }

  async drawLabel(page) {
    await this.embedLabel()
    const options = this.getLabelOptions(page)
    page.drawPage(this.label, options)
  }

  async embedLabel() {
    const labelBytes = await fetch(this.labelUrl).then((res) =>
      res.arrayBuffer()
    )
    const [label] = await this.document.embedPdf(labelBytes)
    this.label = label
  }

  getLabelOptions(page) {
    const pageHeight = page.getHeight()
    const pageWidth = page.getWidth()
    const labelHeight = this.label.height
    const tenMm = 10 / this.mmToPoint
    const x = pageWidth - (tenMm + labelHeight)
    this.y = pageHeight - tenMm
    const rotate = degrees(-90)
    return { x: x, y: this.y, rotate: rotate }
  }

  drawDispatchItems(page, dispatchItems, skus) {
    this.y = this.perforationFromTop
    this.y -= 10 / this.mmToPoint

    this.drawTableHeader(page)

    let index = 0
    let length = dispatchItems.length
    for (index; index < length; index++) {
      let dispatchItem = dispatchItems[index]
      let sku = skus.find((sku) => {
        return sku.sku === dispatchItem.sku
      })
      this.drawOrderNumber(page, dispatchItem)
      this.drawTableRow(page, dispatchItem, sku)
    }
  }

  drawOrderNumber(page, dispatchItem) {
    this.orderNumberDone = true
    let y = this.y + 60

    let x = 15
    page.drawText('Order Number: ', {
      x: x,
      y: y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    x = 110
    page.drawText(dispatchItem.orderNumber, {
      x: x,
      y: y,
      size: this.fontSize,
      font: this.font
    })
  }

  drawTableHeader(page) {
    let x = this.quantityColStart
    page.drawText('Qty', {
      x: x,
      y: this.y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    x = this.skuColStart
    page.drawText('SKU', {
      x: x,
      y: this.y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    x = this.mpnColStart
    page.drawText('MPN', {
      x: x,
      y: this.y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    x = this.variantColStart
    page.drawText('Variant', {
      x: x,
      y: this.y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    x = this.titleColStart
    page.drawText('Title', {
      x: x,
      y: this.y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    this.y -= 20
  }

  drawTableRow(page, dispatchItem, skuData) {
    let pageWidth = page.getWidth()
    let quantity = dispatchItem.quantity || ''
    let sku = dispatchItem.sku || ''
    let title = dispatchItem.title || ''
    let mpn = skuData.mpn || ''
    let variant = skuData.variant || ''

    let x = this.quantityColStart
    page.drawText(quantity.toString(), {
      x: x,
      y: this.y,
      size: this.fontSize,
      font: this.font
    })

    x = this.skuColStart
    page.drawText(sku, {
      x: x,
      y: this.y,
      size: this.fontSize,
      font: this.font
    })

    x = this.mpnColStart
    page.drawText(mpn, {
      x: x,
      y: this.y,
      size: this.fontSize,
      font: this.font,
      maxWidth: this.titleColStart - this.mpnColStart,
      lineHeight: 20
    })

    x = this.variantColStart
    page.drawText(variant, {
      x: x,
      y: this.y,
      size: this.fontSize,
      maxWidth: this.titleColStart - this.variantColStart,
      font: this.font,
      lineHeight: 20
    })

    x = this.titleColStart
    page.drawText(title, {
      x: x,
      y: this.y,
      size: this.fontSize,
      font: this.font,
      maxWidth: pageWidth - this.titleColStart - 15,
      lineHeight: 20
    })

    this.y -= 20
  }

  async openPdf() {
    let fileBytesArray = await this.document.save()
    let blob = new Blob([fileBytesArray], { type: 'application/pdf' })
    var url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }
}
