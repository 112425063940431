<template>
  <div
    v-masonry
    transition-duration="0s"
    :column-width="1"
    :gutter="0"
    class="masonry-container"
    item-selector=".masonry-item"
  >
    <slot></slot>
    <div class="col-width"></div>
  </div>
</template>

<script>
export default {
  watch: {
    collapsedLeftNav() {
      this.$redrawVueMasonry('.masonry-wrapper')
    }
  }
}
</script>

<script setup>
const props = defineProps({
  width: {
    type: Number,
    default: 4
  },
  style: {
    type: String,
    default: ''
  },
  header: {
    type: String,
    default: ''
  },
  collapsedLeftNav: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="scss" scoped>
.col-width {
  width: 25%;
}
</style>
