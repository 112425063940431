<template>
  <TablePanel v-if="skuId" :cols="1" :width="2" header="Inventory">
    <template #1>
      <TableForm
        v-if="local.type === 'KIT' || local.type === 'MANUFACTURED'"
        :id="'form-' + activeTab + '-KIT'"
      >
        <TableRow
          v-model="local.maximumAvailable"
          label="Maximum available"
          type="number"
          read-only
        ></TableRow>
      </TableForm>
      <template v-else>
        <div v-if="!pageLoading" @click="toggleCheckInHandling">
          <label for="handleCheckInDate" class="me-3">
            Split by check in date
          </label>
          <input type="checkbox" :checked="handleCheckInDate" />
        </div>
        <TableGrid
          :cols="skuInventoryComplexCols"
          :rows="skuInventoryComplexRows"
          :total-count="skuInventoryComplexRows.length"
        ></TableGrid>
        <div class="row">
          <div class="col-12 col-md-2 offset-md-5 tbl-cell-btn">
            <SkeletonButton v-if="pageLoading"></SkeletonButton>
            <button v-else type="button" class="btn" @click="addNewLine">
              <font-awesome-icon
                :icon="['fas', 'circle-plus']"
              ></font-awesome-icon>
              <b>Add new row</b>
            </button>
          </div>
        </div>
      </template>
    </template>
  </TablePanel>

  <!-- MOVE STOCK MODAL -->
  <BraidModal ref="moveStockModal">
    <template #title>Move stock to another location</template>
    <template #body>
      <TableForm>
        <TableRow
          v-model="moveStockModalData.fromLocationId"
          label="Location to move from"
          :options="
            locations
              .filter((location) => location.managedLocation == 0)
              .map((location) => {
                return {
                  value: location.tenantLocationId,
                  label: location.name
                }
              })
          "
          type="select"
        ></TableRow>
        <TableRow
          v-if="moveStockModalData.fromLocationId"
          v-model="moveStockModalData.toLocationId"
          label="Location to move to"
          :options="
            locations
              .filter(
                (location) =>
                  location.managedLocation == 0 &&
                  location.tenantLocationId != moveStockModalData.fromLocationId
              )
              .map((location) => {
                return {
                  value: location.tenantLocationId,
                  label: location.name
                }
              })
          "
          type="select"
        ></TableRow>
        <TableRow
          v-if="moveStockModalData.fromLocationId"
          :model-value="getStockCount(moveStockModalData.fromLocationId)"
          label="Quantity in stock"
          type="number"
          read-only
        ></TableRow>
        <TableRow
          v-if="moveStockModalData.fromLocationId"
          v-model="moveStockModalData.quantityMoved"
          label="Quantity to move"
          type="number"
        ></TableRow>
        <TableRow
          v-if="moveStockModalData.fromLocationId"
          v-model="moveStockModalData.comments"
          label="Comments"
          type="textarea"
        ></TableRow>
      </TableForm>
    </template>
    <template #footer>
      <button class="btn btn-red" @click="hideMoveStockModal">Close</button>
      <button v-if="canSaveMoveStock" class="btn btn-green" @click="moveStock">
        Move stock
      </button>
      <button v-else class="btn btn-red" disabled>
        Invalid quantity to move
      </button>
    </template>
  </BraidModal>
  <!-- MOVE STOCK MODAL -->
</template>

<script setup>
import { ref, computed, watch, inject, toRaw } from 'vue'
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'
import RowContainer from '@/components/table/form/RowContainer'
import TableText from '@/components/table/form/TableText'
import TableNumber from '@/components/table/form/TableNumber'
import TableDate from '@/components/table/form/TableDate'
import TableSelect from '@/components/table/form/TableSelect'
import { pageLoad } from '@/composables/pageLoad'
import SkeletonButton from '@/components/skeleton/SkeletonButton.vue'

const { getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

import useApi from '@/components/useApi'
const api = useApi()
const {
  formatDate,
  formatDateTime,
  formatDateTimeNow,
  formatDateForSave,
  formatDateTimeForSave,
  UTC_OFFSET
} = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())
const uuid = require('uuid')

const props = defineProps({
  remote: {
    type: Object,
    default() {
      return {}
    }
  },
  skuId: {
    type: [String, Boolean],
    default: false
  },
  product: {
    type: Object,
    default() {
      return {}
    }
  },
  productSettings: {
    type: Object,
    default() {
      return {}
    }
  },
  skuInventory: {
    type: Array,
    default() {
      return []
    }
  },
  checkInLogs: {
    type: Array,
    default() {
      return []
    }
  },
  locations: {
    type: Array,
    default() {
      return []
    }
  },
  editable: {
    type: Boolean,
    default: true
  }
})

const local = ref(props.remote)
let moveStockModal = ref(null)
const moveStockModalData = ref({})
const INITIAL_INVENTORY = [...toRaw(props.skuInventory)]
const moveStockSettings = ref(null)

const newSkuInventoryRecords = ref([])
const updateSkuInventorySimple = (row) => {
  let formattedRecord = {}
  let quantityDifference

  let index = INITIAL_INVENTORY.findIndex((sku) => {
    return sku.tenantSkuId == row.tenantSkuId
  })
  if (index == -1) {
    quantityDifference = row.quantity
    return
  } else {
    quantityDifference = row.quantity - INITIAL_INVENTORY[index].quantity
  }

  index = newSkuInventoryRecords.value.findIndex(
    (record) => record.locationId == row.tenantLocationId
  )
  if (index == -1) {
    formattedRecord = {
      locationId: row.tenantLocationId,
      skuId: props.skuId,
      quantityReceived: quantityDifference
    }
    newSkuInventoryRecords.value.push(formattedRecord)
  } else {
    newSkuInventoryRecords.value[index].quantityReceived = quantityDifference
  }
}

const openMoveStockModal = async () => {
  moveStockModalData.value = { tenantSkuId: props.skuId }
  moveStockModal.value.show()
}

const hideMoveStockModal = async () => {
  moveStockModalData.value = {}
  moveStockModal.value.hide()
}

const canSaveMoveStock = computed(() => {
  let quantityInStock = parseInt(moveStockModalData.value.locationStockCount)
  let quantityToMove = parseInt(moveStockModalData.value.quantityMoved)

  if (!quantityToMove) {
    return false
  }

  return quantityInStock >= quantityToMove
})

const moveStock = async () => {
  let response = await api.post('/skuInventory/', {
    data: moveStockModalData.value,
    tenantSkuId: props.skuId,
    moveStock: 1
  })

  stockMovements.value = response.data.stockMovements || []
  skuInventory.value = response.data.skuInventory || []
  skuInventorySimpleRows.value = response.data.skuInventoryRecords || []

  hideMoveStockModal()
}

const getStockCount = (locationId) => {
  let record = skuInventory.value.find(
    (record) => record.tenantLocationId == locationId
  )
  moveStockModalData.value.locationStockCount = record.quantity
  return record.quantity
}

const unusedStorageLocations = computed(() => {
  if (props.locations.length === 0) {
    return []
  }

  return props.locations.filter((location) => {
    return (
      location.locationType === 'STORE' &&
      props.skuInventory.findIndex((inventory) => {
        return (
          inventory.locationId == location.tenantLocationId &&
          inventory.skuId == props.skuId &&
          inventory.quantity > 0
        )
      }) == -1
    )
  })
})

const formatSkuInventory = () => {
  let formatted = []
  skuInventoryComplexRows.value.forEach((record) => {
    let value = {
      ...record,
      checkInDate: formatDateTimeForSave(record.checkInDate, utcOffset.value),
      expiryDate: formatDateForSave(record.expiryDate)
    }
    formatted.push(value)
  })

  return formatted
}

const addNewLine = () => {
  skuInventoryComplexRows.value.push({
    skuId: props.skuId,
    correlationId: correlationIndex.value
  })
  correlationIndex.value++
}

const managedLocations = computed(() => {
  return props.locations.filter((location) => location.managedLocation == 0)
})

const displayManagedLocations = computed(() => {
  let obj = {}
  managedLocations.value.forEach((location) => {
    obj[location.tenantLocationId] = location.name
  })
  return obj
})

const locationsDisplayValues = computed(() => {
  return props.locations
})

const skuInventoryComplexCols = ref([
  {
    label: 'Location',
    name: 'locationId',
    visible: true,
    input: true,
    inputType: 'select',
    displayValues: locationsDisplayValues,
    display: 'name',
    reduce: (location) => {
      return location.tenantLocationId
    },
    readonly: (row) => {
      if (!row.tenantCheckInLogId) {
        return
      }
      let location = props.locations.find(
        (location) => location.tenantLocationId == row.locationId
      )
      return location.managedLocation == 1
    },
    itemDisabled: (location) => {
      return location.managedLocation == 1
    }
  },
  {
    label: 'Shelf / Bin',
    name: 'bin',
    visible: true,
    input: true,
    inputType: 'text'
  },
  {
    label: 'Quantity',
    name: 'quantityReceived',
    visible: true,
    input: true,
    inputType: 'number',
    number: true
  },
  {
    label: 'Reserved stock',
    name: 'reservedQuantity',
    visible: true,
    number: true
  },
  {
    label: 'Check-In Date',
    name: 'checkInDate',
    visible: false,
    input: true,
    inputType: 'datetime'
  },
  {
    label: 'Expiry Date',
    name: 'expiryDate',
    visible: true,
    input: true,
    inputType: 'date'
  },
  {
    label: 'Batch Code',
    name: 'batchCode',
    visible: true,
    input: true,
    inputType: 'text'
  },
  {
    label: 'Serial Number',
    name: 'serialNumber',
    visible: true,
    input: true,
    inputType: 'text'
  }
])

const INITIAL_SKU_INVENTORY_DATA = ref([])
const correlationIndex = ref(0)

const getCheckInLogData = async () => {
  if (!props.skuId) {
    return
  }

  let data = [...props.checkInLogs]

  data = data.map((record, index) => {
    correlationIndex.value = index + 1
    return {
      ...record,
      checkInDate: formatDateTime(record.checkInDate, utcOffset.value),
      expiryDate: formatDateForSave(record.expiryDate),
      correlationId: index
    }
  })
  setColumnSettings()
  createSkuInventoryRows(data)
}

const skuInventoryComplexRows = ref([])
const handleCheckInDate = ref(0)

const toggleCheckInHandling = () => {
  handleCheckInDate.value = true
  createSkuInventoryRows(INITIAL_SKU_INVENTORY_DATA.value)
  if (handleCheckInDate.value) {
    skuInventoryComplexCols.value.find(
      (record) => record.name == 'checkInDate'
    ).visible = true
  } else {
    skuInventoryComplexCols.value.find(
      (record) => record.name == 'checkInDate'
    ).visible = false
  }
}

const setColumnSettings = () => {
  if (!props.productSettings.handleExpiry) {
    skuInventoryComplexCols.value.find(
      (record) => record.name == 'expiryDate'
    ).visible = false
  }

  if (!props.productSettings.handleBatch) {
    skuInventoryComplexCols.value.find(
      (record) => record.name == 'batchCode'
    ).visible = false
  }

  if (!props.productSettings.handleSerial) {
    skuInventoryComplexCols.value.find(
      (record) => record.name == 'serialNumber'
    ).visible = false
  }
}

const createSkuInventoryRows = (initialRows) => {
  let fullSeperateInventory = JSON.parse(JSON.stringify(initialRows))

  let groupedRecords = _.groupBy(fullSeperateInventory, (r) => {
    let value = `${r.locationId}${r.bin}`
    if (props.productSettings.handleExpiry) {
      value += r.expiryDate
    }
    if (props.productSettings.handleBatch) {
      value += r.batchCode
    }
    if (props.productSettings.handleSerial) {
      value += r.serialNumber
    }
    if (handleCheckInDate.value) {
      value += r.checkInDate
    }

    return value
  })

  let formatted = []
  Object.values(groupedRecords).forEach((arr) => {
    let p = {}
    arr.forEach((value, index) => {
      let reservedQuantity = 0
      if (
        value.reservedQuantity !== '' &&
        value.reservedQuantity != undefined
      ) {
        reservedQuantity = parseInt(value.reservedQuantity)
      }

      if (index == 0) {
        p = {
          ...value,
          tenantCheckInLogId: [value.tenantCheckInLogId],
          reservedQuantity: reservedQuantity
        }
      } else {
        p.tenantCheckInLogId.push(value.tenantCheckInLogId)
        p.quantityReceived += value.quantityReceived
        p.reservedQuantity += reservedQuantity
      }

      if (index == arr.length - 1) {
        formatted.push(p)
      }
    })
  })
  INITIAL_SKU_INVENTORY_DATA.value = JSON.parse(JSON.stringify(formatted))
  skuInventoryComplexRows.value = formatted
}

const _UNIQUE_STATE_ID = ref(uuid.v4())

// @see @/components/table/ValidationProvider
const registerFormState = inject('registerFormState')
const updateFormChanges = inject('updateFormChanges')

registerFormState(_UNIQUE_STATE_ID, 'Sku_Inventory')

watch(
  () => skuInventoryComplexRows.value,
  (newValue) => {
    let changesMade = !_.isEqual(
      toRaw(newValue),
      INITIAL_SKU_INVENTORY_DATA.value
    )
    updateFormChanges(_UNIQUE_STATE_ID, 'Sku_Inventory', changesMade)
  },
  { deep: true }
)

watch(
  () => props.skuInventory,
  () => {
    getCheckInLogData()
  }
)

const updateLocal = (remote = props.remote) => {
  local.value = remote
}

watch(
  () => props.remote,
  (value) => {
    updateLocal(value)
  }
)

getCheckInLogData()

defineExpose({ formatSkuInventory })
</script>

<style scoped>
th {
  text-align: left;
}
</style>
