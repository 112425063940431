import { createStore } from 'vuex'
import { mutations } from './mutations'
import { getters } from './getters'
import actions from './actions'
import createPersistedState from 'vuex-persistedstate'

export const store = createStore({
  state: {
    tenantComplete: false,
    pageLoading: false
  },
  actions,
  mutations,
  getters,
  plugins: [
    createPersistedState({
      storage: window.sessionStorage
    })
  ]
})
