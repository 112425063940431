<template>
  <TablePanel :cols="2" :width="2">
    <template #1>
      <TableForm>
        <!-- NEW PRODUCT RADIO -->
        <TableRow v-if="!skuId && !productId" type="custom">
          <div class="d-flex">
            <div class="form-check radio-input me-3">
              <input
                id="productOptionRadio1"
                class="form-check-input me-2"
                type="radio"
                name="productRadio"
                :checked="newProductOption == 0"
                @click="newProductOption = 0"
              />
              <label class="form-check-label" for="productOptionRadio1">
                Create new product
              </label>
            </div>
            <div class="form-check radio-input">
              <input
                id="productOptionRadio2"
                class="form-check-input me-2"
                type="radio"
                name="productRadio"
                :checked="newProductOption == 1"
                @click="newProductOption = 1"
              />
              <label class="form-check-label" for="productOptionRadio2">
                Add to existing product
              </label>
            </div>
          </div>
        </TableRow>
        <!-- NEW PRODUCT RADIO -->

        <!-- TITLE -->
        <template v-if="!skuId && !productId && newProductOption == 1">
          <TableRow label="Title" type="custom">
            <div class="d-flex align-items-center">
              <TableSelect
                v-model="local.tenantProductId"
                :options="products"
                display="title"
                :reduce="(product) => product.tenantProductId"
                class="title-input"
                @search="searchProducts"
              ></TableSelect>
              <button class="btn title-btn" @click="openModal">
                <FontAwesomeIcon :icon="['fas', 'magnifying-glass']">
                </FontAwesomeIcon>
              </button>
            </div>
          </TableRow>
        </template>
        <TableRow
          v-else-if="local.tenantProductId"
          v-model="local.title"
          label="Title"
          type="text"
          :link="`/products/${local.tenantProductId}`"
          :read-only="readOnly"
        ></TableRow>
        <TableRow
          v-else
          v-model="local.title"
          label="Title"
          type="text"
          :read-only="readOnly"
        ></TableRow>
        <!-- TITLE -->

        <!-- NEW BRAND RADIO -->
        <template v-if="!skuId && !productId && newProductOption == 0">
          <TableRow v-if="!skuId" type="custom">
            <div class="d-flex">
              <div class="form-check radio-input me-3">
                <input
                  id="brandOptionRadio1"
                  class="form-check-input me-2"
                  type="radio"
                  name="brandRadio"
                  :checked="newBrandOption == 0"
                  @click="newBrandOption = 0"
                />
                <label class="form-check-label" for="brandOptionRadio1">
                  Create new brand
                </label>
              </div>
              <div class="form-check radio-input">
                <input
                  id="brandOptionRadio2"
                  class="form-check-input me-2"
                  type="radio"
                  name="brandRadio"
                  :checked="newBrandOption == 1"
                  @click="newBrandOption = 1"
                />
                <label class="form-check-label" for="brandOptionRadio2">
                  Add to existing brand
                </label>
              </div>
            </div>
          </TableRow>
        </template>
        <!-- NEW BRAND RADIO -->

        <!-- BRAND -->
        <TableRow
          v-if="!skuId && newProductOption == 0 && newBrandOption == 1"
          v-model="local.tenantBrandId"
          label="Brand"
          type="select"
          :options="brands"
          display="name"
          :reduce="(brand) => brand.tenantBrandId"
          @search="searchBrands"
        >
        </TableRow>
        <TableRow
          v-else
          v-model="local.brandName"
          label="Brand"
          type="text"
          :read-only="readOnly"
        ></TableRow>
        <!-- BRAND -->

        <!-- CE MARK -->
        <TableRow type="custom" label="CE Mark required">
          <input
            v-model="local.ceMarkRequired"
            type="checkbox"
            :disabled="readOnly"
            :true-value="1"
            :false-value="0"
          />
        </TableRow>
        <!-- ./CE MARK -->

        <!-- HS CODE -->
        <TableRow
          v-model="local.hsCode"
          label="HS Code"
          type="text"
          :read-only="readOnly"
        ></TableRow>
        <!-- HS CODE -->

        <!-- COUNTRY OF ORIGIN -->
        <TableRow
          v-model="local.origin"
          label="Main Country"
          :options="countries"
          type="select"
          :read-only="readOnly"
        ></TableRow>
        <!-- COUNTRY OF ORIGIN -->
      </TableForm>
    </template>
    <template #2>
      <TableForm :style="'height: 50%'">
        <!-- SHORT DESCRIPTION -->
        <TableRow
          v-model="local.enDescriptionSmall"
          label="Short Description"
          type="textarea"
          :style="'height: 20%'"
          :read-only="readOnly"
        ></TableRow>
        <!-- SHORT DESCRIPTION -->

        <!-- LONG DESCRIPTION -->
        <TableRow
          v-model="local.enDescriptionLong"
          label="Description"
          type="textarea"
          :style="'height: 40%'"
          :read-only="readOnly"
        ></TableRow>
        <!-- LONG DESCRIPTION -->

        <!-- INTERNAL NOTES -->
        <TableRow
          v-model="local.internalNotes"
          label="Internal Notes"
          type="textarea"
          :style="'height: 40%'"
          :read-only="readOnly"
        ></TableRow>
        <!-- INTERNAL NOTES -->
      </TableForm>
    </template>
  </TablePanel>

  <BraidModal ref="addToExistingModal">
    <template #title> Add to existing product </template>
    <template #body>
      <TableContainer>
        <TablePanel>
          <template #1>
            <TableGrid
              ref="grid"
              :cols="addToExistingModalCols"
              :rows="addToExistingModalRows"
              :total-count="noOfProducts"
              :default-sort="{ title: 1 }"
              :filter-function="getProductsList"
              :trclick="selectProduct"
              :update-url="false"
            ></TableGrid>
          </template>
        </TablePanel>
      </TableContainer>
    </template>
    <template #footer>
      <div></div>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref, computed, watch, toRef } from 'vue'
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'
import RowContainer from '@/components/table/form/RowContainer'
import TableText from '@/components/table/form/TableText'
import TableSelect from '@/components/table/form/TableSelect'

import useCountries from '@/composables/selectData/useCountries'
import useApi from '@/components/useApi'
import { useRouter } from 'vue-router'
const { getCountriesForSelect } = useCountries()
const countries = getCountriesForSelect()
const api = useApi()
const router = useRouter()
const _ = require('lodash')
const { formatDateTime, UTC_OFFSET } = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())
const loaded = ref(false)
import { useColumnFilters } from '@/composables/useColumnFilters'
const { getTableFilters } = useColumnFilters(router)

const props = defineProps({
  skuId: {
    type: [String, Boolean],
    default: ''
  },
  product: {
    type: Object,
    default() {
      return {}
    }
  },
  productId: {
    type: [String, Boolean],
    default: ''
  },
  editable: {
    type: Boolean,
    default: false
  },
  getProduct: {
    type: Function,
    default() {
      return
    }
  }
})

const local = toRef(props, 'product')
const products = ref([])
const brands = ref([])
const addToExistingModal = ref(null)
const newProductOption = ref(0)
const newBrandOption = ref(0)

const noOfProducts = ref(0)
const addToExistingModalRows = ref([])
const addToExistingModalCols = [
  {
    label: 'Title',
    name: 'title',
    filter: true,
    visible: true
  },
  {
    label: 'Brand',
    name: 'brandName',
    filter: true,
    visible: true
  }
]

const readOnly = computed(() => {
  if (
    !props.editable ||
    local.value.variantNames.length > 0 ||
    newProductOption.value == 1 ||
    (!props.skuId && props.productId)
  ) {
    return true
  }
  return false
})

const searchProducts = (search, loading) => {
  if (search !== '') {
    getProducts(search, loading)
  }
}

const getProducts = _.debounce(async (searchValue, loading) => {
  if (!searchValue) {
    return
  }
  loading(true)
  let response = await api.get('/products', {
    filter: { title: searchValue },
    sort: { brandName: 1 }
  })
  products.value = response.data.products
  loading(false)
})

const searchBrands = (search, loading) => {
  if (search !== '') {
    getBrands(search, loading)
  }
}

const getBrands = _.debounce(async (searchValue, loading) => {
  if (!searchValue) {
    return
  }
  loading(true)
  let response = await api.get('/brands/', {
    filter: { name: searchValue }
  })
  brands.value = response.data.brands
  loading(false)
})

// watch(
//   () => local.value.tenantProductId,
//   async () => {
//     if (local.value.tenantProductId == null) {
//       return
//     }
//     local.value = await props.getProduct(local.value.tenantProductId)
//   }
// )

watch(newProductOption, () => {
  if (newProductOption.value == 0) {
    local.value = {
      tenantProductId: null,
      variantNames: [],
      variantValues: []
    }
  }
})

const save = async () => {
  if (!props.editable) {
    return false
  }
  if (
    newBrandOption.value == 0 &&
    !props.skuId &&
    local.value.brandName != null &&
    local.value.brandName != ''
  ) {
    let response = await api.post('/brands', {
      brand: { name: local.value.brandName }
    })

    let tenantBrandId = response.data.insertedId

    local.value = {
      ...local.value,
      tenantBrandId
    }
  }
  return local.value
}

const openModal = async () => {
  const tableFilters = getTableFilters({ title: 1 })
  if (addToExistingModalRows.value.length == 0) {
    await getProductsList(tableFilters)
  }
  addToExistingModal.value.show()
}

const getProductsList = async (args = {}) => {
  try {
    if (!args.filter) {
      args.filter = {}
    }
    let response = await api.get('/products', args)
    addToExistingModalRows.value = response.data.products
    noOfProducts.value = response.data.count
  } catch (e) {
    console.log(e)
  }
}

const selectProduct = (row) => {
  local.value.tenantProductId = row.tenantProductId
  addToExistingModal.value.hide()
}

defineExpose({ save })
</script>

<style scoped lang="scss">
.radio-input {
  cursor: pointer;
  display: flex;
  align-items: center;

  label,
  input {
    cursor: pointer;
  }
}
</style>
<style lang="scss">
.title-input {
  width: 100%;
}

.title-btn {
  width: fit-content;
}
</style>
