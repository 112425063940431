<template>
  <TableContainer>
    <TablePanel>
      <template #1>
        <Wizard
          ref="wizard"
          :steps="wizardSteps"
          :icons="stepIcons"
          finish-step-text="Import SKUs"
          @complete-wizard="importSkus"
        >
          <!-- INTEGRATION TYPE-->
          <template #integration>
            <ValidationProvider>
              <TableContainer>
                <TablePanel>
                  <template #1>
                    <TableForm>
                      <TableRow
                        v-model="integrationStep.selectedIntegration"
                        type="select"
                        :options="integrationOptions"
                        label="Integration"
                        hover-information="Select which third party you want to import from"
                      ></TableRow>
                    </TableForm>
                  </template>
                </TablePanel>
              </TableContainer>
            </ValidationProvider>
          </template>
          <!-- ./INTEGRATION TYPE-->

          <!-- INTRODUCTION -->
          <template #check_listings>
            <TableGrid
              ref="grid"
              :cols="columns"
              :rows="rows"
              :total-count="rows.length"
              :toggle-all="toggleAllListings"
              :toggle="toggleListing"
              :selected="selectedListings"
              :get-checked="checkedListing"
              id-key="channelListingId"
              checkbox
              select-all
              scrollable
            ></TableGrid>
          </template>
          <!-- ./INTRODUCTION -->

          <template #update_listings_integration>
            These SKUs may take a few minutes to import
          </template>
        </Wizard>
      </template>
    </TablePanel>
  </TableContainer>
</template>
<script setup>
import { ref, watch, computed, nextTick, inject } from 'vue'
import Wizard from './Wizard.vue'
import TableForm from '@/components/table/form/TableForm.vue'
import TableRow from '@/components/table/form/TableRow.vue'
import ValidationProvider from '@/components/table/ValidationProvider.vue'
import RowContainer from '@/components/table/form/RowContainer.vue'
import TableFileUpload from '@/components/table/form/TableFileUpload'
import useApi from '@/components/useApi'
import formatForSelect from '@/composables/formatForSelect'
import { useRouter } from 'vue-router'

const createAlert = inject('createAlert')

const api = useApi()
const integrations = ref([])
const listings = ref([])
const integrationOptions = ref([])
const rows = ref([])
const selectedListings = ref([])
const router = useRouter()

const columns = [
  {
    label: 'Title',
    name: 'title',
    visible: true
  },
  {
    label: 'SKU',
    name: 'sku',
    visible: true
  },
  {
    label: 'ASIN',
    name: 'asin',
    visible: true
  },
  {
    label: 'In Braid',
    name: 'existsInBraid',
    visible: true,
    boolean: true
  }
]

const importSkus = async () => {
  if (!integrationStep.value.selectedIntegration) {
    return
  }

  let integration = integrations.value.find((integration) => {
    return (
      integration.tenantChannelId == integrationStep.value.selectedIntegration
    )
  })

  if (!integration) {
    return
  }

  let service = getService(integration.channel)
  let channelListingIds = selectedListings.value.map((listing) => {
    return listing.channelListingId
  })

  await api.post(`/${service}/listings-import`, {
    listings: channelListingIds
  })

  router.push('/')
}

const checkCanProgress = () => {
  let value = false
  if (selectedListings.value.length > 0) {
    value = true
  }
  changeStepComplete('Check listings', value)
  changeStepComplete('Import SKUs', value)
}

const checkedListing = (row) => {
  return selectedListings.value.includes(row)
}

const toggleAllListings = (checked) => {
  if (!checked) {
    selectedListings.value = []
    checkCanProgress()
    return
  }

  let index = 0
  let length = rows.value.length

  for (index; index < length; index++) {
    let row = rows.value[index]

    if (selectedListings.value.indexOf(row) !== -1) {
      continue
    }

    selectedListings.value.push(row)
  }

  checkCanProgress()
}

const toggleListing = (row) => {
  let currIndex = selectedListings.value.indexOf(row)

  checkCanProgress()

  if (currIndex !== -1) {
    selectedListings.value.splice(currIndex, 1)
    return
  }

  selectedListings.value.push(row)
}

const getIntegrations = async (args = {}) => {
  let response = await api.get('/channels', args)

  if (!response) {
    return
  }

  let data = response.data.channels
  integrations.value = data
  integrationOptions.value = formatForSelect(data, 'tenantChannelId', 'name')
}

getIntegrations({ filter: { channelType: 'MARKETPLACE' } })

/*******************************************************
                    GENERAL WIZARD SETUP
 ********************************************************/

const DEFAULT_STEPS = ['Integration', 'Check listings', 'Import SKUs']
const stepIcons = ref({
  Integration: ['fas', 'code-branch'],
  'Check listings': ['fas', 'file-lines'],
  'Get listings from eBay': ['fab', 'ebay'],
  'Get listings from Amazon': ['fab', 'amazon'],
  'Get listings from Shopify': ['fab', 'shopify'],
  'Import SKUs': ['fas', 'plus']
})
let wizardSteps = ref([].concat(DEFAULT_STEPS))

/**
 * Mark a step as complete or incomplete
 * @param {string} stepName Name of step to complete (must be one of the wizardSteps array options)
 * @param {boolean} completed True or false if the step is completed
 */
const changeStepComplete = (stepName, completed) => {
  wizard.value.toggleStepCompleted(stepName, completed)
}

//template refs
const wizard = ref(null)

const getListings = async (tenantChannelId) => {
  let integration = integrations.value.find((integration) => {
    return integration.tenantChannelId == tenantChannelId
  })

  if (!integration) {
    return
  }

  let service = getService(integration.channel)
  let response = await api.get(`/${service}/listings`, { tenantChannelId })

  if (!response.data.listings || response.data.listings.length === 0) {
    createAlert(
      'error',
      'There are no available listings on this channel. Please wait and try again.'
    )
    return
  }

  listings.value = response.data.listings
  let skus = listings.value.map((listing) => {
    return listing.sku
  })

  let skuResponse = await api.get('/skus/import', { skus: skus })
  let skuRecords = skuResponse.data.skus
  formatRows(skuRecords)
  changeStepComplete('Integration', true)
}

const getService = (channel) => {
  switch (channel) {
    case 'AMAZON_EU':
      return 'amazon'
    case 'EBAY':
      return 'ebay'
    case 'SHOPIFY':
      return 'shopify'
    default:
      return
  }
}

const formatRows = (skus) => {
  let formatted = []

  let index = 0
  let length = listings.value.length
  for (index; index < length; index++) {
    let listing = listings.value[index]
    let sku = listing.sku
    let skuRecord = skus.find((record) => {
      return record.sku === sku
    })

    let existsInBraid = skuRecord == undefined ? 0 : 1
    let row = {
      sku: sku,
      existsInBraid: existsInBraid,
      channelListingId: listing.channelListingId,
      title: listing.title,
      asin: listing.asin
    }
    formatted.push(row)

    if (!existsInBraid) {
      selectedListings.value.push(row)
    }
  }

  checkCanProgress()
  rows.value = formatted
}

/*******************************************************
                 INTEGRATION TYPE STEP
 ********************************************************/

const integrationStep = ref({ selectedIntegration: '' })
watch(
  () => integrationStep.value.selectedIntegration,
  async (value) => {
    //Depending on the integration chosen change the steps in the wizard
    //First reset to default steps then add new steps as required
    //Check to see if the step has been completed
    if (value !== null || typeof value !== 'undefined' || value !== '') {
      await getListings(value)
    }
  }
)

/*******************************************************
                 SHOPIFY CHOICES
 ********************************************************/
</script>

<style lang="scss"></style>
