<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Location"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    v-if="displayGrid"
    ref="grid"
    :cols="columns"
    :rows="rows"
    :total-count="totalCount"
    :filter-function="getRows"
    :default-sort="defaultSort"
    id-key="tenantLocationId"
    :row-class="getRowClass"
    scrollable
  ></TableGrid>
</template>

// This is for the keep-alive to work
<script>
export default {
  name: 'LocationsList'
}
</script>

<script setup>
import { ref, onActivated, watch, inject, nextTick, onDeactivated } from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'

const router = useRouter()
const api = useApi()
const { getTableFilters, toggleArchived } = useColumnFilters(router)
const deactivated = ref(false)

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  pageId: {
    type: String,
    default: ''
  }
})

/**
 * @see  @/components/template/TheProgressBar
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/*
 *****************      GRID
 */

// Template reference to the TableGrid component
const grid = ref(null)

const displayGrid = ref(false)
const rows = ref([])
const totalCount = ref(0)
const defaultSort = ref({ name: 1 })
const showArchived = ref(false)

const getRowClass = (row) => {
  if (row.archivedAt != null && showArchived.value == true) {
    return 'archived-row'
  }
}

const newLocation = () => {
  router.push('/locations/new')
}

const getRows = async (args = {}) => {
  try {
    progressBarStart()

    if (!args.filter) {
      args.filter = {}
    }

    showArchived.value = args.filter.showArchived == 1

    let response = await api.get('/locations', args)
    rows.value = response.data.locations
    totalCount.value = parseInt(response.data.count)
    displayGrid.value = true
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

const updateTableFilters = ({ filter, sort, skip }) => {
  if (!grid.value) {
    return
  }
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
  grid.value.setPage(skip)
}
const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)

const gotoInventory = (id) => {
  router.push('/inventory/' + id)
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
// Default Column Settings
const cols = [
  {
    label: 'Name',
    name: 'name',
    visible: true,
    onClick: {
      route: '/locations/',
      id: 'tenantLocationId'
    },
    primary: true
  },
  {
    label: 'Country',
    name: 'country',
    visible: true
  },
  {
    label: 'View inventory',
    name: 'viewInventory',
    button: true,
    visible: true,
    click: gotoInventory
  }
]

// Template ref to the ColumnPreferences component
const preferences = ref()

// Shows the column preference modal
const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)
const columns = ref()

const localStorageName = ref('braidLocations')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences, assignColumnFunctions } = columnPreferences(
  localStorageName.value
)

const columnFunctions = {
  viewInventory: {
    click: gotoInventory
  }
}

const getLocationColumns = () => {
  readColumnPreferences(cols)
  columns.value = assignColumnFunctions(columnFunctions)
}
getLocationColumns()

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  {
    id: 0,
    label: 'Add new',
    click: newLocation,
    icon: ['fas', 'circle-plus']
  },
  {
    id: 1,
    label: 'Column Settings',
    click: show,
    icon: ['fas', 'gear']
  }
])

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }
  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    filterUpdate.value = true
  }
)

watch(displayGrid, (current, previous) => {
  if (displayGrid.value) {
    nextTick(() => updateTableFilters(tableFilters))
  }
})

watch(filterUpdate, async (current, previous) => {
  if (!current) {
    return
  }

  let tableFilters = getTableFilters(defaultSort.value)
  getRows(tableFilters)
  updateTableFilters(tableFilters)
  filterUpdate.value = false
})
</script>
