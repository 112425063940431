<template>
  <ValidationProvider>
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: {
          disable: local.defaultMarketplace == null
        }
      }"
    />
    <TableContainer>
      <TablePanel>
        <template #1>
          <TableForm>
            <TableRow
              v-model="local.defaultMarketplace"
              type="select"
              :options="marketplaces"
              label="Default marketplace"
              hover-information="Select your default marketplace"
            ></TableRow>
          </TableForm>
        </template>
      </TablePanel>
    </TableContainer>
  </ValidationProvider>
</template>

<script setup>
import useApi from '@/components/useApi'
import { useRouter } from 'vue-router'
import { ref } from 'vue'
import useMarketplaces from '@/composables/selectData/useMarketplaces'
import TableRow from '@/components/table/form/TableRow.vue'
import TableForm from '@/components/table/form/TableForm.vue'
import ValidationProvider from '@/components/table/ValidationProvider.vue'
import RowContainer from '@/components/table/form/RowContainer.vue'

const api = useApi()
const router = useRouter()
const code = router.currentRoute.value.query.spapi_oauth_code
const local = ref({ defaultMarketplace: null })

const { getMarketplacesForSelect } = useMarketplaces()
const marketplaces = getMarketplacesForSelect()

const getUserAccessToken = async () => {
  if (!code) {
    return
  }

  const name = 'Amazon'
  const endpoint = 'AMAZON'

  let response = await api.post('/integrations/', {
    endpoint,
    name
  })

  await api.post('/channels', {
    newAmazon: 1,
    code: code,
    defaultMarketplace: local.value.defaultMarketplace
  })
  gotoChannels()
}
const gotoChannels = () => {
  router.push('/channels/')
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = [
  {
    id: 0,
    label: 'Save',
    click: getUserAccessToken,
    icon: ['fas', 'floppy-disk']
  }
]
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.channel {
  padding: 12px 15px 0px 15px;
}
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
