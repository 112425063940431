<template>
  <span v-if="editable">
    <TableText
      v-if="type == 'text'"
      ref="input"
      v-model="value"
      :rules="rules"
      class="unlocked"
      :placeholder="placeholder"
      @update:model-value="updated"
    ></TableText>
    <TableNumber
      v-else-if="type == 'number'"
      ref="input"
      v-model="value"
      class="unlocked"
      :rules="rules"
      @update:model-value="updated"
    ></TableNumber>
    <TableDate
      v-else-if="type == 'date'"
      ref="input"
      v-model="value"
      class="unlocked"
      :rules="rules"
      @update:model-value="updated"
    ></TableDate>
    <TableSelect
      v-else-if="type == 'select'"
      ref="input"
      v-model="value"
      :display="display"
      :reduce="reduce"
      :options="options"
      class="unlocked"
      :rules="rules"
      @update:model-value="updated"
      @search="$emit('search', $event)"
    ></TableSelect>
    <slot v-else-if="type == 'custom'"></slot>
  </span>
  <span v-else class="locked">
    <p>{{ modelValue }}</p>
    <button class="btn btn-grey borderless" @click="editable = true">
      {{ buttonText }}
    </button>
  </span>
</template>

<script setup>
import { ref, watch } from 'vue'
import TableText from '@/components/table/form/TableText'
import TableNumber from '@/components/table/form/TableNumber'
import TableDate from '@/components/table/form/TableDate'
import TableSelect from '@/components/table/form/TableSelect'

const emit = defineEmits(['update:modelValue', 'search'])
const props = defineProps({
  modelValue: {
    type: null,
    validator: (v) => true,
    default: ''
  },
  type: {
    type: String,
    default: 'text'
  },
  buttonText: {
    type: String,
    default: 'Edit'
  },
  display: {
    type: String,
    default: 'label'
  },
  reduce: {
    type: Function,
    default() {}
  },
  options: {
    type: Array,
    default() {
      return []
    }
  },
  rules: {
    type: Object,
    default() {
      return {}
    }
  }
})

const value = ref(props.modelValue)

watch(
  () => props.modelValue,
  (val) => (value.value = val)
)

const editable = ref(false)
const previousValue = ref(null)

const updated = (data) => {
  if (data == previousValue.value) {
    return
  }
  data = formatData(data)
  emit('update:modelValue', data)
  previousValue.value = data
}

const formatData = (data) => {
  switch (props.type) {
    case 'text':
      return data.toString()
    case 'number':
      return parseInt(data)
    default:
      return data
  }
}
</script>

<style scoped lang="scss">
.locked {
  display: flex;
  align-items: center;
  padding-left: 0.5rem;

  & p {
    margin: 0 0.5rem 0 0;
  }
}
.unlocked {
  padding-left: 0.5rem;
}
</style>
