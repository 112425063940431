<template>
  <Widget :style="clickable ? 'cursor:pointer' : ''" @click="action">
    <div class="big-number">{{ number }}</div>
    <div class="description-text">{{ text }}</div>
    <div v-if="clickable" class="clickable">
      <FontAwesomeIcon
        :icon="['fas', 'chevron-right']"
        class="btn-icon"
      ></FontAwesomeIcon>
    </div>
  </Widget>
</template>

<script setup>
import { ref, inject } from 'vue'
import Widget from '@/components/content/widgets/Widget'
import { useRouter } from 'vue-router'

const router = useRouter()
const width = 2
const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  number: {
    type: [Number, String],
    default: 0
  },
  action: {
    type: Function,
    default: () => {}
  },
  clickable: {
    type: Boolean,
    default: false
  }
})
</script>

<style lang="scss" scoped>
.big-number {
  font-size: 50px;
  text-align: center;
}

.description-text {
  font-size: 20px;
  text-align: center;
}

.clickable {
  position: absolute;
  right: 1rem;
  top: 4rem;
}
</style>
