<template>
  <Table
    id="orders-table"
    :columns="orderColumns"
    :get-data="getData"
    :rows="orders"
    id-key="tenantOrderId"
    :total-count="orderCount"
    :limit="orderLimit"
    header="Orders to dispatch"
    :link="ordersLink"
    link-text="View awaiting dispatch"
  ></Table>
  <Graph
    id="orders-graph"
    header="Orders (past 14 days)"
    chart-type="LineChart"
    :chart-data="ordersChartData"
    :chart-options="ordersChartOptions"
  ></Graph>
  <Button
    v-if="orders && orders.length > 0"
    id="picklist-button"
    :action="createPicklist"
    text="View order picklist"
  />
  <Button
    v-if="orders && orders.length > 0"
    id="summary-button"
    :action="createSummary"
    text="View order summary"
  />

  <Number
    v-if="orders && orders.length > 0"
    id="dispatches-number"
    :number="orderCount"
    text="unfulfilled dispatches"
    :action="goToDispatches"
    clickable
  />
  <Number
    v-if="orderTotal"
    id="order-total"
    :number="orderTotal"
    text="orders in last 30 days"
  />
  <Number
    v-if="orderUnitTotal"
    id="units-total"
    :number="orderUnitTotal"
    text="Units in last 30 days"
  />
  <Number
    v-if="orderIncomeTotal"
    id="income-total"
    :number="`£ ${orderIncomeTotal}`"
    text="income from orders in last 30 days"
  />
</template>

<script setup>
import { ref, inject, nextTick } from 'vue'
import { dayjs } from '@/dayjs'

import Number from '@/components/content/widgets/Number'
import Button from '@/components/content/widgets/Button'
import Graph from '@/components/content/widgets/Graph'
import Table from '@/components/content/widgets/Table'
import { Picklist } from '@/composables/pdfs/picklist'
import { OrderSummary } from '@/composables/pdfs/orderSummary'
import useApi from '@/components/useApi'
import { useRouter } from 'vue-router'
const api = useApi()
const router = useRouter()
const redrawVueMasonry = inject('redrawVueMasonry')

const orders = ref(null)
const orderCount = ref(0)
const ordersChartData = ref(null)
const ordersChartOptions = ref(null)
const channels = ref(null)
const orderTotal = ref(0)
const orderUnitTotal = ref(0)
const orderIncomeTotal = ref(0.0)
const orderLimit = 5
const ordersLink = `/orders?filter=status*=1,2^paymentStatus=1^fulfillmentChannel=MERCHANT`
const dispatchLink = `/dispatches?filter=orderStatus*=1`
const chartOptions = {
  backgroundColor: 'transparent',
  hAxis: { slantedText: 'true' },
  hAxis: { slantedTextAngle: '90' }
}

const createPicklist = async () => {
  const formatted = await formatItemsForPdf()
  const picklist = new Picklist()
  picklist.create(formatted)
}

const createSummary = async () => {
  const formatted = await formatItemsForPdf()
  const orderSummary = new OrderSummary()
  orderSummary.create(formatted)
}

const formatItemsForPdf = async () => {
  let itemResponse = await api.get('/orders/items-status', { status: 1 })
  let unshippedItems = itemResponse.data.items

  if (unshippedItems.length === 0) {
    return
  }

  let skus = unshippedItems.map((item) => {
    return item.sku
  })

  let skuResponse = await api.get('/skus/orders', { skus })
  let skuData = skuResponse.data.skus

  let index = 0
  let length = unshippedItems.length
  let formatted = {}

  for (index; index < length; index++) {
    let unshippedItem = unshippedItems[index]
    let sku = unshippedItem.sku
    let tenantChannelId = unshippedItem.tenantChannelId
    let orderNumber = unshippedItem.orderNumber
    let customerName = unshippedItem.customerName

    let itemSkuData = skuData.find((record) => {
      return record.sku == sku
    })

    if (!itemSkuData) {
      console.log('NO RECORD FOR ' + sku)
      continue
    }

    if (itemSkuData.type === 'KIT') {
      continue
    }

    if (!formatted[orderNumber]) {
      let channelData = channels.value.find((channel) => {
        return channel.tenantChannelId == tenantChannelId
      })
      formatted[orderNumber] = {
        channelData: channelData,
        customerName: customerName,
        items: []
      }
    }

    unshippedItem.skuData = itemSkuData

    formatted[orderNumber].items.push(unshippedItem)
  }

  return formatted
}

const createOrderSummary = async () => {
  const formatted = await formatItemsForPdf()
  const orderSummary = new OrderSummary()
  orderSummary.create(formatted)
}

const orderColumns = ref([
  {
    label: 'Order number',
    name: 'orderNumber',
    visible: true,
    onClick: {
      route: '/orders/',
      id: 'tenantOrderId'
    },
    primary: true
  },
  {
    label: 'Channel',
    name: 'tenantChannelId',
    visible: true
  },
  {
    label: 'Customer',
    name: 'customerName',
    visible: true
  },
  {
    label: 'Price',
    name: 'totalPrice',
    number: true,
    visible: true
  }
])

const getData = async (args = {}) => {
  await getOrders()
  await getOrderStatistics()
  emit('done')
}

const goToDispatches = () => {
  router.push(dispatchLink)
}

const getOrders = async () => {
  let args = {
    limit: orderLimit,
    sort: { orderNumber: -1 },
    filter: {
      status: ['1', '2'],
      paymentStatus: '1',
      fulfillmentChannel: 'MERCHANT'
    }
  }
  let response = await api.get('/orders', args)
  orderColumns.value = getChannelValues(
    orderColumns.value,
    response.data.channels
  )
  orders.value = response.data.orders
  orderCount.value = parseInt(response.data.count)
}

const getOrderStatistics = async () => {
  let dateFrom = dayjs().subtract(30, 'day').format('YYYY-MM-DD')
  let dateTo = dayjs().format('YYYY-MM-DD')
  let response = await api.get('/statistics/orders', { dateFrom, dateTo })
  let stats = response.data.statistics

  let currentDate = dayjs(dateFrom)
  let endDate = dayjs(dateTo)
  ordersChartData.value = [['Date', 'Number of Orders']]
  let chartData = [['Date', 'Order count', 'Unit count']]
  let totalOrders = 0
  let totalUnits = 0
  let totalIncome = 0.0

  while (currentDate.isSameOrBefore(endDate)) {
    let dayStats = stats.filter((record) => {
      return dayjs(record.date).isSame(currentDate, 'day')
    })

    if (!dayStats || dayStats.length === 0) {
      dayStats = [{}]
    }

    let { dayOrders, dayUnits, dayIncome, dayChartData } = addDayStats(
      currentDate,
      endDate,
      dayStats
    )

    totalOrders += dayOrders
    totalUnits += dayUnits
    totalIncome += dayIncome

    chartData.push(...dayChartData)

    currentDate = currentDate.add(1, 'day')
  }

  orderTotal.value = totalOrders
  orderUnitTotal.value = totalUnits
  orderIncomeTotal.value = totalIncome.toFixed(2)

  ordersChartData.value = chartData
  ordersChartOptions.value = chartOptions
}

const addDayStats = (currentDate, endDate, dayStats) => {
  let index = 0
  let length = dayStats.length
  let dayOrders = 0
  let dayUnits = 0
  let dayIncome = 0
  let dayChartData = []

  for (index; index < length; index++) {
    let dayStat = dayStats[index]

    let orderCount = parseInt(dayStat.orderCount) || 0
    let unitCount = parseInt(dayStat.unitCount) || 0
    let income = parseFloat(dayStat.totalIncome) || 0.0

    dayOrders += orderCount
    dayUnits += unitCount
    dayIncome += income
  }

  let date = currentDate.format('DD/MM')
  dayChartData.push([date, dayOrders, dayUnits])

  return {
    dayOrders,
    dayUnits,
    dayIncome,
    dayChartData
  }
}

const getChannelValues = (tableCols, response = []) => {
  let index = 0
  let length = response.length
  let channelValues = {}
  channels.value = response

  for (index; index < length; index++) {
    let channel = response[index]
    channelValues[channel.tenantChannelId] = channel.name
  }

  let columnIndex = tableCols.findIndex((column) => {
    return column.name === 'tenantChannelId'
  })

  tableCols[columnIndex].displayValues = channelValues
  return tableCols
}

const emit = defineEmits(['done'])
</script>

<style scoped lang="scss">
.masonry-container {
  margin: 1rem;
  width: 100%;
}

.spacer {
  width: 0.5rem;
}

.dispatch-order-widget {
  height: 10rem;
  width: 10rem;
  .number-data {
    height: 60%;
    font-size: 4rem;
    font-weight: 600;
  }

  .text-title {
    height: 40%;
    text-align: center;
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
