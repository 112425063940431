<template>
  <div class="scrollable-wrapper">
    <div class="scrollable">
      <MasonryContainer :collapsed-left-nav="collapsedLeftNav">
        <Alerts :done="markLoaded"></Alerts>
        <Messages :done="markLoaded"></Messages>
        <Orders :done="markLoaded"></Orders>
        <Reports :done="markLoaded"></Reports>
        <!-- <IntegrationHealth></IntegrationHealth> -->
      </MasonryContainer>
    </div>
  </div>
</template>

<script setup>
import { ref, inject, computed, onMounted, watch } from 'vue'
import { checkTenantComplete } from '@/composables/checkTenantComplete.js'
import MasonryContainer from '@/components/MasonryContainer'
import Orders from '@/components/content/dashboard/widgets/Orders'
import Messages from '@/components/content/dashboard/widgets/Messages'
import Alerts from '@/components/content/dashboard/widgets/Alerts'
import Reports from '@/components/content/dashboard/widgets/Reports'
import Widget from '@/components/content/widgets/Widget'
import IntegrationHealth from '@/components/content/dashboard/widgets/IntegrationHealth'
import SkeletonPanel from '@/components/skeleton/SkeletonPanel.vue'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()
const redrawVueMasonry = inject('redrawVueMasonry')

const completed = ref(0)
const widgetCount = 4

const pageLoading = computed(() => {
  return getLoading()
})

const markLoaded = () => {
  completed.value++
}

watch(completed, (current) => {
  if (current === widgetCount) {
  }
})

const date = ref(new Date(Date.now()).toISOString())
checkTenantComplete()

const collapsedLeftNav = inject('collapsedLeftNav')
</script>

<style lang="scss" scoped>
.dashboard-control-bar {
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  background-color: $braid-grey-1;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);

  justify-content: center;

  gap: 1em;
  flex-wrap: wrap;
  button {
    background-color: $braid-grey-1;
    &.btn {
      border: 0;
    }
    span {
      padding-left: 0.5rem;
    }
  }
}
</style>
