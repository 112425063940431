<template>
  <input
    ref="input"
    type="date"
    class="table-date-input"
    :value="modelValue"
    :readonly="readOnly"
    @input="$emit('update:modelValue', $event.target.value)"
    @click="focus()"
  />
</template>

<script setup>
import { ref } from 'vue'
import useTableInput from '@/components/table/useTableInput'

const config = useTableInput()

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  readOnly: {
    type: Boolean,
    default: false
  }
})

const emits = defineEmits(['update:modelValue'])

const input = ref(null)

const focus = () => {
  input.value.showPicker()
}

defineExpose({ focus })
</script>

<style lang="scss">
.table-date-input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 0 0.5rem;
  border-radius: 3px;
}
input[type='date']::-webkit-calendar-picker-indicator {
  visibility: hidden;
}
</style>
