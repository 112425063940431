<template>
  <BraidModal ref="receiveMultipleModal">
    <template #title>Receive stock</template>
    <template #body>
      <TablePanel :cols="1" :width="4">
        <template #1>
          <TableForm>
            <TableRow
              v-model="local.locationId"
              type="select"
              :options="locationOptions"
              label="Location"
            ></TableRow>
          </TableForm>
        </template>
      </TablePanel>

      <TablePanel v-show="local.locationId != null">
        <template #1>
          <TableGrid
            :cols="receiveMultipleCols"
            :rows="items"
            id-key="tenantPurchaseOrderItemId"
            :update-url="false"
            :total-count="items.length"
            caption="Receive stock"
            scrollable
          ></TableGrid>
        </template>
      </TablePanel>
    </template>
    <template #footer>
      <button class="btn btn-red" @click="closeReceiveMultipleModal">
        Close
      </button>
      <button
        class="btn btn-green"
        :disabled="saving || !local.locationId"
        @click="receiveMultipleToLocation"
      >
        {{ buttonText }}
      </button>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref, computed } from 'vue'
import useApi from '@/components/useApi'
import TableRow from '@/components/table/form/TableRow.vue'
import TableForm from '@/components/table/form/TableForm.vue'

const api = useApi()

const saving = ref(false)
const receiveMultipleModal = ref(null)
const local = ref({ locationId: null })

const emit = defineEmits(['getData', 'close'])

const buttonText = computed(() => {
  if (!local.value.locationId) {
    return 'Select a location to check in'
  }

  let location = props.locations.find((location) => {
    return location.tenantLocationId
  })

  return `Check in to ${location.name}`
})

const locationOptions = computed(() => {
  return props.locations.map((location) => {
    return { label: location.name, value: location.tenantLocationId }
  })
})

const props = defineProps({
  items: {
    type: Array,
    default: () => {
      ;[]
    }
  },
  purchaseOrderId: {
    type: String,
    required: true
  },
  deliveryLocationId: {
    type: String,
    default: ''
  },
  locations: {
    type: Array,
    default: () => {
      return []
    }
  },
  purchaseOrderItems: {
    type: Array,
    default() {
      return []
    }
  },
  getData: {
    type: Function,
    default() {
      return
    }
  }
})

const receiveMultipleCols = ref([
  {
    label: 'SKU',
    name: 'sku',
    visible: true
  },
  {
    label: 'Title',
    name: 'title',
    visible: true
  },
  {
    label: 'Variants',
    name: 'variants',
    visible: true
  },
  {
    label: 'Quantity ordered',
    name: 'quantityOrdered',
    visible: true
  },
  {
    label: 'Quantity received',
    name: 'quantityReceived',
    visible: true
  },
  {
    label: 'Quantity cancelled',
    name: 'quantityCancelled',
    visible: true
  },
  {
    label: 'Quantity to receive',
    name: 'quantityToReceive',
    input: true,
    visible: true
  }
])

const receiveMultiple = async () => {
  let uri = `/purchaseOrders/${purchaseOrderId.value}`
  let index = 0
  let length = props.items.length
  let formattedItems = []

  for (index; index < length; index++) {
    let item = props.items[index]
    let quantityReceived = item.quantityReceived || 0
    item.quantityReceived =
      parseInt(quantityReceived) + parseInt(item.quantityToReceive)
    formattedItems.push(item)
  }

  let params = {
    purchaseOrderItems: formattedItems,
    updateItemsOnly: 1,
    purchaseOrderId: purchaseOrderId.value
  }

  await api.put(uri, params, saving)
  closeReceiveMultipleModal()
  emit('getData')
}

const receiveMultipleToLocation = async () => {
  let skuInventoryItems = props.items.map((item) => ({
    tenantPurchaseOrderItemId: item.tenantPurchaseOrderItemId,
    skuId: item.tenantSkuId,
    quantity: item.quantityToReceive || 0,
    locationId: local.value.locationId,
    received: item.quantityReceived || 0
  }))

  skuInventoryItems = skuInventoryItems.filter((item) => item.quantity > 0)

  if (skuInventoryItems.length == 0) {
    return
  }

  let purchaseOrderItems = []

  skuInventoryItems.forEach((item) => {
    purchaseOrderItems.push({
      tenantPurchaseOrderItemId: item.tenantPurchaseOrderItemId,
      quantityReceived: parseInt(item.received) + parseInt(item.quantity)
    })
  })

  let params = {
    purchaseOrderId: props.purchaseOrderId,
    skuInventory: skuInventoryItems,
    purchaseOrderItems,
    checkInMultiple: 1
  }

  await api.post(`/skuInventory/`, params, saving)
  closeReceiveMultipleModal()
  emit('getData')
}

const show = () => {
  if (props.deliveryLocationId !== '') {
    local.value.locationId = props.deliveryLocationId
  }
  receiveMultipleModal.value.show()
}

const hide = () => {
  emit('close')
  local.value = { locationId: '' }
  receiveMultipleModal.value.hide()
}

const closeReceiveMultipleModal = () => {
  local.value = { locationId: '' }
  receiveMultipleModal.value.hide()
}
defineExpose({ show, hide })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
table {
  height: 600px;
  display: block;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}
</style>
