import { PDFDocument, StandardFonts, breakTextIntoLines } from 'pdf-lib'
import dayjs from 'dayjs'
export class CustomsPdf {
  constructor() {
    this.document = null
    this.options = {}
    this.consignments = []
    this.items = []
    this.locations = []
    this.senderAddress = {}
    this.recipientAddress = {}
    this.dispatch = {}
    this.tenantData = {}
    this.headerCol1 = 50
    this.headerCol2 = 350
    this.y = 0
    this.descColStart = 50
    this.hsCodeColStart = 200
    this.countryColStart = 250
    this.unitValueColStart = 325
    this.qtyColStart = 375
    this.weightColStart = 405
    this.totalColStart = 450
    this.nameStart = 50
    this.signatureStart = 250
    this.dateStart = 450
    this.inputLength = 100
    this.headerFontSize = 10
    this.lineHeight = 20
    this.fontSize = 10
    this.pageBuffer = 50
    this.tableTextBuffer = 5
    this.totalWeight = 0.0
    this.totalValue = 0.0
    this.page
    this.font
    this.boldFont
  }

  async create(tenantData, dispatchData = {}, locations = []) {
    if (!tenantData) {
      throw 'Missing company data'
    }

    this.dispatch = dispatchData
    this.items = dispatchData.dispatchItems
    this.locations = locations
    this.order = dispatchData.order
    this.consignments = dispatchData.consignments
    this.tenantData = tenantData
    this.senderAddress = this.getSenderAddress()
    this.recipientAddress = this.getRecipientAddress()

    this.document = await PDFDocument.create()

    await this.embedFonts()
    this.createNewPage()
    this.drawHeader()
    this.drawItems()
    this.drawSummaryLine()
    this.drawSignatureLine()
    await this.openPdf()
  }

  async embedFonts() {
    this.boldFont = await this.document.embedFont(StandardFonts.HelveticaBold)
    this.font = await this.document.embedFont(StandardFonts.Helvetica)
  }

  drawHeader() {
    const pageWidth = this.page.getWidth()
    const shipDate = dayjs(this.order.shipDate).format('DD/MM/YYYY')
    const trackingNumbers = this.getTrackingNumbers()
    const vatStatus = this.getVatStatus()

    let lines = 1
    lines = this.drawText(
      `Tracking #: ${trackingNumbers}`,
      this.headerCol1,
      this.headerCol2 - this.headerCol1,
      lines,
      this.font
    )

    lines = this.drawText(
      `Ship date: ${shipDate}`,
      this.headerCol2,
      pageWidth - this.pageBuffer - this.headerCol2,
      lines,
      this.font
    )

    this.y -= this.lineHeight * lines
    lines = 1

    lines = this.drawText(
      'Sender',
      this.headerCol1,
      this.headerCol2 - this.headerCol1,
      lines,
      this.boldFont
    )

    lines = this.drawText(
      'Recipient',
      this.headerCol2,
      pageWidth - this.pageBuffer - this.headerCol2,
      lines,
      this.boldFont
    )

    this.y -= this.lineHeight * lines
    lines = 1

    lines = this.drawText(
      this.senderAddress,
      this.headerCol1,
      this.headerCol2 - this.headerCol1,
      lines,
      this.font
    )

    lines = this.drawText(
      this.recipientAddress,
      this.headerCol2,
      pageWidth - this.pageBuffer - this.headerCol2,
      lines,
      this.font
    )

    this.y -= this.lineHeight * lines
    lines = 1

    lines = this.drawText(
      'VAT status',
      this.headerCol1,
      this.headerCol2 - this.headerCol1,
      lines,
      this.boldFont
    )

    lines = this.drawText(
      'Purpose of export',
      this.headerCol2,
      pageWidth - this.pageBuffer - this.headerCol2,
      lines,
      this.boldFont
    )

    this.y -= this.lineHeight * lines
    lines = 1

    lines = this.drawText(
      vatStatus,
      this.headerCol1,
      this.headerCol2 - this.headerCol1,
      lines,
      this.font
    )

    lines = this.drawText(
      'Sale',
      this.headerCol2,
      pageWidth - this.pageBuffer - this.headerCol2,
      lines,
      this.font
    )

    this.y -= this.lineHeight * lines
  }

  getVatStatus() {
    if (!this.tenantData.vatRegistered) {
      return 'NOT REGISTERED'
    }

    return `VAT Number: ${this.tenantData.vatNumber}`
  }

  getTrackingNumbers() {
    let trackingNumbers = ''
    let index = 0
    let length = this.consignments.length

    for (index; index < length; index++) {
      let consignment = this.consignments[index]
      let trackingNumber = consignment.trackingNumber
      trackingNumbers += trackingNumber
    }

    return trackingNumbers
  }

  getSenderAddress() {
    let location = this.locations.find((location) => {
      return location.tenantLocationId == this.order.dispatchLocationId
    })

    if (!location) {
      return ''
    }

    return `${location.companyName}\n${location.address1}\n${location.address2}\n${location.address3}\n${location.addressPostcode}\n${location.country}
    `
  }

  getRecipientAddress() {
    return `${this.order.customerName || ''}\n${
      this.order.shippingAddrLine1 || ''
    }\n${this.order.shippingAddrLine2 || ''}\n${
      this.order.shippingAddrLine3 || ''
    }\n${this.order.shippingAddrPostcode || ''}\n${
      this.order.shippingAddrCountry || ''
    }
    `
  }

  drawItems() {
    this.drawTableHeader()

    let index = 0
    let length = this.items.length
    for (index; index < length; index++) {
      this.y -= this.lineHeight
      let item = this.items[index]
      this.drawTableRow(item)
    }
  }

  drawSummaryLine() {
    const pageWidth = this.page.getWidth()
    this.y -= this.lineHeight

    let lines = 1
    lines = this.drawText(
      'Total',
      this.descValueColStart + this.tableTextBuffer,
      this.weightColStart - this.descColStart - this.tableTextBuffer,
      lines,
      this.boldFont,
      this.fontSize,
      this.weightColStart - this.tableTextBuffer
    )

    lines = this.drawText(
      this.totalWeight.toFixed(1),
      this.weightColStart + this.tableTextBuffer,
      this.totalColStart - this.weightColStart - this.tableTextBuffer,
      lines,
      this.font,
      this.fontSize,
      this.totalColStart - this.tableTextBuffer
    )

    lines = this.drawText(
      this.totalValue.toFixed(2),
      this.totalColStart + this.tableTextBuffer,
      pageWidth - this.pageBuffer - this.totalColStart - this.tableTextBuffer,
      lines,
      this.font,
      this.fontSize,
      pageWidth - this.pageBuffer - this.tableTextBuffer
    )

    this.y += this.lineHeight
    this.drawVerticalSeparationLine(
      this.descColStart - this.tableTextBuffer,
      lines + 1
    )
    this.drawVerticalSeparationLine(this.weightColStart, lines + 1)
    this.drawVerticalSeparationLine(this.totalColStart, lines + 1)
    this.drawVerticalSeparationLine(pageWidth - this.pageBuffer, lines + 1)

    this.y -= this.lineHeight
    this.y -= lines * this.lineHeight
    this.drawHorizontalSeparationLine(pageWidth)
  }

  drawSignatureLine() {
    const pageWidth = this.page.getWidth()
    this.y -= this.lineHeight * 2
    let lines = 2
    lines = this.drawText(
      'I declare that the information provided in this invoice is true, accurate and correct to the best of my knowledge',
      this.headerCol1,
      pageWidth - this.pageBuffer,
      lines
    )

    this.y -= this.lineHeight * lines
    lines = 1

    lines = this.drawText(
      'Name:',
      this.nameStart,
      this.signatureStart - this.nameStart - this.tableTextBuffer,
      lines
    )

    let length = this.measureWidth('Name:')

    this.drawHorizontalLine(
      this.nameStart + length + this.tableTextBuffer,
      this.signatureStart - this.tableTextBuffer
    )

    lines = this.drawText(
      'Signature:',
      this.signatureStart,
      this.dateStart - this.signatureStart - this.tableTextBuffer,
      lines
    )

    length = this.measureWidth('Signature:')

    this.drawHorizontalLine(
      this.signatureStart + length + this.tableTextBuffer,
      this.dateStart - this.tableTextBuffer
    )

    lines = this.drawText(
      'Date:',
      this.dateStart,
      pageWidth - this.pageBuffer - this.dateStart - this.tableTextBuffer,
      lines
    )

    length = this.measureWidth('Date:')

    this.drawHorizontalLine(
      this.dateStart + length + this.tableTextBuffer,
      pageWidth - this.pageBuffer
    )

    this.y -= lines * this.lineHeight
  }

  drawTableHeader() {
    const pageWidth = this.page.getWidth()
    this.drawHorizontalSeparationLine(pageWidth)
    this.y -= this.lineHeight
    let lines = 1

    lines = this.drawText(
      'Description of goods',
      this.descColStart + this.tableTextBuffer,
      this.hsCodeColStart - this.descColStart - this.tableTextBuffer,
      lines,
      this.boldFont
    )

    lines = this.drawText(
      'HS code',
      this.hsCodeColStart + this.tableTextBuffer,
      this.countryColStart - this.hsCodeColStart - this.tableTextBuffer,
      lines,
      this.boldFont
    )

    lines = this.drawText(
      'Country of manufacture',
      this.countryColStart + this.tableTextBuffer,
      this.unitValueColStart - this.countryColStart - this.tableTextBuffer,
      lines,
      this.boldFont
    )

    lines = this.drawText(
      'Unit value',
      this.unitValueColStart + this.tableTextBuffer,
      this.qtyColStart - this.unitValueColStart - this.tableTextBuffer,
      lines,
      this.boldFont
    )

    lines = this.drawText(
      'Qty',
      this.qtyColStart + this.tableTextBuffer,
      this.weightColStart - this.qtyColStart - this.tableTextBuffer,
      lines,
      this.boldFont
    )

    lines = this.drawText(
      'Total weight (KG)',
      this.weightColStart + this.tableTextBuffer,
      this.totalColStart - this.weightColStart - this.tableTextBuffer,
      lines,
      this.boldFont
    )

    lines = this.drawText(
      'Total value',
      this.totalColStart + this.tableTextBuffer,
      pageWidth - this.pageBuffer - this.totalColStart - this.tableTextBuffer,
      lines,
      this.boldFont
    )

    this.y += this.lineHeight
    this.drawAllSeparators(lines)
  }

  drawAllSeparators(lines) {
    const pageWidth = this.page.getWidth()
    this.drawVerticalSeparationLine(
      this.descColStart - this.tableTextBuffer,
      lines + 1
    )
    this.drawVerticalSeparationLine(this.hsCodeColStart, lines + 1)
    this.drawVerticalSeparationLine(this.countryColStart, lines + 1)
    this.drawVerticalSeparationLine(this.unitValueColStart, lines + 1)
    this.drawVerticalSeparationLine(this.qtyColStart, lines + 1)
    this.drawVerticalSeparationLine(this.weightColStart, lines + 1)
    this.drawVerticalSeparationLine(this.totalColStart, lines + 1)
    this.drawVerticalSeparationLine(pageWidth - this.pageBuffer, lines + 1)

    this.y -= this.lineHeight
    this.y -= lines * this.lineHeight
    this.drawHorizontalSeparationLine(pageWidth)
  }

  drawTableRow(item) {
    const pageWidth = this.page.getWidth()
    let lines = 1
    let lineTotal = parseInt(item.quantity) * parseFloat(item.price)
    if (item.weight) {
      this.totalWeight += parseFloat(item.weight)
    }

    this.price += lineTotal

    lines = this.drawText(
      item.title,
      this.descColStart + this.tableTextBuffer,
      this.hsCodeColStart - this.descColStart - this.tableTextBuffer,
      lines,
      this.font
    )

    lines = this.drawText(
      '',
      this.hsCodeColStart + this.tableTextBuffer,
      this.countryColStart - this.hsCodeColStart - this.tableTextBuffer,
      lines,
      this.font
    )

    lines = this.drawText(
      '',
      this.countryColStart + this.tableTextBuffer,
      this.unitValueColStart - this.countryColStart - this.tableTextBuffer,
      lines,
      this.font
    )

    lines = this.drawText(
      item.price,
      this.unitValueColStart + this.tableTextBuffer,
      this.qtyColStart - this.unitValueColStart - this.tableTextBuffer,
      lines,
      this.font,
      this.fontSize,
      this.qtyColStart - this.tableTextBuffer
    )

    lines = this.drawText(
      item.quantity.toString(),
      this.qtyColStart + this.tableTextBuffer,
      this.weightColStart - this.qtyColStart - this.tableTextBuffer,
      lines,
      this.font,
      this.fontSize,
      this.weightColStart - this.tableTextBuffer
    )

    lines = this.drawText(
      '0.0',
      this.weightColStart + this.tableTextBuffer,
      this.totalColStart - this.weightColStart - this.tableTextBuffer,
      lines,
      this.font,
      this.fontSize,
      this.totalColStart - this.tableTextBuffer
    )

    lines = this.drawText(
      lineTotal.toString(),
      this.totalColStart + this.tableTextBuffer,
      pageWidth - this.pageBuffer - this.totalColStart - this.tableTextBuffer,
      lines,
      this.font,
      this.fontSize,
      pageWidth - this.pageBuffer - this.tableTextBuffer
    )

    this.y += this.lineHeight

    this.drawAllSeparators(lines)

    if (this.y < 50) {
      this.createNewPage()
      this.drawTableHeader()
    }
  }

  createNewPage() {
    this.page = this.document.addPage()
    this.y = this.page.getHeight() - this.lineHeight
  }

  drawVerticalSeparationLine(x, lines) {
    let height = this.lineHeight * lines
    let endY = this.y - height

    this.page.drawLine({
      start: { x: x, y: this.y },
      end: { x: x, y: endY },
      thickness: 0.2,
      opacity: 0.75
    })
  }

  drawHorizontalSeparationLine(pageWidth) {
    this.page.drawLine({
      start: { x: this.descColStart - 5, y: this.y },
      end: { x: pageWidth - this.pageBuffer, y: this.y },
      thickness: 0.2,
      opacity: 0.75
    })
  }

  drawHorizontalLine(start, end) {
    this.page.drawLine({
      start: { x: start, y: this.y },
      end: { x: end, y: this.y },
      thickness: 0.2,
      opacity: 0.75
    })
  }

  /**
   * Will check if text is going to go over multiple lines
   * then print and set y accordingly
   * @param {Object} page
   * @param {String} text
   * @param {Number} x
   * @param {Number} numberOfLines
   */
  drawText(
    text,
    x,
    maxWidth,
    numberOfLines,
    font = this.font,
    fontSize = this.fontSize,
    rightLimit = 0
  ) {
    const lines = breakTextIntoLines(
      text,
      this.document.defaultWordBreaks,
      maxWidth,
      (s) => this.measureWidth(s)
    )

    let index = 0
    let length = lines.length
    let y = this.y

    for (index; index < length; index++) {
      let line = lines[index]
      if (rightLimit) {
        x = rightLimit - this.measureWidth(line)
      }
      this.page.drawText(line, {
        x: x,
        y: y,
        size: fontSize,
        font: font,
        maxWidth: maxWidth,
        lineHeight: this.lineHeight
      })

      y -= this.lineHeight
    }

    return length > numberOfLines ? length : numberOfLines
  }

  measureWidth(s, fontSize = this.fontSize) {
    return this.font.widthOfTextAtSize(s, fontSize)
  }

  async openPdf() {
    let fileBytesArray = await this.document.save()
    let blob = new Blob([fileBytesArray], { type: 'application/pdf' })
    var url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }
}
