<template>
  <TableGrid
    ref="addressGrid"
    :cols="cols"
    :rows="addresses"
    :total-count="addresses.length"
    :trclick="openAddressModal"
    id-key="tenantCustomerAddressId"
    scrollable
  ></TableGrid>

  <div v-if="editable && !pageLoading" class="row">
    <div class="col-12 d-flex justify-content-center tbl-cell-btn">
      <button type="button" class="btn" @click.prevent="openAddressModal()">
        <font-awesome-icon :icon="['fas', 'circle-plus']"></font-awesome-icon
        ><b>Add Address</b>
      </button>
    </div>
  </div>

  <BraidModal
    ref="addressModal"
    @confirm="saveAddress"
    @close="hideAddressModal"
  >
    <template #header>
      <span v-if="local.tenantCustomerAddressId">Edit address</span>
      <span v-else>Add address</span>
    </template>
    <template #body>
      <TableForm>
        <!-- NAME -->
        <TableRow
          v-model="local.name"
          type="text"
          label="Name"
          :rules="{ length: { max: 200 } }"
        ></TableRow>
        <!-- ./NAME -->

        <!-- BUILDING -->
        <TableRow
          v-model="local.buildingType"
          type="select"
          label="Building Type"
          :options="[
            { value: 'BUSINESS', label: 'Business' },
            { value: 'RESIDENTAL', label: 'Residental' }
          ]"
        ></TableRow>
        <!-- ./BUILDING -->

        <!-- ADDRESS LINE 1 -->
        <TableRow
          v-model="local.address1"
          type="text"
          label="Address Line 1"
          :rules="{ length: { max: 200 } }"
        ></TableRow>
        <!-- ./ADDRESS LINE 1 -->

        <!-- ADDRESS LINE 2 -->
        <TableRow
          v-model="local.address2"
          type="text"
          label="Address Line 2"
          :rules="{ length: { max: 200 } }"
        ></TableRow>
        <!-- ./ADDRESS LINE 2 -->

        <!-- ADDRESS LINE 3 -->
        <TableRow
          v-model="local.address3"
          type="text"
          label="Address Line 3"
          :rules="{ length: { max: 200 } }"
        ></TableRow>
        <!-- ./ADDRESS LINE 3 -->

        <!-- POSTCODE -->
        <TableRow
          v-model="local.addressPostcode"
          type="text"
          label="Postcode"
          :rules="{ length: { max: 10 } }"
        />
        <!-- ./POSTCODE -->

        <TableRow
          v-model="local.country"
          label="Main Country"
          :options="countries"
          type="select"
        >
        </TableRow>
      </TableForm>
    </template>
  </BraidModal>
</template>

<script>
export default {
  name: 'BraidCustomerAddress'
}
</script>

<script setup>
import { onMounted, ref, nextTick, computed } from 'vue'

const _ = require('lodash')

import useApi from '@/components/useApi'
const api = useApi()

import useCountries from '@/composables/selectData/useCountries.js'
const { getCountriesForSelect } = useCountries()
const countries = getCountriesForSelect()
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()
const addressGrid = ref(null)
const pageLoading = computed(() => {
  return getLoading()
})

const emit = defineEmits('update')

const props = defineProps({
  id: {
    type: String,
    required: true
  },
  addressData: {
    type: Array,
    default() {
      return []
    }
  },
  editable: {
    type: Boolean,
    default: false
  }
})

/*
 *****************      GRID / FORM
 */
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

// Object that contains all the data
const local = ref({})

// Rows to display in the grid
const rows = ref([])

const addresses = ref(props.addressData)

// Default Column Settings
const cols = ref([
  {
    label: 'Address name',
    name: 'name',
    visible: true
  },
  {
    label: 'Address Line 1',
    name: 'address1',
    visible: true
  },
  {
    label: 'Postcode',
    name: 'addressPostcode',
    visible: true
  },
  {
    label: 'Country',
    name: 'country',
    visible: true
  }
])

/*
 *****************      MODALS
 */
const addressModal = ref(null)

const hideAddressModal = () => {
  local.value = {}
  addressModal.value.hide()
}

const openAddressModal = (id = null) => {
  if (id) {
    local.value = Object.assign(
      {},
      _.find(addresses.value, { tenantCustomerAddressId: id })
    )
  } else {
    local.value.tenantCustomerId = props.id
  }
  addressModal.value.show()
}

const saveAddress = async () => {
  if (!local.value.tenantCustomerAddressId) {
    insert()
    return
  }

  let id = local.value.tenantCustomerAddressId
  let response = await api.put('/customerAddresses/' + id, {
    customerAddresses: [local.value]
  })

  addresses.value = response.data.customerAddresses
  emit('update', addresses.value)
  hideAddressModal()
}

const insert = async () => {
  let response = await api.post('/customerAddresses/', {
    customerAddress: local.value
  })
  addresses.value = response.data.customerAddresses
  hideAddressModal()
}

onMounted(() => {
  addressGrid.value.updateRows()
})
</script>

<style lang="scss">
@import './scss/modals';
</style>
