<template>
  <ActionBar
    :buttons="buttons"
    :conditions="{
      0: { disable: disablePrintButtons },
      1: { disable: disablePrintButtons },
      2: { disable: disablePrintButtons }
    }"
  />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Order"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    v-show="displayGrid"
    ref="grid"
    :cols="columns"
    :rows="rows"
    :default-sort="defaultSort"
    :filter-function="getRows"
    :total-count="totalCount"
    id-key="tenantLabelId"
    caption="List of labels"
    :reset-filters="resetFilters"
    select-id="tenantLabelId"
    scrollable
    checkbox
    select-all
  ></TableGrid>
</template>

// This is for the keep-alive to work
<script>
export default {
  name: 'LabelsList'
}
</script>

<script setup>
import {
  ref,
  onActivated,
  watch,
  inject,
  nextTick,
  onDeactivated,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
import { CreateMultipleLabelPdf } from '@/composables/pdfs/multipleLabelPdf'
import { CreateIntegratedLabel } from '@/composables/pdfs/integratedLabelPdf'
import { pageLoad } from '@/composables/pageLoad'

const router = useRouter()
const api = useApi()
const deactivated = ref(false)

const { getTableFilters, toggleArchived } = useColumnFilters(router)
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const props = defineProps({
  pageId: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  },
  unprinted: {
    type: Boolean,
    default: false
  }
})

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/*
 *****************      GRID
 */

const createMultipleLabelPdf = new CreateMultipleLabelPdf()
const createIntegratedLabelPdf = new CreateIntegratedLabel()

const grid = ref(null)

const displayGrid = ref(false)
const selected = ref([])
const rows = ref([])
const modalRows = ref([])
const modalCols = ref([])
const defaultSort = ref({ createdAt: -1 })
const totalCount = ref(0)

const disablePrintButtons = computed(() => {
  if (!grid.value) {
    return true
  }

  return grid.value.selected.length === 0
})

const getRows = async (args = {}) => {
  loading()
  const url = props.unprinted ? '/labels/unprinted' : '/labels'
  args.sort = props.unprinted ? { sku: 1 } : args.sort
  try {
    progressBarStart()
    let response = await api.get(url, args)
    rows.value = response.data.labels
    totalCount.value = parseInt(response.data.count)
    displayGrid.value = true
    loaded()
    updateFilters(false)
    progressBarDone()
  } catch (e) {
    console.log(e)
    loaded()
    progressBarFail()
  }
}

const updateTableFilters = ({ filter, sort, skip }) => {
  if (!grid.value) {
    return
  }
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
  grid.value.setPage(skip)
}

const getLabelUrls = async () => {
  if (!grid.value) {
    return
  }

  if (!grid.value.selected || grid.value.selected.length === 0) {
    return
  }

  await getAndSaveLabels()

  let response = await api.get('/labels/display-labels', {
    tenantLabelIds: grid.value.selected
  })

  return response.data.labels
}

const getAndSaveLabels = async (row) => {
  let royalMailLabelIds = []
  let parcel2GoLabelIds = []
  let imageUrls = {}

  let index = 0
  let length = grid.value.selected.length
  for (index; index < length; index++) {
    let tenantLabelId = grid.value.selected[index]
    let row = rows.value.find((row) => {
      return row.tenantLabelId == tenantLabelId
    })

    if (!row) {
      continue
    }

    if (row.label) {
      imageUrls[row.carrierLabelId] = row.label
      continue
    }

    switch (row.carrier) {
      case 'ROYAL_MAIL':
        royalMailLabelIds.push({
          carrierLabelId: row.carrierLabelId,
          tenantChannelId: row.tenantChannelId
        })
        break

      case 'PARCEL2GO':
        parcel2GoLabelIds.push({
          carrierLabelId: row.carrierLabelId,
          tenantChannelId: row.tenantChannelId
        })
        break
    }
  }

  if (royalMailLabelIds.length === 0 && parcel2GoLabelIds.length === 0) {
    return imageUrls
  }

  let royalMailImageUrls = await getLabels(royalMailLabelIds, 'ROYAL_MAIL')
  let parcel2GoImageUrls = await getLabels(parcel2GoLabelIds, 'PARCEL2GO')

  imageUrls = { ...imageUrls, ...royalMailImageUrls, ...parcel2GoImageUrls }

  let labels = []

  for (let carrierLabelId in imageUrls) {
    let imageUrl = imageUrls[carrierLabelId]

    let row = rows.value.find((row) => {
      return row.carrierLabelId == carrierLabelId
    })

    if (!row) {
      continue
    }

    labels.push({
      label: {
        carrierLabelId: carrierLabelId,
        tenantLabelId: row.tenantLabelId,
        label: imageUrl
      }
    })
  }

  await api.put(`/labels/`, {
    labels
  })

  return imageUrls
}

const getUniqueChannelIds = (carrierLabels) => {
  let uniqueChannelIds = {}

  let index = 0
  let length = carrierLabels.length
  for (index; index < length; index++) {
    let carrierLabel = carrierLabels[index]
    let tenantChannelId = carrierLabel.tenantChannelId
    let carrierLabelId = carrierLabel.carrierLabelId

    if (!tenantChannelId) {
      continue
    }

    if (!uniqueChannelIds[tenantChannelId]) {
      uniqueChannelIds[tenantChannelId] = []
    }

    uniqueChannelIds[tenantChannelId].push(carrierLabelId)
  }

  return uniqueChannelIds
}

const getLabels = async (carrierLabels = [], carrier) => {
  if (carrierLabels.length === 0) {
    return {}
  }

  if (!carrier) {
    return {}
  }

  let service = carrier === 'PARCEL2GO' ? 'parcel2go' : 'royal-mail'
  let tenantChannelIds = getUniqueChannelIds(carrierLabels)
  let imageUrls = {}

  for (let tenantChannelId in tenantChannelIds) {
    let carrierLabelIds = tenantChannelIds[tenantChannelId]

    if (!carrierLabelIds || carrierLabelIds.length === 0) {
      continue
    }

    let response = await api.get(`/${service}/labels`, {
      carrier,
      carrierLabelIds,
      tenantChannelId
    })

    imageUrls = { ...imageUrls, ...response.data.imageUrls }
  }

  return imageUrls
}

const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)
// Template reference to the TableGrid component

const resetFilters = () => {
  appliedFilters.value = {}
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
// Default Column Settings
const cols = [
  {
    label: 'Label number',
    name: 'carrierLabelId',
    visible: true,
    filter: true
  },
  {
    label: 'Order number',
    name: 'orderNumber',
    visible: true,
    filter: true
  },
  {
    label: 'Carrier',
    name: 'carrier',
    visible: true,
    displayValues: {
      UPS: 'UPS',
      FEDEX: 'Fedex',
      DHL: 'DHL',
      ROYAL_MAIL: 'Royal mail',
      DPD: 'DPD',
      HERMES: 'Hermes'
    },
    filter: true,
    multiple: true
  },
  {
    label: 'Package type',
    name: 'packageDisplayName',
    visible: true,
    filter: true
  },
  {
    label: 'Service',
    name: 'service',
    visible: true,
    filter: true
  },

  {
    label: 'CustomerName',
    name: 'customerName',
    visible: true
  },
  {
    label: 'Items',
    name: 'items',
    visible: true
  },
  {
    label: 'Created',
    name: 'createdAt',
    dateTime: true,
    visible: true,
    filter: true
  }
]

const preferences = ref()

const show = () => {
  preferences.value.show()
}

const columns = ref()

const localStorageName = ref('braidLabels')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences } = columnPreferences(localStorageName.value)
import { getSkuDataForLabel } from '@/composables/getSkuDataForLabel'
const { getSkusForLabel } = getSkuDataForLabel()

columns.value = readColumnPreferences(cols)

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

/*
 *****************  BULK CREATE LABELS
 */

const printA4Labels = async () => {
  let urls = await getLabelUrls()
  createMultipleLabelPdf.create(urls, 'A4')
  markLabelsPrinted()
}

const printLabels = async () => {
  let urls = await getLabelUrls()
  createMultipleLabelPdf.create(urls, '4X6')
  markLabelsPrinted()
}

const printIntegratedLabels = async () => {
  let urls = await getLabelUrls()
  let skus = await getSkusForLabel(api, urls)
  createIntegratedLabelPdf.createMultiple(urls, skus)
  markLabelsPrinted()
}

const markLabelsPrinted = async () => {
  await api.put('/labels/printed', { tenantLabelIds: grid.value.selected })
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'
const buttons = [
  {
    id: 0,
    label: 'Print 6x4',
    click: printLabels,
    icon: ['fas', 'print']
  },
  {
    id: 1,
    label: 'Print integrated',
    click: printIntegratedLabels,
    icon: ['fas', 'print']
  },
  {
    id: 2,
    label: 'Print A4',
    click: printA4Labels,
    icon: ['fas', 'print']
  }
]

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }

  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    filterUpdate.value = true
  }
)

const updateFilters = (needRows = true) => {
  let tableFilters = getTableFilters(defaultSort.value)
  if (needRows) {
    getRows(tableFilters)
  }
  updateTableFilters(tableFilters)
}

watch(filterUpdate, (current, previous) => {
  if (!current) {
    return
  }
  updateFilters()
  filterUpdate.value = false
})
</script>
