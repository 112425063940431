<template>
  <span
    :ref="popover"
    data-bs-toggle="popover"
    data-bs-container="body"
    :data-bs-content="info"
    @mouseenter="show"
    @mouseleave="hide"
  >
    <FontAwesomeIcon class="info-icon" :icon="icon" size="xs"></FontAwesomeIcon>
  </span>
</template>

<script>
export default {
  name: 'HoverInfo'
}
</script>

<script setup>
import { ref, onUnmounted } from 'vue'
import { Popover } from 'bootstrap'

const props = defineProps({
  info: {
    type: String,
    default: ''
  },
  icon: {
    type: Array,
    default() {
      return ['fas', 'circle-info']
    }
  }
})

const popoverEl = ref()

const popover = (el) => {
  if (el) {
    popoverEl.value = new Popover(el)
  }
}

const show = () => {
  if (popoverEl.value) {
    popoverEl.value.show()
  }
}

const hide = () => {
  if (popoverEl.value) {
    popoverEl.value.hide()
  }
}

onUnmounted(() => {
  hide()
  popoverEl.value = ''
})
</script>

<style lang="scss">
.info-icon {
  margin-left: 0.3rem;
  color: #696969;
}
</style>
