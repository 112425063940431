<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Invoice"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    v-if="displayGrid"
    ref="grid"
    :cols="columns"
    :rows="rows"
    :total-count="totalCount"
    :filter-function="getRows"
    :default-sort="defaultSort"
    id-key="tenantInvoiceId"
    scrollable
  ></TableGrid>
</template>

<script>
export default {
  name: 'InvoicesList'
}
</script>

<script setup>
import { ref, onMounted, onActivated, inject } from 'vue'

import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'

const router = useRouter()
const api = useApi()

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const getRows = async (args) => {
  try {
    progressBarStart()
    let response = await api.get('/invoices', args)
    rows.value = response.data.invoices
    totalCount.value = parseInt(response.data.count)
    displayGrid.value = true
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

/*
 ********* GRID
 */

const grid = ref(null)

const displayGrid = ref(false)
const rows = ref([])
const totalCount = ref(0)
const defaultSort = ref({ tenantInvoiceId: -1 })

/*
 ******** COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'

const cols = [
  {
    label: 'Invoice number',
    name: 'invoiceNumber',
    filter: true,
    visible: true,
    onClick: {
      route: '/invoices/',
      id: 'tenantInvoiceId'
    },
    primary: true
  },
  {
    label: 'Status',
    name: 'status',
    filter: true,
    visible: true,
    displayValues: {
      0: 'Draft',
      1: 'Unfulfilled',
      2: 'Part-fulfilled',
      3: 'Fulfilled',
      4: 'Cancelled'
    }
  },
  { label: 'Due Date', name: 'dueDate', filter: true, visible: true },
  { label: 'Order number', name: 'channelOrderId', filter: true, visible: true }
]

const preferences = ref()

const show = () => {
  preferences.value.show
}

const columns = ref()

const localStorageName = ref('braidInvoices')

const readColumnPreferences = () => {
  let columnSettings = localStorage[localStorageName.value]
  if (!columnSettings) {
    localStorage[localStorageName.value] = JSON.stringify(cols)
    columnSettings = localStorage[localStorageName.value]
  }
  columns.value = JSON.parse(columnSettings)
}

readColumnPreferences()

const updateColumns = (cols) => {
  columns.value = cols
}
/*
 ***** ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const newInvoice = () => {
  router.push('/invoices/new')
}

const buttons = [
  {
    id: 0,
    label: 'Add new',
    click: newInvoice,
    icon: ['fas', 'circle-plus']
  },
  {
    id: 1,
    label: 'Column Settings',
    click: show,
    icon: ['fas', 'gear']
  }
]

onActivated(() => {
  if (!grid.value) {
    return
  }

  grid.value.filterColumns(grid.value.args)
})

onMounted(async () => {
  await getRows({ sort: defaultSort.value })
})
</script>

<style scoped></style>
