import { store } from '@/store/store'

export function pageLoad() {
  const loading = () => {
    return store.dispatch('setPageLoading', true)
  }

  const loaded = () => {
    return store.dispatch('setPageLoading', false)
  }

  const getLoading = () => {
    return store.getters.getLoading
  }

  return { loading, loaded, getLoading }
}
