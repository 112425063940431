<template>
  <input
    ref="input"
    class="table-number-input"
    type="number"
    :value="modelValue"
    :readonly="readOnly"
    :placeholder="placeholder"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
import useTableInput from '@/components/table/useTableInput'
const config = useTableInput()
</script>

<script setup>
import { ref, watch } from 'vue'
const props = defineProps({ ...config._props })
const emit = defineEmits([''].concat(config._emits))

const input = ref(null)

/**
 * We have to watch the value intead of relying on the input event.
 * This is due to the fact that when a new value is pasted via the
 * context menu; the input event isn't triggered (neither is paste event)
 */
watch(
  () => props.modelValue,
  (newValue) => {
    emit('update:modelValue', newValue)
  }
)

const focus = () => {
  if (!props.readOnly) {
    input.value.focus()
  }
}

defineExpose({ focus })
</script>

<style lang="scss">
.table-number-input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding-left: 0rem;
  // background-color: transparent !important;
  padding: 0 0.5rem;
  border-radius: 3px;
}
</style>
