<template>
  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="StockLevelWarning"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    v-if="displayGrid"
    :cols="columns"
    :rows="rows"
    :total-count="rows.length"
    :filter-function="getRows"
    id-key="tenantSkuId"
    scrollable
  ></TableGrid>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onActivated, onMounted, ref } from 'vue'
import useApi from '@/components/useApi'
const { formatDate, formatDateTime, UTC_OFFSET, dayjs } = require('@/dayjs')

import ColumnPreferences from '@/components/table/grid/ColumnPreferences'

import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

const router = useRouter()
const api = useApi()

const displayGrid = ref(false)
const grid = ref(null)
const rows = ref([])
const totalCount = ref(0)

const preferences = ref()
const show = () => {
  preferences.value.show()
}

onActivated(() => {
  if (!grid.value) {
    return
  }

  grid.value.filter(grid.value.args)
})

onMounted(async () => {
  await getRows()
})

const getRows = async (args = {}) => {
  let response = await api.get('/skuInventory/', { warningStock: 1 })

  if (!response) {
    return
  }

  rows.value = response.data.skus
  totalCount.value = parseInt(response.data.count)
  displayGrid.value = true
}

const buttons = []

const cols = [
  {
    label: 'SKU',
    name: 'sku',
    width: 30,
    visible: true,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true
  },
  {
    label: 'Status',
    name: 'status',
    visible: true,
    displayValues: {
      0: 'Inactive',
      1: 'Active'
    }
  },
  {
    label: 'Brand',
    name: 'brandName',
    visible: true
  },
  {
    label: 'Title',
    name: 'title',
    visible: true
  },
  {
    label: 'Variants',
    name: 'variant',
    visible: true
  },
  {
    label: 'On hand',
    name: 'onHand',
    visible: true
  }
]

const columns = ref()
const localStorageName = ref('braidStockLevelWarning')

const updateColumns = (cols) => {
  columns.value = cols
}

let columnSettings = localStorage[localStorageName.value]
if (!columnSettings) {
  localStorage[localStorageName.value] = JSON.stringify(cols)
  columnSettings = localStorage[localStorageName.value]
}
columns.value = JSON.parse(columnSettings)
</script>
