<template>
  <TableGrid
    v-if="displayGrid"
    ref="grid"
    :cols="cols"
    :rows="rows"
    :total-count="rows.length"
    scrollable
  ></TableGrid>
</template>

<script>
export default {
  name: 'ReportsList'
}
</script>

<script setup>
import { ref, onMounted, onActivated, watch, inject } from 'vue'

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const props = defineProps({
  filter: {
    type: String,
    default: ''
  }
})

/*
 *****************      GRID
 */

// Template reference to the TableGrid component
const grid = ref(null)

const displayGrid = ref(false)
const totalCount = ref(0)

const cols = ref([
  {
    label: 'Report',
    name: 'reportName',
    visible: true,
    onClick: {
      route: '/reports/',
      id: 'route'
    },
    primary: true
  },
  {
    label: '',
    name: '',
    visible: true,
    onClick: {
      route: '',
      id: ''
    }
  }
])

const rows = ref([
  {
    reportName: 'Order Item Report',
    route: 'order-item-report'
  },
  {
    reportName: 'Restock Report',
    route: 'restock-report'
  },
  {
    reportName: 'Inventory Alert Level Report',
    route: 'inventory-alert-level-report'
  },
  {
    reportName: 'Ezi Return Report',
    route: 'ezi-return-report'
  }
])

onMounted(() => {
  displayGrid.value = true
})
</script>

<style></style>
