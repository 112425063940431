<template>
  <div class="brands">
    <div class="row mb-3">
      <div class="col">
        <div class="float-right">
          <button type="button" class="btn btn-primary" @click="newBrand()">
            <i class="fa fa-plus"></i>&nbsp;&nbsp;&nbsp;New brand
          </button>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <TableGrid
          v-if="displayGrid"
          :cols="cols"
          :rows="rows"
          :total-count="totalCount"
          :filter-function="getRows"
          scrollable
        ></TableGrid>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'

const displayGrid = ref(false)
const rows = ref([])
const totalCount = ref(0)

const cols = ref([
  {
    label: 'ID',
    name: '_id',
    width: 70
  },
  {
    label: 'Brand',
    name: 'brand'
  },
  {
    label: 'Last updated',
    name: 'lastUpdated'
  }
])

const getRows = async () => {}

const gotoBrand = (id) => {
  router.push('/brands/' + id)
}

const newBrand = () => {
  router.push('/brands/new')
}

onMounted(async () => {
  await getRows()
})
</script>
