<template>
  <ValidationProvider ref="validation" v-slot="{ errors, changes }">
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: {
          show: local.archivedAt == null,
          disable: !changes || saving || errors
        },
        2: {
          show: local.archivedAt != null && selectedTab === 'Customer'
        },
        3: {
          show: local.archivedAt == null && !changes && !saving
        }
      }"
    ></ActionBar>

    <TableTabs ref="tabManager" v-slot="{ activeTab }" :tabs="tabs">
      <template v-if="activeTab === 'Customer'">
        <TableContainer>
          <TablePanel :cols="3" :width="4">
            <template #1>
              <TableForm>
                <!-- NAME -->
                <TableRow
                  v-model="local.name"
                  label="Name"
                  type="text"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./NAME -->

                <!-- WEBSITE -->
                <TableRow
                  v-model="local.website"
                  label="Website"
                  type="text"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./WEBSITE -->

                <!-- FOLLOW UP DATE -->
                <TableRow
                  v-model="local.followUpDate"
                  label="Follow up date"
                  type="date"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./FOLLOW UP DATE -->

                <!-- SHIPPING ADDRESS -->
                <TableRow
                  v-model="local.defaultShippingAddressId"
                  label="Shipping Address"
                  :options="addressOptions"
                  type="select"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./SHIPPING ADDRESS -->

                <!-- BILLING ADDRESS -->
                <TableRow
                  v-model="local.defaultBillingAddressId"
                  label="Billing Address"
                  :options="addressOptions"
                  type="select"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./BILLING ADDRESS -->
              </TableForm>
            </template>
            <template #2>
              <TableForm>
                <TableRow
                  v-model="local.defaultPricingProfileId"
                  label="Pricing Profile"
                  type="select"
                  :options="priceProfiles"
                ></TableRow>
                <TableRow
                  v-model="local.category"
                  label="Category"
                  type="textarea"
                  :read-only="!editable"
                ></TableRow>
                <TableRow
                  v-model="local.tags"
                  label="Tags"
                  type="text"
                  :read-only="!editable"
                ></TableRow>
              </TableForm>
            </template>
            <template #3>
              <TableForm :style="'height: 100%'">
                <!-- COMMENTS -->
                <TableRow
                  v-model="local.comments"
                  label="Comments"
                  type="textarea"
                  :style="'height: 50%;'"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./COMMENTS -->

                <!-- INTERNAL NOTES -->
                <TableRow
                  v-model="local.internalNotes"
                  label="Internal Notes"
                  type="textarea"
                  :style="'height: 50%;'"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./INTERNAL NOTES -->
              </TableForm>
            </template>
          </TablePanel>
          <TablePanel v-if="customerId">
            <template #1>
              <TableForm>
                <RowContainer
                  v-for="(contact, index) in local.contacts"
                  :key="'contact' + index"
                  :heading="contact.name || 'New Contact'"
                  :indent-level="1"
                  :colspan="2"
                  :auto-collapse="Object.keys(contact).length != 0"
                >
                  <TableRow
                    v-model="contact.name"
                    label="Name"
                    type="text"
                    :indent-level="2"
                    :read-only="!editable"
                  >
                  </TableRow>
                  <TableRow
                    v-model="contact.email"
                    label="Email"
                    type="text"
                    :indent-level="2"
                    :read-only="!editable"
                  ></TableRow>
                  <TableRow
                    v-model="contact.phone"
                    label="Phone"
                    type="text"
                    :indent-level="2"
                    :read-only="!editable"
                  ></TableRow>
                  <TableRow
                    v-model="contact.notes"
                    label="Notes"
                    type="text"
                    :indent-level="2"
                    :read-only="!editable"
                  ></TableRow>
                </RowContainer>
              </TableForm>
              <div v-if="editable && !pageLoading" class="row">
                <div class="col-12 d-flex justify-content-center tbl-cell-btn">
                  <button type="button" class="btn" @click="addContactRow()">
                    <font-awesome-icon
                      :icon="['fas', 'circle-plus']"
                    ></font-awesome-icon>
                    <b>Add contact</b>
                  </button>
                </div>
              </div>
            </template>
          </TablePanel>
          <TablePanel v-if="customerId">
            <template #1>
              <CustomerAddress
                :id="customerId"
                :address-data="addresses"
                :editable="editable"
                @update="updateAddresses"
              />
            </template>
          </TablePanel>
        </TableContainer>
      </template>

      <!-- FILES -->
      <template v-if="activeTab === 'Files'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableForm>
                <TableFileUpload
                  :id="customerId"
                  :files="local.files"
                  table="tenantCustomer"
                  :update-files-list="updateFilesList"
                  :editable="editable"
                ></TableFileUpload>
              </TableForm>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
      <!-- ./FILES -->

      <!-- LOGS -->
      <template v-if="activeTab === 'Logs'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableForm>
                <CrmLog
                  ref="crmLogComponent"
                  :crm-log="crmLog"
                  :editable="editable"
                />
              </TableForm>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
      <!-- ./LOGS -->

      <!-- HISTORY -->
      <template v-if="activeTab === 'History'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableGrid
                :cols="auditHistoryCols"
                :rows="historyCustomerRows"
                :total-count="totalHistoryCustomerCount"
                :filter-function="(args) => setHistoryRows(args.skip)"
                caption="Customer History"
                scrollable
              ></TableGrid>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
      <!-- ./HISTORY -->
    </TableTabs>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    ></ConfirmLeaveFormModal>
  </ValidationProvider>
</template>

<script setup>
import {
  ref,
  computed,
  onUpdated,
  watch,
  watchEffect,
  nextTick,
  inject
} from 'vue'

import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})
const router = useRouter()
const api = useApi()

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: [String, Number],
    default: ''
  }
})

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const {
  formatDate,
  formatDateForSave,
  formatDateTime,
  formatDateInput,
  formatDateTimeForSave,
  UTC_OFFSET,
  dayjs
} = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

// Object that contains all the data
const local = ref({ contacts: [] })

const customerId = ref(false)

const editable = computed(() => {
  return local.value.approvedAt == null && local.value.archivedAt == null
})

/**
 * Formats data returned from api
 */
const handleResponse = (data) => {
  let customer = data.customers[0]
  let contacts = data.contacts || []
  let logs = data.logs || []
  let addressesData = data.addresses || []
  let files = data.files || []

  crmLog.value = logs
  addresses.value = addressesData

  if (contacts.length == 0) {
    contacts = []
  }

  local.value = {
    tenantCustomerId: customer.tenantCustomerId,
    name: customer.name,
    followUpDate: formatDateInput(customer.followUpDate),
    website: customer.website,
    comments: customer.comments,
    internalNotes: customer.internalNotes,
    defaultShippingAddressId: customer.defaultShippingAddressId,
    defaultBillingAddressId: customer.defaultBillingAddressId,
    contacts: contacts,
    files: files,
    createdAt: formatDateTime(customer.createdAt, utcOffset.value),
    createdBy: customer.createdBy,
    updatedAt: formatDateTime(customer.updatedAt, utcOffset.value),
    updatedBy: customer.updatedBy,
    archivedAt: formatDateTime(customer.archivedAt, utcOffset.value),
    archivedBy: customer.archivedBy
  }

  remote.value = JSON.parse(JSON.stringify(local.value))
}

/*
 *****************      VALIDATION PROVIDER
 */

// Template ref to validation provider component
const validation = ref(null)

/*
 *****************      MODALS
 */
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'

const confirmLeaveModal = ref()
const confirmModalNext = ref()

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
  confirmModalNext()
}

const closeConfirmModal = () => {
  confirmModalNext.value = null
  confirmLeaveModal.value.hide()
}

/**
 *****************      TABLE FORM
 */
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'
import RowContainer from '@/components/table/form/RowContainer'
import TableFileUpload from '@/components/table/form/TableFileUpload'
import CrmLog from '@/components/CrmLog'
import CustomerAddress from '@/components/content/customers/CustomerAddress'

const crmLogComponent = ref(null)

const tabs = ref(['Customer', 'Files', 'Logs', 'History'])

const saving = ref(false)
const remote = ref({})
const crmLog = ref([])

// Tried to do this by exposing active tab in the TableTab
// but couldn't get it to work, so I've done it this hacky way
// instead
const selectedTab = ref()
const tabManager = ref(null)
watchEffect(() => {
  if (tabManager.value) {
    selectedTab.value = tabManager.value.tab
  }
})

const getCustomerId = () => {
  customerId.value = false
  if (props.id && parseInt(props.id) > 0) {
    customerId.value = props.id
  }
}

const update = async () => {
  local.value.logs = crmLogComponent.value.getLogsForSave()
  if (local.value.followUpDate) {
    local.value.followUpDate = formatDateForSave(local.value.followUpDate)
  }

  if (!customerId.value) {
    insert()
    return
  }

  let response = await api.put(
    '/customers/' + customerId.value,
    {
      customer: local.value
    },
    saving
  )
  handleResponse(response.data)
  validation.value.save()
}

const addresses = ref([])

const updateAddresses = (newAddresses) => {
  addresses.value = newAddresses
}

const addressOptions = computed(() => {
  return addresses.value.map((address) => {
    return {
      value: address.tenantCustomerAddressId,
      label: `${address.name} - (${address.address1}, ${address.addressPostcode})`
    }
  })
})

const statusOptions = ref([
  { label: 'Active', value: 'Active' },
  { label: 'Onboarding', value: 'Onboarding' },
  { label: 'Potential', value: 'Potential' },
  { label: 'No Amazon', value: 'No Amazon' },
  { label: 'Rejected', value: 'Rejected' }
])

const addContactRow = () => {
  local.value.contacts.push({})
}

const updateFilesList = (files = []) => {
  files.value = files
}

const getCustomer = async () => {
  if (!customerId.value || customerId.value === 'new') {
    return
  }

  let response = await api.get('/customers/' + customerId.value)
  handleResponse(response.data)
}

const priceProfiles = ref([])
const getPricingProfiles = async () => {
  let response = await api.get('/pricing', {
    filter: {},
    sort: { name: 1 }
  })
  let profiles = response.data.pricingProfiles
  priceProfiles.value = profiles.map((profile) => {
    return {
      label: profile.name,
      value: profile.tenantPricingId
    }
  })
}

const gotoCustomers = () => {
  router.push('/customers/')
}

const insert = async () => {
  let response = await api.post(
    '/customers/',
    { customer: local.value },
    saving
  )
  customerId.value = String(response.data.insertedId)
  router.replace('/customers/' + customerId.value)
  handleResponse(response.data)
  setTitle()
  validation.value.save()
}

const unarchiveCustomer = async () => {
  let response = await api.put(
    '/customers/' + customerId.value,
    {
      unarchive: 1,
      tenantCustomerId: customerId.value
    },
    saving
  )
  let customer = response.data.customers[0]
  updateCustomer(customer)
}

const archiveCustomer = async () => {
  let response = await api.put(
    '/customers/' + customerId.value,
    {
      archive: 1,
      tenantCustomerId: customerId.value
    },
    saving
  )
  let customer = response.data.customers[0]
  updateCustomer(customer)
}

const updateCustomer = (customer) => {
  customer.createdAt = formatDateTime(customer.createdAt, utcOffset.value)
  customer.approvedAt = formatDateTime(customer.approvedAt, utcOffset.value)
  customer.updatedAt = formatDateTime(customer.updatedAt, utcOffset.value)
  customer.archivedAt = formatDateTime(customer.archivedAt, utcOffset.value)
  customer.paidAt = formatDateTime(customer.paidAt, utcOffset.value)
  customer.followUpDate = formatDate(customer.followUpDate, utcOffset.value)
  local.value = Object.assign({}, local.value, customer)
  remote.value = Object.assign({}, remote.value, customer)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  },
  {
    id: 2,
    label: 'Unarchive',
    click: unarchiveCustomer,
    icon: ['fas', 'arrow-rotate-left']
  },
  {
    id: 3,
    label: 'Archive',
    click: archiveCustomer,
    icon: ['fas', 'box-archive']
  }
])

/*
  HISTORY
*/
watch(selectedTab, async (current, previous) => {
  if (current == 'History' && !loadedHistory.value) {
    await getHistoryRows()
    loadedHistory.value = true
  }
})

/**
 * Calculates the number of audit history records
 */
const totalHistoryCustomerCount = computed(() => {
  return customerAudit.value.length
})

const loadedHistory = ref(false)
const historyCustomerRows = ref([])
const customerAudit = ref([])
const customerAuditCols = ref([
  {
    label: 'Name',
    name: 'name'
  },
  {
    label: 'Status',
    name: 'status'
  },
  {
    label: 'Follow Up Date',
    name: 'followUpDate'
  },
  {
    label: 'Website',
    name: 'website'
  },
  {
    label: 'Default Shipping Address',
    name: 'defaultShippingAddressId'
  },
  {
    label: 'Default Billing Address',
    name: 'defaulyBillingAddressId'
  }
])
const customerAddressAuditCols = ref([
  {
    label: 'Name',
    name: 'name'
  },
  {
    label: 'Building Type',
    name: 'buildingType'
  },
  {
    label: 'Country',
    name: 'country'
  },
  {
    label: 'Address Line 1',
    name: 'address1'
  },
  {
    label: 'Address Line 2',
    name: 'address2'
  },
  {
    label: 'Address Line 3',
    name: 'address3'
  },
  {
    label: 'Address Postcode',
    name: 'addressPostcode'
  }
])
const customerContactAuditCols = ref([
  {
    label: 'Name',
    name: 'name'
  },
  {
    label: 'Email',
    name: 'email'
  },
  {
    label: 'Phone',
    name: 'phone'
  }
])

import { useAudit } from '@/composables/useAudit'
const {
  auditHistoryCols,
  mergeAudit,
  sortAudit,
  formatAuditDates,
  processAudit
} = useAudit()

const getHistoryRows = async (args = {}) => {
  let responseCustomerAudit = await api.get('/audit/tenantCustomer', {
    ...args,
    params: {
      tenantCustomerId: customerId.value
    }
  })

  let responseCustomerAddressAudit = await api.get(
    '/audit/tenantCustomerAddress',
    {
      ...args,
      params: {
        tenantCustomerId: customerId.value
      }
    }
  )

  let responseCustomerContactAudit = await api.get(
    '/audit/tenantCustomerContact',
    {
      ...args,
      params: {
        tenantCustomerId: customerId.value
      }
    }
  )

  let auditRecords = mergeAudit([
    responseCustomerAudit.data.data,
    responseCustomerAddressAudit.data.data,
    responseCustomerContactAudit.data.data
  ])

  let auditSorted = sortAudit(auditRecords)

  customerAudit.value = processAudit(
    {
      Customer: 'tenantCustomerId',
      'Customer Address': 'tenantCustomerAddressId',
      'Customer Contact': 'tenantCustomerContactId'
    },
    {
      Customer: customerAuditCols.value,
      'Customer Address': customerAddressAuditCols.value,
      'Customer Contact': customerContactAuditCols.value
    },
    auditSorted
  )
  setHistoryRows()
}

/**
 *
 */
const setHistoryRows = (paginationSkip = 0) => {
  let paginationEnd = paginationSkip + 20
  if (paginationEnd > customerAudit.value.length) {
    paginationEnd = customerAudit.value.length
  }
  let rows = customerAudit.value.slice(paginationSkip, paginationEnd)
  rows = JSON.parse(JSON.stringify(rows))
  let formatted = formatAuditDates(rows)

  historyCustomerRows.value = formatted
}

const setTitle = async () => {
  let pageTitle = local.value.name || 'Create customer'
  document.getElementById('pagetitle').innerHTML = pageTitle
  document.title = pageTitle
}

const getData = async () => {
  loading()
  try {
    progressBarStart()
    getCustomerId()
    await getCustomer()
    await getPricingProfiles()
    setTitle()
    loaded()
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
    router.back()
  }
}

getData()

const routeRefreshOnMounted = ref(false)
onUpdated(() => {
  if (
    routeRefreshOnMounted.value &&
    router.currentRoute.value.params.id == 'new'
  ) {
    router.go()
  }
  routeRefreshOnMounted.value = true
})
</script>

<style lang="scss"></style>
