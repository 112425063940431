import useApi from '@/components/useApi'

export function getSkuDataForLabel() {
  const getSkusForLabel = async (api, labels) => {
    const skus = formatSkus(labels)
    const skuData = await getSkuData(api, skus)

    return skuData
  }

  const formatSkus = (labels) => {
    let index = 0
    let length = labels.length
    let skus = []
    for (index; index < length; index++) {
      let label = labels[index]
      let dispatchItems = label?.dispatchItems

      if (!dispatchItems) {
        continue
      }

      let dispatchSkus = dispatchItems.map((dispatchItem) => {
        return dispatchItem.sku
      })

      skus.push(...dispatchSkus)
    }

    return skus
  }

  const getSkuData = async (api, skus) => {
    const response = await api.get('/skus/orders', { skus })
    return response.data?.skus || []
  }

  return { getSkusForLabel }
}
