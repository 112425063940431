<template>
  <div
    v-if="show"
    ref="alertEl"
    class="alert alert-dismissible"
    :class="classObject"
    role="alert"
  >
    <FontAwesomeIcon v-if="alertIcon" :icon="alertIcon"></FontAwesomeIcon>
    {{ alertMessage }}
    <button
      type="button"
      class="btn-close"
      aria-label="Close"
      @click="closeAlert"
    ></button>
  </div>
  <slot></slot>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, provide } from 'vue'
import { Alert } from 'bootstrap'
import { useRouter } from 'vue-router'

const alertType = ref('')
const alertMessage = ref('')
const alertIcon = ref('')
const alertEl = ref('')
const alert = ref()
const show = ref(false)
const router = useRouter()

const classObject = computed(() => {
  return {
    'alert-danger': alertType.value === 'error',
    'alert-success': alertType.value === 'success',
    'alert-info': alertType.value === 'info'
  }
})

const closeAlert = () => {
  show.value = false
}

const newAlert = (message) => {
  alertMessage.value = message
  show.value = true
}

const successAlert = (message) => {
  alertType.value = 'success'
  alertIcon.value = ['fas', 'check']
  newAlert(message)
}

const infoAlert = (message) => {
  alertType.value = 'info'
  alertIcon.value = ['fas', 'eye']
  newAlert(message)
}

const errorAlert = (message) => {
  alertType.value = 'error'
  alertIcon.value = ['fas', 'triangle-exclamation']
  newAlert(message)
}

const createAlert = (type, message) => {
  switch (type.toLowerCase()) {
    case 'success':
      successAlert(message)
      break

    case 'error':
      errorAlert(message)
      break

    case 'info':
    default:
      infoAlert(message)
      break
  }
}

onMounted(() => {
  alert.value = new Alert(alertEl.value)
})

router.beforeEach(() => {
  closeAlert()
})

provide('createAlert', createAlert)
</script>

<style lang="scss">
.alert {
  margin: 1rem;
}
</style>
