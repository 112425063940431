<template>
  <TableContainer>
    <TablePanel>
      <template #1>
        <Wizard
          ref="wizard"
          :steps="wizardSteps"
          :icons="stepIcons"
          :finish-step-text="finishStepText"
          @complete-wizard="createNewIntegration"
        >
          <!-- INTEGRATION TYPE-->
          <template #integration_type>
            <ValidationProvider>
              <TableContainer>
                <TablePanel>
                  <template #1>
                    <TableForm>
                      <TableRow
                        v-model="integrationStep.selectedIntegration"
                        type="select"
                        :options="integrations"
                        label="Integration"
                        hover-information="Select which third party you want to integrate with"
                      ></TableRow>
                    </TableForm>
                  </template>
                </TablePanel>
              </TableContainer>
            </ValidationProvider>
          </template>
          <!-- ./INTEGRATION TYPE-->

          <!-- INTRODUCTION -->
          <template #introduction>
            <template v-if="integrationStep.selectedIntegration === 'AMAZON'">
              Amazon intro here
            </template>

            <template
              v-if="integrationStep.selectedIntegration === 'PARCEL2GO'"
            >
              P2GO intro here
            </template>

            <template
              v-if="integrationStep.selectedIntegration === 'ROYALMAIL'"
            >
              Royal mail intro here
            </template>

            <template v-if="integrationStep.selectedIntegration === 'CUSTOM'">
              <ValidationProvider>
                <TableContainer>
                  <TablePanel>
                    <template #1>
                      <TableForm>
                        <TableRow
                          v-model="customIntegrationDetails.endpoint"
                          type="text"
                          label="Endpoint"
                        ></TableRow>
                        <TableRow
                          v-model="customIntegrationDetails.name"
                          type="text"
                          label="Name"
                        ></TableRow>
                      </TableForm>
                    </template>
                  </TablePanel>
                </TableContainer>
              </ValidationProvider>
            </template>

            <template v-if="integrationStep.selectedIntegration === 'EBAY'">
              Ebay intro here
            </template>

            <template v-if="integrationStep.selectedIntegration === 'SHOPIFY'">
              <ValidationProvider>
                <TableContainer>
                  <TablePanel>
                    <template #1>
                      <TableForm>
                        <TableRow
                          v-model="shopifyDetails.shopName"
                          type="text"
                          label="Shop name"
                          hover-information="Please enter the name of your shop on Shopify"
                        ></TableRow>
                      </TableForm>
                    </template>
                  </TablePanel>
                </TableContainer>
              </ValidationProvider>
            </template>

            <!-- GET USER TO CONFIRM THEY HAVE READ THE INTRO-->
            <ValidationProvider>
              <TableForm v-if="shopifyDetails.shopName">
                This will connect Braid to your Shopify store
                <TableRow
                  type="custom"
                  label="I have read and understand the introduction"
                >
                  <input
                    id="introductionHasBeenRead"
                    v-model="introductionStep.hasBeenRead"
                    type="checkbox"
                  />
                </TableRow>
              </TableForm>
            </ValidationProvider>
            <!-- ./GET USER TO CONFIRM THEY HAVE READ THE INTRO-->
          </template>
          <!-- ./INTRODUCTION -->

          <!--CUSTOM INTEGRATION-->
          <template #connect_to_custom_integration>
            <ValidationProvider>
              <TableContainer v-if="credentials">
                <TablePanel>
                  <template #1>
                    <TableForm>
                      <TableRow
                        v-model="credentials.devId"
                        type="text"
                        label="Dev ID"
                      ></TableRow>
                      <TableRow
                        v-model="credentials.appId"
                        type="text"
                        label="App ID"
                      ></TableRow>
                      <TableRow
                        v-model="credentials.clientId"
                        type="text"
                        label="Client ID"
                      ></TableRow>
                      <TableRow
                        v-model="credentials.clientSecret"
                        type="text"
                        label="Client Secret"
                      ></TableRow>
                    </TableForm>
                  </template>
                </TablePanel>
              </TableContainer>
            </ValidationProvider>
          </template>
          <!--./CUSTOM INTEGRATION-->

          <!--AMAZON AUTH0-->
          <template #connect_to_amazon> </template>
          <!--./AMAZON AUTH0-->

          <!--ROYAL MAIL AUTH0-->
          <template #connect_to_royal_mail>
            <ValidationProvider>
              <TableContainer>
                <TablePanel>
                  <template #1>
                    <TableForm>
                      <TableRow
                        v-model="royalMailDetails.apiKey"
                        type="text"
                        label="API Key"
                      ></TableRow>
                    </TableForm>
                  </template>
                </TablePanel>
              </TableContainer>
            </ValidationProvider>
          </template>
          <!--./ROYAL MAIL AUTH0-->

          <!--PARCEL_TO_GO AUTH0-->
          <template #connect_to_parcel2go>
            <ValidationProvider>
              <TableContainer>
                <TablePanel>
                  <template #1>
                    <TableForm>
                      <TableRow
                        v-model="parcel2GoDetails.clientId"
                        type="text"
                        label="Client ID"
                      ></TableRow>
                      <TableRow
                        v-model="parcel2GoDetails.clientSecret"
                        type="text"
                        label="Client Secret"
                      ></TableRow>
                    </TableForm>
                  </template>
                </TablePanel>
              </TableContainer>
            </ValidationProvider>
          </template>
          <!--./PARCEL_TO_GO AUTH0-->

          <!--SHOPIFY AUTH0-->
          <template #connect_to_shopify> </template>
          <!--./SHOPIFY AUTH0-->

          <!--EBAY KEYS-->
          <template #connect_to_ebay>
            <h1>keys stuff here</h1>
          </template>
          <!--./EBAY KEYS-->
        </Wizard>
      </template>
    </TablePanel>
  </TableContainer>
</template>
<script setup>
import { ref, watch, computed, nextTick } from 'vue'
import { useRouter } from 'vue-router'
import Wizard from './Wizard.vue'
import TableForm from '@/components/table/form/TableForm.vue'
import TableRow from '@/components/table/form/TableRow.vue'
import ValidationProvider from '@/components/table/ValidationProvider.vue'
import RowContainer from '@/components/table/form/RowContainer.vue'
import TableFileUpload from '@/components/table/form/TableFileUpload'
import useApi from '@/components/useApi'

const api = useApi()
const router = useRouter()
const shopifyDetails = ref({ shopName: '' })
const credentials = ref(null)

/*******************************************************
                    GENERAL WIZARD SETUP
 ********************************************************/

const DEFAULT_STEPS = ['Integration Type', 'Introduction']
const stepIcons = ref({
  'Integration Type': ['fas', 'code-branch'],
  Introduction: ['fas', 'file-lines'],
  'Connect to Amazon': ['fab', 'amazon'],
  'Connect to eBay': ['fab', 'ebay'],
  'Connect to Shopify': ['fab', 'shopify'],
  'Connect to custom integration': ['fas', 'code-branch']
})
let wizardSteps = ref([].concat(DEFAULT_STEPS))

/**
 * Mark a step as complete or incomplete
 * @param {string} stepName Name of step to complete (must be one of the wizardSteps array options)
 * @param {boolean} completed True or false if the step is completed
 */
const changeStepComplete = (stepName, completed) => {
  wizard.value.toggleStepCompleted(stepName, completed)
}

//template refs
const wizard = ref(null)

// Called when the wizard has been completed
const createNewIntegration = async () => {
  let integration = integrationStep.value.selectedIntegration
  switch (integration) {
    case 'AMAZON':
      return await connectToAmazon()
    case 'EBAY':
      return await connectToEbay()
    case 'SHOPIFY':
      return await connectToShopify()
    case 'PARCEL2GO':
      return await connectToP2Go()
    case 'ROYALMAIL':
      return await connectToRoyalMail()
    case 'CUSTOM':
      return await connectToCustom()
  }
}

// mBnGHANQ7z8N3pcz
/**
 * AMAZON AUTH
 */

const connectToAmazon = async () => {
  const appId = 'amzn1.sp.solution.c268edd5-c593-4e45-8f07-9e4f7837db70'
  window.location.replace(
    `https://sellercentral.amazon.co.uk/apps/authorize/consent?application_id=${appId}&version=beta&state=stateexample`
  )
}

/**
 * EBAY AUTH
 */

const connectToEbay = async () => {
  window.location.replace(
    'https://auth.ebay.com/oauth2/authorize?client_id=FLOWMOTI-Braid-PRD-f8463c110-94f4f0e2&response_type=code&redirect_uri=FLOWMOTION_LTD-FLOWMOTI-Braid--usueqinlp&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly&prompt=login'
  )
}

const connectToCustom = async () => {
  if (credentials.value) {
    router.push('/channels')
    return
  }

  const endpoint = customIntegrationDetails.value.endpoint
  const name = customIntegrationDetails.value.name
  let response = await api.post('/integrations/', {
    endpoint,
    name
  })

  credentials.value = response.data
}

/**
 * SHOPIFY AUTH
 */

const connectToShopify = async () => {
  window.location.replace(
    `https://${shopifyDetails.value.shopName}.myshopify.com/admin/oauth/authorize?client_id=9322ecb9331a3f70d169b340ba6c1876&scope=read_orders,write_orders,read_products,read_customers,read_fulfillments,write_fulfillments,read_assigned_fulfillment_orders,write_assigned_fulfillment_orders,read_merchant_managed_fulfillment_orders,write_merchant_managed_fulfillment_orders,read_locations,write_products,read_product_listings,read_shipping,write_shipping,read_inventory,write_inventory,read_reports,write_reports,read_returns,write_returns,read_third_party_fulfillment_orders,write_third_party_fulfillment_orders&redirect_uri=https://ecommerce-20210901.pod-1.braid-inventory.com/auth/callback&state=state-braid-1&grant_options[]=per_user`
  )
}

const connectToRoyalMail = async () => {
  const name = 'Royal Mail'
  const endpoint = 'ROYAL_MAIL'
  let response = await api.post('/integrations/', {
    endpoint,
    name
  })
  return await api.post('/royal-mail/auth', {
    credential: royalMailDetails.value,
    channel: {
      name: 'Royal Mail',
      channel: 'ROYAL_MAIL'
    }
  })
}

const connectToP2Go = async () => {
  const name = 'Parcel2Go'
  const endpoint = 'PARCEL2GO'
  let response = await api.post('/integrations/', {
    endpoint,
    name
  })

  await api.post('/parcel2Go/auth', {
    credential: parcel2GoDetails.value,
    channel: {
      name: 'Parcel2Go',
      channel: 'PARCEL2GO'
    }
  })
}

/*******************************************************
                 INTRODUCTION STEP
 ********************************************************/

const introductionStep = ref({ hasBeenRead: false })

watch(
  () => introductionStep.value.hasBeenRead,
  (value) => {
    changeStepComplete('Introduction', value)
  }
)
/*******************************************************
                 INTEGRATION TYPE STEP
 ********************************************************/

const integrations = ref([
  { value: 'AMAZON', label: 'Amazon' },
  { value: 'EBAY', label: 'eBay' },
  { value: 'SHOPIFY', label: 'Shopify' },
  { value: 'PARCEL2GO', label: 'Parcel2Go' },
  { value: 'ROYALMAIL', label: 'Royal mail' },
  { value: 'CUSTOM', label: 'Custom' }
])

const finishStepText = computed(() => {
  if (!wizard.value) {
    return 'Nex1t'
  }

  let integration = integrationStep.value.selectedIntegration
  let integrationName
  switch (integration) {
    case 'AMAZON':
      integrationName = 'Amazon'
      changeStepComplete('Introduction', true)
      break
    case 'EBAY':
      integrationName = 'eBay'
      changeStepComplete('Introduction', true)
      break
    case 'SHOPIFY':
      integrationName = 'Shopify'
      break
    case 'PARCEL2GO':
      integrationName = 'Parcel2Go'
      changeStepComplete('Introduction', true)
      break
    case 'ROYALMAIL':
      integrationName = 'Royal mail'
      changeStepComplete('Introduction', true)
      break
    case 'CUSTOM':
      if (credentials.value) {
        return 'Finish'
      }

      return 'Create custom integration'
  }

  return `Connect to ${integrationName}`
})

const integrationStep = ref({ selectedIntegration: '' })
watch(
  () => integrationStep.value.selectedIntegration,
  async (value) => {
    //Depending on the integration chosen change the steps in the wizard
    //First reset to default steps then add new steps as required
    wizardSteps.value = [].concat(DEFAULT_STEPS)
    let stepName = 'Connect to '

    switch (value) {
      case 'AMAZON':
        stepName += 'Amazon'
        break
      case 'EBAY':
        stepName += 'eBay'
        break
      case 'SHOPIFY':
        stepName += 'Shopify'
        break
      case 'PARCEL2GO':
        stepName += 'Parcel2Go'
        break
      case 'ROYALMAIL':
        stepName += 'Royal mail'
        break
      case 'CUSTOM':
        stepName += 'custom integration'
        break
    }

    wizardSteps.value.push(stepName)
    await nextTick()
    changeStepComplete(stepName, true)

    //Check to see if the step has been completed
    const complete =
      value !== null || typeof value !== 'undefined' || value !== ''
    changeStepComplete('Integration Type', complete)
  }
)

/*******************************************************
                 CUSTOM INTEGRATION CHOICES
 ********************************************************/

const customIntegrationDetails = ref({ endpoint: '', name: '' })

watch(customIntegrationDetails.value, (current) => {
  if (current.endpoint == '' || current.name == '') {
    changeStepComplete('Introduction', false)
    return
  }

  changeStepComplete('Introduction', true)
})

/*******************************************************
                 PARCEL2GO CHOICES
 ********************************************************/

const parcel2GoDetails = ref({ apiKey: '', apiSecret: '' })

/*******************************************************
                 ROYAL MAIL CHOICES
 ********************************************************/

const royalMailDetails = ref({ apiKey: '' })

/*******************************************************
                 SHOPIFY CHOICES
 ********************************************************/
</script>

<style lang="scss"></style>
