<template>
  <ValidationProvider ref="validation" v-slot="{ errors, changes }">
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: {
          disable: errors || !changes
        }
      }"
    >
    </ActionBar>
    <TableTabs ref="tabManager" v-slot="{ activeTab }" :tabs="tabs">
      <TableContainer v-if="activeTab == 'Company'">
        <TablePanel :cols="2" :width="2">
          <template #1>
            <div v-if="!pageLoading">
              <div
                v-if="logo != null"
                class="col-12 d-flex justify-content-center mb-3"
              >
                <button
                  class="btn profile-picture-button"
                  @click="file.click()"
                >
                  <img style="height: 10rem" :src="logo" />
                  <div class="button-overlay">
                    <p class="px-1">Edit</p>
                    <font-awesome-icon
                      :icon="['fas', 'pen']"
                    ></font-awesome-icon>
                  </div>
                </button>
              </div>
              <div v-else class="col-12 d-flex justify-content-center mb-3">
                <button class="btn mx-2" @click="file.click()">
                  Upload Logo
                  <font-awesome-icon
                    :icon="['fas', 'upload']"
                  ></font-awesome-icon>
                </button>
              </div>
              <TableForm>
                <TableRow v-model="logo" label="" type="custom">
                  <input
                    id="fileUpload"
                    ref="file"
                    type="file"
                    multiple
                    :disabled="isSavingFiles"
                    accept="image/*"
                    class="input-file"
                    @change="updateLocalLogo"
                  />
                </TableRow>
              </TableForm>
            </div>
          </template>
          <template #2>
            <TableForm>
              <!-- COMPANY NAME -->
              <TableRow
                v-model="local.name"
                label="Company Name"
                type="text"
              ></TableRow>
              <!-- COMPANY NAME -->

              <!-- COMPANY DEFAULT CURRENCY -->
              <TableRow
                v-model="local.currency"
                label="Currency"
                type="select"
                :options="currencies"
              ></TableRow>
              <!-- COMPANY DEFAULT CURRENCY -->

              <!-- COMPANY TYPE -->
              <TableRow
                v-model="local.companyType"
                label="Company Type"
                type="select"
                :options="[
                  { value: 1, label: 'Sole Trader' },
                  { value: 2, label: 'Partnership' },
                  { value: 3, label: 'Limited Liability Partner' },
                  { value: 4, label: 'Private Limited Company' },
                  { value: 5, label: 'Public Limited Company' }
                ]"
              ></TableRow>
              <!-- COMPANY TYPE -->

              <!-- COMPANY REGISTRATION -->
              <TableRow
                v-if="
                  local.companyType == 3 ||
                  local.companyType == 4 ||
                  local.companyType == 5
                "
                v-model="local.companyReg"
                label="Company Reg"
                type="text"
              ></TableRow>
              <!-- COMPANY REGISTRATION -->

              <!-- CONTACT EMAIL -->
              <TableRow
                v-model="local.contactEmail"
                label="Contact Email"
                type="text"
              ></TableRow>
              <!-- CONTACT EMAIL -->

              <!-- CONTACT PHONE -->
              <TableRow
                v-model="local.contactPhone"
                label="Contact Phone"
                type="text"
              ></TableRow>
              <!-- CONTACT PHONE -->
            </TableForm>
          </template>
        </TablePanel>
        <TablePanel :cols="1" :width="2">
          <template #1>
            <TableForm>
              <!-- VAT Registered -->
              <TableRow
                v-model="local.vatRegistered"
                label="Vat Registered"
                type="custom"
              >
                <slot>
                  <input
                    type="checkbox"
                    :checked="checkVatRegistered"
                    @mousedown="toggleVatRegistered"
                  />
                </slot>
              </TableRow>
              <!-- VAT Registered -->

              <!-- VAT Number -->
              <TableRow
                v-model="local.vatNumber"
                label="Vat Number"
                type="text"
              ></TableRow>
              <!-- VAT Number -->
            </TableForm>
          </template>
        </TablePanel>
        <TablePanel>
          <template #1>
            <TableGrid
              ref="userGrid"
              :cols="userCols"
              :rows="userProfiles"
              :total-count="totalCount"
            ></TableGrid>
          </template>
        </TablePanel>
      </TableContainer>
      <TableContainer v-if="activeTab == 'Prefixes'">
        <TablePanel :cols="2" :width="4">
          <template #1>
            <TableForm>
              <RowContainer heading="PO Number" auto-collapse>
                <TableRow
                  v-model="local.poPrefix"
                  label="PO prefix"
                  type="text"
                  :indent-level="1"
                ></TableRow>
                <TableRow
                  v-model="local.poIndex"
                  label="PO index"
                  type="number"
                  :indent-level="1"
                ></TableRow>
              </RowContainer>

              <RowContainer heading="SKU Number" auto-collapse>
                <TableRow
                  v-model="local.skuPrefix"
                  label="SKU prefix"
                  type="text"
                  :indent-level="1"
                ></TableRow>
                <TableRow
                  v-model="local.skuIndex"
                  label="SKU index"
                  type="number"
                  :indent-level="1"
                ></TableRow>
              </RowContainer>

              <RowContainer heading="Order Number" auto-collapse>
                <TableRow
                  v-model="local.orderPrefix"
                  label="Order prefix"
                  type="text"
                  :indent-level="1"
                ></TableRow>
                <TableRow
                  v-model="local.orderIndex"
                  label="Order index"
                  type="number"
                  :indent-level="1"
                ></TableRow>
              </RowContainer>

              <RowContainer heading="Credit note Number" auto-collapse>
                <TableRow
                  v-model="local.creditNotePrefix"
                  label="Credit note prefix"
                  type="text"
                  :indent-level="1"
                ></TableRow>
                <TableRow
                  v-model="local.creditNoteIndex"
                  label="Credit note index"
                  type="number"
                  :indent-level="1"
                ></TableRow>
              </RowContainer>
            </TableForm>
          </template>
        </TablePanel>
      </TableContainer>
      <TableContainer v-if="activeTab == 'Locations'">
        <TablePanel>
          <template #1>
            <TableForm>
              <TableRow
                v-model="local.defaultLocationId"
                label="Default location"
                type="select"
                :options="locations"
              ></TableRow>

              <TableRow
                v-model="local.invoiceLocationId"
                label="Invoice location"
                type="select"
                :options="invoiceLocations"
              ></TableRow>
            </TableForm>
          </template>
        </TablePanel>
      </TableContainer>
      <PricingProfilesTab
        v-if="activeTab == 'Pricing Profiles'"
        ref="pricingProfilesTab"
        :vat-registered="local.vatRegistered"
        :vat-number="local.vatNumber"
      >
      </PricingProfilesTab>
    </TableTabs>
  </ValidationProvider>

  <ConfirmLeaveFormModal
    ref="confirmLeaveModal"
    @cancel="closeConfirmLeaveModal"
    @confirm="gotoNextPage"
  ></ConfirmLeaveFormModal>
</template>

<script setup>
/**
 * IMPORTS
 */
import {
  ref,
  computed,
  onMounted,
  nextTick,
  watch,
  toRaw,
  inject,
  watchEffect
} from 'vue'
import { useRouter } from 'vue-router'
import { store } from '@/store/store'
import { pageLoad } from '@/composables/pageLoad'

const axios = require('axios')
const _ = require('lodash')
const createAlert = inject('createAlert')
const router = useRouter()
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

/**
 * METHODS
 */
import useApi from '@/components/useApi'
const api = useApi()

/**
 * DATA
 */
import useCurrencies from '@/composables/selectData/useCurrencies'
const { getCurrenciesForSelect } = useCurrencies()
const currencies = getCurrenciesForSelect()

/**
 ***************** COMPONENTS
 */
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'
import PricingProfilesTab from '@/components/content/company/Tabs/PricingProfilesTab.vue'
import ActionBar from '@/components/content/ActionBar'
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'
import RowContainer from '@/components/table/form/RowContainer'
/**
 * DATA
 */
const validation = ref(null)
const userGrid = ref(null)

// Tried to do this by exposing active tab in the TableTab
// but couldn't get it to work, so I've done it this hacky way
// instead
const selectedTab = ref()
const tabManager = ref(null)
watchEffect(() => {
  if (tabManager.value) {
    selectedTab.value = tabManager.value.tab
  }
})

watch(selectedTab, async (current, previous) => {
  switch (current) {
    case 'Company':
      nextTick(() => userGrid.value.updateRows())
      break
  }
})

const saving = ref(false)
const confirmLeaveModal = ref(null)
const remote = ref({})
const local = ref({
  configurables: []
})
const locations = ref([])
const mandatoryFields = ref([
  'name',
  'country',
  'currency',
  'companyType',
  'companyReg',
  'contactPhone',
  'contactEmail'
])
const pricingProfilesTab = ref(null)

const uuid = require('uuid')
const _UNIQUE_STATE_ID = ref(uuid.v4())
const INITIAL_LOGO = ref(null)

const userCols = [
  {
    label: 'Name',
    name: 'name',
    visible: true
  },
  {
    label: 'Nickname',
    name: 'nickname',
    visible: true
  },
  {
    label: 'Email',
    name: 'email',
    visible: true
  },
  {
    label: 'Verified',
    name: 'email_verified',
    visible: true
  }
]

const userProfiles = ref([])
const totalCount = ref(0)

const invoiceLocations = computed(() => {
  if (!locations.value || locations.value.length === 0) {
    return []
  }

  return _.filter(locations.value, { locationType: 'BILLING' })
})

const formattedConfigurables = computed(() => {
  let index = 0
  let length = local.value.configurables.length
  let formatted = {}

  for (index; index < length; index++) {
    let configurable = local.value.configurables[index]
    let collection = configurable.collection
    let name = configurable.name

    if (!formatted[collection]) {
      formatted[collection] = {}
    }

    if (!formatted[collection][name]) {
      formatted[collection][name] = {}
    }

    formatted[collection][name] = configurable
  }

  return formatted
})

const valid = computed(() => {
  let index = 0
  let length = mandatoryFields.value.length

  for (index; index < length; index++) {
    let field = mandatoryFields.value[index]
    if (local.value[field] == undefined) {
      if (field === 'companyReg') {
        if (local.value.companyType == 1 || local.value.companyType == 2) {
          continue
        }
      }

      return false
    }
  }

  return true
})

const samplePo = computed(() => {
  if (local.value.poPrefix == undefined || local.value.poIndex == undefined) {
    return 'Invalid'
  }
  return local.value.poPrefix + String(local.value.poIndex).padStart(7, 0)
})

const sampleSku = computed(() => {
  if (local.value.skuPrefix == undefined || local.value.skuIndex == undefined) {
    return 'Invalid'
  }
  return local.value.skuPrefix + String(local.value.skuIndex).padStart(7, 0)
})

const sampleCreditNote = computed(() => {
  if (
    local.value.creditNotePrefix == undefined ||
    local.value.creditNoteIndex == undefined
  ) {
    return 'Invalid'
  }
  return (
    local.value.creditNotePrefix +
    String(local.value.creditNoteIndex).padStart(7, 0)
  )
})

const sampleOrder = computed(() => {
  if (
    local.value.orderPrefix == undefined ||
    local.value.orderIndex == undefined
  ) {
    return 'Invalid'
  }
  return local.value.orderPrefix + String(local.value.orderIndex).padStart(7, 0)
})

const getCompanyData = async () => {
  let response = await api.get('/tenant')
  handleResponse(response)
}

const getLocations = async () => {
  if (!store.getters.getTenantComplete) {
    return
  }

  let retrievedLocations = await api.getLocations()
  let formattedLocations = retrievedLocations.map((location) => {
    return {
      ...location,
      value: location.tenantLocationId,
      label: location.name
    }
  })
  locations.value = formattedLocations
}

const getUsers = async () => {
  let response = await api.get('/user')
  userProfiles.value = Object.values(response.data)
  totalCount.value = userProfiles.value.length
}

const update = async () => {
  // uploadLogo()
  // await pricingProfilesTab.value.save()
  await api.put('/tenant', { tenant: local.value })
  validation.value.updateFormChanges(_UNIQUE_STATE_ID, 'logo', false)
  window.location.reload()
}

const buttons = ref([
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  }
])

/**
 * METHODS
 */
const openconfirmLeaveModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
}

const closeconfirmLeaveModal = () => {
  confirmLeaveModal.value.hide()
}

const handleResponse = (response) => {
  let tenant = response.data.tenant
  let configurables = response.data.configurables || []
  local.value = {
    name: tenant.name,
    tenantId: tenant.tenantId,
    defaultLocationId: tenant.defaultLocationId,
    logo: {
      name: tenant.logoName,
      type: tenant.logoType
    },
    poPrefix: tenant.poPrefix,
    skuPrefix: tenant.skuPrefix,
    skuIndex: tenant.skuIndex,
    poIndex: tenant.poIndex,
    currency: tenant.currency,
    country: tenant.country,
    contactEmail: tenant.contactEmail,
    contactPhone: tenant.contactPhone,
    companyType: tenant.companyType,
    companyReg: tenant.companyReg,
    vatRegistered: tenant.vatRegistered == 1 ? 1 : 0,
    vatNumber: tenant.vatNumber,
    creditNotePrefix: tenant.creditNotePrefix,
    creditNoteIndex: tenant.creditNoteIndex,
    orderIndex: tenant.orderIndex,
    orderPrefix: tenant.orderPrefix,
    configurables: configurables,
    invoiceLocationId: tenant.invoiceLocationId
  }
  remote.value = JSON.parse(JSON.stringify(local.value))
}

const tabs = ref([
  'Company',
  'Prefixes',
  'Locations',
  'Pricing Profiles',
  'Labels'
])
/*
 *    <!------- FILE UPLOAD -------!>
 */
const file = ref({ url: null })
const isSavingFiles = ref(false)
const logo = ref(null)

const uploadLogo = async (e) => {
  const fileInput = document.getElementById('fileUpload')
  let fileUpload = fileInput.files[0]
  let filename = 'logo'
  let filetype = fileUpload.type

  isSavingFiles.value = true
  let urlObj = await getFileUrl({ filename, filetype })

  if (!urlObj) {
    isSavingFiles.value = false
    return
  }

  filename = urlObj['logo'].newFilename

  await axios
    .put(urlObj['logo'].url, fileUpload, {
      headers: { 'Content-Type': filetype, 'x-amz-acl': 'private' }
    })
    .catch((err) => {
      isSavingFiles.value = false
      console.log(err)
    })

  let fileToUpload = {
    name: filename,
    display: 'logo',
    type: filetype
  }

  let response = await api.post('/files/upload-logo', fileToUpload)

  isSavingFiles.value = false
  await getLogo()
}

const updateLocalLogo = () => {
  const fileInput = document.getElementById('fileUpload')
  let fileUpload = fileInput.files[0]
  logo.value = URL.createObjectURL(fileUpload)
  let changesMade = INITIAL_LOGO.value != logo.value
  validation.value.updateFormChanges(_UNIQUE_STATE_ID, 'logo', changesMade)
}

const getFileUrl = async (file) => {
  let response = await api.get('/files/', {
    files: [file],
    table: 'tenant',
    id: 'logo',
    getUploadUrls: 1
  })
  return response.data.urls
}

const getLogo = async () => {
  let response = await api.get('/files/download-logo', {
    fileName: local.value.logo.name
  })
  let url = response.data.url
  logo.value = url
  INITIAL_LOGO.value = logo.value
}

const registerLogoFormState = () => {
  validation.value.registerFormState(_UNIQUE_STATE_ID, 'logo')
}

const checkVatRegistered = () => {
  return local.value.vatRegistered == 1
}

const toggleVatRegistered = () => {
  local.value.vatRegistered = local.value.vatRegistered == 1 ? 0 : 1
}

const getData = async () => {
  loading()
  await getCompanyData()
  await getLocations()
  await getLogo()
  await getUsers()
  loaded()
  registerLogoFormState()
}

getData()
</script>

<style>
.form-wrapper {
  padding: 0.5em 4em 1em 1em;
  background-color: #f9f9f9;
}
.label-right {
  text-align: right;
}
input::-webkit-datetime-edit {
  color: #a6a6a6;
}
input:focus::-webkit-datetime-edit {
  color: inherit !important;
}
.float-right {
  float: right;
}
</style>
<style scoped>
.input-file {
  visibility: hidden;
  width: fit-content;
}
.company-logo {
  max-height: 10vh;
  height: fit-content;
}
</style>
