<script setup>
import useApi from '@/components/useApi'
import { useRouter } from 'vue-router'
const api = useApi()
const router = useRouter()
const code = router.currentRoute.value.query.code

const getUserAccessToken = async () => {
  if (!code) {
    return
  }

  let data = { newEbay: 1, code: code }
  let reAuthChannelId = localStorage.reAuthChannelId

  if (reAuthChannelId) {
    data.tenantChannelId = reAuthChannelId
    data.failureCredentials = null
    localStorage.removeItem('reAuthChannelId')
  }

  const name = 'eBay'
  const endpoint = 'EBAY'
  let response = await api.post('/integrations/', {
    endpoint,
    name
  })

  await api.post('/channels', data)
  gotoChannels()
}
const gotoChannels = () => {
  router.push('/channels/')
}

getUserAccessToken()
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.channel {
  padding: 12px 15px 0px 15px;
}
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
