<template>
  <div ref="bar" class="progress-bar"></div>
  <slot></slot>
</template>

<script setup>
import { ref, provide } from 'vue'
const percent = ref(0)
const show = ref(false)
const timeout = ref(null)

const bar = ref(null)

const set = (percentage) => {
  percent.value = percentage
  bar.value.style.width = `${percent.value}%`
}

const start = () => {
  if (show.value) {
    return
  }
  bar.value.style.visibility = 'visible'
  bar.value.classList.remove('failed')
  show.value = true
  set(25)

  timeout.value = setInterval(() => {
    increase(0.5)
  }, 15)
}

const hide = () => {
  bar.value.style.visibility = 'hidden'
  show.value = false
  bar.value.style.width = '0'
}

const fail = () => {
  bar.value.classList.add('failed')
  done()
}

const increase = (percentage) => {
  percent.value += percentage
  if (percent.value > 90) {
    percent.value = 90
  }
  bar.value.style.width = `${percent.value}%`
}

const done = () => {
  clearInterval(timeout.value)
  set(100)
  show.value = false
  setTimeout(hide, 500)
}

provide('progressBarStart', start)
provide('progressBarFail', fail)
provide('progressBarDone', done)
</script>

<style lang="scss">
.progress-bar {
  height: 0.3rem;
  width: 0;
  max-width: calc(100vw - 13.2rem);
  border-bottom-left-radius: 1.563rem;
  position: absolute;
  z-index: 999999;
  background-color: $success-color;
  background: linear-gradient(
    90deg,
    $braid-green-accessible 0%,
    $success-color 80%
  );
  background: -webkit-linear-gradient(
    90deg,
    $braid-green-accessible 0%,
    $success-color 80%
  );
  background: -moz-linear-gradient(
    0deg,
    $braid-green-accessible 0%,
    $success-color 80%
  );

  &.failed {
    background-color: $error-color;
    background: linear-gradient(
      90deg,
      $braid-green-accessible 0%,
      $error-color 80%
    );
    background: -webkit-linear-gradient(
      90deg,
      $braid-green-accessible 0%,
      $error-color 80%
    );
    background: -moz-linear-gradient(
      90deg,
      $braid-green-accessible 0%,
      $error-color 80%
    );
  }
}
.collapsedLeftNav {
  .progress-bar {
    max-width: calc(100vw - 3.2rem);
  }
}
</style>
