<template>
  <div></div>
</template>

<script setup>
import { onMounted, ref, inject } from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'

const router = useRouter()
const api = useApi()

const code = ref(null)
const state = ref(null)
const shop = ref(null)

let query = router.currentRoute.value.query
code.value = query.code
state.value = query.state
shop.value = query.shop

const getUserAccessToken = async () => {
  const name = shop.value
  const endpoint = 'SHOPIFY'
  let response = await api.post('/integrations/', {
    endpoint,
    name
  })

  await api.post('/channels', {
    newShopify: 1,
    code: code.value,
    state: state.value,
    shop: shop.value,
    name: shop.value
  })
  gotoChannels()
}

getUserAccessToken()

const gotoChannels = () => {
  router.push('/channels/')
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.channel {
  padding: 12px 15px 0px 15px;
}
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
