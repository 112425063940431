<template>
  <SkeletonCard v-if="pageLoading"></SkeletonCard>
  <div v-else class="image-panel">
    <div class="image-preview-panel">
      <img
        v-if="images.length > 0"
        class="image-preview"
        :src="images[0].src"
      />
      <div v-else class="image-preview no-image" @click="$refs.file.click()">
        <FontAwesomeIcon
          size="lg"
          :icon="['fas', 'circle-question']"
          bounce
        ></FontAwesomeIcon>
        <p class="m-0">No Image</p>
      </div>
    </div>

    <div
      class="draggable-area scrollable-wrapper"
      @drop.prevent="drop"
      @dragover.prevent
    >
      <div
        v-for="image in images"
        :key="'image-' + image.id"
        class="image-container"
      >
        <img
          :id="image.id"
          :src="image.src"
          draggable="true"
          class="image"
          @dragstart="dragStart"
          @dragover="dragOver"
          @click="editImage(image)"
          @click.prevent.right="({ x, y }) => rightClick({ x, y }, image.id)"
        />
        <FontAwesomeIcon
          v-if="image.error"
          title="Image has not uploaded or updated."
          size="lg"
          class="error-upload-icon"
          :icon="['fas', 'triangle-exclamation']"
        ></FontAwesomeIcon>
      </div>
      <div
        class="image image-add"
        draggable="false"
        @click="$refs.file.click()"
      >
        <FontAwesomeIcon
          size="lg"
          :icon="['fas', 'circle-plus']"
        ></FontAwesomeIcon>
        <p class="m-0">Add Image</p>
      </div>
      <input
        id="fileUpload"
        ref="file"
        type="file"
        multiple
        accept="image/*"
        class="input-file"
        @change="handleImageUpload"
      />
    </div>
  </div>

  <BraidModal ref="editImageModal" @close="editableImage = null">
    <template #title> Edit image </template>
    <template #body>
      <div v-if="editableImage != null" class="edit-image-box">
        <img class="editable-image" :src="editableImage.src" />
      </div>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref, toRaw, inject, watch, onMounted, computed } from 'vue'
import { pageLoad } from '@/composables/pageLoad'
import SkeletonCard from '@/components/skeleton/SkeletonCard.vue'

const { getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})
const axios = require('axios')
import useApi from '@/components/useApi'
const api = useApi()

const props = defineProps({
  table: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  }
})

const uuid = require('uuid')

const _UNIQUE_STATE_ID = ref(uuid.v4())

const registerFormState = inject('registerFormState')
const updateFormChanges = inject('updateFormChanges')
const showContextMenu = inject('showContextMenu')
const hideContextMenu = inject('hideContextMenu')

const INITIAL_SKU_IMAGES = ref([])
const images = ref([])

registerFormState(_UNIQUE_STATE_ID, `${props.table}_Images`)

watch(
  () => images.value,
  (newValue) => {
    let changesMade = !_.isEqual(toRaw(newValue), INITIAL_SKU_IMAGES.value)
    updateFormChanges(_UNIQUE_STATE_ID, `${props.table}_Images`, changesMade)
  },
  { deep: true }
)

const editableImage = ref(null)
const selected = ref(null)
const index = ref(null)
const editImageModal = ref(null)
const reorderType = ref(false)
const file = ref(null)

const getFiles = async () => {
  let response = await api.get('/files/download-files', {
    id: props.id,
    table: props.table
  })

  let data = response.data.downloads || []

  return data.sort((a, b) => a.order - b.order)
}

const getData = async () => {
  let files = await getFiles()
  INITIAL_SKU_IMAGES.value = [...toRaw(files)]
  images.value = files
}

getData()
/**
 * Sets the editable image and opens the edit image modal.
 */
const editImage = (image) => {
  editableImage.value = image
  editImageModal.value.show()
}

/**
 * Show context menu function
 */
const contextMenuFocus = ref(null)
const rightClick = ({ x, y }, imageId) => {
  contextMenuFocus.value = imageId
  showContextMenu(imageId, true, x, y, contextMenuButtons.value)
}

const deleteImage = (event) => {
  let id = contextMenuFocus.value
  let imageIndex = images.value.findIndex((el) => el.id == id)
  images.value.splice(imageIndex, 1)
  hideContextMenu()
}

const save = async () => {
  if (images.value.length === 0) {
    return
  }

  // upload new images to digitalocean
  images.value = images.value.map((el, index) => {
    return {
      ...el,
      order: index
    }
  })
  let urls = await getFileUrls(filenames.value)

  let length = uploadFiles.value.length
  let index = 0

  let filesToUpload = []
  let uploadFailures = []

  for (index; index < length; index++) {
    let file = uploadFiles.value[index]
    let filename = file.name
    let filetype = file.type
    let urlObj = urls[filename]

    if (!urlObj) {
      continue
    }
    let url = urlObj.url
    let oldFilename = filename
    filename = urlObj.newFilename

    // Uploads new images to s3 bucket
    await axios
      .put(url, file, {
        headers: { 'Content-Type': filetype, 'x-amz-acl': 'public-read' }
      })
      .then(() => {
        filesToUpload.push({
          name: filename,
          display: oldFilename,
          type: filetype,
          order: index
        })
      })
      .catch((err) => {
        console.log(err)
        uploadFailures.push(file)
      })
  }

  // Uploads new photos
  // await api.post('/files/', {
  //   files: filesToUpload,
  //   table: props.table,
  //   id: props.id
  // })

  // Updates the order on all images
  await api.post('/files', {
    files: images.value,
    table: props.table,
    id: props.id
  })

  let uploadedImages = await getFiles()

  let imageResponse = uploadedImages.concat(uploadFailures)

  let formattedImages = []

  imageResponse.forEach((el) => {
    if (el.src) {
      formattedImages.push(el)
    }
    let index = images.value.findIndex((image) => image.filename == el.name)
    if (index != -1) {
      formattedImages.push({ ...images.value[index], error: 1 })
    }
  })

  images.value = formattedImages.sort((a, b) => a.order - b.order)
}

const getFileUrls = async (files) => {
  let response = await api.get('/files/', {
    files: files,
    table: props.table,
    id: props.id,
    getUploadUrls: 1
  })
  return response.data.urls
}

const contextMenuButtons = ref([
  {
    key: 'deleteImageBtn',
    click: deleteImage,
    icon: ['fas', 'trash'],
    text: 'Delete Image'
  }
])

/**
 * Handles dropping an image inside the draggable area.
 *
 * Removes active css class, moves item and clears movement values
 */
const drop = (event) => {
  if (reorderType.value) {
    handleImageReorder(event)
  } else {
    handleDragImageUpload(event)
  }
  reorderType.value = false
}

const handleImageReorder = (event) => {
  selected.value.classList.remove('drag-sort-active')
  let from = images.value.findIndex((el) => el.id == selected.value.id)
  moveItem(from, index.value)
  selected.value = null
  index.value = null
}

const uploadFiles = ref([])
const filenames = ref([])

const handleImageUpload = (event) => {
  let droppedFiles = event.target?.files

  ;[...droppedFiles].forEach((file) => {
    uploadFiles.value.push(file)
    filenames.value.push({ filename: file.name, filetype: file.type })
    addImagePreview(file)
  })
}

/**
 * Handles uploaded files. Add to upload array and previews in image list
 */
const handleDragImageUpload = (event) => {
  let droppedFiles = event.dataTransfer?.files

  ;[...droppedFiles].forEach((file) => {
    uploadFiles.value.push(file)
    filenames.value.push({ filename: file.name, filetype: file.type })
    addImagePreview(file)
  })
}

/**
 * Adds image to image preview list
 */
const addImagePreview = (file) => {
  let reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onloadend = function () {
    images.value.push({
      id: images.value.length,
      filename: file.name,
      src: reader.result
    })
  }
}

/**
 * Handles dragStart of an image.
 *
 * Sets the movement values
 */
const dragStart = (event) => {
  event.dataTransfer.effectAllowed = 'move'
  event.currentTarget.classList.add('drag-sort-active')
  selected.value = event.target
  index.value = images.value.findIndex((el) => el.id == event.target.id)
  reorderType.value = true
}

/**
 * Tracks the location to place the dragged element.
 *
 * Sets index equal to the index of the element in the list
 */
const dragOver = (event) => {
  if (!reorderType.value) {
    return
  }

  let data = images.value

  if (event.target.id == selected.value.id) {
    return
  } else if (isBefore(selected.value, event.target)) {
    index.value = data.findIndex((el) => el.id == event.target.id)
  } else {
    let position = data.findIndex((el) => el.id == event.target.nextSibling.id)
    index.value = position != -1 ? position - 1 : images.value.length - 1
  }
}

/**
 * Re-orders the images array using the from and to indexes.
 */
const moveItem = (from, to) => {
  let item = images.value.splice(from, 1)[0]
  item.changed = 1
  images.value.splice(to, 0, item)
}

/**
 * Calculates if el1 is before or after el2 in the rendered list.
 */
const isBefore = (el1, el2) => {
  let cur
  if (el2.parentNode === el1.parentNode) {
    for (cur = el1.previousSibling; cur; cur = cur.previousSibling) {
      if (cur === el2) return true
    }
  }
  return false
}

defineExpose({ save })
</script>

<style scoped lang="scss">
.image-panel {
  height: 25rem;
  width: 100%;
  display: flex;

  & .image-preview-panel {
    height: 100%;
    width: 50%;
    padding: 1rem;
    // background-color: rgb(231, 153, 110);
    display: flex;
    justify-content: center;
    align-items: center;

    & .image-preview {
      max-height: 100%;
      max-width: 100%;
      border-radius: 5px;

      &.no-image {
        height: 20rem;
        width: 20rem;
        background-color: $braid-grey-3;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;

        & p {
          font-size: 2rem;
          font-weight: 700;
        }
      }
    }
  }

  & .draggable-area {
    // background-color: rgb(241, 139, 139);
    height: 100%;
    width: 50%;
    padding: 1rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;

    .image-add {
      height: 7rem;
      width: 7rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      cursor: pointer !important;
    }

    .image {
      height: 7rem;
      width: 7rem;
      max-height: 7rem;
      max-width: 7rem;
      flex: 1 0 21%;
      margin: 5px;
      background-color: $braid-grey-3;
      border-radius: 5px;
      object-fit: cover;
      cursor: move;
      cursor: grab;

      &.drag-sort-active {
        // background-color: rgba(200, 255, 150, 1);
      }
    }

    .image-container {
      position: relative;
      & .drag-sort-active + .error-upload-icon {
        opacity: 0;
        transition: none;
      }

      & .error-upload-icon {
        transition: opacity 0.3s ease-out;
        opacity: 1;
        position: absolute;
        top: 10px;
        right: 10px;
      }
      & svg {
        & path {
          fill: yellow !important;
        }
      }
    }
  }
}

.edit-image-box {
  height: 80vh;
  width: 100%;
  padding: 3rem;
  & .editable-image {
    max-height: 70%;
    max-width: 100%;
  }
}
</style>

<style lang="scss">
.draggable-area {
  .image-container {
    & svg {
      & path {
        fill: $info-color !important;
      }
    }
  }
}
</style>
