<template>
  <tr v-if="pageLoading">
    <SkeletonInput></SkeletonInput>
  </tr>

  <!-- TYPE: BUTTON -->
  <tr v-else-if="type === 'button'">
    <TableButton
      :colspan="2"
      :label="label"
      @click="$emit('click')"
    ></TableButton>
  </tr>
  <!-- ./TYPE: BUTTON -->

  <tr
    v-else
    :class="{
      'error-row': validator.errors.value || props.error,
      'alert alert-danger': rowClass(value)
    }"
    class="table-row"
    :style="style"
    @click.right.prevent="rightClick"
  >
    <!-- ROW LABEL -->
    <td class="table-row-label" @click="focusInput">
      <p :class="classObject" class="table-row-label-text">
        <span class="hover">
          {{ label }}
          <HoverInfo
            v-if="hoverInformation"
            :id="_UNIQUE_STATE_ID"
            :info="hoverInformation"
          ></HoverInfo>
          <a v-if="link" :href="link" class="label-link">Go to</a>
          <span v-if="required && readonly"> * </span>
        </span>

        <!-- ERRORS -->
        <span v-if="validator.errors.value" class="table-row-errors">
          <FontAwesomeIcon
            v-if="validator.errors.value"
            class="error-icon"
            :icon="['fas', 'triangle-exclamation']"
            title="Error"
            aria-hidden="true"
          ></FontAwesomeIcon>
          {{ validator.errors.value }}
        </span>
        <!-- ./ERRORS -->
      </p>
    </td>
    <!-- ./ROW LABEL -->

    <!-- TYPE: TEXTAREA -->
    <td v-if="type === 'textarea'" class="table-form-input">
      <TableTextArea
        ref="input"
        v-model="value"
        :read-only="readOnly"
        :rules="rules"
        :class="{ readonly: readOnly }"
        class="table-value"
        @update:model-value="handleInput"
      >
      </TableTextArea>
    </td>
    <!-- ./TYPE: TEXTAREA -->

    <td v-else class="table-form-input">
      <!-- TYPE: TEXT -->
      <TableText
        v-if="type === 'text'"
        ref="input"
        v-model="value"
        :read-only="readOnly"
        :rules="rules"
        :placeholder="placeholder"
        :class="{ readonly: readOnly }"
        class="table-value"
        @update:model-value="handleInput"
      ></TableText>
      <!-- ./TYPE: TEXT -->

      <!-- TYPE: DATE -->
      <TableDate
        v-if="type === 'date'"
        ref="input"
        v-model="value"
        :read-only="readOnly"
        :rules="rules"
        :class="{ readonly: readOnly }"
        class="table-value"
        @update:model-value="handleInput"
      ></TableDate>
      <!-- ./TYPE: DATE -->

      <!-- TYPE: NUMBER -->
      <TableNumber
        v-if="type === 'number'"
        ref="input"
        v-model="value"
        :read-only="readOnly"
        :rules="rules"
        :class="{ readonly: readOnly }"
        class="table-value"
        @update:model-value="handleInput"
      ></TableNumber>
      <!-- ./TYPE: NUMBER -->

      <!-- TYPE: SELECT -->
      <TableSelect
        v-if="type === 'select'"
        ref="input"
        v-model="value"
        :display="display"
        :reduce="reduce"
        :options="options"
        :no-options="noOptions"
        :multiple="multiple"
        :read-only="readOnly"
        :rules="rules"
        :class="{ readonly: readOnly }"
        class="table-value"
        @update:model-value="handleInput"
        @search="handleSearch"
      ></TableSelect>
      <!-- ./TYPE: SELECT -->

      <!-- TYPE: VARIANT -->
      <TableVariant
        v-if="type === 'variant'"
        ref="input"
        v-model="value"
        :options="options"
        :variant-value="variantValue"
        :rules="rules"
        :read-only="readOnly"
        :change="change"
        @update:model-value="handleInput"
      >
      </TableVariant>
      <!-- ./TYPE: VARIANT -->

      <!-- TYPE: ATTRIBUTE -->
      <TableAttribute
        v-if="type === 'attribute'"
        ref="input"
        v-model="value"
        :options="options"
        :attribute-value="variantValue"
        :rules="rules"
        :read-only="readOnly"
        :change="change"
        @update:model-value="handleInput"
      >
      </TableAttribute>
      <!-- ./TYPE: ATTRIBUTE -->

      <TableUnlockButton
        v-if="type === 'unlockButton'"
        ref="input"
        v-model="value"
        :display="display"
        :reduce="reduce"
        :options="options"
        :rules="rules"
        :type="customType"
        :button-text="buttonText"
        class="table-value"
        @update:model-value="handleInput"
        @search="handleSearch"
      ></TableUnlockButton>

      <TableSwapButton
        v-if="type === 'swapButton'"
        ref="input"
        class="table-value"
        :button-text="buttonText"
      >
        <template #first><slot name="first"></slot></template>
        <template #second><slot name="second"></slot></template>
      </TableSwapButton>

      <!-- CUSTOM -->
      <slot v-if="type === 'custom'" :validator="validator"></slot>
      <!-- ./CUSTOM -->
    </td>
  </tr>
</template>

<script>
/**
 * PROPS AND EMIT used across all table inputs are defined here.
 */
import useTableInput from '@/components/table/useTableInput'
const config = useTableInput()

const rowProps = {
  /**
   * GENERIC ROW PROPS
   */
  label: {
    type: String,
    default: ''
  },
  error: {
    type: Boolean,
    default: false
  },
  multiple: {
    type: Boolean,
    default: false
  },
  link: {
    type: String,
    default: ''
  },
  indentLevel: {
    // Can be 0, 1 or 2
    type: Number,
    default: 0
  },
  hoverInformation: {
    // If provided, an information icon is displayed to the user. When hovered over
    // It wil display this string.
    type: String,
    default: undefined
  },
  type: {
    type: String,
    required: true,
    validator: (value) => {
      return [
        'button',
        'date',
        'text',
        'textarea',
        'number',
        'select',
        'variant',
        'custom',
        'unlockbutton',
        'swapbutton'
      ].includes(value.toLowerCase())
    }
  },

  /**
   * SELECT INPUT RELATED PROPS
   */
  options: {
    type: Array,
    default() {
      return []
    }
  },
  multiple: {
    type: Boolean,
    default: false
  },
  noOptions: {
    type: [Array, String],
    default: ''
  },
  display: {
    // Used to control which attribute is displayed in v-select.
    // DO NOT set default here, it is set in TableSelect.vue. If you set it here
    // it will override the value in tableselect and things won't work as expected
    type: String
  },
  reduce: {
    // Reduces object options to one attribute
    type: Function
  },

  /**
   * VARIANT INPUT RELATED PROPS
   */
  variantValue: {
    type: Object,
    default() {
      return {}
    }
  },
  change: {
    type: Function,
    default() {
      return
    }
  },
  customType: {
    type: String,
    default: 'text'
  },
  buttonText: {
    type: [Array, String],
    default: 'Edit'
  }
}

export default {
  name: 'TableRow'
}
</script>

<script setup>
import { computed, ref, onMounted, inject, watch } from 'vue'
const uuid = require('uuid')
import useValidator from '@/components/table/useValidator'
import TableText from '@/components/table/form/TableText'
import TableTextArea from '@/components/table/form/TableTextArea'
import TableDate from '@/components/table/form/TableDate'
import TableNumber from '@/components/table/form/TableNumber'
import TableButton from '@/components/table/form/TableButton'
import TableSelect from '@/components/table/form/TableSelect'
import TableVariant from '@/components/table/form/TableVariant'
import TableAttribute from '@/components/table/form/TableAttribute'
import TableUnlockButton from '@/components/table/form/TableUnlockButton'
import TableSwapButton from '@/components/table/form/TableSwapButton'
import HoverInfo from '@/components/table/form/HoverInfo'
import { pageLoad } from '@/composables/pageLoad'
import SkeletonInput from '@/components/skeleton/SkeletonInput.vue'

const { getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const props = defineProps({
  ...config._props,
  ...rowProps,
  rowClass: {
    type: Function,
    default() {
      return ''
    }
  },
  style: {
    type: String,
    default: ''
  },
  required: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(
  ['click', 'mounted', 'search', 'update:modelValue', 'change'].concat(
    config._emits
  )
)

const _INITIAL_VALUE = ref(props.modelValue)
const value = ref(props.modelValue)

const identifier = computed(() => {
  return props.label.replaceAll(' ', '_')
})

watch(
  () => props.modelValue,
  (val) => (value.value = val)
)

const validator = useValidator()
validator.setType(props.type)
validator.setRules(props.rules)

const changesMade = computed(() => {
  // Need this here incase values are loaded in as null or undefined
  if (!value.value && !_INITIAL_VALUE.value) {
    return false
  }

  return value.value !== _INITIAL_VALUE.value
})

const classObject = computed(() => {
  return {
    indent: props.indentLevel == 0,
    indent2: props.indentLevel == 1,
    indent3: props.indentLevel == 2
  }
})

const _UNIQUE_STATE_ID = ref(uuid.v4())
const registerFormState = inject('registerFormState')
registerFormState(_UNIQUE_STATE_ID, identifier.value)

const updateFormChanges = inject('updateFormChanges')
const updateFormErrors = inject('updateFormErrors')

const handleInput = () => {
  updateFormChanges(_UNIQUE_STATE_ID, identifier.value, changesMade.value)
  if (props.rules) {
    validator.validate(value.value)
    if (!validator.errors.value) {
      emit('update:modelValue', value)
      updateFormErrors(_UNIQUE_STATE_ID, identifier.value, false)
      return
    } else {
      updateFormErrors(_UNIQUE_STATE_ID, identifier.value, true)
      return
    }
  }
  emit('change', value.value)
  emit('update:modelValue', value)
}

const handleSearch = (search, loading) => {
  emit('search', search, loading)
}

const input = ref(null)

const focusInput = () => {
  if (props.type !== 'custom') input.value.focus()
}

const showContextMenu = inject('showContextMenu')

const rightClick = ({ x, y }) =>
  showContextMenu(value.value, props.readOnly, x, y)

onMounted(() => {
  emit('mounted')
})
</script>

<style lang="scss" scoped>
.table-row-label {
  width: 1%;
  text-align: left;
  vertical-align: middle;
  white-space: nowrap;

  .table-row-label-text {
    margin: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    span {
      font-weight: 600;
      margin: 0;
    }
  }
}

.error-row {
  .table-row-errors {
    color: $error-color;
    font-weight: bold;
    padding-left: 0.3rem;
    margin-right: 1rem;

    svg.error-icon {
      font-size: 0.9rem;
      margin-left: 0.3rem;

      path {
        fill: $error-color;
      }
    }
  }

  .table-row-label-text {
    color: $error-color;
    span {
      font-weight: 600;
    }
    &::after {
      content: '*';
    }
  }
}

.table-row + .table-row {
  border: 0;
  td {
    border-top-color: $braid-grey-3;
    border-top-style: solid;
    border-top-width: 1px;
  }
}

.table-form-input {
  input:focus,
  textarea:focus,
  select:focus {
    border: 1px solid $braid-purple;
  }
  input,
  textarea,
  select {
    border: 1px solid $braid-grey-1;
  }
}

@media (min-width: 768px) {
  td:first-of-type {
    max-width: fit-content;
    padding-right: 1rem;
  }
  .indent {
    padding-left: 0rem;
  }

  .indent2 {
    padding-left: 1.5rem;
  }

  .indent3 {
    padding-left: 3rem;
  }
}

.label-link {
  padding-left: 0rem;
}
.readonly {
  cursor: default !important;
  background-color: $braid-grey-1;
  .table-form-input {
    input:focus,
    textarea:focus,
    select:focus {
      border: none;
    }
  }
}
td {
  border-bottom-width: 0px;
}
td:first-of-type {
  max-width: calc(50% - 1rem);
  padding-right: 1rem;
}
td:not(:first-of-type) {
  padding: 0.1rem 0 0.1rem 0.5rem;
  max-width: 50%;
}
.table-value {
  height: auto;
}

input,
select {
  height: 100%;
}
a {
  text-decoration: underline !important;
  color: $braid-purple !important;
  cursor: pointer;
  &:hover {
    color: $braid-light-purple;
  }
}
</style>
