import { ref } from 'vue'

export default function () {
  const errors = ref()

  let _type
  let _rules

  const setType = (type) => {
    _type = type
  }

  const setRules = (rules) => {
    _rules = rules
  }

  const validate_length = (value) => {
    if (!value) {
      return
    }

    let min = _rules.length.min || null
    let max = _rules.length.max || null

    if (!min && !max) {
      throw 'Length rule provided with no value for min and max'
    }

    if (min) {
      if (value.length < min) {
        errors.value = ` Minimum length of ${min}`
        return
      }
    }

    if (max) {
      if (value.length > max) {
        errors.value = ` Maximum length of ${max}`
        return
      }
    }

    errors.value = null
  }

  const validate_required = (value) => {
    if (!value || value === '' || value.length === 0) {
      errors.value = ' Required '
      return
    }

    errors.value = null
  }

  const validate = (value) => {
    if (!_type) {
      throw 'Validator type not set'
    }

    if (!_rules) {
      throw 'Validator rules not set'
    }

    if (_rules.length) {
      validate_length(value)
    }

    if (_rules.required) {
      validate_required(value)
    }
  }

  return { errors, setType, setRules, validate }
}
