<template>
  <BraidModal ref="modal" @confirm="confirm" @reject="cancel">
    <template #header> Unsaved changes </template>
    <template #body>
      <p>
        You have unsaved changes on this page, are you sure you want to leave?
      </p>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref } from 'vue'
const emit = defineEmits(['confirm', 'cancel'])

const confirm = () => {
  emit('confirm')
}

const cancel = () => {
  emit('cancel')
}

const modal = ref(null)
const show = () => {
  modal.value.show()
}

const hide = () => {
  modal.value.hide()
}

defineExpose({ show, hide })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
table {
  height: 600px;
  display: block;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}
</style>
