<template>
  <ValidationProvider
    v-if="loaded"
    ref="validation"
    v-slot="{ errors, changes }"
  >
    <ActionBar
      :buttons="buttons"
      :conditions="{ 0: { disable: !changes || errors } }"
    />
    <TableContainer>
      <TablePanel :cols="1" :width="4">
        <template #1>
          <TableForm>
            <!-- NAME -->
            <TableRow v-model="local.name" label="Name" type="text"></TableRow>
            <!-- ./NAME -->

            <!-- VAT RATE -->
            <TableRow
              v-model="local.defaultVatRate"
              label="Default VAT Rate"
              type="number"
            ></TableRow>
            <!-- ./VAT RATE -->

            <!--  COMPANY NAME -->
            <TableRow
              v-model="local.companyName"
              label="Company Name"
              type="text"
            ></TableRow>
            <!--  ./COMPANY NAME -->

            <!--  LOCATION TYPE -->
            <TableRow
              v-model="local.locationType"
              label="Location Type"
              name="locationType"
              :options="[
                { value: 'STORE', label: 'Storage' },
                { value: 'BILLING', label: 'Billing' }
              ]"
              type="select"
            ></TableRow>
            <!--  ./LOCATION TYPE -->

            <!--  ADDRESS LINE 1 -->
            <TableRow
              v-model="local.address1"
              label="Line 1"
              type="text"
            ></TableRow>
            <!--  ./ADDRESS LINE 1 -->

            <!--  ADDRESS LINE 2 -->
            <TableRow
              v-model="local.address2"
              label="Line 2"
              type="text"
            ></TableRow>
            <!--  ./ADDRESS LINE 2 -->

            <!--  ADDRESS LINE 3 -->
            <TableRow
              v-model="local.address3"
              label="Line 3"
              type="text"
            ></TableRow>
            <!--  ./ADDRESS LINE 3 -->

            <!--  POSTCODE -->
            <TableRow
              v-model="local.addressPostcode"
              label="Postcode"
              type="text"
            ></TableRow>
            <!--  ./POSTCODE -->

            <!-- COUNTRY -->
            <TableRow
              v-model="local.addressMainCountry"
              label="Main Country"
              :options="countries"
              type="select"
            ></TableRow>
            <!--./COUNTRY -->
          </TableForm>
        </template>
      </TablePanel>
    </TableContainer>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    >
    </ConfirmLeaveFormModal>
  </ValidationProvider>
</template>

<script setup>
import { ref, onMounted, onUpdated, nextTick, inject } from 'vue'

import { useRouter } from 'vue-router'
const router = useRouter()
import useApi from '@/components/useApi'
const api = useApi()

import useCountries from '@/composables/selectData/useCountries.js'
const { getCountriesForSelect } = useCountries()
const countries = getCountriesForSelect()

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: [String, Number],
    default: ''
  }
})

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const { formatDateTime, UTC_OFFSET } = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

// Object that contains all the data
const local = ref({})

// Displays content once it's fetched from the DB
const loaded = ref(false)
const returnId = ref(false)

const locationId = ref(false)

/**
 * Formats data returned from api
 */
const handleResponse = (data) => {
  let location = data.location
  local.value = {
    tenantLocationId: location.tenantLocationId,
    name: location.name,
    companyName: location.companyName,
    address1: location.address1,
    address2: location.address2,
    address3: location.address3,
    addressPostcode: location.addressPostcode,
    country: location.country,
    defaultVatRate: location.defaultVatRate,
    locationType: location.locationType,
    isDefaultDelivery: location.isDefaultDelivery,
    createdBy: location.createdBy,
    updatedBy: location.updatedBy,
    createdAt: formatDateTime(location.createdAt, utcOffset.value),
    updatedAt: formatDateTime(location.updatedAt, utcOffset.value)
  }
}

/*
 *****************      VALIDATION PROVIDER
 */

// Template ref to validation provider component
const validation = ref(null)

/*
 *****************      MODALS
 */
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'

const confirmLeaveModal = ref(null)
const confirmModalNext = ref(null)

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
  confirmModalNext.value()
}

const closeConfirmModal = () => {
  confirmModalNext.value = null
  confirmLeaveModal.value.hide()
}

/**
 *****************      TABLE FORM
 */
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

const insert = async () => {
  let response = await api.post('/locations/', { location: local.value })
  locationId.value = String(response.data.insertedId)
  router.replace('/locations/' + locationId.value)
  handleResponse(response.data)
}

const update = async () => {
  if (!locationId.value) {
    insert()
    return
  }
  await api.put('/locations/' + locationId.value, { location: local.value })
  validation.value.svae()
}

const setDefaultDeliveryLocation = () => {
  api.put(`/locations/${locationId.value}`, {
    setDefaultDeliveryLocation: 1,
    tenantLocationId: locationId.value
  })
  local.value.isDefaultDelivery = 1
}

const getLocationId = () => {
  locationId.value = false
}

const getReturnId = () => {
  returnId.value = false
  if (props.id && parseInt(props.id) > 0) {
    returnId.value = props.id
  }
}

const getReturn = async () => {
  if (!returnId.value || returnId.value === 'new') {
    return
  }

  let response = await api.get('/returns/' + returnId.value)
  handleResponse(response.data)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = [
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  },
  {
    id: 2,
    label: 'Set as default delivery location',
    click: () => setDefaultDeliveryLocation(),
    icon: []
  }
]

const setTitle = () => {
  let pageTitle = local.value.name || 'Create location'
  document.getElementById('pagetitle').innerHTML = pageTitle
  document.title = pageTitle
}

const getData = async () => {
  try {
    progressBarStart()
    getReturnId()
    await getReturn()
    setTitle()
    loaded.value = true
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

const routeRefreshOnMounted = ref(false)
onUpdated(() => {
  if (
    routeRefreshOnMounted.value &&
    router.currentRoute.value.params.id == 'new'
  ) {
    router.go()
  }
  routeRefreshOnMounted.value = true
})
</script>

<style lang="scss">
.defaultDelivery {
  background-color: $braid-green-accessible;
  color: $braid-white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 1.563rem;
  text-align: center;
  padding: 0.3rem;
}
</style>
