<template>
  <ValidationProvider>
    <TableForm>
      <!-- DATE FROM -->
      <TableRow
        v-model="local.dateFrom"
        type="date"
        label="Date from"
      ></TableRow>
      <!-- ./DATE FROM -->

      <!-- DATE TO -->
      <TableRow v-model="local.dateTo" type="date" label="Date to"></TableRow>
      <!-- ./DATE TO -->
    </TableForm>

    <button class="btn btn-grey borderless" @click="getRows()">
      Get order report
    </button>
  </ValidationProvider>

  <TableGrid
    v-if="displayGrid"
    :cols="cols"
    :rows="rows"
    :total-count="totalCount"
    :filter-function="getRows"
    :default-sort="defaultSort"
    id-key="sku"
    scrollable
  ></TableGrid>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onActivated, ref } from 'vue'
import useApi from '@/components/useApi'
const { formatDate, formatDateTime, UTC_OFFSET, dayjs } = require('@/dayjs')

import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

const router = useRouter()
const api = useApi()

const displayGrid = ref(false)
const grid = ref(null)
const rows = ref([])
const dateFrom = ref(dayjs().subtract(1, 'month').format('YYYY-MM-DD'))
const dateTo = ref(dayjs().format('YYYY-MM-DD'))
const local = ref({ dateFrom: dateFrom.value, dateTo: dateTo.value })
const totalCount = ref(0)
const defaultSort = ref({ sku: 1 })

const getRows = async (args = { filter: {}, sort: defaultSort.value }) => {
  args.filter.dateFrom = local.value.dateFrom
  args.filter.dateTo = local.value.dateTo
  args.orderItemReport = 1
  let response = await api.get('/orders/', args)

  if (!response) {
    return
  }

  rows.value = response.data.orderItems
  totalCount.value = response.data.count
  totalCount.value = parseInt(response.data.count)
  displayGrid.value = true
}

const buttons = []

const cols = [
  {
    label: 'SKU',
    name: 'sku',
    filter: true,
    width: 30,
    visible: true,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true
  },
  {
    label: 'Title',
    filter: true,
    name: 'title',
    visible: true
  },
  {
    label: 'Quantity sold',
    name: 'quantity',
    visible: true
  }
]
</script>
