import { createApp, unref } from 'vue'
import 'flatpickr/dist/flatpickr.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'

import App from './App.vue'

// Includes
import axios from 'axios'
import VueAxios from 'vue-axios'
import { router } from './router/index.js'
import { store } from './store/store'
import { VueMasonryPlugin } from 'vue-masonry'
import { createAuth0 } from '@auth0/auth0-vue'
const { getConfig } = require('./auth0/config')
const authConfig = getConfig()

// Components
import FontAwesomeIcon from './fontAwesomeIcons'
import vSelect from 'vue-select'
import VueFlatPickr from 'vue-flatpickr-component'
import { GChart } from 'vue-google-charts'
import ValidationProvider from '@/components/table/ValidationProvider'
import BraidModal from '@/components/modals/Modal'
import TableTabs from '@/components/table/tabs/TableTabs'
import TableContainer from '@/components/table/panel/TableContainer'
import TablePanel from '@/components/table/panel/TablePanel'
import TableGrid from '@/components/table/grid/TableGrid'

// Directives
import { vClickOutside } from '@/directives/vClickOutside'
import { vOffScreen } from '@/directives/vOffScreen'

import { createVuetify } from 'vuetify'
import { VSkeletonLoader } from 'vuetify/labs/VSkeletonLoader'

const vuetify = createVuetify({
  components: {
    VSkeletonLoader
  }
})

const app = createApp(App)

app
  .use(VueAxios, axios)
  .use(router)
  .use(store)
  .use(vuetify)
  .use(VueMasonryPlugin)
  .use(createAuth0(authConfig))
  .component('v-flat-pickr', VueFlatPickr)
  .component('FontAwesomeIcon', FontAwesomeIcon)
  .component('v-select', vSelect)
  .component('GChart', GChart)
  .component('ValidationProvider', ValidationProvider)
  .component('BraidModal', BraidModal)
  .component('TableTabs', TableTabs)
  .component('TableContainer', TableContainer)
  .component('TablePanel', TablePanel)
  .component('TableGrid', TableGrid)
  .directive('click-outside', vClickOutside)
  .directive('off-screen', vOffScreen)
  .mount('#app')

router.beforeEach(() => {
  const authenticated = unref(
    app.config.globalProperties['$auth0'].isAuthenticated
  )

  if (!authenticated) {
    localStorage.removeItem('locations')
  }
})
