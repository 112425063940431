<template>
  <div :class="containerClass" role="separator">
    <div v-if="$slots.default" class="p-divider-content">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
const props = defineProps({
  align: {
    type: String,
    default: null
  },
  layout: {
    type: String,
    default: 'horizontal'
  },
  type: {
    type: String,
    default: 'solid'
  }
})
const align = ref(props.align)
const layout = ref(props.layout)
const type = ref(props.type)
const containerClass = computed(() => {
  return [
    'p-divider p-component',
    'p-divider-' + layout.value,
    'p-divider-' + type.value,
    {
      'p-divider-left':
        layout.value === 'horizontal' &&
        (!align.value || align.value === 'left')
    },
    {
      'p-divider-center':
        layout.value === 'horizontal' && align.value === 'center'
    },
    {
      'p-divider-right':
        layout.value === 'horizontal' && align.value === 'right'
    },
    { 'p-divider-top': layout.value === 'vertical' && align.value === 'top' },
    {
      'p-divider-center':
        layout.value === 'vertical' &&
        (!align.value || align.value === 'center')
    },
    {
      'p-divider-bottom':
        layout.value === 'vertical' && align.value === 'bottom'
    }
  ]
})
</script>

<style scoped>
.p-divider-horizontal {
  display: flex;
  width: 100%;
  position: relative;
  align-items: center;
  padding: 0.5rem 0 0.5rem 0;
}
.p-divider-horizontal:before {
  position: absolute;
  display: block;
  top: 50%;
  left: 0;
  width: 100%;
  content: '';
}
.p-divider-horizontal.p-divider-left {
  justify-content: flex-start;
}
.p-divider-horizontal.p-divider-right {
  justify-content: flex-end;
}
.p-divider-horizontal.p-divider-center {
  justify-content: center;
}
.p-divider-content {
  background-color: white;
  z-index: 1;
  padding: 0 0.2rem 0 0.2rem;
}
.p-divider-vertical {
  min-height: 100%;
  margin: 0 1rem;
  display: flex;
  position: relative;
  justify-content: center;
}
.p-divider-vertical:before {
  position: absolute;
  display: block;
  top: 0;
  left: 50%;
  height: 100%;
  content: '';
}
.p-divider-vertical.p-divider-top {
  align-items: flex-start;
}
.p-divider-vertical.p-divider-center {
  align-items: center;
}
.p-divider-vertical.p-divider-bottom {
  align-items: flex-end;
}
.p-divider-solid.p-divider-horizontal:before {
  border: 0.5px;
  border-color: rgba(0, 0, 0, 0.3);
  border-top-style: solid;
}
.p-divider-solid.p-divider-vertical:before {
  border-left-style: solid;
}
.p-divider-dashed.p-divider-horizontal:before {
  border: 0.5px;
  border-color: rgba(0, 0, 0, 0.3);
  border-top-style: dashed;
}
.p-divider-dashed.p-divider-vertical:before {
  border-left-style: dashed;
}
.p-divider-dotted.p-divider-horizontal:before {
  border: 0.5px;
  border-color: rgba(0, 0, 0, 0.3);
  border-top-style: dotted;
}
.p-divider-dotted.p-divider-horizontal:before {
  border-left-style: dotted;
}
</style>
