<template>
  <input
    id="fileUpload"
    ref="file"
    type="file"
    multiple
    :disabled="isSavingFiles || !editable"
    accept="image/*,.pdf, .csv"
    class="input-file"
    @change="uploadFiles"
  />
  <TableGrid
    ref="fileGrid"
    :cols="columns"
    :rows="files"
    :total-count="totalCount"
    :drop-function="uploadFiles"
    :local-loading="loading"
    id-key="tenantFileId"
    drop-area
    scrollable
  ></TableGrid>
  <div v-if="files.length == 0" class="row">
    <div class="col-12 d-flex justify-content-center">
      <p class="m-0">No files have been uploaded.</p>
    </div>
  </div>
  <div v-if="editable" class="row">
    <div class="col-12 d-flex justify-content-center tbl-cell-btn">
      <button type="button" class="btn" @click="$refs.file.click()">
        <font-awesome-icon :icon="['fas', 'circle-plus']"></font-awesome-icon
        ><b>Add file</b>
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'
import { pageLoad } from '@/composables/pageLoad'
const axios = require('axios')
import useApi from '@/components/useApi'
import TableRow from '@/components/table/form/TableRow'
import DropArea from '@/components/content/DropArea'

const loading = ref(false)
const props = defineProps({
  table: { type: String, default: '' },
  id: { type: String, default: '' },
  updateFilesList: {
    type: Function,
    default() {
      return
    }
  },
  editable: {
    type: Boolean,
    default: false
  }
})

const viewFile = async (tenantFileId) => {
  //get signed download url
  //view file

  let response = await api.get('/files/' + tenantFileId)
  let url = response.data.url
  let win = window.open(url, '_blank')
  win.focus()
}

const dragging = ref(false)
const totalCount = ref(0)
const MAX_NUMBER_OF_FILES = 6
const columns = ref([
  {
    label: 'Filename',
    name: 'display',
    visible: true,
    primary: true,
    onClick: {
      function: viewFile,
      id: 'tenantFileId'
    }
  },
  {
    label: 'Filetype',
    name: 'filetype',
    visible: true
  },
  {
    label: 'Created by',
    name: 'createdBy',
    visible: true
  },
  {
    label: 'Created at',
    name: 'createdAt',
    visible: true,
    dateTime: true
  }
])
const fileGrid = ref(null)
const acl = ref('')
const isSavingFiles = ref(false)
const files = ref([])
const fileCount = ref(0)
const defaultSort = ref({ tenantFileId: 1 })
const api = useApi()

const uploadFiles = async (e) => {
  let droppedFiles = e.dataTransfer?.files
  const fileInput = document.getElementById('fileUpload')

  if (droppedFiles) {
    fileInput.files = droppedFiles
  }

  let fileUploads = fileInput.files

  let fileNames = []
  let index = 0
  let length = fileUploads.length

  for (index; index < length; index++) {
    let file = fileUploads[index]
    let filename = file.name
    let filetype = file.type
    fileNames.push({ filename, filetype })
  }

  if (!fileNames || fileNames.length === 0) {
    return
  }

  isSavingFiles.value = true
  let urls = await getFileUrls(fileNames)
  fileCount.value += fileUploads.length
  index = 0

  if (!urls) {
    isSavingFiles.value = false
    return
  }

  let filesToUpload = []

  for (index; index < length; index++) {
    let file = fileUploads[index]
    let filename = file.name
    let filetype = file.type
    let urlObj = urls[filename]

    if (!urlObj) {
      continue
    }

    let url = urlObj.url
    let oldFilename = filename
    filename = urlObj.newFilename

    await axios
      .put(url, file, {
        headers: { 'Content-Type': filetype, 'x-amz-acl': acl.value }
      })
      .catch((err) => {
        isSavingFiles.value = false
        console.log(err)
      })

    filesToUpload.push({
      name: filename,
      display: oldFilename,
      type: filetype
    })
  }

  let response = await api.post(
    '/files/',
    { files: filesToUpload, table: props.table, id: props.id },
    isSavingFiles
  )

  files.value = response.data.files
  totalCount.value = files.value.length
  isSavingFiles.value = false
}

const getFileUrls = async (files) => {
  let response = await api.get('/files/', {
    files: files,
    table: props.table,
    id: props.id,
    getUploadUrls: 1
  })
  return response.data.urls
}

const getFiles = async () => {
  loading.value = true
  let response = await api.get('/files/', { id: props.id, table: props.table })
  files.value = response.data.files || []
  totalCount.value = files.value.length
  loading.value = false
}

onMounted(async () => {
  await getFiles()

  fileGrid.value.updateRows()

  switch (props.table) {
    case 'tenantProduct':
      acl.value = 'public-read'
      break

    case 'tenantSku':
      acl.value = 'public-read'
      break

    case 'tenantSupplier':
      acl.value = 'private'
      break

    case 'tenantCustomer':
      acl.value = 'private'
      break
  }
})
</script>

<style lang="scss">
.name-text {
  font-weight: 600;
  text-align: center;
  width: 100%;
  color: black;
}
.type-text {
  text-align: center;
  bottom: 0;
  width: 100%;
  color: dimgray;
}
.click-text {
  text-align: center;
  width: 100%;
  color: #007bff;
}
.image-box {
  outline: 1px solid grey;
  height: 200px;
  width: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.align-right {
  text-align: right;
}

tbody {
  tr {
    cursor: pointer;
  }
}
.disabled {
  cursor: not-allowed;
  color: grey;
}
.text-align-center {
  text-align: center;
}
.active-page {
  color: #007bff;
}

.dropbox {
  outline: 2px dashed $braid-purple; /* the dash box */
  outline-offset: -10px;
  background-color: $braid-green;
  color: #000000;
  padding: 10px 10px;
  min-height: 200px; /* minimum height */
  position: relative;
  cursor: pointer;
}
.dragging {
  background: $braid-grey-0;
  opacity: 0.8;
}
.input-file {
  visibility: hidden;
  width: 100%;
  height: 0px;
  position: relative;
  cursor: pointer;
}
.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 50px 0;
}
</style>

<style scoped>
table {
  width: 100%;
}
.table th {
  vertical-align: top !important;
}
td {
  width: 170px;
  max-width: 170px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
