<template>
  <ValidationProvider ref="validation" v-slot="{ errors, changes }">
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: {
          show: local.archivedAt == null,
          disable: !changes || saving || errors
        },
        2: {
          show: local.archivedAt != null && selectedTab === 'Supplier'
        },
        3: {
          show: local.archivedAt == null && !changes && !saving
        }
      }"
    />

    <TableTabs ref="tabManager" v-slot="{ activeTab }" :tabs="tabs">
      <!-- SUPPLIER -->
      <template v-if="activeTab === 'Supplier'">
        <TableContainer>
          <TablePanel :cols="4" :width="4">
            <template #1>
              <TableForm>
                <!-- NAME -->
                <TableRow
                  v-model="local.name"
                  label="Name"
                  :rules="{ required: true }"
                  type="text"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./NAME -->

                <!-- ACCOUNT NUMBER -->
                <TableRow
                  v-model="local.accountNumber"
                  label="Account Number"
                  :rules="{ required: true }"
                  type="text"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./ACCOUNT NUMBER -->

                <!-- CATEGORY -->
                <TableRow
                  v-model="local.category"
                  label="Category"
                  placeholder="e.g. health, cosmetics, home, pets"
                  :rules="{ required: true, length: { max: 200 } }"
                  :read-only="!editable"
                  type="text"
                />
                <!-- ./CATEGORY -->

                <!-- WEBSITE -->
                <TableRow
                  v-model="local.website"
                  label="Website"
                  :rules="{ length: { max: 500 } }"
                  :read-only="!editable"
                  type="text"
                ></TableRow>
                <!-- ./WEBSITE -->
              </TableForm>
            </template>
            <template #2>
              <TableForm>
                <!-- FOLLOW UP DATE -->
                <TableRow
                  v-model="local.followUpDate"
                  label="Follow up date"
                  type="date"
                  :read-only="!editable"
                ></TableRow>
                <!-- ./FOLLOW UP DATE -->

                <!-- MAIN COUNTRY -->
                <TableRow
                  v-model="local.addressMainCountry"
                  label="Main Country"
                  :options="countries"
                  :read-only="!editable"
                  type="select"
                ></TableRow>
                <!-- ./MAIN COUNTRY -->

                <!-- WAREHOUSE COUNTRY -->
                <TableRow
                  v-model="local.addressWarehouseCountry"
                  label="Warehouse Country"
                  :options="countries"
                  :read-only="!editable"
                  type="select"
                ></TableRow>
                <!-- ./WAREHOUSE COUNTRY -->

                <!-- TAGS -->
                <TableRow
                  v-model="local.tags"
                  label="Tags"
                  placeholder="e.g. Nike, Adidas, Puma"
                  :rules="{ length: { max: 200 } }"
                  :read-only="!editable"
                  type="text"
                ></TableRow>
                <!-- ./TAGS -->

                <!-- MANAGED BY -->
                <TableRow
                  v-model="local.managedBy"
                  label="Managed By"
                  :read-only="!editable"
                  type="text"
                ></TableRow>
                <!-- ./MANAGED BY -->
              </TableForm>
            </template>
            <template #3>
              <TableForm :style="'height: 100%'">
                <!-- COMMENTS -->
                <TableRow
                  v-model="local.comments"
                  label="Comments"
                  type="textarea"
                  :read-only="!editable"
                  :style="'height: 50%'"
                ></TableRow>
                <!-- ./COMMENTS -->

                <!-- INTERNAL NOTES -->
                <TableRow
                  v-model="local.internalNotes"
                  label="Internal Notes"
                  type="textarea"
                  :read-only="!editable"
                  :style="'height: 50%'"
                ></TableRow>
                <!-- ./INTERNAL NOTES -->
              </TableForm>
            </template>
            <template #4>
              <TableForm>
                <RowContainer heading="Finance">
                  <!-- PAYMENT TERMS -->
                  <TableRow
                    v-model="local.paymentTerms"
                    label="Payment Terms"
                    :rules="{ length: { max: 20 } }"
                    type="text"
                    :read-only="!editable"
                    :indent-level="1"
                  ></TableRow>
                  <!-- ./PAYMENT TERMS -->

                  <!-- CURRENCY -->
                  <TableRow
                    v-model="local.currency"
                    label="Currency"
                    :options="currencies"
                    :indent-level="1"
                    :read-only="!editable"
                    type="select"
                  ></TableRow>
                  <!-- ./CURRENCY -->

                  <!--MINIMUM ORDER VALUE -->
                  <TableRow
                    v-model="local.minOrderValue"
                    :label="minOrderLabel"
                    :indent-level="1"
                    :read-only="!editable"
                    type="text"
                  ></TableRow>
                  <!-- ./MINIMUM ORDER VALUE  -->

                  <!-- DELIVERY CHARGE -->
                  <TableRow
                    v-model="local.deliveryCharge"
                    :label="deliveryChargeLabel"
                    :indent-level="1"
                    :read-only="!editable"
                    type="text"
                  ></TableRow>
                  <!-- ./DELIVERY CHARGE -->
                </RowContainer>
              </TableForm>
            </template>
          </TablePanel>

          <TablePanel header="Contacts">
            <template #1>
              <TableForm>
                <RowContainer
                  v-for="(contact, index) in local.contacts"
                  :key="'contact' + index"
                  :heading="contact.name || 'Contact'"
                  :colspan="2"
                  :indent-level="1"
                >
                  <!-- CONTACT NAME -->
                  <TableRow
                    v-model="contact.name"
                    label="Name"
                    type="text"
                    :indent-level="2"
                    :read-only="!editable"
                  >
                  </TableRow>
                  <!-- CONTACT NAME -->

                  <!-- CONTACT EMAIL -->
                  <TableRow
                    v-model="contact.email"
                    label="Email"
                    type="text"
                    :indent-level="2"
                    :read-only="!editable"
                  ></TableRow>
                  <!-- CONTACT EMAIL -->

                  <!-- CONTACT PHONE -->
                  <TableRow
                    v-model="contact.phone"
                    label="Phone"
                    type="text"
                    :indent-level="2"
                    :read-only="!editable"
                  ></TableRow>
                  <!-- CONTACT PHONE -->

                  <!-- CONTACT NOTES -->
                  <TableRow
                    v-model="contact.notes"
                    label="Notes"
                    type="text"
                    :indent-level="2"
                    :read-only="!editable"
                  ></TableRow>
                  <!-- CONTACT NOTES -->
                </RowContainer>
              </TableForm>
              <div v-if="local.contacts.length == 0" class="row">
                <div class="col-12 d-flex justify-content-center">
                  <p class="m-0">
                    No contacts have been added for this supplier.
                  </p>
                </div>
              </div>
              <div v-if="editable" class="row">
                <div class="col-12 d-flex justify-content-center tbl-cell-btn">
                  <button type="button" class="btn" @click="addContactRow()">
                    <font-awesome-icon
                      :icon="['fas', 'circle-plus']"
                    ></font-awesome-icon>
                    <b>Add contact</b>
                  </button>
                </div>
              </div>
            </template>
          </TablePanel>

          <TablePanel v-if="supplierId" header="Skus">
            <template #1>
              <TableGrid
                id-key="tenantSkuId"
                :rows="skus"
                :cols="skuCols"
                :limit="skusLimit"
                :total-count="totalCount"
                :filter-function="getSkus"
                :default-sort="defaultSort"
                scrollable
              ></TableGrid>
              <div v-if="editable" class="row">
                <div class="col-12 d-flex justify-content-center tbl-cell-btn">
                  <button type="button" class="btn" @click="addSku()">
                    <font-awesome-icon
                      :icon="['fas', 'circle-plus']"
                    ></font-awesome-icon>
                    <b>Add sku</b>
                  </button>
                </div>
              </div>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
      <!-- SUPPLIER -->

      <!-- ADDRESSES -->
      <template v-if="activeTab === 'Addresses'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableForm>
                <!-- SUPPLIER ADDRESSES -->
                <SupplierAddress
                  ref="supplierAddress"
                  :address-data="local.addresses"
                  :editable="editable"
                ></SupplierAddress>
                <!-- SUPPLIER ADDRESSES -->
              </TableForm>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
      <!-- ADDRESSES -->

      <!-- FILES -->
      <template v-if="activeTab === 'Files'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableForm>
                <TableFileUpload
                  :id="supplierId"
                  :files="local.files"
                  table="tenantSupplier"
                  :update-files-list="updateFilesList"
                  :editable="editable"
                ></TableFileUpload>
              </TableForm>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
      <!-- ./FILES -->

      <!-- LOGS -->
      <template v-if="activeTab === 'Logs'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableForm>
                <CrmLog
                  ref="crmLogComponent"
                  :crm-log="crmLog"
                  :editable="editable"
                ></CrmLog>
              </TableForm>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
      <!-- ./LOGS -->

      <!-- HISTORY -->
      <template v-if="activeTab === 'History'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableGrid
                :cols="auditHistoryCols"
                :rows="historySupplierRows"
                :total-count="totalHistorySupplierCount"
                :filter-function="(args) => setHistoryRows(args.skip)"
                caption="Supplier History"
                scrollable
              ></TableGrid>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
      <!-- HISTORY -->
    </TableTabs>

    <!-- MODALS -->
    <DeleteConfirmModal
      :confirm="deleteSupplier"
      :close="closeModal"
      :show-warning="true"
    ></DeleteConfirmModal>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    ></ConfirmLeaveFormModal>
    <!-- ./MODALS -->
  </ValidationProvider>
</template>

<script setup>
import {
  ref,
  computed,
  nextTick,
  inject,
  watch,
  watchEffect,
  onUpdated
} from 'vue'

import { useRouter } from 'vue-router'
const router = useRouter()
import useApi from '@/components/useApi'
const api = useApi()
import useCountries from '@/composables/selectData/useCountries.js'
const { getCountriesForSelect } = useCountries()
const countries = getCountriesForSelect()
import useCurrencies from '@/composables/selectData/useCurrencies'
const { getCurrenciesForSelect } = useCurrencies()
const currencies = getCurrenciesForSelect()
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: [Number, String],
    default: ''
  }
})

const editable = computed(() => {
  return local.value.approvedAt == null && local.value.archivedAt == null
})

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const {
  DATE_INPUT_FORMAT_STRING,
  formatDateForSave,
  formatDateInput,
  formatDate,
  formatDateTime,
  UTC_OFFSET
} = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

// Object that contains all the data
const local = ref({
  contacts: [],
  addresses: []
})

// Displays content once it's fetched from the DB

const supplierId = ref(false)
const supplierAddress = ref(null)

/**
 * Formats data returned from api
 */
const handleResponse = (data) => {
  let supplier = data.supplier
  let contacts = data.contacts || []
  let addresses = data.addresses || []
  let files = data.files || []
  crmLog.value = data.logs || []

  if (contacts.length === 0) {
    contacts = []
  }

  local.value = {
    tenantSupplierId: supplierId.value,
    name: supplier.name,
    category: supplier.category,
    accountNumber: supplier.accountNumber,
    paymentTerms: supplier.paymentTerms,
    tags: supplier.tags,
    deliveryCharge: supplier.deliveryCharge,
    minOrderValue: supplier.minOrderValue,
    website: supplier.website,
    managedBy: supplier.managedBy,
    currency: supplier.currency,
    comments: supplier.comments,
    internalNotes: supplier.internalNotes,
    addressMainCountry: supplier.addressMainCountry,
    addressWarehouseCountry: supplier.addressWarehouseCountry,
    contacts: contacts,
    addresses: addresses,
    files: files,
    followUpDate: formatDateInput(supplier.followUpDate),
    createdAt: formatDateTime(supplier.createdAt, utcOffset.value),
    createdBy: supplier.createdBy,
    updatedAt: formatDateTime(supplier.updatedAt, utcOffset.value),
    updatedBy: supplier.updatedBy,
    archivedAt: formatDateTime(supplier.archivedAt, utcOffset.value),
    archivedBy: supplier.archivedBy
  }

  remote.value = JSON.parse(JSON.stringify(local.value))
}

/*
 *****************      VALIDATION PROVIDER
 */

// Template ref to validation provider component
const validation = ref(null)
/*
 *****************      MODALS
 */
import DeleteConfirmModal from '@/components/modals/DeleteConfirmModal'
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'

const modal = ref(null)
const confirmLeaveModal = ref(null)
const confirmModalNext = ref(null)

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
  confirmModalNext()
}

const closeConfirmModal = () => {
  confirmModalNext.value = null
  confirmLeaveModal.value.hide()
}

const openModal = () => {
  modal.value.show()
}

const closeModal = () => {
  modal.value.hide()
}

/**
 *****************      TABLE FORM
 */
import TableFileUpload from '@/components/table/form/TableFileUpload'
import CrmLog from '@/components/CrmLog'
import SupplierAddress from '@/components/content/suppliers/SupplierAddress'
import TableForm from '@/components/table/form/TableForm'
import RowContainer from '@/components/table/form/RowContainer'
import TableRow from '@/components/table/form/TableRow'

const crmLogComponent = ref(null)

const tabs = ref(['Supplier', 'Files', 'Logs', 'Addresses', 'History'])

const remote = ref({})
const saving = ref(false)
const skusLimit = ref(10)
const crmLog = ref([])
const skus = ref([])
const defaultSort = ref({ brandName: 1 })
const totalCount = ref(0)
const skuCols = ref([
  {
    label: 'SKU',
    name: 'sku',
    filter: true,
    visible: true,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true
  },
  {
    label: 'Supplier SKU',
    name: 'supplierSku',
    visible: true
  },
  {
    label: 'Brand',
    name: 'brandName',
    filter: true,
    visible: true
  },
  {
    label: 'Title',
    name: 'title',
    filter: true,
    visible: true
  },
  {
    label: 'Variants',
    name: 'variantValues',
    visible: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    filter: true,
    visible: true
  },
  {
    label: 'Cost price',
    name: 'costPrice',
    number: true,
    visible: true
  },
  {
    label: 'Min qty',
    name: 'minOrderQty',
    visible: true
  }
])

const statusOptions = ref([
  { label: 'Active', value: 'Active' },
  { label: 'Onboarding', value: 'Onboarding' },
  { label: 'Potential', value: 'Potential' },
  { label: 'No Amazon', value: 'No Amazon' },
  { label: 'Rejected', value: 'Rejected' }
])

// Tried to do this by exposing active tab in the TableTab
// but couldn't get it to work, so I've done it this hacky way
// instead
const selectedTab = ref()
const tabManager = ref(null)
watchEffect(() => {
  if (tabManager.value) {
    selectedTab.value = tabManager.value.tab
  }
})

const minOrderLabel = computed(() => {
  if (local.value.currency)
    return `Minimum order value (${local.value.currency})`
  else return 'Minimum order value'
})

const deliveryChargeLabel = computed(() => {
  if (local.value.currency) return `Delivery charge (${local.value.currency})`
  else return 'Delivery charge'
})

const getSkus = async (args = {}) => {
  args.tenantSupplierId = supplierId.value
  args.supplier = 1
  args.limit = skusLimit.value

  let response = await api.get('/skus', args)
  skus.value = setVariantValues(response.data.skus, response.data.variantValues)
  totalCount.value = parseInt(response.data.count)
}

const setVariantValues = (skus, variantValues) => {
  let index = 0
  let length = skus.length

  for (index; index < length; index++) {
    let sku = skus[index]
    let tenantSkuId = sku.tenantSkuId
    let skuVariantValues = _.filter(variantValues, { tenantSkuId })

    if (skuVariantValues.length === 0) {
      continue
    }

    let variant1 = skuVariantValues[0]
    let variant2 = skuVariantValues[1]

    if (variant1) {
      sku.variantValues = variant1.en
    }

    if (variant2) {
      if (sku.variantValues) {
        sku.variantValues += ', ' + variant2.en
      } else {
        sku.variantValues = variant2.en
      }
    }
  }
  return skus
}

const deleteSupplier = async () => {
  modal.value.hide()
  // await api.delete('/suppliers/' + supplierId.value)
  gotoSuppliers()
}

const addContactRow = () => {
  local.value.contacts.push({})
}

const addSku = () => {
  router.push('/sku/new')
}

const getSupplierId = () => {
  supplierId.value = false
  if (props.id && parseInt(props.id) > 0) {
    supplierId.value = props.id
  }
}

const getSupplier = async () => {
  if (!supplierId.value || supplierId.value === 'new') {
    return
  }

  let response = await api.get('/suppliers/' + supplierId.value)
  handleResponse(response.data)
}

const update = async () => {
  local.value.addresses = supplierAddress.value.getAddressesForSave()
  local.value.logs = crmLogComponent.value.getLogsForSave()

  if (local.value.followUpDate) {
    local.value.followUpDate = formatDateForSave(local.value.followUpDate)
  }
  if (!supplierId.value) {
    insert()
    return
  }

  let response = await api.put(
    '/suppliers/' + supplierId.value,
    {
      supplier: local.value
    },
    saving
  )
  handleResponse(response.data)
  validation.value.save()
}

const insert = async () => {
  let response = await api.post(
    '/suppliers/',
    { supplier: local.value },
    saving
  )
  supplierId.value = String(response.data.insertedId)
  router.replace('/suppliers/' + supplierId.value)
  handleResponse(response.data)
  setTitle()
  validation.value.save()
}

const updateFilesList = (files = []) => {
  local.value.files = files
}

const removeEmptyContacts = () => {
  local.value.contacts.forEach((contact) => {
    let validName =
      typeof contact.name === 'string' && contact.name.trim().length > 0
    let validPhone =
      typeof contact.phone === 'string' && contact.phone.trim().length > 0
    let validEmail =
      typeof contact.email === 'string' && contact.email.trim().length > 0
    if (validName && validPhone && validEmail) return
    local.value.contacts.splice(i, 1)
  })
}

const gotoSuppliers = () => {
  router.push('/suppliers/')
}

const unarchiveSupplier = async () => {
  let response = await api.put(
    '/suppliers/' + supplierId.value,
    {
      unarchive: 1,
      tenantSupplierId: supplierId.value
    },
    saving
  )
  let supplier = response.data.supplier
  updateSupplier(supplier)
}

const archiveSupplier = async () => {
  let response = await api.put(
    '/suppliers/' + supplierId.value,
    {
      archive: 1,
      tenantSupplierId: supplierId.value
    },
    saving
  )
  let supplier = response.data.supplier
  updateSupplier(supplier)
}

const bulkUpdatePricing = () => {
  router.push(`/bulk-update-pricing/?supplierId=${supplierId.value}`)
}

const updateSupplier = (supplier) => {
  ;(supplier.createdAt = formatDateTime(supplier.createdAt, utcOffset.value)),
    (supplier.approvedAt = formatDateTime(
      supplier.approvedAt,
      utcOffset.value
    )),
    (supplier.updatedAt = formatDateTime(supplier.updatedAt, utcOffset.value)),
    (supplier.archivedAt = formatDateTime(
      supplier.archivedAt,
      utcOffset.value
    )),
    (supplier.paidAt = formatDateTime(supplier.paidAt, utcOffset.value)),
    (supplier.followUpDate = formatDate(
      supplier.followUpDate,
      utcOffset.value
    )),
    (local.value = Object.assign({}, local.value, supplier))
  remote.value = Object.assign({}, remote.value, supplier)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  },
  {
    id: 2,
    label: 'Unarchive',
    click: unarchiveSupplier,
    icon: ['fas', 'arrow-rotate-left']
  },
  {
    id: 3,
    label: 'Archive',
    click: archiveSupplier,
    icon: ['fas', 'box-archive']
  },
  {
    id: 4,
    label: 'Bulk update pricing',
    click: bulkUpdatePricing,
    icon: ['fas', 'clipboard-list']
  }
])

/*
  HISTORY
*/
watch(selectedTab, async (current, previous) => {
  if (current == 'History' && !loadedHistory.value) {
    await getHistoryRows()
    loadedHistory.value = true
  }
})

/**
 * Calucaltes the number of audit history records
 */
const totalHistorySupplierCount = computed(() => {
  return supplierAudit.value.length
})
const loadedHistory = ref(false)
const historySupplierRows = ref([])
const supplierAudit = ref([])
const supplierAuditCols = ref([
  {
    label: 'Name',
    name: 'name'
  },
  {
    label: 'Status',
    name: 'status'
  },
  {
    label: 'Currency',
    name: 'currency'
  },
  {
    label: 'Account Number',
    name: 'accountNumber'
  },
  {
    label: 'Category',
    name: 'category'
  },
  {
    label: 'Address Main Country',
    name: 'addressMainCountry'
  },
  {
    label: 'Address Warehouse Country',
    name: 'addressWarehouseCountry'
  },
  {
    label: 'Payment Terms',
    name: 'paymentTerms'
  },
  {
    label: 'Website',
    name: 'website'
  },
  {
    label: 'Tags',
    name: 'tags'
  },
  {
    label: 'Minimum Order Value',
    name: 'minOrderValue'
  },
  {
    label: 'Delivery Charge',
    name: 'deliveryCharge'
  },
  {
    label: 'Follow Up Date',
    name: 'followUpDate'
  },
  {
    label: 'Managed By',
    name: 'managedBy'
  }
])
const supplierAddressAuditCols = ref([
  {
    label: 'Name',
    name: 'name'
  },
  {
    label: 'Address Type',
    name: 'addressType'
  },
  {
    label: 'Building Type',
    name: 'buildingType'
  },
  {
    label: 'Country',
    name: 'country'
  },
  {
    label: 'Address Line 1',
    name: 'address1'
  },
  {
    label: 'Address Line 2',
    name: 'address2'
  },
  {
    label: 'Address Line 3',
    name: 'address3'
  },
  {
    label: 'Address Postcode',
    name: 'addressPostcode'
  }
])
const supplierContactAuditCols = ref([
  {
    label: 'Name',
    name: 'name'
  },
  {
    label: 'Email',
    name: 'email'
  },
  {
    label: 'Phone',
    name: 'phone'
  }
])

import { useAudit } from '@/composables/useAudit'
const {
  auditHistoryCols,
  mergeAudit,
  sortAudit,
  formatAuditDates,
  processAudit
} = useAudit()

const getHistoryRows = async (args = {}) => {
  let responseSupplierAudit = await api.get('/audit/tenantSupplier', {
    ...args,
    params: {
      tenantSupplerId: supplierId.value
    }
  })
  let responseSupplierAddressAudit = await api.get(
    '/audit/tenantSupplierAddress',
    {
      ...args,
      params: {
        tenantSupplierId: supplierId.value
      }
    }
  )
  let responseSupplierContactAudit = await api.get(
    '/audit/tenantSupplierContact',
    {
      ...args,
      params: {
        tenantSupplierId: supplierId.value
      }
    }
  )
  let auditRecords = mergeAudit([
    responseSupplierAudit.data.data,
    responseSupplierAddressAudit.data.data,
    responseSupplierContactAudit.data.data
  ])
  let auditSorted = sortAudit(auditRecords)
  supplierAudit.value = processAudit(
    {
      Supplier: 'tenantSupplierId',
      'Supplier Address': 'tenantSupplierAddressId',
      'Supplier Contact': 'tenantSupplierContactId'
    },
    {
      Supplier: supplierAuditCols.value,
      'Supplier Address': supplierAddressAuditCols.value,
      'Supplier Contact': supplierContactAuditCols.value
    },
    auditSorted
  )
  setHistoryRows()
}

/**
 *
 */
const setHistoryRows = (paginationSkip = 0) => {
  let paginationEnd = paginationSkip + 20
  if (paginationEnd > supplierAudit.value.length) {
    paginationEnd = supplierAudit.value.length
  }
  let rows = supplierAudit.value.slice(paginationSkip, paginationEnd)
  rows = JSON.parse(JSON.stringify(rows))
  let formatted = formatAuditDates(rows)

  historySupplierRows.value = formatted
}

const setTitle = () => {
  let pageTitle = local.value.name || 'Create supplier'
  document.getElementById('pagetitle').innerHTML = pageTitle
  document.title = pageTitle
}

const getData = async () => {
  loading()
  local.value = { contacts: [] }

  try {
    progressBarStart()
    getSupplierId()
    await getSupplier()
    await getSkus()
    setTitle()
    loaded()
    progressBarDone()
  } catch (e) {
    progressBarFail()
    router.back()
  }
}

getData()

const routeRefreshOnMounted = ref(false)
onUpdated(() => {
  if (
    routeRefreshOnMounted.value &&
    router.currentRoute.value.params.id == 'new'
  ) {
    router.go()
  }
  routeRefreshOnMounted.value = true
})
</script>
