<template>
  <BraidModal ref="modal">
    <template #title> Receive Stock - {{ purchaseOrderItem.title }}</template>
    <template #body>
      <div v-if="locationWarning" class="warning-notification container">
        <div class="row">
          <div class="col-12">
            <b>Location must be set to complete the check-in.</b>
          </div>
        </div>
      </div>
      <TableContainer>
        <TablePanel>
          <template #1>
            <TableForm>
              <TableRow
                v-model="purchaseOrderItem.sku"
                label="SKU"
                type="text"
                read-only
              ></TableRow>
              <TableRow
                v-model="purchaseOrderItem.title"
                label="Title"
                type="text"
                read-only
              ></TableRow>
            </TableForm>
          </template>
        </TablePanel>

        <TablePanel
          v-if="availableQuantity > 0 && !saveClicked"
          :cols="3"
          :width="4"
        >
          <template #1>
            <span>Stock before:</span>
            <TableForm>
              <TableRow
                v-model="purchaseOrderItem.quantityOrdered"
                label="Quantity Ordered"
                type="number"
                read-only
              ></TableRow>
              <TableRow
                v-model="purchaseOrderItem.quantityReceived"
                label="Quantity Received"
                type="number"
                read-only
              ></TableRow>
              <TableRow
                v-model="purchaseOrderItem.quantityCancelled"
                label="Quantity Cancelled"
                type="number"
                read-only
              ></TableRow>
            </TableForm>
          </template>
          <template #2>
            <div class="col-12 d-flex justify-content-center">
              <div class="form-check radio-input me-3" @click="action = 0">
                <input
                  id="flexRadioDefault1"
                  class="form-check-input me-2"
                  type="radio"
                  name="flexRadioDefault"
                  :checked="action == 0"
                />
                <label class="form-check-label" for="flexRadioDefault1">
                  Check in stock
                </label>
              </div>
              <div class="form-check radio-input" @click="action = 1">
                <input
                  id="flexRadioDefault2"
                  class="form-check-input me-2"
                  type="radio"
                  name="flexRadioDefault"
                  :checked="action == 1"
                />
                <label class="form-check-label" for="flexRadioDefault2">
                  Cancel stock
                </label>
              </div>
            </div>
            <TableForm>
              <!-- QUANTITY -->
              <TableRow label="Quantity" type="custom">
                <input
                  ref="action-input"
                  class="table-number-input"
                  type="number"
                  :min="0"
                  :max="availableQuantity"
                  :value="actionQuantity"
                  @input="updateActionQuantity($event.target.value)"
                />
              </TableRow>
              <!-- QUANTITY -->

              <template v-if="action == 0">
                <div class="col-12 mb-3"></div>

                <!-- LOCATION -->
                <TableRow
                  v-model="local.locationId"
                  label="Location"
                  type="select"
                  :options="locations"
                  :reduce="(location) => location.tenantLocationId"
                  display="name"
                ></TableRow>
                <!-- LOCATION -->

                <!-- SHELF / BIN -->
                <TableRow
                  v-model="local.bin"
                  label="Shelf / Bin"
                  type="text"
                  :read-only="readOnly"
                ></TableRow>
                <!-- SHELF / BIN -->

                <!-- EXPIRY DATE -->
                <TableRow
                  v-model="local.expiryDate"
                  label="Expiry Date"
                  type="date"
                  :read-only="readOnly"
                ></TableRow>
                <!-- EXPIRY DATE -->

                <!-- BATCH CODE -->
                <TableRow
                  v-model="local.batchCode"
                  label="Batch Code"
                  type="text"
                  :read-only="readOnly"
                ></TableRow>
                <!-- BATCH CODE -->

                <!-- SERIAL NUMBER -->
                <TableRow
                  v-model="local.serialNumber"
                  label="Serial Number"
                  type="text"
                  :read-only="readOnly || actionQuantity != 1"
                ></TableRow>
                <!-- SERIAL NUMBER -->
              </template>
            </TableForm>
            <button
              class="btn"
              :disabled="checkDisabled"
              @click="performAction"
            >
              <span>
                <font-awesome-icon :icon="['fas', 'arrow-right']">
                </font-awesome-icon>
                <p class="p-0">{{ actionBtnText }}</p>
              </span>
            </button>
          </template>
          <template #3>
            <span>Stock after:</span>
            <TableForm>
              <TableRow
                v-model="quantityAfterAction.quantityOrdered"
                label="Quantity Ordered"
                type="number"
                read-only
              ></TableRow>
              <TableRow
                v-model="quantityAfterAction.quantityReceived"
                label="Quantity Received"
                type="number"
                read-only
              ></TableRow>
              <TableRow
                v-model="quantityAfterAction.quantityCancelled"
                label="Quantity Cancelled"
                type="number"
                read-only
              ></TableRow>
            </TableForm>
          </template>
        </TablePanel>

        <TablePanel v-else-if="!saveClicked">
          <template #1>
            <p>All incoming stock of the this SKU has been handled.</p>
            <TableForm>
              <TableRow
                v-model="purchaseOrderItem.quantityOrdered"
                label="Quantity Ordered"
                type="number"
                read-only
              ></TableRow>
              <TableRow
                v-model="purchaseOrderItem.quantityReceived"
                label="Quantity Received"
                type="number"
                read-only
              ></TableRow>
              <TableRow
                v-model="purchaseOrderItem.quantityCancelled"
                label="Quantity Cancelled"
                type="number"
                read-only
              ></TableRow>
            </TableForm>
          </template>
        </TablePanel>

        <TablePanel>
          <template #1>
            <TableGrid
              ref="grid"
              :cols="inventoryLocationCols"
              :rows="inventoryLocationRows"
              :total-count="inventoryLocationCount"
            ></TableGrid>
          </template>
        </TablePanel>
        <TablePanel>
          <template #1>
            <TableGrid
              :cols="poItemLogCols"
              :rows="poItemLogRows"
              :total-count="poItemLogCount"
            ></TableGrid>
          </template>
        </TablePanel>
      </TableContainer>
    </template>
    <template #footer>
      <button id="rejectButton" class="btn btn-red" @click="hide">Close</button>
      <button id="confirmButton" class="btn btn-green" @click="receiveStock">
        Save
      </button>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref, computed, watch } from 'vue'

import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'
import RowContainer from '@/components/table/form/RowContainer'

import useApi from '@/components/useApi'
const api = useApi()
const {
  formatDate,
  formatDateTime,
  formatDateTimeNow,
  formatDateForSave,
  formatDateTimeForSave,
  UTC_OFFSET
} = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())
const locationWarning = ref(false)

const emit = defineEmits(['confirm'])
const props = defineProps({
  poNumber: {
    type: String,
    default: ''
  },
  defaultLocationId: {
    type: String,
    default: ''
  },
  locations: {
    type: Array,
    default() {
      return []
    }
  },
  readOnly: {
    type: Boolean,
    default: false
  }
})

const modal = ref(null)
const local = ref({
  locationId: null,
  bin: '',
  checkInDate: '',
  expiryDate: '',
  batchCode: ''
})
const purchaseOrderItem = ref({
  quantityOrdered: 0,
  quantityReceived: 0,
  quantityCancelled: 0
})
const purchaseOrderItemInventory = ref([])

const action = ref(null)
const actionQuantity = ref(null)
const receiveStockActions = ref([])
const actionBtnText = ref(null)

const show = (item, inventory) => {
  saveClicked.value = false
  setItem(item)
  setInventory(inventory)
  setDefaultLocal()
  getPoItemLogs()
  clearActions()
  modal.value.show()
}

const hide = () => {
  setDefaultLocal()
  clearActions()
  modal.value.hide()
}

const setItem = (item) => {
  purchaseOrderItem.value = item
}

const setInventory = (inventory) => {
  purchaseOrderItemInventory.value = inventory
}

const setDefaultLocal = () => {
  local.value = {
    locationId: null,
    bin: '',
    checkInDate: '',
    expiryDate: '',
    batchCode: ''
  }
  action.value = 0
  actionQuantity.value = 0
}

const clearActions = () => {
  receiveStockActions.value = []

  quantityAfterAction.value.quantityOrdered = 0
  quantityAfterAction.value.quantityReceived = 0
  quantityAfterAction.value.quantityCancelled = 0
}

const getPoItemLogs = async () => {
  let response = await api.get('/purchaseOrderItems/getLogs', {
    purchaseOrderItemId: purchaseOrderItem.value.tenantPurchaseOrderItemId
  })

  poItemLogCount.value = response.data.count
  handleLogsResponse(response.data.logs)
}

const handleLogsResponse = (data) => {
  data.forEach((record) => {
    record.checkInDate = formatDateTime(record.checkInDate, utcOffset.value)
    record.expiryDate = formatDate(record.expiryDate)
  })
  poItemLogRows.value = data
}

const deletePoItemLog = (row, deleteFlag) => {
  row.delete = deleteFlag
}

const checkPoItemLogDeleted = (row) => {
  return row.delete
}
defineExpose({ show })

/**
 * GRID
 */
const inventoryLocationCols = [
  {
    label: 'Location Name',
    name: 'name',
    visible: true
  },
  {
    label: 'Quantity',
    name: 'quantity',
    visible: true
  },
  {
    label: 'Shelf / Bin',
    name: 'bin',
    visible: true
  }
]

const inventoryLocationRows = computed(() => {
  if (!props.locations || Object.keys(purchaseOrderItem.value).length == 0) {
    return []
  }
  let rows = purchaseOrderItemInventory.value.map((inventory) => {
    let location = props.locations.find(
      (location) => inventory.locationId == location.tenantLocationId
    )

    return {
      locationId: location.tenantLocationId,
      name: location.name,
      quantity: inventory.quantity,
      bin: inventory.bin
    }
  })

  return rows
})

const locationDisplayValues = computed(() => {
  let obj = {}
  props.locations.forEach((location) => {
    obj[location.tenantLocationId] = location.name
  })
  return obj
})

const inventoryLocationCount = computed(() => {
  return inventoryLocationRows.value.length
})

const poItemLogCols = [
  {
    label: 'Location Name',
    name: 'locationId',
    visible: true,
    multiple: true,
    displayValues: locationDisplayValues.value
  },
  {
    label: 'Quantity',
    name: 'quantityReceived',
    visible: true
  },
  {
    label: 'Expiry Date',
    name: 'expiryDate',
    visible: true
  },
  {
    label: 'Batch Code',
    name: 'batchCode',
    visible: true
  },
  {
    label: 'Serial Number',
    name: 'serialNumber',
    visible: true
  },
  {
    label: 'Check-in Date',
    name: 'checkInDate',
    visible: true
  }
]

const poItemLogRows = ref([])
const poItemLogCount = ref(0)

const availableQuantity = computed(() => {
  if (Object.keys(purchaseOrderItem.value).length == 0) {
    return 0
  }

  return (
    purchaseOrderItem.value.quantityOrdered -
    purchaseOrderItem.value.quantityReceived -
    purchaseOrderItem.value.quantityCancelled
  )
})

const quantityAfterAction = ref({
  quantityOrdered: 0,
  quantityReceived: 0,
  quantityCancelled: 0
})

const updateActionQuantity = (value) => {
  actionQuantity.value = value
}

watch(actionQuantity, (current) => {
  current = parseInt(current)

  let ordered = parseInt(purchaseOrderItem.value.quantityOrdered) || 0
  let received = parseInt(purchaseOrderItem.value.quantityReceived) || 0
  let cancelled = parseInt(purchaseOrderItem.value.quantityCancelled) || 0

  switch (action.value) {
    case 0:
      received += current
      break
    case 1:
      cancelled += current
      break
  }

  quantityAfterAction.value = {
    quantityOrdered: ordered,
    quantityReceived: received,
    quantityCancelled: cancelled
  }
})

const receiveStock = async () => {
  let response = performAction()

  if (!response) {
    return
  }

  saveClicked.value = true

  let receivedRecords = receiveStockActions.value.filter(
    (action) => action.action == 0
  )
  let cancelledRecords = receiveStockActions.value.filter(
    (action) => action.action == 1
  )

  await createReceivedRecords(receivedRecords)
  await createCancelledRecords(cancelledRecords)
  emit('confirm')
  hide()
}

const createReceivedRecords = async (data) => {
  if (data.length == 0) {
    return
  }
  let records = data.map((record) => record.data)

  return await updateSkuInventory(records)
}

const createCancelledRecords = async (data) => {
  if (data.length == 0) {
    return
  }
  return await api.put(
    '/purchaseOrderItems/' + purchaseOrderItem.value.tenantPurchaseOrderItemId,
    {
      purchaseOrderItem: purchaseOrderItem.value
    }
  )
}

const updateSkuInventory = async (records) => {
  records.forEach((data) => {
    data.checkInDate = formatDateTimeForSave(data.checkInDate, utcOffset.value)
    data.expiryDate = formatDateForSave(data.expiryDate)
  })

  return await api.post('/skuInventory', {
    purchaseOrderId: purchaseOrderItem.value.tenantPurchaseOrderItemId,
    skuInventory: records,
    purchaseOrderItem: purchaseOrderItem.value
  })
}

const checkDisabled = computed(() => {
  switch (action.value) {
    case 0:
      if (local.value.locationId == null) {
        return true
      }
    case 1:
    default:
      return false
  }
})

const performAction = () => {
  switch (action.value) {
    case 0:
      return checkIn()
    case 1:
      return cancelStock()
  }
}

const saveClicked = ref(false)

const checkValidActions = () => {
  if (receiveStockActions.value.length > 0) {
    return true
  }

  locationWarning.value = true
  return false
}

const checkIn = () => {
  if (!local.value.locationId) {
    return checkValidActions()
  }
  let dateNow = formatDateTimeNow()
  receiveStockActions.value.push({
    action: 0,
    data: {
      ...local.value,
      skuId: purchaseOrderItem.value.tenantSkuId,
      tenantPurchaseOrderItemId:
        purchaseOrderItem.value.tenantPurchaseOrderItemId,
      checkInDate: dateNow,
      quantity: actionQuantity.value
    }
  })
  purchaseOrderItem.value.quantityReceived += parseInt(actionQuantity.value)
  let index = purchaseOrderItemInventory.value.findIndex(
    (inventory) => inventory.locationId == local.value.locationId
  )

  if (index == -1) {
    purchaseOrderItemInventory.value.push({
      locationId: local.value.locationId,
      bin: local.value.bin,
      quantity: actionQuantity.value
    })
  } else {
    purchaseOrderItemInventory.value[index].quantity =
      parseInt(actionQuantity.value) +
      parseInt(purchaseOrderItemInventory.value[index].quantity)
  }
  setDefaultLocal()
  return true
}

const cancelStock = () => {
  receiveStockActions.value.push({
    action: 1,
    data: {
      quantity: actionQuantity
    }
  })
  purchaseOrderItem.value.quantityCancelled += parseInt(actionQuantity.value)
  setDefaultLocal()
  return true
}

watch(
  () => local.value.locationId,
  () => {
    if (local.value.locationId == null) {
      return
    }
    let index = inventoryLocationRows.value.findIndex(
      (inventory) => inventory.locationId == local.value.locationId
    )

    if (index == -1) {
      local.value.bin = ''
    } else {
      local.value.bin = inventoryLocationRows.value[index].bin
    }
  }
)

watch(
  () => action.value,
  () => {
    switch (action.value) {
      case 0:
        actionBtnText.value = 'Create another check-in'
        return
      case 1:
        actionBtnText.value = 'Cancel stock'
        return
    }
  }
)
</script>

<style scoped lang="scss">
.radio-input {
  cursor: pointer;
  display: flex;
  align-items: center;

  label,
  input {
    cursor: pointer;
  }
}

.warning-notification {
  background-color: #ec7063;
  border: 1px solid #e74c3c;
  border-radius: 3px;
}
</style>
