<template>
  <ValidationProvider ref="validation" v-slot="{ errors, changes }">
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: {
          show: local.archivedAt == null,
          disable: !changes || saving || errors
        },
        3: { disable: changes && !errors, show: id != 'new' },
        4: { show: local.archivedAt != null && selectedTab === 'Order' },
        5: { show: local.archivedAt == null && !changes && !saving }
      }"
    ></ActionBar>

    <TableTabs ref="tabManager" v-slot="{ activeTab }" :tabs="tabs">
      <template v-if="activeTab === 'Order'">
        <TableContainer>
          <TablePanel :cols="2" :width="4">
            <template #1>
              <TableForm>
                <template v-if="local.channelOrderId">
                  <!-- ORDER NUMBER -->
                  <TableRow
                    v-model="local.channelOrderId"
                    label="Order Number"
                    read-only
                    type="text"
                  ></TableRow>
                  <!-- ./ORDER NUMBER -->

                  <!-- CHANNEL -->
                  <TableRow
                    v-model="local.channelName"
                    label="Channel"
                    read-only
                    type="text"
                  ></TableRow>
                  <!-- ./CHANNEL -->
                </template>

                <!-- ORDER NUMBER -->
                <TableRow
                  v-model="local.orderNumber"
                  label="Order number"
                  read-only
                  type="text"
                ></TableRow>
                <!-- ./ORDER NUMBER -->

                <!-- DISPATCH LOCATION -->
                <TableRow
                  v-model="local.dispatchLocationId"
                  label="Dispatch location"
                  :options="dispatchLocationOptions"
                  no-options="Type to search for a location"
                  :read-only="remote.tenantChannelId != null"
                  type="select"
                ></TableRow>
                <!-- ./DISPATCH LOCATION -->

                <!-- STATUS -->
                <TableRow
                  v-model="local.status"
                  label="Status"
                  :options="[
                    { value: 0, label: 'Draft' },
                    { value: 1, label: 'Unfulfilled' },
                    { value: 2, label: 'Part-fulfilled' },
                    { value: 3, label: 'Fulfilled' },
                    { value: 4, label: 'Cancelled' }
                  ]"
                  type="select"
                ></TableRow>
                <!-- ./STATUS -->

                <TableRow
                  label="Customer"
                  type="swapButton"
                  :button-text="['Select', 'Custom']"
                >
                  <template #first>
                    <TableText
                      v-model="local.customerName"
                      :read-only="remote.tenantChannelId != null"
                    ></TableText>
                  </template>
                  <template #second>
                    <TableSelect
                      v-model="local.tenantCustomerId"
                      :options="customers"
                      no-options="Type to search for a customer"
                      :reduce="(customer) => customer.tenantCustomerId"
                      display="name"
                      type="select"
                      @search="searchCustomers"
                      @input="updateCustomerName"
                    ></TableSelect>
                  </template>
                </TableRow>

                <TableRow
                  v-if="orderType == 'MERCHANT'"
                  v-model="local.tenantPricingId"
                  label="Price Profile"
                  type="select"
                  :reduce="(option) => option.tenantPricingId"
                  :options="priceProfiles"
                  display="name"
                ></TableRow>

                <TableRow
                  v-model="local.createdAt"
                  label="Raised At"
                  type="text"
                  read-only
                ></TableRow>

                <TableRow
                  v-model="local.createdBy"
                  label="Raised By"
                  type="text"
                  read-only
                ></TableRow>
              </TableForm>
            </template>
            <template #2>
              <TableForm
                ><!-- TODO: Implement -->
                <!-- LATEST DISPATCH DATE -->
                <!-- <TableRow
                v-model="todo"
                label="Latest Dispatch Date"
                type="date"
              ></TableRow> -->
                <!-- ./LATEST DISPATCH DATE -->

                <!-- TODO: Implement -->
                <!-- LATEST DELIVERY DATE -->
                <!-- <TableRow
                v-model="todo"
                label="Latest Delivery Date"
                type="date"
              ></TableRow> -->
                <!-- ./LATEST DELIVERY DATE -->

                <!-- DELIVERY CHARGE -->
                <TableRow
                  v-model="local.deliveryCharge"
                  label="Delivery Charge"
                  read-only
                  type="text"
                ></TableRow>
                <!-- ./DELIVERY CHARGE -->
                <!-- E-MAIL -->
                <TableRow
                  v-model="local.email"
                  label="Email"
                  :read-only="remote.tenantChannelId != null"
                  type="text"
                ></TableRow>
                <!-- ./E-MAIL -->
                <!-- PHONE -->
                <TableRow
                  v-model="local.phone"
                  label="Phone"
                  :read-only="remote.tenantChannelId != null"
                  type="text"
                ></TableRow>
                <!-- ./PHONE -->
              </TableForm>
            </template>
          </TablePanel>

          <TablePanel :cols="1" :width="2">
            <template #1>
              <TableForm :style="'height: 100%'">
                <!-- INTERNAL NOTES -->
                <TableRow
                  v-model="local.internalNotes"
                  label="Notes"
                  type="textarea"
                  :style="'height: 100%'"
                ></TableRow>
                <!-- ./INTERNAL NOTES -->
              </TableForm>
            </template>
          </TablePanel>

          <TablePanel :cols="2" :width="2">
            <template #1>
              <TableForm>
                <RowContainer heading="Shipping Address" :colspan="2">
                  <!-- ADDRESS SELECT -->
                  <TableRow
                    v-if="local.tenantCustomerId"
                    v-model="local.shippingAddressId"
                    label="Shipping address"
                    :options="addressOptions"
                    no-options="Type to search for an address"
                    type="select"
                    @change="updateShippingAddress"
                  ></TableRow>
                  <!-- ./ADDRESS SELECT -->

                  <!-- LINE 1 -->
                  <TableRow
                    v-model="local.shippingAddrLine1"
                    label="Line 1"
                    :read-only="remote.tenantChannelId != null"
                    :indent-level="1"
                    type="text"
                  ></TableRow>
                  <!-- ./LINE 1 -->

                  <!-- LINE 2 -->
                  <TableRow
                    v-model="local.shippingAddrLine2"
                    label="Line 2"
                    :read-only="remote.tenantChannelId != null"
                    :indent-level="1"
                    type="text"
                  ></TableRow>
                  <!-- ./LINE 2 -->

                  <!-- LINE 3 -->
                  <TableRow
                    v-model="local.shippingAddrLine3"
                    label="Line 3"
                    :read-only="remote.tenantChannelId != null"
                    :indent-level="1"
                    type="text"
                  ></TableRow>
                  <!-- ./LINE 3 -->

                  <!-- POSTCODE -->
                  <TableRow
                    v-model="local.shippingAddrPostcode"
                    label="Postcode"
                    :read-only="remote.tenantChannelId != null"
                    :indent-level="1"
                    type="text"
                  ></TableRow>
                  <!-- ./POSTCODE -->

                  <!-- COUNTRY -->
                  <TableRow
                    v-if="remote.tenantChannelId == null"
                    v-model="local.shippingAddrCountry"
                    label="Main Country"
                    :options="countries"
                    :indent-level="1"
                    type="select"
                  ></TableRow>

                  <TableRow
                    v-else
                    v-model="local.shippingAddrCountry"
                    :indent-level="1"
                    label="Country"
                    type="text"
                    read-only
                  ></TableRow>
                  <!-- ./COUNTRY -->
                </RowContainer>
              </TableForm>
            </template>
            <template #2>
              <TableForm>
                <RowContainer heading="Billing Address" :colspan="2">
                  <!-- USE SHIPPING ADDR FOR BILLING -->
                  <TableRow
                    type="custom"
                    :indent-level="1"
                    label="Same as shipping address"
                  >
                    <input
                      v-model="local.useShippingAddrForBilling"
                      type="checkbox"
                      :checked="local.useShippingAddrForBilling"
                    />
                  </TableRow>
                  <!-- ./USE SHIPPING ADDR FOR BILLING -->

                  <!-- ADDRESS SELECT -->
                  <TableRow
                    v-if="
                      !local.useShippingAddrForBilling && local.tenantCustomerId
                    "
                    v-model="local.billingAddressId"
                    label="Billing Address"
                    :options="addressOptions"
                    no-options="Type to search for an address"
                    type="select"
                    :indent-level="1"
                    @change="updateBillingAddress"
                  ></TableRow>
                  <!-- ./ADDRESS SELECT -->

                  <template v-if="!local.useShippingAddrForBilling">
                    <!-- LINE 1 -->
                    <TableRow
                      v-model="local.billingAddrLine1"
                      label="Line 1"
                      :read-only="remote.tenantChannelId != null"
                      :indent-level="1"
                      type="text"
                    ></TableRow>
                    <!-- ./LINE 1 -->

                    <!-- LINE 2 -->
                    <TableRow
                      v-model="local.billingAddrLine2"
                      label="Line 2"
                      :read-only="remote.tenantChannelId != null"
                      :indent-level="1"
                      type="text"
                    ></TableRow>
                    <!-- ./LINE 2 -->

                    <!-- LINE 3 -->
                    <TableRow
                      v-model="local.billingAddrLine3"
                      label="Line 3"
                      :read-only="remote.tenantChannelId != null"
                      :indent-level="1"
                      type="text"
                    ></TableRow>
                    <!-- ./LINE 3 -->

                    <!-- POSTCODE -->
                    <TableRow
                      v-model="local.billingAddrPostcode"
                      label="Postcode"
                      :read-only="remote.tenantChannelId != null"
                      :indent-level="1"
                      type="text"
                    ></TableRow>
                    <!-- ./POSTCODE  -->

                    <!-- COUNTRY -->
                    <TableRow
                      v-if="remote.tenantChannelId == null"
                      v-model="local.billingAddrCountry"
                      label="Billing Address Country"
                      :options="countries"
                      :indent-level="1"
                      type="select"
                    ></TableRow>

                    <TableRow
                      v-else
                      v-model="local.billingAddrCountry"
                      label="Country"
                      read-only
                      type="text"
                    ></TableRow>
                    <!-- ./COUNTRY -->
                  </template>
                </RowContainer>
              </TableForm>
            </template>
          </TablePanel>

          <TablePanel>
            <template #1>
              <OrderItems
                v-if="
                  local.tenantPricingId || orderType == '3PL' || pageLoading
                "
                ref="orderItemsComponent"
                :update-items="updateOrderItems"
                :editable="editable"
                :initial-items="local.orderItems"
                :dispatch-items="dispatchItems"
                :search-lists="searchLists"
                :update-search-list="updateSearchList"
                :tenant-channel-id="remote.tenantChannelId"
                :vat-number="vatNumber"
                :price-profile="selectedPriceProfile"
              ></OrderItems>
              <span v-else>
                <p>
                  Please choose a Price Profile before adding SKUs to the order.
                </p>
              </span>
            </template>
          </TablePanel>

          <TablePanel header="Picklist">
            <template #1>
              <TableGrid
                ref="grid"
                :cols="reservationCols"
                :rows="reservationRecords"
                :total-count="reservationRecords.length"
                id-key="tenantInventoryReservationId"
                scrollable
              ></TableGrid>
            </template>
          </TablePanel>

          <BraidModal ref="reservationModal" @close="closeReservationModal">
            <template #title
              >Stock reservation - {{ modalReservation.sku }}</template
            >
            <template #body>
              <TableForm>
                <TablePanel>
                  <template #1>
                    {{ modalReservation.quantity }} x
                    {{ modalReservation.sku }} reserved from
                    {{ modalReservation.locationDisplay }}
                  </template>
                </TablePanel>

                <TablePanel v-if="modalSkuInventoryRecords.length > 0">
                  <template #1>
                    <TableGrid
                      ref="modalGrid"
                      :cols="modalSkuInventoryCols"
                      :rows="modalSkuInventoryRecords"
                      :total-count="modalSkuInventoryRecords.length"
                      id-key="tenantSkuInventoryId"
                      scrollable
                    >
                    </TableGrid>
                  </template>
                </TablePanel>
              </TableForm>
            </template>
            <template #footer>
              <button class="btn btn-red" @click="closeReservationModal">
                Close
              </button>
              <button class="btn btn-green" @click="saveReservationModal">
                Save
              </button>
            </template>
          </BraidModal>
        </TableContainer>
      </template>

      <template v-if="activeTab === 'Invoices'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableGrid
                ref="invoicesGrid"
                :cols="invoice_cols"
                :rows="invoices"
                :total-count="invoice_totalCount"
                :default-sort="invoice_defaultSort"
                id-key="orderInvoiceId"
                scrollable
              ></TableGrid>
            </template>
          </TablePanel>
        </TableContainer>
      </template>

      <template v-if="activeTab === 'Dispatches'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableGrid
                ref="dispatchesGrid"
                :cols="dispatch_cols"
                :rows="dispatches"
                :total-count="dispatch_totalCount"
                :default-sort="dispatch_defaultSort"
                id-key="orderDispatchId"
                scrollable
              ></TableGrid>
            </template>
          </TablePanel>
        </TableContainer>
      </template>

      <template v-if="activeTab === 'History'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableGrid
                ref="grid"
                :cols="auditHistoryCols"
                :rows="historyOrderRows"
                :total-count="totalHistoryOrderCount"
                :filter-function="(args) => setHistoryRows(args.skip)"
                caption="Order History"
                scrollable
              ></TableGrid>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
    </TableTabs>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    ></ConfirmLeaveFormModal>
  </ValidationProvider>
</template>

<script setup>
import {
  ref,
  computed,
  onUpdated,
  watch,
  watchEffect,
  nextTick,
  inject
} from 'vue'
const _ = require('lodash')

import { useRouter } from 'vue-router'
const router = useRouter()
import useApi from '@/components/useApi'
const api = useApi()
import useCountries from '@/composables/selectData/useCountries'
const { getCountriesForSelect } = useCountries()
const countries = getCountriesForSelect()
import usePDF from '@/composables/pdfs/usePDF'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()
const orderItemsComponent = ref(null)

const pageLoading = computed(() => {
  return getLoading()
})

const pdf = usePDF()

const orderId = ref(false)
const reservations = ref([])
const skus = ref([])

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: [String, Number],
    default: ''
  }
})

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const {
  DATE_INPUT_FORMAT_STRING,
  formatDateForSave,
  formatDateTimeForSave,
  formatDateInput,
  formatDate,
  formatDateTime,
  UTC_OFFSET
} = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

// Object that contains all the data
const local = ref({ orderItems: [] })

const remote = ref({})

const editable = computed(() => {
  if (!channelOrder.value) {
    return false
  }
  return true
})

const orderType = computed(() => {
  if (local.value.tenantChannelId) {
    return '3PL'
  }
  return 'MERCHANT'
})

/**
 * Formats data returned from api
 */
const handleResponse = (data) => {
  if (data.orders.length === 0) {
    return
  }

  let order = data.orders[0]
  let orderItems = data.orderItems || []

  dispatches.value = data.dispatches
  dispatch_totalCount.value = data.dispatches.length
  dispatchItems.value = data.dispatchItems
  invoices.value = data.invoices || []
  invoice_totalCount.value = parseInt(data.invoices.length)
  customers.value = data.customers || []
  addresses.value = data.addresses || []

  let defaultDispatchLocation = _.find(locations.value, {
    tenantLocationId: order.dispatchLocationId
  })

  if (defaultDispatchLocation) {
    let country = defaultDispatchLocation.country
    let defaultVatRate = defaultDispatchLocation.defaultVatRate
    let index = 0
    let length = orderItems.length
    for (index; index < length; index++) {
      let orderItem = orderItems[index]
      if (orderItem.vatPercent != undefined && orderItem.vatPercent != '') {
        continue
      }
      let productId = orderItem.productId
      let productLocation = _.find(data.locationTaxes, {
        tenantProductId: productId,
        country: country
      })
      if (productLocation) {
        orderItem.vatPercent = productLocation.taxRate
        orderItems[index] = orderItem
        continue
      }
      orderItem.vatPercent = defaultVatRate
      orderItems[index] = orderItem
    }
  }

  local.value = {
    tenantOrderId: orderId,
    orderNumber: order.orderNumber,
    tenantCustomerId: order.tenantCustomerId,
    channelOrderId: order.channelOrderId,
    status: order.status,
    email: order.email,
    internalNotes: order.internalNotes,
    tenantPricingId: order.tenantPricingId,
    phone: order.phone,
    customerName: order.customerName,
    dispatchLocationId: order.dispatchLocationId,
    billingAddrLine1: order.billingAddrLine1,
    billingAddrLine2: order.billingAddrLine2,
    billingAddrLine3: order.billingAddrLine3,
    billingAddrPostcode: order.billingAddrPostcode,
    billingAddrCountry: order.billingAddrCountry,
    shippingAddrLine1: order.shippingAddrLine1,
    shippingAddrLine2: order.shippingAddrLine2,
    shippingAddrLine3: order.shippingAddrLine3,
    shippingAddrPostcode: order.shippingAddrPostcode,
    shippingAddrCountry: order.shippingAddrCountry,
    useShippingAddrForBilling: order.useShippingAddrForBilling,
    orderItems: orderItems,
    tenantChannelId: order.tenantChannelId || null,
    channelName: order.channelName || null,
    createdAt: formatDateTime(order.createdAt, utcOffset.value),
    createdBy: order.createdBy,
    updatedAt: formatDateTime(order.updatedAt, utcOffset.value),
    updatedBy: order.updatedBy,
    archivedAt: formatDateTime(order.archivedAt, utcOffset.value),
    archivedBy: order.archivedBy
  }

  // addLine()
  remote.value = JSON.parse(JSON.stringify(local.value))
}

/*
 *****************      VALIDATION PROVIDER
 */

// Template ref to validation provider component
const validation = ref(null)

/*
 *****************      MODALS
 */
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'
// import OrderShipmentModal from '@/components/modals/OrderShipmentModal'
// import OrderInvoiceModal from '@/components/modals//OrderInvoiceModal'

const confirmLeaveModal = ref(null)

const confirmModalNext = ref(null)
const orderShipmentModal = ref(null)
const orderInvoiceModal = ref(null)

const closeInvoiceModal = () => {
  orderInvoiceModal.value.hide()
}

const openShipmentModal = () => {
  orderShipmentModal.value.show()
}

const closeShipmentModal = () => {
  orderShipmentModal.value.hide()
}

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const closeConfirmModal = () => {
  confirmModalNext.value = null
  confirmLeaveModal.value.hide()
}

const openInvoiceModal = () => {
  orderInvoiceModal.value.show()
}

const gotoNextPage = () => {
  confirmModalhide.value()
  confirmModalNext.value()
}

const addSkuRows = ref([])
const addSkuCols = ref([
  {
    label: 'SKU',
    name: 'sku',
    filter: true,
    visible: true
  },
  {
    label: 'Supplier SKU',
    name: 'supplierSku',
    visible: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    filter: true,
    visible: true
  },
  {
    label: 'Brand',
    name: 'brandName',
    filter: true,
    visible: true
  },
  {
    label: 'Title',
    name: 'title',
    filter: true,
    visible: true
  },
  {
    label: 'Variants',
    name: 'variantValues',
    visible: true
  },
  {
    label: 'In stock',
    name: 'onHand',
    visible: true
  }
])
const addSkuModal = ref()
const selectedAddSkus = ref([])
const totalAddSkuCount = ref()
const defaultAddSkuSort = ref({ brandName: 1 })

const openSkuPoModal = async () => {
  await getAddSkuRows()
  nextTick(addSkuModal.value.show())
  modalStatus.value = true
}

const resetOrderItemsIndex = () => {
  let length = local.value.orderItems.length
  let index = 0
  let counter = 0
  for (index; index < length; index++) {
    if (!local.value.orderItems[index].doNotShow) {
      local.value.orderItems[index].index = counter
      counter++
    }
  }
}

const saveAddSkuModal = () => {
  let index = 0
  let length = selectedAddSkus.value.length
  let formattedSkus = []

  resetPurchaseOrderItemsIndex()

  let counter = 0
  for (index; index < length; index++) {
    let selectedSku = selectedAddSkus.value[index]

    if (!selectedSku) {
      continue
    }

    let tenantSkuId = selectedSku.tenantSkuId

    if (selectedAddSkuIds.value.includes(tenantSkuId)) {
      continue
    }

    let formatted = {
      brandName: selectedSku.brandName,
      casePrice: selectedSku.casePrice,
      gtin: selectedSku.gtin || null,
      hsCode: selectedSku.hsCode || null,
      innerCaseSize: selectedSku.innerCaseSize,
      minOrderQty: selectedSku.minOrderQty,
      mpn: selectedSku.mpn,
      orderByCase: selectedSku.orderByCase,
      orderByUnits: selectedSku.orderByUnits,
      orderCaseSize: selectedSku.orderCaseSize,
      quantity: 0,
      sku: selectedSku.sku,
      supplierSku: selectedSku.supplierSku,
      tenantSkuId: selectedSku.tenantSkuId,
      title: selectedSku.title,
      unitPrice: parseFloat(unitPrice).toString(),
      priceEach: parseFloat(unitPrice).toString(),
      priceCase: parseFloat(casePrice).toString(),
      status: 0,
      brandName: selectedSku.brandName,
      index: local.value.purchaseOrderItems.length + counter
    }
    counter++
    formattedSkus.push(formatted)
  }

  local.value.orderItems = local.value.orderItems.concat(formattedSkus)
}

const openAddSkuModal = () => {
  addSkuModal.value.hide()
  modalStatus.value = false
}

const closeAddSkuModal = () => {
  addSkuModalGrid.value.resetFilters()
  addSkuModal.value.hide()
  modalStatus.value = false
}

/**
 * This is the array of sku ids within the Order
 */
const selectedAddSkuIds = computed(() => {
  let index = 0
  let data = local.value.orderItems
  let length = data.length
  let ids = []

  for (index; index < length; index++) {
    let item = data[index]
    let skuId = item.tenantSkuId
    if (ids.indexOf(skuId) !== -1) {
      continue
    }
    ids.push(skuId)
  }
  return ids
})

const getAddSkuRows = async (args = {}) => {
  args.order = 1

  let response = await api.get('/skus', args)
  addSkuRows.value = response.data.skus
  totalAddSkuCount.value = parseInt(response.data.count)
}

/**
 *****************      TABLE FORM
 */
import OrderItems from '@/components/content/orders/OrderItems'
import TableForm from '@/components/table/form/TableForm'
import RowContainer from '@/components/table/form/RowContainer'
import TableRow from '@/components/table/form/TableRow'
import TableText from '@/components/table/form/TableText'
import TableSelect from '@/components/table/form/TableSelect'

const tabs = ['Order', 'Dispatches', 'Invoices', 'History']
const saving = ref(false)
const searchLists = ref({})
const textCustomer = ref(true)
const addresses = ref([])
const customers = ref([])
const locations = ref([])
const dispatches = ref([])
const dispatchItems = ref([])

// Tried to do this by exposing active tab in the TableTab
// but couldn't get it to work, so I've done it this hacky way
// instead
const selectedTab = ref()
const tabManager = ref(null)
watchEffect(() => {
  if (tabManager.value) {
    selectedTab.value = tabManager.value.tab
  }
})

const channelOrder = computed(() => {
  return local.value.channelOrderId
})

const totalExVat = computed(() => {
  let index = 0
  let data = local.value.orderItems || []
  let length = data.length
  let totalPrice = 0.0
  for (index; index < length; index++) {
    let item = data[index]
    if (item.delete == 1 || item.initialInput) {
      continue
    }
    let linePrice = item.quantity * item.price
    totalPrice += parseFloat(linePrice)
  }
  return totalPrice
})

const totalVat = computed(() => {
  let index = 0
  let data = local.value.orderItems || []
  let length = data.length
  let totalVat = 0.0
  for (index; index < length; index++) {
    let item = data[index]
    if (item.delete == 1 || item.initialInput) {
      continue
    }
    let lineVat =
      item.quantity * item.price * (parseFloat(item.vatPercent) / 100)
    totalVat += parseFloat(lineVat)
  }
  return totalVat || 0.0
})

const totalIncVat = computed(() => {
  return totalExVat.value + totalVat.value
})

const dispatchLocationOptions = computed(() => {
  return locations.value.map((location) => {
    return {
      value: location.tenantLocationId,
      label: ` ${location.name} (  ${location.address1}  ${location.address2}  ${location.address3} ${location.addressPostcode} `
    }
  })
})

const addressOptions = computed(() => {
  return addresses.value.map((addr) => {
    return {
      value: address.tenantCustomerAddressId,
      label: ` ${address.name} - ( ${address.address1}, ${address.addressPostcode})`
    }
  })
})

const updateSearchList = (listName, values) => {
  searchLists.value[listName] = values
}

const updateShippingAddress = (e, value) => {
  let address = _.find(addresses.value, {
    tenantCustomerAddressId: local.value.shippingAddressId
  })
  local.value.shippingAddrLine1 = address.address1
  local.value.shippingAddrLine2 = address.address2
  local.value.shippingAddrLine3 = address.address3
  local.value.shippingAddrPostcode = address.addressPostcode
  local.value.shippingAddrCountry = address.country
}

const updateBillingAddress = () => {
  let address = _.find(addresses.value, {
    tenantCustomerAddressId: local.value.billingAddressId
  })
  local.value.billingAddrLine1 = address.address1
  local.value.billingAddrLine2 = address.address2
  local.value.billingAddrLine3 = address.address3
  local.value.billingAddrPostcode = address.addressPostcode
  local.value.billingAddrCountry = address.country
}

const setTextCustomer = () => {
  local.value.tenantCustomerId = null
  local.value.customerName = null
  textCustomer.value = !textCustomer.value
}

const updateCustomerName = (value) => {
  let customer = _.find(customers, { tenantCustomerId: value })
  if (!customer) {
    return
  }
  local.value.customerName = customer.name
}

const searchCustomers = (search, loading) => {
  if (search !== '') {
    getCustomers(search, loading)
  }
}

const getCustomers = _.debounce(async (searchValue, loading) => {
  if (!searchValue) {
    return
  }

  loading(true)

  let response = await api.get('/customers/', {
    filter: { name: searchValue },
    sort: { name: 1 }
  })
  customers.value = response.data.customers
  loading(false)
}, 250)

const getOrderId = () => {
  orderId.value = false
  if (props.id && parseInt(props.id) > 0) {
    orderId.value = props.id
  }
}

const getOrder = async () => {
  if (!orderId.value || orderId.value === 'new') {
    local.value.status = 0
    local.value.useShippingAddrForBilling = false
    local.value.orderItems = []
    return
  }
  let response = await api.get('/orders/' + orderId.value)
  handleResponse(response.data)

  await getSkuData()

  joinSkuData()
}

/**
 * Will join order items to sku data if it exists
 */
const joinSkuData = () => {
  let index = 0
  let length = local.value.orderItems.length

  for (index; index < length; index++) {
    let orderItem = local.value.orderItems[index]
    let skuData = skus.value.find((sku) => {
      return sku.sku == orderItem.sku
    })

    if (!skuData) {
      continue
    }

    orderItem.variant = skuData.variant
    orderItem.mpn = skuData.mpn

    local.value.orderItems[index] = orderItem
  }
}

/**
 * This will call the inventory-service to get related SKU data
 *
 * that is SKU details, reservation details
 */
const getSkuData = async () => {
  if (!local.value.orderItems || local.value.orderItems.length === 0) {
    return
  }

  let tenantOrderItemIds = []
  let skusToCheck = []

  let index = 0
  let length = local.value.orderItems.length

  for (index; index < length; index++) {
    let orderItem = local.value.orderItems[index]

    if (!orderItem) {
      continue
    }

    let tenantOrderItemId = orderItem.tenantOrderItemId
    let sku = orderItem.sku

    skusToCheck.push(sku)
    tenantOrderItemIds.push(tenantOrderItemId)
  }

  let params = {
    tenantOrderItemIds: tenantOrderItemIds,
    skus: skusToCheck
  }

  let response = await api.get('/skus/orders', params)

  skus.value = response.data.skus
}

const getLocations = async () => {
  locations.value = await api.getLocations()
}

const priceProfiles = ref([])
const getPriceProfiles = async () => {
  let response = await api.get('/pricing', {
    filter: {},
    sort: { name: 1 }
  })
  if (!response) {
    return
  }

  priceProfiles.value = response.data.pricingProfiles
  reservationRecords.value = formatReservations(response.data.reservations)
}

const vatNumber = ref(null)
const getTenant = async () => {
  let tenant = await api.getTenant()
  vatNumber.value = tenant.vatNumber
}

watch(
  () => local.value.tenantCustomerId,
  (current, previous) => {
    if (current != null) {
      let customer = customers.value.find(
        (customer) => customer.tenantCustomerId == current
      )
      if (customer == undefined) {
        return
      }
      local.value.tenantPricingId = customer.defaultPricingProfileId
    }
  }
)

const selectedPriceProfile = ref(null)
watch(
  () => local.value.tenantPricingId,
  async (current, previous) => {
    if (local.value.currency) {
      return
    }
    let index = priceProfiles.value.findIndex(
      (profile) => profile.tenantPricingId == current
    )
    if (index == -1) {
      await getPriceProfiles()
      index = priceProfiles.value.findIndex(
        (profile) => profile.tenantPricingId == current
      )
    }
    selectedPriceProfile.value = priceProfiles.value[index]
    local.value.currency = selectedPriceProfile.value.currency
  }
)

const updateSkuPricing = () => {}

const getLinePrice = (item) => {
  if (!item.quantityOrdered || !item.priceEach) {
    return 0.0
  }
  let linePrice = item.quantityOrdered * item.priceEach
  return linePrice.toFixed(2)
}

const createRandomString = () => {
  var result = []
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  var charactersLength = characters.length
  var length = 10
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join('')
}

const createToast = inject('createToast')

const update = async () => {
  if (!local.value.status == undefined || local.value.status === '') {
    createToast('error', 'Please set the status of the order before saving ')
    return
  }
  local.value.totalPrice = totalExVat
  formatValuesForSave()
  if (!orderId.value) {
    insert()
    return
  }
  let response = await api.put(
    '/orders/' + orderId.value,
    { order: local.value },
    saving
  )
  handleResponse(response.data)
  validation.value.save()
  removeDeletedItemsFromView()
}

const insert = async () => {
  let response = await api.post('/orders/', { order: local.value }, saving)
  orderId.value = response.data.insertedId
  router.replace('/orders/' + orderId.value)
  handleResponse(response.data)
  setTitle()
  validation.value.save()
}

const formatValuesForSave = () => {
  if (local.value.archivedAt) {
    local.value.archivedAt = formatDateTimeForSave(
      local.value.archivedAt,
      utcOffset.value
    )
  }
}

const updateOrderItems = (items) => {
  local.value.orderItems = items
}

const removeDeletedItemsFromView = () => {
  let index = 0
  let length = local.value.orderItems.length
  for (index; index < length; index++) {
    let item = local.value.orderItems[index]
    if (item.delete == 1 || item.deletedBy != null) {
      local.value.orderItems[index].doNoShow = 1
    }
  }
}

const gotoOrders = () => {
  router.push('/orders/')
}

/*
 ********* RESERVATION PICKLIST */

/*
 *****************      INVOICES
 */
const invoices = ref([])
const invoice_totalCount = ref(0)
const invoice_defaultSort = ref({ tenantInvoiceId: -1 })

const invoice_cols = [
  {
    label: 'Invoice number',
    name: 'invoiceNumber',
    visible: true,
    onClick: {
      route: '/invoices/',
      id: 'tenantInvoiceId'
    },
    primary: true
  },
  {
    label: 'Status',
    name: 'status',
    visible: true,
    displayValues: {
      0: 'Draft',
      1: 'Unfulfilled',
      2: 'Part-fulfilled',
      3: 'Fulfilled',
      4: 'Cancelled'
    }
  },
  {
    label: 'Dispatch Country',
    name: 'shippingAddrCountry',
    visible: true
  },
  {
    label: 'Total',
    name: 'totalPrice',
    visible: true
  },
  {
    label: 'Due date',
    name: 'dueDate',
    visible: true
  },
  {
    label: 'Raised at',
    name: 'createdAt',
    visible: true,
    date: true,
    width: 20
  },
  {
    label: 'Updated at',
    name: 'updatedAt',
    date: true,
    visible: true
  }
]

/*
  DISPATCHES
*/

const dispatch_totalCount = ref(0)
const dispatch_defaultSort = ref({ tenantDispatchId: -1 })

const dispatch_cols = [
  {
    label: 'Dispatch number',
    name: 'channelDispatchId',
    visible: true,
    onClick: {
      route: '/dispatches/',
      id: 'tenantDispatchId'
    },
    primary: true
  },
  {
    label: 'Status',
    name: 'status',
    displayValues: {
      0: 'Draft',
      1: 'Label created',
      2: 'Shipped'
    },
    visible: true
  },
  {
    label: 'Carrier',
    name: 'carrier',
    displayValues: {
      UPS: 'UPS',
      DHL: 'DHL',
      HERMES: 'HERMES',
      FEDEX: 'FEDEX',
      DPD: 'DPD',
      ROYAL_MAIL: 'Royal Mail'
    },
    visible: true
  },
  {
    label: 'Ship Date',
    name: 'shipDate',
    inputType: 'datetime',
    date: true,
    visible: true
  },
  {
    label: 'Raised at',
    name: 'channelDispatchCreatedAt',
    dateTime: true,
    visible: true
  },
  {
    label: 'Updated at',
    name: 'channelDispatchUpdatedAt',
    dateTime: true,
    visible: true
  }
]

/**
 * Checks if there are any items left to be dispatched. If not, disabled 'Create Dispatch'
 */
const allItemsDispatched = computed(() => {
  let flag = true
  local.value.orderItems.forEach((item) => {
    let quantity = item.quantity
    let dispatchRows = dispatchItems.value.filter(
      (dispatch) => dispatch.sku == item.sku
    )
    for (let index in dispatchRows) {
      let dispatchRow = dispatchRows[index]
      quantity -= dispatchRow.quantity
    }
    if (quantity > 0) {
      flag = false
    }
  })

  return flag
})

const unarchiveOrder = async () => {
  let response = await api.put(
    '/orders/' + orderId.value,
    {
      unarchive: 1,
      tenantOrderId: orderId.value
    },
    saving
  )
  let order = response.data.orders[0]
  updateOrder(order)
}

const archiveOrder = async () => {
  let response = await api.put(
    '/orders/' + orderId.value,
    {
      archive: 1,
      tenantOrderId: orderId.value
    },
    saving
  )
  let order = response.data.orders[0]
  updateOrder(order)
}

const updateOrder = (order) => {
  order.createdAt = formatDateTime(order.createdAt, utcOffset.value)
  order.approvedAt = formatDateTime(order.approvedAt, utcOffset.value)
  order.updatedAt = formatDateTime(order.updatedAt, utcOffset.value)
  order.archivedAt = formatDateTime(order.archivedAt, utcOffset.value)
  order.paidAt = formatDateTime(order.paidAt, utcOffset.value)
  order.followUpDate = formatDate(order.followUpDate, utcOffset.value)
  local.value = Object.assign({}, local.value, order)
  remote.value = Object.assign({}, remote.value, order)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = [
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  },
  // {
  //   id: 2,
  //   label: 'Create dispatch',
  //   click: () =>
  //     router.push({
  //       name: 'DispatchForm',
  //       params: { id: 'new', tenantOrderId: local.value.tenantOrderId }
  //     }),
  //   icon: ['fas', 'truck-arrow-right']
  // },
  {
    id: 3,
    label: 'Create invoice',
    click: () =>
      router.push({
        name: 'InvoiceForm',
        params: { id: 'new', tenantOrderId: local.value.tenantOrderId }
      }),
    icon: ['fas', 'file-invoice-dollar']
  },
  {
    id: 4,
    label: 'Unarchive',
    click: unarchiveOrder,
    icon: ['fas', 'arrow-rotate-left']
  },
  {
    id: 5,
    label: 'Archive',
    click: archiveOrder,
    icon: ['fas', 'box-archive']
  }
]

/*
  HISTORY
*/

const dispatchesGrid = ref(null)
const invoicesGrid = ref(null)
watch(selectedTab, async (current, previous) => {
  if (current == 'History' && !loadedHistory.value) {
    await getHistoryRows()
    loadedHistory.value = true
  }

  switch (current) {
    case 'History':
      if (!loadedHistory.value) {
        await getHistoryRows()
        loadedHistory.value = true
      }
      break

    case 'Order':
      nextTick(() => {
        orderItemsComponent.value.updateRows()
      })
      break

    case 'Dispatches':
      nextTick(() => {
        dispatchesGrid.value.updateRows()
      })
      break

    case 'Invoices':
      nextTick(() => {
        invoicesGrid.value.updateRows()
      })
      break
  }
})

const changeSkuInventory = async (tenantSkuInventoryId) => {
  modalReservation.value.tenantSkuInventoryId = tenantSkuInventoryId
}

const reservationModal = ref(null)
const modalReservation = ref({})
const modalSkuInventoryRecords = ref([])
const modalSkuInventoryCols = [
  {
    label: 'Location',
    name: 'locationDisplay',
    visible: true
  },
  {
    label: 'Quantity in location',
    name: 'quantity',
    visible: true
  },
  {
    visible: true,
    displayCheck: (row) => {
      return (
        row.tenantSkuInventoryId != modalReservation.value.tenantSkuInventoryId
      )
    },
    button: true,
    label: 'Pick from here',
    click: changeSkuInventory
  }
]

const getSkuInventoryRecordsForSku = async () => {
  let tenantSkuId = modalReservation.value.tenantSkuId

  if (!tenantSkuId) {
    return
  }

  let response = await api.get('/skuInventory/sku', {
    tenantSkuId: tenantSkuId
  })

  modalSkuInventoryRecords.value = formatSkuInventoryRecords(
    response.data.skuInventoryRecords
  )
}

const formatSkuInventoryRecords = (records = []) => {
  if (records.length === 0) {
    return []
  }

  let index = 0
  let length = records.length
  for (index; index < length; index++) {
    let record = records[index]
    record.locationDisplay = formatLocationDisplay(record)
  }

  return records
}

const openReservationModal = async (tenantInventoryReservationId) => {
  modalReservation.value = reservationRecords.value.find(
    (reservation) =>
      reservation.tenantInventoryReservationId == tenantInventoryReservationId
  )
  await getSkuInventoryRecordsForSku()
  reservationModal.value.show()
}

const saveReservationModal = async () => {
  try {
    let response = await api.put(
      `/reservations/${modalReservation.value.tenantInventoryReservationId}`,
      { tenantInventoryReservations: [modalReservation.value] }
    )
    let formattedReservation = formatReservations(response.data.reservations)
    let index = reservationRecords.value.findIndex(
      (record) =>
        record.tenantInventoryReservationId ==
        modalReservation.value.tenantInventoryReservationId
    )
    reservationRecords.value[index] = formattedReservation[0]
    reservationModal.value.hide()
  } catch (e) {
    console.log(e)
  }
}

const closeReservationModal = () => {
  modalReservation.value = {}
  modalSkuInventoryRecords.value = []
}

const reservationRecords = ref([])
const reservationCols = [
  {
    label: 'SKU',
    name: 'sku',
    visible: true
  },
  {
    label: 'Location',
    name: 'locationDisplay',
    visible: true
  },
  {
    label: 'Quantity',
    name: 'quantity',
    visible: true
  },
  {
    label: 'Edit reservation',
    click: openReservationModal,
    visible: true,
    button: true
  }
]

// const getReservationRecords = async () => {
//   let tenantOrderItemIds = local.value.orderItems.map(
//     (orderItem) => orderItem.tenantOrderItemId
//   )
//   try {
//     progressBarStart()
//     let response = await api.get('/reservations', { tenantOrderItemIds })
//     reservationRecords.value = formatReservations(response.data.reservations)
//     progressBarDone()
//   } catch (e) {
//     console.log(e)
//     progressBarFail()
//   }
// }

const formatLocationDisplay = (record) => {
  return `${record.bin ? record.bin + ' - ' : ''} ${record.name} (${
    record.address1
  }, ${record.addressPostcode})`
}

const formatReservations = (reservations = []) => {
  if (reservations.length === 0) {
    return []
  }

  let formatted = []
  let index = 0
  let length = reservations.length
  for (index; index < length; index++) {
    let reservation = reservations[index]

    if (!reservation) {
      continue
    }

    let formattedReservation = { ...reservation }
    formattedReservation.locationDisplay = formatLocationDisplay(reservation)

    let tenantOrderItemId = reservation.tenantOrderItemId
    let tenantOrderItem = local.value.orderItems.find((orderItem) => {
      return orderItem.tenantOrderItemId == tenantOrderItemId
    })

    if (!tenantOrderItem) {
      formatted.push(formattedReservation)
      continue
    }

    formattedReservation.sku = tenantOrderItem.sku
    formatted.push(formattedReservation)
  }

  return formatted
}

/**
 * Calucaltes the number of audit history records
 */
const totalHistoryPoCount = computed(() => {
  return orderAudit.value.length
})
const loadedHistory = ref(false)
const historyOrderRows = ref([])
const orderAudit = ref([])
const orderAuditCols = ref([
  {
    label: 'Status',
    name: ''
  },
  {
    label: 'Payment Status',
    name: 'paymentStatus'
  },
  {
    label: 'Payment Terms',
    name: 'paymentTerms'
  },
  {
    label: 'Customer Name',
    name: 'customerName'
  },
  {
    label: 'Email',
    name: 'email'
  },
  {
    label: 'Phone',
    name: 'phone'
  },
  {
    label: 'Dispatch Location',
    name: 'dispatchLocationId'
  },
  {
    label: 'Billing Address Line 1',
    name: 'billingAddrLine1'
  },
  {
    label: 'Billing Address Line 2',
    name: 'billingAddrLine2'
  },
  {
    label: 'Billing Address Line 3',
    name: 'billingAddrLine3'
  },
  {
    label: 'Billing Address Postcode',
    name: 'billingAddrPostcode'
  },
  {
    label: 'Billing Address Country',
    name: 'billingAddrCountry'
  },
  {
    label: 'Shipping Address Line 1',
    name: 'shippingAddrLine1'
  },
  {
    label: 'Shipping Address Line 2',
    name: 'shippingAddrLine2'
  },
  {
    label: 'Shipping Address Line 3',
    name: 'shippingAddrLine3'
  },
  {
    label: 'Shipping Address Postcode',
    name: 'shippingAddrPostcode'
  },
  {
    label: 'Shipping Address Country',
    name: 'shippingAddrCountry'
  },
  {
    label: 'Total Price',
    name: 'totalPrice'
  },
  {
    label: 'Currency',
    name: 'currency'
  }
])
const orderItemAuditCols = ref([
  {
    label: 'Sku',
    name: 'sku'
  },
  {
    label: 'Status',
    name: 'status'
  },
  {
    label: 'Title',
    name: 'title'
  },
  {
    label: 'Quantity',
    name: 'quantity'
  },
  {
    label: 'Kit Quantity',
    name: 'kitQuantity'
  },
  {
    label: 'Quantity Shipped',
    name: 'quanityShipped'
  },
  {
    label: 'Price',
    name: 'price'
  },
  {
    label: 'VAT Percent',
    name: 'vatPercent'
  },
  {
    label: 'VAT Amount',
    name: 'vatAmount'
  },
  {
    label: 'Line Price',
    name: 'linePrice'
  }
])
const dispatchAuditCols = ref([
  {
    label: 'Ship Date',
    name: 'shipDate'
  },
  {
    label: 'Carrier',
    name: 'carrier'
  },
  {
    label: 'Weight',
    name: 'weightInGrams'
  },
  {
    label: 'Height',
    name: 'height'
  },
  {
    label: 'Width',
    name: 'width'
  },
  {
    label: 'Depth',
    name: 'depth'
  },
  {
    label: 'Dimension Units',
    name: 'dimensionUnits'
  },
  {
    label: 'Status',
    name: 'status'
  }
])
const dispatchConsignmentAuditCols = ref([
  {
    label: 'Consignment Number',
    name: 'consignmentNumber'
  },
  {
    label: 'Tracking Number',
    name: 'trackingNumber'
  }
])
const dispatchItemAuditCols = ref([
  {
    label: 'Sku',
    name: 'sku'
  },
  {
    label: 'Title',
    name: 'title'
  },
  {
    label: 'Quantity',
    name: 'quantity'
  }
])
const invoiceAuditCols = ref([
  {
    label: 'Invoice Number',
    name: 'invoiceNumber'
  },
  {
    label: 'Due Date',
    name: 'dueDate'
  },
  {
    label: 'Status',
    name: 'status'
  },
  {
    label: 'Total Price',
    name: 'totalPrice'
  },
  {
    label: 'Currency',
    name: 'currency'
  },
  {
    label: 'Billing Address Line 1',
    name: 'billingAddrLine1'
  },
  {
    label: 'Billing Address Line 2',
    name: 'billingAddrLine2'
  },
  {
    label: 'Billing Address Line 3',
    name: 'billingAddrLine3'
  },
  {
    label: 'Billing Postcode',
    name: 'billingAddrPostcode'
  },
  {
    label: 'Billing Country',
    name: 'billingAddrCountry'
  },
  {
    label: 'Dispatch Address Line 1',
    name: 'dispatchAddrLine1'
  },
  {
    label: 'Dispatch Address Line 2',
    name: 'dispatchAddrLine2'
  },
  {
    label: 'Dispatch Address Line 3',
    name: 'dispatchAddrLine3'
  },
  {
    label: 'Dispatch Postcode',
    name: 'dispatchAddrPostcode'
  },
  {
    label: 'Dispatch Country',
    name: 'dispatchAddrCountry'
  }
])
const invoiceItemAuditCols = ref([
  {
    label: 'Title',
    name: 'title'
  },
  {
    label: 'Sku',
    name: 'sku'
  },
  {
    label: 'Status',
    name: 'status'
  },
  {
    label: 'MPN',
    name: 'mpn'
  },
  {
    label: 'GTIN',
    name: 'gtin'
  },
  {
    label: '',
    name: 'hsCode'
  },
  {
    label: 'Origin',
    name: 'origin'
  },
  {
    label: 'Quantity',
    name: 'quantity'
  },
  {
    label: 'Price',
    name: 'price'
  },
  {
    label: 'VAT Percent',
    name: 'vatPercent'
  },
  {
    label: 'VAT Amount',
    name: 'vatAmount'
  }
])

import { useAudit } from '@/composables/useAudit'
const {
  auditHistoryCols,
  mergeAudit,
  sortAudit,
  formatAuditDates,
  processAudit
} = useAudit()

const getHistoryRows = async (args = {}) => {
  let responseOrderAudit = await api.get('/audit/tenantOrder', {
    ...args,
    params: {
      tenantOrderId: orderId.value
    }
  })
  let responseOrderItemAudit = await api.get('/audit/tenantOrderItem', {
    ...args,
    params: {
      tenantOrderId: orderId.value
    }
  })
  let responseDispatchAudit = await api.get('/audit/tenantDispatch', {
    ...args,
    params: {
      tenantOrderId: orderId.value
    }
  })
  let responseDispatchConsignmentAudit = await api.get(
    '/audit/tenantDispatchConsignment',
    {
      ...args,
      params: {
        tenantOrderId: orderId.value
      }
    }
  )
  let responseDispatchItemAudit = await api.get('/audit/tenantDispatchItem', {
    ...args,
    params: {
      tenantOrderId: orderId.value
    }
  })
  let responseInvoiceAudit = await api.get('/audit/tenantInvoice', {
    ...args,
    params: {
      tenantOrderId: orderId.value
    }
  })
  let responseInvoiceItemAudit = await api.get('/audit/tenantInvoice', {
    ...args,
    params: {
      tenantOrderId: orderId.value
    }
  })
  let auditRecords = mergeAudit([
    responseOrderAudit.data.data,
    responseOrderItemAudit.data.data,
    responseDispatchAudit.data.data,
    responseDispatchConsignmentAudit.data.data,
    responseDispatchItemAudit.data.data,
    responseInvoiceAudit.data.data,
    responseInvoiceItemAudit.data.data
  ])
  let auditSorted = sortAudit(auditRecords)
  orderAudit.value = processAudit(
    {
      Order: 'tenantOrderId',
      'Order Item': 'tenantOrderItemId',
      Dispatch: 'tenantDispatchId',
      Consignment: 'tenantDispatchConsignmentId',
      'Dispatch Item': 'tenantDispatchId',
      Invoice: 'tenantInvoiceId',
      'Invoice Item': 'tenantInvoiceItemId'
    },
    {
      Order: orderAuditCols.value,
      'Order Item': orderItemAuditCols.value,
      Dispatch: dispatchAuditCols.value,
      Consignment: dispatchConsignmentAuditCols.value,
      'Dispatch Item': dispatchItemAuditCols.value,
      Invoice: invoiceAuditCols.value,
      'Invoice Item': invoiceItemAuditCols.value
    },
    auditSorted
  )
  setHistoryRows()
}

/**
 *
 */
const setHistoryRows = (paginationSkip = 0) => {
  let paginationEnd = paginationSkip + 20
  if (paginationEnd > orderAudit.value.length) {
    paginationEnd = orderAudit.value.length
  }
  let rows = orderAudit.value.slice(paginationSkip, paginationEnd)
  rows = JSON.parse(JSON.stringify(rows))
  let formatted = formatAuditDates(rows)

  historyOrderRows.value = formatted
}

const setTitle = () => {
  let pageTitle = local.value.orderNumber || 'Create order'
  document.getElementById('pagetitle').innerHTML = pageTitle
  document.title = pageTitle
}

const getData = async () => {
  loading()
  try {
    progressBarStart()
    getOrderId()
    await getLocations()
    await getOrder()
    await getPriceProfiles()
    await getTenant()
    loaded()
    setTitle()
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
    router.back()
  }
}

getData()

const routeRefreshOnMounted = ref(false)
onUpdated(() => {
  if (
    routeRefreshOnMounted.value &&
    router.currentRoute.value.params.id == 'new'
  ) {
    router.go()
  }
  routeRefreshOnMounted.value = true
})
</script>
