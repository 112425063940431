const links = [
  {
    id: 1,
    route: '/dashboard',
    label: 'Dashboard',
    icon: ['fas', 'house'],
    activeRoutes: ['Dashboard']
  },
  {
    id: 2,
    route: '/suppliers',
    label: 'Suppliers',
    icon: ['fas', 'boxes-packing'],
    activeRoutes: ['Suppliers', 'SupplierForm'],
    subItems: [
      {
        id: 2.1,
        label: 'Active',
        route: {
          name: 'Suppliers',
          params: { pageId: 2.1 }
        }
      },
      {
        id: 2.2,
        label: 'Archived',
        route: {
          name: 'Suppliers',
          query: { filter: 'showArchived=2' },
          params: { pageId: 2.2 }
        }
      },
      {
        id: 2.3,
        label: 'All',
        route: {
          name: 'Suppliers',
          query: { filter: 'showArchived=1' },
          params: { pageId: 2.3 }
        }
      }
    ]
  },
  {
    id: 3,
    route: '/customers',
    label: 'Customers',
    icon: ['fas', 'user-tie'],
    activeRoutes: ['Customers', 'CustomerForm'],
    subItems: [
      {
        id: 3.1,
        label: 'Active',
        route: {
          name: 'Customers',
          params: { pageId: 3.1 }
        }
      },
      {
        id: 3.2,
        label: 'Archived',
        route: {
          name: 'Customers',
          query: { filter: 'showArchived=2' },
          params: { pageId: 3.2 }
        }
      },
      {
        id: 3.3,
        label: 'All',
        route: {
          name: 'Customers',
          query: { filter: 'showArchived=1' },
          params: { pageId: 3.3 }
        }
      }
    ]
  },
  {
    id: 4,
    route: '/orders',
    label: 'Orders',
    icon: ['fas', 'box-open'],
    activeRoutes: ['Orders', 'OrderForm'],
    subItems: [
      {
        id: 4.1,
        label: 'Active',
        route: {
          name: 'Orders',
          params: { pageId: 4.1 }
        }
      },
      {
        id: 4.2,
        label: 'Archived',
        route: {
          name: 'Orders',
          query: { filter: 'showArchived=2' },
          params: { pageId: 4.2 }
        }
      },
      {
        id: 4.3,
        label: 'Awaiting dispatch',
        route: {
          name: 'Orders',
          query: {
            filter: 'status*=1,2^paymentStatus=1^fulfillmentChannel=MERCHANT'
          },
          params: { pageId: 4.3 }
        }
      },
      {
        id: 4.4,
        label: 'Items',
        route: {
          name: 'OrderItems',
          params: { pageId: 4.6 }
        }
      },
      {
        id: 4.5,
        label: 'Labels to print',
        route: {
          name: 'Unprinted-Labels',
          params: { pageId: 4.7 }
        }
      },
      {
        id: 4.6,
        label: 'Labels',
        route: {
          name: 'Labels',
          params: { pageId: 4.8 }
        }
      }
    ]
  },
  {
    id: 11,
    route: '/returns',
    label: 'Returns',
    icon: ['fas', 'box-open'],
    activeRoutes: ['Returns', 'ReturnForm'],
    subItems: [
      {
        id: 11.1,
        label: 'All',
        route: {
          name: 'Returns',
          params: { pageId: 11.1 }
        }
      }
    ]
  },
  {
    id: 5,
    route: '/messages',
    label: 'Messages',
    icon: ['fas', 'bell'],
    activeRoutes: ['Messages', 'MessageForm'],
    subItems: [
      {
        id: 5.1,
        label: 'Awaiting Response',
        route: {
          name: 'Messages',
          query: { filter: 'messageStatus=Unanswered' },
          params: { pageId: 5.1 }
        }
      },
      {
        id: 5.2,
        label: 'All',
        route: {
          name: 'Messages',
          params: { pageId: 5.2 }
        }
      }
    ]
  },
  {
    id: 6,
    route: '/sku',
    label: 'SKUs',
    icon: ['fas', 'barcode'],
    activeRoutes: ['Skus', 'SkuForm'],
    subItems: [
      {
        id: 6.1,
        label: 'Active',
        route: {
          name: 'Skus',
          params: { pageId: 6.1 }
        }
      },
      {
        id: 6.2,
        label: 'Archived',
        route: {
          name: 'Skus',
          query: { filter: 'showArchived=2' },
          params: { pageId: 6.2 }
        }
      },
      {
        id: 6.3,
        label: 'In Stock',
        route: {
          name: 'Skus',
          query: { filter: 'onHand*=>,10' },
          params: { pageId: 6.3 }
        }
      },
      {
        id: 6.4,
        label: 'Low Stock',
        route: {
          name: 'Skus',
          query: { filter: 'onHand*=<,11' },
          params: { pageId: 6.4 }
        }
      },
      {
        id: 6.5,
        label: 'Categories',
        route: {
          name: 'Categories',
          params: { pageId: 6.5 }
        }
      },
      {
        id: 6.7,
        label: 'Kits',
        route: {
          name: 'Skus',
          query: { filter: 'type=KIT' },
          params: { pageId: 6.7 }
        }
      }
    ]
  },
  {
    id: 10,
    route: '/inventory',
    label: 'Inventory',
    icon: ['fas', 'box-archive'],
    activeRoutes: ['Inventory', 'SkuForm'],
    subItems: [
      {
        id: 10.1,
        label: 'All',
        route: {
          name: 'Inventory',
          params: { pageId: 10.1 }
        }
      }
    ]
  },
  {
    id: 7,
    route: '/purchase-orders',
    label: 'Purchase Orders',
    icon: ['fas', 'clipboard-list'],
    activeRoutes: ['PurchaseOrders', 'PurchaseOrderForm', 'PurchaseOrderItems'],
    subItems: [
      {
        id: 7.1,
        label: 'Active',
        route: {
          name: 'PurchaseOrders',
          params: { pageId: 7.1 }
        }
      },
      {
        id: 7.2,
        label: 'Archived',
        route: {
          name: 'PurchaseOrders',
          query: { filter: 'showArchived=2' },
          params: { pageId: 7.2 }
        }
      },
      {
        id: 7.3,
        label: 'Items',
        route: {
          name: 'PurchaseOrderItems',
          params: { pageId: 7.3 }
        }
      },
      {
        id: 7.4,
        label: 'Items not received',
        route: {
          name: 'PurchaseOrderItemsNotReceived',
          params: { pageId: 7.4 }
        }
      }
    ]
  },
  {
    id: 8,
    route: '/dispatches',
    label: 'Dispatches',
    icon: ['fas', 'truck-arrow-right'],
    activeRoutes: ['Dispatches', 'DispatchForm'],
    subItems: [
      {
        id: 8.1,
        label: 'Active',
        route: {
          name: 'Dispatches',
          params: { pageId: 8.1 }
        }
      },
      {
        id: 8.2,
        label: 'Add new',
        route: {
          name: 'DispatchForm',
          params: { pageId: 8.2, id: 'new' }
        }
      },
      {
        id: 8.3,
        label: 'Unfulfilled',
        route: {
          name: 'Dispatches',
          query: {
            filter: 'orderStatus*=1'
          },
          params: { pageId: 8.3 }
        }
      }
    ]
  }
]

export default links
