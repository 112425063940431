<template>
  <th
    :id="`table-sort-${col.name}`"
    :style="{ width: col.displayWidth }"
    @click="emit('sort', col.name)"
  >
    <span
      class="flex-wrapper"
      :class="{ 'row-reverse': col.number }"
      :title="col.label"
    >
      <p
        class="sortHeading"
        :class="{
          'text-heading': !col.number,
          'numeric-heading': col.number
        }"
      >
        <b>
          {{ col.label }}
        </b>
      </p>
      <span class="icon-wrapper">
        <FontAwesomeIcon
          size="xs"
          class="sort-icon"
          :class="descSortIcon"
          :icon="['fas', 'arrow-down']"
        ></FontAwesomeIcon>
        <FontAwesomeIcon
          size="xs"
          class="sort-icon"
          :class="ascSortIcon"
          :icon="['fas', 'arrow-up']"
        ></FontAwesomeIcon>
      </span>
    </span>
  </th>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue'

const props = defineProps({
  sort: {
    type: Number,
    validator(value) {
      if (value === 0 || value === 1 || value === -1) {
        return 'success'
      }
      return 'warning'
    },
    required: true
  },
  defaultDirection: {
    type: Number,
    required: true
  },
  number: {
    type: Boolean,
    default: false
  },
  col: {
    type: Object,
    default() {
      return {}
    }
  }
})

const emit = defineEmits(['sort'])
const loaded = ref(false)

onMounted(() => {
  loaded.value = true
})

const descSortIcon = computed(() => {
  return {
    activeSort: props.sort == -1
  }
})

const ascSortIcon = computed(() => {
  return {
    activeSort: props.sort == 1
  }
})
</script>

<style lang="scss">
.sortHeading {
  white-space: nowrap;
  cursor: pointer;
}

.flex-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  &.row-reverse {
    flex-direction: row-reverse;
  }

  .text-heading {
    text-align: left;
  }

  .numeric-heading {
    text-align: right;
    display: flex;
    overflow: hidden;
  }

  .sort-icon {
    transition: all 0.5s ease-out;
  }

  &:hover {
    .icon-wrapper {
      .sort-icon {
        path {
          fill: $braid-grey-3;
        }
      }
    }
  }

  p {
    margin-bottom: 0;
  }

  .icon-wrapper {
    display: flex;
    flex-direction: row;
    padding: 0 0.3rem;
    align-items: center;

    .sort-icon {
      path {
        fill: #ffffff;
      }

      &.activeSort {
        path {
          fill: $braid-font-color;
        }
        font-size: 1em;
      }
    }
  }
}

.panel {
  .icon-wrapper {
    .sort-icon {
      path {
        fill: $braid-grey-1;
      }
    }
  }
}

thead {
  th:last-of-type {
    .flex-wrapper {
      .numeric-heading {
        padding-right: 1rem;
      }
    }
  }
}
</style>
