<template>
  <template v-if="isAuthenticated">
    <Header id="header" :links="menuLinks"> </Header>
    <div id="sidebar">
      <LeftSidebar
        class="leftsidebar"
        :links="menuLinks"
        :route-name="route.name"
        @toggle-left-nav="toggleCollapse"
      ></LeftSidebar>
    </div>
    <main id="main">
      <ToastContainer>
        <ContextMenu ref="contextMenu">
          <Alert>
            <ProgressBar>
              <router-view v-slot="{ Component }" :key="$route.path">
                <component :is="Component" />
              </router-view>
            </ProgressBar>
          </Alert>
        </ContextMenu>
      </ToastContainer>
    </main>
  </template>
  <template v-else> </template>
</template>

<script setup>
import { ref, provide, nextTick } from 'vue'
import { useRoute } from 'vue-router'
import { useAuth0 } from '@auth0/auth0-vue'
import Header from '@/components/template/TheHeader.vue'
import ToastContainer from '@/components/template/ToastContainer'
import Alert from '@/components/template/Alert'
import ContextMenu from '@/components/template/ContextMenu'
import LeftSidebar from '@/components/template/TheLeftSidebar'
import ProgressBar from '@/components/template/TheProgressBar'
import links from './MenuItems.js'

const menuLinks = links
const route = useRoute()
const contextMenu = ref(null)
const { isAuthenticated } = useAuth0()

const collapsedLeftNav = ref(false)
provide('collapsedLeftNav', collapsedLeftNav)
const toggleCollapse = () => {
  document.getElementById('app').classList.toggle('collapsedLeftNav')
  nextTick(() => {
    collapsedLeftNav.value = !collapsedLeftNav.value
  })
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

* {
  font-size: 14px;
  color: $braid-font-color;
  font-family: 'Manrope', sans-serif;
  // font-family: 'Open Sans', sans-serif;
  font-weight: 500;
}
</style>
<style lang="scss">
#app {
  min-height: 100vh;
  min-width: 100%;
  overflow-x: hidden;
  margin: 0;
  column-gap: 0;
  row-gap: 0;

  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 3.2rem auto;
  grid-template-areas:
    ' header '
    ' main ';
}

#header {
  grid-area: header;
}

#sidebar {
  display: none;
  grid-area: sidebar;
  max-height: calc(100vh - 3.2rem);
}

.leftsidebar {
  padding-bottom: 2rem;
}

#main {
  grid-area: main;
  max-height: calc(100vh - 3.2rem);
  height: 100%;
  overflow: hidden;
  background-color: $braid-grey-0;
}

@media (min-width: 768px) {
  #app {
    grid-template-columns: 13.2rem auto;
    grid-template-rows: 3.2rem auto;
    grid-template-areas:
      ' header header '
      ' sidebar main ';

    &.collapsedLeftNav {
      grid-template-columns: 3.2rem auto;
    }
  }
  #sidebar {
    display: block;
  }
}

.hide-page {
  visibility: hidden;
}
.show-page {
  visibility: visible;
}
</style>
