<template>
  <div>
    <TableForm>
      <b>Consignment {{ props.consignment.consignmentNumber }}</b>
      <TableRow
        v-model="local.trackingNumber"
        type="text"
        label="Tracking Number"
        @change="$emit('updateDispatch', local)"
      />
    </TableForm>
    <Divider :align="'center'">
      <div v-if="props.consignmentLength == props.consignmentIndex + 1">
        <div class="inline-flex align-items-center">
          <button
            type="button"
            class="btn btn-outline-secondary"
            @click="addConsignment"
          >
            Add Consignment
          </button>
        </div>
      </div>
    </Divider>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, nextTick, inject, watch } from 'vue'
const _ = require('lodash')

const emit = defineEmits(['updateDispatch'])
const props = defineProps({
  consignment: {
    type: Object,
    default() {
      return {}
    }
  },
  tenantChannelId: {
    type: String,
    default: ''
  },
  consignmentIndex: {
    type: Number,
    default: 0
  },
  consignmentLength: {
    type: Number,
    default: 0
  },
  addConsignment: {
    type: Function,
    default() {
      return
    }
  }
})
const tenantChannelId = ref(props.tenantChannelId)

const local = ref({
  consignmentNumber: props.consignmentIndex,
  trackingNumber: ''
})

/*
 *****************      VALIDATION PROVIDER
 */
// watch(items.value, (newValue) => {
//   let changesMade = !_.isEqual(toRaw(newValue), INITIAL_ITEMS)
//   updateFormChanges(_UNIQUE_STATE_ID, changesMade)
// })

/*
 **************** MODALS
 */

/*
 **************** TABLE FORM
 */
import TableForm from '@/components/table/form/TableForm'
import rowContainer from '@/components/table/form/RowContainer'
import TableRow from '@/components/table/form/TableRow'
import Divider from '@/components/template/Divider'

const setConsignment = () => {
  if (Object.keys(props.consignment).length == 0) {
    setDefaults()
    return
  }
  local.value = props.consignment
}

const setDefaults = () => {
  local.value = {
    consignmentNumber: props.consignmentIndex,
    trackingNumber: ''
  }
}

onMounted(async () => {
  try {
    setConsignment()
  } catch (e) {
    console.log(e)
  }
})
</script>
