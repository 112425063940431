<template>
  <span
    v-for="(infoIcon, index) in infoIcons"
    :key="`infoIcon_${col.name}_${index}`"
  >
    <span v-if="infoIcon.condition(col, row)" class="icon">
      <span
        v-if="infoIcon.type === 'Success' && infoIcon.show"
        :title="infoIcon.hoverText(col, row)"
        @mousedown="onClick(infoIcon, $event)"
      >
        <font-awesome-icon
          :icon="['fas', 'circle-check']"
          class="table-icon-success"
        ></font-awesome-icon>
      </span>
      <span
        v-if="infoIcon.type === 'Info' && infoIcon.show"
        :title="infoIcon.hoverText(col, row)"
        @mousedown="onClick(infoIcon, $event)"
      >
        <font-awesome-icon
          :icon="['fas', 'circle-info']"
          class="table-icon-info"
        ></font-awesome-icon>
      </span>
      <span
        v-else-if="infoIcon.type === 'Warning' && infoIcon.show"
        :title="infoIcon.hoverText(col, row)"
        @mousedown="onClick(infoIcon, $event)"
      >
        <font-awesome-icon
          :icon="['fas', 'triangle-exclamation']"
          class="table-icon-warning"
        ></font-awesome-icon>
      </span>
      <span
        v-else-if="infoIcon.type === 'Error' && infoIcon.show"
        :title="infoIcon.hoverText(col, row)"
        @mousedown="onClick(infoIcon, $event)"
      >
        <font-awesome-icon
          :icon="['fas', 'circle-exclamation']"
          class="table-icon-error"
        ></font-awesome-icon>
      </span>
    </span>
  </span>
</template>
<script setup>
import { ref } from 'vue'

const props = defineProps({
  col: {
    type: Object,
    default() {
      return {}
    }
  },
  row: {
    type: Object,
    default() {
      return {}
    }
  },
  infoIcons: {
    type: Array,
    default() {
      return []
    }
  }
})

const onClick = (infoIcon, event) => {
  event.stopPropagation()
  infoIcon.onClick(props.col, props.row)
}
</script>
<style lang="scss">
.table-icon-info,
.table-icon-warning,
.table-icon-error {
  margin: 0 0.2rem;
}
.table-icon-success path {
  color: green;
}
.table-icon-info path {
  color: $braid-purple;
}
.table-icon-warning path {
  color: orange;
}
.table-icon-error path {
  color: red;
}
</style>
<style scoped lang="scss">
span {
  display: flex;
  cursor: pointer;
  height: fit-content;

  &.icon {
    width: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
