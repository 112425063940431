<template>
  <nav ref="sidebar" :class="{ collapsedLeftNav: collapsedLeftNav }">
    <div class="scrollable">
      <LeftMenuItem
        v-for="link in menuLinks"
        :key="link.id"
        :route="link.route"
        :label="link.label"
        :expanded="link.expanded"
        :active-routes="link.activeRoutes"
        :active="link.activeRoutes.includes(routeName)"
        :sub-items="link.subItems"
        :icon="link.icon"
        class="menu-item"
        @toggle-visibility="(data) => updateColumnPreferences(link.id, data)"
      >
      </LeftMenuItem>
    </div>
    <div class="collapse-button">
      <a class="menu-link" title="Collapse Left Bar" @click="toggleCollapse">
        <font-awesome-icon
          :icon="['fas', 'square-caret-up']"
          size="lg"
          :rotation="leftNavCollapseCaretRotation"
          class="collapse-icon"
        ></font-awesome-icon>
      </a>
    </div>
  </nav>
</template>

<script setup>
import LeftMenuItem from '@/components/template/LeftMenuItem.vue'
import { ref } from 'vue'
import useApi from '@/components/useApi'

const props = defineProps({
  routeName: { type: String, default: '' },
  links: {
    type: Array,
    default: () => {
      ;[]
    }
  }
})
const emit = defineEmits(['toggleLeftNav'])

const api = useApi()
const menuLinks = ref()
const locations = ref([])
const localStorageName = ref('braidLeftNav')

const readLeftNavPreferences = async () => {
  await getLocations()
  let settings = checkForChanges()
  menuLinks.value = settings
}

const getLocations = async () => {
  let retrievedLocations = await api.getLocations()

  if (!retrievedLocations || retrievedLocations.length === 0) {
    return
  }

  let formattedLocations = retrievedLocations.map((location) => {
    return {
      ...location,
      value: location.tenantLocationId,
      label: location.name
    }
  })
  locations.value = formattedLocations
}

const checkForChanges = () => {
  let settings = localStorage[localStorageName.value]
  if (!settings) {
    props.links.forEach((link) => {
      if (link.expanded) {
        return
      }
      link.expanded = false
    })
    localStorage[localStorageName.value] = JSON.stringify(props.links)
    return props.links
  }

  settings = JSON.parse(settings)

  let index = 0
  let length = props.links.length
  let formatted = []
  let needToSave = false

  for (index; index < length; index++) {
    let link = props.links[index]
    let id = link.id

    if (link.label === 'Inventory') {
      addLocationsToMenuItems(link)
    }

    let savedSetting = settings.find((setting) => {
      return setting.id == id
    })

    // THIS IS A NEW ADDED LINK
    if (!savedSetting) {
      formatted.push(link)
      needToSave = true
      continue
    }

    // SUBITEMS HAVE BEEN ADDED WHERE THEY DIDNT BEFORE
    if (savedSetting.subItems == undefined && link.subItems != undefined) {
      formatted.push(link)
      needToSave = true
      continue
    }

    // SUBITEMS HAVE BEEN MODIFIED
    if (
      JSON.stringify(savedSetting.subItems) !== JSON.stringify(link.subItems)
    ) {
      savedSetting.subItems = link.subItems
      needToSave = true
    }

    formatted.push(savedSetting)
  }

  if (settings.length !== formatted.length) {
    needToSave = true
  }

  if (needToSave) {
    localStorage[localStorageName.value] = JSON.stringify(formatted)
  }

  return formatted
}

const addLocationsToMenuItems = (link) => {
  link.subItems.splice(1)

  let index = 0
  let length = locations.value.length
  let locationIdIndex = 2
  for (index; index < length; index++) {
    let location = locations.value[index]
    let id = `10.${locationIdIndex}`
    link.subItems.push({
      id: id,
      label: location.name,
      route: {
        name: 'Inventory',
        query: { filter: `locationId=${location.tenantLocationId}` },
        params: { pageId: id }
      }
    })
    locationIdIndex++
  }
}

readLeftNavPreferences()

const updateColumnPreferences = (id, data) => {
  let index = menuLinks.value.findIndex((link) => link.id == id)
  if (index == -1) {
    return
  }
  menuLinks.value[index].expanded = data
  localStorage[localStorageName.value] = JSON.stringify(menuLinks.value)
}

const collapsedLeftNav = ref(false)
const leftNavCollapseCaretRotation = ref(90)
const toggleCollapse = () => {
  collapsedLeftNav.value = !collapsedLeftNav.value

  emit('toggleLeftNav')
}
</script>

<style lang="scss">
nav {
  display: block;
  min-height: 100%;
  background-color: $braid-grey-1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 100%;
  border-right: 1px solid rgba(0, 0, 0, 0.05);
}

.leftsidebar {
  margin-top: 0;
}

.menu-item + .menu-item {
  margin-top: 1rem;
}

.collapse-button {
  padding-left: 1rem;
  height: 2rem;
  margin-top: 1rem;
  display: flex;
  align-items: center;
  .collapse-icon {
    transform: rotate(270deg);
    cursor: pointer;
  }
}

.collapsedLeftNav {
  .collapse-button {
    display: flex;
    justify-content: center;
    padding-left: 0;
    .collapse-icon {
      transform: rotate(90deg);
    }
  }
}

@media (min-width: $lg) {
  .menu-item {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  .menu-item + .menu-item {
    margin-top: 0rem;
  }
}
</style>

<style scoped lang="scss">
.scrollable {
  padding-top: 1rem;
  max-height: calc(100vh - 5.2rem);
  overflow-y: scroll;
}
/* total width */
.scrollable::-webkit-scrollbar {
  width: 16px;
}

/* scrollbar itself */
.scrollable::-webkit-scrollbar-thumb {
  background-color: rgba(#babac0, 0);
  border-radius: 16px;
  border: 5px solid #f4f4f4;
}
.scrollable::-webkit-scrollbar-thumb:hover {
  background-color: rgba(#babac0, 0.6);
}

/* set button(top and bottom of the scrollbar) */
.scrollable::-webkit-scrollbar-button {
  display: none;
}
</style>
