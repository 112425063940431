<template>
  <ValidationProvider>
    <TableForm>
      <!-- DATE FROM -->
      <TableRow
        v-model="local.dateFrom"
        type="date"
        label="Date from"
      ></TableRow>
      <!-- ./DATE FROM -->

      <!-- DATE TO -->
      <TableRow v-model="local.dateTo" type="date" label="Date to"></TableRow>
      <!-- ./DATE TO -->

      <!-- CHANNEL -->
      <TableRow
        v-model="local.channels"
        label="Integrations"
        :options="channels"
        multiple
        type="select"
      ></TableRow>
      <!-- ./CHANNEL -->

      <!-- LOCATIONS -->
      <TableRow
        v-model="local.locations"
        label="Stock locations"
        :options="locations"
        type="select"
        multiple
      ></TableRow>
      <!-- ./LOCATIONS -->

      <!-- WEEKS AHEAD -->
      <TableRow
        v-model="local.weeksAhead"
        label="Weeks ahead"
        type="number"
      ></TableRow>
      <!-- ./WEEKS AHEAD -->
    </TableForm>

    <button class="btn btn-grey borderless" @click="getRows()">
      Get restock report</button
    ><br />
  </ValidationProvider>

  <div class="scrollable-wrapper restock-report">
    <div class="scrollable">
      <template v-for="(supplierSkus, supplierId) in rows" :key="supplierId">
        <TableForm>
          <RowContainer
            :heading="getSupplier(supplierId).name || ''"
            button
            auto-collapse
            :button-text="getPoButtonText(supplierId)"
            :button-icon="getPoButtonIcon(supplierId)"
            @button-clicked="poButtonAction(supplierId, supplierSkus)"
            @toggle-visibility="(data) => toggleTable(supplierId, data)"
          >
          </RowContainer>
        </TableForm>
        <TableGrid
          v-if="showTable[supplierId]"
          :ref="(el) => (tableRefs[supplierId] = el)"
          :cols="formattedCols"
          :rows="supplierSkus"
          :total-count="supplierSkus.length"
          id-key="tenantSkuId"
          :pagination="false"
          class="poGrid"
          scrollable
        ></TableGrid>
      </template>
    </div>
  </div>
  <BraidModal
    ref="createPoModal"
    :hide-hook="clearSelected"
    @show="addInitialSelected"
    @hide="clearSelected"
  >
    <template #title>Create PO for {{ poSupplier.name }}</template>
    <template #body>
      <TableGrid
        ref="grid"
        :rows="poRows"
        :cols="poCols"
        id-key="tenantSkuId"
        :total-count="poRows.length"
        :initial-selected="initialSelected"
        select-id="m2mSkuSupplierId"
        checkbox
        select-all
        scrollable
      ></TableGrid>
    </template>
    <template #footer>
      <button class="btn btn-red" @click="closePoModal">Close</button>
      <button
        :disabled="disableRaisePo"
        class="btn btn-green"
        @click="createPo"
      >
        Create PO
      </button>
    </template>
  </BraidModal>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { onMounted, watch, ref, computed, nextTick } from 'vue'
import useApi from '@/components/useApi'
const { formatDate, formatDateTime, UTC_OFFSET, dayjs } = require('@/dayjs')
const _ = require('lodash')

import RowContainer from '@/components/table/form/RowContainer'

import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'
import ReceiveMultipleSkusModalVue from '@/components/modals/ReceiveMultipleSkusModal.vue'

const router = useRouter()
const api = useApi()
const tableRefs = ref({})

const displayGrid = ref(false)
const grid = ref(null)
const weeksAhead = ref(null)
const rows = ref([])
const formattedCols = ref([])
const channels = ref([])
const locations = ref([])
const currentStock = ref([])
const skusOnOrder = ref([])
const sales = ref([])
const skuSupplierRecords = ref([])
const skus = ref([])
const suppliers = ref([])
const dateFrom = ref(dayjs().subtract(1, 'month').format('YYYY-MM-DD'))
const dateTo = ref(dayjs().format('YYYY-MM-DD'))
const channel = ref('1')
const weeks = ref([])
const local = ref({
  channels: [],
  locations: [],
  weeksAhead: null,
  dateFrom: dateFrom.value,
  dateTo: dateTo.value
})
const supplierPos = ref({})
const showTable = ref([])
const initialSelected = ref([])

onMounted(async () => {
  await getChannels()
  await getLocations()
  getWeeks()
})

const toggleTable = (supplierId, value) => {
  showTable.value[supplierId] = value
  nextTick(() => {
    if (value && tableRefs.value[supplierId] != undefined) {
      tableRefs.value[supplierId].updateRows()
    }
  })
}

const getPoButtonText = (supplierId) => {
  return supplierPos.value[supplierId]
    ? 'Go to purchase order'
    : 'Create purchase order'
}

const getPoButtonIcon = (supplierId) => {
  return supplierPos.value[supplierId]
    ? 'fa-arrow-up-right-from-square'
    : 'circle-plus'
}

const poButtonAction = (supplierId, skus) => {
  if (!supplierPos.value[supplierId]) {
    openPoModal(supplierId, skus)
    return
  }

  let id = supplierPos.value[supplierId]
  goToPurchaseOrder(id)
}

const addInitialSelected = () => {
  if (!grid.value) {
    return
  }

  grid.value.addToSelected(initialSelected.value)
}

const disableRaisePo = computed(() => {
  if (!grid.value) {
    return true
  }

  return grid.value.selected.length === 0
})

const clearSelected = () => {
  if (!grid.value) {
    return
  }

  grid.value.clearSelected()
}

const getWeeks = () => {
  let index = 1
  let length = 13

  for (index; index < length; index++) {
    weeks.value.push({
      id: index,
      label: index,
      value: index
    })
  }
}

const getChannels = async () => {
  let response = await api.get('/channels/')
  channels.value = formatForSelect(
    response.data.channels,
    'name',
    'tenantChannelId'
  )
}

const getLocations = async () => {
  let locationData = await api.getLocations()
  locations.value = formatForSelect(locationData, 'name', 'tenantLocationId')
}

const formatForSelect = (records, labelField, idField) => {
  let formatted = []
  let index = 0
  let length = records.length

  for (index; index < length; index++) {
    let record = records[index]
    let formattedRecord = {
      label: record[labelField],
      value: record[idField]
    }
    formatted.push(formattedRecord)
  }

  return formatted
}

const getSupplier = (supplierId) => {
  let supplier = suppliers.value.find((supplier) => {
    return supplier.tenantSupplierId == supplierId
  })

  return supplier || {}
}

const getRows = async () => {
  const args = {
    dateFrom: local.value.dateFrom,
    dateTo: local.value.dateTo,
    tenantChannelIds: local.value.channels,
    restockReportOrders: 1
  }
  let orderResponse = await api.get('/orders/', args)
  sales.value = orderResponse.data.orderItems
  let skuArray = sales.value.map((record) => {
    return record.sku
  })

  let inventoryResponse = await api.get('/skus/grouped-location', {
    ids: local.value.locations,
    groupedLocation: 1,
    skus: skuArray
  })

  currentStock.value = inventoryResponse.data.inventory
  skus.value = inventoryResponse.data.skus
  suppliers.value = inventoryResponse.data.suppliers
  skusOnOrder.value = inventoryResponse.data.skusOnOrder
  skuSupplierRecords.value = inventoryResponse.data.skuSuppliers

  updateColumns()
  formatRows()
}

const updateColumns = () => {
  formattedCols.value = [...cols]

  if (!local.value.locations || local.value.locations.length <= 1) {
    return
  }

  let locationValues = local.value.locations
  let index = 0
  let length = locationValues.length

  for (index; index < length; index++) {
    let locationId = locationValues[index]
    let location = locations.value.find((location) => {
      return location.value == locationId
    })
    formattedCols.value.splice(5, 0, {
      label: `Stock in ${location.label}`,
      name: `location_${location.value}`,
      visible: true,
      number: true,
      hideZero: true
    })
  }
}

const formatRows = () => {
  let index = 0
  let length = sales.value.length
  let formatted = []

  for (index; index < length; index++) {
    let salesRecord = sales.value[index]
    let sku = salesRecord.sku
    let skuRecord = skus.value.find((record) => record.sku === sku)

    if (!skuRecord) {
      continue
    }

    let tenantSkuId = skuRecord.tenantSkuId

    let currentStockRecords = currentStock.value.filter(
      (record) => record.skuId == tenantSkuId
    )

    let onOrderRecord = skusOnOrder.value.find((record) => {
      return record.tenantSkuId == tenantSkuId
    })

    let onOrder = getOnOrder(onOrderRecord)
    let inStock = getInStock(currentStockRecords)

    let formattedRecord = {
      ...skuRecord,
      ...inStock,
      onOrder: onOrder,
      sold: parseInt(salesRecord.quantity)
    }

    formattedRecord.restock = getRestockQuantity(formattedRecord)
    formatted.push(formattedRecord)
  }
  rows.value = _.groupBy(formatted, 'tenantSupplierId')
  displayGrid.value = true
}

const getOnOrder = (record) => {
  if (!record) {
    return 0
  }

  return parseInt(record.onOrder)
}

const getInStock = (records) => {
  let index = 0
  let length = records.length
  let totalQuantity = 0
  let formatted = {}

  for (index; index < length; index++) {
    let record = records[index]
    let quantity = record.quantity
    if (quantity) {
      totalQuantity += parseInt(quantity)
    }

    formatted[`location_${record.locationId}`] = quantity
  }

  formatted.inStock = totalQuantity
  return formatted
}

const getRestockQuantity = (record) => {
  let timePeriod = dayjs(local.value.dateTo).diff(
    dayjs(local.value.dateFrom),
    'day'
  )
  let averageSales = Math.ceil(
    (record.sold / timePeriod) * (local.value.weeksAhead * 7)
  )
  let inStock = record.inStock
  let onOrder = record.onOrder
  let restock = averageSales - inStock - onOrder
  return restock > 0 ? restock : 0
}

const gotoSku = (id) => {
  router.push(`/sku/${id}`)
}

const cols = [
  {
    label: 'SKU',
    name: 'sku',
    visible: true,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true
  },
  {
    label: 'Title',
    name: 'title',
    visible: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    visible: true
  },
  {
    label: 'Variants',
    name: 'variant',
    visible: true
  },
  {
    label: 'Sold',
    name: 'sold',
    visible: true,
    number: true,
    hideZero: true
  },
  {
    label: 'Total in stock',
    name: 'inStock',
    visible: true,
    number: true,
    hideZero: true
  },
  {
    label: 'On order',
    name: 'onOrder',
    visible: true,
    number: true,
    hideZero: true
  },
  {
    label: 'Restock',
    name: 'restock',
    visible: true,
    number: true,
    hideZero: true
  }
]

/**
 * PO MODAL
 */

const canOrderUnits = (row) => {
  return row.orderByUnits
}

const canOrderCases = (row) => {
  return row.orderByCase
}

const removeSkuCheck = (sku) => {
  if (!grid.value) {
    return
  }

  let index = grid.value.selected.indexOf(sku.m2mSkuSupplierId)

  if (index !== -1) {
    grid.value.toggle(sku)
  }
}

const addSkuCheck = (sku, initial = false) => {
  if (initial) {
    initialSelected.value.push(sku.m2mSkuSupplierId)
    return
  }

  if (!grid.value) {
    return
  }

  let index = grid.value.selected.indexOf(sku.m2mSkuSupplierId)

  if (index === -1) {
    grid.value.toggle(sku)
  }
}

const getQuantityOrdered = (row, initial = false) => {
  let casesOrdered = row.casesOrdered
  let unitsPerCase = row.unitsPerCase

  if (!casesOrdered || casesOrdered == '') {
    row.quantityOrdered = 0

    if (initial) {
      return
    }

    removeSkuCheck(row)
    return
  }

  if (!unitsPerCase || unitsPerCase == '') {
    unitsPerCase = 1
  }

  row.quantityOrdered = parseInt(casesOrdered) * parseInt(unitsPerCase)
  addSkuCheck(row, initial)
}

const createPoModal = ref(null)
const poCols = ref([
  {
    label: 'SKU',
    name: 'sku',
    visible: true
  },
  {
    label: 'Title',
    name: 'title',
    visible: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    visible: true
  },
  {
    label: 'Supplier SKU',
    name: 'supplierSku',
    visible: true
  },
  {
    label: 'Variants',
    name: 'variant',
    visible: true
  },
  {
    label: 'Restock',
    name: 'restock',
    number: true,
    visible: true
  },
  {
    label: 'Qty to order',
    name: 'casesOrdered',
    visible: true,
    number: true,
    input: true,
    inputType: 'number',
    change: getQuantityOrdered
  },
  {
    label: 'Case Size',
    name: 'unitsPerCase',
    visible: true,
    number: true,
    width: 70
  }
])
const poRows = ref([])
const poSupplier = ref({})
const selectedSkus = ref([])

const getPoRows = (supplierId, skus) => {
  initialSelected.value = []
  let formatted = []
  let supplierRecords = skuSupplierRecords.value.filter((record) => {
    return record.tenantSupplierId == supplierId
  })
  let index = 0
  let length = supplierRecords.length
  for (index; index < length; index++) {
    let supplierRecord = supplierRecords[index]

    let sku = skus.find((sku) => {
      return sku.tenantSkuId == supplierRecord.tenantSkuId
    })

    if (!sku) {
      continue
    }

    let title = sku.sku

    if (supplierRecord.stockOptions == 'perPack') {
      title += ` (Pack of ${supplierRecord.unitsPerCase})`
    }

    let formattedRecord = sku

    formattedRecord.sku = title
    formattedRecord.casesOrdered = sku.restock
    formattedRecord.unitsPerCase = supplierRecord.unitsPerCase || 1
    formattedRecord.m2mSkuSupplierId = supplierRecord.m2mSkuSupplierId
    formattedRecord.price = supplierRecord.price
    formattedRecord.priceOptions = supplierRecord.priceOptions
    getQuantityOrdered(formattedRecord, true)

    formatted.push({ ...sku })
  }

  return formatted
}

const openPoModal = (supplierId, skus = []) => {
  poRows.value = getPoRows(supplierId, skus)
  selectedSkus.value = poRows.value.filter((sku) => {
    return sku.casesOrdered > 0
  })
  poSupplier.value = getSupplier(supplierId)
  poSupplier.value = getSupplier(supplierId)
  createPoModal.value.show()
  nextTick(() => {
    grid.value.updateRows()
  })
}

const closePoModal = () => {
  poRows.value = []
  poSupplier.value = {}
  createPoModal.value.hide()
}

const getSelectedPurchaseOrderItems = () => {
  const m2mSkuSupplierIds = grid.value.selected
  let purchaseOrderItems = []

  let index = 0
  let length = m2mSkuSupplierIds.length
  for (index; index < length; index++) {
    let m2mSkuSupplierId = m2mSkuSupplierIds[index]
    let purchaseOrderItem = poRows.value.find((purchaseOrderItem) => {
      return purchaseOrderItem.m2mSkuSupplierId == m2mSkuSupplierId
    })

    if (!purchaseOrderItem) {
      continue
    }

    purchaseOrderItems.push(purchaseOrderItem)
  }

  return purchaseOrderItems
}

const createPo = async () => {
  const purchaseOrderItems = getSelectedPurchaseOrderItems()
  const data = {
    purchaseOrder: {
      tenantSupplierId: poSupplier.value.tenantSupplierId,
      purchaseOrderItems: purchaseOrderItems,
      status: 0,
      deliveryLocationId: null
    }
  }
  let response = await api.post('/purchaseOrders/', data)
  let id = response.data.insertedId
  supplierPos.value[poSupplier.value.tenantSupplierId] = id
  closePoModal()
}

const goToPurchaseOrder = (id) => {
  router.push(`/purchase-orders/${id}`)
}

const openRowContainers = () => {
  if (current == previous) {
    return
  }
  let show = {}
  for (let key in Object.keys(current)) {
    show[key] = false
  }
  showTable.value = show
}
</script>
<style lang="scss">
@import 'scss/scrollable.scss';
@import 'scss/buttons.scss';
</style>
<style>
.restock-report table {
  table-layout: fixed;
}
</style>
