<template>
  <div class="row skeleton-row">
    <div class="col-12">
      <div class="skeleton skeleton-button"></div>
    </div>
  </div>
</template>

<script setup></script>

<style lang="scss" scoped>
.skeleton-row {
  padding-bottom: 1rem;
}

.skeleton-button {
  height: 1.5rem;
  width: 100%;
}

.skeleton {
  position: relative;
  overflow: hidden;
  background-color: #dddbdd;

  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 2s infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}
</style>
