import { ref } from 'vue'
const {
  formatDateTime,
  UTC_OFFSET,
  DATE_TIME_FORMAT_STRING
} = require('@/dayjs')

export function useAudit() {
  const auditHistoryCols = ref([
    {
      label: 'Audit',
      name: 'audit',
      visible: true
    },
    {
      label: 'ID',
      name: 'id',
      visible: true
    },
    {
      label: 'Change',
      name: 'change',
      visible: true
    },
    {
      label: 'Occcured at',
      name: 'updatedAt',
      visible: true
    },
    {
      label: 'Changed by',
      name: 'updatedBy',
      visible: true
    },
    {
      label: 'id',
      name: 'id',
      visible: false
    }
  ])
  const utcOffset = ref(UTC_OFFSET())

  /**
   * Compared the current object to the previous object.
   * Any changes that are in columns with prop visible = true are added to a string to be displayed.
   *
   * CURRENT & PREVIOUS MUST INCLUDE A updatedAt and updatedBy property
   *
   * @param {Object} current
   * @param {Object} previous
   * @param {String} audit
   * @param {Array} cols
   * @returns {Object} formatted for the audit table
   */
  const compareAuditRecords = (current, previous, cols = []) => {
    let change = ''
    if (previous == undefined) {
      return false
    }
    for (let key of Object.keys(current)) {
      if (
        current[key] === previous[key] ||
        key == 'audit' ||
        cols.findIndex((el) => el.name == key) == -1
      ) {
        continue
      }
      let column = cols.find((el) => el.name == key)
      if (column == undefined) {
        continue
      }
      switch (current[key]) {
        case '':
        case null:
        case undefined:
          change += `Deleted ${column.label}; `
        default:
          change += `Set ${column.label} to ${current[key]}; `
      }
    }
    if (change == '') {
      return false
    }

    return {
      audit: current.audit,
      id: current.clientId,
      change: change,
      updatedAt: current.updatedAt,
      updatedBy: current.updatedBy
    }
  }

  /**
   * Spreads both arrays into single array
   * @param {*} auditA
   * @param {*} auditB
   * @returns
   */
  const mergeAudit = (audits) => {
    let merged = []
    audits
      .filter((audit) => audit)
      .forEach((audit) => {
        merged = merged.concat(audit)
      })
    return merged
  }

  /**
   * Sorts audit records based on the updatedAt property
   * @param {*} auditRecords
   * @returns
   */
  const sortAudit = (auditRecords) => {
    return auditRecords.sort((a, b) => {
      let dateA = new Date(a.updatedAt)
      let dateB = new Date(b.updatedAt)
      return dateB.getTime() - dateA.getTime()
    })
  }

  /**
   * Loops through records and formats datetime to dayjs set constant format
   * @param {Array} auditRecords
   * @returns array with formatted updatedAt properties
   */
  const formatAuditDates = (auditRecords) => {
    for (let index in auditRecords) {
      auditRecords[index].updatedAt = formatDateTime(
        auditRecords[index].updatedAt,
        utcOffset.value,
        DATE_TIME_FORMAT_STRING
      )
    }
    return auditRecords
  }

  /**
   *
   * @param {*} paginationSkip
   * @param {*} id
   * @param {*} cols
   * @param {*} allRows
   * @returns
   */
  const processAudit = (id, cols, allRows) => {
    let comparedAudit = []

    for (let index = 0; index < allRows.length - 1; index++) {
      let currentAuditRow = allRows[index]

      let audit = currentAuditRow.audit
      let recordId = id[audit]

      let allRowsSlice = allRows.slice(index + 1)
      let previousAuditRow = allRowsSlice.find((element) => {
        return (
          element[recordId] == currentAuditRow[recordId] &&
          element.audit == currentAuditRow.audit
        )
      })
      let comparison = compareAuditRecords(
        currentAuditRow,
        previousAuditRow,
        cols[audit]
      )
      if (comparison != false) {
        comparedAudit.push(comparison)
      }
    }
    return comparedAudit
  }

  return {
    auditHistoryCols,
    mergeAudit,
    sortAudit,
    formatAuditDates,
    processAudit
  }
}
