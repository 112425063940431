<template>
  <!-- PAGINATION - TABLE 1 has full width. In its own table because it disrupts column width changes for checkbox-cell and delete-cell -->
  <table id="pagination-table">
    <thead class="form-pagination">
      <tr v-if="pagination" class="pagination-bar">
        <th id="paginationCell">
          <div id="paginationWrapper">
            <div id="paginationControls">
              <!-- BUTTON: FIRST PAGE OF RECORDS -->
              <button
                aria-label="First"
                class="btn"
                :disabled="skip <= 0 || disabled"
                @click.prevent="paginate('g', 0)"
              >
                <font-awesome-icon icon="angles-left"></font-awesome-icon>
              </button>
              <!-- ./BUTTON: FIRST PAGE OF RECORDS -->

              <!-- BUTTON: PREVIOUS PAGE OF RECORDS -->
              <button
                class="btn"
                aria-label="Previous"
                :disabled="skip <= 0 || disabled"
                @click.prevent="paginate('p')"
              >
                <font-awesome-icon icon="angle-left"></font-awesome-icon>
              </button>
              <!-- ./BUTTON: PREVIOUS PAGE OF RECORDS -->

              <!-- SKIP DISPLAY -->
              <input
                id="skipDisplay"
                :value="skipDisplay"
                type="number"
                class="form-control"
                :readonly="disabled"
                :disabled="disabled"
                @keyup.enter="(event) => customPageSkip(event.target.value)"
              />
              <!-- ./SKIP DISPLAY -->

              <span id="recordCount" class="input-group-text">
                to {{ endRecordCount }} of {{ totalCount }}
              </span>

              <!-- BUTTON: NEXT PAGE OF RECORDS -->
              <button
                class="btn"
                aria-label="Next"
                :disabled="skip + limit >= totalCount || disabled"
                @click.prevent="paginate('n')"
              >
                <font-awesome-icon icon="angle-right"></font-awesome-icon>
              </button>
              <!-- ./BUTTON: NEXT PAGE OF RECORDS -->

              <!-- BUTTON:  LAST PAGE OF RECORDS -->
              <button
                class="btn"
                aria-label="Last"
                :disabled="skip + limit >= totalCount || disabled"
                @click.prevent="paginate('g', totalCount - limit)"
              >
                <font-awesome-icon icon="angles-right"></font-awesome-icon>
              </button>
              <!-- ./BUTTON:  LAST PAGE OF RECORDS -->
            </div>
          </div>
        </th>
      </tr>
    </thead>
  </table>
  <slot></slot>
  <!-- ./PAGINATION -->
</template>

<script setup>
import { ref, computed } from 'vue'

import { useRouter } from 'vue-router'
const router = useRouter()

const emits = defineEmits(['paginationAction'])
const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  pagination: {
    type: Boolean,
    default: true
  },
  skip: {
    type: Number,
    default: 0
  },
  totalCount: {
    type: [String, Number],
    default: 0
  },
  limit: {
    type: Number,
    default: 20
  }
})

const skipDisplay = computed(() => {
  if (props.totalCount == 0) {
    return 0
  }
  return props.skip + 1
})

/**
 * Computed value of total number of records in list
 */
const endRecordCount = computed(() => {
  let recordCountskip = props.skip || 0
  let end = parseInt(recordCountskip) + props.limit
  if (end > props.totalCount) {
    return props.totalCount
  }
  return end
})
/**
 *
 */

const paginate = (direction, paginateSkip = 0) => {
  let newPageIndex = 0
  switch (direction) {
    case 'p':
      if (props.skip === 0) {
        break
      }
      if (props.skip - props.limit < 0) {
        newPageIndex = 0
        break
      }
      newPageIndex = props.skip - props.limit
      break
    case 'n':
      newPageIndex = props.skip + props.limit
      break
    case 'g':
      if (paginateSkip > props.totalCount) {
        paginateSkip = props.totalCount - props.limit
      }
      newPageIndex = parseInt(paginateSkip)
      break
  }

  emits('paginationAction', newPageIndex)
}
</script>

<style lang="scss" scoped>
.form-pagination {
  background-color: $braid-grey-1;
}

#pagination-table {
  width: 100%;
  #paginationCell {
    padding: 0;
    #paginationWrapper {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 1rem;
      align-items: flex-end;
      .pagination-button {
        margin-right: 1rem;
        & :first-child {
          margin-right: 0.5rem;
        }
      }
      #paginationControls {
        display: flex;
        flex-direction: row;
        #skipDisplay {
          padding: 0 0.25rem;
          margin: 0;
          align-self: center;
          text-align: right;
          width: 20%;
          border: 1px solid rgba(0, 0, 0, 0.15);
        }
        /* Chrome, Safari, Edge, Opera */
        #skipDisplay::-webkit-outer-spin-button,
        #skipDisplay::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        #skipDisplay[type='number'] {
          -moz-appearance: textfield;
        }
        #recordCount {
          background-color: inherit;
          border: 0;
        }
      }
    }
  }
}
</style>
