<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="PurchaseOrder"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    ref="grid"
    :cols="columns"
    :rows="rows"
    :total-count="totalCount"
    :filter-function="getRows"
    :default-sort="defaultSort"
    :info-icons="purchaseOrderItemInfoBlocks"
    id-key="tenantPurchaseOrderItemId"
    :reset-filters="resetFilters"
    scrollable
  ></TableGrid>
</template>

// This is for the keep-alive to work
<script>
export default {
  name: 'PurchaseOrderItemsList'
}
</script>

<script setup>
import {
  ref,
  onActivated,
  watch,
  inject,
  nextTick,
  onDeactivated,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const router = useRouter()
const api = useApi()
const { getColumnFilters, getSort } = useColumnFilters()
const deactivated = ref(false)

const {
  formatDate,
  formatDateForSave,
  formatDateTime,
  formatDateTimeForSave,
  UTC_OFFSET,
  dayjs
} = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

const props = defineProps({
  pageId: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  }
})

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/*
 *****************      GRID
 */

// Template reference to the TableGrid component
const grid = ref(null)

const displayGrid = ref(false)
const rows = ref([])
const totalCount = ref(0)
const defaultSort = ref({ tenantPurchaseOrderId: -1 })
const showArchived = ref(false)
const view = ref('orders')

const handleResponse = (purchaseOrderItems) => {
  purchaseOrderItems.map((item) => {
    return (item.createdAt = formatDateTime(item.createdAt, utcOffset.value))
  })
  rows.value = purchaseOrderItems
}

const getRows = async (args = {}) => {
  loading()
  try {
    progressBarStart()

    if (!args.filter) {
      args.filter = {}
    }

    args.filter.showArchived = 0

    if (router.currentRoute.value.name === 'PurchaseOrderItemsNotReceived') {
      args.notReceived = 1
    }

    let response = await api.get('/purchaseOrderItems', args)
    handleResponse(response.data.purchaseOrderItems)
    totalCount.value = parseInt(response.data.count)
    displayGrid.value = true
    loaded()
    updateFilters(false)
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

const getTableFilters = () => {
  let query = router.currentRoute.value.query
  let sort = query.sort ? getSort(query.sort) : defaultSort.value
  let filter = getColumnFilters(query)
  return { sort, filter }
}

const updateTableFilters = ({ filter, sort }) => {
  if (!grid.value) {
    return
  }
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
}

const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)

const resetFilters = () => {
  appliedFilters.value = {}
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'

const cols = [
  {
    label: 'Sku',
    name: 'sku',
    filter: true,
    visible: true,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true
  },
  {
    label: 'Purchase Order',
    name: 'poNumber',
    filter: true,
    visible: true,
    onClick: {
      route: '/purchase-orders/',
      id: 'tenantPurchaseOrderId'
    }
  },
  {
    label: 'Title',
    name: 'title',
    visible: true,
    filter: true
  },
  {
    label: 'Supplier',
    name: 'supplierName',
    visible: true,
    filter: true
  },
  {
    label: 'Unit Cost',
    name: 'priceEach',
    number: true,
    filter: true,
    visible: true
  },
  {
    label: 'Case Cost',
    name: 'priceCase',
    number: true,
    filter: true,
    visible: true
  },
  {
    label: 'Ordered',
    name: 'quantityOrdered',
    number: true,
    filter: true,
    visible: true,
    hideZero: true
  },
  {
    label: 'Received',
    name: 'quantityReceived',
    number: true,
    filter: true,
    visible: true,
    hideZero: true
  },
  {
    label: 'Cancelled',
    name: 'quantityCancelled',
    number: true,
    filter: true,
    visible: true,
    hideZero: true
  },
  {
    label: 'Expected Date',
    name: 'expectedDate',
    date: true,
    filter: true,
    visible: true
  },
  {
    label: 'Comments',
    name: 'comments',
    visible: true
  },
  {
    label: 'Date Raised',
    name: 'createdAt',
    visible: true
  }
]

const getPoItemProblem = (col, row) => {
  let warning = getPoItemWarning(col, row)

  return warning ? true : false
}

const getPoItemProblemText = (col, row) => {
  let warning = getPoItemWarning(col, row)
  switch (warning) {
    case 'MIN_QTY':
      return 'Quantity ordered is lower than minimum order quantity'

    case 'UNITS_PER_CASE':
      return 'Quantity ordered is not a multiple of case size'

    case 'NOT_RECEIVED':
      return 'All stock not received by expected date'

    default:
      return ''
  }
}

const getPoItemWarning = (col, row) => {
  if (row.minOrderQty > row.quantityOrdered) {
    return 'MIN_QTY'
  }

  if (row.unitsPerCase && row.quantityOrdered % row.unitsPerCase !== 0) {
    return 'UNITS_PER_CASE'
  }

  if (
    row.quantityReceived < row.quantityOrdered &&
    dayjs().isAfter(row.expectedDate)
  ) {
    return 'NOT_RECEIVED'
  }
}

const purchaseOrderItemInfoBlocks = ref({
  sku: [
    {
      type: 'Warning',
      show: true,
      hoverText: getPoItemProblemText,
      condition: getPoItemProblem,
      onClick: () => {}
    }
  ]
})
// Default Column Settings

// Template ref to the ColumnPreferences component
const preferences = ref()

// Shows the column preference modal
const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)
const columns = ref()

const localStorageName = ref('braidPurchaseOrderItems')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences } = columnPreferences(localStorageName.value)

columns.value = readColumnPreferences(cols)

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const newOrder = () => {
  router.push('/orders/new')
}

const buttons = ref([
  {
    id: 0,
    label: 'Add new',
    click: newOrder,
    icon: ['fas', 'circle-plus']
  },
  {
    id: 1,
    label: 'Column Settings',
    click: show,
    icon: ['fas', 'gear']
  }
])

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }

  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    filterUpdate.value = true
  }
)

watch(pageLoading, (current, previous) => {
  if (!pageLoading.value) {
    updateTableFilters(tableFilters)
  }
})

const updateFilters = (needRows = true) => {
  let tableFilters = getTableFilters(defaultSort.value)
  if (needRows) {
    getRows(tableFilters)
  }
  updateTableFilters(tableFilters)
}

watch(filterUpdate, (current, previous) => {
  if (!current) {
    return
  }
  updateFilters()
  filterUpdate.value = false
})
</script>

<style scoped></style>
