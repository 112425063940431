<template>
  <div :class="getPanelWidth()" class="spacer" :style="style">
    <div class="block">
      <div class="row">
        <div
          v-if="header !== '' || slots.header"
          class="col-10 header-container"
        >
          <span class="header-title">
            <template v-if="header !== ''">
              {{ header }}
            </template>
            <template v-else-if="slots.header">
              <slot name="header"></slot>
            </template>
          </span>
        </div>
        <div class="col-2">
          <slot name="action"></slot>
        </div>
      </div>
      <div class="row">
        <div
          v-for="col in cols"
          :key="'table-panel' + col"
          :class="getColWidth()"
          class="panel"
        >
          <slot :name="col"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TablePanel'
}
</script>

<script setup>
import { ref, useSlots } from 'vue'

const slots = useSlots()
const props = defineProps({
  cols: {
    type: Number,
    default: 1
  },
  width: {
    type: Number,
    default: 4
  },
  style: {
    type: String,
    default: ''
  },
  header: {
    type: String,
    default: ''
  }
})

const getColWidth = () => {
  if (props.cols == 0) {
    return 'd-none'
  }
  switch (props.cols) {
    case 1:
      return 'col-12'
    case 2:
      return 'col-12 col-xl-6'
    case 3:
      return 'col-12 col-xl-4'
    case 4:
      return 'col-12 col-xxl-3'
    default:
      return 'col-12 col-xl-6'
  }
}

const getPanelWidth = () => {
  switch (props.width) {
    case 0:
      return 'display: none;'
    case 1:
      return 'col-12 col-xxl-3'
    case 2:
      return 'col-12 col-xxl-6'
    case 3:
      return 'col-12 col-xxl-9'
    case 4:
    default:
      return 'col-12'
  }
}
</script>

<style lang="scss" scoped>
.spacer {
  padding: 0.5rem;
  display: inline-grid;
}
.block {
  background-color: $braid-grey-1;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 2px;
  margin: 0.25rem 0;
  padding: 0.75rem;
}

.panel {
  padding: 0.75rem;
}

.header-container,
.panel {
  height: fit-content;
}

row.header-container + row.panel {
  padding-top: 0;
}

.panel + .panel {
  border-left: 1px solid $braid-grey-2;
}

.header-title {
  font-size: 1.5rem;
  font-weight: 600;
  // border-bottom: 1px solid $braid-grey-4;
  padding: 0.75rem 0.5rem 0 0;
}
@media (max-width: 1400px) {
  .spacer-panel {
    display: none;
  }
}
</style>
