<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Returns"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    ref="grid"
    :cols="columns"
    :rows="rows"
    :total-count="totalCount"
    :filter-function="getRows"
    :default-sort="defaultSort"
    id-key="tenantReturnId"
    :row-class="getRowClass"
    scrollable
  ></TableGrid>
</template>

// This is for the keep-alive to work
<script>
export default {
  name: 'ReturnList'
}
</script>

<script setup>
import {
  ref,
  onActivated,
  watch,
  inject,
  nextTick,
  onDeactivated,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})
const router = useRouter()
const api = useApi()
const { getTableFilters, toggleArchived } = useColumnFilters(router)
const deactivated = ref(false)
const columns = ref()

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  pageId: {
    type: String,
    default: ''
  }
})

/**
 * @see  @/components/template/TheProgressBar
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const cols = [
  {
    label: 'Return',
    name: 'returnNumber',
    visible: true,
    onClick: {
      route: '/returns/',
      id: 'tenantReturnId'
    },
    primary: true
  },
  {
    label: 'Order',
    name: 'orderNumber',
    filter: true,
    visible: true
  },
  {
    label: 'Channel',
    name: 'tenantChannelId',
    filter: true,
    multiple: true,
    visible: true
  },
  {
    label: 'Channel return ID',
    name: 'channelReturnId',
    filter: true,
    visible: true
  },
  {
    label: 'Status',
    name: 'status',
    filter: true,
    visible: true
  },

  {
    label: 'Order fulfillment',
    name: 'fulfillmentChannel',
    filter: true,
    displayValues: {
      MERCHANT: 'Merchant',
      '3PL': 'Third party'
    }
  },
  {
    label: 'Refund amount',
    name: 'refundAmount',
    number: true,
    filter: true,
    visible: true,
    visible: true
  },
  {
    label: 'Raised',
    name: 'raisedAt',
    dateTime: true,
    visible: true
  },
  {
    label: 'Original order date',
    name: 'channelOrderCreatedAt',
    dateTime: true
  }
]

/*
 *****************      GRID
 */
const localStorageName = ref('braidReturns')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences, assignColumnFunctions } = columnPreferences(
  localStorageName.value
)
columns.value = readColumnPreferences(cols)

// Template reference to the TableGrid component
const grid = ref(null)

const rows = ref([])
const totalCount = ref(0)
const defaultSort = ref({ returnNumber: -1 })
const showArchived = ref(false)
const channels = ref([])

const getRowClass = (row) => {
  if (row.archivedAt != null && showArchived.value == true) {
    return 'archived-row'
  }
}

const newLocation = () => {
  router.push('/locations/new')
}

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

const getChannelValues = (response = []) => {
  let index = 0
  let length = response.length
  let channelValues = {}
  channels.value = response

  for (index; index < length; index++) {
    let channel = response[index]
    channelValues[channel.tenantChannelId] = channel.name
  }

  let columnIndex = columns.value.findIndex((column) => {
    return column.name === 'tenantChannelId'
  })

  columns.value[columnIndex].displayValues = channelValues

  updateColumns(columns.value)
}

const getRows = async (args = {}) => {
  loading()
  try {
    progressBarStart()

    if (!args.filter) {
      args.filter = {}
    }

    showArchived.value = args.filter.showArchived == 1

    let response = await api.get('/returns', args)
    rows.value = response.data.returns
    getChannelValues(response.data.channels)
    totalCount.value = parseInt(response.data.count)
    loaded()
    updateFilters(false)
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

const updateTableFilters = ({ filter, sort, skip }) => {
  if (!grid.value) {
    return
  }
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
  grid.value.setPage(skip)
}

const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)

const gotoOrder = (id) => {
  router.push('/orders/' + id)
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
// Default Column Settings

// Template ref to the ColumnPreferences component
const preferences = ref()

// Shows the column preference modal
const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  {
    id: 0,
    label: 'Add new',
    click: newLocation,
    icon: ['fas', 'circle-plus']
  },
  {
    id: 1,
    label: 'Column Settings',
    click: show,
    icon: ['fas', 'gear']
  }
])

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }
  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    filterUpdate.value = true
  }
)

const updateFilters = (needRows = true) => {
  let tableFilters = getTableFilters(defaultSort.value)
  if (needRows) {
    getRows(tableFilters)
  }
  updateTableFilters(tableFilters)
}

watch(filterUpdate, (current, previous) => {
  if (!current) {
    return
  }
  updateFilters()
  filterUpdate.value = false
})
</script>
