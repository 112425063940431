<template>
  <div
    :ref="modalEl"
    class="modal"
    tabindex="-1"
    aria-labelledby="crm-modal"
    aria-hidden="true"
  >
    <div class="modal-dialog" :class="modalSize">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <slot name="title"></slot>
          </h5>
          <button
            type="button"
            class="btn btn-purple borderless btn-lg"
            aria-label="Close"
            @click="closeButton"
          >
            <FontAwesomeIcon
              :icon="['far', 'circle-xmark']"
              class="btn-icon"
            ></FontAwesomeIcon>
          </button>
        </div>
        <div class="modal-body">
          <div class="container">
            <slot name="body"></slot>
          </div>
        </div>
        <div class="modal-footer">
          <slot name="footer">
            <button id="rejectButton" class="btn btn-red" @click="rejectButton">
              {{ rejectButtonText }}
            </button>
            <button
              id="confirmButton"
              class="btn btn-green"
              @click="confirmButton"
            >
              {{ confirmButtonText }}
            </button>
          </slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BraidModal'
}
</script>

<script setup>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { Modal } from 'bootstrap'

const props = defineProps({
  showHook: {
    type: Function,
    default() {
      return
    }
  },
  hideHook: {
    type: Function,
    default() {
      return
    }
  },
  rejectButtonText: {
    type: String,
    default: 'Close'
  },
  confirmButtonText: {
    type: String,
    default: 'Save'
  },
  modalSize: {
    type: String,
    default: 'modal-xl'
  }
})

const emit = defineEmits(['reject', 'confirm', 'close', 'show', 'hide'])

// Use function to assign element to reference
const DOM_ELEMENT = ref()
const modalEl = (el) => {
  DOM_ELEMENT.value = el
}

const BOOTSTRAP_MODAL = ref()

onMounted(() => {
  BOOTSTRAP_MODAL.value = new Modal(DOM_ELEMENT.value)
  if (props.showHook) {
    DOM_ELEMENT.value.addEventListener('show.bs.modal', props.showHook)
  }

  if (props.hideHook) {
    DOM_ELEMENT.value.addEventListener('hide.bs.modal', props.hideHook)
  }
})

onBeforeUnmount(() => {
  if (props.showHook) {
    DOM_ELEMENT.value.removeEventListener('show.bs.modal', props.showHook)
  }

  if (props.hideHook) {
    DOM_ELEMENT.value.removeEventListener('hide.bs.modal', props.hideHook)
  }
})

const rejectButton = () => {
  emit('reject')
  hide()
}

const confirmButton = () => {
  emit('confirm')
  hide()
}

const closeButton = () => {
  emit('close')
  hide()
}

const show = () => {
  BOOTSTRAP_MODAL.value.show()
  emit('show')
}

const hide = () => {
  BOOTSTRAP_MODAL.value.hide()
  emit('hide')
}

defineExpose({ show, hide })
</script>

<style lang="scss">
#rejectButton {
  background-color: $braid-green-accessible;
}

#confirmButton {
  background-color: $braid-green-accessible;
}

.modal-title {
  color: $braid-white;
}
</style>
