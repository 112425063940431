<template>
  <ValidationProvider
    v-if="loaded"
    ref="validation"
    v-slot="{ errors, changes }"
  >
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: { disable: errors || !changes }
      }"
    >
    </ActionBar>
    <TableContainer>
      <TablePanel :cols="2" :width="2">
        <template #1
          ><div class="col-12 d-flex justify-content-center mb-3">
            <button class="btn profile-picture-button" @click="uploadImage">
              <img :src="local.picture" />
              <div class="button-overlay">
                <p class="px-1">Edit</p>
                <font-awesome-icon :icon="['fas', 'pen']"></font-awesome-icon>
              </div>
            </button>
          </div>
        </template>
        <template #2>
          <TableForm>
            <TableRow v-model="local.given_name" label="First name" type="text">
            </TableRow>
            <TableRow v-model="local.family_name" label="Last name" type="text">
            </TableRow>
            <TableRow v-model="local.nickname" label="Nickname" type="text">
            </TableRow>
            <TableRow v-model="local.email" label="Email" type="text" read-only>
            </TableRow>
            <TableRow
              v-model="local.email_verified"
              label="Verified"
              type="text"
              read-only
            >
            </TableRow>
            <TableRow label="Roles" type="textarea" read-only> </TableRow>
          </TableForm>
        </template>
      </TablePanel>
      <TablePanel>
        <template #1>
          <TableGrid
            ref="grid"
            :cols="userCols"
            :rows="userProfiles"
            :total-count="totalCount"
          ></TableGrid>
        </template>
      </TablePanel>
    </TableContainer>
  </ValidationProvider>
  <ConfirmLeaveFormModal
    ref="confirmLeaveModal"
    @cancel="closeConfirmModal"
    @confirm="gotoNextPage"
  ></ConfirmLeaveFormModal>
</template>

<script setup>
import { ref, onMounted, inject, computed } from 'vue'

const props = defineProps({})

// Template ref to validation provider component
const validation = ref(null)
const grid = ref(null)
const saving = ref(false)
const local = ref({})
import useApi from '@/components/useApi'
const api = useApi()
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})
import ActionBar from '@/components/content/ActionBar'
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'
import RowContainer from '@/components/table/form/RowContainer'

const userCols = [
  {
    label: 'Name',
    name: 'name',
    visible: true
  },
  {
    label: 'Nickname',
    name: 'nickname',
    visible: true
  },
  {
    label: 'Email',
    name: 'email',
    visible: true
  },
  {
    label: 'Verified',
    name: 'email_verified',
    visible: true
  }
]

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/**
 * Modals
 */
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'
const confirmLeaveModal = ref(null)

const getUser = async () => {
  let response = await api.get('/user/me')
  local.value = response.data
}

const userProfiles = ref([])
const totalCount = ref(0)
const getUsers = async () => {
  let response = await api.get('/user')
  userProfiles.value = Object.values(response.data)
  totalCount.value = userProfiles.value.length
}

const update = async () => {
  await api.put('/user', { user: local.value })
}

const buttons = ref([
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  }
])

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
}

const closeConfirmModal = () => {
  confirmLeaveModal.value.hide()
}

const uploadImage = () => {
  console.log('Upload image')
}

onMounted(async () => {
  loading()
  try {
    progressBarStart()
    await getUser()
    await getUsers()
    loaded.value = true
    progressBarDone()
    loaded()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
})
</script>
<style lang="scss">
.form-wrapper {
  padding: 0.5em 4em 1em 1em;
  background-color: #f9f9f9;
}
.label-right {
  text-align: right;
}
input::-webkit-datetime-edit {
  color: #a6a6a6;
}
input:focus::-webkit-datetime-edit {
  color: inherit !important;
}
.profile-picture-button {
  position: relative;
  padding: 0;

  .button-overlay {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 100;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      opacity: 1;
      visibility: visible;
    }
    p,
    path {
      color: white;
      font-weight: 600;
      font-size: 1.75rem;
      margin: 0;
    }
  }
}
</style>
