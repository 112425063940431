const allConfig = require('../../auth_config.json')

const getConfig = () => {
  let config
  let callbackUrl
  let cacheLocation = 'memory'

  if (process.env.NODE_ENV === 'development') {
    cacheLocation = 'localstorage'
    config = allConfig.development
    callbackUrl = 'http://localhost:8080'
  } else if (process.env.NODE_ENV === 'test') {
    cacheLocation = 'localstorage'
    config = allConfig.test
    callbackUrl = 'https://test.braid-inventory.com'
  } else {
    config = allConfig.production
    callbackUrl = 'https://qa.braid-inventory.com'
  }

  let { domain, clientId, audience, host } = config
  return {
    cacheLocation: cacheLocation,
    domain: domain,
    client_id: clientId,
    audience: audience,
    redirect_uri: callbackUrl
  }
}

module.exports = { getConfig }
