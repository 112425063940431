<template>
  <TableGrid
    ref="addressGrid"
    :cols="cols"
    :rows="addresses"
    :total-count="addresses.length"
    :trclick="openAddressModal"
    id-key="tenantSupplierAddressId"
    scrollable
  ></TableGrid>

  <div v-if="addresses.length == 0" class="row">
    <div class="col-12 d-flex justify-content-center">
      <p class="m-0">No contacts have been added for this supplier.</p>
    </div>
  </div>
  <div v-if="editable" class="row">
    <div class="col-12 d-flex justify-content-center tbl-cell-btn">
      <button type="button" class="btn" @click.prevent="openAddressModal()">
        <font-awesome-icon :icon="['fas', 'circle-plus']"></font-awesome-icon
        ><b>Add Address</b>
      </button>
    </div>
  </div>

  <BraidModal
    ref="addSupplierAddressModal"
    :confirm-button-text="supplierAddressModalText"
    @confirm="addAddress"
  >
    <template #header>Add Supplier Address</template>
    <template #body>
      <TableForm>
        <TableRow
          v-model="selectedRow.name"
          label="Name"
          type="text"
          :rules="{ length: { max: 200 } }"
        >
        </TableRow>

        <TableRow
          v-model="selectedRow.addressType"
          type="select"
          label="Address type"
          :options="[
            { value: 'DELIVERY', label: 'Delivery' },
            { value: 'BILLING', label: 'Billing' }
          ]"
        ></TableRow>
        <TableRow
          v-model="selectedRow.buildingType"
          type="select"
          label="Building type"
          :options="[
            { value: 'BUSINESS', label: 'Business' },
            { value: 'RESIDENTIAL', label: 'Residential' }
          ]"
        ></TableRow>

        <TableRow
          v-model="selectedRow.address1"
          label="Address line 1"
          type="text"
          :rules="{ length: { max: 200 } }"
        >
        </TableRow>

        <TableRow
          v-model="selectedRow.address2"
          label="Address line 2"
          type="text"
          :rules="{ length: { max: 200 } }"
        >
        </TableRow>

        <TableRow
          v-model="selectedRow.address3"
          label="Address line 3"
          type="text"
          :rules="{ length: { max: 200 } }"
        >
        </TableRow>

        <TableRow
          v-model="selectedRow.addressPostcode"
          label="Postcode"
          type="text"
          :rules="{ length: { max: 10 } }"
        >
        </TableRow>

        <TableRow
          v-model="selectedRow.country"
          label="Country"
          :options="countries"
          type="select"
        >
        </TableRow>
      </TableForm>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref, onMounted, inject, toRaw, watch } from 'vue'
const _ = require('lodash')
const uuid = require('uuid')

const selectedRow = ref({
  name: '',
  addressType: '',
  buildingType: '',
  address1: '',
  address2: '',
  address3: '',
  addressPostcode: '',
  country: ''
})

import useApi from '@/components/useApi'
const api = useApi()

import useCountries from '@/composables/selectData/useCountries.js'
const { getCountriesForSelect } = useCountries()
const countries = getCountriesForSelect()

const addresses = ref(props.addressData)

/**
 *****************      TABLE FORM
 */
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

// toRaw ensures we use the original props value, and this isn't updated
// by reactivity
const INITIAL_ADDRESSES = [...toRaw(props.addressData)]

/*
 *****************      VALIDATION PROVIDER
 */
const _UNIQUE_STATE_ID = ref(uuid.v4())

// @see @/components/table/ValidationProvider
const registerFormState = inject('registerFormState')
const updateFormChanges = inject('updateFormChanges')

registerFormState(_UNIQUE_STATE_ID, 'addresses')

watch(addresses.value, (newValue) => {
  let changesMade = !_.isEqual(toRaw(newValue), INITIAL_ADDRESSES)
  updateFormChanges(_UNIQUE_STATE_ID, 'addresses', changesMade)
})

const addressGrid = ref(null)
const rows = ref([])
const cols = ref([
  {
    label: 'Address name',
    name: 'name',
    visible: true
  },
  {
    label: 'Type',
    name: 'addressType',
    visible: true
  },
  {
    label: 'Postcode',
    name: 'addressPostcode',
    visible: true
  },
  {
    label: 'Country',
    name: 'country',
    visible: true
  }
])

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  addressData: {
    type: Array,
    default() {
      return []
    }
  },
  editable: {
    type: Boolean,
    default: false
  }
})

const addSupplierAddressModal = ref(null)

const close = () => {
  addSupplierAddressModal.value.hide()
}

const show = () => {
  addSupplierAddressModal.value.show()
}

const supplierAddressModalText = ref('')
const openAddressModal = (addressId = null) => {
  if (!props.editable) {
    return
  }
  if (addressId) {
    let address = _.find(addresses.value, {
      tenantSupplierAddressId: addressId
    })
    selectedRow.value = JSON.parse(JSON.stringify(address))
    supplierAddressModalText.value = 'Update'
  } else {
    selectedRow.value = {
      name: '',
      addressType: '',
      buildingType: '',
      address1: '',
      address2: '',
      address3: '',
      postcode: '',
      country: ''
    }
    supplierAddressModalText.value = 'Add'
  }
  show()
}

const addAddress = () => {
  let data = JSON.parse(JSON.stringify(selectedRow.value))
  if (data.tenantSupplierAddressId) {
    let index = addresses.value.findIndex(
      (address) =>
        address.tenantSupplierAddressId == data.tenantSupplierAddressId
    )
    addresses.value[index] = data
  } else {
    addresses.value.push(data)
  }
  selectedRow.value = {
    name: '',
    addressType: '',
    buildingType: '',
    address1: '',
    address2: '',
    address3: '',
    postcode: '',
    country: ''
  }
  close()
}

const getAddressesForSave = () => {
  return addresses.value
}

defineExpose({ getAddressesForSave })

onMounted(() => {
  addressGrid.value.updateRows()
})
</script>
