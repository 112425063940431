import countries from '../../../public/data/countries.json'

export default function () {
  const get = () => {
    return countries
  }

  const getForSelect = () => {
    let values = []
    for (let [key, value] of Object.entries(countries)) {
      values.push({
        label: value.country,
        value: value.twoLetter
      })
    }
    return values
  }

  return { getCountriess: get, getCountriesForSelect: getForSelect }
}
