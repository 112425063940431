<template>
  <slot :errors="hasErrors" :changes="hasChanges" />
</template>

<script setup>
/**
 * @See TableRow.vue - the targeted child component
 * This component provides 3 methods to children components:
 *
 * 1. registerFormState, this allows children to register themselves
 * 2. updateErrors: Allows children to change their error status to true or false
 * 3. updateChanges: Allows children to change their changed status to true of false
 *
 * It then provides these values to its slot:
 *
 * 1. hasErrors : Returns true if any children have error == true
 * 2. hasChanges: Returns true if any children have changed == true
 */
import { ref, provide, computed } from 'vue'

const formState = ref({})

const registerFormState = (id, label) => {
  formState.value[`${id.value}_${label}`] = { error: false, changed: false }
}

provide('registerFormState', registerFormState)

const updateErrors = (id, label, errorStatus) => {
  if (typeof errorStatus !== 'boolean') throw 'errorStatus must be a boolean'
  if (formState.value[`${id.value}_${label}`]) {
    formState.value[`${id.value}_${label}`].error = errorStatus
  }
}

const updateChanges = (id, label, changeStatus) => {
  if (typeof changeStatus !== 'boolean') throw 'changeStatus must be a boolean'
  if (formState.value[`${id.value}_${label}`]) {
    formState.value[`${id.value}_${label}`].changed = changeStatus
  }
}

provide('updateFormErrors', updateErrors)
provide('updateFormChanges', updateChanges)

const hasErrors = computed(() => {
  const errors = Object.values(formState.value)

  if (errors.length > 0) {
    return errors
      .map((row) => row.error)
      .reduce((prev, curr) => {
        return prev || curr
      })
  }

  return false
})

const hasChanges = computed(() => {
  const changes = Object.values(formState.value)
  if (changes.length > 0) {
    return changes
      .map((row) => row.changed)
      .reduce((prev, curr) => {
        return prev || curr
      })
  }
  return false
})

const save = () => {
  Object.values(formState.value).forEach((row) => {
    row.changed = false
  })
}

defineExpose({
  changes: hasChanges,
  errors: hasErrors,
  save: save,
  registerFormState,
  updateFormChanges: updateChanges
})
</script>
