<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Supplier"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    ref="grid"
    :cols="columns"
    :rows="rows"
    :total-count="totalCount"
    :filter-function="getRows"
    :default-sort="defaultSort"
    id-key="tenantPurchaseOrderId"
    :row-class="getRowClass"
    :reset-filters="resetFilters"
    scrollable
  ></TableGrid>
</template>

// This is for the keep-alive to work
<script>
export default {
  name: 'PurchaseOrdersList'
}
</script>

<script setup>
import {
  ref,
  onActivated,
  watch,
  inject,
  nextTick,
  onDeactivated,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const router = useRouter()
const api = useApi()
const { getTableFilters, toggleArchived } = useColumnFilters(router)
const deactivated = ref(false)

const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  pageId: {
    type: String,
    default: ''
  }
})

/**
 * @see  @/components/template/TheProgressBar
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/*
 *****************      GRID
 */

// Template reference to the TableGrid component
const grid = ref(null)

const rows = ref([])
const totalCount = ref(0)
const defaultSort = ref({ poNumber: -1 })
const showArchived = ref(false)

const getRowClass = (row) => {
  if (row.archivedAt != null && showArchived.value == true) {
    return 'archived-row'
  }
}

const newPurchaseOrder = () => {
  router.push('/purchase-orders/new')
}

const getRows = async (args = {}) => {
  loading()
  try {
    progressBarStart()

    if (!args.filter) {
      args.filter = {}
    }

    showArchived.value = args.filter.showArchived == 1

    let response = await api.get('/purchaseOrders', args)
    rows.value = response.data.purchaseOrders
    totalCount.value = parseInt(response.data.count)
    loaded()
    updateFilters(false)
    progressBarDone()
  } catch (e) {
    progressBarFail()
  }
}

const updateTableFilters = ({ filter, sort, skip }) => {
  if (!grid.value) {
    return
  }
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
  grid.value.setPage(skip)
}
const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)

const resetFilters = () => {
  appliedFilters.value = {}
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
// Default Column Settings
const cols = [
  {
    label: 'PO Number',
    name: 'poNumber',
    filter: true,
    visible: true,
    onClick: {
      route: '/purchase-orders/',
      id: 'tenantPurchaseOrderId'
    },
    primary: true,
    width: 150
  },
  {
    label: 'Status',
    name: 'status',
    filter: true,
    multiple: true,
    displayValues: {
      0: 'Draft',
      1: 'Awaiting approval',
      2: 'Sent to supplier',
      3: 'Received'
    },
    visible: true,
    width: 200
  },
  {
    label: 'Supplier',
    name: 'supplierName',
    filter: true,
    visible: true
  },
  {
    label: 'Currency',
    name: 'currency',
    number: true
  },
  {
    label: 'Total units',
    name: 'totalUnits',
    number: true,
    filter: true,
    width: 140
  },
  {
    label: 'Total cost',
    name: 'totalCost',
    visible: true,
    number: true,
    filter: true,
    width: 140
  },
  {
    label: 'Payment terms',
    name: 'paymentTerms',
    filter: true
  },
  {
    label: 'Approved by',
    name: 'approvedBy',
    filter: true
  },
  {
    label: 'Raised by',
    name: 'createdBy',
    filter: true
  },
  {
    label: 'Date raised',
    name: 'createdAt',
    dateTime: true,
    visible: true,
    filter: true
  },
  {
    label: 'Date expected',
    name: 'expectedDate',
    date: true,
    visible: true,
    filter: true
  }
]

// Template ref to the ColumnPreferences component
const preferences = ref()

// Shows the column preference modal
const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)
const columns = ref()

const localStorageName = ref('braidPurchaseOrders')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences } = columnPreferences(localStorageName.value)

columns.value = readColumnPreferences(cols)

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = [
  {
    id: 0,
    label: 'Add New',
    click: newPurchaseOrder,
    icon: ['fas', 'circle-plus']
  },
  {
    id: 1,
    label: 'Column Settings',
    click: show,
    icon: ['fas', 'gear']
  }
]

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }
  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    filterUpdate.value = true
  }
)

const updateFilters = (needRows = true) => {
  let tableFilters = getTableFilters(defaultSort.value)
  if (needRows) {
    getRows(tableFilters)
  }
  updateTableFilters(tableFilters)
}

watch(filterUpdate, (current, previous) => {
  if (!current) {
    return
  }
  updateFilters()
  filterUpdate.value = false
})
</script>
