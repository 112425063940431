import { onActivated, watch } from 'vue'

export function useColumnFilters(router = null) {
  /**
   * Example Input: { filter: 'status*=1,2^paymentStatus=1^fulfillmentChannel=MERCHANT^shippingAddrPostcode=YO30' }
   *
   * Splits string on ^
   *
   * Replaces first instance of '=' in all array elements and converts
   * each array element to an array of column names and filter values
   *
   * Converts array int object into column names
   *
   * @param {Object} query { filter: String } || {}
   * @returns {Object} filter - an object where keys = column names & values = filter values for that column
   */
  const getColumnFilters = (query) => {
    let filter = query?.filter ? query.filter.split('^') : ''

    if (filter != '') {
      filter = filter.map((col) => {
        let columnFilter = col.replace('=', ':')
        columnFilter = columnFilter.split(':')
        let formatted = {}
        // Column[0] is the column name, marking with a * if the column is an array
        // Column[1] is the filter to be set on that column
        formatted[columnFilter[0].replace('*', '')] = columnFilter[0].includes(
          '*'
        )
          ? columnFilter[1].split(',')
          : columnFilter[1]
        return formatted
      })
      filter = filter.reduce(
        (obj, item) => ((obj[Object.keys(item)] = Object.values(item)[0]), obj),
        {}
      )
    }

    return filter
  }

  const getColumnFiltersNoReplace = (query) => {
    let filter = query?.filter ? query.filter.split('^') : ''
    if (filter != '') {
      filter = filter.map((col) => {
        let column = col.replace('=', ':')
        column = column.split(':')
        let formatted = {}
        formatted[column[0]] = column[0].includes('*')
          ? column[1].split(',')
          : column[1]
        return formatted
      })
      filter = filter.reduce(
        (obj, item) => ((obj[Object.keys(item)] = Object.values(item)[0]), obj),
        {}
      )
    }
    return filter
  }

  const getSort = (sortStr) => {
    let sort = {}
    let sortArr = sortStr.split('=')
    sort[sortArr[0]] = sortArr[1]
    return sort
  }

  const getPagination = (paginationStr) => {
    return paginationStr != undefined ? parseInt(paginationStr) : 0
  }

  const getTableFilters = (defaultSort) => {
    let query = router.currentRoute.value.query
    let sort = query.sort ? getSort(query.sort) : defaultSort
    let filter = getColumnFilters(query)
    let page = getPagination(query.page)

    return { sort, filter, skip: page, limit: 20 }
  }

  const toggleArchived = (updateUrl = true) => {
    let query = router.currentRoute.value.query
    query = JSON.parse(JSON.stringify(query))

    let filter = getColumnFilters(query)
    let value = filter.showArchived || ''

    if (value != '' && filter != '') {
      delete filter.showArchived
    } else if (filter != '') {
      filter.showArchived = 2
    } else {
      filter = {}
      filter.showArchived = 2
    }

    if (updateUrl) {
      let filterQuery = ''
      let index = 0

      for (const [key, value] of Object.entries(filter)) {
        if (index != 0) {
          filterQuery += '^'
        }
        filterQuery += key + '=' + value
        index++
      }

      if (filterQuery != '') {
        query.filter = filterQuery
      } else {
        delete query.filter
      }

      router.replace({
        query
      })
    }

    return filter
  }

  return {
    getColumnFilters,
    getColumnFiltersNoReplace,
    getSort,
    getPagination,
    getTableFilters,
    toggleArchived
  }
}
