<template>
  <Button :action="goToRestockReport" text="Restock report" />
  <Button :action="goToInventoryLevelReport" text="Inventory level report" />
  <Button :action="goToEziReturnReport" text="EZI return report" />
  <Button :action="goToOrderItemReport" text="Order item report" />
</template>

<script setup>
import { ref, inject, nextTick, onMounted } from 'vue'
import Button from '@/components/content/widgets/Button'
import useApi from '@/components/useApi'
import { useRouter } from 'vue-router'
const api = useApi()
const router = useRouter()

const goToRestockReport = () => {
  goToPage('/reports/restock-report')
}
const goToEziReturnReport = () => {
  goToPage('/reports/ezi-return-report')
}
const goToInventoryLevelReport = () => {
  goToPage('/reports/inventory-alert-level-report')
}
const goToOrderItemReport = () => {
  goToPage('/reports/order-item-report')
}

const goToPage = (page) => {
  if (!page || page == '') {
    return
  }

  router.push(page)
}

const emit = defineEmits(['done'])
onMounted(() => {
  emit('done')
})
</script>

<style lang="scss"></style>
