<template>
  <div>
    <TableGridPagination
      v-if="!pageLoading"
      :pagination="pagination"
      :has-filters="hasFilters"
      :reset-filters="resetFilters"
      :skip="skip"
      :total-count="totalCount"
      :call-filter="callFilter"
      :limit="limit"
      :update-url="updateUrl"
      @skip="
        (data) => {
          skip = data
        }
      "
    ></TableGridPagination>
    <div class="relative">
      <DropArea
        :display="dragging && dropArea"
        :drop-function="handleDrop"
        :end-drag="endDrag"
      ></DropArea>
      <div
        :class="{ 'scrollable-table': scrollable }"
        @dragenter.prevent="dragging = true"
        @dragover.prevent="dragging = true"
      >
        <table
          :id="tableId"
          class="table data-table table-hover table-fixed vh100"
        >
          <thead>
            <!-- HEADING + SORT -->
            <tr v-if="Object.keys(defaultSort).length !== 0">
              <th v-if="selectAll" class="checkbox-header"></th>
              <th v-if="!selectAll && checkbox" class="checkbox-header"></th>
              <template
                v-for="(col, index) in visibleColumns"
                :key="'TableGrid' + col.label + index"
              >
                <TableGridSort
                  :sort="parseInt(sort[col.name]) || 0"
                  :col="col"
                  :default-direction="DEFAULT_SORT_DIRECTION"
                  @sort="sortColumn"
                >
                </TableGridSort>
              </template>
              <th v-if="props.delete" class="delete-header"></th>
            </tr>
            <!-- ./HEADING + SORT -->

            <!-- JUST HEADING -->
            <tr v-else>
              <th v-if="selectAll && !hasFilters" class="checkbox-header">
                <input
                  v-if="!pageLoading"
                  type="checkbox"
                  :checked="allSelected"
                  @click="toggleAll($event.target.checked, allRows, idKey)"
                />
              </th>
              <th
                v-if="!selectAll && checkbox"
                :key="'TableGridCheckbox'"
                class="checkbox-header"
              ></th>
              <th
                v-for="(col, index) in visibleColumns"
                :key="'TableGrid' + col.label + index"
                :style="{ width: col.displayWidth }"
              >
                <span
                  v-if="!col.button"
                  class="flex-wrapper"
                  :class="{ 'row-reverse': col.number }"
                  :title="col.label"
                >
                  <p
                    class="sortHeading"
                    :class="{
                      'text-heading': !col.number,
                      'numeric-heading': col.number
                    }"
                  >
                    <b>{{ col.label }}</b>
                  </p>
                </span>
              </th>
              <th v-if="props.delete" class="delete-header"></th>
            </tr>
            <!-- JUST HEADING -->

            <!-- FILTERS -->
            <tr v-if="hasFilters">
              <th v-if="selectAll" class="checkbox-header">
                <input
                  type="checkbox"
                  :checked="allSelected"
                  value="1"
                  @click="toggleAll($event.target.checked)"
                />
              </th>
              <th
                v-if="!selectAll && checkbox"
                :key="'TableGridCheckbox'"
                class="checkbox-header"
              ></th>
              <th
                v-for="(column, index) in visibleColumns"
                :key="'TableGrid' + index"
                :style="{ width: column.displayWidth }"
                class="filter-cell"
              >
                <TableGridFilter
                  v-if="column.filter"
                  :ref="TableGridFilterRef.filters"
                  v-model="filter[column.name]"
                  :multiple="column.multiple"
                  :column="column"
                  :update-url="updateUrl"
                  @filter="filterColumns"
                >
                </TableGridFilter>
              </th>
              <th v-if="props.delete" class="delete-header"></th>
            </tr>
            <!-- ./FILTERS -->
          </thead>
          <tbody v-if="totalCount > 0 || pageLoading">
            <tr
              v-for="(row, index) in allRows"
              :key="'row' + row + index"
              :class="rowClass(row)"
              @mousedown.left="rowClick(row)"
            >
              <td v-if="checkbox" class="checkbox-cell" @mousedown.stop>
                <input
                  v-if="!pageLoading"
                  :id="'checkbox' + index"
                  type="checkbox"
                  value="1"
                  :checked="getChecked(row)"
                  @change="toggle(row)"
                />
              </td>
              <td
                v-for="col in visibleColumns"
                :key="col.name + '' + index"
                :style="{ width: col.displayWidth }"
                @click="checkClick(col, $event)"
                @click.right.prevent="
                  ({ x, y }) => rightClick({ x, y }, row[col.name])
                "
              >
                <template
                  v-if="col.displayCheck == undefined || col.displayCheck(row)"
                >
                  <span class="table-cell-group">
                    <SkeletonText v-if="pageLoading"></SkeletonText>
                    <span
                      v-else-if="col.date"
                      :title="formatDate(row[col.name])"
                      >{{ formatDate(row[col.name]) }}</span
                    >

                    <span
                      v-else-if="col.input"
                      :title="row[col.name]"
                      class="input-span"
                    >
                      <TableSelect
                        v-if="col.inputType == 'select'"
                        v-model="row[col.name]"
                        :options="col.displayValues"
                        :display="col.display"
                        :reduce="col.reduce"
                        :read-only="col.readonly ? col.readonly(row) : false"
                        :class="{
                          readonly: col.readonly ? col.readonly(row) : false
                        }"
                        :item-disabled="col.itemDisabled"
                        @mousedown.stop
                        @change="colChange(col, row)"
                        @keyup="colChange(col, row)"
                      ></TableSelect>
                      <input
                        v-else
                        :id="'input' + col.name + index"
                        v-model="row[col.name]"
                        :type="col.inputType"
                        class="form-control"
                        :class="{ 'numeric-data': col.inputType == 'number' }"
                        :min="col.inputType === 'number' ? 0 : ''"
                        :max="col.inputType === 'number' ? row.max : ''"
                        :readonly="col.readonly ? col.readonly(row) : false"
                        @click.stop.prevent="
                          () =>
                            col.inputType == 'date'
                              ? picker('input' + col.name + index)
                              : {}
                        "
                        @mousedown.stop
                        @change="colChange(col, row)"
                        @keyup="colChange(col, row)"
                      />
                    </span>

                    <span
                      v-else-if="col.dateTime"
                      :title="formatDateTime(row[col.name])"
                    >
                      {{ formatDateTime(row[col.name]) }}
                    </span>

                    <span
                      v-else-if="col.relational"
                      :title="getRelDisplay(row, col)"
                      >{{ getRelDisplay(row, col) }}</span
                    >

                    <span v-else-if="col.displayValues" class="text-data">
                      <StatusBadge
                        v-if="
                          col.name === 'status' &&
                          col.displayValues[row[col.name]] != undefined
                        "
                        :display-value="col.displayValues[row[col.name]]"
                      ></StatusBadge>
                      <span
                        v-else
                        class="data"
                        :title="col.displayValues[row[col.name]]"
                      >
                        {{ col.displayValues[row[col.name]] }}
                      </span>
                    </span>

                    <span v-else-if="col.button">
                      <button
                        class="btn btn-purple btn-outline"
                        @click="
                          () => {
                            col.click(row[idKey])
                          }
                        "
                      >
                        {{ col.label }}
                      </button>
                    </span>

                    <span v-else-if="col.boolean">
                      <span v-if="row[col.name]">
                        <FontAwesomeIcon
                          :icon="['fas', 'check']"
                        ></FontAwesomeIcon
                      ></span>
                    </span>

                    <span
                      v-else-if="col.number"
                      :title="row[col.name]"
                      class="data numeric-data"
                    >
                      <template v-if="col.hideZero">
                        {{
                          row[col.name] == 0
                            ? '-'
                            : row[col.name] == null
                            ? '-'
                            : row[col.name]
                        }}
                      </template>
                      <template v-else>
                        {{ row[col.name] }}
                      </template>
                    </span>

                    <span v-else :title="row[col.name]" class="data text-data">
                      <span v-if="col.onClick" @mousedown.stop>
                        <a @click.stop.prevent="handleColClick(row, col)">
                          {{ row[col.name] }}
                        </a>
                      </span>
                      <span v-else :title="row[col.name]">
                        {{ row[col.name] }}
                      </span>
                    </span>

                    <InfoIcon
                      v-if="infoIcons[col.name]"
                      :col="col"
                      :row="row"
                      :info-icons="infoIcons[col.name]"
                    ></InfoIcon>
                  </span>
                </template>
              </td>
              <td v-if="props.delete" class="delete-cell" @mousedown.stop>
                <button
                  v-if="!checkDeleted(row)"
                  class="btn btn-delete"
                  @click.stop="deleteFunction(row, true)"
                >
                  <FontAwesomeIcon
                    class="color-red"
                    :icon="['fas', 'trash']"
                  ></FontAwesomeIcon>
                </button>
                <button
                  v-else
                  class="btn btn-delete"
                  @click.stop="deleteFunction(row, false)"
                >
                  <FontAwesomeIcon
                    :icon="['fas', 'arrow-rotate-left']"
                  ></FontAwesomeIcon>
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <!-- ./TABLE 3 END -->
  </div>
</template>

<script setup>
import {
  ref,
  inject,
  computed,
  onMounted,
  watch,
  nextTick,
  onActivated,
  onUpdated
} from 'vue'
import TableGridPagination from '@/components/table/grid/TableGridPagination'
import TableGridFilter from '@/components/table/grid/TableGridFilter'
import TableGridSort from '@/components/table/grid/TableGridSort'
import StatusBadge from '@/components/table/grid/StatusBadge'
import TableSelect from '@/components/table/form/TableSelect'
import InfoIcon from '@/components/table/grid/InfoIcon'
import DropArea from '@/components/content/DropArea'
const { formatDate, formatDateTime, UTC_OFFSET, dayjs } = require('@/dayjs')
import { useRouter } from 'vue-router'
const router = useRouter()
import { useColumnFilters } from '@/composables/useColumnFilters'
const { getColumnFilters } = useColumnFilters(router)
const uuid = require('uuid')
import { pageLoad } from '@/composables/pageLoad'
const { loading, getLoading } = pageLoad()
import SkeletonText from '@/components/skeleton/SkeletonText.vue'

const pageLoading = computed(() => {
  return getLoading() || props.localLoading
})

/**
 * ************ STARTUP FUNCTIONS ********************
 */
/**
 * Toggles the alert icons, default set to toggle 'Warning' alert icon currently
 */
const toggleAlertIcons = () => {
  emit('toggleAlertIcons', 'Warning')
}

/**
 * ************ EMITS ********************
 */
const emit = defineEmits(['toggleAlertIcons', 'mounted'])

/**
 * ************ PROPS ********************
 */
const props = defineProps({
  // All columns in a list
  cols: {
    type: Array,
    default() {
      return []
    },
    required: true
  },
  // All rows to show in the list
  rows: {
    type: Array,
    default() {
      return []
    }
  },
  rowClass: {
    type: Function,
    default() {
      return
    }
  },
  initialSelected: {
    type: Array,
    default() {
      return []
    }
  },
  // Function to run when a whole row is clicked
  trclick: {
    type: Function,
    default() {
      return false
    }
  },
  // Toggles checkbox
  selectSingle: {
    type: Boolean,
    default: false
  },
  //Id on which to select
  selectId: {
    type: String,
    default: ''
  },
  // Function to run when the delete btn is pressed
  deleteFunction: {
    type: Function,
    default() {
      return {}
    }
  },
  //Function to run when drop event is triggered
  dropFunction: {
    type: Function,
    default() {
      return {}
    }
  },
  // Function to run when filters have been applied in TableGridFilter
  filterFunction: {
    type: Function,
    default() {
      return {}
    }
  },
  // Total number of rows
  totalCount: {
    type: [String, Number],
    default: 0,
    required: true
  },
  // Default sort of TableGridSort
  defaultSort: {
    type: Object,
    default() {
      return {}
    }
  },
  checkDeleted: {
    type: Function,
    default() {
      return {}
    }
  },
  idKey: {
    type: String,
    default: ''
  },
  caption: {
    type: String,
    default: ''
  },
  // Bool to add checkboxes to start of each element
  checkbox: {
    type: Boolean,
    default: false
  },
  // Bool if multiple pages of records
  pagination: {
    type: Boolean,
    default: true
  },
  // Bool if drop area on table
  dropArea: {
    type: Boolean,
    default: false
  },
  delete: {
    type: Boolean,
    default: false
  },
  selectAll: {
    type: Boolean,
    default: false
  },
  displaySelected: {
    type: Boolean,
    required: false
  },
  basic: {
    type: Boolean,
    required: false
  },
  filterOptions: {
    type: Object,
    default() {
      return {}
    }
  },
  // Limit of records to show
  limit: { type: Number, default: 20 },
  initialFilter: {
    type: Object,
    default() {
      return {}
    }
  },
  initialSort: {
    type: Object,
    default() {
      return {}
    }
  },
  updateUrl: {
    type: Boolean,
    default: true
  },
  infoIcons: {
    type: Object,
    default() {
      return {}
    }
  },
  scrollable: {
    type: Boolean,
    default: false
  },
  resetFilters: {
    type: Function,
    default() {
      return
    }
  },
  localLoading: {
    type: Boolean,
    default: false
  }
})
/**
 * ************ REFS ********************
 */

const allRows = ref(
  Array.apply(null, Array(5)).map(function () {
    return {}
  })
)

const selected = ref([])
// Page loaded flag
const loaded = ref(false)
// Default Sort
const DEFAULT_SORT_DIRECTION = ref(1)
// Record to start pargination from
const skip = ref(0)
// Applied filter object to the table
const filter = ref({})
// Applied Sort to the table
const sort = ref({})
const dragging = ref(false)
// Record limit for a page on the table
const limit = ref(props.limit)
// Table Columns to show
const columns = ref(props.cols)
// Extra context menu buttons
const contextMenuButtons = ref([
  {
    key: 'hideAlertIconsBtn',
    click: toggleAlertIcons,
    icon: ['fas', 'eye'],
    text: 'Toggle warning icons'
  }
])
// Table Grid Filter input component references
const TableGridFilterRef = { filters: ref([]) }
const showContextMenu = inject('showContextMenu')

const getChecked = (row) => {
  let value = props.selectId ? row[props.selectId] : row

  return selected.value.includes(value)
}

const clearSelected = () => {
  selected.value = []
}

const addToSelected = (values = []) => {
  if (!Array.isArray(values)) {
    return
  }

  // THIS IS TO STOP ANY DUPLICATES - HOWEVER I DON'T KNOW IF SOMETHING WILL NEED DUPLICATES IN FUTURE
  let index = 0
  let length = values.length
  for (index; index < length; index++) {
    let value = values[index]
    let inSelected = selected.value.includes(value)

    if (inSelected) {
      continue
    }

    selected.value.push(value)
  }
}

const toggleAll = (checked) => {
  if (!checked) {
    selected.value = []
    return
  }

  let index = 0
  let length = allRows.value.length
  for (index; index < length; index++) {
    let row = allRows.value[index]
    let value = props.selectId ? row[props.selectId] : row

    if (selected.value.indexOf(value) !== -1) {
      continue
    }

    selected.value.push(value)
  }
}

const toggle = (row) => {
  let value = props.selectId ? row[props.selectId] : row
  let currIndex = selected.value.indexOf(value)
  if (currIndex !== -1) {
    selected.value.splice(currIndex, 1)
    return
  }

  if (props.selectSingle) {
    selected.value = [value]
    return
  }

  selected.value.push(value)
}

const allSelected = computed(() => {
  let numberSelected = 0

  allRows.value.forEach((row) => {
    if (selected.value.includes(row[props.idKey])) {
      numberSelected++
    }
  })

  return allRows.value.length == numberSelected
})

/**
 * ******************** METHODS ********************
 */

/**
 * Show context menu function
 */
const rightClick = ({ x, y }, rowData) =>
  showContextMenu(rowData, true, x, y, contextMenuButtons.value)

/**
 * Updates columns ref when col prop is updated
 */
watch(
  () => props.cols,
  (value) => {
    columns.value = value
  }
)

const updateRows = (rows = props.rows) => {
  allRows.value = rows
}

watch(
  () => props.rows,
  (value) => {
    updateRows(value)
  }
)

const picker = (id) => {
  let dateInput = document.getElementById(id)

  dateInput.showPicker()
}

/**
 *  List of columns visible on the TableGrid
 */
const visibleColumns = computed(() => {
  if (!columns.value || columns.value.length == 0 || !loaded.value) {
    return []
  }
  let cols = columns.value.filter((col) => col.visible)
  return getColumnWidth(cols)
})

/**
 * Computed Array of column values showing which columns have a filter applied
 */
const hasFilters = computed(() => {
  if (!columns.value || columns.value.length == 0) {
    return []
  }
  return columns.value
    .map((col) => col.filter || false)
    .filter((value) => typeof value === 'boolean')
    .reduce((curr, prev) => curr || prev)
})

const tableId = uuid.v4()

/**
 * Calculates the widths of each column in the table
 *
 * Adheres to the width set in the cols (in pixels), yet if there are no auto fill cols in the visible cols
 * then all cols will have auto even width
 *
 */
const getColumnWidth = (cols) => {
  const table = document.getElementById(tableId)
  let totalTableWidth = table.getBoundingClientRect().width
  let setWidthCols = cols.filter((col) => col.width).map((col) => col.width)
  let autoWidthCols = cols.filter((col) => !col.width)
  let tableWidthRemaining = setWidthCols.reduce(
    (previousValue, currentValue) => previousValue - currentValue,
    totalTableWidth
  )

  let noOfAutoCols = autoWidthCols.length
  cols.forEach((col) => {
    if (noOfAutoCols == 0 && col.width) {
      let width = 100 / setWidthCols.length
      col.displayWidth = `${width}%`
      return
    } else if (noOfAutoCols != 0 && col.width) {
      col.displayWidth = `${col.width}px`
      return
    }
    let width = ((tableWidthRemaining / totalTableWidth) * 100) / noOfAutoCols
    col.displayWidth = `${width}%`
  })
  return cols
}

/**
 * On mounted.
 *
 * If there is an initial filter (got from URL and passed as prop)
 * then set this
 */
onMounted(() => {
  if (props.initialFilter) {
    filter.value = props.initialFilter
  }
  nextTick(() => (loaded.value = true))
  emit('mounted')
})

const hideContextMenu = inject('hideContextMenu')
/**
 * Handles a click on a given col
 */
const handleColClick = (row, col) => {
  hideContextMenu()
  let id = row[col.onClick.id]

  if (col.onClick.function) {
    col.onClick.function(id)
    return
  }

  router.push(col.onClick.route + id)
}

/**
 * On row change (through inline inputs) call change function for column
 */
const colChange = _.debounce((col, row) => {
  if (!col.change) {
    return
  }
  col.change(row)
}, 250)

/**
 * Formats the filters within the filter reference for the tableGridFilter components
 *
 * Sets Skip to 0 so that the first page is shown
 */
const formatFilters = () => {
  skip.value = 0
  Object.keys(filter.value).forEach((key) => {
    if (
      filter.value[key] === '' ||
      !filter.value[key] ||
      filter.value[key] === null
    ) {
      delete filter.value[key]
    }
  })
}

/**
 * Formats the filters arguments and calls the filter
 */
const callFilter = () => {
  let sortValue =
    Object.keys(sort.value).length > 0 ? sort.value : props.defaultSort
  let args = {
    filter: filter.value,
    sort: sortValue,
    limit: parseInt(limit.value),
    skip: parseInt(skip.value)
  }
  props.filterFunction(args)
}

/**
 * Formats the filter columns and calls the filter
 */
const filterColumns = async () => {
  formatFilters()
  callFilter()
}

/**
 * Resets all settings (except sort) for the table
 */
const resetFilters = (callFilter = true) => {
  limit.value = props.limit
  skip.value = 0
  let query = router.currentRoute.value.query
  query = JSON.parse(JSON.stringify(query))
  query.filter = getColumnFilters(query)
  if (query.filter.showArchived) {
    filter.value = {
      showArchived: query.filter.showArchived
    }
    query.filter = `showArchived=${query.filter.showArchived}`
  } else {
    delete query.filter
    filter.value = {}
  }
  delete query.page
  router.replace({
    query
  })
  props.resetFilters()

  if (callFilter) {
    filterColumns()
  }
}

/**
 * Sorts a column in a given direction, if column is already sorted then reverse the direction
 *
 * If props.updateUrl is true, sort will be updated within the URl
 * @param {String} colName
 */
const sortColumn = (colName) => {
  let currentSort = sort.value[colName]
  let sortValue = DEFAULT_SORT_DIRECTION.value
  if (currentSort == 1) {
    sortValue = -1
  }
  sort.value = {}
  sort.value[colName] = sortValue
  filterColumns()

  let query = router.currentRoute.value.query
  query = JSON.parse(JSON.stringify(query))
  // ADD SORT TO URL
  if (props.updateUrl) {
    let newSort = `${colName}=${sortValue}`
    query.sort = newSort
    router.replace({
      query
    })
  }
}

/**
 * Handles the on click event for a row
 */
const rowClick = (row) => {
  if (!props.trclick) {
    return
  } else if (props.idKey) {
    props.trclick(row[props.idKey])
  } else {
    props.trclick(row)
  }
}

/**
 * Function runs when a td cell is clicked.
 * If cell has own onClick function, it cancels the event of the row click
 */
const checkClick = (col, event) => {
  if (col.onClick) {
    event.stopPropagation()
  }
}

/**
 * Exposed function.
 *
 * Sets filter when is retrieved from URL.
 * This is done on columnPreferences change or on filter change (inc page open)
 */
const setFilter = (data) => {
  for (let field in data) {
    let value = data[field]
    filter.value[field] = value
  }

  Object.keys(filter.value).forEach((key) => {
    const filterReference = TableGridFilterRef.filters.value.find(
      (filter) => filter.col === key
    )
    if (!filterReference || filter.value[key] == '') {
      return
    }
    filterReference.setEditFilterFlag()
    filterReference.setPreviousFilter(filter.value[key])
  })
}

const clearPreviousFilters = () => {
  const filters = TableGridFilterRef.filters.value

  filters.forEach((filter) => {
    filter.setPreviousFilter()
  })
}

/**
 * Exposed function.
 *
 * Sets sort when retrieved from URL.
 */
const setSort = (data) => {
  sort.value = data
  let sortStr = ''
  for (let [key, value] of Object.entries(data)) {
    sortStr = `${key}=${value}`
  }
  let query = router.currentRoute.value.query
  query.sort = sortStr
  router.replace({
    query: query
  })
}

/**
 * Exposed function.
 *
 * Sets page whem retrieved from URl.
 */
const setPage = (data) => {
  skip.value = data
  if (props.updateUrl) {
    let query = router.currentRoute.value.query
    query.page = skip.value
    router.replace({
      query: query
    })
  }
}

/**
 * Gets the display value for a relational field
 */
const getRelDisplay = (row, col) => {
  const val = row[col.name]
  let relField = col.relationalField
  let filter = {}
  filter[relField] = val
  let displayVal = _.find(col.object, filter)
  if (!displayVal) {
    return ''
  }
  return displayVal[col.relationalDisplay]
}

/**
 * DROPAREA
 */
const handleDrop = (e) => {
  endDrag()
  props.dropFunction(e)
}

const endDrag = (e) => {
  dragging.value = false
}

defineExpose({
  filterColumns,
  setFilter,
  setSort,
  setPage,
  resetFilters,
  clearPreviousFilters,
  addToSelected,
  toggle,
  clearSelected,
  selected,
  updateRows
})
</script>

<style lang="scss">
.scrollable-table {
  overflow-y: scroll;
  max-height: calc(100vh - 13.2rem);
  padding-bottom: 1rem;
}

/* total width */
.scrollable-table::-webkit-scrollbar {
  width: 16px;
}

/* scrollbar itself */
.scrollable-table::-webkit-scrollbar-thumb {
  background-color: rgba(#babac0, 0);
  border-radius: 16px;
  border: 5px solid #f4f4f4;
}
.scrollable-table::-webkit-scrollbar-thumb:hover {
  background-color: rgba(#babac0, 0.6);
}

/* set button(top and bottom of the scrollbar) */
.scrollable-table::-webkit-scrollbar-button {
  display: none;
}

table {
  max-height: max-content;
  height: min-content;
}
thead {
  height: fit-content;
  position: sticky;
  top: 0;
  background-color: $braid-grey-0;
  tr {
    border-style: none;
    .filter-cell {
      border-width: 0;
    }
  }
}
tbody {
  border-top: none !important;
  tr {
    height: 0.797rem;
    margin-left: 0.5rem;
    border: 1px solid $braid-grey-1;
    cursor: default;
    td {
      .table-cell-group {
        display: flex;
        align-items: center;
        .input-span {
          width: 100%;
          input.numeric-data {
            align-self: right;
          }
        }
      }
      vertical-align: middle;
      width: auto;
      .data {
        display: inline-block;
        width: 100%;
        padding-left: 0.3rem;
        vertical-align: middle;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .text-data {
        text-align: left;
        overflow: hidden;
        span {
          cursor: text;
        }
      }
      .numeric-data {
        text-align: right;
        span {
          cursor: text;
        }
      }
      .status-badge {
        min-width: 7rem;
        max-width: 7rem;
      }
      &.checkbox-cell {
        width: 2rem;
      }
      &.delete-cell {
        width: 4rem;
        .btn-delete {
          visibility: hidden;
          transition: none;
        }
      }

      &:last-of-type {
        .numeric-data {
          padding-right: 1rem;
        }
      }
    }
    &:hover .delete-cell .btn-delete {
      visibility: visible;
    }
  }
}

.panel {
  thead {
    background-color: $braid-grey-1;
  }
}

.color-red path {
  color: $error-color;
}
.archived-row {
  background: $info-color;
}
</style>

<style lang="scss" scoped>
a {
  text-decoration: underline !important;
  color: $braid-purple !important;
  cursor: pointer;
  &:hover {
    color: $braid-light-purple;
  }
}
.relative {
  position: relative;
}
</style>
