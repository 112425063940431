<template>
  <TablePanel v-if="!bulkUpdate" :cols="1" :width="2" header="Suppliers">
    <template #1>
      <TableForm>
        <RowContainer
          v-for="(supplier, index) in suppliers"
          :key="'supplier' + index"
          :indent-level="1"
          :heading="supplier.name || 'Supplier'"
          :colspan="2"
          :auto-collapse="supplier.tenantSupplierId ? true : false"
        >
          <!-- Supplier -->
          <TableRow
            v-model="supplier.tenantSupplierId"
            :indent-level="2"
            type="select"
            :options="supplierOptions"
            label="Supplier"
            display="label"
            :reduce="(value) => value.value"
            :read-only="!editable"
            @change="(id) => updateSkuSupplierRows(supplier, id)"
            @search="searchSuppliers"
          ></TableRow>
          <!-- Supplier -->

          <template v-if="supplier.tenantSupplierId != null">
            <!-- Order type -->
            <TableRow
              v-model="supplier.supplierSku"
              label="Supplier SKU"
              :indent-level="2"
              type="text"
              :read-only="!editable"
            ></TableRow>
            <!-- Order type -->
          </template>
        </RowContainer>
      </TableForm>
      <div class="col tbl-cell-btn">
        <SkeletonButton v-if="pageLoading"></SkeletonButton>
        <button v-else class="btn" type="button" @click="addSupplier">
          <font-awesome-icon :icon="['fas', 'circle-plus']">
          </font-awesome-icon>
          <b>Add Supplier</b>
        </button>
      </div>
    </template>
  </TablePanel>
  <TablePanel header="Trading Price">
    <template v-if="bulkUpdate" #action>
      <FormPagination
        :disabled="changes"
        :pagination="pagination"
        :skip="skip"
        :total-count="totalCount"
        :limit="limit"
        @pagination-action="paginate"
      ></FormPagination>
    </template>
    <template #1>
      <template v-for="(row, index) in skuSupplierPricingRows" :key="index">
        <div
          class="row sku-supplier-pricing-row"
          :class="{ collapsed: row.collapsed }"
        >
          <div v-if="row.collapsed" class="col-11">
            <div class="row">
              <div v-if="bulkUpdate" class="col-2">
                <b>SKU:</b>
                <p>
                  {{ row.sku }}
                </p>
              </div>
              <div v-if="bulkUpdate" class="col-2">
                <b>MPN:</b>
                <p>
                  {{ row.mpn }}
                </p>
              </div>
              <div v-else class="col-2">
                <b>Supplier:</b>
                <p>
                  {{
                    supplierOptions.find(
                      (supplier) => supplier.value == row.tenantSupplierId
                    )?.label
                  }}
                </p>
              </div>
              <div class="col-3">
                <b>Description:</b>
                <p class="m-0">
                  Stock ordered by
                  {{
                    row.stockOptions == 'perUnit'
                      ? 'units.'
                      : `cases of ${row.unitsPerCase}.`
                  }}
                  {{ row.price }} per
                  {{ row.priceOptions == 'perUnit' ? 'unit.' : 'case.' }}
                </p>
              </div>

              <div class="col-2">
                <b>Price:</b>
                <p>{{ row.price }}</p>
              </div>
              <div v-if="row.stockOptions == 'perPack'" class="col-2">
                <b>Units Per Case:</b>
                <p>
                  {{ row.unitsPerCase }}
                </p>
              </div>
            </div>
          </div>
          <div v-else class="col-11">
            <div class="row">
              <div v-if="bulkUpdate" class="col-2">
                <div class="row h-100">
                  <div class="col-12">
                    <b>MPN</b>
                  </div>
                  <div class="col-12 d-flex align-items-end">
                    <input
                      ref="input"
                      v-model="row.mpn"
                      class="table-text-input"
                      type="text"
                      readonly
                      disabled
                    />
                  </div>
                </div>
              </div>

              <div v-else class="col-2">
                <div class="row h-100">
                  <div class="col-12 no-overflow" title="Supplier">
                    <b>Supplier</b>
                  </div>
                  <div class="col-12 d-flex align-items-end">
                    <v-select
                      ref="input"
                      v-model="row.tenantSupplierId"
                      class="table-select-input form-control table-form-select"
                      :options="supplierOptions"
                      label="label"
                      :append-to-body="true"
                      :reduce="(supplier) => supplier.value"
                      @update:model-value="
                        (value) => (row.tenantSupplierId = value)
                      "
                    ></v-select>
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="row h-100">
                  <div class="col-12 no-overflow" title="Ordered By">
                    <b>Ordered By</b>
                  </div>
                  <div class="col-12 d-flex align-items-end">
                    <v-select
                      ref="input"
                      v-model="row.stockOptions"
                      class="table-select-input form-control table-form-select"
                      :options="[
                        {
                          label: 'Unit',
                          value: 'perUnit'
                        },
                        {
                          label: 'Case',
                          value: 'perPack'
                        }
                      ]"
                      label="label"
                      :append-to-body="true"
                      :reduce="(option) => option.value"
                      @update:model-value="
                        (value) => (row.stockOptions = value)
                      "
                    ></v-select>
                  </div>
                </div>
              </div>

              <div v-if="row.stockOptions == 'perPack'" class="col-2">
                <div class="row h-100">
                  <div class="col-12 no-overflow" title="Price By">
                    <b>Price By</b>
                  </div>
                  <div class="col-12 d-flex align-items-end">
                    <v-select
                      ref="input"
                      v-model="row.priceOptions"
                      class="table-select-input form-control table-form-select"
                      :options="[
                        {
                          label: 'Unit',
                          value: 'perUnit'
                        },
                        {
                          label: 'Case',
                          value: 'perPack'
                        }
                      ]"
                      label="label"
                      :append-to-body="true"
                      :reduce="(option) => option.value"
                      @update:model-value="
                        (value) => (row.priceOptions = value)
                      "
                    ></v-select>
                  </div>
                </div>
              </div>

              <div v-if="row.stockOptions == 'perPack'" class="col-2">
                <div class="row h-100">
                  <div class="col-12 no-overflow" title="Units Per Case">
                    <b>Units Per Case</b>
                  </div>
                  <div class="col-12 d-flex align-items-end">
                    <input
                      ref="input"
                      v-model="row.unitsPerCase"
                      class="table-number-input"
                      type="number"
                      :min="0"
                      @input="
                        updatePricing(row, 'unitsPerCase', $event.target.value)
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="col-2">
                <div class="row h-100">
                  <div
                    class="col-12 no-overflow"
                    title="Minimum Order Quantity "
                  >
                    <b>Minimum Order Quantity</b>
                  </div>
                  <div class="col-12 d-flex align-items-end">
                    <input
                      ref="input"
                      v-model="row.minOrderQty"
                      class="table-number-input"
                      type="number"
                      :min="0"
                    />
                  </div>
                </div>
              </div>

              <div v-if="row.stockOptions != null" class="col-1">
                <div class="row h-100">
                  <div class="col-12 no-overflow" title="Price Per Unit">
                    <b>Price Per Unit</b>
                  </div>
                  <div class="col-12 d-flex align-items-end">
                    <input
                      v-if="row.priceOptions != 'perUnit'"
                      ref="input"
                      v-model="row.unitPrice"
                      class="table-number-input"
                      type="number"
                      :min="0"
                      readonly
                      disabled
                    />
                    <input
                      v-else
                      ref="input"
                      v-model="row.unitPrice"
                      class="table-number-input"
                      type="number"
                      :min="0"
                      @input="
                        updatePricing(row, 'unitPrice', $event.target.value)
                      "
                    />
                  </div>
                </div>
              </div>
              <div v-if="row.stockOptions == 'perPack'" class="col-1">
                <div class="row h-100">
                  <div class="col-12 no-overflow" title="Price Per Case">
                    <b>Price Per Case</b>
                  </div>
                  <div class="col-12 d-flex align-items-end">
                    <input
                      v-if="row.priceOptions == 'perUnit'"
                      ref="input"
                      v-model="row.casePrice"
                      class="table-number-input"
                      type="number"
                      :min="0"
                      readonly
                      disabled
                    />
                    <input
                      v-else
                      ref="input"
                      v-model="row.casePrice"
                      class="table-number-input"
                      type="number"
                      :min="0"
                      @input="
                        updatePricing(row, 'casePrice', $event.target.value)
                      "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-1">
            <button
              v-if="row.collapsed"
              class="btn"
              @click="row.collapsed = false"
            >
              <font-awesome-icon :icon="['fas', 'pen']"> </font-awesome-icon>
            </button>
            <button
              v-if="!row.collapsed"
              class="btn"
              @click="row.delete = row.delete == 1 ? 0 : 1"
            >
              <font-awesome-icon
                v-if="row.delete == 1"
                :icon="['fas', 'arrow-rotate-left']"
              >
              </font-awesome-icon>
              <font-awesome-icon v-else :icon="['fas', 'trash']">
              </font-awesome-icon>
            </button>
          </div>
        </div>
      </template>
      <div
        v-if="skuSupplierPricingRows.length > 0 && !bulkUpdate"
        class="row button-row"
      >
        <div class="tbl-cell-btn">
          <button
            class="btn"
            type="button"
            :disabled="!lastFilled"
            @click="
              () => {
                addSupplierPrice()
              }
            "
          >
            <font-awesome-icon :icon="['fas', 'circle-plus']">
            </font-awesome-icon>
            <b>Add Supplier Price</b>
          </button>
        </div>
      </div>
    </template>
  </TablePanel>
</template>

<script setup>
import { ref, computed, toRaw, watch, inject, toRef } from 'vue'
import useApi from '@/components/useApi'

const api = useApi()
const uuid = require('uuid')

import TableForm from '@/components/table/form/TableForm'
import RowContainer from '@/components/table/form/RowContainer'
import TableRow from '@/components/table/form/TableRow'
import FormPagination from '@/components/table/form/FormPagination'
import { pageLoad } from '@/composables/pageLoad'
import SkeletonButton from '@/components/skeleton/SkeletonButton.vue'

const { getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const emit = defineEmits('update')

const props = defineProps({
  skuSuppliers: {
    type: Object,
    default() {
      return {}
    }
  },
  changes: {
    type: Boolean,
    default: false
  },
  editable: {
    type: Boolean,
    default: true
  },
  searchList: {
    type: Array,
    default() {
      return []
    }
  },
  bulkUpdate: {
    type: Boolean,
    default: false
  }
})

const skuSupplierPricingRows = ref([])
const allSkuSupplierPricingRows = ref([])
const INITIAL_SKU_SUPPLIERS = ref([])
const lastFilled = ref(false)
const suppliersList = toRef(props, 'searchList')

const pagination = ref(false)
const limit = 10
const skip = ref(0)
const totalCount = ref(0)

const getLastFilled = () => {
  let lastRow =
    skuSupplierPricingRows.value[skuSupplierPricingRows.value.length - 1] || {}
  lastFilled.value = lastRow.stockOptions != undefined
}

const updatePricing = (row, col, value) => {
  switch (col) {
    case 'unitsPerCase':
      if (row.priceOptions == 'perPack') {
        row.unitPrice = (
          Math.round((row.casePrice / value) * 100) / 100
        ).toFixed(2)
      } else {
        row.casePrice = (row.unitPrice * value).toFixed(2)
      }
      break

    case 'casePrice':
      row.unitPrice = (
        Math.round((value / row.unitsPerCase) * 100) / 100
      ).toFixed(2)
      break

    case 'unitPrice':
      row.casePrice = (value * row.unitsPerCase).toFixed(2)
      break
  }
  row.price = value
}

const setSkuSupplierPricingRows = () => {
  let rows = props.skuSuppliers

  INITIAL_SKU_SUPPLIERS.value = [...toRaw(rows)]
  let collapsed = props.bulkUpdate ? false : true

  let formatted = rows.map((pricing) => {
    let formatted = {
      ...pricing,
      collapsed: collapsed
    }

    if (pricing.priceOptions == 'perPack') {
      formatted.casePrice = formatted.price
      updatePricing(formatted, 'casePrice', formatted.price)
    } else if (formatted.priceOptions == 'perUnit') {
      formatted.unitPrice = formatted.price
      updatePricing(formatted, 'unitPrice', formatted.price)
    }

    return formatted
  })
  skuSupplierPricingRows.value = formatted.sort((a, b) => {
    switch (true) {
      case a.unitsPerCase > b.unitsPerCase:
        return 1
      case a.unitsPerCase < b.unitsPerCase:
        return -1
      case a.unitsPerCase == b.unitsPerCase:
        return a.price > b.price ? 1 : -1
    }
  })

  totalCount.value = skuSupplierPricingRows.value.length

  if (props.bulkUpdate && totalCount.value > limit) {
    pagination.value = true
    allSkuSupplierPricingRows.value = skuSupplierPricingRows.value
    getPage()
  }

  getLastFilled()
}

const getPage = () => {
  let end = skip.value + limit

  skuSupplierPricingRows.value = allSkuSupplierPricingRows.value.slice(
    skip.value,
    end
  )
}

setSkuSupplierPricingRows()

const suppliers = ref([])
const formatSuppliers = () => {
  let formatted = []
  let grouped = _.groupBy(
    skuSupplierPricingRows.value,
    (r) => r.tenantSupplierId
  )
  Object.values(grouped).forEach((m2mRecords) => {
    let record = m2mRecords[0]
    formatted.push({
      tenantSupplierId: record.tenantSupplierId,
      supplierSku: record.supplierSku,
      name: record.name
    })
  })
  suppliers.value = formatted
}
formatSuppliers()

const save = () => {
  emit('update')
}

const resetInitial = () => {
  INITIAL_SKU_SUPPLIERS.value = [...toRaw(allSkuSupplierPricingRows.value)]
}

const paginate = (index) => {
  skip.value = index
  resetInitial()
  getPage()
}

const addSupplier = () => {
  suppliers.value.push({ name: '', orderType: 'units', orderByUnits: 1 })
}

const updateSkuSupplierRows = (row, id) => {
  let supplier = suppliersList.value.find((supplier) => {
    return supplier.tenantSupplierId == id
  })

  row.name = supplier.name

  if (row.tenantSupplierId) {
    skuSupplierPricingRows.value.forEach((pricing) => {
      if (pricing.tenantSupplierId == row.tenantSupplierId) {
        pricing.tenantSupplierId = id
      }
    })
  } else {
    addSupplierPrice(id)
  }
}

const supplierOptions = computed(() => {
  let searchList = suppliersList.value.map((supplier) => {
    return { value: supplier.tenantSupplierId, label: supplier.name }
  })
  let current = []
  suppliers.value.forEach((supplier) => {
    if (supplier.tenantSupplierId) {
      current.push({ value: supplier.tenantSupplierId, label: supplier.name })
    }
  })
  let merged = searchList.concat(current)
  merged = merged.filter((item, index) => {
    return merged.indexOf(item) == index
  })

  return [...new Set(merged.map((o) => JSON.stringify(o)))].map((s) =>
    JSON.parse(s)
  )
})

const searchSuppliers = (search, loading) => {
  if (search !== '') {
    getSuppliers(search, loading)
  }
}

const getSuppliers = _.debounce(async (searchValue, loading) => {
  if (!searchValue) {
    return
  }

  loading(true)
  let response = await api.get('/suppliers/', {
    filter: { name: searchValue }
  })
  suppliersList.value = response.data.suppliers
  loading(false)
}, 250)

const skuSupplierPricingCols = [
  {
    label: 'Supplier',
    name: 'tenantSupplierId',
    visible: true,
    input: true,
    inputType: 'select',
    displayValues: suppliers.value,
    display: 'name',
    reduce: (supplier) => {
      return supplier.tenantSupplierId
    }
  },
  {
    label: 'Price Options',
    name: 'priceOptions',
    visible: true,
    input: true,
    inputType: 'select',
    displayValues: [
      {
        label: 'Per Unit',
        value: 'perUnit'
      },
      {
        label: 'Per Pack',
        value: 'perPack'
      }
    ],
    display: 'label',
    reduce: (row) => {
      return row.value
    },
    change: (row) => {
      if (row.priceOptions == 'perUnit') {
        row.unitsPerCase = 1
      }
    },
    width: 300
  },
  {
    label: 'Units',
    name: 'unitsPerCase',
    visible: true,
    input: true,
    inputType: 'number',
    width: 200,
    readonly: (row) => {
      return row.priceOptions == 'perUnit'
    }
  },
  {
    label: 'Min. Quantity',
    name: 'minOrderQty',
    visible: true,
    input: true,
    inputType: 'number',
    width: 200
  },

  {
    label: 'Price',
    name: 'price',
    visible: true,
    input: true,
    inputType: 'number',
    width: 200
  },
  {
    label: 'Price VAT',
    name: 'priceVat',
    visible: true,
    input: true,
    inputType: 'select',
    displayValues: [
      {
        label: 'Including VAT',
        value: 0
      },
      {
        label: 'Excluding VAT',
        value: 1
      },
      {
        label: 'No VAT',
        value: 2
      }
    ],
    display: 'label',
    reduce: (row) => {
      return row.value
    },
    change: (row) => {
      if (row.priceVat == 2) {
        row.vatType = '-'
      } else {
        row.vatType = 'STANDARD'
      }
      row.vatRate = null
    },
    width: 200
  },
  {
    label: 'VAT Type',
    name: 'vatType',
    visible: true,
    input: true,
    inputType: 'select',
    displayValues: [
      {
        label: 'Standard',
        value: 'STANDARD'
      },
      {
        label: 'Custom',
        value: 'CUSTOM'
      }
    ],
    display: 'label',
    reduce: (row) => {
      return row.value
    },
    readonly: (row) => {
      return row.priceVat == 2
    },
    change: (row) => {
      row.vatRate = null
    },
    width: 200
  },
  {
    label: 'VAT (%)',
    name: 'vatRate',
    visible: true,
    input: true,
    inputType: 'number',
    readonly: (row) => {
      return row.vatType == 'STANDARD' || row.priceVat == 2
    },
    width: 200
  }
]

// const skuSupplierPricingRows = ref(props.skuSupplierPricing)

const addSupplierPrice = (supplierId = null) => {
  let formatted = {
    priceOptions: 'perUnit',
    minOrderQty: 0,
    unitsPerCase: 1,
    priceVat: 0,
    vatType: 'STANDARD',
    vatRate: null
  }

  if (supplierId) {
    formatted.tenantSupplierId = supplierId
  } else if (suppliers.value.length == 1) {
    formatted.tenantSupplierId = suppliers.value[0].tenantSupplierId
  }
  skuSupplierPricingRows.value.push(formatted)
}

const removeSupplierPrice = (row) => {
  if (row.delete) {
    row.delete ^= true
  } else {
    row.delete = 1
  }
}

const formatSkuSupplierPricing = () => {
  return skuSupplierPricingRows.value.map((record) => {
    let supplier = suppliers.value.find(
      (supplier) => supplier.tenantSupplierId == record.tenantSupplierId
    )

    if (!supplier) {
      return
    }

    let { collapsed, ...formatted } = {
      ...record,
      supplierSku: supplier.supplierSku,
      vatType: record.vatType != '-' ? record.vatType : null,
      vatRate:
        record.vatType == 'STANDARD'
          ? -1
          : record.priceVat == 2
          ? null
          : record.vatRate,
      price:
        record.priceOptions == 'perUnit' ? record.unitPrice : record.casePrice
    }

    return formatted
  })
}

const _UNIQUE_STATE_ID = ref(uuid.v4())

const registerFormState = inject('registerFormState')
const updateFormChanges = inject('updateFormChanges')

registerFormState(_UNIQUE_STATE_ID, 'Sku_Suppliers')

watch(
  () => skuSupplierPricingRows,
  (newValue) => {
    let formatted = newValue.value.map((record) => {
      let { collapsed, ...formatted } = record
      return formatted
    })
    let changesMade = !_.isEqual(toRaw(formatted), INITIAL_SKU_SUPPLIERS.value)
    getLastFilled()
    updateFormChanges(_UNIQUE_STATE_ID, 'Sku_Suppliers', changesMade)
  },
  { deep: true }
)

watch(
  () => props.skuSuppliers,
  () => {
    setSkuSupplierPricingRows()
    formatSuppliers()
  }
)

defineExpose({ formatSkuSupplierPricing, resetInitial })
</script>

<style scoped lang="scss">
.sku-supplier-pricing-row {
  height: 4.5rem;
  display: flex;
  align-items: center;

  &.collapsed {
    cursor: pointer;
    &:hover {
      background-color: $braid-grey-2;
    }
  }
}
.sku-supplier-pricing-row + .sku-supplier-pricing-row {
  border-top: 1px solid $braid-grey-4;
}

.table-number-input,
.table-text-input,
.table-select-input {
  height: auto;
}

.button-row {
  margin-top: 2rem;
}

.no-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
