<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Order"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    ref="grid"
    :cols="columns"
    :rows="rows"
    :total-count="totalCount"
    :filter-function="getRows"
    :default-sort="defaultSort"
    id-key="tenantOrderId"
    :reset-filters="resetFilters"
    scrollable
  ></TableGrid>
</template>

<script>
export default {
  name: 'OrdersList'
}
</script>

<script setup>
import {
  ref,
  onActivated,
  watch,
  inject,
  nextTick,
  onDeactivated,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
import { pageLoad } from '@/composables/pageLoad'

const { loading, loaded, getLoading } = pageLoad()
const router = useRouter()
const api = useApi()
const { getColumnFilters, getSort } = useColumnFilters()
const deactivated = ref(false)
const pageLoading = computed(() => {
  return getLoading()
})

const props = defineProps({
  pageId: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  }
})

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/*
 *****************      GRID
 */

// Template reference to the TableGrid component
const grid = ref(null)

const displayGrid = ref(false)
const rows = ref([])
const totalCount = ref(0)
const showArchived = ref(false)
const defaultSort = ref({ channelOrderUpdatedAt: -1 })
const view = ref('orders')

const getRows = async (args) => {
  loading()
  args.showItems = 1
  try {
    progressBarStart()

    if (!args.filter) {
      args.filter = {}
    }

    showArchived.value = args.filter.showArchived == 1

    let response = await api.get('/orders', args)
    rows.value = buildItemsView(response.data)
    totalCount.value = parseInt(response.data.count)
    loaded()
    updateFilters(false)
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

const buildItemsView = ({ orderItems, orders }) => {
  if (!orderItems || orderItems.length === 0) {
    return []
  }

  let formatted = []

  orderItems.forEach((orderItem) => {
    let tenantOrderId = orderItem.tenantOrderId
    let order = _.find(orders, { tenantOrderId })
    orderItem.orderNumber = order.orderNumber
    orderItem.channelOrderId = order.channelOrderId
    orderItem.channelName = order.channelName
    orderItem.fulfillmentChannel = order.fulfillmentChannel
    orderItem.status = order.status
    orderItem.channelOrderCreatedAt = order.channelOrderCreatedAt
    orderItem.channelOrderUpdatedAt = order.channelOrderUpdatedAt
    formatted.push(orderItem)
  })

  return formatted
}

const getTableFilters = () => {
  let query = router.currentRoute.value.query
  let sort = query.sort ? getSort(query.sort) : defaultSort.value
  let filter = getColumnFilters(query)
  return { sort, filter }
}

const updateTableFilters = ({ filter, sort }) => {
  if (!grid.value) {
    return
  }
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
}

const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)

const resetFilters = () => {
  appliedFilters.value = {}
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
// Default Column Settings
const cols = [
  {
    label: 'Order #',
    name: 'orderNumber',
    filter: true,
    visible: true,
    onClick: {
      route: '/orders/',
      id: 'tenantOrderId'
    },
    primary: true
  },
  {
    label: 'Channel',
    name: 'channelName',
    filter: true
  },
  {
    label: 'Fulfillment',
    name: 'fulfillmentChannel',
    filter: true,
    displayValues: {
      MERCHANT: 'Merchant',
      '3PL': 'Third party'
    },
    visible: true
  },
  {
    label: 'Status',
    name: 'status',
    filter: true,
    visible: true,
    multiple: true,
    displayValues: {
      0: 'Draft',
      1: 'Unfulfilled',
      2: 'Part-fulfilled',
      3: 'Fulfilled',
      4: 'Cancelled'
    },
    visible: true
  },
  {
    label: 'SKU',
    name: 'sku',
    visible: true,
    filter: true
  },
  {
    label: 'Title',
    name: 'title',
    visible: true
  },
  {
    label: 'Quantity',
    name: 'quantity',
    number: true,
    width: '10px',
    visible: true
  },
  {
    label: 'Price',
    name: 'price',
    number: true,
    visible: true
  },
  {
    label: 'Raised at',
    name: 'channelOrderCreatedAt',
    dateTime: true,
    visible: true
  },
  {
    label: 'Last updated',
    name: 'channelOrderUpdatedAt',
    dateTime: true
  }
]

// Template ref to the ColumnPreferences component
const preferences = ref()

// Shows the column preference modal
const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)
const columns = ref()

const localStorageName = ref('braidOrderItemsPage')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences } = columnPreferences(localStorageName.value)

columns.value = readColumnPreferences(cols)

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const newOrder = () => {
  router.push('/orders/new')
}

const buttons = ref([
  {
    id: 0,
    label: 'Add new',
    click: newOrder,
    icon: ['fas', 'circle-plus']
  },
  {
    id: 1,
    label: 'Column Settings',
    click: show,
    icon: ['fas', 'gear']
  }
])

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }

  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    if (!previous) {
      // This means it is coming from a different submenu and will be using the onActivated trigger
      return
    }

    if (deactivated.value) {
      // This means that the component is being kept alive using keep-alive and will be triggered by the onActivated trigger
      return
    }

    filterUpdate.value = true
  }
)

const updateFilters = (needRows = true) => {
  let tableFilters = getTableFilters(defaultSort.value)
  if (needRows) {
    getRows(tableFilters)
  }
  updateTableFilters(tableFilters)
}

watch(filterUpdate, (current, previous) => {
  if (!current) {
    return
  }
  updateFilters()
  filterUpdate.value = false
})
</script>
