<template>
  <header class="header">
    <div id="mobileNavMenu">
      <a
        id="mobileNavButton"
        class="btn btn-sm"
        role="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <FontAwesomeIcon :icon="['fas', 'bars']" class="menu-icon">
        </FontAwesomeIcon>
      </a>
      <ul
        id="mobileNavDropdown"
        class="dropdown-menu"
        aria-labelledby="mobileNavButton"
      >
        <template v-for="link in links" :key="link.id + 'header'">
          <li class="d-block d-lg-none mobile-nav-item">
            <RouterLink :to="link.route">{{ link.label }}</RouterLink>
          </li>
        </template>
      </ul>
    </div>
    <router-link to="/dashboard" class="d-flex align-items-center">
      <img
        id="logo"
        src="@/assets/Tri-colour-braid-no-padding.svg"
        alt="Braid Logo"
        title="Braid Logo"
      />
      <img
        id="logo-text"
        src="@/assets/Braid-text-no-padding.svg"
        alt="Braid Logo Text"
        title="Braid Logo Text"
      />
    </router-link>

    <span class="flex-wrapper-header">
      <h1 id="pagetitle">{{ pageTitle }}</h1>
      <div id="userMenu">
        <a
          id="userMenuBtn"
          class="btn btn-sm"
          role="button"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          {{ initials }}
        </a>

        <ul
          id="userMenuDropdown"
          class="dropdown-menu"
          aria-labelledby="userMenuBtn"
        >
          <li>
            <RouterLink to="/user">
              <FontAwesomeIcon
                :icon="['fas', 'user']"
                class="user-menu-icon"
              ></FontAwesomeIcon>
              <b>Profile</b></RouterLink
            >
          </li>
          <li>
            <RouterLink to="/company">
              <FontAwesomeIcon
                :icon="['fas', 'building']"
                class="user-menu-icon"
              ></FontAwesomeIcon>
              <b>Company</b></RouterLink
            >
          </li>
          <li class="mobile-nav-settings">
            <RouterLink to="/settings">
              <FontAwesomeIcon
                :icon="['fas', 'gear']"
                class="user-menu-icon"
              ></FontAwesomeIcon>
              <b>Settings</b>
            </RouterLink>
          </li>
          <li>
            <a href="#" @click.prevent="userLogout()">
              <p>
                <FontAwesomeIcon
                  :icon="['fas', 'right-from-bracket']"
                  class="user-menu-icon"
                ></FontAwesomeIcon>
                <b>Logout</b>
              </p>
            </a>
          </li>
        </ul>
        <a class="btn desktop-nav-settings" role="button" href="/settings"
          ><FontAwesomeIcon
            :icon="['fas', 'gear']"
            class="settings-icon"
          ></FontAwesomeIcon
        ></a>
      </div>
    </span>
  </header>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import { useRouter } from 'vue-router'

const { logout, user } = useAuth0()

const props = defineProps({
  links: {
    type: Array,
    default: () => {
      ;[]
    }
  },
  routeName: { type: String, default: '' }
})

const initials = computed(() => {
  try {
    return user.value.given_name.charAt(0) + user.value.family_name.charAt(0)
  } catch (e) {
    return 'NU'
  }
})

const userLogout = () => {
  localStorage.removeItem('locations')
  logout({
    returnTo: window.location.origin
  })
}

const router = useRouter()
const pageTitle = ref('Braid')

router.afterEach(() => {
  pageTitle.value = document.title
})
</script>

<style lang="scss">
.header {
  position: relative;
  background-color: $braid-purple;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding-top: 0;

  #logo {
    height: 3rem;
    padding-left: 1rem;
    transition: all 1s ease-out;
  }
  #logo-text {
    display: none;
  }

  .flex-wrapper-header {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 0 1rem 0 4rem;
    justify-content: space-between;

    #pagetitle {
      color: #ffffff;
      font-size: 1.5rem;
      justify-self: flex-start;
      align-self: flex-end;
    }

    #userMenu {
      display: flex;
      align-items: center;
      #userMenuBtn {
        color: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 100%;
        padding: 0.5rem;
      }

      svg {
        &.settings-icon {
          path {
            fill: #ffffff !important;
          }
        }
      }

      .user-menu-icon {
        margin-right: 1rem;
      }

      li {
        a {
          color: #000000;
          text-decoration: none;
        }
      }
    }
    .desktop-nav-settings {
      display: none;
    }

    #userMenuDropdown {
      padding: 0.2rem;

      li {
        display: block;
        margin-top: 1rem;
        padding-left: 1rem;

        &:last-child {
          margin-bottom: 1rem;
        }

        &.mobile.nav-settings {
          display: block;
        }
      }
    }
  }
  #mobileNavMenu {
    svg {
      &.menu-icon {
        path {
          fill: #ffffff !important;
        }
      }
    }

    #mobileNavDropdown {
      padding: 0.2rem;

      li {
        display: block;
        margin-top: 0.5rem;
        padding-top: 0.5rem;

        a {
          color: #000000;
          text-decoration: none;
        }

        &:last-child {
          margin-bottom: 1rem;
        }

        &.mobile-nav-item + .mobile-nav-item {
          border-top: 1px solid $braid-grey-4;
        }
      }
    }
  }
  @media (min-width: 768px) {
    #logo {
      padding-left: 2.5rem;
    }

    #logo-text {
      display: block;
      height: 1.5rem;
      padding-left: 0.5rem;
      transition: all 1s ease-out;
    }

    #mobileNavMenu {
      display: none;
    }

    .mobile.nav-settings {
      display: none;
    }

    .desktop-nav-settings {
      display: block;
    }

    .flex-wrapper-header {
      margin: 0 4rem 0 8rem;
    }
  }
}
</style>
