<template>
  <BraidModal
    ref="modal"
    confirm-button-text="Put all on backorder"
    @confirm="putAllOnBackorder"
  >
    <template #title>Put on back-order</template>
    <template #body>
      <table class="table table-hover">
        <thead>
          <th>SKU</th>
          <th>Title</th>
          <th>Variants</th>
          <th>Quantity ordered</th>
          <th>Quantity received</th>
        </thead>
        <tbody>
          <tr v-for="(row, index) in items" :key="row.sku + index">
            <td>{{ row.sku }}</td>
            <td>{{ row.title }}</td>
            <td>{{ row.variant1Value }} {{ row.variant2Value }}</td>
            <td>{{ row.quantityOrdered }}</td>
            <td>{{ row.quantityReceived }}</td>
          </tr>
        </tbody>
      </table>
    </template>
  </BraidModal>
</template>

<script>
export default {
  name: 'BackOrderMultipleSkus'
}
</script>

<script setup>
import { ref } from 'vue'
import useApi from '@/components/useApi'

const props = defineProps({
  items: {
    type: Array,
    default() {
      return []
    }
  },
  purchaseOrderId: {
    type: [String, Boolean],
    required: true
  }
})

const modal = ref(null)
const emit = defineEmits(['close'])
const api = useApi()

const putAllOnBackorder = async () => {
  const URI = `/purchaseOrders/${props.purchaseOrderId}`

  let params = {
    purchaseOrderItems: props.items,
    updateItemsOnly: 1,
    purchaseOrderId: props.purchaseOrderId
  }

  await api.put(URI, params)
  emit('close', props.items)
}

const show = () => {
  modal.value.show()
}

const hide = () => {
  modal.value.hide()
}

defineExpose({ show, hide })
</script>

<style lang="scss"></style>
