<template>
  <BraidModal ref="columnPreferencesModal">
    <template #title> Configure {{ listName }}'s column </template>
    <template #body>
      <div class="row">
        <div class="col-4">
          <h5>Hidden</h5>
        </div>
        <div class="col-4 offset-2">
          <h5>Visible</h5>
        </div>
      </div>
      <div class="row pt-1">
        <div class="col-4">
          <div id="list-tab-hidden" class="list-group" role="tablist">
            <p
              v-for="(column, index) in hiddenColumns"
              id="hidden-columns-list"
              :key="'hidden' + column.label + index"
              class="list-group-item list-group-item-action m-0"
              :class="{
                active: selectedColumn.label === column.label,
                'primary-column': column.primary
              }"
              data-bs-toggle="list"
              href="#list-home"
              role="tab"
              aria-controls="list-home"
              @click="selectColumn(column, index, false)"
            >
              {{ column.label }}
            </p>
          </div>
        </div>
        <div class="col-2 d-flex justify-content-center align-items-center">
          <button
            id="column-preference-btn-hide"
            class="btn column-preference-btn"
            :disabled="selectedColumn.primary"
            @click="hideColumn()"
          >
            <FontAwesomeIcon :icon="['fas', 'angle-left']"></FontAwesomeIcon>
          </button>
          <button
            id="column-preference-btn-show"
            class="btn column-preference-btn"
            :disabled="selectedColumn.primary"
            @click="showColumn()"
          >
            <FontAwesomeIcon :icon="['fas', 'angle-right']"></FontAwesomeIcon>
          </button>
        </div>
        <div class="col-4">
          <div id="list-tab-visible" class="list-group" role="tablist">
            <p
              v-for="(column, index) in visibleColumns"
              id="visible-columns-list"
              :key="'hidden' + column.label + index"
              class="list-group-item list-group-item-action m-0"
              :class="{
                active: selectedColumn.label === column.label,
                'primary-column': column.primary
              }"
              data-bs-toggle="list"
              href="#list-home"
              role="tab"
              aria-controls="list-home"
              @click="selectColumn(column, index, true)"
            >
              {{ column.label }}
            </p>
          </div>
        </div>
        <div class="col-2 vstack d-flex justify-content-center">
          <button
            id="change-order-button-first"
            class="btn column-preference-btn"
            @click="changeOrder('first')"
          >
            <FontAwesomeIcon :icon="['fas', 'angles-up']"></FontAwesomeIcon>
          </button>

          <button
            id="change-order-button-previous"
            class="btn column-preference-btn"
            @click="changeOrder('dec')"
          >
            <FontAwesomeIcon :icon="['fas', 'angle-up']"></FontAwesomeIcon>
          </button>

          <button
            id="change-order-button-next"
            class="btn column-preference-btn"
            @click="changeOrder('inc')"
          >
            <FontAwesomeIcon :icon="['fas', 'angle-down']"></FontAwesomeIcon>
          </button>

          <button
            id="change-order-button-last"
            class="btn column-preference-btn"
            @click="changeOrder('last')"
          >
            <FontAwesomeIcon :icon="['fas', 'angles-down']"></FontAwesomeIcon>
          </button>
        </div>
      </div>
    </template>
    <template #footer>
      <button id="closeButton" class="btn btn-red" @click="close">Close</button>
      <button id="rejectButton" class="btn btn-red" @click="resetColumns">
        Reset
      </button>
      <button id="confirmButton" class="btn btn-green" @click="saveColumns">
        Save
      </button>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref, computed, onMounted, watch, nextTick } from 'vue'

const props = defineProps({
  listName: {
    type: String,
    default: '',
    required: true
  },
  cols: {
    type: Array,
    default() {
      ;[]
    }
  },
  storageName: {
    type: String,
    default: '',
    required: true
  }
})

const emit = defineEmits(['save', 'reset'])

let preferences
let columns

if (localStorage[props.storageName]) {
  preferences = JSON.parse(localStorage[props.storageName])
  columns = ref(preferences.columns)
} else {
  columns = ref(props.cols)
}

const visibleColumns = computed(() => {
  return columns.value.filter((col) => col.visible)
})

const hiddenColumns = computed(() => {
  return columns.value.filter((col) => !col.visible)
})

/**
 * When the user changes the order of columns, they only affect the object returned from visibleColumns.
 * As that method uses .filter (returns a new array) we need to modify the columns  reference to cause a
 * re-render of the DOM
 */
const updateColumns = () => {
  columns.value = visibleColumns.value.concat(hiddenColumns.value)
}

const modalEl = ref()
const columnPreferencesModal = ref(null)

const close = () => {
  columnPreferencesModal.value.hide()
}

const show = () => {
  columnPreferencesModal.value.show()
}

const hide = () => {
  let listPreferences = JSON.parse(localStorage[props.storageName])
  columns.value = listPreferences.columns
  columnPreferencesModal.value.hide()
}

/**
 * Allows parent component to show / hide the modal
 */
defineExpose({ show, hide })

// Keep track of which column the user has selected
const selectedColumn = ref('')

const selectColumn = (column, index, visible) => {
  selectedColumn.value = { ...column, index, visible }
}

const hideColumn = () => {
  //Only show columns if they're visible
  if (!selectedColumn.value.visible) {
    return
  }

  let column = visibleColumns.value[selectedColumn.value.index]
  if (!column) {
    return
  }
  column.visible = false
  updateColumns()
  let newIdx = hiddenColumns.value.findIndex((el) => el.label === column.label)
  newIdx = newIdx < 0 ? 0 : newIdx
  selectColumn(column.label, newIdx, false)
}

const showColumn = () => {
  //Only show columns if they're hidden
  if (selectedColumn.value.visible) {
    return
  }

  let column = hiddenColumns.value[selectedColumn.value.index]
  if (!column) {
    return
  }
  column.visible = true
  updateColumns()

  let newIdx = visibleColumns.value.findIndex((el) => el.label === column.label)

  newIdx = newIdx < 0 ? 0 : newIdx
  selectColumn(column.label, newIdx, true)
}

const swapColumns = (selectedIndex, targetIndex) => {
  let selectedColumnObject = visibleColumns.value[selectedIndex]
  let targetColumnObject = visibleColumns.value[targetIndex]

  visibleColumns.value[targetIndex] = selectedColumnObject
  visibleColumns.value[selectedIndex] = targetColumnObject

  selectedColumn.value.index = targetIndex

  nextTick(updateColumns)
}

const incrementColumnOrder = () => {
  let newIndex = selectedColumn.value.index + 1
  if (newIndex < visibleColumns.value.length) {
    swapColumns(selectedColumn.value.index, newIndex)
  }
}

const decrementColumnOrder = () => {
  let newIndex = selectedColumn.value.index - 1
  if (newIndex >= 0) {
    swapColumns(selectedColumn.value.index, newIndex)
  }
}

const makeColumnFirst = () => {
  swapColumns(selectedColumn.value.index, 0)
}

const makeColumnLast = () => {
  swapColumns(selectedColumn.value.index, visibleColumns.value.length - 1)
}

const changeOrder = (change) => {
  if (!selectedColumn.value) {
    return
  }
  //If we have a selected column and it's visible we can change its order
  let column = columns.value[selectedColumn.value.index]
  if (column.visible) {
    switch (change) {
      case 'inc':
        incrementColumnOrder()
        break
      case 'dec':
        decrementColumnOrder()
        break
      case 'first':
        makeColumnFirst()
        break
      case 'last':
        makeColumnLast()
      default:
        break
    }
  }
}

/**
 * Saves columns when changed in column preferences
 *
 * Deletes property 'displayWidth' as this is the calculated width per column in this specific order
 * and will be recalculated upon load
 */
const saveColumns = () => {
  columns.value.forEach((col) => delete col.displayWidth)
  emit('save', columns.value)
  localStorage[props.storageName] = JSON.stringify({
    ...preferences,
    columns: columns.value
  })
  columnPreferencesModal.value.hide()
}

const resetColumns = () => {
  columns.value = props.cols
  emit('save', columns.value)
  localStorage[props.storageName] = JSON.stringify({
    ...preferences,
    columns: columns.value
  })
}
</script>

<style lang="scss">
.column-preference-btn {
  height: 3rem;
  width: 3rem;
}

.list-group-item {
  border-width: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 2px;
}

.list-group-item .selected-column {
  border: 1px solid #cccccc;
  background-color: #f5f5f5;
}

#list-tab-visible,
#list-tab-hidden {
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.list-group-item {
  cursor: pointer;
  &.active {
    background-color: rgba($braid-purple, 0.6);
    border-color: rgba($braid-purple, 0.6);
  }

  &:hover:not(.active) {
    background-color: rgba($braid-purple, 0.2);
    border-color: rgba($braid-purple, 0.2);
  }
}
</style>
