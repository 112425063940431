<template>
  <ActionBar
    :buttons="buttons"
    :conditions="{
      1: {
        disable: disableBulkButton
      }
    }"
  />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Order"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    ref="grid"
    :cols="columns"
    :rows="rows"
    :default-sort="defaultSort"
    :filter-function="getRows"
    :total-count="totalCount"
    id-key="tenantDispatchId"
    select-id="tenantDispatchId"
    caption="List of dispatches"
    :reset-filters="resetFilters"
    scrollable
    checkbox
    select-all
  ></TableGrid>
</template>

// This is for the keep-alive to work
<script>
export default {
  name: 'DispatchesList'
}
</script>

<script setup>
import {
  ref,
  onActivated,
  watch,
  inject,
  nextTick,
  onDeactivated,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
const grid = ref(null)
const router = useRouter()

const api = useApi()
const { getTableFilters, toggleArchived } = useColumnFilters(router)
const deactivated = ref(false)

import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})
const props = defineProps({
  pageId: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  }
})

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/*
 *****************      GRID
 */

const newDispatch = () => {
  router.push('/dispatches/new')
}

const rows = ref([])
const modalRows = ref([])
const modalCols = ref([])
const defaultSort = ref({ channelDispatchId: -1 })
const totalCount = ref(0)
const channels = ref([])

const getRows = async (args = {}) => {
  loading()
  try {
    progressBarStart()
    let response = await api.get('/dispatches', args)
    rows.value = response.data.dispatches
    columns.value = getChannelValues(columns.value, response.data.channels)
    totalCount.value = parseInt(response.data.count)
    loaded()
    updateFilters(false)
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

const disableBulkButton = computed(() => {
  if (!grid.value) {
    return true
  }

  return grid.value.selected.length === 0
})

const updateTableFilters = ({ filter, sort, skip }) => {
  if (!grid.value) {
    return
  }
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
  grid.value.setPage(skip)
}

const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)
// Template reference to the TableGrid component

const resetFilters = () => {
  appliedFilters.value = {}
}

const getChannelValues = (tableCols, response = []) => {
  let index = 0
  let length = response.length
  let channelValues = {}
  channels.value = response

  for (index; index < length; index++) {
    let channel = response[index]
    channelValues[channel.tenantChannelId] = channel.name
  }

  let columnIndex = tableCols.findIndex((column) => {
    return column.name === 'tenantChannelId'
  })

  tableCols[columnIndex].displayValues = channelValues
  return tableCols
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
// Default Column Settings
const cols = [
  {
    label: 'Dispatch number',
    name: 'channelDispatchId',
    filter: true,
    visible: true,
    width: 150,
    onClick: {
      route: '/dispatches/',
      id: 'tenantDispatchId'
    },
    primary: true
  },
  {
    label: 'Channel',
    name: 'tenantChannelId',
    visible: true,
    filter: true,
    multiple: true
  },
  {
    label: 'Order number',
    name: 'channelOrderId',
    filter: true,
    visible: true
  },
  {
    label: 'Order status',
    name: 'orderStatus',
    filter: true,
    visible: true,
    multiple: true,
    width: 200,
    displayValues: {
      0: 'Draft',
      1: 'Unfulfilled',
      2: 'Part-fulfilled',
      3: 'Fulfilled',
      4: 'Cancelled'
    }
  },
  {
    label: 'Dispatch status',
    name: 'status',
    filter: true,
    visible: true,
    width: 200,
    displayValues: {
      0: 'Draft',
      1: 'Label created',
      2: 'Shipped'
    }
  },
  {
    label: 'Items',
    name: 'items',
    visible: true
  },
  {
    label: 'Carrier',
    name: 'carrier',
    filter: true,
    visible: true,
    displayValues: {
      UPS: 'UPS',
      FEDEX: 'Fedex',
      DHL: 'DHL',
      ROYAL_MAIL: 'Royal mail',
      DPD: 'DPD',
      HERMES: 'Hermes'
    }
  },
  {
    label: 'Ship date',
    name: 'shipDate',
    dateTime: true,
    filter: true,
    visible: true
  },
  {
    label: 'Raised at',
    name: 'channelDispatchCreatedAt',
    dateTime: true,
    visible: true
  },
  {
    label: 'Last updated',
    name: 'channelDispatchUpdatedAt',
    dateTime: true,
    visible: true
  }
]

const preferences = ref()

const show = () => {
  preferences.value.show()
}

const columns = ref()

const localStorageName = ref('braidDispatches')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences } = columnPreferences(localStorageName.value)

columns.value = readColumnPreferences(cols)

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

/*
 *****************  BULK CREATE LABELS
 */

const bulkCreateWizard = () => {
  router.push(`/bulk-label?ids=${grid.value.selected}`)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'
const buttons = [
  {
    id: 0,
    label: 'Add new',
    click: newDispatch,
    icon: ['fas', 'circle-plus']
  },
  {
    id: 1,
    label: 'Bulk create labels',
    click: bulkCreateWizard,
    icon: ['fas', 'circle-plus']
  },
  { id: 2, label: 'Column Settings', click: show, icon: ['fas', 'gear'] }
]

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }

  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    filterUpdate.value = true
  }
)

const updateFilters = (needRows = true) => {
  let tableFilters = getTableFilters(defaultSort.value)
  if (needRows) {
    getRows(tableFilters)
  }
  updateTableFilters(tableFilters)
}

watch(filterUpdate, (current, previous) => {
  if (!current) {
    return
  }
  updateFilters()
  filterUpdate.value = false
})
</script>
