import { ref } from 'vue'
export function columnPreferences(localStorageName) {
  let columns = null
  let icons = null

  /**
   * Checks to see if we can read column preferences from local storage. If not
   * it writes the cols array to local storage (as a default)
   */
  const readColumnPreferences = (cols) => {
    let listSettings = checkForColumnChanges(cols)
    columns = listSettings.columns
    icons = listSettings.icons

    return columns
  }

  const readAlertIconPreferences = () => {
    return icons
  }
  /**
   * This checks for changes in cols
   *
   * if no col settings in localStorage, saves and returns cols
   *
   * else for each col in cols, it checks to see if in columnSettings
   * if it is, it adds the columnSettings version to a new array
   * else it adds the new version to a new array
   *
   * Adding it to a new array means this will cover adding and removing of columns without some horrible comparisons
   *
   * Need to save is triggered if a new col is added or columnSettings length is different to formatted cols length
   */
  const checkForColumnChanges = (cols) => {
    let listPreferences = localStorage[localStorageName]

    if (!listPreferences) {
      let settings = {
        columns: cols,
        icons: {
          Success: { show: true },
          Info: { show: true },
          Warning: { show: true },
          Error: { show: true }
        }
      }
      localStorage[localStorageName] = JSON.stringify(settings)
      return settings
    }

    listPreferences = JSON.parse(listPreferences)
    let columnSettings = listPreferences.columns
    let iconSettings = listPreferences.icons

    let index = 0
    let length = cols.length
    let formattedCols = []
    let needToSave = false

    for (index; index < length; index++) {
      let col = cols[index]
      let name = col.name

      let savedCol = columnSettings.find((column) => {
        return column.name === name
      })

      // THIS IS A NEW ADDED COLUMN
      if (!savedCol) {
        formattedCols.push(col)
        needToSave = true
        continue
      }

      formattedCols.push(savedCol)
    }

    // THIS MEANS A COLUMN HAS BEEN REMOVED SO IT NEEDS SAVED
    if (columnSettings.length !== formattedCols.length) {
      needToSave = true
    }

    if (needToSave) {
      localStorage[localStorageName] = JSON.stringify({
        ...columnSettings,
        columns: formattedCols
      })
    }

    return { columns: columnSettings, icons: iconSettings }
  }

  /**
   *
   * @param {*} cols
   * @param {*} columnFunctions
   * @returns
   */
  const assignColumnFunctions = (columnFunctions) => {
    let cols = columns

    if (cols == null) {
      return
    }

    let index = 0
    let length = cols.length
    for (index; index < length; index++) {
      let col = cols[index]
      let name = col.name

      if (columnFunctions[name]) {
        let functions = columnFunctions[name]
        cols[index] = { ...col, ...functions }
      }
    }

    return cols
  }

  const toggleAlertIcons = (type) => {
    let listPreferences = localStorage[localStorageName]
    if (!listPreferences) {
      let settings = {
        columns: cols,
        icons: {
          Info: { show: true },
          Warning: { show: true },
          Error: { show: true }
        }
      }
      localStorage[localStorageName] = JSON.stringify(settings)
    }
    listPreferences = JSON.parse(listPreferences)
    listPreferences.icons[type].show = !listPreferences.icons[type].show
    localStorage[localStorageName] = JSON.stringify(listPreferences)
  }

  const updateAlertIcons = (alertIcons) => {
    for (let [key, value] of Object.entries(alertIcons)) {
      for (let index in value) {
        value[index].show = icons[value[index].type].show
      }
    }
    return alertIcons
  }

  return {
    readColumnPreferences,
    readAlertIconPreferences,
    assignColumnFunctions,
    toggleAlertIcons,
    updateAlertIcons
  }
}
