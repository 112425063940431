import { ref, inject } from 'vue'
import axios from 'axios'
import { useAuth0 } from '@auth0/auth0-vue'
import allConfig from '../../auth_config.json'
import { router } from '@/router/index'
import { store } from '@/store/store'
const { setTimezone } = require('@/dayjs')
const tenantCheckPath = '/tenant/check-complete'
import buildRequestRoutes from '@/composables/buildRequestRoutes'

export default function () {
  const createToast = inject('createToast')
  const createAlert = inject('createAlert')

  let config = allConfig.production
  let requestRoutes = {}

  if (process.env.NODE_ENV === 'development') {
    config = allConfig.development
    requestRoutes = buildRequestRoutes()
  }

  const { getAccessTokenSilently } = useAuth0()
  const host = config.host

  /**
   * Will check if the podnumber is stored in localstorage before every req
   * if not, it will fetch from tenant service
   * @returns {string} podNumber
   */
  const getPod = async () => {
    let podNumber = localStorage.getItem('pod')
    let timezone = localStorage.getItem('tz')

    if (podNumber && timezone) {
      setTimezone(timezone)
      return podNumber
    }

    let tenantData = await getTenant()

    podNumber = tenantData.podNumber
    timezone = tenantData.timezone
    localStorage.setItem('pod', podNumber)
    localStorage.setItem('tz', timezone)

    setTimezone(timezone)
    return podNumber
  }

  const getLocations = async () => {
    let locations = localStorage.getItem('locations')

    if (locations) {
      return JSON.parse(locations)
    }

    let method = 'GET'
    let unsuccessfulToast = 'Error occurred retrieving data'
    let url = '/locations'
    let requestOptions = { url, method }
    let response = await makeRequest({
      requestOptions,
      unsuccessfulToast,
      gettingTenant: true
    })
    locations = response?.data?.locations

    if (!locations) {
      return
    }

    localStorage.setItem('locations', JSON.stringify(locations))
    return locations
  }

  const getTenant = async () => {
    let tenant = localStorage.getItem('tenant')

    if (tenant) {
      return JSON.parse(tenant)
    }

    let method = 'GET'
    let unsuccessfulToast = 'Error occurred retrieving data'
    let url = '/tenant'
    let requestOptions = { url, method }
    let response = await makeRequest({
      requestOptions,
      unsuccessfulToast,
      gettingTenant: true
    })
    let tenantData = response?.data?.tenant

    if (!tenantData) {
      return
    }

    tenant = {
      vatNumber: tenantData.vatNumber,
      tradingName: tenantData.tradingName,
      podNumber: tenantData.podNumber,
      timezone: tenantData.timezone
    }

    localStorage.setItem('tenant', JSON.stringify(tenant))
    return tenant
  }

  const checkTenantComplete = async (path) => {
    if (
      store.getters.getTenantComplete ||
      path === tenantCheckPath ||
      path === '/tenant'
    ) {
      return true
    }

    let response = await get(tenantCheckPath)
    let tenantComplete = response.data.tenantComplete || false

    store.dispatch('setTenantComplete', tenantComplete)

    if (!tenantComplete) {
      createAlert(
        'error',
        'You must enter the necessary details for your company before continuing - Company Name, Currency, Company Type, Contact Email and Contact Phone.'
      )
      router.push('/company')
    }

    return tenantComplete
  }

  const getOptions = async ({
    url,
    method,
    params = {},
    data = {},
    responseType = 'json',
    encoding = 'utf8',
    contentType = 'application/json',
    gettingTenant = false,
    validateStatus = 400
  } = {}) => {
    if (process.env.NODE_ENV === 'development') {
      params.requestRoutes = requestRoutes
    }

    const headers = await getHeaders(contentType, gettingTenant)

    return {
      url: host + url,
      method: method,
      params: params,
      data: data,
      responseType: responseType,
      encoding: encoding,
      validateStatus: (status) => {
        return status < validateStatus
      },
      headers: headers
    }
  }

  const getHeaders = async (contentType, gettingTenant) => {
    const token = await getToken()
    let pod
    let headers = {
      Authorization: token,
      'Content-Type': contentType
    }

    if (!gettingTenant) {
      pod = await getPod()
      headers['x-braid-pod'] = pod
    }

    return headers
  }

  const getToken = async () => {
    let token = await getAccessTokenSilently()
    return `Bearer ${token}`
  }

  const get = async (
    url,
    params,
    {
      responseType = 'json',
      encoding = 'utf8',
      contentType = 'application/json'
    } = {}
  ) => {
    let tenantComplete = await checkTenantComplete(url)
    if (!tenantComplete) {
      return
    }

    let method = 'GET'
    let unsuccessfulToast = 'Error occurred retrieving data'
    let requestOptions = { url, method, responseType, encoding, contentType }
    let response = await makeRequest({
      requestOptions,
      params,
      unsuccessfulToast
    })

    if (response.data.incompleteTenant) {
      router.push('/company')
      return
    }

    return response
  }

  const post = async (
    url,
    data,
    saving = ref(false),
    { validateStatus } = {}
  ) => {
    let successfulToast = 'Updated successfully'
    let unsuccessfulToast = 'Error occurred'
    let method = 'POST'
    let requestOptions = { url, method }

    return await makeRequest({
      requestOptions,
      data,
      validateStatus,
      unsuccessfulToast,
      successfulToast,
      saving
    })
  }

  const put = async (
    url,
    data,
    saving = ref(false),
    { validateStatus } = {}
  ) => {
    let successfulToast = 'Updated successfully'
    let unsuccessfulToast = 'Error occurred'
    let method = 'PUT'
    let requestOptions = { url, method, validateStatus }

    return await makeRequest({
      requestOptions,
      data,
      validateStatus,
      unsuccessfulToast,
      successfulToast,
      saving
    })
  }

  const makeRequest = async ({
    requestOptions,
    params,
    data,
    successfulToast,
    unsuccessfulToast,
    gettingTenant = false,
    saving = ref(false)
  }) => {
    saving.value = true
    let toastType = 'success'
    let toastMessage = successfulToast
    let options = await getOptions({
      ...requestOptions,
      params,
      data,
      gettingTenant
    })
    let response

    try {
      response = await axios.request(options)

      if (response.status >= 400) {
        toastType = 'error'
        toastMessage = 'Error occurred'
      }
    } catch (error) {
      toastType = 'error'
      toastMessage = unsuccessfulToast
      response = error
    }

    if (toastMessage) {
      createToast(toastType, toastMessage)
    }

    saving.value = false
    return response
  }

  return { put, post, get, checkTenantComplete, getTenant, getLocations }
}
