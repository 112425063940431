<template>
  <input
    ref="input"
    v-model="value"
    type="text"
    class="table-text-input"
    :readonly="readOnly"
    :placeholder="placeholder"
  />
</template>
<script>
import useTableInput from '@/components/table/useTableInput'
const config = useTableInput()
</script>

<script setup>
import { ref, watch } from 'vue'
const props = defineProps({ ...config._props })
const emit = defineEmits([''].concat(config._emits))

const input = ref(null)

const value = ref(props.modelValue)

/**
 * We have to watch the value intead of relying on the input event.
 * This is due to the fact that when a new value is pasted via the
 * context menu; the input event isn't triggered (neither is paste event)
 */
watch(value, (newValue) => {
  emit('update:modelValue', newValue)
})

watch(
  () => props.modelValue,
  (newValue) => (value.value = newValue)
)

const focus = () => {
  if (!props.readOnly) {
    input.value.focus()
  }
}

defineExpose({ focus })
</script>

<style lang="scss">
.table-text-input {
  width: 100%;
  height: 100%;
  outline: none;
  border: none;
  padding: 0 0.5rem;
  border-radius: 3px;
}
</style>
