<template>
  <TableRow type="custom">
    <div class="d-flex">
      <div class="form-check radio-input me-3">
        <input
          id="presetOptionRadio1"
          class="form-check-input me-2"
          type="radio"
          name="presetRadio"
          :checked="newPresetOption == 0"
          @click="newPresetOption = 0"
        />
        <label class="form-check-label" for="presetOptionRadio1">
          Use preset measurements
        </label>
      </div>
      <div class="form-check radio-input">
        <input
          id="presetOptionRadio2"
          class="form-check-input me-2"
          type="radio"
          name="presetRadio"
          :checked="newPresetOption == 1"
          @click="newPresetOption = 1"
        />
        <label class="form-check-label" for="presetOptionRadio2">
          Use unique measurements
        </label>
      </div>
    </div>
  </TableRow>

  <TableRow
    v-if="newPresetOption == 0"
    label="Package measurements"
    type="custom"
  >
    <div class="d-flex align-items-center">
      <TableSelect
        v-model="tenantPackagePresetId"
        :options="props.packagePresets"
        display="label"
        :reduce="(preset) => preset.tenantPackagePresetId"
        class="title-input"
      ></TableSelect>
      <button class="btn title-btn" @click="openModal">
        <FontAwesomeIcon :icon="['fas', 'plus']"> </FontAwesomeIcon>
      </button>
    </div>
  </TableRow>

  <template v-else>
    <TableRow v-model="local.width" type="number" label="Width"></TableRow>
    <TableRow v-model="local.height" type="number" label="Height"></TableRow>
    <TableRow v-model="local.depth" type="number" label="Depth"></TableRow>
    <TableRow
      v-model="local.dimensionUnits"
      type="select"
      :options="[
        { value: 'IN', label: 'Inches' },
        { value: 'MM', label: 'MM' },
        { value: 'CM', label: 'CM' },
        { value: 'M', label: 'M' }
      ]"
      label="Dimension units"
    ></TableRow>
  </template>

  <BraidModal ref="presetModal" @confirm="createNewPreset">
    <template #title>Create package preset</template>
    <template #body>
      <TableContainer>
        <TablePanel>
          <template #1>
            <TableForm>
              <TableRow
                v-model="newPresetData.name"
                label="Name"
                :rules="{ required: true }"
                type="text"
              ></TableRow>

              <TableRow
                v-model="newPresetData.height"
                label="Height"
                :rules="{ required: true }"
                type="number"
              ></TableRow>

              <TableRow
                v-model="newPresetData.width"
                label="Width"
                :rules="{ required: true }"
                type="number"
              />

              <TableRow
                v-model="newPresetData.depth"
                label="Depth"
                :rules="{ required: true }"
                type="number"
              />

              <TableRow
                v-model="newPresetData.dimensionUnits"
                type="select"
                :options="[
                  { value: 'IN', label: 'Inches' },
                  { value: 'MM', label: 'MM' },
                  { value: 'CM', label: 'CM' },
                  { value: 'M', label: 'M' }
                ]"
                label="Dimension units"
              ></TableRow>
            </TableForm>
          </template>
        </TablePanel>
      </TableContainer>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref, watch, computed, nextTick, inject, toRaw } from 'vue'
import TableRow from '@/components/table/form/TableRow.vue'
import TableForm from '@/components/table/form/TableForm.vue'
import TableSelect from '@/components/table/form/TableSelect.vue'
import useApi from '@/components/useApi'

const tenantPackagePresetId = ref(null)
const newPresetOption = ref(0)
const presetModal = ref(0)
const local = ref({})
const newPresetData = ref({})

const emit = defineEmits(['new-preset', 'update-value'])

const api = useApi()

const openModal = () => {
  presetModal.value.show()
}

const dimensions = computed(() => {
  if (!tenantPackagePresetId.value) {
    return local.value
  }

  let packagePreset = props.packagePresets.find((preset) => {
    return preset.tenantPackagePresetId == tenantPackagePresetId.value
  })

  if (!packagePreset) {
    return {}
  }

  return {
    height: packagePreset.height,
    width: packagePreset.width,
    depth: packagePreset.depth,
    dimensionUnits: packagePreset.dimensionUnits
  }
})

const createNewPreset = async () => {
  let data = { packagePreset: newPresetData.value }
  let response = await api.post('/packagePresets/', data)
  let insertedId = response.data.insertedId
  let newPresets = response.data.packagePresets
  tenantPackagePresetId.value = insertedId
  emit('new-preset', newPresets)
}

const props = defineProps({
  packagePresets: {
    type: Array,
    default: () => {
      return []
    }
  }
})

watch([() => tenantPackagePresetId.value, local.value], () => {
  emit('update-value')
})

defineExpose({ dimensions })
</script>
