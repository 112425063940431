import { START_LOCATION, createRouter, createWebHistory } from 'vue-router'

import Dashboard from '@/components/content/dashboard/Dashboard'

import Brands from '@/components/content/brands/Brands'

import Suppliers from '@/components/content/suppliers/Suppliers'
import SupplierForm from '@/components/content/suppliers/SupplierForm'
import BulkUpdatePricing from '@/components/content/suppliers/BulkUpdatePricing'

import Customers from '@/components/content/customers/Customers'
import CustomerForm from '@/components/content/customers/CustomerForm'

import Locations from '@/components/content/locations/Locations'
import LocationForm from '@/components/content/locations/LocationForm'

import Orders from '@/components/content/orders/Orders'
import OrderForm from '@/components/content/orders/OrderForm'
import Items from '@/components/content/orders/Items'

import Skus from '@/components/content/skus/Skus'
import SkuForm from '@/components/content/skus/SkuForm'

import Inventory from '@/components/content/inventory/Inventory'

import Dispatches from '@/components/content/dispatches/Dispatches'
import DispatchForm from '@/components/content/dispatches/DispatchForm'
import LabelsList from '@/components/content/dispatches/LabelsList'

import Invoices from '@/components/content/invoices/Invoices'
import InvoiceForm from '@/components/content/invoices/InvoiceForm'

import Categories from '@/components/content/categories/Categories'
import CategoryForm from '@/components/content/categories/CategoryForm'

import BackorderSkus from '@/components/content/backorderedSkus/BackorderSkus'

import Channels from '@/components/content/channels/Channels'
import ChannelForm from '@/components/content/channels/ChannelForm'

import Messages from '@/components/content/messages/Messages'
import MessageForm from '@/components/content/messages/MessageForm'

import PurchaseOrders from '@/components/content/purchaseOrders/PurchaseOrders'
import PurchaseOrderItems from '@/components/content/purchaseOrders/PurchaseOrderItems'
import PurchaseOrderForm from '@/components/content/purchaseOrders/PurchaseOrderForm'

import Products from '@/components/content/products/Products'
import ProductForm from '@/components/content/products/ProductForm'

import Alerts from '@/components/content/alerts/Alerts'

import Returns from '@/components/content/returns/Returns'
import ReturnForm from '@/components/content/returns/ReturnForm'

import Reports from '@/components/content/reports/Reports'
import ReportForm from '@/components/content/reports/ReportForm'

import CompanyForm from '@/components/content/company/CompanyForm'
import UserForm from '@/components/UserForm'

import EbayToken from '@/components/EbayToken'
import ShopifyToken from '@/components/ShopifyToken'
import AmazonToken from '@/components/AmazonToken'

import Settings from '@/components/Settings'

import { authGuard } from '@auth0/auth0-vue'

import TenantWizard from '@/components/content/wizards/TenantWizard'
import IntegrationWizard from '@/components/content/wizards/IntegrationWizard'
import SkuImportWizard from '@/components/content/wizards/SkuImportWizard'
import BulkLabelWizard from '@/components/content/wizards/BulkLabelWizard'

import { pageLoad } from '@/composables/pageLoad'
const { loaded } = pageLoad()

export const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Home',
      component: Dashboard,
      meta: {
        pagetitle: 'Home'
      },
      beforeEnter: authGuard
    },
    {
      path: '/settings',
      name: 'Settings',
      meta: {
        pagetitle: 'Settings'
      },
      beforeEnter: authGuard,
      component: IntegrationWizard
    },
    {
      path: '/bulk-label',
      name: 'Create labels',
      meta: {
        pagetitle: 'Create labels'
      },
      beforeEnter: authGuard,
      component: BulkLabelWizard
    },
    {
      path: '/sku-import',
      name: 'SKU import',
      meta: {
        pagetitle: 'Import SKUs'
      },
      beforeEnter: authGuard,
      component: SkuImportWizard
    },
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: Dashboard,
      props: true,
      meta: {
        pagetitle: 'Dashboard'
      },
      beforeEnter: authGuard
    },
    {
      path: '/brands',
      name: 'Brands',
      component: Brands,
      props: true,
      meta: {
        pagetitle: 'Brands'
      },
      beforeEnter: authGuard
    },
    {
      path: '/inventory',
      name: 'Inventory',
      component: Inventory,
      props: true,
      meta: {
        pagetitle: 'Inventory'
      },
      beforeEnter: authGuard
    },
    {
      path: '/sku',
      name: 'Skus',
      component: Skus,
      props: true,
      meta: {
        pagetitle: 'Skus'
      },
      beforeEnter: authGuard
    },
    {
      path: '/sku/:id',
      name: 'SkuForm',
      component: SkuForm,
      props: true,
      meta: {
        pagetitle: 'Sku Form'
      },
      beforeEnter: authGuard
    },
    {
      path: '/sku/new',
      name: 'SkuForm New',
      component: SkuForm,
      props: true,
      meta: {
        pagetitle: 'Sku Form'
      },
      beforeEnter: authGuard
    },
    {
      path: '/suppliers',
      name: 'Suppliers',
      component: Suppliers,
      props: true,
      meta: {
        pagetitle: 'Suppliers'
      },
      beforeEnter: authGuard
    },
    {
      path: '/suppliers/:id',
      name: 'SupplierForm',
      component: SupplierForm,
      props: true,
      meta: {
        pagetitle: 'Supplier Form'
      },
      beforeEnter: authGuard
    },
    {
      path: '/bulk-update-pricing/',
      name: 'BulkUpdatePricing',
      component: BulkUpdatePricing,
      props: true,
      meta: {
        pagetitle: 'Update supplier pricing'
      },
      beforeEnter: authGuard
    },
    {
      path: '/customers',
      name: 'Customers',
      component: Customers,
      props: true,
      meta: {
        pagetitle: 'Customers'
      },
      beforeEnter: authGuard
    },
    {
      path: '/customers/:id',
      name: 'CustomerForm',
      component: CustomerForm,
      props: true,
      meta: {
        pagetitle: 'Customer Form'
      },
      beforeEnter: authGuard
    },
    {
      path: '/products',
      name: 'Products',
      props: true,
      meta: {
        pagetitle: 'Products'
      },
      component: Products,
      beforeEnter: authGuard
    },
    {
      path: '/products/:id',
      name: 'ProductForm',
      component: ProductForm,
      props: true,
      meta: {
        pagetitle: 'Product Form'
      },
      beforeEnter: authGuard
    },
    {
      path: '/order-items',
      name: 'OrderItems',
      component: Items,
      props: true,
      meta: {
        pagetitle: 'Order items'
      },
      beforeEnter: authGuard
    },
    {
      path: '/orders',
      name: 'Orders',
      component: Orders,
      props: true,
      meta: {
        pagetitle: 'Orders'
      },
      beforeEnter: authGuard
    },
    {
      path: '/orders/:id',
      name: 'OrderForm',
      component: OrderForm,
      props: true,
      meta: {
        pagetitle: 'Order Form'
      },
      beforeEnter: authGuard
    },
    {
      path: '/alerts',
      name: 'Alerts',
      props: true,
      meta: {
        pagetitle: 'Alerts'
      },
      component: Alerts,
      beforeEnter: authGuard
    },
    {
      path: '/locations',
      name: 'Locations',
      props: true,
      meta: {
        pagetitle: 'Locations'
      },
      component: Locations,
      beforeEnter: authGuard
    },
    {
      path: '/locations/:id',
      name: 'LocationForm',
      component: LocationForm,
      props: true,
      meta: {
        pagetitle: 'Location Form'
      },
      beforeEnter: authGuard
    },
    {
      path: '/purchase-orders',
      name: 'PurchaseOrders',
      props: true,
      meta: {
        pagetitle: 'Purchase Orders'
      },
      component: PurchaseOrders,
      beforeEnter: authGuard
    },
    {
      path: '/purchase-order-items',
      name: 'PurchaseOrderItems',
      props: true,
      meta: {
        pageTitle: 'Purchase Order Items'
      },
      component: PurchaseOrderItems,
      beforeEnter: authGuard
    },
    {
      path: '/purchase-order-items/not-received',
      name: 'PurchaseOrderItemsNotReceived',
      props: true,
      meta: {
        pageTitle: 'Purchase Order Items'
      },
      component: PurchaseOrderItems,
      beforeEnter: authGuard
    },
    {
      path: '/purchase-orders/:id',
      name: 'PurchaseOrderForm',
      component: PurchaseOrderForm,
      props: true,
      meta: {
        pagetitle: 'Purchase Order Form'
      },
      beforeEnter: authGuard
    },
    {
      path: '/messages',
      name: 'Messages',
      component: Messages,
      props: true,
      meta: {
        pageTitle: 'Messages'
      },
      beforeEnter: authGuard
    },
    {
      path: '/messages/:id',
      name: 'MessageForm',
      props: true,
      component: MessageForm,
      beforeEnter: authGuard
    },
    {
      path: '/returns',
      name: 'Returns',
      component: Returns,
      props: true,
      meta: {
        pageTitle: 'Returns'
      },
      beforeEnter: authGuard
    },
    {
      path: '/returns/:id',
      name: 'ReturnForm',
      props: true,
      component: ReturnForm,
      beforeEnter: authGuard
    },
    {
      path: '/categories',
      name: 'Categories',
      component: Categories,
      props: true,
      meta: {
        pagetitle: 'Categories'
      },
      beforeEnter: authGuard
    },
    {
      path: '/categories/:id',
      name: 'CategoryForm',
      props: true,
      meta: {
        pagetitle: 'Category Form'
      },
      component: CategoryForm,
      beforeEnter: authGuard
    },
    {
      path: '/channels',
      name: 'Channels',
      component: Channels,
      props: true,
      meta: {
        pagetitle: 'Channels'
      },
      beforeEnter: authGuard
    },
    {
      path: '/channels/:id',
      name: 'ChannelForm',
      props: true,
      meta: {
        pagetitle: 'Channel Form'
      },
      component: ChannelForm,
      beforeEnter: authGuard
    },
    {
      path: '/company',
      name: 'CompanyForm',
      meta: {
        pagetitle: 'Company'
      },
      component: CompanyForm,
      beforeEnter: authGuard
    },
    {
      path: '/User',
      name: 'UserForm',
      meta: {
        pagetitle: 'User'
      },
      component: UserForm,
      beforeEnter: authGuard
    },
    {
      path: '/backorder',
      name: 'Backorder',
      component: BackorderSkus,
      props: true,
      meta: {
        pagetitle: 'Backorder'
      },
      beforeEnter: authGuard
    },
    {
      path: '/dispatches',
      name: 'Dispatches',
      component: Dispatches,
      props: true,
      meta: {
        pagetitle: 'Dispatches'
      },
      beforeEnter: authGuard
    },
    {
      path: '/dispatches/:id',
      name: 'DispatchForm',
      meta: {
        pagetitle: 'Dispatch Form'
      },
      props: true,
      component: DispatchForm,
      beforeEnter: authGuard
    },
    {
      path: '/invoices',
      name: 'Invoices',
      component: Invoices,
      props: true,
      meta: {
        pagetitle: 'Invoices'
      },
      beforeEnter: authGuard
    },
    {
      path: '/invoices/:id',
      name: 'InvoiceForm',
      props: true,
      meta: {
        pagetitle: 'Invoice Form'
      },
      component: InvoiceForm,
      beforeEnter: authGuard
    },
    {
      path: '/labels',
      name: 'Labels',
      props: true,
      component: LabelsList,
      beforeEnter: authGuard
    },
    {
      path: '/labels/unprinted',
      name: 'Unprinted-Labels',
      props: { unprinted: true },
      component: LabelsList,
      beforeEnter: authGuard
    },
    {
      path: '/reports',
      name: 'Reports',
      props: true,
      component: Reports,
      beforeEnter: authGuard
    },
    {
      path: '/reports/:id',
      name: 'ReportForm',
      props: true,
      component: ReportForm,
      beforeEnter: authGuard
    },
    {
      path: '/ebay-token',
      name: 'EbayToken',
      component: EbayToken,
      beforeEnter: authGuard
    },
    {
      path: '/ecommerce-token',
      name: 'ShopifyToken',
      component: ShopifyToken,
      beforeEnter: authGuard
    },
    {
      path: '/amazon-token',
      name: 'AmazonToken',
      component: AmazonToken,
      beforeEnter: authGuard
    },
    { path: '/:pathMatch(.*)*', redirect: '/' }
  ]
})

router.beforeEach((to, from) => {
  loaded()
  let pagetitle = getPagetitle(to)
  document.title = pagetitle
})

const getPagetitle = (to) => {
  let pagetitle = to.meta.pagetitle || 'Braid'
  switch (true) {
    case to.query.filter && to.query.filter.includes('showArchived=2'):
      pagetitle += ' - Archived'
  }
  return pagetitle
}
