import { onMounted } from 'vue'
import useApi from '@/components/useApi'

export function checkTenantComplete() {
  const api = useApi()

  const checkTenantComplete = async () => {
    return await api.checkTenantComplete()
  }

  onMounted(() => {
    return checkTenantComplete()
  })
}
