<template>
  <ValidationProvider ref="validation" v-slot="{ errors, changes }">
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: { disable: (!changes && !tenantOrderId) || errors || saving },
        3: { disable: disablePrintButtons },
        4: { disable: disablePrintButtons },
        5: { disable: disablePrintButtons }
      }"
    ></ActionBar>

    <TableTabs ref="tabManager" v-slot="{ activeTab }" :tabs="tabs">
      <TableContainer v-if="activeTab === 'Dispatch'">
        <TablePanel :cols="3" :width="4">
          <template #1>
            <TableForm>
              <!-- SHIPMENT NUMBER -->
              <TableRow
                v-if="local.channelDispatchId"
                v-model="local.channelDispatchId"
                label="Dispatch Order"
                read-only
                type="text"
              />
              <!-- ./SHIPMENT NUMBER -->

              <!-- ORDER NUMBER -->
              <TableRow
                v-if="local.orderNumber != null"
                v-model="local.orderNumber"
                read-only
                label="Order Number"
                type="select"
                :link="`/orders/${local.tenantOrderId}`"
              ></TableRow>
              <!-- ./ORDER NUMBER -->

              <!--  STATUS -->
              <TableRow
                v-model="local.status"
                label="Status"
                type="select"
                :options="[
                  {
                    value: 0,
                    label: 'Draft'
                  },
                  { value: 1, label: 'Label created' },
                  { value: 2, label: 'Shipped' }
                ]"
              />
              <!-- ./STATUS -->

              <!-- SHIP DATE -->
              <TableRow v-model="local.shipDate" type="date" label="Ship Date">
              </TableRow>
              <!-- ./SHIP DATE -->

              <!-- CARRIER -->
              <TableRow
                v-model="local.carrier"
                type="select"
                label="Carrier"
                :options="[
                  { value: 'UPS', label: 'UPS' },
                  { value: 'DHL', label: 'DHL' },
                  { value: 'HERMES', label: 'HERMES' },
                  { value: 'FEDEX', label: 'FEDEX' },
                  { value: 'DPD', label: 'DPD' },
                  { value: 'ROYAL_MAIL', label: 'Royal Mail' }
                ]"
              ></TableRow>
              <!-- ./CARRIER -->
            </TableForm>
          </template>

          <template #2>
            <TableForm>
              <TableRow
                v-model="local.dispatchNotes"
                type="textarea"
                label="Dispatch Notes"
              ></TableRow>
            </TableForm>
          </template>
          <template v-if="local.consignments.length > 0" #3>
            <ConsignmentForm
              v-for="(consignment, index) in local.consignments"
              :key="'consignment' + index"
              :consignment="local.consignments[index]"
              :consignment-index="index"
              :tenant-channel-id="remote.tenantChannelId"
              :consignment-length="local.consignments.length"
              :add-consignment="addConsignmentToDispatch"
              @update-dispatch="updateConsignment"
            >
            </ConsignmentForm>
          </template>
        </TablePanel>

        <TablePanel>
          <template #1>
            <TableGrid
              ref="dispatchItemsGrid"
              :rows="local.dispatchItems"
              :cols="dispatchItemCols"
              id-key="tenantDispatchItemId"
              :total-count="local.dispatchItems.length"
              :pagination="false"
              scrollable
            ></TableGrid>
          </template>
        </TablePanel>

        <TablePanel header="Shipping dimensions">
          <template #1>
            <PackagePreset
              ref="packagePresetComponent"
              :package-presets="packagePresets"
              @new-preset="formatPackagePresets"
              @update-value="updatePackageDimensions"
            ></PackagePreset>
          </template>
        </TablePanel>

        <LabelsPanel
          v-if="!pageLoading"
          ref="labelsPanelRef"
          :order="local.order"
          :dispatch="local"
          :locations="locations"
          :items-total="itemsTotal"
          :channels="channels"
          @get-label="getLabel"
          @update-status="updateStatus"
        ></LabelsPanel>
      </TableContainer>

      <TableContainer v-if="activeTab === 'Labels'">
        <TablePanel>
          <template #1>
            <TableGrid
              ref="labelsGrid"
              :rows="labels"
              :cols="labelCols"
              id-key="tenantLabelId"
              :total-count="labels.length"
              :pagination="false"
              select-id="tenantLabelId"
              checkbox
              select-all
              scrollable
            ></TableGrid>
          </template>
        </TablePanel>
      </TableContainer>
    </TableTabs>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    ></ConfirmLeaveFormModal>
  </ValidationProvider>
</template>

<script setup>
import {
  ref,
  computed,
  onUpdated,
  nextTick,
  inject,
  watch,
  watchEffect
} from 'vue'
const _ = require('lodash')

const { formatDate, formatDateTime, UTC_OFFSET } = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'
import formatForSelect from '@/composables/formatForSelect'

import RowContainer from '@/components/table/form/RowContainer.vue'
import PackagePreset from '@/components/PackagePreset'
import LabelsPanel from '@/components/content/dispatches/Labels'
import useApi from '@/components/useApi'
import useCarriers from '@/composables/selectData/useCarriers.js'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

import { CustomsPdf } from '@/composables/pdfs/customsPdf'
import { CreateMultipleLabelPdf } from '@/composables/pdfs/multipleLabelPdf'
import { CreateIntegratedLabel } from '@/composables/pdfs/integratedLabelPdf'
import { getSkuDataForLabel } from '@/composables/getSkuDataForLabel'
import { useRouter } from 'vue-router'

const { getSkusForLabel } = getSkuDataForLabel()
const grid = ref(null)
const router = useRouter()
const api = useApi()
const { getCarriers } = useCarriers()
const carriers = getCarriers()
const customsPdf = new CustomsPdf()

const saving = ref(false)
const packagePresetComponent = ref(null)
const locations = ref([])
const labelModal = ref(null)
const carrierLabelData = ref({ order: {}, orderItems: [], skus: [] })
const packagePresets = ref([])
const createMultipleLabelPdf = new CreateMultipleLabelPdf()
const createIntegratedLabelPdf = new CreateIntegratedLabel()
const dispatchId = ref(false)
/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: String,
    default: ''
  },
  tenantOrderId: {
    type: String,
    default: null
  }
})

const disablePrintButtons = computed(() => {
  if (!labelsGrid.value) {
    return true
  }

  return labelsGrid.value.selected.length === 0
})

const itemsTotal = computed(() => {
  let total = 0.0

  let index = 0
  let length = local.value.dispatchItems.length
  for (index; index < length; index++) {
    let dispatchItem = local.value.dispatchItems[index]
    let price = dispatchItem.price

    if (!price) {
      continue
    }

    price = parseFloat(price)
    total += price
  }

  return total
})

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const dispatchItemsGrid = ref(null)
const labelsGrid = ref(null)
const selectedTab = ref()
const tabManager = ref(null)
watchEffect(() => {
  if (tabManager.value) {
    selectedTab.value = tabManager.value.tab
  }
})

// Object that contains all the data
const local = ref({ consignments: [], dispatchItems: [], order: {} })

const remote = ref({})

/*
 *****************      VALIDATION PROVIDER
 */

// Template ref to validation provider component
const validation = ref(null)

/*
 *****************      MODALS
 */
const confirmLeaveModal = ref(null)
const confirmModalNext = ref(null)

const closeConfirmModal = () => {
  confirmModalNext.value = null
  confirmLeaveModal.value.hide()
}

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmModalhide.value()
  confirmModalNext.value()
}

const getPackagePresets = async () => {
  let response = await api.get('/packagePresets')
  let presets = response.data.packagePresets
  formatPackagePresets(presets)
}

const formatPackagePresets = (presets = []) => {
  packagePresets.value = []
  let index = 0
  let length = presets.length
  for (index; index < length; index++) {
    let data = presets[index]
    let label = `${data.name} - ${data.height} x ${data.width} x ${data.depth}`
    packagePresets.value.push({
      label: label,
      value: data.tenantPackagePresetId,
      ...data
    })
  }
}

const updatePackageDimensions = () => {
  let dimensions = packagePresetComponent.value.dimensions
  local.value.height = dimensions.height
  local.value.width = dimensions.width
  local.value.depth = dimensions.depth
  local.value.dimensionUnits = dimensions.dimensionUnits
}

const getCompanyData = async () => {
  let response = await api.get('/tenant')
  return response.data.tenant
}

const getLocations = async () => {
  let retrievedLocations = await api.getLocations()
  let formattedLocations = retrievedLocations.map((location) => {
    return {
      ...location,
      value: location.tenantLocationId,
      label: location.name
    }
  })
  locations.value = formattedLocations
}

/**
 * LABELS
 */

const updateStatus = () => {
  local.value.status = 2
}
const getQuotes = async () => {
  let response = await api.get('/parcel2Go/quotes', {
    order: local.value.order,
    dispatch: local.value
  })
  let quotes = response.data.quotes
  let formattedQuotes = []
  let index = 0
  let length = quotes.length
  for (index; index < length; index++) {
    let quote = quotes[index]
    let value = quote.Service.Slug
    let label = `${quote.Service.Name}: ${quote.TotalPrice}`
    let price = parseFloat(quote.TotalPrice)
    formattedQuotes.push({
      value,
      label,
      price
    })
  }

  formattedQuotes.sort((a, b) => {
    return a.price - b.price
  })

  carrierLabelData.value.quotes = formattedQuotes
}

const getLabel = async (tenantLabelId) => {
  if (!labels.value || labels.value.length === 0) {
    await getLabels()
  }

  let label = labels.value.find((label) => {
    return label.tenantLabelId === tenantLabelId
  })

  if (!label) {
    await getLabels()
    label = labels.value.find((label) => {
      return label.tenantLabelId === tenantLabelId
    })
  }

  if (!label.label) {
    label.label = await getAndSaveLabel(label)
  }

  let response = await api.get('/labels/display-labels', {
    tenantLabelIds: [tenantLabelId]
  })
  let url = response.data.labels[0].url
  openLabel(url)
}

const openLabel = (url) => {
  window.open(url, '_blank')
}

const openLabelA4 = (url) => {
  createMultipleLabelPdf.create([url])
}

const openLabelIntegrated = (url) => {
  createIntegratedLabel.createMultiple([
    { url: url, dispatchItems: local.value.dispatchItems }
  ])
}

const getAndSaveLabel = async (label) => {
  let service = ''

  switch (label.carrier) {
    case 'ROYAL_MAIL':
      service = 'royal-mail'
      break

    case 'PARCEL2GO':
      service = 'parcel2go'
      break
  }

  let response = await api.get(`/${service}/labels`, {
    carrier: label.carrier,
    carrierLabelIds: [label.carrierLabelId],
    tenantChannelId: label.tenantChannelId
  })

  let imageUrls = response.data.imageUrls

  let labelRecord = {
    label: {
      carrierLabelId: label.carrierLabelId,
      tenantLabelId: label.tenantLabelId,
      label: imageUrls[label.carrierLabelId]
    }
  }

  await api.put(`/labels/`, {
    labels: [labelRecord],
    saving
  })

  return imageUrls[label.carrierLabelId]
}

const channels = ref([])
const labels = ref([])
const labelCols = ref([
  {
    label: 'Label number',
    name: 'carrierLabelId',
    visible: true,
    primary: true
  },
  {
    label: 'Carrier',
    name: 'carrierDisplayName',
    visible: true
  },
  {
    label: 'Package',
    name: 'packageDisplayName',
    visible: true
  },
  {
    label: 'Service',
    name: 'service',
    visible: true
  },
  {
    label: 'Created by',
    name: 'createdBy',
    visible: true
  },
  {
    label: 'Created at',
    name: 'createdAt',
    visible: true
  }
])

const getLabels = async () => {
  if (!local.value.tenantDispatchId) {
    return
  }

  const args = {
    tenantDispatchId: local.value.tenantDispatchId
  }

  let response = await api.get('/labels/dispatch', args)
  labels.value = response.data.labels
}

const relevantServices = computed(() => {
  if (!carrierLabelData.value.carrierId) {
    return []
  }

  let filtered = carrierLabelData.value.services.filter((item) => {
    return item.carrierId == carrierLabelData.value.carrierId
  })

  return formatForSelect(filtered, 'serviceCode', 'serviceName')
})

const relevantPackageTypes = computed(() => {
  if (!carrierLabelData.value.carrierId) {
    return []
  }

  let filtered = carrierLabelData.value.packageTypes.filter((item) => {
    return item.carrierId == carrierLabelData.value.carrierId
  })

  return formatForSelect(filtered, 'packageName', 'displayName')
})

/**
 *****************      TABLE FORM
 */
import ConsignmentForm from '@/components/content/dispatches/consignments/ConsignmentForm'
import ConsignmentItems from '@/components/content/dispatches/consignments/ConsignmentItems'
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

const tabs = ['Dispatch', 'Labels']

/*
 ************* HANDLE SHIPMENT
 */
const trackingLink = computed(() => {
  if (!local.value.carrier) {
    return ''
  }

  if (!local.value.trackingNumber || local.value.trackingLink === '') {
    return ''
  }

  let carrierUrl = carriers.value[local.value.carrier]
  return carrierUrl.replace('[TRACKING_ID]', local.value.trackingNumber)
})

const canCreateLabel = computed(() => {
  return true
})

const isEmpty = (value) => {
  return value == null || value == ''
}

const getDispatchId = () => {
  if (props.id && parseInt(props.id) > 0) {
    dispatchId.value = props.id
  }
}

const getDispatch = async () => {
  if (!dispatchId.value || dispatchId.value === 'new') {
    return
  }
  let response = await api.get('/dispatches/' + dispatchId.value)
  handleDispatchResponse(response.data)

  let orderResponse = await api.get('/orders/' + local.value.tenantOrderId)
  handleOrderResponse(orderResponse.data)

  local.value.shippingAddrLine1 = local.value.order.shippingAddrLine1
  local.value.shippingAddrLine2 = local.value.order.shippingAddrLine2
  local.value.shippingAddrLine3 = local.value.order.shippingAddrLine3
  local.value.shippingAddrCountry = local.value.order.shippingAddrCountry
  local.value.shippingAddrPostcode = local.value.order.shippingAddrPostcode
}

const getOrder = async () => {
  setDefaults()

  if (!local.value.tenantOrderId) {
    return
  }
  let response = await api.get('/orders/' + local.value.tenantOrderId)
  handleOrderResponse(response.data)
  // addConsignmentToDispatch()
  await getSkuShippingDetails(response.data.orderItems)
}

const getSkuShippingDetails = async (orderItems) => {
  if (orderItems.length !== 1) {
    return
  }

  let sku = orderItems[0].sku
  let response = await api.get('/skus/', {
    sku,
    shipping: 1
  })

  let shippingDetails = response.data.sku

  if (shippingDetails.useProductDimensions) {
    local.value.width = shippingDetails.shippingWidth
    local.value.height = shippingDetails.shippingHeight
    local.value.depth = shippingDetails.shippingDepth
    local.value.dimensionUnits = shippingDetails.shippingDimensionUnit
    if (shippingDetails.shippingWeightUnit == 'G') {
      local.value.weightInGrams = shippingDetails.shippingWeight
    }

    return
  }

  local.value.width = shippingDetails.skuShippingWidth
  local.value.height = shippingDetails.skuShippingHeight
  local.value.depth = shippingDetails.skuShippingDepth
  local.value.dimensionUnits = shippingDetails.skuShippingDimensionUnit
  if (shippingDetails.skuShippingWeightUnit == 'G') {
    local.value.weightInGrams = shippingDetails.skuShippingWeight
  }
}

const setDefaults = () => {
  local.value = {
    carrier: '',
    status: 0,
    tenantOrderId: props.tenantOrderId,
    orderNumber: '',
    order: {},
    dispatchNotes: '',
    shipDate: formatDate(Date.now()),
    consignments: [],
    dispatchItems: []
  }
}

const update = async () => {
  if (!dispatchId.value) {
    insert()
    return
  }

  await api.put(
    '/dispatches/' + dispatchId.value,
    { dispatch: local.value },
    saving
  )
  validation.value.save()
}

const updateConsignment = (consignment) => {
  let consignmentNumber = consignment.consignmentNumber

  if (!local.value.consignments[consignmentNumber]) {
    // local.value.consignments.push(consignment)
    return
  }

  local.value.consignments[consignmentNumber] = consignment
}

const insert = async () => {
  let dispatch = {
    ...local.value,
    dispatchItems: local.value.order.orderItems
  }
  let response = await api.post('/dispatches/', { dispatch }, saving)
  dispatchId.value = String(response.data.insertedId)
  router.replace('/dispatches/' + dispatchId.value)
  handleDispatchResponse(response.data)
  setTitle()
}

/**
 * Handles response from API call
 */
const handleDispatchResponse = (data) => {
  let dispatch = data.dispatches[0]

  local.value = {
    tenantDispatchId: dispatch.tenantDispatchId,
    channelDispatchId: dispatch.channelDispatchId,
    carrier: dispatch.carrier,
    status: dispatch.status,
    tenantOrderId: dispatch.tenantOrderId,
    orderNumber: dispatch.orderNumber,
    channelOrderId: dispatch.channelOrderId,
    dispatchNotes: dispatch.dispatchNotes,
    weightInGrams: dispatch.weightInGrams,
    width: dispatch.width,
    height: dispatch.height,
    depth: dispatch.depth,
    dimensionUnits: dispatch.dimensionUnits,
    shipDate: formatDate(dispatch.shipDate),
    createdBy: dispatch.createdBy,
    updatedBy: dispatch.updatedBy,
    createdAt: formatDateTime(dispatch.createdAt, utcOffset.value),
    updatedAt: formatDateTime(dispatch.updatedAt, utcOffset.value),
    consignments: handleConsignmentsResponse(dispatch.consignments),
    dispatchItems: dispatch.dispatchItems
  }

  remote.value = JSON.parse(JSON.stringify(local))
}

const handleConsignmentsResponse = (data) => {
  if (!data || data.length == 0) {
    return [{ consignmentNumber: 1 }]
  }

  let consignments = []
  data.forEach((consignment) => {
    consignments.push({
      tenantConsignmentId: consignment.tenantConsignmentId,
      trackingNumber: consignment.trackingNumber || '',
      consignmentNumber: consignment.consignmentNumber,
      tenantLabelId: consignment.tenantLabelId
    })
  })
  return consignments
}

const formatOrderItems = (orderItems, dispatchItems) => {
  let formatted = []
  let index = 0
  let length = orderItems.length

  for (index; index < length; index++) {
    let orderItem = orderItems[index]

    let dispatchItem = dispatchItems.find((dispatchItem) => {
      return dispatchItem.tenantOrderItemId == orderItem.tenantOrderItemId
    })

    let quantity = orderItem.quantity

    if (dispatchItem) {
      quantity -= dispatchItem.quantity
    }

    formatted.push({
      tenantOrderItemId: orderItem.tenantOrderItemId,
      sku: orderItem.sku,
      title: orderItem.title,
      quantityTotal: orderItem.quantity,
      quantity: quantity,
      price: orderItem.price,
      quantityNotDispatched: orderItem.quantity
    })
  }

  return formatted
}

/*
 ************* HANDLING CONSIGNMENTS
 */
const addConsignmentToDispatch = () => {
  local.value.consignments.push({
    consignmentNumber: local.value.consignments.length + 1
  })
}

/*
 ************* HANDLING ORDERS
 */
const gotoOrder = (id) => {
  if (id) router.push('/orders/' + id)
}

const handleOrderResponse = (data) => {
  let order = data.orders[0]
  if (!order) {
    local.value.order = {}
    return
  }

  let orderItems = data.orderItems || []
  let dispatchItems = data.dispatchItems || []
  local.value.tenantOrderId = local.value.tenantOrderId
  local.value.channelOrderId = order.channelOrderId

  local.value.order = {
    tenantOrderId: local.value.tenantOrderId,
    tenantCustomerId: order.tenantCustomerId,
    channelOrderId: order.channelOrderId,
    status: order.status,
    email: order.email,
    internalNotes: order.internalNotes,
    phone: order.phone,
    customerName: order.customerName,
    dispatchLocationId: order.dispatchLocationId,
    billingAddrLine1: order.billingAddrLine1,
    billingAddrLine2: order.billingAddrLine2,
    billingAddrLine3: order.billingAddrLine3,
    billingAddrPostcode: order.billingAddrPostcode,
    billingAddrCountry: order.billingAddrCountry,
    shippingAddrLine1: order.shippingAddrLine1,
    shippingAddrLine2: order.shippingAddrLine2,
    shippingAddrLine3: order.shippingAddrLine3,
    shippingAddrPostcode: order.shippingAddrPostcode,
    shippingAddrCountry: order.shippingAddrCountry,
    useShippingAddrForBilling: order.useShippingAddrForBilling,
    orderItems: formatOrderItems(orderItems, dispatchItems),
    tenantChannelId: order.tenantChannelId || null,
    channelName: order.channelName || null
  }
}

const createCommercialInvoice = async () => {
  if (!locations.value || locations.value.length === 0) {
    await getLocations()
  }

  const tenantData = await getCompanyData()
  customsPdf.create(tenantData, local.value, locations.value)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const printA4Labels = async () => {
  let urls = await getLabelUrls()
  createMultipleLabelPdf.create(urls, 'A4')
  markLabelsPrinted()
}

const printLabels = async () => {
  let urls = await getLabelUrls()
  createMultipleLabelPdf.create(urls, '4X6')
  markLabelsPrinted()
}

const printIntegratedLabels = async () => {
  let urls = await getLabelUrls()
  let skus = await getSkusForLabel(api, urls)
  createIntegratedLabelPdf.createMultiple(urls, skus)
  markLabelsPrinted()
}

const markLabelsPrinted = async () => {
  await api.put('/labels/printed', {
    tenantLabelIds: labelsGrid.value.selected
  })
  updateFilters(true)
}

const buttons = ref([
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Commercial invoice',
    click: createCommercialInvoice,
    icon: ['fas', 'file-invoice-dollar']
  },
  {
    id: 2,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  },
  {
    id: 3,
    label: 'Print 6x4',
    click: printLabels,
    icon: ['fas', 'print']
  },
  {
    id: 4,
    label: 'Print integrated',
    click: printIntegratedLabels,
    icon: ['fas', 'print']
  },
  {
    id: 5,
    label: 'Print A4',
    click: printA4Labels,
    icon: ['fas', 'print']
  }
])

const getLabelUrls = async () => {
  if (!labelsGrid.value) {
    return
  }

  if (!labelsGrid.value.selected || labelsGrid.value.selected.length === 0) {
    return
  }

  await getAndSaveLabels()

  let response = await api.get('/labels/display-labels', {
    tenantLabelIds: labelsGrid.value.selected
  })

  return response.data.labels
}

const getAndSaveLabels = async () => {
  let tenantChannelId = ''
  let carrierLabelIds = []

  let index = 0
  let length = labelsGrid.value.selected.length
  for (index; index < length; index++) {
    let tenantLabelId = labelsGrid.value.selected[index]
    let row = labels.value.find((row) => {
      return row.tenantLabelId == tenantLabelId
    })

    if (!row) {
      continue
    }

    if (!row.label) {
      row.label = await getAndSaveLabel(row)
    }

    carrierLabelIds.push(row.carrierLabelId)

    if (tenantChannelId === '') {
      tenantChannelId = row.tenantChannelId || ''
      continue
    }

    if (tenantChannelId != row.tenantChannelId) {
      console.log('need to all be same channel')
      return
    }
  }

  let imageUrls = labels.value.map((label) => {
    return label.label
  })

  return imageUrls
}

const setTitle = async () => {
  let pageTitle = local.value.channelDispatchId || 'Create Dispatch'
  document.getElementById('pagetitle').innerHTML = pageTitle
  document.title = pageTitle
}

const getChannels = async () => {
  let response = await api.get('/channels', {
    filter: { channelType: 'SHIPPING' }
  })
  channels.value = response.data.channels
}

const getData = async () => {
  loading()
  try {
    progressBarStart()
    getDispatchId()

    await getOrder()
    await getDispatch()

    getPackagePresets()
    getLocations()
    getLabels()
    getChannels()
    loaded()
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
    router.back()
  }
  setTitle()
}

getData()

const routeRefreshOnMounted = ref(false)
onUpdated(() => {
  if (
    routeRefreshOnMounted.value &&
    router.currentRoute.value.params.id == 'new'
  ) {
    router.go()
  }
  routeRefreshOnMounted.value = true
})

/**
 * ORDER ITEM GRID
 */

const getQtyNotDispatched = (item) => {
  let quantityNotDispatched =
    parseInt(item.quantityTotal) - parseInt(item.quantity)

  item.quantityNotDispatched =
    quantityNotDispatched < 0 ? 0 : quantityNotDispatched
}

const orderItemCols = ref([
  {
    label: 'SKU',
    name: 'sku',
    visible: true,
    primary: true
  },
  {
    label: 'Title',
    name: 'title',
    visible: true
  },
  {
    label: 'Quantity not dispatched',
    name: 'quantityNotDispatched',
    visible: true,
    number: true
  },
  {
    label: 'Quantity dispatched',
    name: 'quantity',
    visible: true,
    number: true,
    input: true,
    inputType: 'number',
    change: getQtyNotDispatched
  },
  {
    label: 'Total Quantity',
    name: 'quantityTotal',
    visible: true,
    number: true
  },
  {
    label: 'Price',
    name: 'price',
    visible: true,
    number: true
  }
])

/**
 * DISPATCH ITEM GRID
 */

const dispatchItemCols = ref([
  {
    label: 'SKU',
    name: 'sku',
    visible: true,
    primary: true
  },
  {
    label: 'Title',
    name: 'title',
    visible: true
  },
  {
    label: 'Quantity',
    name: 'quantity',
    visible: true,
    number: true,
    input: true,
    inputType: 'number'
  },
  {
    label: 'Price',
    name: 'price',
    visible: true,
    number: true
  }
])

watch(selectedTab, async (current, previous) => {
  switch (current) {
    case 'Labels':
      if (!labels.value) {
        await getLabels()
      }
      nextTick(() => {
        labelsGrid.value.updateRows()
      })
      break

    case 'Dispatch':
      nextTick(() => {
        dispatchItemsGrid.value.updateRows()
      })
      break
  }
})
</script>

<style lang="scss">
#trackingLink {
  width: 100%;
  color: $braid-purple;
  text-decoration: none;
  font-weight: bold;

  &:hover,
  &:active {
    text-decoration: none;
  }
}
v-divider {
  border-bottom: 1px solid black;
  padding-bottom: 0.5rem;
  background-color: red;
}
</style>
