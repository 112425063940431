<template>
  <TablePanel header="Selling Price">
    <template #1>
      <span v-if="editable && !pageLoading">
        <div class="row">
          <div class="col-2 pricing-profile-action">
            <p><b>Pricing Profile Action:</b></p>
          </div>
        </div>
        <div class="row">
          <div class="col-2 pricing-profile-action">
            <select
              ref="actionSelect"
              v-model="pricingProfileAction"
              class="form-control pricing-profile-action"
              style="width: 100%"
            >
              <option value="">Choose an action</option>
              <option value="STANDARD">Standardise VAT</option>
              <option value="CUSTOM">Customise VAT</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-2 pricing-profile-action">
            <button
              v-if="skuId && skuId != 'new'"
              :disabled="
                selectedSkuProfiles.length == 0 ||
                pricingProfileAction == null ||
                pricingProfileAction === ''
              "
              type="button"
              class="btn btn-purple"
              style="width: 100%"
              @click="performPricingProfileAction"
            >
              {{ pricingProfileButtonText }}
            </button>
          </div>
        </div>
      </span>
      <TableGrid
        ref="pricingProfilesGrid"
        :rows="visiblePricingProfiles"
        :cols="visibleSkuProfileCols"
        :total-count="visiblePricingProfiles.length"
        id-key="m2mSkuPricingId"
        checkbox
        :get-checked="isPricingProfileChecked"
        :toggle="selectPricingProfile"
        :toggle-all="selectAllPricingProfiles"
        :selected="selectedSkuProfiles"
        select-all
        delete
        :delete-function="removePricingProfileFromSku"
        :check-deleted="
          (row) =>
            skuPriceProfiles.findIndex(
              (profile) => profile.tenantPricingId == row.tenantPricingId
            ) == -1
        "
      ></TableGrid>
    </template>
  </TablePanel>
</template>

<script setup>
import { ref, watch, inject, toRaw, computed, onMounted } from 'vue'
const uuid = require('uuid')
import useApi from '@/components/useApi'
const api = useApi()
import { pageLoad } from '@/composables/pageLoad'

const { getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const emit = defineEmits(['update'])

const props = defineProps({
  skuPriceProfiles: {
    type: Array,
    default() {
      return []
    }
  },
  editable: {
    type: Boolean,
    default: true
  },
  skuId: {
    type: [String, Boolean],
    default: ''
  },
  vatNumber: {
    type: String,
    default: null
  }
})

const visiblePricingProfiles = ref([])
const getVisiblePricingProfiles = () => {
  let profiles = pricingProfiles.value
  profiles.forEach((profile) => {
    return {
      ...profile,
      vatRate: null,
      vatType: 'Standard',
      vatPercent: null
    }
  })
  let skuPriceProfiles = JSON.parse(JSON.stringify(skuProfiles.value))
  visiblePricingProfiles.value = profiles
    .concat(skuPriceProfiles)
    .sort((a, b) => {
      switch (true) {
        case a.price != null && b.price == null:
          return -1
        case a.price == null && b.price != null:
          return 1
        case a.price == null && b.price == null:
          return a.name > b.name ? 1 : -1
        case a.price != null && b.price != null:
          return a.price > b.price ? 1 : -1
      }
    })
}

const initialProfiles = ref([...toRaw(props.skuPriceProfiles)])

const profilesForSave = computed(() => {
  return visiblePricingProfiles.value
    .filter((profile) => profile.price != null)
    .sort((a, b) => a.m2mSkuPricingId - b.m2mSkuPricingId)
})

const skuProfiles = ref(props.skuPriceProfiles)

const _UNIQUE_STATE_ID = ref(uuid.v4())
// @see @/components/table/ValidationProvider
const registerFormState = inject('registerFormState')
const updateFormChanges = inject('updateFormChanges')

registerFormState(_UNIQUE_STATE_ID, 'Price_Profiles')

watch(
  () => profilesForSave.value,
  (newValue) => {
    let initial = initialProfiles.value.sort(
      (a, b) => a.m2mSkuPricingId - b.m2mSkuPricingId
    )
    let changesMade = !_.isEqual(toRaw(newValue), initial)
    updateFormChanges(_UNIQUE_STATE_ID, 'Price_Profiles', changesMade)
  },
  { deep: true }
)

const modal = ref()

// Action for pricing profile button
const pricingProfileAction = ref('')
// Checkbox selected pricing profiles
const selectedSkuProfiles = ref([])
// Pricing profile modal ref
const pricingProfileModal = ref(null)
// All pricing profile records (filled when modal is opened)
const pricingProfiles = ref(null)
const pricingProfileCount = ref(0)

const skuProfileCols = ref([
  {
    label: 'Pricing Profile Name',
    name: 'name',
    visible: true
  },
  {
    label: 'Country',
    name: 'country',
    visible: true
  },
  {
    label: 'Currency',
    name: 'currency',
    visible: true
  },
  {
    label: 'Price',
    name: 'price',
    input: true,
    inputType: 'number',
    mandatory: true,
    min: 0,
    visible: true
  }
])

const skuProfileVATCols = ref([
  {
    label: 'Price VAT',
    name: 'vatOptions',
    visible: true,
    multiple: true,
    displayValues: {
      0: 'No VAT',
      1: 'Including VAT',
      2: 'Excluding VAT'
    }
  },
  {
    label: 'VAT Type',
    name: 'vatType',
    visible: true,
    input: true,
    inputType: 'select',
    display: 'label',
    displayValues: [
      {
        label: 'Standard',
        value: 'STANDARD'
      },
      {
        label: 'Custom',
        value: 'CUSTOM'
      }
    ],
    reduce: (row) => {
      return row.value
    },
    readonly: (row) => {
      return row.vatType == '-'
    }
  },
  {
    label: 'VAT (%)',
    name: 'vatPercent',
    input: true,
    inputType: 'number',
    min: 0,
    max: 100,
    readonly: (row) => {
      return row.vatType == 'Standard' || row.vatType == '-'
    },
    visible: true
  }
])

const visibleSkuProfileCols = computed(() => {
  if (props.vatNumber == null) {
    return skuProfileCols.value
  }
  return skuProfileCols.value.concat(skuProfileVATCols.value)
})

// Action button text
const pricingProfileButtonText = computed(() => {
  switch (true) {
    case pricingProfileAction.value === 'STANDARD':
      return 'Standarise VAT'
    case pricingProfileAction.value === 'CUSTOM':
      return 'Customise VAT'
    default:
      return 'Pricing Profile Action'
  }
})

/**
 * Function opens modal, if pricing profiles havent been gathered then goes and gets
 */
const getPricingProfiles = async () => {
  if (pricingProfiles.value == null) {
    let response = await api.get('/pricing', {
      filter: {},
      sort: { name: 1 }
    })
    pricingProfiles.value = handleProfiles(response.data.pricingProfiles)
    pricingProfileCount.value = pricingProfiles.value.length
    getVisiblePricingProfiles()
  }
}

/**
 * Removes already assigned pricing profiles from the array
 */
const handleProfiles = (profiles) => {
  let currentPricingProfiles = skuProfiles.value.map(
    (profile) => profile.tenantPricingId
  )

  return profiles
    .filter(
      (profile) => !currentPricingProfiles.includes(profile.tenantPricingId)
    )
    .map((profile) => {
      delete profile.tenantChannelIds
      return {
        ...profile,
        tenantSkuId: props.skuId,
        price: null,
        vatRate: -1,
        vatType: 'Standard',
        vatPercent: null
      }
    })
}

/**
 * Defines if pricing profile is selected
 */
const isPricingProfileChecked = (row) => {
  return selectedSkuProfiles.value.indexOf(row) !== -1
}

/**
 * Selects a pricing profile
 */
const selectPricingProfile = (row) => {
  let index = selectedSkuProfiles.value.indexOf(row)

  if (index !== -1) {
    selectedSkuProfiles.value.splice(index, 1)
    return
  }

  selectedSkuProfiles.value.push(row)
}

/**
 * Selects all pricing profiles
 */
const selectAllPricingProfiles = (checked, rows, idKey) => {
  //   selectedSkuProfiles.value = skuProfiles.value
  if (!checked) {
    selectedSkuProfiles.value = []
    return
  }

  let index = 0
  let length = skuProfiles.value.length

  for (index; index < length; index++) {
    let profile = skuProfiles.value[index]

    if (selectedSkuProfiles.value.indexOf(profile) !== -1) {
      continue
    }

    selectedSkuProfiles.value.push(profile)
  }
}

/**
 * Performs pricing profile action on button click
 */
const performPricingProfileAction = () => {
  if (pricingProfileAction.value == '') {
    return
  }
  selectedSkuProfiles.value.forEach((profile) => {
    switch (pricingProfileAction.value) {
      case 'STANDARD':
        profile.vatType = 'Standard'
        profile.vatPercent = null
        break
      case 'CUSTOM':
        profile.vatType = 'Custom'
        profile.vatPercent = profile.vatRate != -1 ? profile.vatRate : null
        break
    }
    return profile
  })
}

/**
 *
 */
const formatPricingProfiles = () => {
  skuProfiles.value.forEach((profile) => {
    switch (profile.vatType) {
      case 'Standard':
        profile.vatRate = -1
      case 'Custom':
        profile.vatRate = profile.vatPercent
    }
  })
}

/**
 * Removed the pricing profile from the sku
 */
const removePricingProfileFromSku = (row) => {
  skuProfiles.value = skuProfiles.value.filter(
    (el) => el.tenantPricingId != row.tenantPricingId
  )

  let index = pricingProfiles.value.findIndex(
    (profile) => (profile.tenantPricingId = row.tenantPricingId)
  )
  visiblePricingProfiles.value.push(pricingProfiles.value[index])
}

onMounted(async () => {
  await getPricingProfiles()
})

const formatPriceProfiles = () => {
  return profilesForSave.value
}

defineExpose({ formatPriceProfiles })
</script>

<style scoped></style>
