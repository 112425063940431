<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Backordered SKUs"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    v-if="displayGrid"
    ref="grid"
    :cols="columns"
    :rows="rows"
    :total-count="totalCount"
    :filter-function="getRows"
    id-key="tenantPurchaseOrderId"
    scrollable
  ></TableGrid>
</template>

// This is for the keep-alive to work
<script>
export default {
  name: 'BackordersList'
}
</script>

<script setup>
import { ref, onActivated, onMounted, watch, inject } from 'vue'

import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'

const router = useRouter()
const api = useApi()

const props = defineProps({
  pageId: {
    type: String,
    default: ''
  }
})

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const getRows = async (args = {}) => {
  try {
    progressBarStart()

    args.backorder = 1
    let response = await api.get('/skus', args)
    rows.value = response.data.skus
    totalCount.value = parseInt(response.data.count)
    displayGrid.value = true

    progressBarDone()
  } catch (e) {
    progressBarFail()
  }
}

/*
 *****************      GRID
 */

// Template reference to the TableGrid component
const grid = ref(null)
const displayGrid = ref(false)
const rows = ref([])
const defaultSort = ref({ brandName: 1 })
const totalCount = ref(0)

const gotoPurchaseOrder = (id) => {
  router.push('/purchase-orders/' + id)
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'

// Default Column Settings
const cols = [
  {
    label: 'PO Number',
    name: 'poNumber',
    filter: true,
    visible: true,
    onClick: {
      route: '/purchase-orders/',
      id: 'tenantPurchaseOrderId'
    },
    primary: true
  },
  {
    label: 'SKU',
    name: 'sku',
    filter: true,
    visible: true
  },
  {
    label: 'Brand',
    name: 'brandName',
    filter: true,
    visible: true
  },
  {
    label: 'Title',
    name: 'title',
    filter: true,
    visible: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    filter: true,
    visible: true
  },
  {
    label: 'GTIN',
    name: 'gtin',
    filter: true,
    visible: true
  },
  {
    label: 'ASIN',
    name: 'asin',
    filter: true,
    visible: true
  }
]

// Template reference to the ColumnPreferences component
const preferences = ref()

// Shows the modal to reorganise and hide/show columns
const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)
const columns = ref()

const localStorageName = ref('braidBackorderedSKUs')

/**
 * Checks to see if we can read column preferences from local storage. If not
 * it writes the cols array to local storage (as a default)
 */
const readColumnPreferences = () => {
  let columnSettings = localStorage[localStorageName.value]

  if (!columnSettings) {
    localStorage[localStorageName.value] = JSON.stringify(cols)
    columnSettings = localStorage[localStorageName.value]
  }
  columns.value = JSON.parse(columnSettings)
}

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

readColumnPreferences()

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  { id: 0, label: 'Column Settings', click: show, icon: ['fas', 'gear'] }
])

/**
 * When this component is activated (see vue docs here https://vuejs.org/api/composition-api-lifecycle.html#onactivated)
 * Filter the rows shown
 */

const filterUpdate = ref(false)
const appliedFilters = ref({})
import { useColumnFilters } from '@/composables/useColumnFilters'
const { getColumnFilters, getSort } = useColumnFilters()

onActivated(() => {
  filterUpdate.value = true
  if (!grid.value) {
    return
  }

  grid.value.filterColumns(grid.value.args)
})

watch(
  () => props.pageId,
  (current, previous) => {
    filterUpdate.value = true
  }
)

watch(filterUpdate, async (current, previous) => {
  if (!current) {
    return
  }
  let query = router.currentRoute.value.query
  let sort = query.sort ? getSort(query.sort) : defaultSort.value
  let filter = getColumnFilters(query)

  let args = {
    sort: sort,
    filter: filter
  }
  await getRows(args)
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
  filterUpdate.value = false
})
</script>
