export const vOffScreen = {
  updated(el, binding, vnode) {
    let bounding = el.getBoundingClientRect()
    console.log(bounding.bottom)
    if (
      bounding.bottom >
      (window.innerHeight || document.documentElement.clientHeight)
    ) {
      binding.value(el)
    }
  }
}
