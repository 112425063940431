import { PDFDocument, PageSizes } from 'pdf-lib'

export class CreateMultipleLabelPdf {
  constructor() {
    this.document = null
    this.options = {}
    this.type = 'A4'
    this.pageMeasurements = []
  }

  async create(labels, type = 'A4') {
    this.document = await PDFDocument.create()
    this.type = type
    this.pageMeasurements = type === 'A4' ? PageSizes.A4 : [288, 432]

    let index = 0
    let length = labels.length
    for (index; index < length; index++) {
      let labelObject = labels[index]
      let labelUrl = labelObject.url

      if (!labelUrl) {
        continue
      }

      let page = this.document.addPage(this.pageMeasurements)
      let label = await this.embedLabel(labelUrl)
      this.getOptions(label, page)
      page.drawPage(label, this.options)
    }

    await this.openPdf()
  }

  async embedLabel(labelUrl) {
    const labelBytes = await fetch(labelUrl).then((res) => res.arrayBuffer())
    const [label] = await this.document.embedPdf(labelBytes)
    return label
  }

  getOptions(label, page) {
    const labelHeight = label.height
    const pageHeight = page.getHeight()
    const x = 0
    const y = pageHeight - labelHeight
    this.options = { x, y }
  }

  async openPdf() {
    let fileBytesArray = await this.document.save()
    let blob = new Blob([fileBytesArray], { type: 'application/pdf' })
    var url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }
}
