<template>
  <ColumnPreferences
    ref="preferences"
    :cols="itemsColumns"
    list-name="orderItems"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    ref="itemsGrid"
    :cols="colsToShow"
    :rows="visibleRows"
    :pagination="false"
    :total-count="items.length"
    :default-sort="defaultSortItems"
    id-key="tenantOrderItemId"
    scrollable
  ></TableGrid>

  <div v-if="!pageLoading" class="tbl-cell-btn">
    <button type="button" class="btn" @click="openAddSkuModal()">
      <font-awesome-icon :icon="['fas', 'circle-plus']"></font-awesome-icon>
      <b>Add</b>
    </button>
  </div>

  <BraidModal
    ref="addSkuModal"
    @confirm="saveAddSkuModal"
    @close="closeAddSkuModal"
    @reject="closeAddSkuModal"
  >
    <template #title>Add SKU to order</template>
    <template #body>
      <TableGrid
        ref="addSkuModalGrid"
        select-id="tenantSkuId"
        :cols="addSkuCols"
        :rows="addSkuRows"
        :total-count="totalAddSkuCount"
        :filter-function="getAddSkuRows"
        :default-sort="defaultAddSkuSort"
        checkbox
        select-all
        :update-url="false"
        caption="List of SKUs"
        scrollable
      ></TableGrid>

      <div :colspan="addSkuCols.length" class="tbl-cell-btn">
        <button type="button" class="btn" @click="newOrderItem()">
          <font-awesome-icon :icon="['fas', 'circle-plus']"></font-awesome-icon>
          <b>Custom</b>
        </button>
      </div>
    </template>
  </BraidModal>
</template>

<script setup>
import {
  ref,
  computed,
  onMounted,
  nextTick,
  inject,
  watch,
  toRaw,
  toRef
} from 'vue'
import { useColumnFilters } from '@/composables/useColumnFilters'
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
import TableForm from '@/components/table/form/TableForm'
import RowContainer from '@/components/table/form/RowContainer'
import TableRow from '@/components/table/form/TableRow'
const _ = require('lodash')
const uuid = require('uuid')
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import useVat from '@/composables/selectData/useVat'

const itemsGrid = ref(null)
const router = useRouter()
const api = useApi()
const vat = useVat()
const defaultSortItems = ref({ sku: -1 })
const localStorageName = ref('braidOrderItems')
import { columnPreferences } from '@/composables/columnPreferences'
const {
  readColumnPreferences,
  readAlertIconPreferences,
  assignColumnFunctions,
  toggleAlertIcons,
  updateAlertIcons
} = columnPreferences(localStorageName.value)
const emits = defineEmits(['focusNewLine', 'blurNewLine', 'newLineFocus'])

const updateRows = () => {
  itemsGrid.value.updateRows()
}

const updateItemRow = (row) => {
  let index = items.value.findIndex((item) => {
    return item.tenantOrderItemId === row.tenantOrderItemId
  })
  if (index == -1) {
    return
  }
  items.value[index] = row
}

const itemsColumns = [
  {
    label: 'SKU',
    name: 'sku',
    visible: true,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true,
    input: props.editable
  },
  {
    label: 'Title',
    name: 'title',
    visible: true,
    input: props.editable
  },
  {
    label: 'MPN',
    name: 'mpn',
    visible: true
  },
  {
    label: 'Variants',
    name: 'variant',
    visible: true
  },
  {
    label: 'Quantity',
    name: 'quantity',
    visible: true,
    input: props.editable,
    change: updateItemRow
  },
  {
    label: 'Dispatched',
    name: 'quantityShipped',
    visible: true,
    input: props.editable,
    change: updateItemRow
  },
  {
    label: 'Price',
    name: 'price',
    visible: true,
    input: props.editable,
    change: updateItemRow
  },
  {
    label: 'VAT (%)',
    name: 'vatPercent',
    visible: true,
    input: props.editable,
    change: updateItemRow
  },
  {
    label: 'Subtotal',
    name: 'linePrice',
    visible: true,
    number: true
  },
  {
    label: 'VAT',
    name: 'lineVat',
    visible: true,
    number: true
  },
  {
    label: 'Total',
    name: 'lineTotal',
    visible: true,
    number: true
  }
]

const columnFunctions = {
  quantity: {
    input: props.editable,
    change: updateItemRow
  },
  quantityShipped: {
    input: props.editable,
    change: updateItemRow
  },
  price: {
    input: props.editable,
    change: updateItemRow
  },
  vatPercent: {
    input: props.editable,
    change: updateItemRow
  }
}

const itemsCols = ref()

const getOrderItemColumns = () => {
  readColumnPreferences(itemsColumns)
  itemsCols.value = assignColumnFunctions(columnFunctions)
}
getOrderItemColumns()

const updateColumns = (cols) => {
  itemsCols.value = cols
}

const props = defineProps({
  initialItems: {
    type: Array,
    default() {
      return []
    }
  },
  dispatchItems: {
    type: Array,
    default() {
      return []
    }
  },
  updateItems: {
    type: Function,
    default() {
      return
    }
  },
  searchLists: {
    type: Object,
    default() {
      return {}
    }
  },
  updateSearchList: {
    type: Function,
    default() {
      return
    }
  },
  tenantChannelId: {
    type: String,
    default: ''
  },
  vatNumber: {
    type: String,
    default: null
  },
  editable: {
    type: Boolean,
    default: false
  },
  priceProfile: {
    type: Object,
    default() {
      return {}
    }
  }
})

const items = toRef(props, 'initialItems')
const visibleRows = computed(() => {
  let formattedItems = []
  items.value.forEach((item) => {
    let linePrice = getLinePrice(item)
    let lineVat = getLineVat({ ...item, linePrice })
    let lineTotal = getLineTotal({ ...item, linePrice, lineVat })
    formattedItems.push({
      ...item,
      linePrice,
      lineVat,
      lineTotal
    })
  })
  return formattedItems
})

const getLinePrice = (item) => {
  if (!item.quantity || !item.price) {
    return 0.0
  }

  let price = item.price || 0.0
  let quantity = item.quantity || 0

  let linePrice = parseInt(quantity) * price
  return linePrice.toFixed(2)
}

const getLineVat = (item) => {
  if (props.vatNumber == null) {
    return null
  }
  let linePrice = item.linePrice * 100
  let vat = item.vatPercent * 1000

  let lineVAT = (linePrice * vat) / 100000

  return lineVAT.toFixed(2)
}

const getLineTotal = (item) => {
  let total = (item.linePrice * 100 + item.lineVat * 100) / 100

  return total.toFixed(2)
}

const local = ref({
  newLineConfig: false
})

// toRaw ensures we use the original props value, and this isn't updated
// by reactivity
const INITIAL_ITEMS = [...toRaw(props.initialItems)]

/*
 *****************      VALIDATION PROVIDER
 */
const _UNIQUE_STATE_ID = ref(uuid.v4())

// @see @/components/table/ValidationProvider
const registerFormState = inject('registerFormState')
const updateFormChanges = inject('updateFormChanges')

registerFormState(_UNIQUE_STATE_ID, 'order_items')

watch(items.value, (newValue) => {
  let changesMade = !_.isEqual(toRaw(newValue), INITIAL_ITEMS)
  updateFormChanges(_UNIQUE_STATE_ID, 'order_items', changesMade)
})

/*
 *****************      MODALS
 */
// Default Column Settings
const modalCols = [
  {
    label: 'SKU',
    name: 'sku',
    filter: true,
    visible: true,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true
  },
  {
    label: 'Brand',
    name: 'brandName',
    filter: true,
    visible: true
  },
  {
    label: 'Title',
    name: 'title',
    filter: true,
    visible: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    filter: true,
    visible: true
  },
  {
    label: 'GTIN',
    name: 'gtin',
    filter: true,
    visible: true
  },
  {
    label: 'ASIN',
    name: 'asin',
    filter: true,
    visible: true
  }
]

const rows = ref([...props.initialItems])
const listName = ref('ORDER_ITEMS_SKUS')

const getRows = async (args) => {
  let response = await api.get('/skus', args)
  rows.value = response.data.skus
  totalCount.value = parseInt(response.data.count)
}

/*
 *****************      GRID
 */
// Default Column Settings
const cols = ref([
  { id: 'orderItemHeader1', label: 'SKU', name: 'sku', visible: true },
  { id: 'orderItemHeader2', label: 'Title', name: 'title', visible: true },
  {
    id: 'orderItemHeader3',
    label: 'Quantity ordered ',
    name: 'quantity',
    input: true,
    inputType: 'number',
    visible: true
  },
  {
    id: 'orderItemHeader4',
    label: 'Quantity dispatched ',
    name: 'quantityShipped',
    input: true,
    inputType: 'number',
    visible: true
  },
  {
    id: 'orderItemHeader5',
    label: 'Price',
    name: 'price',
    input: true,
    inputType: 'number',
    visible: true
  },
  {
    id: 'orderItemHeader6',
    label: 'VAT',
    name: 'vatPercent',
    input: true,
    inputType: 'number',
    visible: true
  },
  { id: 'orderItemHeader7', label: 'Total', name: 'sku', visible: true }
])

const displaySelected = ref(true)
const defaultSort = ref({ brandName: 1 })
const totalCount = ref(0)
const selectedSkus = ref([])

const displaySkuOptions = (row) => {
  let variantString = row.variant1

  if (variantString == null && row.variant2 != null) {
    variantString = row.variant2
  } else if (row.variant2 != null) {
    variantString += ', ' + row.variant2
  }

  return `${row.sku} ${row.title || ''} ${variantString || ''}`
}

const rowsToShow = computed(() => {
  let rows = items.value.map((item) => {
    if (item.doNotShow != 1) {
      let dispatchRows = props.dispatchItems.filter(
        (dispatch) => dispatch.sku == item.sku
      )
      item.quantityShipped = 0
      for (let index in dispatchRows) {
        let dispatchRow = dispatchRows[index]
        item.quantityShipped += dispatchRow.quantity
      }
      return item
    }
  })

  // Last object is not an item, but an order overview
  // so don't return it
  // rows.pop()
  return rows
})

const orderOverview = computed(() => {
  if (items.value.length > 0) {
    return items.value[items.value.length - 1]
  }
  return false
})

const goToSku = (row) => {
  router.push('/sku/' + row.tenantSkuId)
}

const setSku = (event, listName, row) => {
  let displayValue = event.target.value
  let option = document.querySelector(
    '#' + listName + " option[value='" + displayValue + "']"
  )
  if (!option) {
    return
  }

  let tenantSkuId = option.dataset.value
  let item = _.find(props.searchLists[listName], {
    tenantSkuId: parseInt(tenantSkuId)
  })

  row.title = item.title
  row.sku = item.sku
  row.tenantSkuId = item.tenantSkuId
  row.vatPercent = item.vatRate
}

const searchSkus = async ($event, listName) => {
  let searchValue = $event.target.value
  if (searchValue !== '') {
    getSkus(searchValue, listName)
  }
}

const getSkus = async (searchValue, listName) => {
  let args = {
    search: 1,
    filter: {
      searchTerm: searchValue
    }
  }
  let response = await api.get('/skus', args)
  props.updateSearchList(listName, response.data.skus)
}

const cancelRemoveLine = (index) => {
  items.value[index]['delete'] = null
}

const removeLine = (row, index) => {
  if (!row.tenantOrderItemId) {
    items.value.splice(index, 1)
    return
  }

  items.value[index]['delete'] = 1
}

const addLine = (row) => {
  items.value.push(row)
  props.updateItems(items.value)
}

/*
  HANLDERS FOR ORDER ITEM TAB
*/

const newLineHasFocus = ref()
const focusNewRow = (event) => {
  newLineHasFocus.value = true
}
const blurNewRow = (event) => {
  newLineHasFocus.value = false
  setTimeout(() => {
    if (newLineHasFocus.value != true) submitRow()
  }, 50)
}

const calcNewTotal = () => {
  local.value.newLineConfig.total = getLinePrice(local.value.newLineConfig)
}

const newOrderItem = async () => {
  let line = {
    sku: '',
    title: '',
    quantity: 0,
    quantityShipped: 0,
    price: 0.0,
    vatPercent: 0.0,
    total: 0.0
  }
  addLine(line)
  closeAddSkuModal()
}

const isRowDefault = () => {
  if (local.value.newLineConfig == false) return true
  for (const [key, value] of Object.entries(local.value.newLineConfig)) {
    if (typeof value == 'string' && value != '') {
      return false
    }
  }
  return true
}

const submitRow = () => {
  if (isRowDefault()) {
    local.value.newLineConfig = false
    return false
  }

  let item = local.value.newLineConfig
  let sku = {
    tenantSkuId: '',
    sku: item.sku,
    title: item.title,
    tier1Price: item.price,
    vatRate: item.vatPercent,
    linePrice: getLinePrice(item),
    doNotShow: 0
  }

  addLine(sku)

  local.value.newLineConfig = false
  return true
}

const submitRowAndAddNew = () => {
  let response = submitRow()
  if (response) {
    newOrderItem()
  } else {
    newLineHasFocus.value = false
  }
}

const colsToShow = computed(() => {
  if (props.vatNumber != null) {
    return itemsCols.value
  }
  return itemsCols.value.filter((col) => col.label != 'VAT')
})

/**
 *
 * ADD SKU MODAL
 *
 */

const addSkuRows = ref([])
const addSkuCols = ref([
  {
    label: 'SKU',
    name: 'sku',
    filter: true,
    visible: true
  },
  {
    label: 'Supplier SKU',
    name: 'supplierSku',
    visible: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    filter: true,
    visible: true
  },
  {
    label: 'Brand',
    name: 'brandName',
    filter: true,
    visible: true
  },
  {
    label: 'Title',
    name: 'title',
    filter: true,
    visible: true
  },
  {
    label: 'Variants',
    name: 'variantValues',
    visible: true
  },
  {
    label: 'In stock',
    name: 'onHand',
    visible: true
  }
])
const addSkuModal = ref(null)
const totalAddSkuCount = ref()
const addSkuModalGrid = ref(null)
const defaultAddSkuSort = ref({ brandName: 1 })

const openAddSkuModal = async () => {
  if (!props.priceProfile) {
    return
  }
  await getAddSkuRows({
    sort: defaultAddSkuSort.value,
    filter: { tenantPricingId: props.priceProfile.tenantPricingId }
  })
  nextTick(addSkuModal.value.show())
}

const resetOrderItemsIndex = () => {
  let length = items.value.length
  let index = 0
  let counter = 0
  for (index; index < length; index++) {
    if (!items.value[index].doNotShow) {
      items.value[index].index = counter
      counter++
    }
  }
}

const saveAddSkuModal = () => {
  let index = 0
  let length = addSkuModalGrid.value.selected.length
  let formattedSkus = []

  resetOrderItemsIndex()

  let counter = 0
  for (index; index < length; index++) {
    let selectedSku = addSkuModalGrid.value.selected[index]

    if (!selectedSku) {
      continue
    }

    let tenantSkuId = selectedSku.tenantSkuId

    if (addSkuModalGrid.value.selected.includes(tenantSkuId)) {
      continue
    }

    let formatted = {
      tenantSkuId: selectedSku.tenantSkuId,
      sku: selectedSku.sku,
      brandName: selectedSku.brandName,
      title: selectedSku.title,
      gtin: selectedSku.gtin || null,
      mpn: selectedSku.mpn,
      hsCode: selectedSku.hsCode || null,
      quantity: 0,
      quantityShipped: 0,
      index: items.value.length + counter,
      price: selectedSku.price || null,
      vatPercent: getVatRate(selectedSku.vatRate)
    }
    counter++
    formattedSkus.push(formatted)
  }

  items.value = items.value.concat(formattedSkus)
  props.updateItems(items.value)
}

const getVatRate = (vatRate) => {
  switch (vatRate) {
    case '-1.00':
      return vat.get(props.priceProfile.country)
    case null:
      return null
    default:
      return vatRate
  }
}

const closeAddSkuModal = () => {
  addSkuModalGrid.value.resetFilters()
  addSkuModal.value.hide()
}

/**
 * This is the array of sku ids within the Order
 */

const getAddSkuRows = async (args = {}) => {
  args.order = 1

  let response = await api.get('/skus', args)
  addSkuRows.value = response.data.skus
  totalAddSkuCount.value = parseInt(response.data.count)
}

defineExpose({ updateRows })
</script>
<style lang="scss" scoped>
.btn-delete {
  visibility: hidden;
  background-color: transparent;
  border: none;
  height: 100%;
  width: 20%;
  justify-self: center;
}
tr:hover td .btn-delete {
  visibility: visible;
}
</style>
