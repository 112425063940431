export default function (data, valueField, displayField) {
  let formatted = []
  let index = 0
  let length = data.length

  for (index; index < length; index++) {
    let item = data[index]
    formatted.push({
      value: item[valueField],
      label: item[displayField]
    })
  }

  return formatted
}
