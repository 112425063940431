<template>
  <Alert
    v-for="alert in alerts"
    id="dispatches-number"
    :key="alert.tenantAlertId"
    :alert="alert"
    @dismiss-alert="dismissAlert"
  />
</template>

<script setup>
import { ref, inject, nextTick } from 'vue'
import { dayjs } from '@/dayjs'

import Alert from '@/components/content/widgets/Alert'
import useApi from '@/components/useApi'
import { useRouter } from 'vue-router'
const api = useApi()
const router = useRouter()
const redrawVueMasonry = inject('redrawVueMasonry')
const createToast = inject('createToast')

const alerts = ref(null)

const getData = async (args = {}) => {
  await getAlerts()
  emit('done')
}

const dismissAlert = async (alert) => {
  alert.dismiss = true
  let response = await api.put(
    `/alerts/${alert.tenantAlertId}`,
    { alert },
    undefined,
    { validateStatus: 500 }
  )

  if (response.status != 200) {
    alert.dismiss = false
    createToast('error', 'Error dismissing alert')
  }

  nextTick(() => {
    redrawVueMasonry()
  })
}

const getAlerts = async () => {
  let response = await api.get('/alerts/visible', {})
  alerts.value = response.data.alerts
}

getData()
const emit = defineEmits(['done'])
</script>

<style scoped lang="scss">
.masonry-container {
  margin: 1rem;
  width: 100%;
}

.spacer {
  width: 0.5rem;
}

.dispatch-order-widget {
  height: 10rem;
  width: 10rem;
  .number-data {
    height: 60%;
    font-size: 4rem;
    font-weight: 600;
  }

  .text-title {
    height: 40%;
    text-align: center;
  }
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
