<template>
  <ValidationProvider
    v-if="loaded"
    ref="validation"
    v-slot="{ errors, changes }"
  >
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: { disable: !changes || saving || errors },
        2: { show: local.archivedAt == null },
        3: { show: local.archivedAt != null }
      }"
    ></ActionBar>

    <TableTabs ref="tabManager" v-slot="{ activeTab }" :tabs="tabs">
      <TableContainer v-if="activeTab == 'Product'">
        <TablePanel :cols="2" :width="4">
          <template #1>
            <TableForm>
              <!-- TITLE -->
              <TableRow
                v-model="local.title"
                label="Title"
                type="text"
              ></TableRow>
              <!-- ./TITLE -->

              <!-- NEW BRAND RADIO -->
              <TableRow type="custom">
                <div class="d-flex">
                  <div class="form-check radio-input me-3">
                    <input
                      id="brandOptionRadio1"
                      class="form-check-input me-2"
                      type="radio"
                      name="brandRadio"
                      :checked="newBrandOption == 0"
                      @click="newBrandOption = 0"
                    />
                    <label class="form-check-label" for="brandOptionRadio1">
                      Existing brand
                    </label>
                  </div>
                  <div class="form-check radio-input">
                    <input
                      id="brandOptionRadio2"
                      class="form-check-input me-2"
                      type="radio"
                      name="brandRadio"
                      :checked="newBrandOption == 1"
                      @click="newBrandOption = 1"
                    />
                    <label class="form-check-label" for="brandOptionRadio2">
                      Create new brand
                    </label>
                  </div>
                </div>
              </TableRow>
              <!-- NEW BRAND RADIO -->

              <!-- BRAND -->
              <TableRow
                v-if="newBrandOption == 0 && local.tenantBrandId"
                v-model="local.brandName"
                label="Brand"
                type="text"
                :link="`/brands/${local.tenantBrandId}`"
                :read-only="!editable"
              ></TableRow>
              <TableRow
                v-else-if="newBrandOption == 0"
                v-model="local.tenantBrandId"
                label="Brand"
                type="select"
                :options="searchLists.brand"
                display="name"
                :reduce="(brand) => brand.tenantBrandId"
                @search="searchBrands"
              >
              </TableRow>
              <TableRow
                v-else
                v-model="local.brandName"
                label="Brand"
                type="text"
                :read-only="!editable"
              ></TableRow>
              <!-- BRAND -->

              <!-- CE MARK -->
              <TableRow type="custom" label="CE Mark required">
                <input v-model="local.ceMarkRequired" type="checkbox" />
              </TableRow>
              <!-- ./CE MARK -->

              <!-- HS CODE -->
              <TableRow
                v-model="local.hsCode"
                label="HS Code"
                type="text"
              ></TableRow>
              <!-- ./HS CODE -->

              <!-- COUNTRY OF ORIGIN -->
              <TableRow
                v-model="local.origin"
                label="Main Country"
                :options="countries"
                type="select"
              ></TableRow>
              <!-- ./COUNTRY OF ORIGIN -->
            </TableForm>
          </template>
          <template #2>
            <TableForm :style="'height: 50%'">
              <!-- SHORT DESCRIPTION -->
              <TableRow
                v-model="local.enDescriptionSmall"
                label="Short Description"
                type="textarea"
                :style="'height: 20%'"
              ></TableRow>
              <!-- ./SHORT DESCRIPTION -->

              <!-- DESCRIPTION -->
              <TableRow
                v-model="local.enDescriptionLong"
                label="Description"
                type="textarea"
                :style="'height: 40%'"
              ></TableRow>
              <!-- ./DESCRIPTION -->

              <!-- INTERNAL NOTES -->
              <TableRow
                v-model="local.internalNotes"
                label="Internal Notes"
                type="textarea"
                :style="'height: 40%'"
              ></TableRow>
              <!-- ./INTERNAL NOTES -->
            </TableForm>
          </template>
        </TablePanel>

        <TablePanel :cols="1" :width="2">
          <template #1>
            <TableForm>
              <!-- BULLET POINT 1 -->
              <TableRow
                v-model="local.enBullet1"
                label="Bullet point 1"
                type="text"
              ></TableRow>
              <!-- ./BULLET POINT 1 -->

              <!-- BULLET POINT 2 -->
              <TableRow
                v-model="local.enBullet2"
                label="Bullet point 2"
                type="text"
              ></TableRow>
              <!-- ./BULLET POINT 2 -->

              <!-- BULLET POINT 3 -->
              <TableRow
                v-model="local.enBullet3"
                label="Bullet point 3"
                type="text"
              ></TableRow>
              <!-- ./BULLET POINT 3 -->

              <!-- BULLET POINT 4 -->
              <TableRow
                v-model="local.enBullet4"
                label="Bullet point 4"
                type="text"
              ></TableRow>
              <!-- ./BULLET POINT 4 -->

              <!-- BULLET POINT 5 -->
              <TableRow
                v-model="local.enBullet5"
                label="Bullet point 5"
                type="text"
              ></TableRow>
              <!-- ./BULLET POINT 5 -->
            </TableForm>
          </template>
          <template #2>
            <TableForm> </TableForm>
          </template>
        </TablePanel>

        <TablePanel :cols="2" :width="2" header="Check-in settings">
          <template #1>
            <TableForm>
              <TableRow label="Handle Expiry Dates" type="custom">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  :checked="productSettings.handleExpiry"
                  @click="productSettings.handleExpiry ^= true"
                  @change="handleInput"
                />
              </TableRow>
              <TableRow label="Handle Batch Codes" type="custom">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  :checked="productSettings.handleBatch"
                  @click="productSettings.handleBatch ^= true"
                  @change="handleInput"
                />
              </TableRow>
              <TableRow label="Handle Serial Numbers" type="custom">
                <input
                  type="checkbox"
                  style="cursor: pointer"
                  :checked="productSettings.handleSerial"
                  @click="productSettings.handleSerial ^= true"
                  @change="handleInput"
                />
              </TableRow>
            </TableForm>
          </template>
          <template #2>
            <TableForm>
              <TableRow
                v-model="productSettings.saleOrder"
                label="Sale Order"
                type="select"
                :options="[
                  {
                    label: 'Check-In Date (First), Expiry Date (Second)',
                    value: 0
                  },
                  {
                    label: 'Expiry Date (First), Check-In Date (Second)',
                    value: 1
                  }
                ]"
                :reduce="(order) => order.value"
                display="label"
              ></TableRow>
            </TableForm>
          </template>
        </TablePanel>

        <TablePanel :cols="2" :width="4" header="Dimensions">
          <template #1>
            <TableForm>
              <!-- PRODUCT DIMENSIONS -->
              <RowContainer heading="Product Dimensions">
                <!-- WIDTH -->
                <TableRow
                  v-model="local.productWidth"
                  label="Width"
                  :indent-level="1"
                  type="text"
                ></TableRow>
                <!-- ./WIDTH -->

                <!-- HEIGHT -->
                <TableRow
                  v-model="local.productHeight"
                  label="Height"
                  :indent-level="1"
                  type="text"
                ></TableRow>
                <!-- ./HEIGHT -->

                <!-- DEPTH -->
                <TableRow
                  v-model="local.productDepth"
                  label="Depth"
                  :indent-level="1"
                  type="text"
                ></TableRow>
                <!-- ./DEPTH -->

                <!-- DIMENSION MEASUREMENT UNIT -->
                <TableRow
                  v-model="local.productDimensionUnit"
                  label="Dimension Measurement Unit"
                  :options="[
                    { value: 'IN', label: 'Inches' },
                    { value: 'MM', label: 'MM' },
                    { value: 'CM', label: 'CM' },
                    { value: 'M', label: 'M' }
                  ]"
                  :indent-level="1"
                  type="select"
                ></TableRow>
                <!-- ./DIMENSION MEASUREMENT UNIT -->

                <!-- WEIGHT -->
                <TableRow
                  v-model="local.productWeight"
                  label="Weight"
                  :indent-level="1"
                  type="text"
                ></TableRow>
                <!-- ./WEIGHT -->

                <!-- WEIGHT MEASUREMENT UNIT -->
                <TableRow
                  v-model="local.productWeightUnit"
                  label="Weight Measurement Unit"
                  :options="[
                    { value: 'G', label: 'G' },
                    { value: 'KG', label: 'KG' },
                    { value: 'LB', label: 'LB' }
                  ]"
                  :indent-level="1"
                  type="select"
                ></TableRow>
                <!-- ./WEIGHT MEASUREMENT UNIT-->
              </RowContainer>
              <!-- ./ PRODUCT DIMENSIONS -->
            </TableForm>
          </template>
          <template #2>
            <TableForm>
              <!-- SHIPPING DIMENSIONS -->
              <RowContainer heading="Shipping Dimensions">
                <!-- WIDTH -->
                <TableRow
                  v-model="local.shippingWidth"
                  label="Width"
                  :indent-level="1"
                  type="text"
                ></TableRow>
                <!-- ./WIDTH -->

                <!-- HEIGHT -->
                <TableRow
                  v-model="local.shippingHeight"
                  label="Height"
                  :indent-level="1"
                  type="text"
                ></TableRow>
                <!-- ./HEIGHT -->

                <!-- DEPTH -->
                <TableRow
                  v-model="local.shippingDepth"
                  label="Depth"
                  :indent-level="1"
                  type="text"
                ></TableRow>
                <!-- ./DEPTH -->

                <!-- DIMENSION MEASUREMENT UNIT -->
                <TableRow
                  v-model="local.shippingDimensionUnit"
                  label="Dimension Measurement Unit"
                  :options="[
                    { value: 'IN', label: 'Inches' },
                    { value: 'MM', label: 'MM' },
                    { value: 'CM', label: 'CM' },
                    { value: 'M', label: 'M' }
                  ]"
                  :indent-level="1"
                  type="select"
                ></TableRow>
                <!-- ./DIMENSION MEASUREMENT UNIT -->

                <!-- WEIGHT MEASUREMENT UNIT -->
                <TableRow
                  v-model="local.shippingWeightUnit"
                  label="Weight Measurement Unit"
                  :options="[
                    { value: 'G', label: 'G' },
                    { value: 'KG', label: 'KG' },
                    { value: 'LB', label: 'LB' }
                  ]"
                  :indent-level="1"
                  type="select"
                ></TableRow>
                <!-- ./WEIGHT MEASUREMENT UNIT-->
              </RowContainer>
              <!-- ./ SHIPPING DIMENSIONS -->
            </TableForm>
          </template>
        </TablePanel>
      </TableContainer>

      <TableContainer v-if="activeTab === 'SKUs'">
        <TablePanel>
          <template #1>
            <TableGrid
              v-if="activeTab === 'SKUs'"
              ref="skusGrid"
              :rows="skus"
              :cols="skusCols"
              :total-count="skus.length"
              id-key="tenantSkuId"
              scrollable
            ></TableGrid>
            <div class="row">
              <div class="col-12 d-flex justify-content-center tbl-cell-btn">
                <button type="button" class="btn" @click="gotoSkuForm">
                  <font-awesome-icon
                    :icon="['fas', 'circle-plus']"
                  ></font-awesome-icon
                  ><b>Add SKU</b>
                </button>
              </div>
            </div>
          </template>
        </TablePanel>
      </TableContainer>

      <TableContainer v-if="activeTab === 'Files'">
        <TableFileUpload
          :id="productId"
          :files="local.files"
          table="tenantProduct"
          :update-files-list="updateFilesList"
        ></TableFileUpload>
      </TableContainer>

      <TableContainer v-if="activeTab === 'Tax Rates'">
        <ProductTaxRates
          :key="productTaxRatesKey"
          v-model="local.taxRates"
          @save="update('productTaxRates')"
        >
        </ProductTaxRates>
      </TableContainer>

      <TableContainer v-if="activeTab == 'Categories / Variants'">
        <TablePanel :cols="1" :width="4" header="Categories">
          <template #1>
            <TableForm>
              <TableRow
                v-for="(category, index) in local.categories"
                :key="index"
                v-model="category.tenantCategoryId"
                label="Category"
                type="select"
                display="en"
                :reduce="(value) => value.tenantCategoryId"
                :options="categoryOptions"
                @search="searchCategory"
                @update:model-value="selectCategory($event, category)"
              ></TableRow>
              <TableRow type="custom">
                <div class="col-12 col-md-2 offset-md-5 tbl-cell-btn">
                  <button type="button" class="btn" @click="addCategory">
                    <font-awesome-icon
                      :icon="['fas', 'circle-plus']"
                    ></font-awesome-icon>
                    <b>Add Category</b>
                  </button>
                </div>
              </TableRow>
            </TableForm>
          </template>
        </TablePanel>
        <TablePanel :cols="1" :width="2" header="Variants">
          <template #1>
            <TableForm>
              <!-- VARIANTS -->

              <template
                v-for="(variantName, index) in local.variantNames"
                :key="index"
              >
                <TableRow
                  v-model="local.variantNames[index].en"
                  type="variant"
                  :label="'Variant ' + index"
                  :value="local.variantNames[index].en"
                  :variant-value="variantName"
                  :options="searchLists[variantName.listName]"
                  @update:model-value="
                    setVariantNameId($event, index, variantName.listName)
                  "
                ></TableRow>

                <!-- ADD VARIANT -->
                <TableRow
                  v-if="index === local.variantNames.length - 1"
                  type="custom"
                >
                  <div class="col-12 col-md-2 offset-md-5 tbl-cell-btn">
                    <button type="button" class="btn" @click="addVariant">
                      <font-awesome-icon
                        :icon="['fas', 'circle-plus']"
                      ></font-awesome-icon>
                      <b>Add Variant</b>
                    </button>
                  </div>
                </TableRow>
              </template>

              <!-- ./VARIANTS -->
            </TableForm>
          </template>
        </TablePanel>
        <TablePanel :cols="1" :width="2" header="Attributes">
          <template #1>
            <TableForm>
              <TableRow
                v-for="(attributeValue, index) in local.attributeValues"
                :key="index"
                v-model="local.attributeValues[index].displayValue"
                type="attribute"
                :label="attributeValue.en"
                :variant-value="attributeValue"
                :options="searchLists[attributeValue.listName]"
                :read-only="!editable"
                @update:model-value="
                  setAttributeValueId($event, index, attributeValue.listName)
                "
              ></TableRow>
            </TableForm>
          </template>
        </TablePanel>
      </TableContainer>
    </TableTabs>

    <DeleteConfirmModal
      ref="deleteModal"
      :show-warning="true"
      @confirm="archiveProduct"
    ></DeleteConfirmModal>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    ></ConfirmLeaveFormModal>
  </ValidationProvider>
</template>

<script setup>
import {
  ref,
  onUpdated,
  watch,
  inject,
  computed,
  onMounted,
  nextTick,
  toRaw,
  watchEffect
} from 'vue'
const _ = require('lodash')

import { useRouter, useRoute } from 'vue-router'
const router = useRouter()
const route = useRoute()
import useApi from '@/components/useApi'
const api = useApi()

import useCountries from '@/composables/selectData/useCountries.js'
const { getCountriesForSelect } = useCountries()
const countries = getCountriesForSelect()

const { formatDateTime, UTC_OFFSET } = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())
const uuid = require('uuid')
const skusGrid = ref(null)

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: [Number, String],
    default: ''
  }
})

const editable = computed(() => {
  return local.value.archivedAt == null
})

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

// Object that contains all the data
const local = ref({ variantNames: [], attributeNames: [], categories: [] })
const productSettings = ref(null)
const INITIAL_PRODUCT_SETTINGS = ref({})
const _UNIQUE_PRODUCT_SETTINGS_STATE_ID = ref(uuid.v4())

const registerProductSettings = () => {
  validation.value.registerFormState(
    _UNIQUE_PRODUCT_SETTINGS_STATE_ID,
    'Product_Settings'
  )
}

const selectedTab = ref()
const tabManager = ref(null)
watchEffect(() => {
  if (tabManager.value) {
    selectedTab.value = tabManager.value.tab
  }
})

watch(selectedTab, async (current, previous) => {
  switch (current) {
    case 'SKUs':
      nextTick(() => skusGrid.value.updateRows())
  }
})

watch(
  () => productSettings.value,
  (newValue) => {
    if (newValue != null && validation.value == null) {
      return
    }
    let changesMade = !_.isEqual(
      toRaw(newValue),
      INITIAL_PRODUCT_SETTINGS.value
    )
    validation.value.updateFormChanges(
      _UNIQUE_PRODUCT_SETTINGS_STATE_ID,
      'Product_Settings',
      changesMade
    )
  },
  { deep: true }
)

const getCategory = async (id) => {
  if (!id) {
    return
  }
  let response = await api.get('/categories/' + id)
  let categoryData = handleCategoryResponse(response.data)

  return categoryData
}

const handleCategoryResponse = (data) => {
  let categoryData = data.category
  let attributeNames = data.attributeNames

  let joinedAttributeNames = local.value.attributeNames.concat(attributeNames)

  local.value.attributeNames = [
    ...new Set(joinedAttributeNames.map((o) => JSON.stringify(o)))
  ].map((s) => JSON.parse(s))

  local.value.attributeValues = local.value.attributeValues.concat(
    setAttributeValues(attributeNames, [])
  )
  return categoryData
}

const setAttributeValues = (attributeNames, attributeValues) => {
  let index = 0
  let length = attributeNames.length

  let formattedAttributeValues = []

  for (index; index < length; index++) {
    let attributeName = attributeNames[index]
    let m2mCategoryAttributeNameId = attributeName.m2mCategoryAttributeNameId
    let value = _.find(attributeValues, { m2mCategoryAttributeNameId }) || {
      m2mCategoryAttributeNameId: m2mCategoryAttributeNameId,
      tenantAttributeNameId: attributeName.tenantAttributeNameId
    }
    let formattedValue = JSON.parse(JSON.stringify(value))
    let listName = 'attribute_' + value.m2mCategoryAttributeNameId
    formattedValue.en = attributeName.en
    formattedValue.tenantAttributeNameId = attributeName.tenantAttributeNameId
    formattedValue.listName = listName
    formattedValue.displayValue = value.en || null
    formattedAttributeValues.push(formattedValue)
    searchLists.value.listName = [value]
  }

  return formattedAttributeValues
}

const setAttributeValueId = (value, index, listName) => {
  let option = document.querySelector(
    '#' + listName + ' option[value="' + value + '"]'
  )
  let attributeValue = local.value.attributeValues[index]

  if (option) {
    // selected a created variant
    let tenantAttributeValueId = option.dataset.value
    attributeValue.tenantAttributeValueId = tenantAttributeValueId
    attributeValue.displayValue = value
    local.value.attributeValue.value[index] = attributeValue
    return
  }

  attributeValue.create = 1
  attributeValue.tenantAttributeValueId = null
  attributeValue.displayValue = value
  local.value.attributeValues[index] = attributeValue
}

// Displays content once it's fetched from the DB
const loaded = ref(false)

const productId = ref(false)
const newBrandOption = ref(0)

/**
 * Both used to rerender the product tax rates component
 * so that the tax rates dipslayed are the most up to date
 */
const productTaxRatesKey = ref(0)
const rerenderTaxes = () => {
  productTaxRatesKey.value = productTaxRatesKey.value + 1
}
/**
 * Formats data returned from api
 */
const handleResponse = (data) => {
  let product = data.product
  skus.value = data.skus || [{}]
  variantValues.value = data.variantValues
  let files = data.files || []
  let attributeNames = data.attributeNames || []
  let attributeValues = data.attributeValues || []
  let formattedAttributeValues = setAttributeValues(
    attributeNames,
    attributeValues
  )

  addVariantValuesToSkus(data.skuVariantValues)

  if (searchLists.value.brand.length === 0) {
    searchLists.value.brand = data.brands || []
  }

  let variantNames = formatVariantNames(data.variantNames)
  let categories = data.categories || []

  let enDescriptionLong = product.enDescriptionLong
  let brandObject = searchLists.value.brand.find(
    (brand) => brand.tenantBrandId == product.tenantBrandId
  )

  local.value = {
    tenantBrandId: product.tenantBrandId,
    brandName: brandObject?.name,
    tenantCategoryId1: product.tenantCategoryId1,
    tenantCategoryId2: product.tenantCategoryId2,
    tenantProductId: product.tenantProductId,
    title: product.title,
    hsCode: product.hsCode,
    origin: product.origin,
    internalNotes: product.internalNotes,
    enDescriptionLong: enDescriptionLong,
    enDescriptionSmall: product.enDescriptionSmall,
    enBullet1: product.enBullet1,
    enBullet2: product.enBullet2,
    enBullet3: product.enBullet3,
    enBullet4: product.enBullet4,
    enBullet5: product.enBullet5,
    productWidth: product.productWidth,
    productHeight: product.productHeight,
    productDepth: product.productDepth,
    ceMarkRequired: product.ceMarkRequired,
    productDimensionUnit: product.productDimensionUnit,
    productWeight: product.productWeight,
    productWeightUnit: product.productWeightUnit,
    shippingWidth: product.shippingWidth,
    shippingHeight: product.shippingHeight,
    shippingDepth: product.shippingDepth,
    shippingDimensionUnit: product.shippingDimensionUnit,
    shippingWeight: product.shippingWeight,
    shippingWeightUnit: product.shippingWeightUnit,
    taxRates: data.taxRates,
    variantNames: variantNames,
    attributeNames: attributeNames,
    attributeValues: formattedAttributeValues,
    categories: categories,
    files: files,
    createdAt: formatDateTime(product.createdAt, utcOffset.value),
    createdBy: product.createdBy,
    updatedAt: formatDateTime(product.updatedAt, utcOffset.value),
    updatedBy: product.updatedBy,
    archivedAt: formatDateTime(product.archivedAt, utcOffset.value),
    archivedBy: product.archivedBy
  }

  productSettings.value = {
    handleExpiry: product.handleExpiry,
    handleBatch: product.handleBatch,
    handleSerial: product.handleSerial,
    saleOrder: product.saleOrder
  }
  INITIAL_PRODUCT_SETTINGS.value = {
    handleExpiry: product.handleExpiry,
    handleBatch: product.handleBatch,
    handleSerial: product.handleSerial,
    saleOrder: product.saleOrder
  }

  rerenderTaxes()

  addVariantCols()

  if (variantNames.length === 0) {
    addVariant()
  }
}

const formatVariantNames = (variantNames) => {
  let index = 0
  let length = variantNames.length

  for (index; index < length; index++) {
    let variantName = variantNames[index]
    let id = variantName.m2mProductVariantNameId
    let listName = 'variantName_' + id
    searchLists.value[listName] = [variantName]
    variantNames[index].listName = listName
    variantNames[index].displayValue = variantName.en
  }

  return variantNames
}

/*
 *****************      VALIDATION PROVIDER
 */

// Template ref to validation provider component
const validation = ref(null)

/*
 *****************      MODALS
 */
import DeleteConfirmModal from '@/components/modals/DeleteConfirmModal'
import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'

let brandModal = ref(null)
let deleteModal = ref(null)
let confirmLeaveModal = ref(null)
let confirmModalNext = ref(null)

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
  confirmModalNext()
}

const closeConfirmModal = () => {
  confirmModalNext = null
  confirmLeaveModal.value.hide()
}

const openDeleteModal = () => {
  deleteModal.value.show()
}

/**
 *****************      TABLE FORM
 */
import TableForm from '@/components/table/form/TableForm'
import RowContainer from '@/components/table/form/RowContainer'
import TableRow from '@/components/table/form/TableRow'
import Skus from '@/components/content/skus/Skus'
import TableFileUpload from '@/components/table/form/TableFileUpload'
import ProductTaxRates from '@/components/content/products/ProductTaxRates'

const tabs = ref([
  'Product',
  'SKUs',
  'Files',
  'Tax Rates',
  'Categories / Variants'
])
const searchLists = ref({ brand: [], category: [] })
const newBrand = ref(false)
const displayVariant = ref(1)
const variantValues = ref([])
const variantNames = ref([])
const saving = ref(false)
const skus = ref([])
const productFiles = ref([])
const skusCols = ref([
  {
    label: 'SKU',
    name: 'sku',
    visible: true,
    onClick: {
      route: '/sku/',
      id: 'tenantSkuId'
    },
    primary: true
  },
  {
    label: 'MPN',
    name: 'mpn',
    visible: true
  },
  {
    label: 'GTIN',
    name: 'gtin',
    visible: true
  },
  {
    label: 'ASIN',
    name: 'asin',
    visible: true
  },
  {
    label: 'Last updated',
    name: 'lastUpdated',
    visible: true
  }
])

const updateFilesList = (files = []) => {
  local.value.files = files
}

const setVariantNameId = (value, index, listName) => {
  let option = document.querySelector(
    '#' + listName + ' option[value="' + value + '"]'
  )
  let variantName = local.value.variantNames[index]

  if (option) {
    // selected a created variant;
    let tenantVariantNameId = option.dataset.value
    variantName.tenantVariantNameId = parseInt(tenantVariantNameId)
    variantName.displayValue = value
    local.value.variantNames[index] = variantName
    return
  }

  variantName.create = 1
  variantName.displayValue = value
  local.value.variantNames[index] = variantName
}

const setAttributeNameId = (value, index, listName) => {
  let option = document.querySelector(
    '#' + listName + ' option[value="' + value + '"]'
  )
  let attributeName = local.value.attributeNames[index]

  if (option) {
    // selected a created attribute;
    let tenantAttributeNameId = option.dataset.value
    attributeName.tenantAttributeNameId = parseInt(tenantAttributeNameId)
    attributeName.displayValue = value
    local.value.attributeNames[index] = attributeName
    return
  }

  attributeName.create = 1
  attributeName.displayValue = value
  local.value.attributeNames[index] = attributeName
}

const getAllVariantNames = async (listName) => {
  if (variantNames.value.length > 0) {
    searchLists.value[listName] = variantNames.value
    return
  }

  let response = await api.get('/variantNames/')
  searchLists.value[listName] = response.data.variantNames
  variantNames.value = response.data.variantNames
}

const loadVariantValues = async () => {
  local.value.variantNames.forEach((variantName) => {
    getAllVariantNames(variantName.listName)
  })
}

const removeVariant = (row, index) => {
  if (!row.m2mProductVariantNameId) {
    local.value.variantNames.splice(index, 1)
    return
  }

  local.value.variantNames[index].delete = 1
}

const undoRemove = (index) => {
  delete local.value.variantNames[index].delete
}

const createRandomString = () => {
  var result = []
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  var charactersLength = characters.length
  var length = 10
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join('')
}

const addVariant = async () => {
  let listName = createRandomString()
  let variantName = { listName: listName, productId: productId.value }
  searchLists.value.listName = []
  local.value.variantNames.push(variantName)
  await getAllVariantNames(listName)
}

const archiveProduct = async () => {
  let response = await api.put(
    '/products/' + productId.value,
    {
      archive: 1,
      tenantProductId: productId.value
    },
    saving
  )
  let product = response.data.product
  updateProduct(product)
}

const updateProduct = (product) => {
  product.createdAt = formatDateTime(product.createdAt, utcOffset.value)
  product.approvedAt = formatDateTime(product.approvedAt, utcOffset.value)
  product.updatedAt = formatDateTime(product.updatedAt, utcOffset.value)
  product.archivedAt = formatDateTime(product.archivedAt, utcOffset.value)
  local.value = Object.assign({}, local.value, product)
}

const setNewBrand = () => {
  local.value.tenantBraidId = null
  local.value.brandName = null
  newBrand.value = !newBrand.value
}

const updateOrigin = (value) => {
  local.value.origin = value
}

const addCategory = () => {
  local.value.categories.push({
    tenantCategoryId: null,
    en: ''
  })
}

const getProductId = () => {
  productId.value = false
  let pathId = route.params.id
  if (pathId && parseInt(pathId) > 0) {
    productId.value = route.params.id
  }
}

const searchBrands = (search, loading) => {
  if (search !== '') {
    getBrands(search, loading)
  }
}

const searchVariantNames = (search, loading, name) => {
  if (search !== '') {
    getVariants(search, loading, name)
  }
}

const searchCategory = (search, loading) => {
  if (search !== '') {
    getCategories(search, loading, 'category')
  }
}

const selectCategory = async (id, changedCategory) => {
  let category = await getCategory(id.value)

  if (!category) {
    return
  }

  let index = local.value.categories.findIndex((cat) => cat == changedCategory)
  local.value.categories[index] = category
}

const categoryOptions = computed(() => {
  let categories = local.value.categories.filter(
    (cat) => cat.tenantCategoryId != null
  )
  let list = searchLists.value.category.concat(categories)

  return list.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) => t.tenantCategoryId === value.tenantCategoryId)
  )
})
const getBrands = _.debounce(async (searchValue, loading) => {
  if (!searchValue) {
    return
  }

  loading(true)
  let response = await api.get('/brands/', { filter: { name: searchValue } })
  searchLists.value.brand = response.data.brands
  loading(false)
}, 250)

const getCategories = _.debounce(async (searchValue, loading, listName) => {
  if (!searchValue) {
    return
  }

  loading(true)
  let response = await api.get('/categories/', {
    filter: { en: searchValue },
    sort: { en: 1 }
  })
  searchLists.value[listName] = response.data.categories.map((category) => {
    return { en: category.en, tenantCategoryId: category.tenantCategoryId }
  })
  loading(false)
}, 250)

const getVariants = _.debounce(async (searchValue, loading, listName) => {
  if (!searchValue) {
    return
  }

  loading(true)
  let response = await api.get('/variantNames/', {
    filter: { variant: searchValue }
  })
  searchLists.value.listName = response.data.variantNames
  loading(false)
}, 250)

const getProduct = async () => {
  if (!productId.value || productId.value === 'new') {
    addVariant()
    return
  }

  let response = await api.get('/products/' + productId.value)
  handleResponse(response.data)
}

const addVariantValuesToSkus = (skuVariantValues) => {
  let index = 0
  let length = skuVariantValues.length

  for (index; index < length; index++) {
    let skuVariantValue = skuVariantValues[index]

    if (!skuVariantValue) {
      continue
    }

    let tenantSkuId = skuVariantValue.tenantSkuId
    let display = skuVariantValue.en

    let sku = _.find(skus.value, { tenantSkuId })
    sku[`m2mVariant_+${skuVariantValue.m2mProductVariantNameId}`] = display
  }
}

const addVariantCols = () => {
  let index = 0
  let length = local.value.variantNames.length

  for (index; index < length; index++) {
    let variantName = local.value.variantNames[index]
    addVariantCol(variantName, index + 1)
  }
}

const addVariantCol = (variant, pos) => {
  let displayName = variant.en
  skusCols.value.splice(pos, 0, {
    label: displayName,
    name: 'm2mVariant_' + variant.m2mProductVariantNameId
  })
}

const update = async () => {
  if (
    newBrandOption.value == 1 &&
    local.value.brandName != null &&
    local.value.brandName != ''
  ) {
    let response = await api.post('/brands', {
      brand: { name: local.value.brandName }
    })
    let tenantBrandId = response.data.insertedId

    local.value = {
      ...local.value,
      tenantBrandId
    }
  }

  local.value = {
    ...local.value,
    ...productSettings.value
  }

  if (!productId.value) {
    insert()
    return
  }

  let response = await api.put(
    '/products/' + productId.value,
    { product: local.value },
    saving
  )
  handleResponse(response.data)
  validation.value.save()
}

const insert = async () => {
  let response = await api.post('/products/', { product: local.value }, saving)
  productId.value = String(response.data.insertedId)
  router.replace('/products/' + productId.value)
  handleResponse(response.data)
  setTitle()
}

const gotoProduct = (response) => {
  router.push('/products/' + response.insertedId)
  getProductId()
  getProduct()
}

const gotoProducts = () => {
  router.push('/products/')
}

const gotoSkuForm = () => {
  let path = '/sku/new'
  path += '?productId=' + productId.value
  router.push(path)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  },
  {
    id: 2,
    label: 'Archive',
    click: openDeleteModal,
    icon: ['fas', 'trash']
  },
  {
    id: 3,
    label: 'Unarchive',
    click: () => {},
    icon: []
  }
])

const setTitle = async () => {
  let title = local.value.title

  if (title) {
    document.getElementById('pagetitle').innerHTML = title
    document.title = title
  } else {
    document.getElementById('pagetitle').innerHTML = 'Create product'
    document.title = 'Create product'
  }
}

const getData = async () => {
  try {
    progressBarStart()
    getProductId()
    await getProduct()
    await loadVariantValues()
    setTitle()
    loaded.value = true
    nextTick(() => registerProductSettings())

    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

getData()

const routeRefreshOnMounted = ref(false)
onUpdated(() => {
  if (
    routeRefreshOnMounted.value &&
    router.currentRoute.value.params.id == 'new'
  ) {
    router.go()
  }
  routeRefreshOnMounted.value = true
})
</script>
