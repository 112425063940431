<template>
  <TableGrid
    ref="crmGrid"
    :cols="cols"
    :rows="rows"
    :total-count="rows.length"
    scrollable
  ></TableGrid>

  <div v-if="rows.length == 0" class="row">
    <div class="col-12 d-flex justify-content-center">
      <p class="m-0">No logs have been added.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 d-flex justify-content-center tbl-cell-btn">
      <button type="button" class="btn" @click="openCrmModal()">
        <font-awesome-icon :icon="['fas', 'circle-plus']"></font-awesome-icon
        ><b>Add log</b>
      </button>
    </div>
  </div>

  <BraidModal ref="crmLogModal" @confirm="addCrmLog">
    <template #header>Add CRM Log</template>
    <template #body>
      <TableForm>
        <TableRow
          v-model="selectedRow.notes"
          label="Notes"
          type="textarea"
        ></TableRow>
        <TableRow
          v-model="selectedRow.createdAt"
          label="Created"
          type="text"
          read-only
        ></TableRow>
        <!-- <textarea
          v-model.trim="selectedRow.notes"
          class="form-control"
          rows="8"
        ></textarea> -->
      </TableForm>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue'
import useApi from '@/components/useApi'
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'

const props = defineProps({
  id: { type: String, default: '' },
  crmLog: {
    type: Array,
    default() {
      return []
    }
  },
  editable: { type: Boolean, default: false }
})

const crmGrid = ref(null)

const api = useApi()

const modal = ref(null)

const saving = ref(false)

const local = ref({
  notes: ''
})

const rows = ref(props.crmLog)
const selectedRow = ref({})

const cols = ref([
  {
    label: 'Note',
    name: 'notes',
    multiLine: true,
    visible: true
  },
  {
    label: 'Created',
    name: 'createdAt',
    width: '200px',
    dateTime: true,
    visible: true
  },
  {
    label: 'Created by',
    name: 'createdBy',
    width: '200px',
    visible: true
  }
])

const schema = ref({
  supplierId: { type: 'string' },
  notes: { type: 'string' }
})

const show = () => {
  crmLogModal.value.show()
}

const { formatDateTime, UTC_OFFSET } = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

const crmLogModal = ref(null)
const openCrmModal = () => {
  selectedRow.value = {
    notes: '',
    createdAt: formatDateTime(
      new Date(Date.now()).toISOString(),
      utcOffset.value
    )
  }
  show()
}

const addCrmLog = () => {
  let data = JSON.parse(JSON.stringify(selectedRow.value))
  if (data.tenantSupplierLogId) {
    let index = rows.value.findIndex(
      (row) => (row.tenantSupplierLogId = data.tenantSupplierLogId)
    )
    rows.value[index] = data
  } else {
    rows.value.push(data)
  }
}

const getLogsForSave = () => {
  return rows.value
}

onMounted(() => {
  crmGrid.value.updateRows()
})

defineExpose({ getLogsForSave })
</script>
