<template>
  <div class="scrollable-wrapper">
    <div class="scrollable row m-0 spacer">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableContainer'
}
</script>

<style scoped>
.spacer {
  margin: 0 0.5rem;
  max-width: 100%;
}
</style>
