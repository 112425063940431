<template>
  <ActionBar :buttons="buttons" />

  <!-- <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Channel"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences> -->

  <TableGrid
    ref="grid"
    :cols="columns"
    :rows="rows"
    :total-count="totalCount"
    :filter-function="getRows"
    :default-sort="defaultSort"
    id-key="tenantChannelId"
    scrollable
  ></TableGrid>
</template>

<script setup>
import { onMounted, ref, inject, computed } from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const router = useRouter()
const api = useApi()

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const getRows = async (args = {}) => {
  loading()
  try {
    progressBarStart()
    let response = await api.get('/channels', args)

    if (!response) {
      return
    }
    rows.value = response.data.channels
    totalCount.value = parseInt(response.data.count)
    loaded()
    progressBarDone()
  } catch (e) {
    progressBarFail()
  }
}

/*
 *****************      GRID
 */

const newChannel = () => {
  router.push('/channels/new')
}

const rows = ref([])
const totalCount = ref(0)
const defaultSort = ref({ name: 1 })

const authAmazon = () => {
  window.location.replace(
    'https://sellercentral.amazon.co.uk/apps/authorize/consent?application_id=amzn1.sellerapps.app.c49cd680-997f-4ece-932b-db6ab6434879&version=beta&state=stateexample'
  )
}

const authEbay = () => {
  window.location.replace(
    'https://auth.ebay.com/oauth2/authorize?client_id=FLOWMOTI-Braid-PRD-f8463c110-94f4f0e2&response_type=code&redirect_uri=FLOWMOTION_LTD-FLOWMOTI-Braid--usueqinlp&scope=https://api.ebay.com/oauth/api_scope https://api.ebay.com/oauth/api_scope/sell.marketing.readonly https://api.ebay.com/oauth/api_scope/sell.marketing https://api.ebay.com/oauth/api_scope/sell.inventory.readonly https://api.ebay.com/oauth/api_scope/sell.inventory https://api.ebay.com/oauth/api_scope/sell.account.readonly https://api.ebay.com/oauth/api_scope/sell.account https://api.ebay.com/oauth/api_scope/sell.fulfillment.readonly https://api.ebay.com/oauth/api_scope/sell.fulfillment https://api.ebay.com/oauth/api_scope/sell.analytics.readonly https://api.ebay.com/oauth/api_scope/sell.finances https://api.ebay.com/oauth/api_scope/sell.payment.dispute https://api.ebay.com/oauth/api_scope/commerce.identity.readonly&prompt=login'
  )
}

const authShopify = () => {
  window.location.replace(
    'https://braid-test-test.myshopify.com/admin/oauth/authorize?client_id=9322ecb9331a3f70d169b340ba6c1876&scope=read_orders,write_orders&redirect_uri=https://localhost:8080/shopify-token&state=state-braid-1&grant_options[]=per_user'
  )
}

const authParcel2go = () => {
  window.location.replace(
    'https://www.parcel2go.com/auth/connect/authorize?client_id=example&redirect_uri=http%3A%2F%2Fwww.example.com%2Freturn&response_type=token&scope=openid+public-api'
  )
}

const authEbayTest = async () => {
  await api.post('/channels', {
    newEbay: 1,
    code: 'v^1.1#i^1#I^3#p^3#r^1#f^0#t^Ul41XzM6RkJFQzExOTk2MEM5QjRGNzE2NzM0NDdDOUI5QjRDMjFfMV8xI0VeMjYw'
  })
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'

// Default Column Settings
const cols = [
  {
    label: 'Name',
    name: 'name',
    filter: true,
    visible: true,
    onClick: {
      route: '/channels/',
      id: 'tenantChannelId'
    },
    primary: true
  },
  {
    label: 'Marketplace',
    name: 'marketplace',
    filter: true,
    visible: true
  },
  {
    label: 'Last sync',
    name: 'lastSuccessfulSync',
    dateTime: true,
    visible: true
  },
  {
    label: 'Created at',
    name: 'createdAt',
    dateTime: true,
    visible: true
  },
  {
    label: 'CreatedBy',
    name: 'createdBy',
    visible: true
  }
]

//Template reference to the Column Preferences component
const preferences = ref()

const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)
const columns = ref()

const localStorageName = ref('braidChannels')

/**
 * Checks to see if we can read column preferences from local storage. If not
 * it writes the cols array to local storage (as a default)
 */
const readColumnPreferences = () => {
  let columnSettings = localStorage[localStorageName.value]

  if (!columnSettings) {
    localStorage[localStorageName.value] = JSON.stringify(cols)
    columnSettings = localStorage[localStorageName.value]
  }

  columns.value = JSON.parse(columnSettings)
}

readColumnPreferences()

const updateColumns = (cols) => {
  columns.value = cols
}

/**
 * ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'
const buttons = [
  {
    id: 0,
    label: 'Add new',
    click: newChannel,
    icon: ['fas', 'circle-plus']
  },
  {
    id: 1,
    label: 'Column Settings',
    click: show,
    icon: ['fas', 'gear']
  }
]

onMounted(async () => {
  await getRows({ sort: defaultSort.value })
})
</script>
