<template>
  <span :id="`table-grid-filter-${column.name}`" class="table-grid-filter">
    <v-select
      v-if="multiple"
      id="vueSelect"
      ref="input"
      v-model="multiSelect"
      multiple
      class="table-select-input form-control"
      :options="options"
      :reduce="(option) => option.key"
      append-to-body
      label="value"
      @update:model-value="updateMultiSelect($event)"
    ></v-select>
    <span v-if="!filterApplied && !multiple" class="input-filter">
      <v-select
        v-if="column.displayValues"
        id="vueSelect"
        ref="input"
        class="table-select-input form-control"
        :options="options"
        :reduce="(option) => option.key"
        label="value"
        @update:model-value="updateSelect($event)"
      ></v-select>
      <v-flat-pickr
        v-else-if="column.dateTime"
        id="vueFlatPickr"
        ref="input"
        v-model="datetime"
        :config="config"
        class="form-control"
      ></v-flat-pickr>
      <div v-else-if="column.number" class="input-group">
        <div class="input-group-prepend d-xl-block">
          <button
            class="btn btn-outline-secondary dropdown-toggle"
            type="button"
            data-bs-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {{ number[0] }}
          </button>
          <div class="dropdown-menu">
            <a class="dropdown-item" @click="updateNumberDropdown('=')">{{
              '='
            }}</a>
            <a class="dropdown-item" @click="updateNumberDropdown('>')">{{
              '>'
            }}</a>
            <a class="dropdown-item" @click="updateNumberDropdown('<')">{{
              '<'
            }}</a>
            <a class="dropdown-item" @click="updateNumberDropdown('>=')">{{
              '>='
            }}</a>
            <a class="dropdown-item" @click="updateNumberDropdown('<=')">{{
              '<='
            }}</a>
          </div>
        </div>
        <input
          ref="input"
          type="text"
          class="form-control"
          :value="modelValue[1]"
          @input="updateNumberInput($event.target.value)"
          @keyup.enter="applyNumberFilter"
        />
      </div>
      <input
        v-else
        ref="input"
        type="text"
        :value="modelValue"
        class="form-control"
        @input="$emit('update:modelValue', $event.target.value.trim())"
        @blur="applyFilter"
        @keyup.enter="blurInput"
        @keyup="setUserEdited"
      />
      <button
        v-if="showRemove"
        class="btn-close btn-remove-filter"
        aria-label="Remove filter"
        @mousedown="removeFilter"
      ></button>
    </span>

    <span
      v-else-if="filterApplied && !multiple"
      class="badge filter-badge"
      @mousedown="editFilter"
    >
      <span v-if="column.displayValues" class="filter-badge-text">
        {{ column.displayValues[modelValue] }}
      </span>
      <span v-else-if="column.number" class="filter-badge-text">
        {{ number[0] }}
        {{ number[1] }}
      </span>
      <span v-else class="filter-badge-text">
        {{ modelValue }}
      </span>
      <button
        class="btn-close"
        aria-label="Remove filter"
        @mousedown="removeFilter"
      ></button
    ></span>
  </span>
</template>

<script setup>
import { ref, watch, onActivated, nextTick, computed } from 'vue'
import { useRouter } from 'vue-router'
const router = useRouter()

const props = defineProps({
  modelValue: {
    type: [Array, String],
    default: ''
  },
  column: {
    type: Object,
    required: false,
    default() {
      return {}
    }
  },
  multiple: {
    type: Boolean,
    default: false
  },
  updateUrl: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(['filter', 'update:modelValue', 'focus'])

/*
  V-SELECT
*/
const updateSelect = (data) => {
  emit('update:modelValue', data)
  if (props.modelValue == previousFilter.value) {
    setTimeout(() => {
      updateURLQueryParams()
      emit('filter')
    }, 50)
    previousFilter.value = props.modelValue
    editFilterFlag.value = false
  }
}

/*
  V-SELECT MULTIPLE
*/
const multiSelect = ref(props.modelValue === '' ? null : props.modelValue)
const updateMultiSelect = (data) => {
  if (data.length == 0) {
    removeFilter()
    return
  }
  emit('update:modelValue', data)
  setTimeout(() => {
    updateURLQueryParams()
    emit('filter')
  }, 100)
}

/*
  NUMBER
*/
const number = ref(['=', ''])
const ariaExpanded = ref(false)

const updateNumberInput = (data) => {
  number.value[1] = data
  emit('update:modelValue', number.value)
  previousFilter.value = number.value
}

const updateNumberDropdown = (operator) => {
  number.value[0] = operator
  ariaExpanded.value = false
  if (number.value[1] == '') {
    return
  }
  emit('update:modelValue', number.value)
  applyNumberFilter()
}

const applyNumberFilter = () => {
  if (ariaExpanded.value == true) {
    return
  }
  updateURLQueryParams()
  emit('filter')
  editFilterFlag.value = false
}

/*
  DATETIME
*/
const datetime = ref(null)

watch(datetime, (current, previous) => {
  if (current == null) {
    return
  }
  emit('update:modelValue', current.split(' to '))
  setTimeout(() => {
    updateURLQueryParams()
    emit('filter')
  }, 100)
})
const config = ref({
  mode: 'range'
})

/*
  FILTER HANDLERS
*/
const showRemove = computed(() => {
  return props.modelValue != '' && editFilterFlag.value
})

const previousFilter = ref('')
const editFilterFlag = ref(true)
const userEdited = ref(false)

const removeFilter = () => {
  if (
    previousFilter.value == '' ||
    previousFilter.value == null ||
    previousFilter.value == ['=', '']
  ) {
    return
  }
  datetime.value = null
  number.value = ['=', '']
  multiSelect.value = null

  emit('update:modelValue', '')

  setTimeout(() => {
    updateURLQueryParams()
    emit('filter')
  }, 50)
  previousFilter.value = ''
  editFilterFlag.value = true
}

const setUserEdited = () => {
  userEdited.value = true
}

const blurInput = () => {
  input.value.blur()
}

const applyFilter = _.debounce(() => {
  if (props.modelValue == previousFilter.value) {
    if (props.modelValue != '') {
      editFilterFlag.value = false
    }
    return
  }
  if (
    props.modelValue.length === 0 ||
    props.modelValue === '' ||
    !props.modelValue
  ) {
    removeFilter()
    return
  }
  editFilterFlag.value = false
  updateURLQueryParams()
  emit('filter')
  previousFilter.value = props.modelValue
}, 250)

import { useColumnFilters } from '@/composables/useColumnFilters'
const { getColumnFiltersNoReplace } = useColumnFilters()
const updateURLQueryParams = () => {
  if (!props.updateUrl) {
    return
  }
  let query = router.currentRoute.value.query

  let filter = getColumnFiltersNoReplace(query)
  let sort = query?.sort ? query.sort : ''

  let key = props.column.name
  let value = props.modelValue
  if (props.column.number || props.multiple || props.column.dateTime) {
    key += '*'
    if (value != '') {
      value = value.join(',')
    }
  }

  if (value == '' && filter != '') {
    delete filter[key]
  } else if (filter != '') {
    filter[key] = value
  } else {
    filter = {}
    filter[key] = value
  }

  let filterQuery = ''
  query = {}
  let index = 0
  for (const [key, value] of Object.entries(filter)) {
    if (index != 0) {
      filterQuery += '^'
    }
    filterQuery += key + '=' + value
    index++
  }

  if (filterQuery != '') {
    query = { filter: filterQuery }
  }
  if (sort != '') {
    query.sort = sort
  }

  router.replace({
    query: query
  })
}

const options = computed(() => {
  if (!props.column.displayValues) {
    return []
  }
  return Object.entries(props.column.displayValues).map(([key, value]) => ({
    key,
    value
  }))
})

const editFilter = () => {
  editFilterFlag.value = true
  setTimeout(focusInput, 100)
}

const filterApplied = computed(() => {
  return (
    props.modelValue != '' &&
    props.modelValue != undefined &&
    !editFilterFlag.value
  )
})

const setEditFilterFlag = () => {
  editFilterFlag.value = false
}

const setPreviousFilter = (data) => {
  previousFilter.value = data
}
const input = ref(null)
const focusInput = () => {
  if (!props.column.displayValues) {
    input.value.focus()
    return
  } else {
    input.value.open = true
  }
  removeFilter()
}

watch(
  () => props.modelValue,
  (current, previous) => {
    if (current == '' && current != previous) {
      number.value = ['=', '']
      datetime.value = null
      multiSelect.value = null
      editFilterFlag.value = true
    }
    if (props.multiple) {
      multiSelect.value = current == '' ? null : current
    }
    if (props.column.datetime) {
      datetime.value = current
    }
    if (props.column.number) {
      number.value = current == '' ? ['=', ''] : current
    }
  }
)

onActivated(() => {
  editFilterFlag.value = true
  previousFilter.value = props.modelValue
})

defineExpose({ setEditFilterFlag, col: props.column.name, setPreviousFilter })
</script>

<style lang="scss">
.table-grid-filter {
  background-color: #ffffff;

  input {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    &::placeholder {
      color: $braid-grey-4;
    }
  }

  .input-filter {
    display: flex;
    flex-direction: row;

    .btn-remove-filter {
      margin-left: -1.8rem;
      align-self: center;
      height: 0.35em;
      width: 0.35em;
    }
  }

  .apply-filter-button {
    border: 0;
    width: 100%;
    line-height: 0.9em;
    background-color: $braid-grey-1;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    font-size: 0.8rem;
    font-weight: bold;
    text-transform: uppercase;
  }

  .filter-badge {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    background-color: $braid-grey-2;
    color: #000000;

    border: 1px solid #000000;
    width: 100%;
    padding: 0.375rem 0.75rem;

    .filter-badge-text {
      font-size: 1rem;
      line-height: initial;
      overflow: hidden;
      text-overflow: ellipsis;
      padding-right: 0.5rem;
    }
  }

  .table-select-input {
    width: 100%;
    height: 100%;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 0;
    &::placeholder {
      color: $braid-grey-4;
    }
    & input {
      border: none;
    }
  }

  .vs__selected-options {
    .vs__selected + .vs__search {
      height: 0;
    }
  }
  #vueFlatPickr {
    background-color: white;
  }

  .btn-outline-secondary {
    border: 1px solid rgba(0, 0, 0, 0.2);
    &:focus {
      box-shadow: none;
    }
  }

  .input-group-prepend {
    height: 100%;
    display: none;
    & .btn-outline-secondary {
      &:active,
      &:hover,
      &.show {
        background-color: white;
        border-color: rgba(0, 0, 0, 0.2);
        color: #212529;
      }
      &.show:focus {
        box-shadow: none;
      }
    }
  }
  .input-group-prepend + .form-control {
    border-bottom-left-radius: 3px !important;
    border-top-left-radius: 3px !important;
  }
  @media (min-width: 992px) {
    .input-group-prepend + .form-control {
      border-left: none;
      border-bottom-left-radius: 0 !important;
      border-top-left-radius: 0 !important;
    }
  }
}
</style>

<style lang="scss">
.table-grid-filter {
  .vs__dropdown-toggle {
    padding: 3px 0;
  }

  .dropdown-toggle {
    padding-top: 0;
    padding-bottom: 0;
    height: 100%;
  }
}
</style>
