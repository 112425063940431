<template>
  <ActionBar ref="actionbar" :buttons="buttons"></ActionBar>
  <OrderItemReport v-if="id == 'order-item-report'"></OrderItemReport>
  <RestockReport v-else-if="id == 'restock-report'"></RestockReport>
  <EziReturnReport v-else-if="id == 'ezi-return-report'"></EziReturnReport>
  <StockLevelWarning
    v-else-if="id == 'inventory-alert-level-report'"
  ></StockLevelWarning>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import OrderItemReport from '@/components/content/reports/OrderItemReport'
import RestockReport from '@/components/content/reports/RestockReport'
import StockLevelWarning from '@/components/content/reports/StockLevelWarning'
import EziReturnReport from '@/components/content/reports/EziReturnReport'

import ActionBar from '@/components/content/ActionBar'

const actionbar = ref(false)

const router = useRouter()
const props = defineProps({
  id: {
    type: String,
    default: ''
  }
})

const buttons = ref([
  {
    id: 0,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  }
])

onMounted(() => {})
</script>

<style lang="scss"></style>
