<template>
  <BraidModal ref="modal" @confirm="confirm">
    <template #header> Are you sure? </template>
    <template #body>
      This will delete this record and you will be unable to view it in future.
      <br />
      <div v-if="showWarning" class="alert alert-danger" role="alert">
        <i class="bi bi-exclamation-triangle"></i
        ><span class="ps-3">
          This action will delete any records associated with this</span
        >
      </div>
    </template>
  </BraidModal>
</template>

<script setup>
import { ref } from 'vue'

const modal = ref(null)

const props = defineProps({
  showWarning: { type: Boolean, default: true }
})

const emit = defineEmits(['confirm'])

const confirm = () => {
  emit('confirm')
}

const show = () => {
  modal.value.show()
}

defineExpose({ show })
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1,
h2 {
  font-weight: normal;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
table {
  height: 600px;
  display: block;
  overflow-x: auto;
  overflow-y: auto;
  white-space: nowrap;
}
</style>
