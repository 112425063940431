<template>
  <ValidationProvider
    v-if="loaded"
    ref="validation"
    v-slot="{ errors, changes }"
  >
    <ActionBar
      :buttons="buttons"
      :conditions="{ 0: { disable: errors || !changes } }"
    />

    <TableTabs ref="tabManager" v-slot="{ activeTab }" :tabs="tabs">
      <TableContainer
        v-show="activeTab === 'Categories'"
        :id="'form-' + activeTab"
      >
        <TablePanel :cols="1" :width="4">
          <template #1>
            <TableForm>
              <TableRow
                v-model="local.parentCategoryId"
                label="Parent Category"
                type="select"
                display="en"
                :options="categories"
                :reduce="(value) => value.tenantCategoryId"
                @search="searchCategories"
                @update:model-value="updateParentCategory"
              ></TableRow>

              <TableRow
                v-model="local.primaryAmazonCode"
                label="Primary Amazon Code"
                type="text"
              ></TableRow>

              <TableRow
                v-model="local.secondaryAmazonCode"
                label="Secondary Amazon Code"
                type="text"
              ></TableRow>

              <TableRow
                v-model="local.primaryEbayCode"
                label="Primary Ebay Code"
                type="text"
              ></TableRow>

              <TableRow
                v-model="local.secondaryEbayCode"
                label="Secondary Ebay Code"
                type="text"
              ></TableRow>

              <TableRow
                v-model="local.shopifyCollection"
                label="Shopify Collection"
                type="text"
              ></TableRow>
            </TableForm>
          </template>
        </TablePanel>

        <TablePanel :width="2" header="Product Attributes">
          <template #1>
            <TableForm>
              <template
                v-for="(attributeName, index) in local.productAttributeNames"
                :key="index"
              >
                <TableRow
                  v-model="local.productAttributeNames[index].en"
                  type="variant"
                  label="Attribute"
                  :value="local.productAttributeNames[index].en"
                  :variant-value="attributeName"
                  :options="searchLists[attributeName.listName]"
                  @update:model-value="
                    setProductAttributeNameId(
                      $event,
                      index,
                      attributeName.listName
                    )
                  "
                ></TableRow>
              </template>
              <!-- ADD VARIANT -->
              <TableRow type="custom">
                <div class="col-12 col-md-2 offset-md-5 tbl-cell-btn">
                  <button
                    type="button"
                    class="btn"
                    @click="addProductAttribute"
                  >
                    <font-awesome-icon
                      :icon="['fas', 'circle-plus']"
                    ></font-awesome-icon>
                    <b>Add Product Attribute</b>
                  </button>
                </div>
              </TableRow>
            </TableForm>
          </template>
        </TablePanel>
        <TablePanel :width="2" header="Sku Attributes">
          <template #1>
            <TableForm>
              <template
                v-for="(attributeName, index) in local.skuAttributeNames"
                :key="index"
              >
                <TableRow
                  v-model="local.skuAttributeNames[index].en"
                  type="variant"
                  label="Attribute"
                  :value="local.skuAttributeNames[index].en"
                  :variant-value="attributeName"
                  :options="searchLists[attributeName.listName]"
                  @update:model-value="
                    setSkuAttributeNameId($event, index, attributeName.listName)
                  "
                ></TableRow>
              </template>
              <!-- ADD VARIANT -->
              <TableRow type="custom">
                <div class="col-12 col-md-2 offset-md-5 tbl-cell-btn">
                  <button type="button" class="btn" @click="addSkuAttribute">
                    <font-awesome-icon
                      :icon="['fas', 'circle-plus']"
                    ></font-awesome-icon>
                    <b>Add Sku Attribute</b>
                  </button>
                </div>
              </TableRow>
            </TableForm>
          </template>
        </TablePanel>

        <TablePanel :cols="1" :width="2">
          <template #1>
            <TableForm>
              <RowContainer heading="Category name localisations">
                <TableRow
                  v-model="local.en"
                  :indent-level="1"
                  label="English Category Name"
                  type="text"
                >
                </TableRow>

                <TableRow
                  v-model="local.de"
                  :indent-level="1"
                  label="German Category Name"
                  type="text"
                >
                </TableRow>

                <TableRow
                  v-model="local.es"
                  :indent-level="1"
                  label="Spanish Category Name"
                  type="text"
                >
                </TableRow>

                <TableRow
                  v-model="local.fr"
                  :indent-level="1"
                  label="French Category Name"
                  type="text"
                >
                </TableRow>

                <TableRow
                  v-model="local.it"
                  :indent-level="1"
                  label="Italian Category Name"
                  type="text"
                >
                </TableRow>

                <TableRow
                  v-model="local.nl"
                  :indent-level="1"
                  label="Dutch Category Name"
                  type="text"
                >
                </TableRow>

                <TableRow
                  v-model="local.pl"
                  :indent-level="1"
                  label="Polish Category Name"
                  type="text"
                >
                </TableRow>

                <TableRow
                  v-model="local.se"
                  :indent-level="1"
                  label="Swedish Category Name"
                  type="text"
                >
                </TableRow>
              </RowContainer>
            </TableForm>
          </template>
        </TablePanel>
        <TablePanel :width="2" header="Category Tree">
          <template #1>
            <TableGrid
              v-if="subcategoryRows.length > 0"
              ref="grid"
              :cols="subcategoryCols"
              :rows="subcategoryRows"
              :total-count="subcategoryRows.length"
            ></TableGrid>
            <TableForm v-else>
              <tr>
                <td colspan="2">
                  There are no sub-categories for this category.
                </td>
              </tr>
            </TableForm>
          </template>
        </TablePanel>
      </TableContainer>
      <TableContainer v-if="activeTab === 'Products'" :id="'form-' + activeTab">
        <TablePanel>
          <template #1>
            <TableGrid
              ref="grid"
              :cols="productCols"
              :rows="productRows"
              :total-count="totalProducts"
              :filter-function="getProductRows"
              id-key="tenantProductId"
            ></TableGrid>
          </template>
        </TablePanel>
      </TableContainer>
    </TableTabs>

    <ConfirmLeaveFormModal
      ref="confirmLeaveModal"
      @cancel="closeConfirmModal"
      @confirm="gotoNextPage"
    ></ConfirmLeaveFormModal>
  </ValidationProvider>
</template>

<script setup>
import {
  ref,
  onMounted,
  onUpdated,
  watch,
  watchEffect,
  nextTick,
  inject
} from 'vue'

import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
import TableForm from '@/components/table/form/TableForm'
import TableRow from '@/components/table/form/TableRow'
import RowContainer from '@/components/table/form/RowContainer'

const router = useRouter()
const api = useApi()
const { getTableFilters } = useColumnFilters(router)

/**
 * @see router/index.js
 * Passed in via vue router, ID of the category being displayed in the form
 */
const props = defineProps({
  id: {
    type: String,
    default: ''
  }
})

/**
 * @see @/components/template/TheProgressBar.vue
 */
const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const { formatDateTime, UTC_OFFSET } = require('@/dayjs')
const utcOffset = ref(UTC_OFFSET())

// Object that contains all the data
const local = ref({
  productAttributeNames: [],
  skuAttributeNames: []
})

// Displays content once it's fetched from the DB
const loaded = ref(false)

const categories = ref([])
const categoryId = ref(null)

const tabs = ref(['Categories', 'Products'])
const searchLists = ref({})
const attributeNames = ref([])

// Tried to do this by exposing active tab in the TableTab
// but couldn't get it to work, so I've done it this hacky way
// instead
const selectedTab = ref()
const tabManager = ref(null)
watchEffect(() => {
  if (tabManager.value) {
    selectedTab.value = tabManager.value.tab
  }
})

/**
 * Formats data returned from api
 */
const handleResponse = (data) => {
  let category = data.category
  let subCategories = data.subCategories
  let parentCategoryData = data.parentCategory

  let attributeNames = data.attributeNames
  let index = 0
  let length = attributeNames.length
  for (index; index < length; index++) {
    let attributeName = attributeNames[index]
    let id = attributeName.m2mCategoryAttributeNameId
    let listName = 'attributeName_' + id
    searchLists.value[listName] = [attributeName]
    attributeNames[index].listName = listName
    attributeNames[index].displayValue = attributeName.en
  }

  let productAttributeNames = attributeNames.filter(
    (attr) => attr.type == 'product'
  )
  let skuAttributeNames = attributeNames.filter((attr) => attr.type == 'sku')

  local.value = {
    tenantCategoryId: category.tenantCategoryId,
    parentCategoryId: category.parentCategoryId,
    primaryAmazonCode: category.primaryAmazonCode,
    secondaryAmazonCode: category.secondaryAmazonCode,
    primaryEbayCode: category.primaryEbayCode,
    secondaryEbayCode: category.secondaryEbayCode,
    shopifyCollection: category.shopifyCollection,
    en: category.en,
    de: category.de,
    es: category.es,
    fr: category.fr,
    it: category.it,
    nl: category.nl,
    pl: category.pl,
    se: category.se,
    createdBy: category.createdBy,
    updatedBy: category.updatedBy,
    createdAt: formatDateTime(category.createdAt, utcOffset.value),
    updatedAt: formatDateTime(category.updatedAt, utcOffset.value),
    productAttributeNames: productAttributeNames,
    skuAttributeNames: skuAttributeNames
  }
  subcategoryRows.value = subCategories
  parentCategory.value = parentCategoryData

  if (Object.keys(parentCategoryData).length > 0) {
    categories.value = [parentCategoryData]
  }
}

const updateParentCategory = () => {
  let category = categories.value.find(
    (category) => category.tenantCategoryId == local.value.parentCategoryId
  )
  parentCategory.value = category
}

const subcategoryCols = ref([
  {
    label: 'Category',
    name: 'name',
    visible: true,
    onClick: {
      route: '/categories/',
      id: 'tenantCategoryId'
    }
  },
  {
    label: 'Depth',
    name: 'index',
    visible: true
  }
])
const subcategoryRows = ref([])
const parentCategory = ref({})

/*
 *****************      VALIDATION PROVIDER
 */

// Template ref to validation provider component
const validation = ref(null)

/*
 *****************      MODALS
 */

import ConfirmLeaveFormModal from '@/components/modals/global/ConfirmLeaveFormModal'

const confirmLeaveModal = ref()
const confirmModalNext = ref()

const openConfirmModal = () => {
  confirmLeaveModal.value.show()
}

const closeConfirmModal = () => {
  confirmModalNext.value = null
  confirmLeaveModal.value.hide()
}

const gotoNextPage = () => {
  confirmLeaveModal.value.hide()
  confirmModalNext()
}

const searchCategories = (search, loading) => {
  if (search !== '') {
    getCategories(search, loading)
  }
}

const getCategories = _.debounce(async (searchValue, loading) => {
  if (!searchValue) {
    return
  }
  let response = await api.get('/categories/', {
    filter: { en: searchValue }
  })
  let categoryList = response.data.categories

  // This make sure you can't add a sub-category as a parent category
  categoryList = categoryList.filter((cat) => {
    return (
      subcategoryRows.value.findIndex(
        (sub) => sub.tenantCategoryId == cat.tenantCategoryId
      ) == -1
    )
  })

  if (Object.values(parentCategory.value).length > 0) {
    categories.value = categoryList.concat([parentCategory.value])
  } else {
    categories.value = categoryList
  }
}, 250)

const getCategoryId = () => {
  if (props.id && parseInt(props.id) > 0) {
    categoryId.value = props.id
  }
}

const getCategory = async () => {
  if (!categoryId.value || categoryId.value === 'new') {
    addAttribute()
    return
  }

  let response = await api.get('/categories/' + categoryId.value)
  handleResponse(response.data)
}

const update = async () => {
  let productAttributes = local.value.productAttributeNames.map((attr) => {
    return {
      ...attr,
      type: 'product'
    }
  })
  let skuAttributes = local.value.skuAttributeNames.map((attr) => {
    return {
      ...attr,
      type: 'sku'
    }
  })
  local.value.attributeNames = productAttributes.concat(skuAttributes)
  if (!categoryId.value) {
    insert()
    return
  }

  let response = await api.put('/categories/' + categoryId.value, {
    category: local.value
  })
  handleResponse(response.data)
  validation.value.save()
}

const insert = async () => {
  let response = await api.post('/categories/', { category: local.value })
  categoryId.value = String(response.data.insertedId)
  router.replace('/categories/' + categoryId.value)
  handleResponse(response.data)
}

const setProductAttributeNameId = (value, index, listName) => {
  let option = document.querySelector(
    '#' + listName + ' option[value="' + value + '"]'
  )
  let attributeName = local.value.productAttributeNames[index]

  if (option) {
    // selected a created attribute;
    let tenantAttributeNameId = option.dataset.value
    attributeName.tenantAttributeNameId = parseInt(tenantAttributeNameId)
    attributeName.displayValue = value
    local.value.productAttributeNames[index] = attributeName
    return
  }

  attributeName.create = 1
  attributeName.displayValue = value
  local.value.productAttributeNames[index] = attributeName
}

const setSkuAttributeNameId = (value, index, listName) => {
  let option = document.querySelector(
    '#' + listName + ' option[value="' + value + '"]'
  )
  let attributeName = local.value.skuAttributeNames[index]

  if (option) {
    // selected a created attribute;
    let tenantAttributeNameId = option.dataset.value
    attributeName.tenantAttributeNameId = parseInt(tenantAttributeNameId)
    attributeName.displayValue = value
    local.value.skuAttributeNames[index] = attributeName
    return
  }

  attributeName.create = 1
  attributeName.displayValue = value
  local.value.skuAttributeNames[index] = attributeName
}

const getAllAttributeNames = async (listName) => {
  if (attributeNames.value.length > 0) {
    searchLists.value[listName] = attributeNames.value
    return
  }

  let response = await api.get('/categories/attributeNames/')
  searchLists.value[listName] = response.data.attributeNames
  attributeNames.value = response.data.attributeNames
}

const addProductAttribute = async () => {
  let listName = createRandomString()
  let attributeName = { listName: listName, categoryId: categoryId.value }
  searchLists.value.listName = []
  local.value.productAttributeNames.push(attributeName)
  await getAllAttributeNames(listName)
}

const addSkuAttribute = async () => {
  let listName = createRandomString()
  let attributeName = { listName: listName, categoryId: categoryId.value }
  searchLists.value.listName = []
  local.value.skuAttributeNames.push(attributeName)
  await getAllAttributeNames(listName)
}

const createRandomString = () => {
  var result = []
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
  var charactersLength = characters.length
  var length = 10
  for (var i = 0; i < length; i++) {
    result.push(characters.charAt(Math.floor(Math.random() * charactersLength)))
  }
  return result.join('')
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = ref([
  {
    id: 0,
    label: 'Save',
    click: update,
    icon: ['fas', 'floppy-disk']
  },
  {
    id: 1,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  }
])

const setTitle = () => {
  let pageTitle = local.value.en || 'Create Category'
  document.getElementById('pagetitle').innerHTML = pageTitle
  document.title = pageTitle
}

const getData = async () => {
  try {
    progressBarStart()
    getCategoryId()
    await getCategory()
    setTitle()
    loaded.value = true
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

getData()

const loadedProducts = ref(false)
/*
  HISTORY
*/
watch(selectedTab, async (current, previous) => {
  if (current == 'Products' && !loadedProducts.value) {
    let tableFilters = getTableFilters({ brandName: 1 })
    await getProductRows(tableFilters)
    loadedProducts.value = true
  }
})

const productCols = ref([
  {
    label: 'Title',
    name: 'title',
    filter: true,
    visible: true,
    width: 300,
    onClick: {
      route: '/products/',
      id: 'tenantProductId'
    },
    primary: true
  },
  {
    label: 'Brand',
    name: 'brandName',
    filter: true,
    visible: true
  },
  {
    label: 'Last updated',
    name: 'updatedAt',
    visible: true
  }
])
const productRows = ref([])
const totalProducts = ref([])

const getProductRows = async (args = {}) => {
  try {
    progressBarStart()
    let response = await api.get(
      '/products/by-category/' + local.value.tenantCategoryId,
      args
    )
    handleProductResponse(response.data)
    progressBarDone()
  } catch (e) {
    progressBarFail()
  }
}

const handleProductResponse = (data) => {
  let products = data.products
  products.forEach((product) => {
    product.updatedAt = formatDateTime(product.updatedAt, utcOffset.value)
  })

  totalProducts.value = data.count
  productRows.value = products
}

const routeRefreshOnMounted = ref(false)
onUpdated(() => {
  if (
    routeRefreshOnMounted.value &&
    router.currentRoute.value.params.id == 'new'
  ) {
    router.go()
  }
  routeRefreshOnMounted.value = true
})
</script>

<style lang="scss"></style>
