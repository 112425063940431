<template>
  <ActionBar :buttons="buttons" />

  <ColumnPreferences
    ref="preferences"
    :cols="cols"
    list-name="Order"
    :storage-name="localStorageName"
    @save="updateColumns"
  ></ColumnPreferences>

  <TableGrid
    ref="grid"
    :cols="columns"
    :rows="rows"
    :initial-filter="tableFilters.filter"
    :total-count="totalCount"
    :filter-function="getRows"
    :default-sort="defaultSort"
    id-key="tenantOrderId"
    :row-class="getRowClass"
    :reset-filters="resetFilters"
    scrollable
  ></TableGrid>
</template>

// This is for the keep-alive to work
<script>
export default {
  name: 'OrdersList'
}
</script>

<script setup>
import {
  ref,
  onActivated,
  watch,
  inject,
  nextTick,
  onDeactivated,
  computed
} from 'vue'
import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'
import { useColumnFilters } from '@/composables/useColumnFilters'
import { Picklist } from '@/composables/pdfs/picklist'
import { pageLoad } from '@/composables/pageLoad'
const { loading, loaded, getLoading } = pageLoad()

const pageLoading = computed(() => {
  return getLoading()
})

const _ = require('lodash')
const router = useRouter()
const api = useApi()
const { getTableFilters, toggleArchived } = useColumnFilters(router)
const deactivated = ref(false)

const props = defineProps({
  pageId: {
    type: String,
    default: ''
  },
  id: {
    type: String,
    default: ''
  }
})

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

/*
 *****************      GRID
 */

// Template reference to the TableGrid component
const grid = ref(null)

const channels = ref([])
const rows = ref([])
const totalCount = ref(0)
const defaultSort = ref({ channelOrderUpdatedAt: -1 })
const showArchived = ref(false)

const getRowClass = (row) => {
  if (row.archivedAt != null && showArchived.value == true) {
    return 'archived-row'
  }
}

const toggleShowArchived = async (value) => {
  let filter = toggleArchived()
  grid.value.setFilter(filter)
  grid.value.filterColumns()
}

const getRows = async (args = {}) => {
  loading()
  try {
    progressBarStart()
    if (!args.filter) {
      args.filter = {}
    }

    showArchived.value = args.filter.showArchived == 1

    let response = await api.get('/orders', args)
    rows.value = response.data.orders
    getChannelValues(response.data.channels)
    totalCount.value = parseInt(response.data.count)
    loaded()
    updateFilters(false)
    progressBarDone()
  } catch (e) {
    progressBarFail()
  }
}

const getChannelValues = (response = []) => {
  let index = 0
  let length = response.length
  let channelValues = {}
  channels.value = response

  for (index; index < length; index++) {
    let channel = response[index]
    channelValues[channel.tenantChannelId] = channel.name
  }

  let columnIndex = columns.value.findIndex((column) => {
    return column.name === 'tenantChannelId'
  })

  columns.value[columnIndex].displayValues = channelValues

  updateColumns(columns.value)
}

const updateTableFilters = ({ filter, sort, skip }) => {
  if (!grid.value) {
    return
  }
  appliedFilters.value = filter != '' ? filter : {}
  grid.value.setFilter(appliedFilters.value)
  grid.value.setSort(sort)
  grid.value.setPage(skip)
}

const tableFilters = getTableFilters(defaultSort.value)
getRows(tableFilters)

const resetFilters = () => {
  appliedFilters.value = {}
}

/*
 *****************      COLUMN MANAGEMENT
 */
import ColumnPreferences from '@/components/table/grid/ColumnPreferences'
// Default Column Settings
const cols = ref([
  {
    label: 'Order number',
    name: 'orderNumber',
    filter: true,
    visible: true,
    width: 150,
    onClick: {
      route: '/orders/',
      id: 'tenantOrderId'
    },
    primary: true
  },
  {
    label: 'Order ID',
    name: 'channelOrderId',
    filter: true,
    visible: true
  },
  {
    label: 'Channel',
    name: 'tenantChannelId',
    filter: true,
    multiple: true
  },
  {
    label: 'Fulfillment',
    name: 'fulfillmentChannel',
    filter: true,
    displayValues: {
      MERCHANT: 'Merchant',
      '3PL': 'Third party'
    }
  },
  {
    label: 'Status',
    name: 'status',
    filter: true,
    visible: true,
    multiple: true,
    width: 200,
    displayValues: {
      0: 'Draft',
      1: 'Unfulfilled',
      2: 'Part-fulfilled',
      3: 'Fulfilled',
      4: 'Cancelled'
    }
  },
  {
    label: 'Payment',
    name: 'paymentStatus',
    filter: true,
    width: 200,
    displayValues: {
      0: 'Awaiting payment',
      1: 'Paid',
      2: 'Failed',
      3: 'Partially refunded',
      4: 'Refunded'
    }
  },
  {
    label: 'Customer',
    name: 'customerName',
    visible: true
  },
  {
    label: 'Email',
    name: 'email'
  },
  {
    label: 'Phone',
    name: 'phone',
    filter: true
  },
  {
    label: 'Postcode',
    name: 'shippingAddrPostcode',
    filter: true,
    visible: true
  },
  {
    label: 'Price',
    name: 'totalPrice',
    number: true,
    filter: true,
    visible: true
  },
  {
    label: 'Latest dispatch date',
    name: 'dispatchDate',
    dateTime: true,
    filter: true,
    visible: true
  },
  {
    label: 'Latest delivery date',
    name: 'deliveryDate',
    dateTime: true,
    filter: true,
    visible: true
  },
  {
    label: 'Raised at',
    name: 'channelOrderCreatedAt',
    dateTime: true,
    visible: true,
    filter: true
  },
  {
    label: 'Last updated',
    name: 'channelOrderUpdatedAt',
    dateTime: true
  }
])

// Template ref to the ColumnPreferences component
const preferences = ref()

// Shows the column preference modal
const show = () => {
  preferences.value.show()
}

// Stores the user column preference (read from localstorage)
const columns = ref()

const localStorageName = ref('braidOrders')
import { columnPreferences } from '@/composables/columnPreferences'
const { readColumnPreferences } = columnPreferences(localStorageName.value)

columns.value = readColumnPreferences(cols.value)

const updateColumns = (cols) => {
  columns.value = cols
  setTimeout(() => {
    grid.value.setFilter(appliedFilters.value)
  }, 50)
}

const createPicklist = async () => {
  let itemResponse = await api.get('/orders/items-status', { status: 1 })
  let unshippedItems = itemResponse.data.items

  if (unshippedItems.length === 0) {
    return
  }

  let skus = unshippedItems.map((item) => {
    return item.sku
  })
  let skuResponse = await api.get('/skus/orders', { skus })
  let skuData = skuResponse.data.skus

  let index = 0
  let length = unshippedItems.length
  let formatted = {}

  for (index; index < length; index++) {
    let unshippedItem = unshippedItems[index]
    let sku = unshippedItem.sku
    let tenantChannelId = unshippedItem.tenantChannelId
    let orderNumber = unshippedItem.orderNumber

    let itemSkuData = skuData.find((record) => {
      return record.sku == sku
    })

    if (!itemSkuData) {
      console.log('NO RECORD FOR ' + sku)
      continue
    }

    if (itemSkuData.type === 'KIT') {
      continue
    }

    if (!formatted[orderNumber]) {
      let channelData = channels.value.find((channel) => {
        return channel.tenantChannelId == tenantChannelId
      })
      formatted[orderNumber] = {
        channelData: channelData,
        items: []
      }
    }

    unshippedItem.skuData = itemSkuData

    formatted[orderNumber].items.push(unshippedItem)
  }

  const picklist = new Picklist()
  picklist.create(formatted)
}

/*
 *****************      ACTIONBAR
 */
import ActionBar from '@/components/content/ActionBar'

const newOrder = () => {
  router.push('/orders/new')
}

const buttons = ref([
  {
    id: 0,
    label: 'Add new',
    click: newOrder,
    icon: ['fas', 'circle-plus']
  },
  {
    id: 1,
    label: 'Column Settings',
    click: show,
    icon: ['fas', 'gear']
  },
  {
    id: 4,
    label: 'Picklist unshipped',
    click: () => {
      createPicklist()
    },
    icon: ['fas', 'clipboard-list']
  }
])

const filterUpdate = ref(false)
const appliedFilters = ref({})

onActivated(() => {
  if (!grid.value) {
    return
  }

  filterUpdate.value = true
  deactivated.value = false
})

onDeactivated(() => {
  deactivated.value = true
})

watch(
  () => props.pageId,
  (current, previous) => {
    filterUpdate.value = true
  }
)

const updateFilters = (needRows = true) => {
  let tableFilters = getTableFilters(defaultSort.value)
  if (needRows) {
    getRows(tableFilters)
  }
  updateTableFilters(tableFilters)
}

watch(filterUpdate, (current, previous) => {
  if (!current) {
    return
  }
  updateFilters()
  filterUpdate.value = false
})
</script>
