import { PDFDocument, StandardFonts, breakTextIntoLines } from 'pdf-lib'

export class Picklist {
  constructor() {
    this.document = null
    this.options = {}
    this.orders = {}
    this.y = 0
    this.pageCount = 0
    this.quantityColStart = 30
    this.skuColStart = 60
    this.titleColStart = 150
    this.variantColStart = 450
    this.headerFontSize = 10
    this.lineHeight = 20
    this.fontSize = 10
    this.summary = {}
    this.page
    this.font
    this.boldFont
  }

  async create(orders) {
    this.orders = orders || {}
    this.document = await PDFDocument.create()

    await this.embedFonts()
    this.createNewPage()
    this.createSummary()
    this.drawSummary()
    this.drawFooters()
    await this.openPdf()
  }

  async embedFonts() {
    this.boldFont = await this.document.embedFont(StandardFonts.HelveticaBold)
    this.font = await this.document.embedFont(StandardFonts.Helvetica)
  }

  createSummary() {
    for (let orderNumber in this.orders) {
      let order = this.orders[orderNumber]
      let items = order.items
      this.addToSummary(items)
    }
  }

  addToSummary(items) {
    let index = 0
    let length = items.length
    for (index; index < length; index++) {
      let item = items[index]
      let quantity = item.quantity || 0
      let sku = item.sku || ''
      let title = `${item.skuData?.brandName || ''} ${
        item.skuData?.title || item.title
      }`
      let variant = item.skuData?.variant || ''

      if (!this.summary[sku]) {
        this.summary[sku] = { quantity, title, variant }
        continue
      }

      this.summary[sku].quantity += quantity
    }
  }

  drawSummary() {
    const pageWidth = this.page.getWidth()

    for (let sku in this.summary) {
      if (this.y < 20) {
        this.createNewPage()
      }

      let skuSummary = this.summary[sku]
      let variant = skuSummary.variant ? `- ${skuSummary.variant}` : ''
      let line = `${skuSummary.quantity} x ${skuSummary.title} ${variant}`
      let lines = 1

      lines = this.drawText(
        line,
        this.quantityColStart,
        pageWidth - this.quantityColStart,
        lines
      )

      this.y -= this.lineHeight * lines
    }
  }

  createNewPage() {
    this.pageCount++
    this.page = this.document.addPage()
    this.y = this.page.getHeight() - this.lineHeight
  }

  drawFooters() {
    let index = 0
    let length = this.pageCount

    for (index; index < length; index++) {
      let line = `Page ${index + 1} of ${length}`
      let page = this.document.getPage(index)
      page.drawText(line, {
        x: 450,
        y: 20,
        size: this.fontSize,
        font: this.font
      })
    }
  }

  drawSeparationLine(pageWidth) {
    this.page.drawLine({
      start: { x: 10, y: this.y + 15 },
      end: { x: pageWidth - 10, y: this.y + 15 },
      thickness: 0.2,
      opacity: 0.75
    })
  }

  /**
   * Will check if text is going to go over multiple lines
   * then print and set y accordingly
   * @param {Object} page
   * @param {String} text
   * @param {Number} x
   * @param {Number} numberOfLines
   */
  drawText(
    text,
    x,
    maxWidth,
    numberOfLines,
    font = this.font,
    fontSize = this.fontSize
  ) {
    const measureWidth = (s) => this.font.widthOfTextAtSize(s, fontSize)
    const lines = breakTextIntoLines(
      text,
      this.document.defaultWordBreaks,
      maxWidth,
      measureWidth
    )

    let index = 0
    let length = lines.length
    let y = this.y

    for (index; index < length; index++) {
      let line = lines[index]
      this.page.drawText(line, {
        x: x,
        y: y,
        size: fontSize,
        font: font,
        maxWidth: maxWidth,
        lineHeight: this.lineHeight
      })

      y -= this.lineHeight
    }

    return length > numberOfLines ? length : numberOfLines
  }

  async openPdf() {
    let fileBytesArray = await this.document.save()
    let blob = new Blob([fileBytesArray], { type: 'application/pdf' })
    var url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }
}
