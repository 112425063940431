<template>
  <ValidationProvider
    v-if="loaded"
    ref="validation"
    v-slot="{ errors, changes }"
  >
    <ActionBar
      :buttons="buttons"
      :conditions="{
        0: { disable: !allowPrintInvoice },
        1: { disable: (!changes && !saveButtonEnabled) || saving || errors }
      }"
    ></ActionBar>

    <TableTabs v-slot="{ activeTab }" :tabs="tabs">
      <template v-if="activeTab == 'Invoice'">
        <TableContainer>
          <TablePanel>
            <template #1>
              <TableForm>
                <!-- INVOICE NUMBER -->
                <TableRow
                  v-if="local.tenantInvoiceId != 'new'"
                  v-model="local.invoiceNumber"
                  label="Invoice Number"
                  type="text"
                  read-only
                ></TableRow>
                <!-- ./ INVOICE NUMBER -->

                <!-- ORDER NUMBER -->
                <TableRow
                  v-if="local.tenantOrderId != null"
                  v-model="local.orderNumber"
                  :link="`/orders/${local.tenantOrderId}`"
                  read-only
                  label="Order Number"
                  type="text"
                ></TableRow>
                <!-- / ORDER NUMBER -->

                <!-- STATUS -->
                <TableRow
                  v-model="local.status"
                  label="Status"
                  type="select"
                  :options="[
                    { value: 0, label: 'Draft' },
                    { value: 1, label: 'Unfulfilled' },
                    { value: 2, label: 'Part-fulfilled' },
                    { value: 3, label: 'Fulfilled' },
                    { value: 4, label: 'Cancelled' }
                  ]"
                ></TableRow>
                <!-- /STATUS -->

                <!-- DUE DATE -->
                <TableRow
                  v-model="local.dueDate"
                  label="Due Date"
                  type="date"
                ></TableRow>
                <!-- /DUE DATE -->

                <!-- COMMENTS -->
                <TableRow
                  v-model="local.comments"
                  label="Comments"
                  type="textarea"
                ></TableRow>
                <!-- /COMMENTS -->

                <TableRow
                  label="Use dispatch address for billing"
                  type="custom"
                >
                  <input
                    v-model="local.useDispatchAddrForBilling"
                    type="checkbox"
                    :true-value="1"
                    :false-value="0"
                  />
                </TableRow>

                <RowContainer heading="Dispatch Address">
                  <!-- DISPATCH COMPANy -->
                  <TableRow
                    v-model="local.dispatchAddrCompany"
                    label="Company"
                    type="text"
                  ></TableRow>
                  <!-- /DISPATCH COMPANY -->

                  <!-- DISPATCH LINE 1 -->
                  <TableRow
                    v-model="local.dispatchAddrLine1"
                    label="Line 1"
                    type="text"
                  ></TableRow>
                  <!-- /DISPATCH LINE 1 -->

                  <!-- DISPATCH LINE 2 -->
                  <TableRow
                    v-model="local.dispatchAddrLine2"
                    label="Line 2"
                    type="text"
                  ></TableRow>
                  <!-- /DISPATCH LINE 2 -->

                  <!-- DISPATCH LINE 3 -->
                  <TableRow
                    v-model="local.dispatchAddrLine3"
                    label="Line 3"
                    type="text"
                  ></TableRow>
                  <!-- /DISPATCH LINE 3 -->

                  <!-- DISPATCH POSTCODE -->
                  <TableRow
                    v-model="local.dispatchAddrPostcode"
                    label="Postcode"
                    type="text"
                  ></TableRow>
                  <!-- /DISPATCH POSTCODE -->

                  <!-- DISPATCH COUNTRY -->
                  <TableRow
                    v-model="local.dispatchAddrCountry"
                    label="Country"
                    type="text"
                  ></TableRow>
                  <!-- /DISPATCH Country -->
                </RowContainer>

                <RowContainer
                  v-if="!local.useDispatchAddrForBilling"
                  heading="Billing Address"
                >
                  <!-- BILLING COMPANY -->
                  <TableRow
                    v-model="local.billingAddrCompany"
                    label="Company"
                    type="text"
                  ></TableRow>
                  <!-- /BILLING COMPANY -->

                  <!-- INVOICE LINE 1 -->
                  <TableRow
                    v-model="local.billingAddrLine1"
                    label="Line 1"
                    type="text"
                  ></TableRow>
                  <!-- /INVOICE LINE 1 -->

                  <!-- INVOICE LINE 2 -->
                  <TableRow
                    v-model="local.billingAddrLine2"
                    label="Line 2"
                    type="text"
                  ></TableRow>
                  <!-- /INVOICE LINE 2 -->

                  <!-- INVOICE LINE 3 -->
                  <TableRow
                    v-model="local.billingAddrLine3"
                    label="Line 3"
                    type="text"
                  ></TableRow>
                  <!-- /INVOICE LINE 3 -->

                  <!-- INVOICE POSTCODE -->
                  <TableRow
                    v-model="local.billingAddrPostcode"
                    label="Postcode"
                    type="text"
                  ></TableRow>
                  <!-- /INVOICE POSTCODE -->

                  <!-- INVOICE COUNTRY -->
                  <TableRow
                    v-model="local.billingAddrCountry"
                    label="Country"
                    type="text"
                  ></TableRow>
                  <!-- /INVOICE Country -->
                </RowContainer>
              </TableForm>
            </template>
          </TablePanel>

          <TablePanel>
            <template #1>
              <TableGrid
                ref="grid"
                :cols="columns"
                :rows="local.invoiceItems"
                :total-count="local.invoiceItems.length"
                :filter-function="getOrder"
                :default-sort="defaultSort"
                id-key="tenantInvoiceItemId"
                scrollable
              ></TableGrid>
            </template>
          </TablePanel>
        </TableContainer>
      </template>
    </TableTabs>
  </ValidationProvider>
</template>

<script setup>
import { ref, computed, onMounted, inject, watch } from 'vue'

import { useRouter } from 'vue-router'
import useApi from '@/components/useApi'

const router = useRouter()
const api = useApi()

const invoiceId = ref(false)
// To manually enabled the save button
const saveButtonEnabled = ref(false)

const props = defineProps({
  id: {
    type: [String, Number],
    default: ''
  },
  tenantOrderId: {
    type: String,
    default: null
  }
})

const progressBarStart = inject('progressBarStart')
const progressBarFail = inject('progressBarFail')
const progressBarDone = inject('progressBarDone')

const local = ref({
  order: {
    orderItems: []
  },
  invoiceItems: [],
  status: 0,
  useDispatchAddrForBilling: 1,
  billingAddrCompany: null,
  billingAddrLine1: null,
  billingAddrLine2: null,
  billingAddrLine3: null,
  billingAddrPostcode: null,
  billingAddrCountry: null,
  dispatchAddrCompany: null,
  dispatchAddrLine1: null,
  dispatchAddrLine2: null,
  dispatchAddrLine3: null,
  dispatchAddrPostcode: null,
  dispatchAddrCountry: null
})
const remote = ref({})
const loaded = ref(false)

const setDefaults = () => {
  local.value = {
    tenantOrderId: props.tenantOrderId,
    order: {
      orderItems: []
    },
    status: 0,
    useDispatchAddrForBilling: 1,
    invoiceItems: [],
    billingAddrCompany: null,
    billingAddrLine1: null,
    billingAddrLine2: null,
    billingAddrLine3: null,
    billingAddrPostcode: null,
    billingAddrCountry: null,
    dispatchAddrCompany: null,
    dispatchAddrLine1: null,
    dispatchAddrLine2: null,
    dispatchAddrLine3: null,
    dispatchAddrPostcode: null,
    dispatchAddrCountry: null
  }
}

/*
 ********** TABLE FORM
 */
import TableForm from '@/components/table/form/TableForm'
import RowContainer from '@/components/table/form/RowContainer'
import TableRow from '@/components/table/form/TableRow'

const tabs = ['Invoice']
const saving = ref(false)

/*
 **********  VALIDATION PROVIDER
 */
const validation = ref(null)

/*
 ********** INVOICE
 */
const allowPrintInvoice = computed(() => {
  return local.value.tenantInvoiceId == 'new' ? false : true
})
const getInvoiceId = () => {
  invoiceId.value = false
  if (props.id && parseInt(props.id) > 0) {
    invoiceId.value = props.id
  }
}

const getInvoice = async () => {
  if (!invoiceId.value || invoiceId.value === 'new') {
    setDefaults()
    return
  }
  let response = await api.get('/invoices/' + invoiceId.value)
  handleInvoiceResponse(response.data.invoice)
}

const handleInvoiceResponse = async (invoice) => {
  if (!invoice) {
    return
  }

  local.value = {
    tenantInvoiceId: invoice.tenantInvoiceId,
    tenantOrderId: invoice.tenantOrderId,
    orderNumber: invoice.orderNumber,
    channelOrderId: invoice.channelOrderId,
    invoiceNumber: invoice.invoiceNumber,
    order: {
      orderItems: []
    },
    dueDate: invoice.dueDate,
    status: invoice.status,
    totalPrice: invoice.totalPrice,
    currency: invoice.currency,
    comments: invoice.comments,
    createdAt: invoice.createdAt,
    updatedAt: invoice.updatedAt,
    invoiceItems: invoice.lineItems,
    billingAddrCompany: invoice.billingAddrCompany,
    billingAddrLine1: invoice.billingAddrLine1,
    billingAddrLine2: invoice.billingAddrLine2,
    billingAddrLine3: invoice.billingAddrLine3,
    billingAddrPostcode: invoice.billingAddrPostcode,
    billingAddrCountry: invoice.billingAddrCountry,
    dispatchAddrCompany: invoice.dispatchAddrCompany,
    dispatchAddrLine1: invoice.dispatchAddrLine1,
    dispatchAddrLine2: invoice.dispatchAddrLine2,
    dispatchAddrLine3: invoice.dispatchAddrLine3,
    dispatchAddrPostcode: invoice.dispatchAddrPostcode,
    dispatchAddrCountry: invoice.dispatchAddrCountry,
    useDispatchAddrForBilling: invoice.useDispatchAddrForBilling
  }

  formatLineTotals()
}

const update = async () => {
  if (!local.value.status == undefined || local.value.status === '') {
    return
  }

  if (!invoiceId.value) {
    insert()
    saveButtonEnabled.value = false
    return
  }
  let response = await api.put(
    '/invoices/' + invoiceId.value,
    { invoice: local.value },
    saving
  )
  handleInvoiceItemResponse(response.data.items)
  saveButtonEnabled.value = false
  validation.value.save()
}

const insert = async () => {
  let response = await api.post('/invoices/', { invoice: local.value }, saving)
  invoiceId.value = String(response.data.insertedId)
  router.replace('/invoices/' + invoiceId.value)
  // handleInvoiceResponse(response.data)
}

const handleInvoiceItemResponse = (items) => {
  if (!items || items.length == 0) {
    return
  }

  local.value.invoiceItems = items
  formatLineTotals()
}

/*
 ********* ORDERS
 */
const getOrder = async () => {
  if (!local.value.tenantOrderId) {
    return
  }

  if (invoiceId.value) {
    return
  }

  let response = await api.get('/orders/' + local.value.tenantOrderId)
  handleOrderResponse(response.data)
}

const handleOrderResponse = (data) => {
  let order = data.orders[0]

  if (!order) {
    local.value.order = {}
    return
  }
  let orderItems = data.orderItems || []
  local.value.order = {
    tenantOrderId: local.value.tenantOrderId,
    tenantCustomerId: order.tenantCustomerId,
    channelOrderId: order.channelOrderId,
    orderNumber: order.orderNumber,
    status: order.status,
    email: order.email,
    internalNotes: order.internalNotes,
    phone: order.phone,
    customerName: order.customerName,
    dispatchLocationId: order.dispatchLocationId,
    orderItems: orderItems,
    tenantChannelId: order.tenantChannelId || null,
    channelName: order.channelName || null
  }
  if (local.value.tenantInvoiceId == 'new') {
    local.value.billingAddrLine1 = order.billingAddrLine1
    local.value.billingAddrLine2 = order.billingAddrLine2
    local.value.billingAddrLine3 = order.billingAddrLine3
    local.value.billingAddrPostcode = order.billingAddrPostcode
    local.value.billingAddrCountry = order.billingAddrCountry
    local.value.dispatchAddrLine1 = order.shippingAddrLine1
    local.value.dispatchAddrLine2 = order.shippingAddrLine2
    local.value.dispatchAddrLine3 = order.shippingAddrLine3
    local.value.dispatchAddrPostcode = order.shippingAddrPostcode
    local.value.dispatchAddrCountry = order.shippingAddrCountry
    local.value.useDispatchAddrForBilling = order.useDispatchAddrForBilling
  }

  addOrderItemsToInvoiceItems(orderItems)
}

const addOrderItemsToInvoiceItems = (orderItems) => {
  for (let i in orderItems) {
    let orderItem = orderItems[i]

    local.value.invoiceItems.push({
      channelOrderItemId: orderItem.channelOrderItemId,
      gtin: null,
      hsCode: null,
      mpn: null,
      origin: null,
      price: orderItem.price,
      sku: orderItem.sku,
      status: 'Parked',
      tenantOrderId: orderItem.tenantOrderId,
      title: orderItem.title,
      vatAmount: orderItem.vatAmount,
      vatPercent: orderItem.vatPercent,
      quantity: orderItem.quantity
    })
  }
}

/*
 ****** INVOICE ITEMS
 */
const defaultSort = ref({ tenantInvoiceId: -1 })
const invoiceItemsNotInCurrent = ref([])

const getOtherInvoiceItems = async () => {
  if (!local.value.tenantOrderId) {
    return
  }

  if (invoiceId.value) {
    return
  }

  let response = await api.get('/invoices/getInvoicesByOrderId', {
    tenantOrderId: local.value.tenantOrderId
  })
  handleAllInvoiceItems(response.data)
}

const handleAllInvoiceItems = (data) => {
  let invoiceItems = data.invoiceItems
  let items = invoiceItems.filter(
    (item) => item.tenantInvoiceId != invoiceId.value
  )
  invoiceItemsNotInCurrent.value = items

  // items = invoiceItems.filter((item) => item.tenantInvoiceId != invoiceId.value)
  // local.value.invoiceItems = items
}

const formatLineTotals = () => {
  local.value.invoiceItems.map((item) => {
    item.linePrice = getLinePrice(item)
    item.lineVat = getLineVat(item)
    item.lineTotal = getLineTotal(item)
  })
}

const getLinePrice = (item) => {
  if (!item.quantity || !item.price) {
    return 0.0
  }

  let price = item.price || 0.0
  let quantity = item.quantity || 0

  let linePrice = parseInt(quantity) * price
  return linePrice.toFixed(2)
}

const getLineVat = (item) => {
  if (tenant.value.vatNumber == null) {
    return null
  }
  let linePrice = item.linePrice
  let vat = item.vatPercent / 100

  let lineVAT = linePrice * vat

  return lineVAT.toFixed(2)
}

const getLineTotal = (item) => {
  let total = (item.linePrice * 100 + item.lineVat * 100) / 100

  return total.toFixed(2)
}

const tenant = ref({})
const getTenant = async () => {
  let response = await api.get('/tenant')
  let data = response.data.tenant

  tenant.value = {
    vatNumber: data.vatNumber,
    tradingName: data.tradingName,
    contactPhone: data.contactPhone,
    contactEmail: data.contactEmail
  }
}

const rowCount = computed(() => {
  return rows.value.length
})

const columns = [
  {
    label: 'Sku',
    name: 'sku',
    visible: true,
    filter: false
  },
  {
    label: 'Quantity In Current Invoice',
    name: 'quantity',
    visible: true,
    input: true,
    inputType: 'number',
    change: () => {
      saveButtonEnabled.value = true
      formatLineTotals()
    }
  },
  {
    label: 'Price',
    name: 'price',
    visible: true,
    input: true,
    change: () => {
      saveButtonEnabled.value = true
      formatLineTotals()
    }
  },
  {
    label: 'VAT (%)',
    name: 'vatPercent',
    visible: true,
    input: true,
    change: () => {
      saveButtonEnabled.value = true
      formatLineTotals()
    }
  },
  {
    label: 'Subtotal',
    name: 'linePrice',
    visible: true,
    number: true
  },
  {
    label: 'VAT',
    name: 'lineVat',
    visible: true,
    number: true
  },
  {
    label: 'Total',
    name: 'lineTotal',
    visible: true,
    number: true
  }
]

/*
 ******* INVOICE PDF
 */
import usePDF from '@/composables/pdfs/usePDF'
const pdf = usePDF()
const createPdf = async () => {
  pdf.createInvoicePdf(local.value, tenant.value, 'download')
}

/*
 ******** ACTION BAR
 */
import ActionBar from '@/components/content/ActionBar'

const buttons = [
  { id: 0, label: 'Print Invoice', click: createPdf },
  { id: 1, label: 'Save', click: update, icon: ['fas', 'floppy-disk'] },
  {
    id: 2,
    label: 'Close',
    click: () => router.back(),
    icon: ['far', 'circle-xmark']
  }
]

const getData = async () => {
  try {
    progressBarStart()
    getInvoiceId()
    await getTenant()
    await getInvoice()
    await getOtherInvoiceItems()
    await getOrder()
    let pageTitle = local.value.invoiceNumber || 'Create Invoice'
    document.getElementById('pagetitle').innerHTML = pageTitle
    document.title = pageTitle

    loaded.value = true
    progressBarDone()
  } catch (e) {
    console.log(e)
    progressBarFail()
  }
}

getData()
</script>

<style></style>
