module.exports = () => {
  let formattedRequestRoutes = {}

  for (let route in process.env) {
    if (!route.startsWith('VUE_APP_REQUEST_ROUTES')) {
      continue
    }
    let address = process.env[route]
    let service = route.toLowerCase().replace('vue_app_request_routes_', '')
    if (service == 'parcel2go') {
      service = 'parcel2Go'
    }
    formattedRequestRoutes[service] = address
  }

  return formattedRequestRoutes
}
