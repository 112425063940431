import { PDFDocument, StandardFonts, breakTextIntoLines } from 'pdf-lib'

export class OrderSummary {
  constructor() {
    this.document = null
    this.options = {}
    this.orders = []
    this.y = 0
    this.pageCount = 0
    this.quantityColStart = 30
    this.skuColStart = 60
    this.titleColStart = 150
    this.variantColStart = 450
    this.headerFontSize = 10
    this.lineHeight = 20
    this.fontSize = 10
    this.summary = {}
    this.page
    this.font
    this.boldFont
  }

  async create(orders) {
    this.orders = orders || []
    this.document = await PDFDocument.create()

    await this.embedFonts()
    this.createNewPage()
    this.drawOrders()
    this.drawFooters()
    await this.openPdf()
  }

  async embedFonts() {
    this.boldFont = await this.document.embedFont(StandardFonts.HelveticaBold)
    this.font = await this.document.embedFont(StandardFonts.Helvetica)
  }

  drawOrders() {
    const pageWidth = this.page.getWidth()

    for (let orderNumber in this.orders) {
      let order = this.orders[orderNumber]
      let items = order.items

      this.drawOrder(orderNumber, order)
      this.drawItems(items)
      this.drawSeparationLine(pageWidth)
    }
  }

  drawItems(items) {
    let index = 0
    let length = items.length
    for (index; index < length; index++) {
      let item = items[index]
      this.drawTableRow(item)
    }
  }

  drawTableHeader() {
    const pageWidth = this.page.getWidth()
    let x = this.quantityColStart
    this.page.drawText('Quantity', {
      x: x,
      y: this.y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    x = this.skuColStart
    this.page.drawText('SKU', {
      x: x,
      y: this.y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    x = this.titleColStart
    this.page.drawText('Title', {
      x: x,
      y: this.y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    x = this.variantColStart
    this.page.drawText('Variant', {
      x: x,
      y: this.y,
      size: this.headerFontSize,
      font: this.boldFont
    })

    this.page.drawLine({
      start: { x: 10, y: this.y - 5 },
      end: { x: pageWidth - 10, y: this.y - 5 },
      thickness: 0.5,
      opacity: 0.75
    })

    this.y -= this.lineHeight
  }

  drawOrder(orderNumber, order) {
    if (this.y < 100) {
      this.createNewPage()
    }

    const pageWidth = this.page.getWidth()
    let channelData = order.channelData || {}
    let customerName = order.customerName || ''

    let lines = 1

    lines = this.drawText(
      orderNumber,
      this.quantityColStart,
      this.titleColStart - this.quantityColStart,
      lines
    )

    lines = this.drawText(
      channelData.name,
      this.titleColStart,
      this.variantColStart - this.titleColStart,
      lines
    )

    lines = this.drawText(
      customerName,
      this.variantColStart,
      pageWidth - this.variantColStart,
      lines
    )

    lines += 0.5

    this.y -= this.lineHeight * lines
  }

  drawTableRow(item) {
    const pageWidth = this.page.getWidth()
    let quantity = `${item.quantity.toString()} x `
    let sku = item.sku || ''
    let title = `${item.skuData?.brandName || ''} ${
      item.skuData?.title || item.title
    }`
    let variant = item.skuData?.variant || ''
    let lines = 1
    // sku += `\n(${item.channelData.name})`

    lines = this.drawText(
      quantity.toString(),
      this.quantityColStart,
      this.skuColStart - this.quantityColStart,
      lines,
      this.boldFont
    )
    lines = this.drawText(
      sku,
      this.skuColStart,
      this.titleColStart - this.skuColStart,
      lines,
      this.boldFont
    )
    lines = this.drawText(
      title,
      this.titleColStart,
      this.variantColStart - this.titleColStart,
      lines
    )
    lines = this.drawText(
      variant,
      this.variantColStart,
      pageWidth - this.variantColStart,
      lines
    )

    this.y -= this.lineHeight * lines

    if (this.y < 50) {
      this.createNewPage()
    }
  }

  createNewPage() {
    this.pageCount++
    this.page = this.document.addPage()
    this.y = this.page.getHeight() - this.lineHeight
  }

  drawFooters() {
    let index = 0
    let length = this.pageCount

    for (index; index < length; index++) {
      let line = `Page ${index + 1} of ${length}`
      let page = this.document.getPage(index)
      page.drawText(line, {
        x: 450,
        y: 20,
        size: this.fontSize,
        font: this.font
      })
    }
  }

  drawSeparationLine(pageWidth) {
    this.page.drawLine({
      start: { x: 10, y: this.y + 15 },
      end: { x: pageWidth - 10, y: this.y + 15 },
      thickness: 0.2,
      opacity: 0.75
    })
  }

  /**
   * Will check if text is going to go over multiple lines
   * then print and set y accordingly
   * @param {Object} page
   * @param {String} text
   * @param {Number} x
   * @param {Number} numberOfLines
   */
  drawText(
    text,
    x,
    maxWidth,
    numberOfLines,
    font = this.font,
    fontSize = this.fontSize
  ) {
    const measureWidth = (s) => this.font.widthOfTextAtSize(s, fontSize)
    const lines = breakTextIntoLines(
      text,
      this.document.defaultWordBreaks,
      maxWidth,
      measureWidth
    )

    let index = 0
    let length = lines.length
    let y = this.y

    for (index; index < length; index++) {
      let line = lines[index]
      this.page.drawText(line, {
        x: x,
        y: y,
        size: fontSize,
        font: font,
        maxWidth: maxWidth,
        lineHeight: this.lineHeight
      })

      y -= this.lineHeight
    }

    return length > numberOfLines ? length : numberOfLines
  }

  async openPdf() {
    let fileBytesArray = await this.document.save()
    let blob = new Blob([fileBytesArray], { type: 'application/pdf' })
    var url = URL.createObjectURL(blob)
    window.open(url, '_blank')
  }
}
