<template>
  <div class="row" :style="style">
    <div class="col-12">
      <table class="table table-form" style="height: 100%">
        <thead>
          <tr>
            <slot name="head"></slot>
          </tr>
        </thead>
        <tbody>
          <slot></slot>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableForm'
}
</script>

<script setup>
const props = defineProps({
  style: {
    type: String,
    default: ''
  }
})
</script>

<style lang="scss">
.table-form {
  td {
    padding: 0;
    line-height: 2rem;
  }

  tbody tr:hover td {
    background-color: rgba(0, 0, 0, 0.001);
  }
  border-collapse: separate;
  border-spacing: 0px;
}
</style>
