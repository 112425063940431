<template>
  <div class="menu-item-wrapper" :class="active && 'active'">
    <template v-if="subItems.length > 0">
      <a class="menu-link" :title="label" @click="toggleVisibility">
        <RouterLink class="menu-link icon-link" :to="route">
          <div>
            <font-awesome-icon
              v-if="icon.length > 0"
              :icon="icon"
              class="nav-icon"
            ></font-awesome-icon>
            <p class="menu-item-text">
              <b>{{ label }}</b>
            </p>
          </div>
          <font-awesome-icon
            class="menu-item-icon"
            :icon="menuItemIcon"
          ></font-awesome-icon>
        </RouterLink>
      </a>
      <div v-if="visible" class="sub-item-wrapper">
        <RouterLink
          v-for="item in subItems"
          :key="item.id"
          class="menu-link"
          :to="item.route"
        >
          <div class="sub-item">
            <p>
              {{ item.label }}
            </p>
          </div>
        </RouterLink>
      </div>
    </template>
    <RouterLink v-else class="menu-link" :to="route">
      <div>
        <font-awesome-icon
          v-if="icon.length > 0"
          :icon="icon"
          class="nav-icon"
        ></font-awesome-icon>
        <p class="menu-item-text">
          <b>{{ label }}</b>
        </p>
      </div>
    </RouterLink>
  </div>
</template>

<script>
export default {
  name: 'LeftMenuItem'
}
</script>

<script setup>
import { ref, computed } from 'vue'

const emit = defineEmits(['toggleVisibility'])

const props = defineProps({
  route: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: ''
  },
  subItems: {
    type: Array,
    default() {
      return []
    }
  },
  active: {
    type: Boolean
  },
  activeRoutes: {
    type: Array,
    default() {
      return []
    }
  },
  icon: {
    type: Array,
    default() {
      return []
    }
  },
  expanded: {
    type: Boolean,
    default: false
  }
})

const visible = ref(props.expanded)

const menuItemIcon = computed(() => {
  if (visible.value === true) {
    return ['fas', 'chevron-down']
  }

  if (props.subItems.length > 0) {
    return ['fas', 'chevron-right']
  }

  return []
})

const toggleVisibility = () => {
  visible.value = !visible.value
  emit('toggleVisibility', visible.value)
}
</script>

<style lang="scss">
.menu-item-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding-left: 1.2rem;
  padding-right: 0;

  &:hover {
    border-left: 0.2rem solid #382e7c;
    padding-left: 1rem;
  }

  &.active {
    border-left: 0.2rem solid #382e7c;
    padding-left: 1rem;
  }

  .menu-item {
    padding-top: 0.5rem;
  }

  .menu-item-icon,
  .menu-item-text {
    display: inline-block;
    user-select: none; /* Chrome */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
  }

  .menu-item-icon {
    font-size: 0.7em;
  }

  .menu-item-text {
    margin: 0;
    margin-left: 0.3rem;
    font-size: 1.1rem;
  }

  .menu-link {
    cursor: pointer;
    text-decoration: none;
    font-weight: normal;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    // padding-right: 0.75rem;
    .icon-link {
      pointer-events: none;
      // color: white !important;
    }
  }

  .nav-icon {
    width: 1.2rem;
  }
}

.sub-item-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  .sub-item {
    margin: 0;
    margin-left: 1.5rem;
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-content: flex-end;
    p {
      margin-bottom: 0.2rem;
      // color: white;
    }
  }
}

.collapsedLeftNav {
  .menu-item-icon,
  .menu-item-text {
    display: none;
  }
  .menu-item-wrapper {
    padding-left: 0.7rem;
    &:hover {
      padding-left: 0.5rem;
    }
    &.active {
      padding-left: 0.5rem;
    }
    .menu-link {
      padding: 0;
      justify-content: center;
    }
  }
  .sub-item-wrapper {
    display: none;
  }
  .icon-link {
    pointer-events: all !important;
  }
}
</style>
